import React from 'react';
import useRedirect from 'hooks/useRedirect';
import { useParams } from 'react-router-dom';
import { Box, Button, Col, Row, Text } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import { CaretRight } from '@phosphor-icons/react';
import {
  LessonPracticeResultProps,
  LessonPracticeTypes
} from 'containers/LearningMaterials/LearningMaterial.types';
import getPracticeResultData from '../utils/getPracticeResultData';
import getPracticeTaskingUrl from 'containers/LearningMaterials/utils/getPracticeTaskingUrl';

interface LessonPracticeBlockProps {
  data: (LessonPracticeTypes | null)[];
}

export function LessonPracticeResult({
  result,
  status,
  textColor,
  bgColor
}: LessonPracticeResultProps & { result: number }) {
  const theme = useTheme();

  return (
    <Box
      backgroundColor={theme.colors[bgColor]}
      borderRadius="5px"
      padding="0 10px"
    >
      <Text
        variant="header-4"
        fontSize="10px"
        letterSpacing="0.64px"
        color={theme.colors[textColor]}
      >
        {result} ({status})
      </Text>
    </Box>
  );
}

function LessonPracticeBlock({ data }: LessonPracticeBlockProps) {
  const theme = useTheme();
  const redirect = useRedirect();
  const params: any = useParams();
  const lessonId = parseInt(params?.lessonId, 10);
  const topicId = parseInt(params?.topicId, 10);

  const handleStartTask = (id: number) => {
    redirect(getPracticeTaskingUrl(id, topicId, lessonId));
  };

  return (
    <Row
      gridGap="25px"
      width="100%"
      paddingTop="30px"
      borderTop={`1px solid ${theme.colors.lighter}`}
    >
      <Text variant="custom-20px-bold">Practice</Text>
      <Row gridGap="5px">
        {data.map((practice, key) => {
          if (!practice) {
            return null;
          }

          const { id, title, avgResult } = practice;
          return (
            <Col
              key={id}
              width="100%"
              padding="15px 20px"
              borderRadius="10px"
              justifyContent="space-between"
              boxShadow={`0px 4px 10px ${theme.colors.shadow}`}
            >
              <Col gridGap="10px">
                <Text variant="ui-1-bold">Task {key + 1}:</Text>
                <Text>{title}</Text>
              </Col>
              <Col gridGap="15px">
                {avgResult >= 0 && (
                  <LessonPracticeResult
                    {...getPracticeResultData(avgResult)}
                    result={avgResult}
                  />
                )}
                <Box
                  width="1px"
                  height="100%"
                  backgroundColor={theme.colors.lighter}
                />
                <Button
                  variant="ghost"
                  height="24px"
                  noHover
                  noPadding
                  alignItems="center"
                  lineHeight="24px"
                  iconSuffix={<CaretRight size={13} weight="bold" />}
                  onClick={() => handleStartTask(id)}
                >
                  start task
                </Button>
              </Col>
            </Col>
          );
        })}
      </Row>
    </Row>
  );
}

export default LessonPracticeBlock;
