import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { css } from 'styled-components/macro';
import { Box, Col } from 'components/_main';
import { LabelElement } from './LabelsDropdown.ui';
import { LabelsDropdownProps } from './LabelsDropdown.types';

function LabelsDropdown({ data, onSelect, elRef }: LabelsDropdownProps) {
  const handleClick = ({ key }: { key: string }) => onSelect(key);
  const [el, setEl] = useState<HTMLElement | null>(null);
  const [styles, setStyles] = useState<Record<string, string>>({});

  useEffect(() => {
    if (elRef?.current) {
      setEl(elRef.current);
    }
  }, [elRef]);

  useEffect(() => {
    const wrapperEl = document.getElementById('AnnotationViewPort');
    if (!wrapperEl) return;

    const { height } = wrapperEl.getBoundingClientRect();

    setStyles({
      maxHeight: `${height}px`
    });
  }, []);

  const items = data.map((elem) => ({
    key: elem.label,
    label: (
      <Col
        key={elem.id}
        width="100%"
        bg="transparent"
        css={css`
          cursor: pointer;

          &:hover {
            background-color: transparent;
            transition: background-color 200ms ease-in;
          }
        `}
      >
        <LabelElement label={elem.label} />
      </Col>
    )
  }));

  if (!el) return null;

  return (
    <Dropdown
      visible
      overlay={
        <Menu
          css={css`
            .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
              padding: 6px 10px;
            }
          `}
          // @ts-ignore
          onClick={handleClick}
          style={{
            minWidth: '210px',
            maxWidth: '400px',
            paddingTop: '6px',
            paddingBottom: '6px',
            overflow: 'auto',
            ...styles
          }}
          items={items}
        />
      }
      {...(el &&
        el !== null && {
          getPopupContainer: () => el
        })}
    >
      <></>
    </Dropdown>
  );
}

export default LabelsDropdown;
