import React, { useCallback, useMemo } from 'react';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { useRedirect } from 'hooks/useRedirect';
import { Project } from 'generated/graphql';
import columnsHelper from 'containers/Projects/ProjectsHelper/columns.helper';
import parseSubType from 'utils/parseSubType';
import useGetListProjects from 'containers/Projects/hooks/useGetListProject';
import ProjectsTable from 'containers/Projects/ProjectsTable';

const ProjectsHelper = () => {
  const projectListProps = useGetListProjects();

  const userSessionData = useGetUserSessionData();
  const handleRedirect = useRedirect();

  const onStartTasking = useCallback(
    (data: Project) => {
      const { id, type, projectSettings, title } = data || {};
      const specType = parseSubType(
        projectSettings?.subType ?? projectSettings?.annotationType
      );
      handleRedirect(
        `/tasks/tasking/${type}/${id}?title=${title}&specType=${specType}`
      );
    },
    [handleRedirect]
  );

  const onStartAudit = useCallback(
    (data: Project) => {
      const { id, type, projectSettings, title } = data || {};
      const specType = parseSubType(
        projectSettings?.subType ?? projectSettings?.annotationType
      );
      handleRedirect(
        `/tasks/auditing/${type}/${id}?title=${title}&specType=${specType}`
      );
    },
    [handleRedirect]
  );

  const columnMemo = useMemo(
    () =>
      columnsHelper({
        onStartTasking,
        onStartAudit,
        isEnabled: userSessionData.enabled ?? true
      }),
    [onStartAudit, onStartTasking, userSessionData.enabled]
  );

  return <ProjectsTable columns={columnMemo} {...projectListProps} />;
};

export default ProjectsHelper;
