import React from 'react';
import { createRoot } from 'react-dom/client';
import App from 'containers/App';
import 'react-toastify/dist/ReactToastify.css';
import ApolloClientInit from 'config/apollo';
import { ApolloProvider } from '@apollo/client';
import { AppContextProvider } from 'containers/App';
import theme from 'theme';
import { ThemeProvider } from 'styled-components/macro';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import 'antd/es/style/default.css';
import history from 'utils/history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { varEnv } from 'utils/envVars';

const client = ApolloClientInit();

if (varEnv.isProd) {
  Sentry.init({
    dsn: 'https://738f96d39ab64fe6a867fb4a4b60ae7e@o4504875946147840.ingest.sentry.io/4504911660646400',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const elem = document.getElementById('root');

if (elem) {
  const root = createRoot(elem);

  root.render(
    <HistoryRouter history={history}>
      <ApolloProvider client={client}>
        <AppContextProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AppContextProvider>
      </ApolloProvider>
    </HistoryRouter>
  );
}
