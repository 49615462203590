import { Platform, Project } from 'generated/graphql';
import {
  WizardFormValues,
  GoogleDriveDataResult,
  GoogleDriveDataResultDoc
} from './AddProjectWizard.types';
import {
  AnnotationSpecificationType,
  ProjectSpecialization,
  ProjectType
} from 'appTypes';
import {
  getSpecializationByAnnotationType,
  ProjectImageSpecializationNotExist,
  ProjectVideoSpecializationNotExist
} from 'appConstants';
import {
  ADD_PROJECT_WIZARD_CONTEXT_STATE_INIT,
  WIZARD_FORM_INITIAL_VALUES
} from './AddProjectWizard.constants';
import { consoleError } from 'utils';
import parseSubType from 'utils/parseSubType';

function clearTypename(obj: any) {
  const { __typename, ...restProps } = obj;

  return restProps;
}

export function clearArrayOfObjsFromTypename(arrayOfObjs: any) {
  if (!arrayOfObjs || !Array.isArray(arrayOfObjs)) return arrayOfObjs;

  return arrayOfObjs.map((obj: any) => {
    if (typeof obj === 'string') return obj;

    let newObj = clearTypename(obj);

    Object.entries(newObj).forEach(([key, value]) => {
      if (value && Array.isArray(value)) {
        newObj[key] = clearArrayOfObjsFromTypename(value);
        return;
      }
      newObj[key] =
        value && typeof value === 'object' ? clearTypename(value) : value;
    });

    return newObj;
  });
}

export function mapGoogleDriveResponseToAnnotationMultipleFiles(
  data?: GoogleDriveDataResult
) {
  if (!data?.docs || !Array.isArray(data.docs)) {
    consoleError({ message: 'Google Drive results not valid', data });
    return [];
  }

  return data.docs.map((item: GoogleDriveDataResultDoc) => {
    return {
      id: item.id,
      type: item.type,
      name: item.name
    };
  });
}

export function mapProjectDataToWizard(
  projectData?: Partial<Project>
): WizardFormValues {
  const def = WIZARD_FORM_INITIAL_VALUES;

  const clearMl = clearArrayOfObjsFromTypename(
    projectData?.mlLabels?.multilabels ?? def.mlLabels?.multilabels ?? []
  );

  return {
    mlLabels: {
      multilabels: clearMl
    },
    helperRate: projectData?.helperRate ?? def.helperRate,
    auditorRate: projectData?.auditorRate ?? def.auditorRate,
    customLabels: projectData?.customLabels ?? def.customLabels,
    clientShortInstruction:
      projectData?.clientShortInstruction ?? def.clientShortInstruction,
    clientInstructionFileNames:
      projectData?.clientInstructionUrl ?? def.clientInstructionFileNames,
    projectSettings: {
      subType: (parseSubType(projectData?.projectSettings?.annotationType) ??
        def?.projectSettings?.annotationType) as AnnotationSpecificationType,
      annotationType:
        projectData?.projectSettings?.annotationType &&
        getSpecializationByAnnotationType(
          parseSubType(projectData?.projectSettings?.annotationType)
        ),
      chunkLength:
        projectData?.projectSettings?.chunkLength ??
        def?.projectSettings?.chunkLength
    },
    clientId: def.clientId,
    title: projectData?.title ?? def.title,
    adminShortInstruction:
      projectData?.adminShortInstruction ?? def.adminShortInstruction,
    adminInstructionFileName:
      projectData?.adminInstructionUrl ?? def.adminInstructionFileName,
    mediaFileName:
      (projectData?.mediaFileName as string[]) ?? def.mediaFileName,
    type: (projectData?.type ?? def.type) as ProjectType,
    labels: projectData?.labels ?? def.labels
  };
}

export function checkIsOnlyRequestCustomHelpers({
  type = ADD_PROJECT_WIZARD_CONTEXT_STATE_INIT.type,
  platform = Platform.Hosted,
  projectSpecialization
}: {
  type?: ProjectType;
  platform?: Platform;
  projectSpecialization?: ProjectSpecialization;
}) {
  const isVideoAnnotation = type === ProjectType.VIDEO_ANNOTATION;
  const isImageAnnotation = type === ProjectType.IMAGE_ANNOTATION;
  const isNlp = type === ProjectType.NLP;
  const ownPlatform = platform === Platform.Own;

  if (!projectSpecialization) return false;

  return (
    (isVideoAnnotation &&
      ProjectVideoSpecializationNotExist.includes(projectSpecialization)) ||
    (isImageAnnotation &&
      ProjectImageSpecializationNotExist.includes(projectSpecialization)) ||
    ownPlatform ||
    isNlp
  );
}
