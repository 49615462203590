import React from 'react';
import { useTheme } from 'styled-components/macro';
import 'react-toastify/dist/ReactToastify.css';
import { Box } from 'components/_main';
import { CloseIcon } from 'assets';

const ToastCloseButton = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      maxHeight="16px"
      padding="20px"
      marginLeft="20px"
      borderLeft={`1px solid ${theme.colors.lightest}`}
    >
      <CloseIcon
        css={`
          path {
            fill: ${theme.colors.light};
          }
        `}
      />
    </Box>
  );
};

export default ToastCloseButton;
