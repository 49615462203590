import React from 'react';
import { ErrorBoundaryState, ErrorBoundaryProps } from './types';
import { toast } from 'utils/toast';

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public static getDerivedStateFromError() {
    return { error: true };
  }

  public state: ErrorBoundaryState = {
    error: false
  };

  public render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      toast.error(JSON.stringify(error));
      return fallback;
    }

    return children;
  }
}
