import React, { MutableRefObject, useRef } from 'react';
import SvgLayer from './components/SvgLayer';
import { AnnotationSystemLayerProps } from './AnnotationSystem.types';
import { useAnnotationSystemState } from './context';
import { MediaLayerImage, MediaLayerVideo } from './components/MediaLayer';
import { LoadingSpinner, Root } from './AnnotationSystem.ui';
import Box from 'components/_main/Box';
import numberToPx from '../../utils/numberToPx';
import { Media } from './utils';
import useOnResultChange from './hooks/useOnResultChange';
import useAdjustSizeToWrapper from './hooks/useAdjustSiteToWrapper';
import { Z_INDEXES } from 'appConstants';
import useTouchEvent from 'containers/AnnotationSystem/hooks/useTouchEvent';

/**
 * Annotation System
 * -----------------
 */

export function AnnotationSystem({
  onMediaLoadSuccess,
  isAutoSetSizeToWrapper = true,
  onGetWrapperSize,
  wrapperRef,
  boxProps,
  withSvgLayer = true,
  adjustToHeight = true,
  ...propsForUpdatingTaskTriggering
}: AnnotationSystemLayerProps) {
  const mediaRef = useRef<Media | null>(null);
  const annotationRef = useRef<HTMLDivElement | null>(null);

  const { x, y, initH, initW, curZoomLevel, type, isInitMediaLoading } =
    useAnnotationSystemState();

  useOnResultChange();

  const { mediaUrl, results, taskId, videoEnd, videoStart, isViewOnly } =
    propsForUpdatingTaskTriggering;

  const { handleAdjustSizeToWrapper } = useAdjustSizeToWrapper({
    mediaRef,
    isAutoSetSizeToWrapper,
    onGetWrapperSize,
    onMediaLoadSuccess,
    wrapperRef,
    adjustToHeight,
    mediaUrl,
    results,
    videoStart,
    videoEnd,
    taskId,
    isViewOnly
  });
  useTouchEvent({ ref: annotationRef });

  const offsetWidth = annotationRef?.current?.offsetWidth ?? 0;
  const isFlexDisplay = initW * curZoomLevel < offsetWidth;

  return (
    <Root
      x={x}
      y={y}
      overflow="auto"
      {...boxProps}
      data-testid="AnnotationViewPort"
      id="AnnotationViewPort"
    >
      <Box
        ref={annotationRef}
        display={isFlexDisplay ? 'flex' : 'grid'}
        flexCenter={isFlexDisplay}
        data-testid="AnnotationSystemWrapper"
        zIndex="5"
      >
        {isInitMediaLoading && (
          <Box
            backgroundColor="#000"
            opacity={0.5}
            width="100%"
            height="100%"
            position="absolute"
            zIndex={Z_INDEXES.popover}
          >
            <LoadingSpinner />
          </Box>
        )}
        <Box
          id="AnnotationMediaLayerWrapper"
          position="relative"
          width={numberToPx(initW * curZoomLevel)}
          height={numberToPx(initH * curZoomLevel)}
        >
          {type === 'video' ? (
            <MediaLayerVideo
              ref={mediaRef as MutableRefObject<HTMLVideoElement>}
              mediaUrl={mediaUrl}
              // @ts-ignore
              onLoadMetadata={handleAdjustSizeToWrapper}
            />
          ) : (
            <MediaLayerImage
              mediaUrl={mediaUrl}
              ref={mediaRef as MutableRefObject<HTMLImageElement>}
              // @ts-ignore
              onLoad={handleAdjustSizeToWrapper}
            />
          )}
          {withSvgLayer && <SvgLayer />}
        </Box>
      </Box>
    </Root>
  );
}

export default AnnotationSystem;
