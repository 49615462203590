const getKeyForRoute = (
  path?: string | string[],
  idx: number = Math.random()
) => {
  if (!path) {
    return idx;
  }

  if (typeof path === 'string') {
    return `${path}_${idx}`;
  }

  const key = path.reduce<string>((init: string, item: string, idx: number) => {
    return idx === 0 ? item : init + item;
  }, '');

  return `${key}_${idx}`;
};

export default getKeyForRoute;
