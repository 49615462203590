import React, { PropsWithChildren, useMemo, useReducer } from 'react';
import reducer, { onInit, ReducerActionTypes, REDUCER_INIT } from '../reducer';
import context from './context';
import {
  AnnotationSystemContextProps,
  AnnotationSystemProps
} from '../AnnotationSystem.types';

export default function ContextProvider({
  children,
  ...restProps
}: PropsWithChildren<AnnotationSystemContextProps>) {
  const [state, dispatch] = useReducer(reducer, {
    ...REDUCER_INIT,
    ...restProps
  });

  const value: [AnnotationSystemProps, React.Dispatch<ReducerActionTypes>] =
    useMemo(() => [state, dispatch], [state]);

  return <context.Provider value={value}>{children}</context.Provider>;
}
