import { useCallback, useEffect, useState } from 'react';
import { consoleError } from 'utils';

interface VideoRefProps {
  videoRef: HTMLVideoElement | null;
  onTimeChange?: (newTime: number, isPlaying?: boolean) => void;
  onPlay?: () => void;
  onStop?: () => void;
  onEndReached?: () => void;
  onCanPlayThrough?: () => void;
  onMetadataLoaded?: (metadata?: HTMLVideoElement) => void;
  min?: number;
  max?: number;
}

export default function useVideo({
  videoRef,
  onTimeChange,
  onEndReached,
  onCanPlayThrough,
  onMetadataLoaded,
  min = 0,
  max
}: VideoRefProps) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayToggle = useCallback(
    (isPlay: boolean) => {
      const vid = videoRef;
      if (!vid) return;

      if (isPlay === false) {
        setIsPlaying(() => {
          vid.pause();
          return false;
        });
        return;
      }

      vid.play();
      setIsPlaying(true);
    },
    [videoRef]
  );

  const handleSetCurTime = useCallback(
    (newTime: number) => {
      const ref = videoRef;
      if (!ref || ref?.currentTime === undefined) {
        consoleError(ref);
        return;
      }

      ref.currentTime = newTime;
    },
    [videoRef]
  );

  const handleTimeChange = useCallback(
    (e: React.ChangeEvent<HTMLVideoElement>) => {
      const curRef = e.target;
      if (!curRef) return;

      const curTime = e.target.currentTime;

      if (curTime >= (max ?? curRef.duration) || curTime < min) {
        if (curRef?.currentTime) curRef.currentTime = min;
        onTimeChange && onTimeChange(min, isPlaying);
        handlePlayToggle(false);
        return;
      }

      onTimeChange && onTimeChange(curTime);
    },
    [handlePlayToggle, isPlaying, max, min, onTimeChange]
  );

  const handlePlay = useCallback(
    (e?: any) => {
      handlePlayToggle(true);
    },
    [handlePlayToggle]
  );

  const handleStop = useCallback(() => {
    handlePlayToggle(false);
  }, [handlePlayToggle]);

  const handleEndReached = useCallback(
    (e?: any) => {
      handlePlayToggle(false);
      onEndReached && onEndReached();
    },
    [handlePlayToggle, onEndReached]
  );

  const handleCanPlayThrough = useCallback(() => {
    onCanPlayThrough && onCanPlayThrough();
  }, [onCanPlayThrough]);

  const handleMetadataLoaded = useCallback(() => {
    onMetadataLoaded && onMetadataLoaded();
  }, [onMetadataLoaded]);

  useEffect(() => {
    const ref = videoRef;
    if (!ref) return;

    // @ts-ignore
    ref.addEventListener('timeupdate', handleTimeChange);
    ref.addEventListener('play', handlePlay);
    ref.addEventListener('ended', handleEndReached);
    ref.addEventListener('canplaythrough', handleCanPlayThrough);
    ref.addEventListener('loadedmetadata', handleMetadataLoaded);

    return () => {
      // @ts-ignore
      ref.removeEventListener('timeupdate', handleTimeChange);
      ref.removeEventListener('play', handlePlay);
      ref.removeEventListener('ended', handleEndReached);
      ref.removeEventListener('canplaythrough', handleCanPlayThrough);
      ref.removeEventListener('loadedmetadata', handleMetadataLoaded);
    };
  }, [
    handleCanPlayThrough,
    handleEndReached,
    handleMetadataLoaded,
    handlePlay,
    handleTimeChange,
    videoRef
  ]);

  return {
    isPlaying,
    onStop: handleStop,
    onPlay: handlePlay,
    onPlayToggle: handlePlayToggle,
    onSetCurTime: handleSetCurTime
  };
}
