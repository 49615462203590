import styled, { css } from 'styled-components/macro';
import { AnnotationUnitType } from '../../AnnotationSystem.types';

interface RootProps {
  isActive: boolean;
  color: string;
  specType?: AnnotationUnitType;
  isCreate?: boolean;
}

interface MainBorderRectProps {
  isActive: boolean;
  color: string;
}

export const Root = styled.svg(
  ({ isActive, isCreate, color }: RootProps) => css`
    ${isActive &&
    css`
      overflow: visible;
      position: relative;
      z-index: 1;
    `}

    &[data-is-end='true'] {
      opacity: 0.5;
    }

    ${isCreate &&
    css`
      pointer-events: none;
    `}

    &.hover, &:hover {
      ${MainBorderRect} {
        stroke: ${color};
      }
    }
  `
);

export const MainBorderRect = styled.rect(
  ({ isActive, color }: MainBorderRectProps) => css`
    fill: transparent;
    stroke-width: 3;

    ${isActive &&
    css`
      stroke: ${color};
      position: relative;
      z-index: 1;
      fill: ${color}30;
      cursor: grab;
    `}
  `
);
