import React from 'react';
import { WithFastField } from 'components/_form';
import { Tab, Tabs } from 'components/Tabs/Tabs.styled';
import { Project, TaskType } from 'generated/graphql';
import General from './tabs/General';
import { ProjectTabType } from 'containers/ProjectDetailsCard/constants';
import FinishedTasksPreviewDataProvider from './tabs/FinishedTasksPreview/FinishedTasksPreviewDataProvider';
import InviteHelpersTab from './tabs/InviteHelpersTab/InviteHelpresTab';
import { Row } from 'components';

type TProjectEdit = {
  projectData: Project;
};

const ProjectDetailsCardModalContentTabsClient = ({
  projectData
}: TProjectEdit) => {
  if (projectData?.type === TaskType.Nlp) {
    return (
      <Row padding="30px 0">
        <General projectData={projectData} />
      </Row>
    );
  }

  return (
    <Tabs
      mb="30px"
      defaultActiveKey={ProjectTabType.General}
      destroyInactiveTabPane
      style={{
        padding: '0',
        height: '100%'
      }}
    >
      <Tab data-testid="tab-general" tab="General" key={ProjectTabType.General}>
        <WithFastField>
          <General projectData={projectData} />
        </WithFastField>
      </Tab>
      <Tab
        data-testid="tab-invite-helpers"
        tab="Helpers"
        key={ProjectTabType.InviteHelpers}
      >
        <WithFastField>
          <InviteHelpersTab
            projectId={projectData.id}
            projectData={projectData}
          />
        </WithFastField>
      </Tab>
      <Tab data-testid="tab-results" tab="Results" key={ProjectTabType.Results}>
        <WithFastField>
          <FinishedTasksPreviewDataProvider projectData={projectData} />
        </WithFastField>
      </Tab>
    </Tabs>
  );
};

export default ProjectDetailsCardModalContentTabsClient;
