import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import cx from 'classnames';
import { ModalFormProps } from './ModalForm.types';
import {
  Content,
  Body,
  FooterStyled,
  ConfirmContent,
  ConfirmButtons,
  ConfirmTitle
} from '../ui';
import { checkFieldsIsValidate } from '../utils';
import messages from '../messages';
import {
  MODAL_FORM_FALLBACK_CLASS,
  MODAL_FORM_FALLBACK_NAME
} from '../constants';
import { Button } from 'components/_main';
import SubmitButton from './SubmitButton';

const ModalForm = <InitValues extends object>({
  title,
  isVisible,
  onCancel,
  onOk,
  initialValues,
  form,
  children,
  className,
  name,
  loading,
  layout = 'inline',
  okText = 'Save',
  cancelText = 'Cancel',
  withSubmitConfirm,
  formProps,
  modalProps
}: React.PropsWithChildren<ModalFormProps<InitValues>>) => {
  const [isConfirmModalShow, setIsConfirmModalShow] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleConfirmSave = () => {
    form.submit();
    setIsConfirmModalShow(false);
  };

  const handleConfirmCancel = () => {
    setIsConfirmModalShow(false);
  };

  const handleConfirmOpen = async () => {
    if (await checkFieldsIsValidate(form)) {
      setIsConfirmModalShow(true);
    }
  };

  return (
    <Modal
      destroyOnClose
      title={title}
      visible={isVisible}
      footer={false}
      onCancel={onCancel}
      centered={true}
      {...modalProps}
    >
      <Form
        layout={layout}
        form={form}
        name={name ?? MODAL_FORM_FALLBACK_NAME}
        className={cx(className, MODAL_FORM_FALLBACK_CLASS)}
        initialValues={initialValues}
        onFinish={onOk}
        {...formProps}
      >
        <Body>
          <Content>{children}</Content>
          <FooterStyled>
            <Button variant="secondary" onClick={onCancel}>
              {cancelText}
            </Button>
            <SubmitButton
              onConfirmOpen={handleConfirmOpen}
              okText={okText}
              loading={loading}
              withSubmitConfirm={withSubmitConfirm}
            />
            <Form.Item
              shouldUpdate={true}
              style={{ margin: 0, padding: 0 }}
            ></Form.Item>
          </FooterStyled>
        </Body>
        {withSubmitConfirm && (
          <Modal
            centered={true}
            title={<ConfirmTitle>{messages.confirmTitle}</ConfirmTitle>}
            visible={isConfirmModalShow}
            footer={false}
            onCancel={handleConfirmCancel}
          >
            <Body>
              <ConfirmContent>
                <ConfirmButtons>
                  <Button variant="primary" onClick={handleConfirmSave}>
                    {messages.confirmSave}
                  </Button>
                  <Button onClick={handleConfirmCancel}>
                    {messages.confirmCancel}
                  </Button>
                </ConfirmButtons>
              </ConfirmContent>
            </Body>
          </Modal>
        )}
      </Form>
    </Modal>
  );
};

export default ModalForm;
