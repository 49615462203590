import { LessonPracticeResultProps } from 'containers/LearningMaterials/LearningMaterial.types';

export default function getPracticeResultData(
  result: number
): LessonPracticeResultProps {
  let practiceResultInfo = {
    title: 'Try again 😳',
    status: 'WEAK',
    desc: "We understand that your test result indicates that you have not fully completed the theoretical portion. We suggest starting over from the beginning and retaking the test to ensure that you have a better understanding of the material. Please don't hesitate to ask for assistance or additional resources if needed. Good luck on your next attempt!",
    textColor: 'light',
    bgColor: 'lightest',
    cancelText: 'back to lesson'
  };

  if (result > 80 && result < 92) {
    practiceResultInfo = {
      title: 'Good 🙂',
      status: 'GOOD',
      desc: "Great job on your good result! Your hard work and dedication have already led you to success, and with continued effort, you have the potential to achieve even greater results. Keep pushing yourself to reach your full potential, and I have no doubt that you'll achieve outstanding success in the future projects!",
      textColor: 'orange',
      bgColor: 'orangeLighter',
      cancelText: 'later'
    };
  } else if (result >= 92) {
    practiceResultInfo = {
      title: 'Great! 🤩',
      status: 'GREAT',
      desc: 'Congratulations! You have achieved a great result. Your exceptional effort and commitment have paid off, and you should be immensely proud of your outstanding achievement. Keep up the excellent work!',
      textColor: 'greenLight',
      bgColor: 'greenLighter',
      cancelText: ''
    };
  }

  return practiceResultInfo;
}
