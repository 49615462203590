import { AnnotationSystemEventsEnum } from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import { MouseEvent, useCallback } from 'react';

interface Props {
  unitId: string;
}

export default function useRectMouseMove({ unitId }: Props) {
  const { curEvent } = useAnnotationSystemState();
  const { onSetCurEvent } = useAnnotationSystemActions();
  const { isUnitSel } = useStatus({
    unitId
  });

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      switch (curEvent) {
        case AnnotationSystemEventsEnum.SELECTED_MODE:
          if (
            e.buttons === 1 &&
            (e.movementX !== 0 || e.movementY !== 0) &&
            isUnitSel
          ) {
            onSetCurEvent(AnnotationSystemEventsEnum.DRAG_MODE);
          }
          break;
        default:
          break;
      }
    },
    [curEvent, isUnitSel, onSetCurEvent]
  );

  return handleMouseMove;
}
