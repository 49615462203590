import {
  AnnotationUnitProps,
  AnnotationSystemEvents,
  AnnotationSystemProps,
  VideoStatus,
  AnnotationUnits,
  AnnotationSystemModesEnum,
  ResizePosition,
  TimelineTransition
} from '../AnnotationSystem.types';

export enum ReducerActions {
  'INIT' = 'INIT',
  'RESET' = 'RESET',
  'SET_H' = 'SET_H',
  'SET_W' = 'SET_W',
  'SET_X' = 'SET_X',
  'SET_Y' = 'SET_Y',
  'ADD_ANNOTATION_UNIT' = 'ADD_ANNOTATION_UNIT',
  'MODIFY_ANNOTATION_UNIT' = 'MODIFY_ANNOTATION_UNIT',
  'DELETE_ANNOTATION_UNIT' = 'DELETE_ANNOTATION_UNIT',
  'INIT_ANNOTATION_UNITS' = 'INIT_ANNOTATION_UNIT',
  'SET_ZOOM_LEVEL' = 'SET_ZOOM_LEVEL',
  'ZOOM_IN' = 'ZOOM_IN',
  'ZOOM_OUT' = 'ZOOM_OUT',
  'ZOOM_RESET' = 'ZOOM_RESET',
  'SET_CUR_EVENT' = 'SET_CUR_EVENT',
  'SET_INIT_SIZE' = 'SET_INIT_SIZE',
  'SET_CUR_SEL_UNIT_ID' = 'SET_CUR_SEL_UNIT_ID',
  'SET_CUR_SEL_POINT_ID' = 'SET_CUR_SEL_POINT_ID', // polygon point or dot
  'SET_HIDE_ANNOTATION_UNIT_ID' = 'SET_HIDE_ANNOTATION_UNIT_ID',
  'SET_SVG_LAYER_REF' = 'SET_SVG_LAYER_REF',
  'SET_MEDIA_IMAGE_REF' = 'SET_MEDIA_IMAGE_REF',
  'SET_MEDIA_VIDEO_REF' = 'SET_MEDIA_VIDEO_REF',
  'SET_VIEWPORT_SIZE' = 'SET_VIEWPORT_SIZE',
  'SET_CUR_HOVER_UNIT_ID' = 'SET_CUR_HOVER_UNIT_ID',
  'SET_CUR_VIDEO_TIME' = 'SET_CUR_VIDEO_TIME',
  'SET_CUR_VIDEO_STATUS' = 'SET_CUR_VIDEO_STATUS',
  'SET_CURSOR_INIT_POS' = 'SET_CURSOR_INIT_POS',
  'SET_MEDIA_LOADING_FINISH' = 'SET_MEDIA_LOADING_FINISH',
  'DELETE_TRANSITION_POINT' = 'DELETE_TIMELINE_POINT',
  'ADD_TIMELINE_POINT' = 'ADD_TIMELINE_POINT',
  'MODIFY_TRANSITION_POINT' = 'MODIFY_TRANSITION_POINT',
  'SET_SVG_LAYER_OPACITY_LEVEL' = 'SET_SVG_LAYER_OPACITY_LEVEL',
  'SET_CUR_MODE' = 'SET_CUR_MODE',
  'SET_EVENT_RESIZE' = 'SET_EVENT_RESIZE',
  'SET_IS_LOADING' = 'SET_IS_LOADING',
  'SET_ANNOTATION_ACCURACY' = 'SET_ANNOTATION_ACCURACY',
  'SET_ANNOTATION_PRACTICE_PROGRESS' = 'SET_ANNOTATION_PRACTICE_PROGRESS',
  'SET_PAGE_FRAMES' = 'SET_PAGE_FRAMES',
  'SET_COPY_UNIT_ID' = 'SET_COPY_UNIT_ID',
  'SET_OFFSET_ANNOTATION_UNITS' = 'SET_OFFSET_ANNOTATION_UNITS',
  'ADD_POLYGON_POINT' = 'ADD_POLYGON_POINT',
  'DELETE_POLYGON_POINT' = 'DELETE_POLYGON_POINT',
  'MODIFY_POLYGON_POINT' = 'MODIFY_POLYGON_POINT',
  'ADD_AT_STROKE_POLYGON_POINT' = 'ADD_AT_STROKE_POLYGON_POINT',
  'SET_POLYGON_POINTS' = 'SET_POLYGON_POINTS',
  'TOGGLE_LABEL_SELECT_DROPDOWN' = 'TOGGLE_LABEL_SELECT_DROPDOWN'
}

export interface SetCursorInitPos {
  payload: { x: number; y: number };
  type: ReducerActions.SET_CURSOR_INIT_POS;
}

export interface SetCurVideoStatus {
  payload: VideoStatus;
  type: ReducerActions.SET_CUR_VIDEO_STATUS;
}

export interface SetCurVideoTime {
  payload: number;
  type: ReducerActions.SET_CUR_VIDEO_TIME;
}

export interface SetMediaVideoRef {
  payload: HTMLVideoElement | null;
  type: ReducerActions.SET_MEDIA_VIDEO_REF;
}

export interface SetMediaLoadingFinish {
  payload: {
    ref: any | null;
    w: number;
    h: number;
    initW: number;
    initH: number;
  } & Pick<
    AnnotationSystemProps,
    'mediaUrl' | 'videoStart' | 'videoEnd' | 'taskId' | 'results' | 'isViewOnly'
  >;
  type: ReducerActions.SET_MEDIA_LOADING_FINISH;
}

export interface SetMediaImageRef {
  payload: HTMLImageElement | null;
  type: ReducerActions.SET_MEDIA_IMAGE_REF;
}

export interface SetSvgLayerRef {
  payload: SVGSVGElement | null;
  type: ReducerActions.SET_SVG_LAYER_REF;
}

export interface SetCurEvent {
  payload: AnnotationSystemEvents;
  type: ReducerActions.SET_CUR_EVENT;
}

export interface SetH {
  payload: number;
  type: ReducerActions.SET_H;
}

export interface SetW {
  payload: number;
  type: ReducerActions.SET_W;
}

export interface SetX {
  payload: number;
  type: ReducerActions.SET_X;
}

export interface SetY {
  payload: number;
  type: ReducerActions.SET_Y;
}

export interface AddAnnotationUnit {
  payload: AnnotationUnitProps;
  type: ReducerActions.ADD_ANNOTATION_UNIT;
}

export interface ModifyAnnotationUnit {
  payload: Partial<AnnotationUnitProps>;
  type: ReducerActions.MODIFY_ANNOTATION_UNIT;
}

export interface DeleteAnnotationUnit {
  payload: string;
  type: ReducerActions.DELETE_ANNOTATION_UNIT;
}

export interface DeleteTransitionPoint {
  payload: {
    unitId: string;
    timelineTimestamp: number;
  };
  type: ReducerActions.DELETE_TRANSITION_POINT;
}

export interface ModifyTransitionPoint {
  payload: {
    unitId: string;
    timelineTimestamp: number;
    content: Partial<TimelineTransition>;
  };
  type: ReducerActions.MODIFY_TRANSITION_POINT;
}

export interface InitAnnotationUnits {
  payload: AnnotationUnits;
  type: ReducerActions.INIT_ANNOTATION_UNITS;
}

export interface ZoomIn {
  payload: null;
  type: ReducerActions.ZOOM_IN;
}

export interface ZoomOut {
  payload: null;
  type: ReducerActions.ZOOM_OUT;
}

export interface SetZoomLevel {
  payload: number;
  type: ReducerActions.SET_ZOOM_LEVEL;
}

export interface ZoomReset {
  payload: null;
  type: ReducerActions.ZOOM_RESET;
}

export interface SetInitSize {
  payload: {
    w: number;
    h: number;
  };
  type: ReducerActions.SET_INIT_SIZE;
}

export interface SetViewportSize {
  payload: {
    w?: number;
    h?: number;
  };
  type: ReducerActions.SET_VIEWPORT_SIZE;
}

export interface SetCurSelUnitId {
  payload: string | null;
  type: ReducerActions.SET_CUR_SEL_UNIT_ID;
}

export interface SetCurSelPointId {
  payload: number | string | null;
  type: ReducerActions.SET_CUR_SEL_POINT_ID;
}

export interface SetHideAnnotationUnitId {
  payload: string[];
  type: ReducerActions.SET_HIDE_ANNOTATION_UNIT_ID;
}

export interface SetSvgLayerOpacityLevel {
  payload: number;
  type: ReducerActions.SET_SVG_LAYER_OPACITY_LEVEL;
}

export interface SetCurHoverUnitId {
  payload: string | null;
  type: ReducerActions.SET_CUR_HOVER_UNIT_ID;
}

export interface SetCurMode {
  payload: AnnotationSystemModesEnum;
  type: ReducerActions.SET_CUR_MODE;
}

export interface SetEventResize {
  payload: ResizePosition;
  type: ReducerActions.SET_EVENT_RESIZE;
}

export interface SetEventResize {
  payload: ResizePosition;
  type: ReducerActions.SET_EVENT_RESIZE;
}

export interface OnReset {
  payload: null;
  type: ReducerActions.RESET;
}

export interface SetIsLoading {
  payload: boolean;
  type: ReducerActions.SET_IS_LOADING;
}

export interface SetAnnotationAccuracy {
  payload: number;
  type: ReducerActions.SET_ANNOTATION_ACCURACY;
}

export interface SetAnnotationPracticeProgress {
  payload: number;
  type: ReducerActions.SET_ANNOTATION_PRACTICE_PROGRESS;
}

export interface OnInit {
  payload: Partial<AnnotationSystemProps>;
  type: ReducerActions.INIT;
}

export interface SetPageFrames {
  payload: number;
  type: ReducerActions.SET_PAGE_FRAMES;
}

export interface SetCopyUnitId {
  payload: null;
  type: ReducerActions.SET_COPY_UNIT_ID;
}

export interface SetOffsetAnnotationUnits {
  payload: number;
  type: ReducerActions.SET_OFFSET_ANNOTATION_UNITS;
}

export interface AddPolygonPoint {
  payload: [number, number];
  type: ReducerActions.ADD_POLYGON_POINT;
}
export interface AddAtStrokePolygonPoint {
  payload: [number, number];
  type: ReducerActions.ADD_AT_STROKE_POLYGON_POINT;
}

export interface DeletePolygonPoint {
  payload: number; // polygon point index
  type: ReducerActions.DELETE_POLYGON_POINT;
}

export interface ModifyPolygonPoint {
  payload: {
    index: number;
    newPosition: [number, number];
  };
  type: ReducerActions.MODIFY_POLYGON_POINT;
}

export interface SetPolygonPoints {
  payload: {
    unitId: string;
    newPolyPoints: PolygonPoints;
  };
  type: ReducerActions.SET_POLYGON_POINTS;
}

export interface ToggleLabelSelectDropdown {
  payload: null;
  type: ReducerActions.TOGGLE_LABEL_SELECT_DROPDOWN;
}

export type PolygonPoint = [number, number];
export type PolygonPoints = PolygonPoint[];

export type ReducerActionTypes =
  | SetH
  | SetX
  | SetW
  | SetY
  | AddAnnotationUnit
  | DeleteAnnotationUnit
  | ZoomIn
  | ZoomOut
  | ZoomReset
  | SetZoomLevel
  | SetCurEvent
  | SetInitSize
  | SetCurSelUnitId
  | SetHideAnnotationUnitId
  | SetSvgLayerRef
  | SetMediaImageRef
  | SetMediaVideoRef
  | SetMediaLoadingFinish
  | SetViewportSize
  | SetCurHoverUnitId
  | SetCurVideoTime
  | SetCurVideoStatus
  | SetSvgLayerOpacityLevel
  | ModifyAnnotationUnit
  | SetCursorInitPos
  | SetCurMode
  | OnReset
  | OnInit
  | InitAnnotationUnits
  | SetEventResize
  | DeleteTransitionPoint
  | ModifyTransitionPoint
  | SetIsLoading
  | SetAnnotationAccuracy
  | SetAnnotationPracticeProgress
  | SetPageFrames
  | SetCopyUnitId
  | SetOffsetAnnotationUnits
  | AddPolygonPoint
  | ModifyPolygonPoint
  | DeletePolygonPoint
  | SetCurSelPointId
  | AddAtStrokePolygonPoint
  | SetPolygonPoints
  | ToggleLabelSelectDropdown;

export type Actions<T extends ReducerActionTypes> = (payload: T['payload']) => {
  type: T['type'];
} & T;
