import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Row, Box, Text } from 'components/_main';
import { RobotAvatarPlaceholderImage } from 'assets';

function Certification() {
  const theme = useTheme();

  return (
    <Box display="grid" height="100%" css="place-items: center">
      <Row displayFlex width="max-content" justifyItems="center">
        <Box
          width="175px"
          height="175px"
          borderRadius="50%"
          backgroundColor={theme.colors.lightest}
        >
          <RobotAvatarPlaceholderImage width="100%" height="100%" />
        </Box>
        <Text variant="header-2">Coming Soon</Text>
      </Row>
    </Box>
  );
}

export default Certification;
