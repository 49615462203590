import React from 'react';
import { Box } from 'components/_main';
import { BoxProps } from 'components/_main/Box';
import { Slider } from '../VideoPlayer.ui';
import { VIDEO_STEP } from 'containers/AnnotationSystem/AnnotationSystem.constants';
import { roundTimestampsToMs } from 'containers/AnnotationSystem/reducer/utils';

interface ProgressBarProps {
  step?: number;
  min?: number | null;
  max?: number | null;
  onChange?: (time: number) => void;
  boxProps?: Partial<BoxProps>;
  hideControl?: boolean;
  disableClick?: boolean;
  onStop?(): void;
  onSetCurTime?(time: number): void;
  curVidTime: number;
}

export default function ProgressBar({
  min = 0,
  max,
  onChange,
  step = VIDEO_STEP,
  disableClick,
  onSetCurTime,
  onStop,
  curVidTime
}: ProgressBarProps) {
  const handleProgressBarChange = (time: number) => {
    const value = roundTimestampsToMs(time);

    onStop && onStop();

    if (value === undefined) return;
    onSetCurTime && onSetCurTime(value);
    onChange && onChange(value);
  };

  if (max === null || min === undefined || min === null) {
    console.info('Max time, min time or vidRef.current are not defined');
    console.info({ max, min });
    return null;
  }

  return (
    <Box width="100%">
      <Slider
        disableClick={disableClick}
        step={roundTimestampsToMs(step)}
        value={roundTimestampsToMs(curVidTime)}
        min={min}
        max={max}
        onChange={handleProgressBarChange}
      />
    </Box>
  );
}
