import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useFormikContext } from 'formik';
import { Project, ProjectStatus } from 'generated/graphql';
import useGetProjectById from '../../hooks/useGetProjectById';
import { ProjectDetailsCardDataProviderProps } from './types';
import {
  isChangedStatus,
  isChangedFiles,
  isChangedInfo,
  isChangedRate,
  isChangedAssignedHelper,
  isChangedAssignedAuditor
} from 'containers/ProjectDetailsCard/utils';
import { isAdmin } from 'utils';

interface ClientModal {
  data?: Project['client'] | null;
  isOpen: boolean;
}

type TCtx = {
  loading?: boolean;
  isEditable?: boolean;
  isReadOnly?: boolean;
  projectData?: Project | null;
  clientModal: ClientModal;
  isFromClientCard?: boolean;
  isSomethingChanged: boolean;
  setIsSomethingChanged: any;
  setModalTitleRenderComp: any;
  modalTitleRenderComp: React.ReactElement | null;
  isMediaUploading: boolean;
  isMediaEmpty: boolean;
  setClientModalClose: () => void;
  setIsMediaUploading: (isUploading: boolean) => void;
  setIsMediaEmpty: (isEmpty: boolean) => void;
  setClientModal: (clientModalData: ClientModal) => void;
};

const CTX_INIT_VALUES: TCtx = {
  projectData: null,
  clientModal: {
    data: null,
    isOpen: false
  },
  isSomethingChanged: false,
  isMediaUploading: false,
  isMediaEmpty: false,
  setIsSomethingChanged: () => null,
  setClientModalClose: () => null,
  modalTitleRenderComp: <>test1</>,
  setModalTitleRenderComp: () => null,
  setIsMediaUploading: () => null,
  setIsMediaEmpty: () => null,
  setClientModal: () => null
};
export const ctx = createContext<TCtx>(CTX_INIT_VALUES);

export function useCtx() {
  return useContext(ctx);
}

export function useCtxSelectIsChanged() {
  const { values, initialValues } = useFormikContext();
  const { isSomethingChanged, setIsSomethingChanged } = useContext(ctx);

  useEffect(() => {
    const newIsChanges =
      isChangedStatus(initialValues, values) ||
      isChangedInfo(initialValues, values) ||
      isChangedRate(initialValues, values) ||
      isChangedFiles(initialValues, values) ||
      isChangedAssignedHelper(initialValues, values) ||
      isChangedAssignedAuditor(initialValues, values);
    setIsSomethingChanged(newIsChanges);
  }, [initialValues, setIsSomethingChanged, values]);

  return isSomethingChanged;
}

export function CtxProvider({
  children,
  projectId,
  ...restProps
}: React.PropsWithChildren<ProjectDetailsCardDataProviderProps>) {
  const { data, loading } = useGetProjectById({
    projectId
  });

  const isEditable =
    isAdmin() &&
    (data?.status === ProjectStatus.Draft ||
      data?.status === ProjectStatus.Unreviewed);
  const isReadOnly =
    data?.status === ProjectStatus.Completed ||
    data?.status === ProjectStatus.Archived;

  const [ctxValues, setCtxValues] = useState<TCtx>({
    ...CTX_INIT_VALUES,
    isSomethingChanged: false,
    modalTitleRenderComp: <>zxc</>,
    ...restProps
  });

  const handleSetIsSomethingChanged = React.useCallback(
    (isChanged: boolean) => {
      setCtxValues((prevValues: any) => ({
        ...prevValues,
        isSomethingChanged: isChanged
      }));
    },
    []
  );

  const setIsMediaUploading = React.useCallback((isMediaUploading: boolean) => {
    setCtxValues((prevValues: any) => ({
      ...prevValues,
      isMediaUploading
    }));
  }, []);

  const setIsMediaEmpty = React.useCallback((isMediaEmpty: boolean) => {
    setCtxValues((prevValues: any) => ({
      ...prevValues,
      isMediaEmpty
    }));
  }, []);

  const handleSetModalTitleRenderComp = React.useCallback(
    (newTitleComp: React.ReactElement) => {
      setCtxValues((prevValues: any) => ({
        ...prevValues,
        modalTitleRenderComp: newTitleComp
      }));
    },
    []
  );

  const setClientModal = React.useCallback(
    (clientModalData: Partial<ClientModal>) => {
      setCtxValues((prevValues: any) => ({
        ...prevValues,
        clientModal: {
          ...prevValues.clientModal,
          ...clientModalData
        }
      }));
    },
    []
  );

  const setClientModalClose = useCallback(() => {
    setClientModal({ isOpen: false });
  }, [setClientModal]);

  const finalCtxValues: TCtx = useMemo(
    (): TCtx => ({
      ...ctxValues,
      projectData: data,
      loading,
      isEditable,
      isReadOnly,
      setClientModalClose: setClientModalClose,
      setClientModal: setClientModal,
      setIsMediaEmpty: setIsMediaEmpty,
      setIsMediaUploading: setIsMediaUploading,
      setIsSomethingChanged: handleSetIsSomethingChanged,
      setModalTitleRenderComp: handleSetModalTitleRenderComp
    }),
    [
      isEditable,
      isReadOnly,
      ctxValues,
      data,
      loading,
      setClientModalClose,
      setClientModal,
      setIsMediaEmpty,
      setIsMediaUploading,
      handleSetIsSomethingChanged,
      handleSetModalTitleRenderComp
    ]
  );

  return <ctx.Provider value={finalCtxValues}>{children}</ctx.Provider>;
}
