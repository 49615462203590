export default function PrivacyPolicy() {
  return (
    <div className="page-center">
      <div className="privacy-policy-outer">
        <div className="privacy-policy-heading">
          <h1 style={{ textTransform: 'uppercase' }}>Privacy Policy</h1>
          <p>
            <strong>Last updated: July 28th, 2021</strong>
          </p>
        </div>
        <div className="privacy-policy-cont-outer">
          <div className="privacy-policy-main-text">
            <p>
              <strong>Helpware Inc.</strong> is committed to protecting your
              privacy. This privacy policy applies to those who visit
              Helpware.com and subdomains of this domain (“Site” or collectively
              “Sites”), as well as users of Helpware products and services
              (“Service” or collectively “Services”) which is owned and operated
              by Helpware Inc. ("Helpware", “We”, “Us” or “Our”). This privacy
              policy describes how Helpware collects, uses and shares the
              personal information you provide to us. It also describes the
              choices available to you regarding the use of, your access to, and
              how to update and correct your personal information. The use of
              information collected through our products and services shall be
              limited to the purpose of providing the products and services for
              which our customer has contracted with Helpware.
            </p>
          </div>
          <div className="privacy-policy-toggle-cont">
            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>What information do we collect?</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  <strong>Personal Information.</strong>
                </p>
                <p>
                  Helpware may collect personally identifiable information from
                  you such as names, addresses, telephone numbers, physical
                  addresses, email addresses, CVs (in the case of candidates),
                  and, if applicable, company names, addresses, telephone
                  numbers, fax numbers, physical addresses, email addresses,
                  credit card numbers or tax ID numbers as well as similar
                  information concerning technical contacts, marketing contacts,
                  and executive contacts within your company or organization
                  (collectively, “Personal Information”).
                </p>
                <p>
                  <strong>Non-Personal Information.</strong>
                </p>
                <p>
                  Helpware may collect non-personally identifiable information
                  from you such as the type of browser you use, your operating
                  system, the screen resolution of your browser, your ISP, your
                  IP address, which pages you view on the Site and the time and
                  duration of your visits to the Site (collectively,
                  “Non-Personal Information”). Helpware associates Non-Personal
                  Information with Personal Information if you register with the
                  Site.
                </p>
                <p>
                  <strong>User Communications.</strong>
                </p>
                <p>
                  If you communicate with us, we may collect information
                  relating to that communication whether it takes the form of
                  email, fax, letter, forum posting, testimonials or any other
                  form of communication between you and Helpware or Submitted by
                  you to the Site (collectively, “User Communications”).
                </p>
                <p>
                  <strong>What do we use your information for?</strong>
                </p>
                <p>
                  Any of the information we collect from you may be used in one
                  of the following ways:
                </p>
                <p>
                  <strong>To improve customer service</strong>
                </p>
                <p>
                  In the context of customer support, your information helps us
                  to more effectively respond to your customer service requests.
                </p>
                <p>
                  <strong>To process transactions</strong>
                </p>
                <p>
                  Your information, whether public or private, will not be sold,
                  exchanged, transferred, or given to any other company for any
                  reason whatsoever, other than for the express purpose of
                  delivering the purchased product or service requested.
                </p>
                <p>
                  <strong>To send periodic emails</strong>
                </p>
                <p>
                  The email address you provide in the application may be used
                  to send you information and updates pertaining to your usage,
                  in addition to receiving occasional company news, updates,
                  related product or service information, etc.
                </p>
                <p>
                  <strong>To carry out the hiring process</strong>
                </p>
                <p>
                  We use identification and contact details, job performance
                  data, and data on your suitability for the purpose of carrying
                  out the selection process, including screening of candidates.
                  After that personal data is processed under the legal title of
                  performance of the contract, implementation of pre-contract
                  arrangements, i.e. preparation of an employment contract or
                  agreement. We keep your personal information for the duration
                  of the recruitment process, unless you consent to its further
                  retention in the Candidate Database. Based on your consent, we
                  store your information in the Candidate Database and thus
                  process your identification, contact details, and performance
                  data to contact you with another relevant job offer if any. We
                  can also use your data from the Candidate Database to better
                  understand what kinds of job candidates are interested in the
                  careers we have to offer. With your consent, we process the
                  data for up to 3 years after the end of the recruitment
                  process. You may withdraw your consent at any time by
                  contacting dpo@helpware.com. Data processing before revocation
                  of your consent remains valid.
                </p>
                <p>
                  <strong>
                    To distribute marketing and advertising materials
                  </strong>
                </p>
                <p>
                  We may contact you with the relevant information about
                  Helpware products and services from time to time. Most
                  messages we will send by email. For some messages, we may use
                  Personal Information we collect about you to help us determine
                  the most relevant information to share with you. If you do not
                  want to receive such messages from us, you will be able to
                  tell us by selecting certain boxes on forms we use when we
                  first collect your contact details. Or you can change your
                  marketing preferences by clicking on the unsubscribe link at
                  the bottom of our emails.
                </p>
              </div>
            </div>

            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>How do we protect your information?</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  We implement a variety of security measures to maintain the
                  safety of your personal information when you place an order or
                  enter, submit, or access your personal information. We offer
                  the use of a secure server. All supplied sensitive information
                  is transmitted via Transport Layer Security (TLS) technology
                  to and then stored into our database to be only accessed by
                  those authorized with special access rights to our systems,
                  and are required to keep the information confidential. Credit
                  card information is transmitted directly to the payment
                  processor and is not stored on our servers. If you have any
                  questions about the security of your personal information, you
                  can contact us at dpo@helpware.com
                </p>
              </div>
            </div>

            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>Cookies or other tracking technologies?</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  Cookies are small files that a site or its service provider
                  transfers to your computer’s hard drive through your Web
                  browser (if you allow) that enables the sites or service
                  providers systems to recognize your browser and capture and
                  remember certain information.
                </p>
                <p>
                  Helpware uses cookies or similar technologies to analyze
                  trends, administer the website, track users’ movements around
                  the website, and to gather demographic information about our
                  user base as a whole. You can control the use of cookies at
                  the individual browser level, but if you choose to disable
                  cookies, it may limit your use of certain features or
                  functions on our website or service.
                </p>
                <p>
                  <strong>Log files.</strong>
                  <br />
                  As is true of most websites, we gather certain information
                  automatically. This information may include Internet protocol
                  (IP) addresses, browser type, Internet service provider (ISP),
                  referring/exit pages, the files viewed on our site (e.g., HTML
                  pages, graphics, etc.), operating system, date/time stamp,
                  and/or clickstream data to analyze trends in the aggregate and
                  administer the site.
                </p>
                <p>
                  <strong>Behavioral Targeting.</strong>
                  <br />
                  We partner with a third party to manage our advertising on
                  other sites. Our third party partner may use cookies or
                  similar technologies in order to provide you advertising based
                  upon your browsing activities and interests. If you wish to
                  opt out of interest-based advertising, you can contact us at
                  dpo@helpware.com. Please note you will continue to receive
                  generic ads.
                </p>
              </div>
            </div>

            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>Do we share any information to outside parties?</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  We do not sell, trade, or otherwise transfer to outside
                  parties your personal information. We will share your personal
                  information with third parties only in the ways that are
                  described in this privacy policy. However, non-personally
                  identifiable visitor information may be provided to other
                  parties for marketing, advertising, or other uses.
                </p>
                <p>
                  <strong>Service Providers.</strong>
                  <br />
                  We may provide your personal information to companies that
                  provide services to help us with our business activities such
                  as offering customer service, carrying out the recruiting
                  process or processing your payment. These companies are
                  authorized to use your personal information only as necessary
                  to provide these services to us.
                </p>
                <p>
                  <strong>With your consent</strong>
                  <br />
                  We share information about you with third parties when you
                  give us consent to do so. For example, we often display
                  personal testimonials of satisfied customers on our public
                  websites. With your consent, we may post your name alongside
                  the testimonial.
                </p>
                <p>
                  <strong>Third-Party Widgets</strong>
                  <br />
                  Our blogs and forums might contain widgets and social media
                  features, such as the Twitter "tweet" or Facebook "like"
                  buttons. These widgets and features may collect your IP
                  address, which page you are visiting on the Sites or Services,
                  and may set a cookie to enable the feature to function
                  properly. Widgets and social media features are either hosted
                  by a third party or hosted directly on our Services. Your
                  interactions with these features are governed by the privacy
                  policy of the company providing it.
                </p>
                <p>
                  <strong>Legal Disclaimer</strong>
                  <br />
                  We may also disclose your personal information as required by
                  law, such as to comply with a subpoena or similar legal
                  process when we believe in good faith that disclosure is
                  necessary to protect our rights, protect your safety or the
                  safety of others, investigate fraud, or respond to a
                  government request.
                </p>
                <p>
                  In the event Helpware is involved in a merger, acquisition, or
                  sale of all or a portion of its assets, you will be notified
                  via email and/or a prominent notice on our website, of any
                  change in ownership, uses of your personal information, and
                  choices you may have regarding your personal information.
                </p>
                <p>
                  We may also disclose your personal information to any other
                  third party with your prior consent.
                </p>
              </div>
            </div>

            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>What are your rights as a Data Subject?</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  <strong>Right of access:</strong> In accordance with Art. 15
                  GDPR, you may have the right to obtain confirmation from us as
                  to whether or not your personal data is processed by us, and,
                  where that is the case, to request access to your personal
                  data. The information about personal data processing includes
                  the purposes of the processing, the categories of personal
                  data concerned, and the recipients or categories of recipients
                  to whom your personal data have been or may be disclosed.
                  However, this is not an absolute right and the interests of
                  other individuals may restrict your right of access. Also, you
                  may have the right to obtain a copy of your personal data
                  undergoing processing. For additional copies requested, we may
                  charge a reasonable fee based on administrative costs.
                </p>
                <p>
                  <strong>Right to rectification: </strong>In accordance with
                  Art. 16 GDPR, you may have the right to obtain from us the
                  rectification of inaccurate personal data. Depending on the
                  purposes of the processing, you may have the right to have
                  incomplete personal data completed, including by means of
                  providing a supplementary statement.
                </p>
                <p>
                  <strong>Right to erasure (right to be forgotten): </strong>In
                  accordance with Art. 17 GDPR, you have the right to request
                  that we delete your personal data. Please keep in mind that we
                  may keep your personal data if it is still necessary for:
                </p>
                <ul>
                  <li>fulfilling our legal obligation;</li>
                  <li>
                    archival, historical, or scientific research or statistical
                    purposes; or
                  </li>
                  <li>
                    determination, exercise, or defense of our legal claims.
                  </li>
                </ul>
                <p>
                  <strong>Right to restriction of processing: </strong>In
                  accordance with Art. 18 GDPR, you have the right to request
                  that we restrict the processing of your personal data. In this
                  case, the respective personal data will be marked accordingly
                  and may only be processed by us for certain purposes.
                </p>
                <p>
                  <strong>Right to personal data portability: </strong>In
                  accordance with Art. 20 GDPR, you have the right to receive
                  the personal data concerning you, which you have provided to
                  us, in a structured, commonly used, and machine-readable
                  format and/or to request the transfer of this personal data to
                  another entity.
                </p>
                <p>
                  <strong>Right to object: </strong>If you have given your
                  consent to the processing of your data in accordance with Art.
                  7 III GDPR, you may revoke your consent at any time in the
                  future. The declaration of revocation must be addressed to us
                  and must be presented in writing or delivered by email or fax.
                </p>
              </div>
            </div>

            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>Our Policy towards Children</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  The Helpware products and/or services are not directed at
                  persons under 16 and we do not knowingly collect Personal
                  Information from children under 16. If you become aware that
                  your child has provided us with Personal Information, without
                  your consent, then please contact us using the details below
                  so that we can take steps to remove such information and
                  terminate any account your child has created with us.
                </p>
              </div>
            </div>

            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>Third party links</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  Occasionally, at our discretion, we may include links to our
                  merchant partners (third party products or services) on our
                  Sites. These third party’s websites have separate and
                  independent privacy policies. We, therefore, have no
                  responsibility or liability for the content and activities of
                  these linked websites. Nonetheless, we seek to protect the
                  integrity of our Sites and welcome any feedback about these
                  third party websites.
                </p>
                <p>
                  Testimonials
                  <br />
                  We display personal testimonials of satisfied customers on our
                  Sites in addition to other endorsements. With your consent, we
                  may post your testimonial along with your name. If you wish to
                  update or delete your testimonial, you can contact us at
                  dpo@helpware.com
                </p>
                <p>
                  <strong>Social Media Widgets</strong>
                  <br />
                  Helpware Sites may include links that direct you to other
                  websites or services whose privacy practices may differ from
                  ours. Your use of and any information you submit to any of
                  those third-party sites is governed by their privacy policies,
                  not this one.
                </p>
              </div>
            </div>

            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>Consent to Our Terms and Conditions</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  Please also visit our Terms and Conditions section
                  establishing your consent to the use, disclaimers, and
                  limitations of liability governing the use of this Site
                  Information Related to Data Collected through our Products and
                  Services
                </p>
                <p>
                  <strong>
                    Information Related to Data Processor or Collected for our
                    Customers:
                  </strong>
                  <br />
                  Helpware is considered a processor or sub-processor under the
                  direction of our customers who are considered controllers or
                  processors. Helpware has entered appropriate Data Processing
                  Agreements with our customers controlling protection of your
                  personal data.
                </p>
                <p>
                  <strong>Transfer of Personal Data to Third Parties:</strong>
                  <br />
                  We may transfer personal data to third-party processor
                  companies (i.e. cloud data and server services) that help us
                  provide our service. Such third party processors are located
                  in, and the transfers are limited to, the United States or
                  countries in the European Union and these processors are
                  controlled by data processing agreements providing the same
                  protections of your personal data.
                </p>
                <p>
                  <strong>
                    Access and Retention of Data Controlled by our Customers:
                  </strong>
                  <br />
                  Helpware acknowledges that you have the right to access your
                  personal information or ask for its removal. An individual who
                  seeks access, or who seeks to correct, amend, or delete
                  inaccurate data should direct his/her query to the Helpware's
                  customer (the data controller). If requested to remove data we
                  will respond within a reasonable timeframe.
                </p>
                <p>
                  Helpware will retain personal data we process on behalf of our
                  customers for as long as needed to provide Services to our
                  customer. Helpware will also retain personal data as necessary
                  to comply with our legal obligations, resolve disputes, and
                  enforce our agreements.
                </p>
              </div>
            </div>

            <div className="privacy-policy-cont-rep">
              <div className="privacy-policy-cont-rep-title">
                <h4>How to contact us about this Policy</h4>
              </div>
              <div className="privacy-policy-cont-rep-cont">
                <p>
                  <strong>Changes to our Privacy Policy</strong>
                  <br />
                  We may update this Privacy Policy to reflect changes to our
                  information practices. If we make any material changes we will
                  notify you by email (sent to the e-mail address specified in
                  your account) or by means of a notice on this Site prior to
                  the change becoming effective. We encourage you to
                  periodically review this page for the latest information on
                  our privacy practices.
                </p>
                <p>
                  <strong>Contacting Us</strong>
                  <br />
                  If there are any questions regarding this Privacy Policy, the
                  practices of this Site, or your dealings with this Site, you
                  may contact us using the information below:
                </p>
                <p>
                  <strong>Helpware Inc.</strong>
                  <br />
                  PO Box 701059
                  <br />
                  PMB 99737
                  <br />
                  Louisville, Kentucky 40270-1059 US
                </p>
                <p>
                  <a href="mailto:dpo@helpware.com">dpo@helpware.com</a>
                </p>
                <p>Current DPO: Cassy Bayona</p>
                <p>
                  Fill out our Data Subject Request Form{' '}
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc4fOO-RAMnpj3QnspcgzW66aUcSLtyUgeuHR3jE61YzdRzWQ/viewform?usp=sf_link"
                    rel="noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
