import React, { PropsWithChildren } from 'react';
import { Project, ProjectStatus } from 'generated/graphql';
import { Box, BoxProps, Text } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import { getTasksCount } from 'utils/getTasksCount';

function Item({ children, color, ...restProps }: PropsWithChildren<BoxProps>) {
  return (
    <Box
      borderRadius="4px"
      height="25px"
      width="120px"
      p="0 5px"
      displayFlex
      flexCenter
      {...restProps}
    >
      <Text
        upper
        color={color}
        fontSize="10px"
        fontWeight="bold"
        textAlign="center"
        lineHeight="12px"
        letterSpacing="0.02rem"
      >
        {children}
      </Text>
    </Box>
  );
}

interface Props {
  data: Project;
}

function ProjectStatusPlank({ data }: Props) {
  const theme = useTheme();
  const {
    inAuditTasksCount,
    newTasksCount,
    readyForAuditTasksCount,
    startedTasksCount
  } = getTasksCount(data);
  const isHelperCompleted =
    data.isUserHelper && newTasksCount <= 0 && startedTasksCount <= 0;
  const isAuditorNotReady =
    data.isUserAuditor &&
    readyForAuditTasksCount <= 0 &&
    inAuditTasksCount <= 0;

  if (!data) {
    return null;
  }

  if (
    data.status !== ProjectStatus.Completed &&
    (isHelperCompleted || !data.isUserHelper) &&
    isAuditorNotReady
  )
    return (
      <Item
        backgroundColor={theme.colors.purpleLight}
        color={theme.colors.purple}
        displayFlex
        justifyContent="center"
      >
        Not ready for audit yet
      </Item>
    );

  if (data.status === ProjectStatus.Unreviewed)
    return (
      <Item
        backgroundColor={theme.colors.orangeLight}
        color={theme.colors.orange}
      >
        Unreviewed
      </Item>
    );

  if (data.isDeleted)
    return (
      <Item
        backgroundColor={theme.colors.lightest}
        color={theme.colors.lighter}
      >
        Archived
      </Item>
    );

  if (
    data.status === ProjectStatus.Completed ||
    (!data.isUserAuditor && isHelperCompleted)
  )
    return (
      <Item
        backgroundColor={theme.colors.greenLighter}
        color={theme.colors.green}
      >
        Completed
      </Item>
    );

  if (data.status === ProjectStatus.Draft)
    return (
      <Item backgroundColor={theme.colors.lighter} color={theme.colors.light}>
        Draft
      </Item>
    );

  if (data.status === ProjectStatus.InProcess)
    return (
      <Item
        backgroundColor={theme.colors.purpleLight}
        color={theme.colors.purple}
      >
        Processing
      </Item>
    );

  if (data.status === ProjectStatus.Active)
    return (
      <Item backgroundColor={theme.colors.blueLight} color={theme.colors.blue}>
        In progress
      </Item>
    );

  return (
    <Item backgroundColor={theme.colors.lighter} color={theme.colors.light}>
      Empty
    </Item>
  );
}

export default ProjectStatusPlank;
