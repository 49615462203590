import { FormInstance } from 'antd/lib/form';

export const checkFieldsIsValidate = async (form: FormInstance) => {
  try {
    await form.validateFields();
    return true;
  } catch (errorInfo) {
    return false;
  }
};
