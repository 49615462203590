import React, { useMemo } from 'react';
import { Project } from 'generated/graphql';
import columnsActiveProjectAdmin from 'containers/Projects/ProjectsAdmin/ActiveProjects/columns.admin';
import useGetListProjects from 'containers/Projects/hooks/useGetListProject';
import ProjectsTable from 'containers/Projects/ProjectsTable';
import useArchiveProject from 'containers/Projects/hooks/useArchiveProject';

interface ProjectsAdminTabProps {
  activeProjectId: number;
  onEdit(data: Project): void;
}

const ActiveProjects = ({ activeProjectId, onEdit }: ProjectsAdminTabProps) => {
  const { refetchListProjects, ...projectListProps } = useGetListProjects();
  const { handleProjectArchive } = useArchiveProject({
    refetchListProjects
  });

  const columnMemo = useMemo(
    () =>
      columnsActiveProjectAdmin({
        activeProjectId,
        onEdit,
        onArchive: handleProjectArchive
      }),
    [activeProjectId, onEdit, handleProjectArchive]
  );

  return <ProjectsTable columns={columnMemo} {...projectListProps} />;
};

export default ActiveProjects;
