import styled, { css } from 'styled-components/macro';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  space,
  position,
  shadow,
  typography,
  compose
} from 'styled-system';
import { BoxProps } from './Box.types';
import { PropsWithChildren } from 'react';
import {
  displayGrid,
  maxSize,
  flexCenter,
  flexCenterInverted,
  noGap,
  horizCenter,
  vertCenter,
  displayFlex,
  clickable
} from './mixins';
import { scrollStyled } from 'theme';
import { px } from 'utils';

const customUtils = [
  displayGrid,
  displayFlex,
  flexCenter,
  flexCenterInverted,
  maxSize,
  clickable,
  noGap,
  horizCenter(),
  vertCenter()
];

const Box = styled.div.withConfig<BoxProps>({
  shouldForwardProp: (prop, defValidFn) => defValidFn(prop)
})<PropsWithChildren<BoxProps>>(
  ({ overflowVisible, gap = '1rem' }) => css`
    text-overflow: ellipsis;

    ${gap &&
    css`
      gap: ${px(gap)};
    `}

    ${overflowVisible &&
    css`
      overflow: visible;
    `}
  `,
  ...customUtils,
  compose(
    grid,
    flexbox,
    layout,
    border,
    background,
    space,
    color,
    position,
    shadow,
    typography
  )
);

export default Box;
