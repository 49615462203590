import styled, { css } from 'styled-components/macro';
import React, { PropsWithChildren } from 'react';
import { AnnotationUnitType } from '../../AnnotationSystem.types';
import numberToPx from 'utils/numberToPx';

interface RootProps {
  isActive: boolean;
  isDrag: boolean;
  isDisableHover: boolean;
  color: string;
}

export const Root = styled.svg(
  ({ isActive, isDrag, isDisableHover, color }: RootProps) => css`
    overflow: visible;
    position: relative;
    z-index: 1;
    pointer-events: ${isDisableHover ? 'none' : 'visiblePainted'};

    ${MainBorderRect} {
      stroke: ${color};
      fill: ${color};
      fill-opacity: 0.3;
    }

    ${isActive &&
    css`
      stroke: ;
    `}

    ${isActive &&
    !isDrag &&
    css`
      ${MainBorderRect} {
        cursor: grab;
      }

      ${LabelTagRoot} {
      }
    `}
    ${isActive &&
    isDrag &&
    css`
      ${MainBorderRect} {
        cursor: grab;
      }

      ${LabelTagRoot} {
      }
    `}

    ${!isActive &&
    !isDrag &&
    css`
      &.hover,
      &:hover {
        ${MainBorderRect} {
          stroke: ${({ theme }) => theme.colors.warning};
          fill-opacity: 0.5;
          cursor: pointer;
        }

        ${LabelTagRoot} {
        }
      }
    `}

    &[data-is-end='true'] {
      opacity: 0.5;
    }
  `
);

export const MainBorderRect = styled.rect(
  () => css`
    stroke-width: 2px;
    position: relative;
    z-index: 1;
  `
);

export const MainBorderRectViewOnly = styled.rect(
  () => css`
    pointer-events: stroke;
    stroke-width: 1px;
    stroke: ${({ theme }) => theme.colors.orange};
    fill: transparent;
    position: relative;
    z-index: 1;
  `
);

export const InnerStroke = styled.rect(
  () => css`
    pointer-events: none;
    stroke: transparent;
    fill: transparent;
  `
);

export const LabelTagRoot = styled.div(
  () => css`
    pointer-events: none;
    width: max-content;
    padding: 0 0.5rem;
    text-transform: uppercase;
  `
);

interface ForeignObjectStyledProps {
  width: number;
}

export const ForeignObjectStyled = styled.foreignObject(
  ({ width }: ForeignObjectStyledProps) => css`
    cursor: pointer;
    overflow: visible;
    min-width: 50px;
    ${width &&
    css`
      width: ${numberToPx(width)};
    `}
  `
);

export const LabelTag = ({
  children,
  labelTagRef,
  width,
  ...restProps
}: PropsWithChildren<{
  width: number;
  onMouseDown: React.ReactEventHandler;
  labelTagRef: React.MutableRefObject<HTMLDivElement | null>;
}>) => {
  return (
    <ForeignObjectStyled
      x={0}
      y={0}
      width={width || 25}
      height={22}
      {...restProps}
    >
      <LabelTagRoot ref={labelTagRef}>{children}</LabelTagRoot>
    </ForeignObjectStyled>
  );
};
