import { gql } from 'graphql-tag';

export const CLIENT_DETAIL_FILED = gql`
  fragment ClientDetailsFields on Client {
    id
    firstName
    lastName
    email
    activated
    isDeleted
    enabled
    companyName
    isPremium
    createdAt
  }
`;
