import React from 'react';
import { RouteContent } from 'components/RouteContent';
import ProjectTabs from 'containers/Projects';
import HeaderAppBar from 'containers/HeaderAppBar';
import { Text } from 'components/_main';
import { isClient } from 'utils';

const DashboardWithProjects = () => (
  <div>
    <HeaderAppBar title={isClient() ? null : 'Projects'} />
    <RouteContent>
      <ProjectTabs />
    </RouteContent>
  </div>
);

export default DashboardWithProjects;
