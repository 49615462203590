import { FastField, FieldProps, FieldConfig } from 'formik';
import React, { useMemo } from 'react';

interface WithFastFieldProps extends Omit<FieldConfig, 'name'> {
  name?: string;
}

function WithFastField({
  children,
  name = 'fastField',
  ...restProps
}: React.PropsWithChildren<WithFastFieldProps>) {
  const component = useMemo(
    () => (props: FieldProps) => {
      if (!React.isValidElement(children)) {
        return null;
      }

      return React.cloneElement(children, { ...children.props, ...props });
    },
    [children]
  );

  return (
    <FastField name={name} {...restProps}>
      {component}
    </FastField>
  );
}

export default WithFastField;
