import { Row, Text } from 'components/_main';
import {
  AnnotationSystemModesEnum,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useIndexColor from 'containers/AnnotationSystem/hooks/useIndexColor';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import React from 'react';
import AnnotationLabelItem from './AnnotationLabelItem';
import AnnotationLabelsBlock from './AnnotationLabelsBlock';
import Label from './Label';

interface WithColorPros {
  item: AnnotationUnitProps;
}

interface DotLabelProps {
  dot: AnnotationUnitProps;
  color: string;
}

export default function AnnotationsDotWrapperLabels() {
  const title = 'Annotations';
  const { annotationUnits } = useAnnotationSystemState();
  const isExpandable = annotationUnits.length > 0;

  return (
    <AnnotationLabelsBlock title={title} isExpandable={isExpandable}>
      {Boolean(annotationUnits.length) ? (
        annotationUnits
          .filter((item) => item.type === 'wrapper')
          .map((item) => {
            return <DotsWrapperLabel key={item.unitId} item={item} />;
          })
      ) : (
        <Text variant="ui-1-regular" colorVariant="light">
          No annotations added yet.
        </Text>
      )}
    </AnnotationLabelsBlock>
  );
}

function DotsWrapperLabel({ item }: WithColorPros) {
  const color = useIndexColor(item.groupIndex);
  const { annotationUnits } = useAnnotationSystemState();

  const dotsWithSameGroup = annotationUnits.filter(
    (dot) => dot.type === 'dot' && dot.groupIndex === item.groupIndex
  );

  const { isActive } = useStatus({
    ...item,
    place: 'sidebar'
  });

  return (
    <Label isActive={isActive} key={item.unitId} item={item} color={color}>
      {dotsWithSameGroup.map((dot) => (
        <DotLabel key={dot.unitId} dot={dot} color={color} />
      ))}
    </Label>
  );
}

function DotLabel({ dot, color }: DotLabelProps) {
  const { onSetCurSelUnitId, onSetCurMode } = useAnnotationSystemActions();

  const { isActive } = useStatus({ ...dot, place: 'sidebar' });

  const handleChildrenSelect = (id: string) => () => {
    onSetCurSelUnitId(id);
    onSetCurMode(AnnotationSystemModesEnum.DOT_SELECT);
  };

  return (
    <AnnotationLabelItem
      color={color}
      small
      data={{
        ...dot,
        label: dot.description ?? ''
      }}
      isActive={isActive}
      onClick={handleChildrenSelect(dot.unitId)}
    />
  );
}
