import React, { useEffect } from 'react';
import SignIn from 'containers/SignIn';
import { useNavigate } from 'react-router-dom';
import { useRedirect } from 'hooks/useRedirect';

function SignInPage() {
  const handleRedirect = useRedirect();
  const history = useNavigate();
  const tokenFromLocalStorage = localStorage.getItem('token') ?? null;

  useEffect(() => {
    /** TODO: Move to one place. App root? */
    if (tokenFromLocalStorage) {
      setTimeout(() => {
        handleRedirect('/projects');
      }, 1000);
    }
  }, [handleRedirect, history, tokenFromLocalStorage]);

  return <SignIn />;
}

export default SignInPage;
