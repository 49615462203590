import React from 'react';
import { Row, Box } from 'components/_main';
import { ProjectType } from 'appTypes';
import SidebarFoldable from 'components/SidebarFoldable';
import { AnnotationSidebarProps } from '../../AnnotationView.types';
import AnnotationsBoxLabels from './AnnotationLabelsTab/AnnotationsBoxLabels';
import AnnotationsDotWrapperLabels from './AnnotationLabelsTab/AnnotationsDotWrapperLabels';
import AnnotationsContentModerationLabels from './AnnotationLabelsTab/AnnotationsContentModerationLabels';
import AnnotationHistoryBlock from './AnnotationHistory/AnnotationHistoryBlock';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import {
  AnnotationSidebarTitle,
  AnnotationInstructions
} from './AnnotationSidebar.ui';
import AnnotationsPolygonLabels from './AnnotationLabelsTab/AnnotationsPolgons';

function AnnotationSidebar({
  taskId,
  taskType,
  taskingType,
  taskProjectData,
  taskProjectHistory
}: AnnotationSidebarProps) {
  const { annotationSpecificationType } = useAnnotationSystemState();

  const isContentModeration = taskType === ProjectType.CONTENT_MODERATION;
  const isDots = annotationSpecificationType === 'dot';
  const isPolygons = annotationSpecificationType === 'polygon';

  let labelsRender = <AnnotationsBoxLabels />;

  if (isDots) {
    labelsRender = <AnnotationsDotWrapperLabels />;
  }

  if (isPolygons) {
    labelsRender = <AnnotationsPolygonLabels />;
  }

  if (isContentModeration) {
    labelsRender = <AnnotationsContentModerationLabels />;
  }

  return (
    <SidebarFoldable data-testid="SidebarFoldable" isRightPosition={false}>
      <Row
        display="grid"
        gridGap="40px"
        minWidth="280px"
        height="100%"
        gridAutoRows="min-content auto"
      >
        <AnnotationSidebarTitle
          taskId={taskId}
          taskType={taskType}
          taskingType={taskingType}
        />
        <Box
          display="grid"
          gridGap="40px"
          flexDirection="column"
          flexGrow="1"
          height="100%"
          gridAutoFlow="row"
          gridAutoRows="minmax(20px, min-content)"
          overflow="hidden"
        >
          <AnnotationInstructions taskProjectData={taskProjectData} />
          {labelsRender}
          {taskProjectHistory && taskingType === 'auditing' && (
            <AnnotationHistoryBlock data={taskProjectHistory} />
          )}
        </Box>
      </Row>
    </SidebarFoldable>
  );
}

export default AnnotationSidebar;
