import settings from 'containers/AnnotationSystem/AnnotationSystem.config';
import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import DrawLineBetweenDots from 'containers/AnnotationSystem/renderUtils/DrawLineBetweenDots';
import React from 'react';

interface Props {
  dots: AnnotationUnitProps[];
}

export default function useRenderLines({ dots }: Props) {
  let dotsConnected: Record<string, string[]> = {};
  let linesLabelsConnected: string[] = [];
  let newLines: React.ReactNode[] = [];
  let keys: string[] = [];

  for (const dotFrom of dots) {
    const curIndex = dotFrom?.groupIndex ?? 1;
    const curId = dotFrom.unitId;

    const matcher = new RegExp(`^${curIndex}-${dotFrom.label}-([0-9]+)`);
    const curIdTo = curId.match(matcher)?.[1];

    if (!curIdTo) {
      continue;
    }

    if (!curId || !settings?.['dot']?.ids?.[curIdTo]?.connections) {
      continue;
    }

    const coordFrom = {
      x: dotFrom.x,
      y: dotFrom.y
    };

    for (const toDotLabel of settings['dot'].ids[curIdTo].connections) {
      for (const dotTo of dots) {
        const matcher = new RegExp(`^${curIndex}-${dotTo.label}-([0-9]+)`);
        const dotToLabelPoint = dotTo.unitId.match(matcher)?.[1];
        const groupIndexDotTo = dotTo.groupIndex;

        if (
          curIndex !== groupIndexDotTo ||
          toDotLabel !== dotToLabelPoint ||
          (dotsConnected?.[curId] && dotsConnected[curId].includes(toDotLabel))
        ) {
          continue;
        }

        const coordTo = {
          x: dotTo.x,
          y: dotTo.y
        };

        if (
          linesLabelsConnected.includes(
            `from-${dotTo.unitId}-to-${dotTo.unitId}`
          )
        ) {
          continue;
        }
        linesLabelsConnected.push(
          `from-${dotFrom.unitId}-to-${dotFrom.unitId}`
        );

        if (!dotsConnected[curId]) {
          dotsConnected[curId] = [];
        }

        dotsConnected[curId].push(toDotLabel);

        const key = `${coordFrom.x}-${coordTo.x}-${coordFrom.y}-${coordTo.y}-${dotFrom.id}-${dotFrom.groupIndex}-line`;

        if (keys.includes(key)) continue;

        keys.push(key);

        newLines.push(
          <DrawLineBetweenDots
            label={dotFrom.label}
            className="dotLine"
            dataTestId="line"
            groupIndex={dotTo.groupIndex}
            dataIdFrom={`${dotFrom.unitId}`}
            dataIdTo={`${dotTo.unitId}`}
            key={key}
            from={coordFrom}
            to={coordTo}
          />
        );
      }
    }
  }
  return newLines;
}
