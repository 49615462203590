import React from 'react';
import { useTheme } from 'styled-components/macro';
import Box, { BoxProps } from '../Box';

function Container(props: React.PropsWithChildren<BoxProps>) {
  const theme = useTheme();
  return (
    <Box
      backgroundColor={theme.colors.bg}
      borderRadius="5px"
      maxWidth="max-content"
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxShadow="0px 6px 15px rgba(178, 185, 206, 0.3)"
      padding="32px 30px"
      {...props}
    />
  );
}

export default Container;
