import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { upper } from 'utils/_stringUtilsLegacy';
import { HelperReportEntity } from 'generated/graphql';
import { RATE_MULITPLIER } from 'utils/_rateUtils';
import { formatDate } from 'utils/_dateUtilsLegacy';

export const columns: ColumnsType = [
  {
    title: upper('Project'),
    width: 100,
    render: (value: HelperReportEntity) => {
      return <>{value.title}</>;
    }
  },
  {
    title: upper('Created'),
    width: 50,
    render: (value: HelperReportEntity) => {
      return <>{formatDate(value.createdAt)}</>;
    }
  },
  {
    title: upper('Finished'),
    width: 50,
    render: (value: HelperReportEntity) => {
      return <>{formatDate(value.finishedAt)}</>;
    }
  },
  {
    title: upper('Submitted Tasks'),
    width: 50,
    render: (value: HelperReportEntity) => {
      const { helperPoints } = value || {};
      return <>{helperPoints}</>;
    }
  },
  {
    title: upper('Penalties'),
    dataIndex: 'penalties',
    width: 50
  },
  {
    title: upper('Audited tasks'),
    width: 50,
    render: (value: HelperReportEntity) => {
      const { auditorTasks } = value || {};
      return <>{auditorTasks}</>;
    }
  },
  {
    title: upper('Audited points'),
    width: 50,
    render: (value: HelperReportEntity) => {
      const { auditorPoints } = value || {};
      return <>{auditorPoints}</>;
    }
  },
  {
    title: upper('Total earned'),
    width: 50,
    render: (value: HelperReportEntity) => {
      const {
        auditorRate,
        auditorPoints,
        helperRate,
        helperPoints,
        penalties
      } = value || {};
      const result =
        auditorPoints * auditorRate + (helperPoints - penalties) * helperRate;
      return <>{`$${result ? (result / RATE_MULITPLIER).toFixed(3) : 0}`}</>;
    }
  }
];

export default columns;
