import React from 'react';
import { Col, Box, Row, Text } from 'components/_main';
import { Modal } from 'components/_modals';
import { LockIcon } from 'assets';
import { projectTypeHelpList } from '../../AddProjectWizard.constants';

import { useTheme } from 'styled-components/macro';

interface ProjectTypeHelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ProjectTypeHelpItem({
  image,
  title,
  description,
  onlyPremium
}: {
  image: React.ReactNode;
  title: string;
  description: string;
  onlyPremium?: boolean;
}) {
  const theme = useTheme();

  return (
    <>
      <Row
        width="100%"
        gridGap="24px"
        paddingBottom="40px"
        borderBottom={`1px solid ${theme.colors.lighter}`}
        css={`
          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }
        `}
      >
        <Box height="100%">{image}</Box>
        <Row>
          <Text variant="custom-20px-bold">{title}</Text>
          <Text>{description}</Text>
        </Row>
        {onlyPremium && (
          <Col gridGap="8px" displayFlex alignItems="center">
            <LockIcon />
            <Text colorVariant="light">Not available for Freemium Plan</Text>
          </Col>
        )}
      </Row>
    </>
  );
}

function ProjectTypeHelpModal(props: ProjectTypeHelpModalProps) {
  const { onClose, isOpen } = props;

  return (
    <Modal
      width="700px"
      title="About Annotation Types"
      isVisible={isOpen}
      onCancel={onClose}
      onOk={onClose}
      cancelText={null}
      okText="OK, GOT IT"
    >
      <Row gridGap="40px" padding="16px" height="73vh" overflow="auto">
        {projectTypeHelpList.map(({ id, ...rest }) => (
          <ProjectTypeHelpItem key={id} {...rest} />
        ))}
      </Row>
    </Modal>
  );
}

export default ProjectTypeHelpModal;
