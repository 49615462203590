import React from 'react';
import HeaderAppBar from 'containers/HeaderAppBar';
import { Row } from 'components/_main';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import ProfileDetails from './ProfileDetails';
import { useTheme } from 'styled-components/macro';

function ProfileDetailsPage() {
  const userSessionData = useGetUserSessionData();
  const theme = useTheme();

  return (
    <div>
      <HeaderAppBar />
      <Row bg={`${theme.colors.bg}`}>
        <ProfileDetails {...userSessionData} />
      </Row>
    </div>
  );
}

export default ProfileDetailsPage;
