import React from 'react';
import { useField } from 'formik';
import { ProjectType } from 'appTypes';
import {
  ImageAnnotationSettings,
  VideoAnnotationSetting
} from './ProjectTypeSettings.ui';

interface ProjectSpecializationTypeSelectProps {
  projectTypeName?: string;
  settingChunkLengthName?: string;
}

function ProjectSpecializationTypeSubTypeSelect({
  projectTypeName = 'type',
  settingChunkLengthName
}: ProjectSpecializationTypeSelectProps) {
  const [{ value: projectTypeValue }] = useField(projectTypeName);

  return (
    <>
      {projectTypeValue === ProjectType.IMAGE_ANNOTATION && (
        <ImageAnnotationSettings />
      )}
      {projectTypeValue === ProjectType.VIDEO_ANNOTATION && (
        <VideoAnnotationSetting
          settingChunkLengthName={settingChunkLengthName}
        />
      )}
    </>
  );
}

export default ProjectSpecializationTypeSubTypeSelect;
