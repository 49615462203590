import React from 'react';
import styled, { css } from 'styled-components/macro';
import { upper as upperFn } from 'utils';
import { TextProps, Text } from '.';
import { fontStyleFromVariants } from './mixins';

interface LinkProps extends TextProps {
  upper?: boolean;
  href?: string;
  target?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

const LinkStyled = styled(Text)<LinkProps>(
  ({ theme, disabled, variant = 'ui-1-bold' }) => css`
    a {
      color: ${theme.colors.primary};
      font-weight: 700;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        color: ${theme.colors.secondary};

        svg path {
          fill: ${theme.colors.secondary};
          transition: all 0.3s;
        }
      }

      ${disabled &&
      css`
        &,
        &:hover {
          color: ${theme.colors.disabledFg};
        }
      `}

      ${fontStyleFromVariants({ variant })};
    }
  `
);

function Link({
  children,
  href,
  upper,
  target,
  disabled,
  onClick,
  ...restProps
}: React.PropsWithChildren<LinkProps>) {
  let content = children;

  if (upper && typeof children === 'string') {
    content = upperFn(children);
  }

  return (
    <LinkStyled
      {...restProps}
      {...(!disabled && { onClick })}
      disabled={disabled}
    >
      <a
        className="componentLink"
        {...(!disabled && {
          href
        })}
        target={target}
      >
        {content}
      </a>
    </LinkStyled>
  );
}

export default Link;
