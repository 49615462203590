import React, { PropsWithChildren } from 'react';
import Box from '../Box';
import { GridProps } from './Grid.types';

export default function Grid({
  children,
  ...restProps
}: PropsWithChildren<GridProps>) {
  return (
    <Box display="grid" {...restProps}>
      {children}
    </Box>
  );
}
