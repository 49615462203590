import React, { useCallback, useMemo } from 'react';
import { Formik, FormikProps, useFormikContext } from 'formik';
import { consoleError, consoleLogDebounced, isAdmin, upper } from 'utils';
import { varEnv } from 'utils/envVars';
import {
  AddProjectWizardLegacyProps as AddProjectWizardProps,
  HandleNextProps,
  StepsDescriptions,
  WizardFormValues
} from '../../AddProjectWizard.types';
import { Divider } from 'antd';
import { Text, Button, Box, Row, Col } from 'components/_main';
import { toast } from 'utils/toast';
import { useAddProjectWizardContext } from 'containers/AddProjectWizard/AddProjectWizard.context';
import { FastForwardCircleIcon } from '../../assets';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import Icon from 'components/Icon';
import useRedirect from 'hooks/useRedirect';

const checkIsChildrenJSX = (child: unknown): child is JSX.Element => {
  return 'props' in (child as object);
};

export const Wizard = ({
  initialValues,
  children,
  onSubmit,
  onClose,
  formRef,
  validateCb,
  onStepChange,
  stepNumber = 0,
  validationSchema,
  showSaveDraftProjectButton,
  saveDraftProjectButton,
  showSkipStepButton,
  isDisabledSaveButton
}: AddProjectWizardProps) => {
  const { activated } = useGetUserSessionData();
  const context = useAddProjectWizardContext();
  const { formOptions, onSetFormOptions } = context;
  const [snapshot, setSnapshot] =
    React.useState<WizardFormValues>(initialValues);

  const steps = React.Children.toArray(children)
    .map((child) => {
      if (!checkIsChildrenJSX(child)) {
        console.error('Wizard: Children is not valid JSX Element', child);
        return null;
      }
      return child;
    })
    .filter((child) => !!child?.props);
  const step: React.ReactElement<{
    description: StepsDescriptions;
    validationSchema: any;
  }> | null = steps[stepNumber];
  const totalSteps = steps.length;

  const isStepBeforeLast = formOptions?.finalSubmitStepDisabled
    ? stepNumber === totalSteps - 1
    : stepNumber === totalSteps - 2;

  const isLastStep = stepNumber === totalSteps - 1;

  const next = useCallback(
    (props?: HandleNextProps) => {
      const formik = formRef.current;

      const newStep = stepNumber + 1;
      setSnapshot((prevValues) => {
        if (props?.skip) {
          setTimeout(() => {
            formik.setValues({ ...prevValues });
          }, 0);

          props?.cb && props.cb(prevValues, props?.context);

          return {
            ...prevValues
          };
        }

        const newValues = {
          ...prevValues,
          ...formik.values
        };

        props?.cb && props.cb(newValues, props?.context);

        return newValues;
      });

      onStepChange && onStepChange(newStep);
    },
    [formRef, onStepChange, stepNumber]
  );

  const previous = useCallback(() => {
    const newStep = stepNumber - 1;
    onStepChange && onStepChange(newStep);
  }, [onStepChange, stepNumber]);

  const handleClose = () => {
    const formik = formRef.current;

    formik.resetForm({ values: initialValues });
    onClose();
  };

  const handleBack = useCallback(() => {
    previous();
  }, [previous]);

  const handleNext = useCallback(
    (propsArg?: HandleNextProps) => async () => {
      let props = { ...propsArg };

      const formik: FormikProps<WizardFormValues> = formRef.current;

      let finalStepFormOptions = {};

      if (!formik) {
        consoleError({
          message: 'No form ref. Final step of adding new project'
        });
        return;
      }

      if (!step) {
        return;
      }

      if (isStepBeforeLast) {
        props = {
          ...props,
          skip: props?.skip,
          cb: onSubmit,
          context: {
            ...context,
            formOptions: finalStepFormOptions
          }
        };
      }

      if (props?.skip) {
        finalStepFormOptions = {
          ...formOptions,
          skippedStepsNames: [
            ...(formOptions?.skippedStepsNames ?? []),
            step.props.description
          ]
        };

        onSetFormOptions(finalStepFormOptions);
      }

      if (!props.skip) {
        for (const value of Object.keys(formik.values)) {
          formik.setFieldTouched(value);
        }
        const errors = await formik.validateForm();
        const newErrorsCount = Object.keys(errors).length;
        const error = document.querySelector('.ant-form-item-has-error');
        if (newErrorsCount > 0) {
          formik.setErrors(errors);
          error?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
      }

      try {
        setTimeout(() => {
          next(props);
        }, 100);
        return;
      } catch (e: any) {
        e?.message &&
          toast.error(e.message, {
            toastId: 'serverError'
          });
        consoleError({
          error: e,
          message: 'Error on final set of adding new project. Go to prev. step'
        });
        handleBack();
        return;
      }
    },
    [
      context,
      formOptions,
      formRef,
      handleBack,
      isStepBeforeLast,
      next,
      onSetFormOptions,
      onSubmit,
      step
    ]
  );

  const skipStepButtonRender = useMemo(
    () => (
      <Button
        onClick={handleNext({ skip: true })}
        variant="ghost"
        noHover
        normal
        noPadding
        iconPrefix={
          <Icon size={24}>
            <FastForwardCircleIcon />
          </Icon>
        }
      >
        <Text colorVariant="primary" variant="ui-small-bold">
          {upper('SKIP THIS STEP')}
        </Text>
      </Button>
    ),
    [handleNext]
  );

  return (
    <Formik<WizardFormValues>
      innerRef={formRef}
      initialValues={snapshot}
      validationSchema={
        validationSchema ?? (step && step.props.validationSchema)
      }
      onSubmit={onSubmit}
      enableReinitialize
      validate={validateCb}
    >
      <FormikBody
        onBack={handleBack}
        onNext={handleNext()}
        onClose={handleClose}
        saveDraftProjectButton={
          showSaveDraftProjectButton && !isAdmin() && saveDraftProjectButton
        }
        skipStepButton={
          activated && showSkipStepButton && !isAdmin() && skipStepButtonRender
        }
        isDisabledSaveButton={isDisabledSaveButton}
        stepNumber={stepNumber}
        step={step}
        isStepBeforeLast={isStepBeforeLast}
        isLastStep={isLastStep}
      />
    </Formik>
  );
};

interface FormikBodyProps {
  stepNumber: number;
  step: React.ReactNode;
  onBack: () => void;
  onNext: () => void;
  onClose: () => void;
  isLastStep?: boolean;
  isStepBeforeLast?: boolean;
  isDisabledSaveButton?: boolean;
  saveDraftProjectButton?: React.ReactNode;
  skipStepButton?: React.ReactNode;
}

function FormikBody({
  onNext,
  onBack,
  isLastStep,
  stepNumber,
  step,
  onClose,
  skipStepButton,
  saveDraftProjectButton,
  isStepBeforeLast,
  isDisabledSaveButton
}: FormikBodyProps) {
  const { values, isSubmitting } = useFormikContext();
  const { isLoading } = useAddProjectWizardContext();
  const redirect = useRedirect();

  varEnv.isSb &&
    consoleLogDebounced({ formikValuesDebounced: values }, 'WizardOldFormik');

  const handleFinish = () => (isAdmin() ? onClose() : redirect('/projects'));

  return (
    <Row height="100%" gridGap="0px" gridAutoRows="1fr max-content">
      <Box
        height="100%"
        padding="40px 0 0 6px"
        id="StepWrapper"
        overflow="auto"
      >
        {/**
         * STEP
         * ====
         */}
        {step}
      </Box>
      <Box>
        {isLastStep ? (
          <>
            {!isLoading && (
              <>
                <Divider />
                <Col width="100%" justifyContent="flex-end">
                  <Button
                    size="big"
                    data-testid={'create-project_button_step-finish'}
                    onClick={handleFinish}
                  >
                    {upper('Finish')}
                  </Button>
                </Col>
              </>
            )}
          </>
        ) : (
          <>
            <Divider />
            <Col width="100%" justifyContent="space-between">
              <Button
                variant="outlined"
                size="big"
                data-testid={
                  stepNumber > 0
                    ? 'create-project_button_step-back'
                    : 'create-project_button_step-cancel'
                }
                onClick={stepNumber > 0 ? onBack : onClose}
              >
                {stepNumber > 0 ? upper('Back') : upper('Cancel')}
              </Button>
              <Col gridGap="40px">
                <Col flexCenter>
                  {skipStepButton}
                  {saveDraftProjectButton}
                </Col>
                <Button
                  isLoading={isSubmitting}
                  size="big"
                  data-testid={
                    isStepBeforeLast
                      ? 'create-project_button_step-save'
                      : 'create-project_button_step-next'
                  }
                  disabled={isDisabledSaveButton}
                  className="next-step-button"
                  onClick={onNext}
                >
                  {isStepBeforeLast ? upper('Save') : upper('Next')}
                </Button>
              </Col>
            </Col>
          </>
        )}
      </Box>
    </Row>
  );
}

export default Wizard;
