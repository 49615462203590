import { gql } from '@apollo/client';
import { PROJECT_DETAIL_FIELDS } from '../fragments/project';

export const GET_PROJECT = gql`
  ${PROJECT_DETAIL_FIELDS}
  query GetProject($projectId: Int!) {
    project(projectId: $projectId) {
      ...ProjectDetailFields
    }
  }
`;

export const PROJECT_RESULTS = gql`
  query ProjectResults($uploadToGDrive: Boolean, $projectId: Int!) {
    projectResults(projectId: $projectId, uploadToGDrive: $uploadToGDrive) {
      id
      results
    }
  }
`;

export const LIST_PROJECTS = gql`
  ${PROJECT_DETAIL_FIELDS}
  query ListProjects(
    $clientId: Int
    $sortByDateOrder: SortByDateOrder
    $page: Int
    $returnAllData: Boolean
    $isReadyForReport: Boolean
  ) {
    listProjects(
      clientId: $clientId
      sortByDateOrder: $sortByDateOrder
      page: $page
      returnAllData: $returnAllData
      isReadyForReport: $isReadyForReport
    ) {
      pagesCount
      projects {
        ...ProjectDetailFields
      }
    }
  }
`;

export const LIST_UNREVIEWD_PROJECTS = gql`
  ${PROJECT_DETAIL_FIELDS}
  query ListUnreviewdProjects($sortByDateOrder: SortByDateOrder, $page: Int) {
    listUnreviewedProjects(sortByDateOrder: $sortByDateOrder, page: $page) {
      pagesCount
      projects {
        ...ProjectDetailFields
      }
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  ${PROJECT_DETAIL_FIELDS}
  query GetProjectById($projectId: Int!) {
    project(projectId: $projectId) {
      ...ProjectDetailFields
    }
  }
`;

export const ASSIGNED_PROJECT_TITLES_TO_HELPER = gql`
  query AssignedProjectTitlesToHelper($helperId: Int!) {
    assignedProjectTitlesToHelper(helperId: $helperId)
  }
`;

export const PROJECT_TITLE_UNIQ = gql`
  query ProjectTitleUniq($clientId: Int!, $title: String!) {
    projectTitleIsUnique(clientId: $clientId, title: $title)
  }
`;

export const PROJECT_GENERATE_COCO_REPORT = gql`
  query ProjectGenerateCocoReport($clientId: Int!, $projectId: Int!) {
    generateCoCoReport(clientId: $clientId, projectId: $projectId)
  }
`;

export const PROJECT_GENERATE_YOLO_REPORT = gql`
  query ProjectGenerateYoloReport($clientId: Int!, $projectId: Int!) {
    generateYoloReport(clientId: $clientId, projectId: $projectId)
  }
`;
