import React, { useMemo } from 'react';
import { Menu, Dropdown } from 'antd';
import useRedirect from 'hooks/useRedirect';
import useSignOut from 'hooks/useSignOut';
import { CaretDown } from '@phosphor-icons/react';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { Col, Box, Text } from 'components/_main';
import BadgePricingPlan from 'components/BadgePricingPlan';
import { AvatarImageGenerator } from 'components/AvatarGenerator';
import { isClient } from 'utils';
import { useTheme } from 'styled-components/macro';

export function HeaderAppBarMenu({
  firstName,
  lastName
}: {
  firstName: string;
  lastName: string;
}) {
  const theme = useTheme();
  const { isPremium } = useGetUserSessionData();

  const handleRedirect = useRedirect();
  const handleSignout = useSignOut();

  const onEditProfile = React.useCallback(() => {
    handleRedirect('/profile');
  }, [handleRedirect]);

  const onSignout = React.useCallback(() => {
    handleSignout();
  }, [handleSignout]);

  const menu = useMemo(
    () => (
      <Menu
        items={[
          isClient()
            ? {
                key: '1',
                onClick: onEditProfile,
                label: 'Edit Profile'
              }
            : null,
          {
            key: '2',
            onClick: onSignout,
            label: 'Logout'
          }
        ]}
      />
    ),
    [onEditProfile, onSignout]
  );

  return (
    <>
      {isClient() && !isPremium && <BadgePricingPlan />}
      <Dropdown
        overlay={menu}
        trigger={['click']}
        overlayStyle={{ borderRadius: '10px' }}
      >
        <Col
          flexCenter
          css={`
            cursor: pointer;
          `}
        >
          {(firstName || lastName) && (
            <Col>
              <Text variant="ui-1-bold">
                {firstName} {lastName}
              </Text>
            </Col>
          )}
          <Col gridGap="6px" flexCenter>
            <Box
              displayFlex
              position="relative"
              gridGap="0px"
              css={`
                place-items: center;
                svg {
                  max-height: 40px;
                  max-width: 40px;
                }
              `}
              data-testid="avatar-dropdown-menu"
              onClick={(e) => e.preventDefault()}
            >
              <AvatarImageGenerator small />
            </Box>
            <CaretDown size={14} weight="bold" color={theme.colors.light} />
          </Col>
        </Col>
      </Dropdown>
    </>
  );
}
