import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components/macro';
import useHandleState from './useHandleState';
import useHotkeys from './useHotkeys';
import UnitToolbarWithLabelSelect from '../UnitToolbarWithLabelSelect';
import useLabelColor from 'containers/AnnotationSystem/hooks/useLabelColor';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import useCanvasCoords from './useCanvasCoords';
import useSvgMouseDown from './useSvgMouseDown';
import useSvgMouseUp from './useSvgMouseUp';
import useSvgMouseMove from './useSvgMouseMove';
import usePointMouseDown from './usePointMouseDown';
import usePointMouseMove from './usePointMouseMove';
import usePolyMouseDown from './usePolyMouseDown';
import useRectMouseMove from './useRectMouseMove';
import useCopy from './useCopy';
import { Group } from './Polygon.ui';

interface Props {
  polygonUnitProps: AnnotationUnitProps;
}

export default function Polygon({ polygonUnitProps }: Props) {
  const { curSelPointId, isViewOnly } = useAnnotationSystemState();
  const { onSetCurMode } = useAnnotationSystemActions();
  const theme = useTheme();
  const refPoly = useRef<SVGPolygonElement>(null);
  const refPolyWrapper = useRef<SVGSVGElement>(null);
  const [refRect, setRefRect] = useState<SVGRectElement | null>(null);
  const { unitId } = polygonUnitProps;

  const { props, setProps, setPropsWithReducerUpdate, onReducerUpdate } =
    useHandleState({
      unitId,
      props: polygonUnitProps
    });

  const { polygonPoints = [], label, type } = props;

  const {
    isCreate,
    isActive,
    isDotSel,
    isResize,
    isDrag,
    isHidden,
    isPolyAddPoint,
    isSelect,
    isNavigate,
    isSelected
  } = useStatus(props);

  useSvgMouseDown({
    polygonPoints,
    setPropsWithReducerUpdate,
    unitId
  });
  useSvgMouseUp({
    onReducerUpdate,
    polygonPoints,
    unitId
  });
  useSvgMouseMove({
    props,
    setProps,
    unitId
  });

  const handleRectMouseMove = useRectMouseMove({ unitId });
  const handlePointMouseDown = usePointMouseDown({ unitId });
  const handlePointMouseMove = usePointMouseMove({ unitId });
  const handlePolyMouseDown = usePolyMouseDown({
    polygonPoints,
    setPropsWithReducerUpdate,
    unitId
  });
  const handleCopy = useCopy();

  const canvasCoords = useCanvasCoords();

  useHotkeys();

  const color = useLabelColor(label);

  useLayoutEffect(() => {
    const elPoly = refPoly.current;
    const elRect = refRect;
    if (!elPoly || !elRect) return;

    const polyBox = elPoly.getBoundingClientRect();
    elRect.setAttribute('x', (polyBox.x - canvasCoords[0]).toString());
    elRect.setAttribute('y', (polyBox.y - canvasCoords[1]).toString());
    elRect.setAttribute('width', polyBox.width.toString());
    elRect.setAttribute('height', polyBox.height.toString());
  });

  const polyPointsControls = useMemo(
    () =>
      polygonPoints?.map((point, index) => {
        const cx = point[0];
        const cy = point[1];

        const curCircle = index === curSelPointId;

        return (
          <>
            <circle
              key={`${index}`}
              {...((isNavigate || isCreate || (!isActive && isDotSel)) &&
              !isSelect
                ? {
                    onMouseDown: () => null,
                    onMouseMove: () => null
                  }
                : {
                    onMouseDown: handlePointMouseDown(index),
                    onMouseMove: handlePointMouseMove(index)
                  })}
              cx={cx}
              cy={cy}
              r={6}
              {...(curCircle && {
                stroke: color,
                strokeWidth: 2,
                style: {
                  cursor: 'grab'
                }
              })}
              {...(isCreate && {
                pointerEvents: isCreate ? 'none' : 'auto'
              })}
              {...(curCircle &&
                isDrag && {
                  style: {
                    cursor: 'grabbing'
                  }
                })}
              fill={curCircle ? theme.colors.fg : color}
            ></circle>
            <circle
              style={{ pointerEvents: 'none' }}
              cx={cx}
              cy={cy}
              r={1}
              stroke="none"
              fill={theme.colors.bg}
            />
          </>
        );
      }),
    [
      color,
      curSelPointId,
      handlePointMouseDown,
      handlePointMouseMove,
      isActive,
      isCreate,
      isDotSel,
      isDrag,
      isNavigate,
      isSelect,
      polygonPoints,
      theme.colors.bg,
      theme.colors.fg
    ]
  );

  if (isHidden) return null;

  return (
    <Group
      data-unit-id={unitId}
      ref={refPolyWrapper}
      color={color}
      selected={isSelected}
      {...{
        onMouseDown: handlePolyMouseDown
      }}
    >
      {!isPolyAddPoint && !isCreate && !isDotSel && (
        <rect
          onMouseMove={handleRectMouseMove}
          ref={setRefRect}
          width="100%"
          cursor="grab"
          height="100%"
          strokeWidth={2}
          fill={'transparent'}
        />
      )}
      <polygon
        style={{
          cursor: isDotSel ? 'copy' : 'auto',
          pointerEvents: isDotSel ? 'visibleStroke' : 'none'
        }}
        ref={refPoly}
        fill={`${color}50`}
        strokeWidth={2}
        strokeDasharray={
          isActive && (isCreate || isPolyAddPoint || isDotSel) ? '3 1' : 0
        }
        stroke={color}
        points={polygonPoints?.join(' ')}
      />
      {isActive && (isDotSel || isCreate) && polyPointsControls}
      {isSelected && polygonPoints?.length >= 2 && (
        <UnitToolbarWithLabelSelect
          onEdit={() => onSetCurMode(AnnotationSystemModesEnum.DOT_SELECT)}
          unitProps={props}
          onCopy={handleCopy}
          show={isActive && !isResize && !isDrag}
          svgRef={{ current: refRect }}
          label={label}
          color={color}
          absolutePos={true}
        />
      )}
    </Group>
  );
}
