import { GetProjectDetailsChartDataQuery, Project } from 'generated/graphql';

export const getTasksCount = (
  data: Project | GetProjectDetailsChartDataQuery['project']
) => {
  const projectStats = data?.projectStats && data?.projectStats[0];

  const completedTasksCount = projectStats?.completedTasksCount ?? 0;
  const inAuditTasksCount = projectStats?.inAuditTasksCount ?? 0;
  const newTasksCount = projectStats?.newTasksCount ?? 0;
  const readyForAuditTasksCount = projectStats?.readyForAuditTasksCount ?? 0;
  const rejectedTasksCount = projectStats?.rejectedTasksCount ?? 0;
  const startedTasksCount = projectStats?.startedTasksCount ?? 0;
  const totalTasksCount = projectStats?.totalTasksCount ?? 0;
  const allTasks = data
    ? completedTasksCount +
      inAuditTasksCount +
      newTasksCount +
      readyForAuditTasksCount +
      rejectedTasksCount +
      startedTasksCount
    : 0;

  return {
    completedTasksCount,
    inAuditTasksCount,
    newTasksCount,
    readyForAuditTasksCount,
    rejectedTasksCount,
    startedTasksCount,
    totalTasksCount,
    allTasks
  };
};
