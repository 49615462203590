import React from 'react';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useAnnotationDelete from 'containers/AnnotationSystem/hooks/useAnnotationDelete';
import AnnotationLabelItem from 'containers/TasksRouter/AnnotationView/components/AnnotationSidebar/AnnotationLabelsTab/AnnotationLabelItem';
import { LabelProps } from 'containers/TasksRouter/AnnotationView/components/AnnotationSidebar/types';
import useCurSelUnitProps from 'containers/AnnotationSystem/hooks/useCurSelUnitProps';
import useAnnotationVideo from 'containers/AnnotationSystem/hooks/useAnnotationVideo';

function Label({
  item,
  color,
  isActive = false,
  children,
  lastFrame,
  lastTime
}: React.PropsWithChildren<LabelProps>) {
  const { unitId } = item;
  const { hideAnnotationUnitIds, isUnitCreatingProcess } =
    useAnnotationSystemState();
  const { polygonPoints } = useCurSelUnitProps();

  const {
    onSetHideAnnotationUnitId,
    onSetCurEvent,
    onSetCurSelUnitId,
    onDeletePolygonPoint
  } = useAnnotationSystemActions();

  const { onSetCurTime } = useAnnotationVideo();

  const handleShow = () => {
    const ids = hideAnnotationUnitIds.includes(unitId)
      ? hideAnnotationUnitIds.filter((i) => i !== unitId)
      : [...hideAnnotationUnitIds, unitId];
    onSetCurSelUnitId(unitId);
    onSetHideAnnotationUnitId(ids);
  };

  const handleClick = () => {
    onSetCurSelUnitId(unitId);
    /**
     *  TODO: Make EVENT smth like "go to unit", that would combine mode changes,
     *        cur sel unit change and some additional logic. This is DUPLICATE of same thing in Toolbar for Polygons
     */
    if (isUnitCreatingProcess) {
      polygonPoints && onDeletePolygonPoint(polygonPoints?.length - 1);
      onSetCurEvent(null);
    }

    onSetCurSelUnitId(unitId);
  };

  const onDelete = useAnnotationDelete();

  return (
    <AnnotationLabelItem
      onClick={handleClick}
      onSetVidTime={onSetCurTime}
      color={color}
      key={item.unitId}
      data={item}
      isActive={isActive}
      onShow={handleShow}
      onDelete={onDelete}
      isShow={!hideAnnotationUnitIds.includes(item.unitId)}
      children={children}
      lastFrame={lastFrame}
      lastTime={lastTime}
    />
  );
}

export default Label;
