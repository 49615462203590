import { CHUNK_LENGTH } from 'appConstants';
import parseSubType from 'utils/parseSubType';
import { TNewTask } from '../Tasks.types';
import { TaskingType } from 'appTypes';

export default function getTaskingUrl(
  taskData: TNewTask,
  finalProjectId: number,
  type: TaskingType = 'tasking',
  topicId?: number,
  lessonId?: number
) {
  const taskId = taskData.id;
  const title = taskData?.project?.title;
  const specType = parseSubType(
    taskData?.project?.projectSettings?.annotationType ??
      taskData?.project?.projectSettings?.subType
  );
  const withFrames = taskData?.project?.projectSettings?.processingType
    ? taskData?.project?.projectSettings?.processingType === 'frames'
    : taskData?.project?.projectSettings?.subType?.match('/frames');
  const isVideoAsImage =
    taskData?.project?.projectSettings?.chunkLength !== CHUNK_LENGTH.unlim;

  const params = `?title=${title}${specType ? `&specType=${specType}` : ''}${
    withFrames ? `&frames=1` : ''
  }${isVideoAsImage ? `&isVideoAsImage=1` : ''}`;

  if (type === 'practice') {
    return `/learning/${topicId}/lesson/${lessonId}/${type}/${taskData.type}/${taskId}${params}`;
  }

  return `/tasks/${type}/${taskData.type}/${finalProjectId}/${taskId}${params}`;
}
