import { AnnotationSpecificationType } from 'appTypes';

export default function parseSubType(
  subType: any
): AnnotationSpecificationType | null {
  if (typeof subType !== 'string') {
    return 'box';
  }

  const firstPart = subType.split('/')[0];

  if (firstPart === 'boxes') {
    return 'box';
  }

  if (firstPart === 'humanPoses-17dots') {
    return 'dot';
  }

  if (firstPart === 'polygons') {
    return 'polygon';
  }

  return firstPart as AnnotationSpecificationType;
}
