import React from 'react';
import { TData } from '../Users.types';
import { useToggleHelperAccountMutation } from 'generated/graphql';
import { Modal } from 'components/_modals';
import useGetAssignedProjectTitlesToHelper from 'hooks/useGetAssignedProjectTitlesToHelper';
import getHelperAssignedToProjectWarning from 'utils/getHelperAssignedToProjectWarning';
import { TRefetchQueries } from 'appTypes';

function ModalDisable<
  TDateGeneric extends {
    id: string;
    assignedProjectsList?: string[];
    enabled?: boolean;
  } = TData
>({
  isOpen,
  setModalOpen,
  curData,
  refetchQueries
}: {
  curData: TDateGeneric;
  isOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  refetchQueries: TRefetchQueries;
}) {
  const handleOnClose = () => {
    setModalOpen(false);
  };

  const [toggleAccount, { loading: toggleAccountLoading }] =
    useToggleHelperAccountMutation({
      refetchQueries: () => refetchQueries
    });

  const { data: assignedProjectsList, loading } =
    useGetAssignedProjectTitlesToHelper(parseInt(curData.id, 10));

  return (
    <Modal
      title={'Toggle user availability'}
      isVisible={isOpen}
      okText={'Ok'}
      onOk={() => {
        toggleAccount({
          variables: {
            helperId: parseInt(curData.id, 10)
          }
        });

        handleOnClose();
      }}
      onCancel={() => {
        handleOnClose();
      }}
      loading={toggleAccountLoading || loading}
    >
      {curData.enabled &&
      assignedProjectsList &&
      assignedProjectsList.length > 0 ? (
        getHelperAssignedToProjectWarning(
          assignedProjectsList as string[],
          'disable'
        )
      ) : (
        <div>Are you sure?</div>
      )}
      <div> </div>
    </Modal>
  );
}

export default ModalDisable;
