import { Form } from 'antd';
import Input from 'components/Input';
import InputLabel from 'components/InputLabel';
import { Button, Row, Text } from 'components/_main';
import React, { useState } from 'react';
import { consoleError } from 'utils';
import { toast } from 'utils/toast';
import { useInviteHelpersContext } from '../InviteHelpers.context';

interface Props {
  name?: string;
}

const defFieldName = 'customMessageInviteHelpersRequest';

function CustomHelpersRequestTab({ name = defFieldName }: Props) {
  const { customMessageMeta, onCustomMessageUpdate, onSendCustomRequest } =
    useInviteHelpersContext();
  const [value, setValue] = useState<string>('');

  const handleSendCustomRequest = async () => {
    try {
      if (onSendCustomRequest) {
        await onSendCustomRequest(value);
      }
      toast.success('Message sent successfully ');
    } catch (error) {
      consoleError({
        message: 'Error during sending message for request helpers',
        messageText: value,
        error
      });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newValue = e?.target?.value;

    setValue(newValue);

    if (onCustomMessageUpdate) {
      onCustomMessageUpdate(value);
    }
  };

  return (
    <Form>
      <Row maxWidth="640px" gridGap="10px">
        <InputLabel meta={customMessageMeta} name={name}>
          <Input
            textarea
            placeholder="Write the quick summary of what team do you need"
            rows={6}
            onChange={handleChange}
          />
        </InputLabel>
        <Text colorVariant="light" maxWidth="370px">
          <Text variant="ui-small-bold">Example:</Text> We have 85k images, with
          an average of 2 images per minute, I need a team to annotate all the
          datasets for 5 days.
        </Text>
        {onSendCustomRequest && (
          <Button onClick={handleSendCustomRequest}>Send</Button>
        )}
      </Row>
    </Form>
  );
}

export default CustomHelpersRequestTab;
