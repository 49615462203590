import { Form } from 'antd';
import styled, { css } from 'styled-components/macro';
import { InputLabelProps } from './InputLabel.types';

const FormItem = Form.Item;

export const FormItemStyled = styled(FormItem).withConfig<InputLabelProps>({
  shouldForwardProp: (prop) => {
    return ![
      'forInputField',
      'alignTop',
      'width',
      'layout',
      'small',
      'disableErrors',
      'labelPaddingTop'
    ].includes(prop);
  }
})`
  width: ${({ width }: any) => (width ? `${width}px` : `100%`)};
  margin-bottom: 0 !important;
  height: min-content;

  .ant-col.ant-col-0.ant-form-item-control {
    width: auto;
    flex-grow: 1;
    max-width: 800px;
  }

  .ant-col.ant-form-item-control:nth-of-type(2) {
    padding-left: 16px;
  }

  .ant-col.ant-form-item-control.ant-col-24:nth-of-type(2) {
    padding-left: 0;
  }

  .ant-col.ant-form-item-label {
    width: auto;
    flex-grow: 0;

    label {
      height: max-content;
      white-space: pre-wrap
    };
  }

  &.ant-form-item {
    flex-wrap: nowrap;
  }

  ${({ layout }) =>
    layout === 'horizontal'
      ? css`
          height: 100%;
        `
      : css`
          &.ant-form-item {
            flex-direction: column;
            flex-wrap: nowrap;
          }
        `}}

  .ant-form-item-with-help,
  .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    ${({ layout, forInputField }: any) =>
      layout === 'vertical' &&
      forInputField &&
      css`
        margin-left: 1rem;
      `}

    display: flex;
    align-items: ${({ alignTop }) => (alignTop ? 'flex-start' : 'center')};

    label {
      color: ${({ theme }) => theme.colors.dark};
      font-size: 13px;
      font-weight: 700;
      ${({ alignTop }) =>
        alignTop &&
        css`
          padding-top: 1rem;
        `}

      ${({ layout }) =>
        layout === 'vertical' &&
        css`
          padding-top: 0;
          padding-bottom: 0.5rem;
        `}
    }

    .ant-form-item-control {
      display: grid;
      width: 100%;
      grid-auto-flow: row;
      grid-auto-rows: max-content;
      justify-content: center;
    }
  }

  ${({ small }) =>
    small &&
    css`
      .ant-form-item-label {
        padding: 0;
        margin: 0;

        label {
          font-size: 11px;
          height: 12px;
        }
      }

      .ant-form-item-control {
        input {
          height: 24px;
        }
      }
    `};

  ${({ help }) =>
    !Boolean(help) &&
    css`
      .ant-form-item-explain,
      .ant-form-item-explain.ant-form-item-explain-connected {
        overflow: hidden;
      }
    `};

  ${({ labelPaddingTop }) =>
    labelPaddingTop &&
    css`
      label {
        padding-top: ${labelPaddingTop}px;
      }
    `};
`;
