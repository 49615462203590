import styled from 'styled-components/macro';
import React from 'react';
import { useFormikContext } from 'formik';
import Select from 'components/Select';
import { Select as SelectAntd } from 'antd';
import { ProjectType } from 'appTypes';
import { inputRateRules, InputRateComponent } from 'components/InputRate';
import AntdFormItem from 'components/AntdFormItem';
import { Col, Text } from 'components/_main';
import InputLabelField from 'components/_form/InputLabelField';

const Root = styled.div`
  .ant-row.ant-form-item {
    width: 300px;
  }
`;

export const ProjectRates: React.FC<{
  embedded?: boolean;
  disabled?: boolean;
}> = React.memo(({ embedded = false, disabled }) => {
  const { values } = useFormikContext<any>();
  const prjType = values?.type;

  const renderRatesInput = (
    <Col gridTemplateColumns="120px 120px">
      <InputLabelField
        name="auditorRate"
        label="Auditor Rate, $/h."
        layout="vertical"
        rules={inputRateRules}
        disabled={disabled}
      >
        <InputRateComponent prefix="$" />
      </InputLabelField>

      <InputLabelField
        name="helperRate"
        label="Helper Rate, $/h."
        layout="vertical"
        rules={inputRateRules}
        disabled={disabled}
      >
        <InputRateComponent prefix="$" />
      </InputLabelField>
    </Col>
  );

  if (embedded) {
    return renderRatesInput;
  }

  return (
    <Root>
      <Text bold>Add price</Text>
      <Col>
        <AntdFormItem label={'Type'}>
          <Select defaultValue={'per-picture'}>
            <SelectAntd.Option value={'per-picture'}>
              {prjType === ProjectType.VIDEO_ANNOTATION
                ? 'Per frame'
                : 'Per picture'}
            </SelectAntd.Option>
          </Select>
        </AntdFormItem>
      </Col>
      {renderRatesInput}
    </Root>
  );
});

export default ProjectRates;
