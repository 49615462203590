import React, { useEffect, useState } from 'react';
import { Modal } from 'components/_modals';
import { TTaskDataMapped } from 'containers/TasksRouter/Tasks.types';
import { TaskingType } from 'appTypes';
import { toast } from 'utils/toast';

interface Props {
  taskData: TTaskDataMapped;
  taskingType: TaskingType;
}

function TaskReturnedInfo({ taskData, taskingType }: Props) {
  const [comment, setComment] = useState('');
  const [isReturnedTask, setIsReturnedTask] = useState(false);

  useEffect(() => {
    if (!taskData?.history) return;

    const message =
      taskData.history.find(({ action }) => action === 'RETURN')?.comment ?? '';

    if (!message) return;

    if (taskingType === 'auditing') {
      toast.info('This task was previously returned', 'By the way');
      return;
    }

    setComment(message);
    setIsReturnedTask(true);
  }, [taskData, taskingType]);

  const handleClose = () => {
    setIsReturnedTask(false);
  };

  if (!comment || taskingType === 'auditing') return null;

  return (
    <Modal
      width={440}
      bodyStyle={{ padding: '30px 24px' }}
      title="Returned Task"
      isVisible={isReturnedTask}
      onCancel={handleClose}
      maskClosable={false}
      footer={false}
    >
      {comment}
    </Modal>
  );
}

export default TaskReturnedInfo;
