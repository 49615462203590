import React, { useEffect, useLayoutEffect } from 'react';
import { Route, RouteProps, useSearchParams } from 'react-router-dom';
import ErrorBoundary from 'containers/ErrorBoundary';
import FallbackUI from 'containers/FallbackUI';
import { routes } from 'config';
import getKeyForRoute from 'utils/getKeyForRoute';
import ToastContainer from 'containers/ToastContainer';
import { GlobalStyles } from './App.styles';
import { Routes, Navigate } from 'react-router-dom';
import { DashboardPage } from 'pages';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { useTheme } from 'styled-components/macro';
import { toast } from 'utils/toast';
import { useScript } from 'hooks/useScript';
import { currentRole, isClient } from 'utils';
import { useAppContext } from './App.context';
import TermsPage from 'static/Terms';
import PrivacyPolicyPage from 'static/PrivacyPolicy';
import { Box } from 'components/_main';
import consoleLog from 'utils/consoleLog';
import { varEnv } from 'utils/envVars';
import useHtmlViewportZoom from 'hooks/useHtmlViewportZoom';

interface AppProps {
  disableRedirect?: boolean;
}

const App: React.FC<React.PropsWithChildren<AppProps>> = ({
  disableRedirect
}) => {
  useHtmlViewportZoom();

  useEffect(() => {
    consoleLog(currentRole());
  }, []);

  const userData = useGetUserSessionData();
  const { signOut } = useAppContext();
  const isLoggedIn = userData.isLoggedIn;
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    const error = searchParams.get('error');

    if (!error) {
      return;
    }

    if (error === 'unauth') {
      toast.error('Authorization token are expired. Please, re-login');
      signOut();
    }
  }, [searchParams, signOut]);

  useScript('https://apis.google.com/js/api.js', !isClient());
  useScript('./chatBotScript.js', varEnv.isDev || !isClient());

  return (
    <>
      <GlobalStyles theme={theme} />
      <ErrorBoundary fallback={<FallbackUI />}>
        <Routes key={isLoggedIn ? 1 : 0}>
          <Route
            path="/static/privacy-policy"
            element={
              <Box p="2rem">
                <PrivacyPolicyPage />
              </Box>
            }
          />
          <Route
            path="/static/terms"
            element={
              <Box p="2rem">
                <TermsPage />
              </Box>
            }
          />
          {isLoggedIn ? (
            <Route path="*" element={<DashboardPage />} />
          ) : (
            routes.map((item: RouteProps, idx: number) => (
              <Route
                key={getKeyForRoute(
                  Array.isArray(item.path) ? item.path[0] : item.path,
                  idx
                )}
                {...item}
              />
            ))
          )}
          {!isLoggedIn && !disableRedirect ? (
            <Route path="*" element={<Navigate to="/" />} />
          ) : null}
        </Routes>
        <ToastContainer />
      </ErrorBoundary>
    </>
  );
};

export default App;
