import {
  LearningMaterialsTypes,
  LessonsTypes
} from 'containers/LearningMaterials/LearningMaterial.types';

interface LessonDetails {
  topicId: number;
  lessonId: number;
}

interface LessonDetailsInfo {
  currentTopicIndex: number;
  currentLessonIndex: number;
  currentTopicLessons: LessonsTypes[];
  initialLesson: LessonDetails;
}

export const getLessonInfo = (
  curTopicId: number,
  curLessonId: number,
  learningMaterials: LearningMaterialsTypes[]
): LessonDetailsInfo => {
  const currentTopicIndex = learningMaterials.findIndex(
    (topic) => topic.id === curTopicId
  );
  const currentTopicLessons =
    learningMaterials[currentTopicIndex].lessons ?? [];
  const currentLessonIndex = currentTopicLessons?.findIndex(
    (lesson) => lesson.id === curLessonId
  );
  const initialLesson = {
    topicId: learningMaterials[0]?.id ?? 0,
    lessonId: learningMaterials[0]?.lessons[0]?.id ?? 0
  };

  return {
    currentTopicIndex,
    currentLessonIndex,
    currentTopicLessons,
    initialLesson
  };
};

export const getPrevLesson = (
  curTopicId: number,
  curLessonId: number,
  learningMaterials: LearningMaterialsTypes[]
): LessonDetails => {
  const {
    currentTopicIndex,
    currentTopicLessons,
    currentLessonIndex,
    initialLesson
  } = getLessonInfo(curTopicId, curLessonId, learningMaterials);

  const prevLesson = currentTopicLessons[currentLessonIndex - 1];
  if (prevLesson) {
    return {
      topicId: learningMaterials[currentTopicIndex]?.id,
      lessonId: prevLesson.id
    };
  }

  const prevTopic = learningMaterials[currentTopicIndex - 1];
  if (prevTopic) {
    return {
      topicId: prevTopic.id,
      lessonId: prevTopic.lessons[prevTopic.lessons.length - 1]?.id ?? 0
    };
  }

  return initialLesson;
};

export const getNextLesson = (
  curTopicId: number,
  curLessonId: number,
  learningMaterials: LearningMaterialsTypes[]
): LessonDetails => {
  const {
    currentTopicIndex,
    currentTopicLessons,
    currentLessonIndex,
    initialLesson
  } = getLessonInfo(curTopicId, curLessonId, learningMaterials);

  const nextIncompleteLesson = currentTopicLessons
    .slice(currentLessonIndex + 1)
    .find((lesson) => !lesson.isDone);
  if (nextIncompleteLesson) {
    return {
      topicId: learningMaterials[currentTopicIndex]?.id,
      lessonId: nextIncompleteLesson.id
    };
  }

  const nextTopic = learningMaterials
    .slice(currentTopicIndex + 1)
    .find((topic) => topic.lessons.some((lesson) => !lesson.isDone));
  const nextLesson = nextTopic?.lessons.find((lesson) => !lesson.isDone);
  if (nextTopic && nextLesson) {
    return {
      topicId: nextTopic.id,
      lessonId: nextLesson.id
    };
  }

  const prevTopic = learningMaterials
    .slice(0, currentTopicIndex + 1)
    .find((topic) => topic.lessons.some((lesson) => !lesson.isDone));
  const prevLesson = prevTopic?.lessons.find((lesson) => !lesson.isDone);
  if (prevTopic && prevLesson) {
    return {
      topicId: prevTopic.id,
      lessonId: prevLesson.id
    };
  }

  return initialLesson;
};
