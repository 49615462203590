import { Box, Col, Row, Text } from 'components/_main';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import Icon from 'components/Icon';

interface Props {
  allCardsCount: number;
  cardWidth: number;
  cardGap?: number;
  defaultOpen?: boolean;
}

export default function ExpandTasksBlock({
  children,
  cardWidth,
  cardGap = 0,
  allCardsCount,
  defaultOpen = false
}: PropsWithChildren<Props>) {
  const [isExpanded, setExpanded] = useState(defaultOpen);
  const wrapper = useRef<HTMLDivElement>(null);
  const [cardCount, setCardCount] = useState(0);
  const expandable = allCardsCount > cardCount;

  const handleTrigger = () => {
    if (expandable) {
      setExpanded((previousState) => !previousState);
    }
  };

  useEffect(() => {
    if (wrapper.current) {
      const wrapperWidth = wrapper.current.getBoundingClientRect().width;
      const cardCount = Math.floor(
        (wrapperWidth - cardGap) / (cardWidth + cardGap)
      );
      setCardCount(cardCount);
    }
  }, [cardGap, cardWidth]);

  return (
    <Row gridGap="16px">
      <Box
        overflow="hidden"
        height={isExpanded ? '100%' : '280px'}
        ref={wrapper}
      >
        {children}
      </Box>
      {expandable && (
        <Col
          gridGap="8px"
          displayFlex
          width="100%"
          alignItems="center"
          justifyContent="flex-end"
          onClick={handleTrigger}
        >
          <Text variant="ui-small-bold" colorVariant="primary" upper>
            {isExpanded ? 'View less' : 'View more'}
          </Text>
          <Icon size={12} fontWeight={600} color="primary">
            {isExpanded ? <CaretUp /> : <CaretDown />}
          </Icon>
        </Col>
      )}
    </Row>
  );
}
