import React from 'react';
import FinishedTasksPreview from './FinishedTasksPreview';
import { Project, TaskType, useProjectResultsQuery } from 'generated/graphql';
import AntdLoadingSpin from 'components/AntdLoadingSpin';
import { Box, Row, Text } from 'components/_main';
import { RobotSadImage } from 'assets';
import { useTheme } from 'styled-components/macro';

interface Props {
  projectData: Project;
}

function FinishedTasksPreviewDataProvider({ projectData }: Props) {
  const theme = useTheme();
  const { data, loading } = useProjectResultsQuery({
    variables: {
      projectId: projectData.id
    },
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <AntdLoadingSpin />;
  }

  const isNotExistTypeResult =
    projectData.type !== TaskType.VideoAnnotation &&
    projectData.type !== TaskType.ImageAnnotation;

  if (isNotExistTypeResult || !data?.projectResults?.results) {
    return (
      <Row
        justifyItems="center"
        alignContent="center"
        width="100%"
        height="100%"
      >
        <Box
          width="160px"
          height="160px"
          borderRadius="50%"
          bg={theme.colors.lightest}
        >
          <RobotSadImage width="100%" height="100%" />
        </Box>
        {isNotExistTypeResult ? (
          <Row gridGap="5px">
            <Text variant="custom-20px-bold" textAlign="center">
              No results
            </Text>
            <Text textAlign="center" colorVariant="light">
              Sorry, results preview for this annotation type is not available
              yet
            </Text>
          </Row>
        ) : !data?.projectResults?.results ? (
          <Row gridGap="5px">
            <Text variant="custom-20px-bold" textAlign="center">
              No results
            </Text>
            <Text textAlign="center" colorVariant="light">
              There are no completed tasks yet
            </Text>
          </Row>
        ) : null}
      </Row>
    );
  }

  return (
    <FinishedTasksPreview
      isLoading={loading}
      projectResults={data?.projectResults?.results}
      projectData={projectData}
    />
  );
}

export default FinishedTasksPreviewDataProvider;
