import React from 'react';
import { TData } from '../types';
import { toast } from 'utils/toast';
import { useDeleteClientMutation } from 'generated/graphql';
import { Modal } from 'components/_modals';
import { TRefetchQueries } from 'appTypes';

const Delete: React.FC<{
  curData?: TData;
  isOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  refetchQueries: TRefetchQueries;
}> = ({ isOpen, setModalOpen, curData, refetchQueries }) => {
  const handleOnClose = () => {
    setModalOpen(false);
  };

  const [deleteMutation, { loading }] = useDeleteClientMutation({
    onCompleted: () => {
      toast.success('Client successfully deleted');
      handleOnClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: () => refetchQueries
  });

  return (
    <Modal
      title={'Delete client'}
      isVisible={isOpen}
      okText={'Delete'}
      onOk={() => {
        if (!curData || !curData?.id)
          return console.error('Not curData or curData.id');
        deleteMutation({
          variables: {
            clientId: parseInt(curData.id, 10)
          }
        });
        handleOnClose();
      }}
      onCancel={() => {
        handleOnClose();
      }}
      loading={loading}
    >
      <div>Are you sure?</div>
      <div> </div>
    </Modal>
  );
};

export default Delete;
