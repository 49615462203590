import { HELPERS_LIST, GET_HELPER_BY_ID } from 'graphql/queries/helper';
import {
  useGetHelperByIdQuery,
  useUpdateHelperMutation
} from 'generated/graphql';
import React from 'react';
import { toast } from 'utils/toast';
import { TitleView } from './Title.ui';
import { GET_ASSIGNED_HELPERS_LIST } from 'graphql/queries/helper';

export default function Title({ helperId }: { helperId: number }) {
  const { data, loading: initialLoading } = useGetHelperByIdQuery({
    variables: {
      helperId
    }
  });

  const [updateHelper, { loading }] = useUpdateHelperMutation({
    onCompleted: () => {
      toast.success('Helper successfully created');
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [
      {
        query: HELPERS_LIST
      },
      {
        query: GET_HELPER_BY_ID,
        variables: {
          helperId
        }
      },
      {
        query: GET_ASSIGNED_HELPERS_LIST
      }
    ]
  });

  return (
    <TitleView
      {...data?.helper}
      loading={initialLoading || loading}
      onSubmit={(variables: any) => {
        updateHelper({
          variables: {
            helperId,
            ...variables
          }
        });
      }}
    />
  );
}
