import { upper } from 'utils/_stringUtilsLegacy';
import projectProgressColumn from 'components/Table/_columnRender/projectProgressColumn';
import projectStatusColumn from 'components/Table/_columnRender/projectStatusColumn';
import { ColLegacy } from 'components/_main';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';

const columns = ({
  isFromProjectDetailsCard,
  onEdit
}: {
  isFromProjectDetailsCard?: boolean;
  onEdit: (data: any) => void;
}) => [
  {
    title: upper('project'),
    dataIndex: 'title',
    width: 400,
    key: 'title'
  },
  {
    title: upper('created'),
    width: 100,
    dataIndex: 'formattedCreatedAt',
    key: 'createdAt'
  },
  {
    title: upper('specialization'),
    width: 200,
    dataIndex: 'mappedType',
    key: 'type'
  },
  {
    title: upper('Progress'),
    render: projectProgressColumn(),
    width: 200
  },
  {
    title: upper('Status'),
    render: projectStatusColumn,
    width: 100
  },
  !isFromProjectDetailsCard
    ? {
        title: upper('Actions'),
        render: (data: any) => {
          return (
            <ColLegacy>
              <span onClick={() => onEdit(data)}>
                <EditOutlined />
              </span>
            </ColLegacy>
          );
        },
        width: 50
      }
    : {}
];

export default columns;
