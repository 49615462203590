import gql from 'graphql-tag';
import { CLIENT_DETAIL_FILED } from '../fragments/client';

export const REMIND_PASSWORD = gql`
  mutation RequestPasswordReset($email: String!, $role: AccountType!) {
    RequestPasswordReset(email: $email, role: $role) {
      requested
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation PasswordReset($token: String!, $new_password: String!) {
    PasswordReset(token: $token, new_password: $new_password) {
      reseted
      firstName
    }
  }
`;

export const LOGIN_HELPER = gql`
  mutation LoginHelper($email: String!, $password: String!) {
    loginHelper(email: $email, password: $password) {
      token
      helper {
        id
        firstName
        lastName
        email
        enabled
        isDeleted
        activated
      }
    }
  }
`;

export const LOGIN_CLIENT = gql`
  ${CLIENT_DETAIL_FILED}
  mutation LoginClient($email: String!, $password: String!) {
    loginClient(email: $email, password: $password) {
      token
      client {
        ...ClientDetailsFields
      }
    }
  }
`;

export const LOGIN_CLIENT_BY_GOOGLE = gql`
  ${CLIENT_DETAIL_FILED}
  mutation LoginClientByGoogle($googleToken: String!) {
    loginClientByGoogle(googleToken: $googleToken) {
      token
      client {
        ...ClientDetailsFields
      }
    }
  }
`;

export const LOGIN_ADMIN = gql`
  mutation LoginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      token
      admin {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const CONFIRM_REGISTER_BY_EMAIL = gql`
  mutation ActivateAccount($token: String!) {
    ActivateAccountByToken(token: $token) {
      activated
    }
  }
`;

export const REGISTER_CLIENT = gql`
  ${CLIENT_DETAIL_FILED}
  mutation RegisterClient(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $companyName: String!
    $termsVersion: String!
  ) {
    registerClient(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      companyName: $companyName
      termsVersion: $termsVersion
    ) {
      token
      client {
        ...ClientDetailsFields
      }
    }
  }
`;

export const REGISTER_HELPER = gql`
  mutation RegisterHelper(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    registerHelper(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const RESEND_ACTIVATION_LINK = gql`
  mutation ResendActivationLink {
    reSendActivationLink
  }
`;
