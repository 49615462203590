import { useCallback } from 'react';
import { ProjectDetails } from 'appTypes';
import { EMPTY_PROJECT_DATA } from 'containers/Projects/constants';
import useOpen from 'hooks/useOpen';
import {
  Project,
  ProjectStatus,
  SortByDateOrder,
  TaskType,
  useCopyProjectMutation,
  useGetProjectLazyQuery
} from 'generated/graphql';
import { toast } from 'utils/toast';
import { LIST_PROJECTS } from 'graphql/queries/project';

interface ClientProjectProps {
  currentProjectData: Project;
  page?: number;
  sortMethod?: SortByDateOrder;
  setCurrentProjectData(data: Project): void;
}

const useClientProject = ({
  currentProjectData,
  page,
  sortMethod,
  setCurrentProjectData
}: ClientProjectProps) => {
  const {
    isOpen: isProjectDetailsOpen,
    onOpen: onProjectDetailsOpen,
    onClose: onProjectDetailsClose
  } = useOpen();

  const {
    isOpen: isCreateProjectOpen,
    onOpen: onCreateProjectOpen,
    onClose: onCreateProjectClose
  } = useOpen();

  const {
    isOpen: isRenameProjectOpen,
    onOpen: onRenameProjectOpen,
    onClose: onRenameProjectClose
  } = useOpen();

  const {
    isOpen: isPricingPlansOpen,
    onOpen: onPricingPlansOpen,
    onClose: onPricingPlansClose
  } = useOpen();

  const [getProjectData] = useGetProjectLazyQuery();

  const handleDraftProjectOpen = useCallback(
    async (id: number) => {
      try {
        const data = await getProjectData({
          variables: {
            projectId: id
          }
        });
        if (data?.data?.project) {
          setCurrentProjectData(data.data.project);
          onCreateProjectOpen();
        }
      } catch (e: any) {
        e && e?.message && toast.error(e?.message);
      }
    },
    [getProjectData, onCreateProjectOpen, setCurrentProjectData]
  );

  const handleProjectDetailsOpen = useCallback(
    (data: ProjectDetails) => {
      if (data.status === ProjectStatus.Draft) {
        handleDraftProjectOpen(data.id);
        return;
      }

      setCurrentProjectData(data);
      onProjectDetailsOpen();
    },
    [handleDraftProjectOpen, onProjectDetailsOpen, setCurrentProjectData]
  );

  const handleProjectDetailsClose = useCallback(() => {
    onProjectDetailsClose();
    setTimeout(() => {
      setCurrentProjectData(EMPTY_PROJECT_DATA);
    }, 300);
  }, [onProjectDetailsClose, setCurrentProjectData]);

  const handleAddProjectClick = useCallback(() => {
    setCurrentProjectData(EMPTY_PROJECT_DATA);
    onCreateProjectOpen();
  }, [onCreateProjectOpen, setCurrentProjectData]);

  const [copyProject] = useCopyProjectMutation({
    refetchQueries: [
      {
        query: LIST_PROJECTS,
        variables: {
          page,
          sortByDateOrder: sortMethod
        }
      }
    ]
  });

  const handleCopyProject = useCallback(
    (id: number, newTitle: string) => {
      return copyProject({
        variables: {
          projectId: id,
          newTitle: newTitle
        }
      });
    },
    [copyProject]
  );

  const handleProjectRename = (newTitle: string) => {
    if (!currentProjectData || !currentProjectData.id) {
      console.error('No project id');
      return;
    }

    return handleCopyProject(currentProjectData.id, newTitle);
  };

  const handleProjectRenameOpen = useCallback(
    (data: ProjectDetails) => {
      setCurrentProjectData(data);
      onRenameProjectOpen();
    },
    [onRenameProjectOpen, setCurrentProjectData]
  );

  const handlePricingPlansUpgrade = useCallback(() => {
    onPricingPlansOpen();
  }, [onPricingPlansOpen]);

  const onProjectRenameOk = async (newName: string) => {
    try {
      await handleProjectRename(newName);
      onRenameProjectClose();
      toast.success('Project successfully copied');
    } catch (e) {
      toast.error('Please, use unique name');
    }
  };

  const onProjectRenameCancel = () => {
    onRenameProjectClose();
  };

  return {
    isProjectDetailsOpen,
    isCreateProjectOpen,
    isRenameProjectOpen,
    isPricingPlansOpen,
    onCreateProjectClose,
    onPricingPlansClose,
    onProjectRenameOk,
    onProjectRenameCancel,
    handleProjectDetailsOpen,
    handleProjectDetailsClose,
    handleAddProjectClick,
    handlePricingPlansUpgrade,
    handleProjectRenameOpen
  };
};

export default useClientProject;
