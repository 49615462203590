import React from 'react';
import { Row } from '../../../../components/_main';
import { useAddProjectWizardContext } from 'containers/AddProjectWizard/AddProjectWizard.context';
import { consoleError, isAdmin } from 'utils';
import {
  VideoAnnotationDatasetSplitByType,
  VideoAnnotationDatasetProcessingType
} from 'appTypes';
import { useField } from 'formik';
import { ADD_WIZARD_FIELDS } from 'appConstants';
import {
  UploadFiles,
  convertToFileStates,
  UploadFilesContextProviderProps,
  UploadOnSuccess,
  UploadOnError
} from 'containers/UploadFiles';
import { FormikError } from 'components/_form';
import { toast } from 'utils/toast';
import useGetUserSessionData from 'hooks/useGetUserSessionData';

interface StepUploadDatasetVideoSingleDataProviderProps {
  mediaFileNameName?: string;
  uploadFilesBlock?: React.ReactNode;
}

const accept = 'video/avi, video/mp4';

const maxTotalSize = 500_000_000;

function handleVideoFileDuration(
  videoFile: File,
  onSuccess: (
    videoFile: File,
    duration: number,
    fileNameFromBe?: string
  ) => void,
  fileNameFromBe?: string
) {
  if (!videoFile) {
    return;
  }

  const video = document.createElement('video');

  window.URL = window.URL || window.webkitURL;

  video.preload = 'metadata';

  video.onloadedmetadata = (ev) => {
    const vid: any = ev.currentTarget;
    const duration = (vid?.duration as any) ?? 0;
    setTimeout(() => {
      onSuccess(videoFile, duration, fileNameFromBe);
    }, 100);
    window.URL.revokeObjectURL(video.src);
    video.remove();
  };

  video.src = window.URL.createObjectURL(videoFile);
}

/** Video annotation. Single long video file */
function StepUploadDatasetVideoSingleDataProvider({
  mediaFileNameName = 'mediaFileName',
  uploadFilesBlock
}: StepUploadDatasetVideoSingleDataProviderProps) {
  /** TODO: Move those accept to .env or constants */
  const {
    onSetVideoMediaFile,
    videoMediaFile,
    videoAnnotationSpecSubType,
    videoAnnotationSplitType
  } = useAddProjectWizardContext();
  const { isPremium } = useGetUserSessionData();

  const [, , helper] = useField(ADD_WIZARD_FIELDS.media);

  const handleSuccess: UploadOnSuccess = ({ files, fileNameFromBe }) => {
    helper.setError(undefined);
    const newFile = files?.[0];
    if (!newFile) {
      consoleError({ error: 'No video file uploaded', files });
      return;
    }

    if (
      videoAnnotationSpecSubType ===
        VideoAnnotationDatasetProcessingType.SPLIT_BY_FRAMES &&
      videoAnnotationSplitType ===
        VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE &&
      !newFile.name.match('.avi$')
    ) {
      handleVideoFileDuration(newFile, onSetVideoMediaFile, fileNameFromBe);
      return;
    }

    onSetVideoMediaFile(newFile, 0, fileNameFromBe);
  };

  const handleError: UploadOnError = (_, error) => {
    if (!error) return;
    error && toast.error(error);
    helper.setError(error);
    helper.setTouched(true);
  };

  let uploadSizeLimit = isPremium ? null : maxTotalSize;

  if (isAdmin()) {
    uploadSizeLimit = null;
  }

  return (
    <Row>
      <StepUploadDatasetVideoSingle
        initialData={{
          initialFiles: convertToFileStates([videoMediaFile] as File[]),
          onDelete: () => {
            onSetVideoMediaFile(null);
          },
          onFilesUpdate: () => {
            helper.setError(undefined);
          },
          onSuccess: handleSuccess,
          onError: handleError,
          maxTotalSize: uploadSizeLimit
        }}
        uploaderProps={{
          uploadFilesBlock,
          accept: accept,
          maxFiles: 1
        }}
      />
      <FormikError name={mediaFileNameName} />
    </Row>
  );
}

function StepUploadDatasetVideoSingle(props: UploadFilesContextProviderProps) {
  return (
    <Row>
      <UploadFiles {...props} />
    </Row>
  );
}

export default StepUploadDatasetVideoSingleDataProvider;
