import React from 'react';
import useAnnotationDotsGroupDelete from './useAnnotationDotsGroupDelete';
import useAnnotationUnitDelete from './useAnnotationUnitDelete';
import useCurSelUnitProps from './useCurSelUnitProps';

export default function useAnnotationDelete() {
  const curSelUnitProps = useCurSelUnitProps();
  const handleDotsGroupsDelete = useAnnotationDotsGroupDelete();
  const handleUnitDelete = useAnnotationUnitDelete();

  if (curSelUnitProps?.type === 'wrapper' || curSelUnitProps?.type === 'dot') {
    return handleDotsGroupsDelete;
  }

  return handleUnitDelete;
}
