import {
  AnnotationSystemEventsEnum,
  AnnotationUnitCoords
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { preventResizeInverse } from 'containers/AnnotationSystem/utils';
import useMousePositionRef, {
  MousePosStateRef
} from 'hooks/useMousePositionRef';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

interface Props
  extends Pick<
    ReturnType<typeof useMousePositionRef>,
    'onStart' | 'onReset' | 'stateRef'
  > {
  svgRef: React.MutableRefObject<SVGSVGElement | null> | null;
}

export default function useMouseDown({
  svgRef,
  onStart,
  onReset,
  stateRef
}: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const preFinalPosStateRef = useRef<AnnotationUnitCoords | null>(null);

  const { curEvent, svgLayerRef } = useAnnotationSystemState();
  const { onSetCurEvent } = useAnnotationSystemActions();

  const handleMouseMoveStart = useCallback(
    (state: MutableRefObject<MousePosStateRef>) => {
      const svgElement = svgRef?.current;

      if (!svgElement) return;

      const { x, w, h, y } = preventResizeInverse(state.current);

      svgElement?.setAttribute('x', x.toString());
      svgElement?.setAttribute('y', y.toString());
      svgElement?.setAttribute('height', h.toString());
      svgElement?.setAttribute('width', w.toString());
    },
    [svgRef]
  );

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      switch (curEvent) {
        case null:
        case AnnotationSystemEventsEnum.CREATE_MODE: {
          onStart({
            event,
            initPos: {
              x: 0,
              y: 0,
              w: 0,
              h: 0
            },
            onUpdate: handleMouseMoveStart
          });
          setIsClicked(true);
          onSetCurEvent(AnnotationSystemEventsEnum.CREATING_SIZE);
          return;
        }
        case AnnotationSystemEventsEnum.CREATING_SIZE: {
          preFinalPosStateRef.current = preventResizeInverse(stateRef.current);
          setShowLabel(true);
          onSetCurEvent(AnnotationSystemEventsEnum.CREATING_LABEL);
          return;
        }
        case AnnotationSystemEventsEnum.CREATING_LABEL: {
          return;
        }
      }
    },
    [curEvent, handleMouseMoveStart, onSetCurEvent, onStart, stateRef]
  );

  useEffect(() => {
    onReset();
  }, [onReset]);

  useEffect(() => {
    if (!svgLayerRef) return;

    // @ts-ignore
    svgLayerRef?.addEventListener('mousedown', handleMouseDown);
    return () => {
      // @ts-ignore
      svgLayerRef?.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown, svgLayerRef]);

  return useMemo(
    () => ({
      isClicked,
      showLabel,
      preFinalPosStateRef,
      setShowLabel,
      setIsClicked
    }),
    [isClicked, showLabel, setShowLabel, setIsClicked]
  );
}
