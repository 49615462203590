import {
  AnnotationUnitProps,
  SvgLayerMoveChangeProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { getAnnotationUnitsWrapperSize } from 'containers/AnnotationSystem/utils';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  groupIndex?: number;
  unitId: string;
}

export default function useUpdateOuterRectOnDotsChange({
  groupIndex,
  unitId
}: Props) {
  const { onModifyAnnotationUnit } = useAnnotationSystemActions();
  const { annotationUnits, curZoomLevel } = useAnnotationSystemState();

  const onModifyAnnotationUnitDebounced = useDebouncedCallback(
    onModifyAnnotationUnit,
    50
  );

  const sameGroupIndexWrapper = annotationUnits.findIndex(
    (unit) => unit.groupIndex === groupIndex && unit.type === 'wrapper'
  );

  if (sameGroupIndexWrapper === -1) {
    return () => {};
  }

  const sameGroupIndexDots = annotationUnits.filter(
    (unit) => unit.groupIndex === groupIndex && unit.type === 'dot'
  );

  const wrapper = annotationUnits[sameGroupIndexWrapper];
  const dotsSameGroupIndexWithoutWrapper = sameGroupIndexDots.filter(
    (unit) => unit.unitId !== wrapper.unitId
  );

  const dotPrevProps = annotationUnits.find(
    (dot) => dot.unitId === unitId
  ) as AnnotationUnitProps;

  return ({ newX, newY }: SvgLayerMoveChangeProps) => {
    if (!newX || !newY) {
      return;
    }

    const newWrapper: AnnotationUnitProps = {
      ...wrapper,
      ...getAnnotationUnitsWrapperSize([
        ...dotsSameGroupIndexWithoutWrapper.filter(
          (dot) => dot.unitId !== unitId
        ),
        {
          ...dotPrevProps,
          x: newX / curZoomLevel,
          y: newY / curZoomLevel
        }
      ])
    };

    onModifyAnnotationUnitDebounced(newWrapper);
  };
}
