import { v4 as uuidv4 } from 'uuid';
import { useAnnotationSystemActions } from 'containers/AnnotationSystem/context';
import useCurSelUnitProps from 'containers/AnnotationSystem/hooks/useCurSelUnitProps';
import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { DEFAULT_COPY_SHIFT } from 'containers/AnnotationSystem/AnnotationSystem.constants';

export default function useCopy() {
  const curSelUnitProps = useCurSelUnitProps();
  const { onAddAnnotationUnit } = useAnnotationSystemActions();

  return () => {
    if (!curSelUnitProps) {
      return;
    }

    const newId = uuidv4();

    const copyAnnotationUnit: AnnotationUnitProps = {
      ...curSelUnitProps,
      unitId: newId,
      polygonPoints: curSelUnitProps.polygonPoints?.map((point) => [
        point[0] + DEFAULT_COPY_SHIFT,
        point[1] + DEFAULT_COPY_SHIFT
      ])
    };

    onAddAnnotationUnit(copyAnnotationUnit);
  };
}
