import React from 'react';
import { ToolbarSection } from './AnnotationToolbar.ui';
import SectionZoom from './SectionZoom';
import SectionHand from './SectionHand';

export default function ContentModerationToolbar() {
  return (
    <>
      <SectionHand />
      <SectionZoom />
    </>
  );
}
