import React, { useState } from 'react';
import { TabsArea as TabsAreaBase, TabTitle } from './HelperDetailsCard.ui';
import { Tabs } from 'components/Tabs/Tabs.styled';

function TabsArea({
  children,
  defaultTabKey
}: React.PropsWithChildren<{ defaultTabKey: string }>) {
  const [tab, setTab] = useState<string | undefined>();

  const tabs = React.Children.toArray(children).map((element) => {
    const comp = React.isValidElement(element) ? element : null;
    if (!comp) return null;
    const key = comp.key;
    const { title } = comp.props;
    return (
      <Tabs.TabPane
        key={key}
        tab={
          <TabTitle data-testid={comp.props?.['data-testid']}>{title}</TabTitle>
        }
      >
        {comp}
      </Tabs.TabPane>
    );
  });

  return (
    <TabsAreaBase>
      <Tabs
        activeKey={tab}
        onChange={(tabKey) => setTab(tabKey)}
        defaultActiveKey={defaultTabKey}
      >
        {tabs}
      </Tabs>
    </TabsAreaBase>
  );
}

export default TabsArea;
