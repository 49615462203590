import React, { useCallback } from 'react';

function useBeforeCloseTab({
  isChanged = true,
  actionBeforeClose = async () => {}
}: {
  isChanged?: boolean;
  actionBeforeClose?: () => Promise<void>;
}) {
  const onBeforeUnload = useCallback(
    async (e: any) => {
      if (!e) e = window.event;
      e.cancelBubble = true;

      if (isChanged) {
        e.preventDefault();
        await actionBeforeClose();
        e.returnValue = '';
        return '';
      }
      delete e['returnValue'];
      return;
    },
    [actionBeforeClose, isChanged]
  );

  React.useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload]);
}

export default useBeforeCloseTab;
