import { TaskList } from 'generated/graphql';

export const COUNT_PER_PAGE = 60;

export const STATUS_OPTIONS = [
  { id: '1', label: 'submitted', color: 'light' },
  { id: '2', label: 'available', color: 'primary' },
  { id: '3', label: 'in progress', color: 'orange' }
];

export const getTaskIndex = (data: TaskList[] | null = [], taskId: number) => {
  const index = data?.findIndex((el) => taskId === el.taskId) ?? 0;

  if (index === -1) {
    return 0;
  }

  return index + 1;
};

export const frames: TaskList[] = [
  { taskId: 1, mediaURL: '', isAvailable: false },
  { taskId: 2, mediaURL: '', isAvailable: false },
  { taskId: 3, mediaURL: '', isAvailable: true },
  { taskId: 4, mediaURL: '', isAvailable: true },
  { taskId: 5, mediaURL: '', isAvailable: true },
  { taskId: 6, mediaURL: '', isAvailable: false },
  { taskId: 7, mediaURL: '', isAvailable: false },
  { taskId: 895, mediaURL: '', isAvailable: true },
  { taskId: 9, mediaURL: '', isAvailable: true },
  { taskId: 10, mediaURL: '', isAvailable: true },
  { taskId: 11, mediaURL: '', isAvailable: true },
  { taskId: 12, mediaURL: '', isAvailable: true },
  { taskId: 13, mediaURL: '', isAvailable: true },
  { taskId: 14, mediaURL: '', isAvailable: true },
  { taskId: 15, mediaURL: '', isAvailable: true },
  { taskId: 16, mediaURL: '', isAvailable: false },
  { taskId: 17, mediaURL: '', isAvailable: false },
  { taskId: 18, mediaURL: '', isAvailable: true },
  { taskId: 19, mediaURL: '', isAvailable: true },
  { taskId: 20, mediaURL: '', isAvailable: true },
  { taskId: 21, mediaURL: '', isAvailable: true },
  { taskId: 22, mediaURL: '', isAvailable: true },
  { taskId: 23, mediaURL: '', isAvailable: true },
  { taskId: 24, mediaURL: '', isAvailable: true },
  { taskId: 25, mediaURL: '', isAvailable: true },
  { taskId: 26, mediaURL: '', isAvailable: false },
  { taskId: 27, mediaURL: '', isAvailable: false },
  { taskId: 28, mediaURL: '', isAvailable: true },
  { taskId: 29, mediaURL: '', isAvailable: true },
  { taskId: 30, mediaURL: '', isAvailable: true },
  { taskId: 31, mediaURL: '', isAvailable: true },
  { taskId: 32, mediaURL: '', isAvailable: true },
  { taskId: 33, mediaURL: '', isAvailable: true },
  { taskId: 34, mediaURL: '', isAvailable: true },
  { taskId: 35, mediaURL: '', isAvailable: true },
  { taskId: 36, mediaURL: '', isAvailable: false },
  { taskId: 37, mediaURL: '', isAvailable: false },
  { taskId: 38, mediaURL: '', isAvailable: true },
  { taskId: 39, mediaURL: '', isAvailable: true },
  { taskId: 40, mediaURL: '', isAvailable: true },
  { taskId: 41, mediaURL: '', isAvailable: true },
  { taskId: 42, mediaURL: '', isAvailable: true }
];
