import { useEffect, useMemo } from 'react';
import { Project, useListProjectsLazyQuery } from 'generated/graphql';

export type TUseGetListProjectsItemData = {} & Pick<
  Project,
  'title' | 'id' | 'isDeleted'
>;

const useGetListProjects = (
  clientId?: string | number | null,
  isClientRequired?: boolean,
  showArchived: boolean = false,
  isAll: boolean = false,
  isReadyOnly?: boolean
) => {
  const [query, { data, loading }] = useListProjectsLazyQuery({
    variables: {
      isReadyForReport: isReadyOnly,
      ...(isAll ? { returnAllData: true } : {})
    }
  });

  useEffect(() => {
    if (isClientRequired) {
      clientId &&
        query({
          variables: {
            clientId:
              typeof clientId === 'number' ? clientId : parseInt(clientId, 10)
          }
        });
    } else {
      clientId
        ? query({
            variables: {
              clientId:
                typeof clientId === 'number' ? clientId : parseInt(clientId, 10)
            }
          })
        : query();
    }
  }, [clientId, isClientRequired, query]);

  const finalData = useMemo(() => {
    return data?.listProjects?.projects?.reduce(
      (accum: TUseGetListProjectsItemData[], value) => {
        value &&
          (showArchived || !value.isDeleted) &&
          accum.push({
            id: value?.id,
            isDeleted: value?.isDeleted,
            title: value?.title
          });

        return accum;
      },
      []
    );
  }, [data, showArchived]);

  return {
    data: finalData,
    loading: loading
  };
};

export default useGetListProjects;
