import { Dropdown, Menu } from 'antd';
import { VIDEO_ANNOTATION_PREDEFINED_FRAMES_INTERVALS } from 'appConstants';
import { Col, Text } from 'components/_main';

interface Props {
  setStep(i: number): void;
  step: number;
}

export default function ChangeFrameStepDropdown({ step, setStep }: Props) {
  const menu = (
    <Menu
      onClick={(e) => {
        setStep(parseInt(e.key));
      }}
      items={VIDEO_ANNOTATION_PREDEFINED_FRAMES_INTERVALS}
    />
  );

  return (
    <Dropdown placement="top" overlay={menu} trigger={['click']}>
      <Col minWidth="20px" flexCenter>
        <Text fontWeight="600">{`${step}`}</Text>
      </Col>
    </Dropdown>
  );
}
