import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  TaskList,
  useGetTaskByIdLazyQuery,
  useSelectTaskMutation
} from 'generated/graphql';
import getTaskingUrl from 'containers/TasksRouter/utils/getTaskingUrl';
import { toast } from 'utils/toast';
import { useAnnotationSystemActions } from 'containers/AnnotationSystem/context';
import { TaskingUrlParams } from 'containers/TasksRouter/AnnotationView/AnnotationView.types';
import { TNewTask } from 'containers/TasksRouter/Tasks.types';

const useSelectTask = ({ refetch, setTaskData }: any) => {
  const { projectId: projectIdParams, auditingType }: TaskingUrlParams =
    useParams<Record<string, keyof TaskingUrlParams>>();
  const projectId = parseInt(projectIdParams ?? '', 10);

  const [selectTask] = useSelectTaskMutation();
  const [getTaskById] = useGetTaskByIdLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const { onSetIsLoading } = useAnnotationSystemActions();

  const completeSelectTask = useCallback(
    (task?: TNewTask | null, isAvailable?: boolean) => {
      if (!task) return;
      refetch();
      setTaskData({ ...task, isAvailableForHelper: isAvailable });
      const taskUrl = getTaskingUrl(task, projectId, auditingType);
      window.history.replaceState('', '', `${taskUrl}`);
      toast.success(`Task ${task.id} selected`);
    },
    [projectId, auditingType, refetch, setTaskData]
  );

  const handleSelectTask = useCallback(
    ({ taskId, isAvailable }: TaskList) => {
      onSetIsLoading(true);
      if (isAvailable) {
        selectTask({
          variables: {
            taskId
          },
          onCompleted: ({ selectTask }) => {
            completeSelectTask(selectTask, isAvailable);
          },
          onError: (error) => {
            refetch();
            toast.error(error.message);
          }
        });
        return;
      }
      getTaskById({
        variables: { taskId },
        onCompleted: ({ task }) => {
          completeSelectTask(task, isAvailable);
        },
        onError: (error) => {
          refetch();
          toast.error(error.message);
        }
      });
    },
    [onSetIsLoading, refetch, selectTask, getTaskById, completeSelectTask]
  );
  return [handleSelectTask];
};

export default useSelectTask;
