import AuthPageViewNew from 'containers/AuthPageViewNew';
import Result from 'containers/Result';
import { ActionParams } from 'containers/Result/Result';
import React from 'react';

const actionConfirmationParams: ActionParams = {
  actionMessage: 'Didn’t get an e-mail?',
  path: '/signup',
  linkTitle: 'Resend'
};

export default function ForgotPasswordEmailPage() {
  return (
    <AuthPageViewNew>
      <AuthPageViewNew.PaneChild>
        <Result
          resultTitle="Password Reset Sent!"
          resultMessage="Password reset instructions were sent to your email address.
                Check your email"
          actionParams={actionConfirmationParams}
        />
      </AuthPageViewNew.PaneChild>
    </AuthPageViewNew>
  );
}
