import { BoxStyledProps } from 'components/_main/Box';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import defaultTheme from 'theme';
import { IconStyled } from './styled';
import { IconProps } from './types';

const convertThemeColorNamesToHash = (
  colors: Record<string, any>,
  theme: typeof defaultTheme
) => {
  const convertedColors: Record<string, any> = {};
  for (const color in colors) {
    convertedColors[color] =
      colors[color] in theme['colors']
        ? theme.colors[colors[color]]
        : colors[color];
  }

  return convertedColors;
};

export default function Icon({
  children,
  onClick,
  ref,
  ...restProps
}: React.PropsWithChildren<IconProps & BoxStyledProps>) {
  const theme = useTheme();
  const { disabled } = restProps;
  const convertedColors = convertThemeColorNamesToHash(restProps, theme);
  return (
    <IconStyled
      ref={ref}
      {...convertedColors}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </IconStyled>
  );
}
