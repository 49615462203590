import { Z_INDEXES } from 'appConstants';
import { createGlobalStyle, css } from 'styled-components/macro';
import theme, { scrollStyled } from 'theme';

export interface CustomAntContentProps {
  flex?: string;
  bg?: string;
}

interface GlobalStylesProps {
  isStorybook?: boolean;
}

const GlobalStyles = createGlobalStyle(
  ({ isStorybook }: GlobalStylesProps) => css`
    ${!isStorybook &&
    css`
      html,
      body,
      #root {
        width: 100%;
        height: 100%;
        max-width: 100vw;
        overflow-x: hidden;
      }
    `}

    * {
      outline: none;
      box-sizing: border-box;
      &:focus,
      &:active {
        outline: none;
      }
    }

    div,
    [data-test-id='virtuoso-scroller'] {
      ${scrollStyled};
    }

    .ant-spin.ant-spin-spinning {
      max-height: 100% !important;
    }
    .ant-spin-dot.ant-spin-dot-spin {
      & > i {
        background-color: ${({ theme }) => theme.colors.primary};
      }
    }

    div {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }

    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    body {
      margin: 0;
      font-family: ${theme.fonts.main.family}, Roboto, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: ${theme.fonts.main.weight};
    }

    html {
      --antd-wave-shadow-color: ${({ theme }) => theme.colors.primary};
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    ::selection {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
      background-color: transparent !important;
    }

    .ant-input,
    .ant-input-affix-wrapper.ant-input-password,
    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper,
    .ant-form-item .ant-form-item-control .ant-input-affix-wrapper {
      &:active,
      &::selection,
      ::selection,
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.primary};
        box-shadow: none;
      }
      &:disabled {
        border-color: transparent;
      }

      box-shadow: none;
    }

    .ant-col.ant-form-item-control:nth-of-type(2) {
      padding-left: 16px;
    }

    .ant-col.ant-form-item-control.ant-col-24:nth-of-type(2) {
      padding-left: 0;
    }

    .ant-form-item label:after {
      content: none;
    }

    .ant-input-affix-wrapper-focused {
      border-color: ${({ theme }) => theme.colors.primary} !important;
    }

    .ant-select-dropdown {
      z-index: ${Z_INDEXES.dropdown};
    }

    [role='alert'] {
    }

    .ant-modal-wrap {
      z-index: ${Z_INDEXES.modal};
    }

    .ant-modal-body {
      padding: 40px 50px;

      > div:first-of-type,
      > div:first-of-type > div:first-child {
        height: 100%;
      }
    }

    .ant-col-0 {
      display: block;
    }

    .ant-pagination-item {
      font-family: 'Montserrat';
    }

    .ant-dropdown-menu {
      border-radius: 10px;
      box-shadow: 0 4px 10px 0 ${({ theme }) => theme.colors.shadow};
    }

    .ant-dropdown-menu-item {
      padding: 10px 20px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightest};

      &:last-child {
        border-bottom: none;
      }
    }

    .ant-modal-content {
      border-radius: 5px;
      box-shadow: none;
      max-height: 96vh;
      overflow: auto;
    }

    .ant-modal-header {
      padding: 19px 24px;
      border-bottom: none;
      background-color: ${({ theme }) => theme.colors.lightest};
    }

    .ant-modal-title {
      font-weight: 700;
    }

    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 24px;
    }

    .ant-tooltip-inner {
      min-height: 20px;
      font-size: 12px;
      padding: 3px 12px;
      border-radius: 30px;
      background-color: ${({ theme }) => theme.colors.dark};
      opacity: 0.9;
    }

    .ant-tooltip-arrow {
      width: 14px;
      height: 14px;
    }

    .ant-form-item-explain-error {
      font-size: 13px;
      margin-top: 5px;
    }

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner-content {
      padding: 10px;
    }

    .ant-breadcrumb {
      color: #373838;
      font-weight: 700;
      a {
        font-size: 14px;
        font-weight: 700;
        color: #4bc3b5;
      }
    }

    .ant-breadcrumb-separator {
      color: #909090;
      margin: 0 20px;
    }

    .ant-divider-horizontal {
      margin: 20px 0;
    }

    .ant-popover-inner {
      border-radius: 6px;
    }
  `
);

export { GlobalStyles };
