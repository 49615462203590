import {
  AnnotationUnitProps,
  TimelineTransition
} from '../AnnotationSystem.types';
import arrayInsert from 'utils/arrayInsertByIndex';

interface HandleUpdateWithTimeline {
  curTime: number;
  onUpdate: (newState: Partial<AnnotationUnitProps>) => void;
  svgRef: SVGSVGElement | null;
  timelineTransitions: AnnotationUnitProps['timelineTransitions'];
  startTime?: number | null;
}

export default function handleUpdateWithTimeline({
  svgRef,
  onUpdate,
  curTime,
  timelineTransitions,
  startTime
}: HandleUpdateWithTimeline) {
  /**
   * Assume that timelineTransitions are properly sorted
   */
  const element = svgRef;

  if (!element) {
    console.error('No element in svgRef.current');
    return;
  }

  if (!timelineTransitions) {
    console.error('No timeline transitions');
    return;
  }

  const curH = parseInt(element.getAttribute('height') ?? '0');
  const curW = parseInt(element.getAttribute('width') ?? '0');
  const curY = parseInt(element.getAttribute('y') ?? '0');
  const curX = parseInt(element.getAttribute('x') ?? '0');
  const isEnd = element.getAttribute('data-is-end') === 'true' ? true : false;

  const newSingleTrans: TimelineTransition = {
    x: curX,
    y: curY,
    h: curH,
    w: curW,
    timestamp: curTime,
    isEnd
  };

  /** Find duplicates */
  const duplicateIdx = timelineTransitions.findIndex(
    (item) => item.timestamp === curTime
  );

  /** Replace if there are some duplicates */
  if (duplicateIdx >= 0) {
    let newTransArr = [...timelineTransitions];
    newTransArr[duplicateIdx] = {
      ...newTransArr[duplicateIdx],
      ...newSingleTrans
    };
    onUpdate({
      ...newSingleTrans,
      timelineTransitions: [...newTransArr]
    });
    return;
  }

  /** Find biggest timeline index */
  const firstBiggestIdx = timelineTransitions.findIndex(
    (item) => item.timestamp > curTime
  );
  /** If any */
  if (firstBiggestIdx > 0) {
    onUpdate({
      ...newSingleTrans,
      timelineTransitions: [
        ...arrayInsert(timelineTransitions, firstBiggestIdx, newSingleTrans)
      ]
    });
    return;
    /** If first */
  } else if (firstBiggestIdx === 0) {
    onUpdate({
      ...newSingleTrans,
      timelineTransitions: [
        ...arrayInsert(timelineTransitions, 0, newSingleTrans)
      ]
    });
    return;
    /** If none */
  } else if (firstBiggestIdx === -1) {
    onUpdate({
      ...newSingleTrans,
      timelineTransitions: [...timelineTransitions, newSingleTrans]
    });
    return;
  }
}
