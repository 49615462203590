import { Text } from 'components/_main';
import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useAnnotationVideo from 'containers/AnnotationSystem/hooks/useAnnotationVideo';
import useLabelColor from 'containers/AnnotationSystem/hooks/useLabelColor';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import React, { useMemo } from 'react';
import AnnotationLabelItem from './AnnotationLabelItem';
import ExpandBlock from 'containers/TasksRouter/AnnotationView/components/AnnotationSidebar/ExpandBlock';
import Label from './Label';
import { roundTimestampsToMs } from 'containers/AnnotationSystem/reducer/utils';
import { getNearestFrameToTime } from 'containers/AnnotationSystem/utils';
import { Virtuoso } from 'react-virtuoso';

interface WithColorPros {
  item: AnnotationUnitProps;
}

interface TransitionTimePointProps {
  color: string;
  onSelect: () => void;
  onDelete: () => void;
  time: number;
  isViewOnly: boolean;
  isShow: boolean;
  onShow: () => void;
  unitId: string;
}

export default function AnnotationsBoxLabels() {
  const { annotationUnits } = useAnnotationSystemState();
  const title = 'Annotations';
  const isExpandable = annotationUnits.length > 0;

  const labels = useMemo(() => {
    return annotationUnits.map((item, index) => (
      <BoxWrapperLabels key={item?.unitId ?? index} item={item} />
    ));
  }, [annotationUnits]);

  return (
    <ExpandBlock
      title={
        <Text
          data-testid="SidebarUnitsAnnotationsItemsTitle"
          variant="ui-small-bold"
          upper
        >
          {title}
        </Text>
      }
      expandable={isExpandable}
    >
      {Boolean(annotationUnits.length) ? (
        labels
      ) : (
        <Text variant="ui-1-regular" colorVariant="light">
          No annotations added yet.
        </Text>
      )}
    </ExpandBlock>
  );
}

function BoxWrapperLabels({ item }: WithColorPros) {
  const color = useLabelColor(item.label);
  const {
    onSetCurSelUnitId,
    onDeleteTransitionPoint,
    onModifyTransitionPoint
  } = useAnnotationSystemActions();
  const { isViewOnly } = useAnnotationSystemState();
  const { onSetCurTime, frames, isVideo } = useAnnotationVideo();
  const unitId = item.unitId;

  const { isActive } = useStatus(item);

  const timelineTransitions = item?.timelineTransitions;

  const timelinePoints = useMemo(() => {
    if (!timelineTransitions?.length || !isVideo) {
      return [];
    }

    return timelineTransitions?.map((transitionData) => {
      const time = transitionData?.timestamp;
      const handleSelect = () => {
        onSetCurTime(time);
        onSetCurSelUnitId(unitId);
      };

      const handleDelete = () => {
        onDeleteTransitionPoint({
          unitId,
          timelineTimestamp: time
        });
      };

      const handleShow = () => {
        onModifyTransitionPoint({
          unitId,
          timelineTimestamp: time,
          content: {
            isEnd: !transitionData.isEnd
          }
        });
      };

      return (
        <TransitionTimePoint
          key={transitionData?.timestamp}
          onSelect={handleSelect}
          isShow={!transitionData?.isEnd}
          onShow={handleShow}
          isViewOnly={isViewOnly}
          onDelete={handleDelete}
          time={time}
          unitId={unitId}
          color={color}
        />
      );
    });
  }, [
    timelineTransitions,
    isVideo,
    isViewOnly,
    unitId,
    color,
    onSetCurTime,
    onSetCurSelUnitId,
    onDeleteTransitionPoint,
    onModifyTransitionPoint
  ]);

  const lastTime = isVideo
    ? timelineTransitions?.[timelineTransitions?.length - 1]?.timestamp
    : undefined;
  const lastFrame = lastTime
    ? getNearestFrameToTime(frames, lastTime)
    : undefined;

  return (
    <Label
      lastTime={lastTime}
      lastFrame={lastFrame}
      isActive={isActive}
      key={item.unitId}
      item={item}
      color={color}
    >
      {isVideo && (
        <Virtuoso
          style={{ height: '200px', overflow: 'auto' }}
          totalCount={timelinePoints.length}
          itemContent={(index) => timelinePoints[index]}
        />
      )}
    </Label>
  );
}

function useIsTransitionActive(time: number, unitId: string) {
  const { curSelUnitId } = useAnnotationSystemState();
  const { curVidTime } = useAnnotationVideo();

  const curUnitTime = roundTimestampsToMs(time);
  const roundedTime = roundTimestampsToMs(curVidTime);

  const isActive = useMemo(
    () =>
      curSelUnitId === unitId &&
      (curUnitTime === roundedTime ||
        curUnitTime === roundedTime - 0.001 ||
        curUnitTime === roundedTime + 0.001)
        ? true
        : false,
    [curSelUnitId, curUnitTime, roundedTime, unitId]
  );

  return isActive;
}

function TransitionTimePoint({
  color,
  time,
  onDelete,
  onSelect,
  isShow,
  onShow,
  isViewOnly,
  unitId
}: TransitionTimePointProps) {
  const data = useMemo(
    () => ({
      label: time.toFixed(3).toString() ?? ''
    }),
    [time]
  );

  const isActive = useIsTransitionActive(time, unitId);

  return useMemo(
    () => (
      <AnnotationLabelItem
        color={color}
        isShow={isShow}
        small
        data={data}
        isActive={isActive}
        onClick={onSelect}
        onDelete={onDelete}
        onShow={onShow}
        isViewOnly={isViewOnly}
      />
    ),
    [color, data, isActive, isShow, isViewOnly, onDelete, onSelect, onShow]
  );
}
