import {
  AnnotationSystemEventsEnum,
  ResizePosition
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { MutableRefObject, useEffect } from 'react';

interface Props {
  ref: MutableRefObject<SVGElement | null>;
  unitId: string;
  onMouseDown?: (event?: MouseEvent) => void;
  onResizeMouseDown?: (event?: MouseEvent) => void;
  onDragMouseDown?: (event?: MouseEvent) => void;
}

export default function useMouseDown({
  unitId,
  ref,
  onMouseDown,
  onResizeMouseDown,
  onDragMouseDown
}: Props) {
  const { curSelUnitId, curEvent } = useAnnotationSystemState();
  const { onSetCurEvent, onSetCurSelUnitId, onSetEventResize } =
    useAnnotationSystemActions();
  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const element: SVGElement | null = ref.current;

    const currentTarget = event.target as HTMLElement;

    if (!element || !currentTarget) {
      return;
    }

    onMouseDown && onMouseDown(event);

    switch (curEvent) {
      case null:
      case AnnotationSystemEventsEnum.SELECTED_MODE: {
        const resizePosition = currentTarget?.dataset
          ?.resizePosition as ResizePosition;

        if (curSelUnitId !== unitId) {
          onSetCurSelUnitId(unitId);
          return;
        }

        if (currentTarget?.dataset?.changeType === 'drag') {
          onDragMouseDown && onDragMouseDown(event);
          onSetCurEvent(AnnotationSystemEventsEnum.DRAG_MODE);
          return;
        }

        if (currentTarget?.dataset?.changeType === 'resize' && resizePosition) {
          onResizeMouseDown && onResizeMouseDown(event);
          onSetEventResize(resizePosition);
          return;
        }

        return;
      }
    }
  };

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    element.addEventListener('mousedown', handleMouseDown);

    return () => {
      element.removeEventListener('mousedown', handleMouseDown);
    };
  });
}
