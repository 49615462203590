import styled from 'styled-components/macro';

export const ButtonWithShortcutText = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: max-content;
  grid-auto-rows: max-content;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  & > *:nth-child(2) {
    font-size: 11px;
    text-align: center;
    color: #88a0b1;
  }
`;
