import { useContext, useMemo } from 'react';
import context from './context';
import { AnnotationSystemEventsEnum } from '../AnnotationSystem.types';

/**
 * This hook should contain only scalars, no pointer type data like arrays, objects, functions,..
 */
export default function useAnnotationSystemState() {
  const [state] = useContext(context);

  const { curEvent } = state;

  return {
    ...state,
    isUnitCreatingProcess:
      curEvent === AnnotationSystemEventsEnum.CREATE_MODE ||
      curEvent === AnnotationSystemEventsEnum.CREATING_LABEL ||
      curEvent === AnnotationSystemEventsEnum.CREATING_SIZE
  };
}
