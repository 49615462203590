import React from 'react';
import { Modal as ModalBase, Spin } from 'antd';
import { Body, Content } from '../ui';
import messages from '../messages';
import { X } from '@phosphor-icons/react';
import { Z_INDEXES } from 'appConstants';
import { ModalProps } from './Modal.types';
import Footer from './Footer';
import { useTheme } from 'styled-components/macro';
import Icon from 'components/Icon';

export const Modal = ({
  title,
  isVisible,
  onCancel,
  onOk,
  children,
  className,
  loading,
  okText = messages.save,
  cancelText = messages.cancel,
  isOkDisabled = false,
  footer,
  okButton,
  formLayout = 'horizontal',
  ...restProps
}: React.PropsWithChildren<ModalProps>) => {
  const theme = useTheme();
  const LoadingSpin = loading ? Spin : React.Fragment;

  return (
    <ModalBase
      mask
      destroyOnClose
      className={className}
      title={title}
      visible={isVisible}
      footer={false}
      onCancel={onCancel}
      centered
      closeIcon={
        <Icon hoverFillColor={theme.colors.fg}>
          <X size={22} color={theme.colors.light} />
        </Icon>
      }
      maskStyle={{ zIndex: Z_INDEXES.modal }}
      bodyStyle={{ padding: '20px' }}
      {...restProps}
    >
      <LoadingSpin>
        <Body>
          <Content formLayout={formLayout}>{children}</Content>
          {footer ?? (
            <Footer
              cancelText={cancelText}
              isOkDisabled={isOkDisabled}
              loading={loading}
              okButton={okButton}
              okText={okText}
              onCancel={onCancel}
              onOk={onOk}
            />
          )}
        </Body>
      </LoadingSpin>
    </ModalBase>
  );
};

export default Modal;
