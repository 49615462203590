import styled from 'styled-components/macro';

export const ClientsName = styled.div`
  display: grid;
  width: max-content;
  grid-template-rows: max-content max-content;
  grid-template-columns: 1fr 1fr;

  & > *:nth-child(1) {
    grid-row: 1/2;
    grid-column: 1/-1;
    font-weight: 700;
  }
  & > *:nth-child(2) {
    grid-row: 2/3;
    grid-column: 1/-1;
    color: #88a0b1;
    font-weight: normal;
  }
`;
