import styled, { css } from 'styled-components/macro';
import { Tabs as BaseTabs } from 'antd';

const { TabPane: BaseTabPane } = BaseTabs;

interface TabsProps {
  small?: boolean;
  mini?: boolean;
  mb?: string;
}

export const TabPane = styled(BaseTabPane)``;
export const Tabs = styled(BaseTabs)<TabsProps>`
  padding: 1rem 0 2rem;
  color: ${({ theme }) => theme.colors.light};

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      margin-left: 0 !important;
      color: ${({ theme }) => theme.colors.light};
      border-bottom: 1px solid ${({ theme }) => theme.colors.lighter};
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    & .ant-tabs-tab-btn {
      font-weight: 700;
    }
    & .add-labels-tabs-nav {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .ant-tabs-nav {
    flex: 0;
    margin-bottom: ${({ mb }) => mb ?? '38px'};
    &::before {
      border-color: transparent;
    }
  }

  &.ant-tabs {
  }

  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  }

  .ant-tabs-content-holder {
    flex: 2;
  }

  .ant-tabs-nav .ant-tabs-ink-bar {
    position: absolute;
    height: 2px;
    background: ${({ theme }) => theme.colors.primary};
  }

  .ant-tabs-tab {
    font-size: 14px;
    text-transform: capitalize;
    color: #8095a3;
    padding: 17px 2rem;

    &:hover {
      color: ${({ theme }) => theme.colors.gray3};
      border-color: ${({ theme }) => theme.colors.gray3};
    }
    &:focus,
    &:active {
      color: #212121;
    }
  }

  .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
    &:focus,
    &:active {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .ant-tabs-tab-disabled {
    &:hover,
    &:focus,
    &:active {
      color: #8095a3;
    }
  }

  .ant-tabs-content.ant-tabs-content-top {
    overflow-y: auto;
    height: 100%;
  }

  ${({ small }) =>
    small &&
    css`
      .ant-tabs-tab {
        font-size: 12px;
      }

      .ant-tabs-tab-btn {
        min-width: 100px;
        display: flex;
        justify-content: center;
        &:focus,
        &:active {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    `}

  ${({ mini }) =>
    mini &&
    css`
      padding: 0;
      &.ant-tabs {
        height: 100%;
        overflow: hidden;
      }
      .ant-tabs-tab {
        min-width: 70px;
        padding: 17px 18px;
      }
      .ant-tabs-nav {
        margin-bottom: 20px;
      }
    `}
`;

export const Tab = TabPane;

export default Tabs;
