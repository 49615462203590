import { HandIcon } from 'assets/taskingTopToolbar';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import React from 'react';
import { ToolbarIcon, ToolbarSection } from './AnnotationToolbar.ui';
import { AnnotationSystemEventsEnum } from 'containers/AnnotationSystem/AnnotationSystem.types';

export default function SectionHand() {
  const { curEvent } = useAnnotationSystemState();

  const { onSetCurEvent } = useAnnotationSystemActions();

  const active = curEvent === AnnotationSystemEventsEnum.ZOOM_NAVIGATION;

  const handleClick = () => {
    if (active) {
      onSetCurEvent(null);
      return;
    }
    onSetCurEvent(AnnotationSystemEventsEnum.ZOOM_NAVIGATION);
  };

  return (
    <ToolbarSection width="100%">
      <ToolbarIcon icon={<HandIcon />} active={active} onClick={handleClick} />
    </ToolbarSection>
  );
}
