import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { DEFAULT_REDIRECT_AFTER_REQUEST } from 'appConstants';

export const useRedirect = () => {
  const history = useNavigate();

  return useCallback(
    (to: string, withTimeout?: boolean, timeout?: number) => {
      if (withTimeout) {
        setTimeout(() => {
          history(to);
        }, timeout ?? DEFAULT_REDIRECT_AFTER_REQUEST);
      }

      history(to);
    },
    [history]
  );
};

export default useRedirect;
