import { TAnnotObjResult } from 'containers/ImageAnnotationsLegacy';

export const getSvgObjSizes = (obj: SVGElement): TAnnotObjResult => {
  const x = parseFloat(obj.getAttribute('x') || '0');
  const y = parseFloat(obj.getAttribute('y') || '0');
  const w = parseFloat(obj.getAttribute('width') || '0');
  const h = parseFloat(obj.getAttribute('height') || '0');
  return { x, y, w, h, label: '' };
};

export default getSvgObjSizes;
