import React, { useMemo } from 'react';
import StyledTable from 'components/Table';
import { Project } from 'generated/graphql';
import columnsPendingProjectAdmin from 'containers/Projects/ProjectsAdmin/PendingProjects/columns.admin';
import useGetListPendingProject from 'containers/Projects/hooks/useGetListPendingProject';

interface PendingProjectsProps {
  onEdit(data: Project): void;
  onActivate(data: Project): void;
}

const PendingProjects = ({ onEdit, onActivate }: PendingProjectsProps) => {
  const {
    listProjects,
    pendingPagesCount,
    page,
    setPage,
    pendingProjectsLoading
  } = useGetListPendingProject();

  const columnsPending = useMemo(
    () =>
      columnsPendingProjectAdmin({
        onEdit,
        onActivate
      }),
    [onEdit, onActivate]
  );

  return (
    <StyledTable
      setCurTablePage={setPage}
      page={page}
      pagesCount={pendingPagesCount}
      columns={columnsPending}
      dataSource={listProjects as object[]}
      loading={pendingProjectsLoading}
    />
  );
};

export default PendingProjects;
