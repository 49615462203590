import { toast } from 'utils/toast';

export function consoleError(...args: any[]) {
  let apolloErrorMessage = args?.[0]?.message;

  if (apolloErrorMessage) {
    console.error(apolloErrorMessage);
    toast.error(apolloErrorMessage);
    return;
  }

  console.error(...args);
}

export default consoleError;
