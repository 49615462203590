import React from 'react';
import { dashboardCtx } from 'containers/Dashboard/Dashboard.context';

const useDashboardCtx = () => {
  const { data, setData } = React.useContext(dashboardCtx);

  const setAppBarTitle = React.useCallback(
    (appBarTitle: string) => {
      setData((data: any) => ({ ...data, appBarTitle }));
    },
    [setData]
  );

  const toggleFullScreen = React.useCallback(() => {
    setData((data) => ({ ...data, isFullScreen: !data.isFullScreen }));
  }, [setData]);

  return { data, setData, setAppBarTitle, toggleFullScreen };
};

export default useDashboardCtx;
