import { Tooltip } from 'antd';
import React from 'react';
import { BoundingBoxIcon, SkeletonPointsIcon, PolygonIcon } from 'assets';
import { AnnotationSpecificationType } from 'appTypes';

export default function getAnnotationSpecializationIcon(
  specialization: AnnotationSpecificationType | string
) {
  switch (specialization) {
    case 'humanPoses-17dots':
    case 'dot':
      return (
        <Tooltip title="Dots">
          <SkeletonPointsIcon />
        </Tooltip>
      );
    case 'boxes':
    case 'box':
      return (
        <Tooltip title="Bounding box">
          <BoundingBoxIcon />
        </Tooltip>
      );
    case 'polygons':
    case 'polygon':
      return (
        <Tooltip title="Polygons">
          <PolygonIcon />
        </Tooltip>
      );
  }

  return null;
}
