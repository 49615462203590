import React from 'react';
import { Col, Text, Row, Link } from 'components/_main';
import { Project, TaskType } from 'generated/graphql';
import ProjectTitle from 'components/ProjectTitle';
import parseSubType from 'utils/parseSubType';
import {
  getAnnotationSpecializationIcon,
  getAnnotationSpecializationTitle,
  isAdmin,
  isClient,
  trimInstructionShortName
} from 'utils';
import { useCtx } from 'containers/ProjectDetailsCard/ProjectDetailsCard.Context';
import { AnnotationLabel } from 'components/AnnotationLabel';
import ClientInstructions from '../ClientInstructions';

interface GeneralProps {
  projectData: Project;
}

function General({ projectData }: GeneralProps) {
  const { isEditable } = useCtx();

  const annotationSpecialization = parseSubType(
    projectData?.projectSettings?.subType ??
      projectData?.projectSettings?.annotationType
  );
  const isShowLabels =
    projectData?.type !== TaskType.DataSetCollection &&
    projectData?.type !== TaskType.Nlp &&
    annotationSpecialization !== 'dot';
  const withoutSubType =
    projectData?.type !== TaskType.ContentModeration &&
    projectData?.type !== TaskType.DataSetCollection;

  return (
    <Row wide gridGap="30px">
      <Row gridGap="12px" width="340px">
        <Text variant="ui-small-bold" upper={!isEditable}>
          Project Name
        </Text>
        <ProjectTitle name="title" readOnly={!isEditable} />
      </Row>
      {annotationSpecialization && withoutSubType && (
        <Row gridGap="12px">
          <Text variant="ui-small-bold" upper>
            Annotation Type
          </Text>
          <Col alignItems="center">
            {getAnnotationSpecializationIcon(annotationSpecialization)}
            <Text lineHeight="26px">
              {getAnnotationSpecializationTitle(annotationSpecialization)}
            </Text>
          </Col>
        </Row>
      )}
      {isShowLabels && (
        <Row gridGap="12px" width="100%">
          <Text variant="ui-small-bold" upper={!isEditable}>
            Labels
          </Text>
          <AnnotationLabel disabled={!isEditable} maxHeight="100px" />
        </Row>
      )}
      {isAdmin() && projectData?.type === TaskType.Nlp && (
        <Row gridGap="12px" width="100%">
          <Text variant="ui-small-bold" upper>
            Dataset
          </Text>
          <Text>
            {projectData?.mediaFileName?.map((link) => {
              if (link) {
                return (
                  <Link href={link}>{trimInstructionShortName(link)}</Link>
                );
              }
              return null;
            })}
          </Text>
        </Row>
      )}
      {isClient() && <ClientInstructions />}
    </Row>
  );
}

export default General;
