import React from 'react';
import { Row, Col, Text, Box } from 'components/_main';
import { ProjectTypeLabel } from 'appTypes';
import { useTheme } from 'styled-components/macro';
import SpinnerLoader from 'components/SpinnerLoader';
import { DoneIcon } from 'assets';
import ExpandBlock from './ExpandBlock';
import useAutoSaveTask from 'hooks/useAutoSaveTask';
import { trimInstructionShortName } from 'utils';
import {
  AnnotationInstructionsProps,
  AnnotationSidebarProps
} from '../../AnnotationView.types';
import { Tooltip } from 'antd';

export function AnnotationSidebarTitle({
  taskId,
  taskType,
  taskingType
}: AnnotationSidebarProps) {
  return (
    <>
      {taskingType === 'practice' ? (
        <AnnotationSidebarReadOnlyTitle taskId={taskId} taskType={taskType} />
      ) : (
        <AnnotationSidebarTitleAutoSave taskId={taskId} taskType={taskType} />
      )}
    </>
  );
}

export function AnnotationSidebarTitleAutoSave({
  taskId,
  taskType
}: AnnotationSidebarProps) {
  const { loadingAutosave, handleOnSave } = useAutoSaveTask({
    taskId,
    taskType
  });

  return (
    <Row gridGap="4px" px="14px">
      <Col alignItems="center">
        <Text variant="ui-4-bold">Task ID: {taskId}</Text>
        <Tooltip title="Save">
          <Box width="24px" height="24px" onClick={handleOnSave}>
            {loadingAutosave ? <SpinnerLoader /> : <DoneIcon />}
          </Box>
        </Tooltip>
      </Col>
      <Text variant="ui-1-regular" colorVariant="light">
        {ProjectTypeLabel[taskType as keyof typeof ProjectTypeLabel]}
      </Text>
    </Row>
  );
}

export function AnnotationSidebarReadOnlyTitle({
  taskId,
  taskType
}: AnnotationSidebarProps) {
  return (
    <Row gridGap="4px" px="14px">
      <Col alignItems="center">
        <Text variant="ui-4-bold">Task ID: {taskId}</Text>
      </Col>
      <Text variant="ui-1-regular" colorVariant="light">
        {ProjectTypeLabel[taskType as keyof typeof ProjectTypeLabel]}
      </Text>
    </Row>
  );
}

export function AnnotationInstructions({
  taskProjectData
}: AnnotationInstructionsProps) {
  const theme = useTheme();

  const handleOnDownloadInstruction = () => {
    if (taskProjectData?.adminInstructionUrl) {
      window.open(taskProjectData?.adminInstructionUrl, '_blank');
    }
  };

  const { adminShortInstruction, adminInstructionUrl } = taskProjectData ?? {};

  if (!adminInstructionUrl && !adminShortInstruction) return null;

  return (
    <Row height="100%" overflowY="auto">
      <ExpandBlock
        expandable={
          !!(
            taskProjectData?.adminShortInstruction ||
            taskProjectData?.adminInstructionUrl
          )
        }
        title={
          <Text variant="ui-small-bold" upper>
            Instructions
          </Text>
        }
      >
        <Row noGap>
          {(taskProjectData?.adminShortInstruction ||
            taskProjectData?.adminInstructionUrl) && (
            <Row
              gridGap="1rem"
              padding="14px 14px"
              border={`2px solid ${theme.colors.yellow}`}
              borderRadius="5px"
            >
              {taskProjectData?.adminShortInstruction && (
                <Text lineHeight="21px">
                  {taskProjectData?.adminShortInstruction}
                </Text>
              )}
              {taskProjectData?.adminInstructionUrl && (
                <Text
                  variant="ui-1-bold"
                  colorVariant="primary"
                  css={`
                    cursor: pointer;
                  `}
                  onClick={handleOnDownloadInstruction}
                >
                  {trimInstructionShortName(
                    taskProjectData?.adminInstructionUrl
                  )}
                </Text>
              )}
            </Row>
          )}
        </Row>
      </ExpandBlock>
    </Row>
  );
}
