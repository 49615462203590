import React from 'react';
import { BoundingBoxIcon } from 'assets';
import { AnnotationSystemEventsEnum } from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { ToolbarIcon, ToolbarSection } from './AnnotationToolbar.ui';
import SectionZoom from './SectionZoom';
import SectionSvgLayerOpacity from './SectionSvgLayerOpacity';
import SectionHand from './SectionHand';

export default function ImageAnnotationToolbar() {
  const { isUnitCreatingProcess, curEvent, isViewOnly } =
    useAnnotationSystemState();

  const { onSetCurEvent } = useAnnotationSystemActions();

  const activeCreateMode =
    curEvent === AnnotationSystemEventsEnum.CREATE_MODE ||
    curEvent === AnnotationSystemEventsEnum.CREATING_SIZE ||
    curEvent === AnnotationSystemEventsEnum.CREATING_LABEL;

  const handleOnStartCreateMode = () => {
    if (isUnitCreatingProcess) {
      onSetCurEvent(null);
      return;
    }

    onSetCurEvent(AnnotationSystemEventsEnum.CREATE_MODE);
  };

  return (
    <>
      <ToolbarSection>
        <ToolbarIcon
          icon={<BoundingBoxIcon />}
          active={activeCreateMode}
          disabled={isViewOnly}
          onClick={handleOnStartCreateMode}
        />
      </ToolbarSection>
      <SectionHand />
      <SectionZoom />
      <SectionSvgLayerOpacity />
    </>
  );
}
