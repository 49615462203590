import React from 'react';
import { TData } from '../Users.types';
import { toast } from 'utils/toast';
import { useDeleteHelperMutation } from 'generated/graphql';
import { Modal } from 'components/_modals';
import useGetAssignedProjectTitlesToHelper from 'hooks/useGetAssignedProjectTitlesToHelper';
import getHelperAssignedToProjectWarning from 'utils/getHelperAssignedToProjectWarning';
import { TRefetchQueries } from 'appTypes';

function ModalDelete<TDateGeneric extends { id: string } = TData>({
  isOpen,
  setModalOpen,
  curData,
  refetchQueries
}: {
  curData: TDateGeneric;
  isOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  refetchQueries: TRefetchQueries;
}) {
  const handleOnClose = () => {
    setModalOpen(false);
  };

  const [deleteHelper, { loading }] = useDeleteHelperMutation({
    onCompleted: () => {
      toast.success('Helper successfully deleted');
      handleOnClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: () => refetchQueries
  });

  const { data: assignedProjectsList, loading: anotherLoading } =
    useGetAssignedProjectTitlesToHelper(parseInt(curData.id, 10));

  return (
    <Modal
      title={'Delete user'}
      isVisible={isOpen}
      okText={'Delete'}
      isOkDisabled={assignedProjectsList && assignedProjectsList.length > 0}
      onOk={() => {
        if (!curData || !curData?.id)
          return console.error('Not curData or curData.id');
        deleteHelper({
          variables: {
            helperId: parseInt(curData.id, 10)
          }
        });
        handleOnClose();
      }}
      onCancel={() => {
        handleOnClose();
      }}
      loading={loading || anotherLoading}
    >
      {' '}
      {assignedProjectsList && assignedProjectsList.length > 0 ? (
        getHelperAssignedToProjectWarning(assignedProjectsList as string[])
      ) : (
        <div>Are you sure?</div>
      )}
      <div> </div>
    </Modal>
  );
}

export default ModalDelete;
