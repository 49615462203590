import React, { PropsWithChildren } from 'react';
import { css, useTheme } from 'styled-components/macro';
import { Box, Text, Col } from 'components/_main';
import { px } from 'utils';
import { Check } from '@phosphor-icons/react';
import { LockIcon } from 'assets';

export interface StepCircleProps {
  isActive?: boolean;
  done?: boolean;
  isSmallDot?: boolean;
  isLinkActive?: boolean;
  description?: string;
  title?: string;
  isLocked?: boolean;
}

export function StepCircle({
  title,
  isActive,
  done,
  isSmallDot,
  isLinkActive,
  description,
  isLocked
}: PropsWithChildren<StepCircleProps>) {
  const lineHeight = 25;
  const borderNormal = 3;
  const borderSmall = 1;
  const sizeNormal = 45;
  const sizeSmall = 9;
  const theme = useTheme();

  const curSize = isSmallDot ? sizeSmall : sizeNormal;

  return (
    <Col
      gridAutoColumns="45px 1fr"
      gridGap="20px"
      {...(isSmallDot && {
        gridGap: '35px'
      })}
      maxHeight={curSize + lineHeight}
      css="
        &:last-child {
          .stepWrapper::after {
            display: none;
          }
        }
      "
    >
      <Box
        className="stepWrapper"
        pb={px(lineHeight)}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        css={`
          box-sizing: border-box;

          &::after {
            top: calc(100% - ${px(lineHeight)});
            content: ' ';
            width: 1px;
            height: ${px(lineHeight)};
            position: absolute;
            background-color: ${theme.colors.lighter};
          }

          ${(done || isLinkActive) &&
          css`
            &::after {
              background-color: ${theme.colors.primary};
            }
          `}

          ${isActive &&
          css`
            &::after {
              height: ${px(lineHeight)};
            }
          `}

        ${isSmallDot &&
          css`
            &::after {
            }
          `}

        ${isActive &&
          isSmallDot &&
          css`
            &::after {
            }
          `}

        ${isSmallDot &&
          done &&
          css`
            &::after {
            }
          `}
        `}
      >
        <Box
          borderRadius="100rem"
          color={theme.colors.light}
          display="flex"
          borderWidth={px(borderSmall)}
          borderColor={theme.colors.lighter}
          borderStyle="solid"
          justifyContent="center"
          alignItems="center"
          width={px(sizeNormal)}
          height={px(sizeNormal)}
          {...(done && {
            borderWidth: 0,
            backgroundColor: theme.colors.primary,
            color: theme.colors.bg
          })}
          {...(isActive && {
            borderWidth: 0,
            backgroundColor: theme.colors.primary,
            fontWeight: '600',
            color: theme.colors.bg
          })}
          {...(isSmallDot && {
            width: px(sizeSmall),
            height: px(sizeSmall),
            borderWidth: px(borderSmall),
            borderColor: theme.colors.lighter,
            backgroundColor: theme.colors.bg
          })}
          {...(isSmallDot &&
            isActive && {
              borderWidth: 0,
              backgroundColor: theme.colors.primary
            })}
          {...(isSmallDot &&
            done && {
              borderWidth: 0,
              backgroundColor: theme.colors.primary
            })}
        >
          {!isLocked && !isSmallDot && (done ? <Check size={20} /> : title)}
          {isLocked && <LockIcon />}
        </Box>
      </Box>
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        maxHeight={curSize + lineHeight}
      >
        <Text
          variant="ui-1-medium"
          colorVariant="light"
          {...(!isSmallDot &&
            (isActive || done) && {
              variant: 'ui-1-bold',
              colorVariant: 'normal',
              fontWeight: 700
            })}
          {...(isSmallDot &&
            isActive && {
              colorVariant: 'normal',
              fontWeight: 400
            })}
          {...(isSmallDot &&
            done && {
              variant: 'ui-1-regular',
              colorVariant: 'light'
            })}
          pb={lineHeight}
        >
          {description}
        </Text>
      </Box>
    </Col>
  );
}
