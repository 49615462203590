import React from 'react';
import { Row, Col, Text } from 'components/_main';
import AntdCheckbox from 'components/AntdCheckbox';
import {
  useAnnotationSystemState,
  useAnnotationSystemActions
} from 'containers/AnnotationSystem/context';
import useAnnotationSystemLabels from 'containers/AnnotationSystem/context/useAnnotationSystemLabels';
import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import AnnotationLabelsBlock from './AnnotationLabelsBlock';
import arrayRemove from 'utils/arrayRemove';
import arrayInsert from 'utils/arrayInsert';

function AnnotationsContentModerationLabels() {
  const { annotationUnits } = useAnnotationSystemState();
  const title = 'I can see on the image';
  const isExpandable = false;

  const item = annotationUnits?.[0];

  if (!item) {
    return null;
  }

  return (
    <AnnotationLabelsBlock title={title} isExpandable={isExpandable}>
      <ModerationLabels key={item?.id ?? item?.unitId} item={item} />
    </AnnotationLabelsBlock>
  );
}

function ModerationLabels({ item }: { item: AnnotationUnitProps }) {
  const { reasons } = item;
  const labels = useAnnotationSystemLabels();
  const { onModifyAnnotationUnit } = useAnnotationSystemActions();

  const handleChange = (label: string) => {
    const isChecked = reasons?.includes(label);

    if (!reasons) return;

    const newReasons = isChecked
      ? arrayRemove(reasons, label)
      : arrayInsert(reasons, label);

    onModifyAnnotationUnit({
      ...item,
      reasons: newReasons,
      status: newReasons.length === 0 ? 'valid' : 'invalid'
    });
  };

  return (
    <Row gridGap="12px" paddingBottom="4px">
      {labels.map((label) => (
        <Col key={label}>
          <AntdCheckbox
            checked={item?.reasons?.includes(label)}
            onChange={() => handleChange(label)}
          >
            <Text paddingLeft="12px">{label}</Text>
          </AntdCheckbox>
        </Col>
      ))}
    </Row>
  );
}

export default AnnotationsContentModerationLabels;
