import { InputLabelFieldProps } from './InputLabelField.types';

export function mapInputProps<T extends string | number = string>({
  disabled,
  placeholder,
  password,
  textarea,
  rows,
  readOnly,
  inputProps,
  ...restProps
}: InputLabelFieldProps<T>) {
  return {
    componentProps: {
      disabled,
      placeholder,
      password,
      textarea,
      rows,
      readOnly,
      ...inputProps
    },
    restProps
  };
}
