import * as yup from 'yup';

interface FormatErrors {
  message: string;
  path: string;
}

const emailNotLongEnough = 'Email must be at least 3 characters';
const firstNameNotLongEnough = 'First name must be at least 3 characters';
const lastNameNotLongEnough = 'Last name must be at least 3 characters';
const invalidEmail = 'Email must be a valid email';

const specialChars = '<>~`!@#$%^&*()+=_-{}';
const singleLowCaseLetterRE = /(?=.*?[a-z])/;
const singleUpCaseLetterRE = /^(?=.*?[A-Z])/;
const singleDigitRE = /^(?=.*?[0-9])/;
const singleLetterSpecCharRE = /(?=.*?[<>~`!@#$%^&*()+=_\-{}])/;

const restrictWhitespacesRE = new RegExp(`^[^\\s]*$`);
const restrictNonASCIICharsRE = new RegExp('^[^!@<>~`#$%^&*()+=_\\{}]*$');
const restrictNumbersRE = new RegExp('^[^0-9]*$');

const password = yup
  .string()
  .required('Please enter password')
  .max(30, 'Password must be less than 30 characters long')
  .min(8, 'Password must be at least 8 characters long')
  .matches(restrictWhitespacesRE, 'Spaces are not allowed')
  .matches(singleLowCaseLetterRE, 'Must contain at least 1 lowercase letter')
  .matches(singleUpCaseLetterRE, 'Must contain at least 1 capital letter')
  .matches(singleDigitRE, `Must contain at least 1 digit`)
  .matches(
    singleLetterSpecCharRE,
    `Must contain at least 1 special character ${specialChars}`
  );

const videoAnnotation = yup
  .string()
  .oneOf(['Dots', 'Frames'])
  .required(`Please choose video annotation type`);

const unique = (yup.addMethod as (array: any, name: any, fn: any) => any)(
  yup.array,
  'unique',
  function (message: string, mapper: (listItem: string) => string) {
    // @ts-ignore
    return this.test('unique', message, (list: any) => {
      if (Array.isArray(list)) {
        return list.length === new Set(list.map(mapper)).size;
      }
      return false;
    });
  }
);

const labelTags = yup.mixed().when('isArray', {
  is: Array.isArray,
  then: yup.array().of(yup.string())
});

const nameOld = yup
  .string()
  .required('Please enter first name')
  .min(3, firstNameNotLongEnough)
  .max(100)
  .matches(restrictWhitespacesRE, 'Spaces are not allowed')
  .matches(restrictNonASCIICharsRE, 'Please provide latin letters only');

const emailOld = yup
  .string()
  .required('Please enter email')
  .min(3, emailNotLongEnough)
  .max(100)
  .email(invalidEmail);

const name = yup
  .string()
  .required('Please enter valid name')
  .min(1, firstNameNotLongEnough)
  .max(30)
  .matches(restrictNumbersRE, 'Numbers are not allowed')
  .matches(restrictWhitespacesRE, 'Spaces are not allowed')
  .matches(restrictNonASCIICharsRE, 'Please provide latin letters only');

const companyName = yup
  .string()
  .required('Please enter company name')
  .min(1, lastNameNotLongEnough)
  .max(50)
  .matches(restrictNonASCIICharsRE, 'Please provide latin letters only');

const email = yup
  .string()
  .required('Please enter email')
  .min(3, emailNotLongEnough)
  .max(50)
  .email(invalidEmail);

const passwordConfirmation = yup
  .string()
  .oneOf([yup.ref('password')], 'Passwords must match')
  .required('Required');

const newUniquePassword = password.notOneOf(
  [yup.ref('oldPassword')],
  'Password must be different from the current one'
);

const signupValidationSchema = yup.object().shape({
  firstName: nameOld,
  lastName: nameOld,
  email: emailOld,
  password
});

const signupClientValidationSchema = yup.object().shape({
  firstName: nameOld,
  lastName: nameOld,
  companyName: companyName,
  email: emailOld,
  password
});

const clientValidationSchemaNew = yup.object().shape({
  firstName: name,
  lastName: name,
  companyName,
  email
});

const updateClientInfoValidationSchema = yup.object().shape({
  firstName: name,
  lastName: name
});

const changePasswordValidationSchema = yup.object().shape({
  oldPassword: password,
  password: newUniquePassword,
  passwordConfirmation
});

const signupClientValidationSchemaNew = yup.object().shape({
  firstName: name,
  lastName: name,
  companyName,
  companyEmail: email,
  checkbox: yup.boolean().oneOf([true], 'Required'),
  password,
  passwordConfirmation
});

const helperNameValidationSchema = yup.object().shape({
  firstName: name,
  lastName: name,
  email
});

const signinValidationSchema = yup.object().shape({
  email,
  password
});

const formatYupError = (err: { inner: FormatErrors[] }) => {
  const errors: FormatErrors[] = [];
  err.inner.forEach((e) => {
    errors.push({
      message: e.message,
      path: e.path
    });
  });
  return errors;
};

const normalizeErrors = (errors: any) => {
  return errors.reduce((acc: any, val: any) => {
    acc[val.path] = val.message;
    return acc;
  }, {});
};

const formatError = (error: any) =>
  error && error[0].toUpperCase() + error.slice(1);

export const helperAssignSchema = {
  selectedHelperIds: yup.array<any>().min(1, 'Should be at least 1 helper')
};

const adminInstructionsSchema = {
  adminShortInstruction: yup
    .string()
    .min(20, 'Should contain at least 20 char-s')
    .max(1000, 'Should contain less then 1000 char-s')
    .nullable(),
  adminInstructionFileName: yup.string()
};

const clientInstructionsSchema = {
  clientShortInstruction: yup
    .string()
    .min(20, 'Should contain at least 20 char-s')
    .max(1000, 'Should contain less then 1000 char-s'),
  clientInstructionFileNames: yup.array().of(yup.string())
};

const rateCantBeBelowZero = (value: number | undefined | string | null) => {
  return value === null || value === undefined || value >= 0;
};

const ratesSchema = {
  helperRate: yup
    .string()
    .nullable()
    .test('cantBeBelowZero', "Can't be lower than 0", rateCantBeBelowZero),
  auditorRate: yup
    .string()
    .nullable()
    .test('cantBeBelowZero', "Can't be lower than 0", rateCantBeBelowZero)
};

const multiLevelLabels = {
  mlLabels: yup.object({
    multilabels: yup
      .array()
      .of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
          trigger: yup.object({
            parentId: yup.string(),
            items: yup.array().of(yup.string())
          }),
          entities: yup.array().of(yup.string())
        })
      )
      .min(1)
  })
};

const isLabelsCorrectSchema = {
  // @ts-ignore
  isLabelsCorrect: yup.string().test(function () {
    // @ts-ignore
    const { labels, mlLabels } = this.parent;
    if (
      !(
        (labels && labels.length > 0) ||
        (mlLabels && mlLabels?.multilabels && mlLabels.multilabels.length > 0)
      )
    ) {
      return this.createError({
        message: 'Please create at least one label',
        path: 'labels'
      });
    }
    return true;
  })
};

const titleSchema = {
  title: yup
    .string()
    .required('Required')
    .min(1, 'Should contain at least 1 character')
    .max(30, 'Should contain less then 30 character')
    .test(
      'validLetters',
      'Must contain only latin letters, numbers & space',
      (value) => {
        return !!(value && value.match(new RegExp(`^[A-Za-z0-9 ]*$`, 'g')));
      }
    )
    .test(
      'atLeastOneLetter',
      'Must not contain only numbers and space',
      (value) => {
        return !!(value && value.match(/(?=.*[a-zA-Z])/g));
      }
    )
};

const labelsSchema = {
  customLabels: yup.boolean(),
  labels: yup
    .array()
    .of(yup.string())
    .test('notEmpty', 'Required', (value) => {
      return Array.isArray(value) && value?.length > 0;
    })
    .test('notContainOnlySpace', "Can't contain only spaces", (value) => {
      if (Array.isArray(value)) {
        return value.reduce(
          (acc: boolean, string) =>
            string ? !/^[ ].*$/.test(string.trim()) : false,
          true
        );
      }
      return false;
    })
    .test('uniqueLabels', 'Duplicates are not allowed', (value) => {
      if (!Array.isArray(value)) return false;

      const newDefinedArr: string[] = value.filter(
        (label) => !!label
      ) as string[];

      if (newDefinedArr.length === 0) return false;

      const newTrimedArr = newDefinedArr.reduce(
        (acc: string[], label: string) => [...acc, label.trim()],
        []
      );
      return newTrimedArr.length === new Set(newTrimedArr).size;
    })
    .required(`Can't go without labels`)
    .min(1, 'Please add at least 1 label to proceed')
};

const taskReturnValidationSchema = yup.object().shape({
  message: yup.string().required(`Please enter reason`)
});

export {
  ratesSchema,
  adminInstructionsSchema,
  clientInstructionsSchema,
  multiLevelLabels,
  rateCantBeBelowZero,
  titleSchema,
  labelsSchema,
  isLabelsCorrectSchema,
  helperNameValidationSchema,
  signupValidationSchema,
  signupClientValidationSchema,
  signupClientValidationSchemaNew,
  signinValidationSchema,
  formatYupError,
  normalizeErrors,
  formatError,
  password,
  password as passwordValidationField,
  passwordConfirmation,
  email,
  email as emailValidationField,
  name as nameValidationField,
  videoAnnotation,
  labelTags,
  unique,
  clientValidationSchemaNew,
  updateClientInfoValidationSchema,
  changePasswordValidationSchema,
  taskReturnValidationSchema
};
