import React from 'react';
import { Row, Text } from 'components/_main';
import { ToastProps, ToastType } from './Toast.types';

const toastTypeMessages: Record<ToastType, string> = {
  success: 'Bingo!',
  error: 'Oh, no!',
  info: ''
};

function Toast({ text, type = 'success', title }: ToastProps) {
  return (
    <Row gridGap="2px" justifyItems="flex-start">
      <Text variant="ui-1-bold">{toastTypeMessages[type] || title}</Text>
      <Text>{text}</Text>
    </Row>
  );
}

export default Toast;
