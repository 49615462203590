import React from 'react';
import { useAnnotationSystemState } from '../../context';
import Polygon from '../Polygon';
import { loadPolyPointsZoom } from '../Polygon/utils';
import useSvgLayerMouseMove from 'containers/AnnotationSystem/hooks/useSvgLayerMouseMove';

export default function SvgLayerPolygons() {
  const { annotationUnits, curZoomLevel } = useAnnotationSystemState();
  const units = annotationUnits.map((unit) => ({
    ...unit,
    polygonPoints: loadPolyPointsZoom(unit.polygonPoints ?? [], curZoomLevel)
  }));

  useSvgLayerMouseMove({});

  return (
    <>
      {units &&
        Array.isArray(units) &&
        units.map((polygon) => (
          <Polygon key={`${polygon.unitId}`} polygonUnitProps={polygon} />
        ))}
    </>
  );
}
