import { Radio as RadioBase } from 'antd';
import styled, { css } from 'styled-components/macro';

export const RadioGroup = styled(RadioBase.Group)(({ theme }) => css``);
export const Radio = styled(RadioBase)(
  ({ theme }) => css`
    &.ant-radio-wrapper {
      align-items: center;
      margin: 0;
    }

    .ant-radio {
      top: 0;

      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
      }

      .ant-radio-inner {
        width: 20px;
        height: 20px;
        border-color: ${theme.colors.lighter};

        &::after {
          transform: scale(1);
          width: 12px;
          height: 12px;
          margin-top: -6px;
          margin-left: -6px;
          border-radius: 50%;
          background-color: ${theme.colors.primary};
        }
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border-width: 2px;
          border-color: ${theme.colors.primary};
        }
      }
    }

    .ant-radio-disabled {
      .ant-radio-inner {
        border-color: ${theme.colors.lighter};
        &::after {
          background-color: ${theme.colors.lighter};
        }
      }
    }
  `
);
