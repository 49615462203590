import { Platform, Project, ProjectStatus, TaskType } from 'generated/graphql';

export const EMPTY_PROJECT_DATA: Project = {
  adminInstructionUrl: undefined,
  adminShortInstruction: undefined,
  auditorsCount: undefined,
  client: undefined,
  createdAt: new Date(),
  customLabels: false,
  helpersCount: undefined,
  isDeleted: false,
  projectStats: undefined,
  newTasksCount: 0,
  startedTasksCount: 0,
  inAuditTasksCount: 0,
  rejectedTasksCount: 0,
  completedTasksCount: 0,
  totalTasksCount: 0,
  type: TaskType.ImageAnnotation,
  updatedAt: undefined,
  workPoints: 0,
  id: 0,
  clientInstructionUrl: [],
  clientShortInstruction: '',
  labels: [],
  status: ProjectStatus.Unreviewed,
  platform: Platform.Hosted,
  title: '',
  auditorRate: 0,
  helperRate: 0,
  isUserAuditor: false,
  isUserHelper: false
};

export const EDIT_DATA_COMPONENT = 'button-edit';
export const ACTIVATE_DATA_COMPONENT = 'button-activate';
export const DELETE_DATA_COMPONENT = 'button-delete';
