import { DashboardCtx, DashboardCtxData } from 'appTypes';
import React from 'react';

export const dashboardCtxInitData: DashboardCtxData = {
  appBarTitle: '',
  isFullScreen: false
};

export const dashboardCtxInit: DashboardCtx = {
  data: dashboardCtxInitData,
  setData: () => {}
};

export const dashboardCtx = React.createContext<DashboardCtx>(dashboardCtxInit);
