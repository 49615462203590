import { Box, Col, Row, Text } from 'components/_main';
import { Modal } from 'components/_modals';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import { projectSpecializationHelpList } from 'containers/AddProjectWizard/AddProjectWizard.constants';

interface ProjectSpecializationHelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ProjectTypeCard({ children }: React.PropsWithChildren<{}>) {
  const theme = useTheme();

  return (
    <Box
      displayFlex
      flexCenter
      height="140px"
      width="140px"
      boxShadow={`0px 4px 10px ${theme.colors.shadow}`}
      borderRadius="5px"
      css={`
        svg {
          height: 25px;
          path,
          rect {
            stroke: ${theme.colors.primary} !important;
          }
        }
      `}
    >
      <Box width="42px">{children}</Box>
    </Box>
  );
}

function ProjectSpecializationHelpModal({
  isOpen,
  onClose
}: ProjectSpecializationHelpModalProps) {
  const theme = useTheme();

  return (
    <Modal
      width="700px"
      title="Project specialization help"
      isVisible={isOpen}
      onCancel={onClose}
      cancelText={null}
      onOk={onClose}
      okText="OK, GOT IT"
    >
      <Row gridGap="40px" height="73vh" overflow="auto">
        {projectSpecializationHelpList.map((specialization) => (
          <Col
            key={specialization.id}
            gridGap="40px"
            width="100%"
            padding="6px 0 40px 6px"
            borderBottom={`1px solid ${theme.colors.lighter}`}
            css={`
              &:last-child {
                border-bottom: none;
              }
            `}
          >
            <ProjectTypeCard>{specialization.icon}</ProjectTypeCard>
            <Row maxWidth="400px">
              <Text variant="custom-20px-bold">{specialization.title}</Text>
              <Text lineHeight="26px">{specialization.description}</Text>
            </Row>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default ProjectSpecializationHelpModal;
