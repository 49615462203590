import styled from 'styled-components/macro';

export const SvgRoot = styled.svg`
  overflow: visible;
`;

export const Foreign = styled.foreignObject`
  overflow: visible;
  transform: translateX(-${({ width }: { width: number }) => width / 2}px);
`;

export const Rect = styled.rect`
  stroke-dasharray: 10 5;
  stroke: orange;
  stroke-width: 3px;
  fill: transparent;
`;
