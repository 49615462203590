import React from 'react';
import { Button } from 'components/_main';
import { useSendClientActivationLinkMutation } from 'generated/graphql';
import { toast } from 'utils/toast';
import consoleError from 'utils/consoleError';

interface SendInviteProps {
  clientId: string;
}

function SendInvite({ clientId }: SendInviteProps) {
  const [sendInvite] = useSendClientActivationLinkMutation({
    fetchPolicy: 'no-cache',
    variables: {
      clientId: parseInt(clientId, 10)
    },
    onCompleted: () => {
      toast.success('Invite has been sent');
    },
    onError: (error) => {
      consoleError({ errorFromLazyQueryOptions: error });

      if (error.message) {
        toast.error(error.message);
        return;
      }

      toast.error('Something went wrong. Please, try again later');
    }
  });

  const handeSendInvite = async () => {
    try {
      await sendInvite();
    } catch (e) {
      toast.error('Something went wrong. Please, try again later');
    }
  };

  return (
    <Button small square onClick={handeSendInvite}>
      Send invite
    </Button>
  );
}

export default SendInvite;
