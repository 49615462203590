import {
  AnnotationSpecificationType,
  AppState,
  AppStateContextReturn,
  CurrentUser,
  MicrotaskingStructureProps,
  ProjectSpecialization,
  ProjectType,
  ProjectTypeLabel
} from './appTypes';

import { ReactComponent as ImageAnnSvg } from 'assets/projectTypes/image.svg';
import { ReactComponent as VideoAnnSvg } from 'assets/projectTypes/video.svg';
import { ReactComponent as DigitizationSvg } from './assets/projectTypes/digitization.svg';
import { ReactComponent as ContentModerationSvg } from './assets/projectTypes/contentModeration.svg';
import { ReactComponent as DatasetCollectionSvg } from './assets/projectTypes/datasetCollection.svg';
import { ReactComponent as NLPSvg } from './assets/projectTypes/nlp.svg';
import React from 'react';
import { varEnv } from 'utils/envVars';
import { WizardFormValuesWithBetterNames } from 'containers/AddProjectWizard/AddProjectWizard.types';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ProjectStatus } from 'generated/graphql';
import { AnnotationUnitType } from 'containers/AnnotationSystem/AnnotationSystem.types';
import getCardColors from 'containers/Microtasking/utils/getCardColors';

export const TOKEN = 'token';
export const USER_SESSION_DATA = 'userSessionData';
export const APP_BAR_HEIGHT = '60px';
export const DASHBOARD_MAIN_PADDING = '16px';

export const AUTOSAVE_TIMEOUT = 30000;
export const AUTOSAVE_LOADER_DELAY = 2000;

export const CUR_USER_SESSION_INIT_DATA: CurrentUser = {
  isLoggedIn: false,
  enabled: true,
  email: '',
  firstName: '',
  lastName: '',
  id: '',
  companyName: '',
  activated: false
};

export const Z_INDEXES = {
  sidebarMain: 1000,
  addProjectWiz: 1001,
  mLabelsModal: 1005,
  popover: 1004,
  modal: 1005,
  toaster: 1010,
  dropdown: 1030,
  confirmModal: 1035
};

export const DEFAULT_REDIRECT_AFTER_REQUEST =
  varEnv.defaultRedirectTimeoutAfterRequest;

const serverConnectionErrorMessage =
  'Server connection error. Please try again later';

export const MESSAGES = {
  acceptInviteByEmailSuccess:
    'You successfully accepted an invite! Redirect to Dashboard...',
  acceptInviteByEmailFail: serverConnectionErrorMessage,
  authRegActivatedSuccess: 'Activated successfully',
  authRegActivatedFail: 'Authentication link is expired',
  serverError: {
    common: serverConnectionErrorMessage
  }
};

export const TABLE_ITEMS_PER_PAGE = 20;
export const CARD_MODAL_WIDTH = 1200;
export const CARD_MODAL_HEIGH = 600;
export const PRACTICAL_PROJECT_ID = '1';

export const CHUNK_LENGTH = {
  unlim: 9999,
  def: 1
};

export const ROUTES = {
  root: '/',
  signOut: '/signOut',
  signIn: '/signin',
  signUp: '/signup',
  dashboard: '/dashboard',
  acceptInviteById: '/invite/accept/:id',
  workStatistics: '/workStatistics'
};

export const ADD_WIZARD_FIELDS: Record<
  keyof WizardFormValuesWithBetterNames,
  any
> = {
  adminInstructionFileName: 'adminInstructionFileName',
  adminShortInstruction: 'adminShortInstruction',
  annotationImages: 'annotationImages',
  auditorRate: 'auditorRate',
  clientId: 'clientId',
  clientInstructionFileNames: 'clientInstructionFileNames',
  clientShortInstruction: 'clientShortInstruction',
  customLabels: 'customLabels',
  files: 'files',
  helperRate: 'helperRate',
  mediaFileName: 'mediaFileName',
  title: 'title',
  customMessageInviteHelpersRequest: 'customMessageInviteHelpersRequest',
  clientInstructionTextarea: 'clientShortInstruction',
  media: 'mediaFileName',
  labels: 'labels',
  mlLabels: 'mlLabels',
  type: 'type',
  projectSettings: {
    subType: 'subType',
    chunkLength: 'chunkLength'
  },
  platform: 'platform',
  authToken: 'AUTH_TOKEN',
  sbCurUser: 'SB_CUR_USER'
};

export const UPLOAD_TYPES = {
  single: 'single',
  multiple: 'multiple',
  googleDrive: 'googleDrive'
};

export const VIDEO_ANNOTATION_PREDEFINED_INTERVALS: {
  label: string;
  value: string;
}[] = [
  { label: '0.5 sec', value: '0.5' },
  { label: '1 sec', value: '1' },
  { label: '2 sec', value: '2' }
];

export const VIDEO_ANNOTATION_PREDEFINED_FRAMES_INTERVALS: ItemType[] = [
  { label: '1 frame', key: 1 },
  { label: '5 frame', key: 5 },
  { label: '10 frame', key: 10 }
];

export const BLENDR_CONNECT_STATUSES = {
  NO: 'no',
  YES: 'connected',
  FAIL: 'failed to connect'
};

export const CLIENT_INIT = {
  firstName: '',
  lastName: ''
};

export const PROJECT_INIT = {
  id: 0,
  title: '',
  mlLabels: [],
  client: CLIENT_INIT,
  customLabels: false
};

export const TASK_DATA_INIT = {
  id: 0,
  projectId: 0,
  updatedAt: '',
  result: [],
  createdAt: '',
  type: ProjectType.IMAGE_ANNOTATION,
  project: PROJECT_INIT,
  history: [],
  status: ProjectStatus.Active,
  mediaUrl: '',
  videoStart: 0,
  videoEnd: 0,
  videoFPS: 0,
  videoFrameRate: 0,
  labels: [],
  helperTimeSpent: 0,
  returnedFromAuditor: false,
  timesReturnedFromAuditor: 0,
  isAvailableForHelper: true
};

export const SPECIALIZATION_LIST = [
  {
    id: 'VA',
    label: ProjectTypeLabel.VIDEO_ANNOTATION,
    value: ProjectType.VIDEO_ANNOTATION,
    icon: <VideoAnnSvg />,
    iconComponent: VideoAnnSvg
  },
  {
    id: 'IA',
    label: ProjectTypeLabel.IMAGE_ANNOTATION,
    value: ProjectType.IMAGE_ANNOTATION,
    icon: <ImageAnnSvg />,
    iconComponent: ImageAnnSvg
  },
  {
    id: 'CM',
    label: ProjectTypeLabel.CONTENT_MODERATION,
    value: ProjectType.CONTENT_MODERATION,
    icon: <ImageAnnSvg />,
    iconComponent: ImageAnnSvg
  },
  {
    id: 'DG',
    label: ProjectTypeLabel.DIGITIZATION,
    value: ProjectType.DIGITIZATION,
    icon: <DigitizationSvg />,
    iconComponent: DigitizationSvg
  }
];

export const microtaskingStructure: MicrotaskingStructureProps[] = [
  {
    id: ProjectType.NLP,
    type: ProjectType.NLP,
    specializations: [
      { id: 1, specType: ProjectSpecialization.SENTIMENT_ANALYSIS },
      { id: 2, specType: ProjectSpecialization.CONTENT_RELEVANCE_SCORE },
      { id: 3, specType: ProjectSpecialization.PRODUCT_CATEGORIES },
      { id: 4, specType: ProjectSpecialization.LANGUAGE_DETECTION }
    ]
  },
  {
    id: ProjectType.IMAGE_ANNOTATION,
    type: ProjectType.IMAGE_ANNOTATION,
    specializations: [
      { id: 1, specType: ProjectSpecialization.BOUNDING_BOX },
      { id: 2, specType: ProjectSpecialization.ROTATED_BOUNDING_BOX },
      { id: 3, specType: ProjectSpecialization.POLYGONS },
      { id: 4, specType: ProjectSpecialization.HUMAN_POSES },
      { id: 5, specType: ProjectSpecialization.SEMANTIC_SEGMENTS },
      { id: 6, specType: ProjectSpecialization.POLYLINE },
      { id: 7, specType: ProjectSpecialization.CUBOIDS_3D }
    ]
  },
  {
    id: ProjectType.VIDEO_ANNOTATION,
    type: ProjectType.VIDEO_ANNOTATION,
    specializations: [
      { id: 1, specType: ProjectSpecialization.BOUNDING_BOX },
      { id: 2, specType: ProjectSpecialization.ROTATED_BOUNDING_BOX },
      { id: 3, specType: ProjectSpecialization.POLYGONS },
      { id: 4, specType: ProjectSpecialization.HUMAN_POSES },
      { id: 5, specType: ProjectSpecialization.SEMANTIC_SEGMENTS },
      { id: 6, specType: ProjectSpecialization.POLYLINE },
      { id: 7, specType: ProjectSpecialization.CUBOIDS_3D }
    ]
  },
  {
    id: ProjectType.CONTENT_MODERATION,
    type: ProjectType.CONTENT_MODERATION,
    specializations: []
  },
  {
    id: ProjectType.DATA_SET_COLLECTION,
    type: ProjectType.DATA_SET_COLLECTION,
    specializations: []
  }
];

export const microtaskingExtendedStructure: MicrotaskingStructureProps[] =
  microtaskingStructure.map((item) => {
    const colors = getCardColors(item.specializations.length);
    return {
      ...item,
      specializations: item.specializations.map((specialization, key) => ({
        ...specialization,
        color: colors[key]
      }))
    };
  });

export const mapProjectSpecToAnnoUnitType: Record<
  ProjectSpecialization,
  AnnotationUnitType | null
> = {
  BOUNDING_BOX: 'box',
  ROTATED_BOUNDING_BOX: 'rotatedBox',
  HUMAN_POSES: 'dot',
  POLYGONS: 'polygon',
  CUBOIDS_3D: 'cuboids3D',
  POLYLINE: 'polyline',
  SEMANTIC_SEGMENTS: 'semantic',
  SENTIMENT_ANALYSIS: 'sentimentAnalysis',
  CONTENT_RELEVANCE_SCORE: 'contentRelevanceScore',
  PRODUCT_CATEGORIES: 'productCategories',
  LANGUAGE_DETECTION: 'languageDetection'
};

export const mapProjectSpecToAnnotationType: Record<
  ProjectSpecialization,
  AnnotationSpecificationType
> = {
  BOUNDING_BOX: 'box',
  ROTATED_BOUNDING_BOX: 'rotatedBoxes',
  HUMAN_POSES: 'dot',
  POLYGONS: 'polygon',
  CUBOIDS_3D: 'cuboids3D',
  POLYLINE: 'polyLines',
  SEMANTIC_SEGMENTS: 'semantics',
  SENTIMENT_ANALYSIS: 'sentimentAnalysis',
  CONTENT_RELEVANCE_SCORE: 'contentRelevanceScore',
  PRODUCT_CATEGORIES: 'productCategories',
  LANGUAGE_DETECTION: 'languageDetection'
};

export const mapProjectSpecToAnnotationTitle: Record<
  AnnotationSpecificationType | string,
  string
> = {
  box: 'Bounding boxes',
  rotatedBoxes: 'Rotated Bounding Boxes',
  dot: 'Dots',
  polygon: 'Polygons',
  cuboids3D: 'Cuboids (3D)',
  polyLines: 'Polyline',
  semantics: 'Semantic Segmentation',
  sentimentAnalysis: 'Sentiment-analysis',
  contentRelevanceScore: 'Content relevance score',
  productCategories: 'Product categories',
  languageDetection: 'Language detection'
};

export const getSpecializationByAnnotationType = (
  annotationType?: AnnotationSpecificationType | string | null
): ProjectSpecialization =>
  (Object.keys(mapProjectSpecToAnnotationType) as ProjectSpecialization[]).find(
    (key) => mapProjectSpecToAnnotationType[key] === annotationType
  ) || ProjectSpecialization.BOUNDING_BOX;

export const ProjectImageSpecializationNotExist: ProjectSpecialization[] = [
  ProjectSpecialization.ROTATED_BOUNDING_BOX,
  ProjectSpecialization.SEMANTIC_SEGMENTS,
  ProjectSpecialization.POLYLINE,
  ProjectSpecialization.CUBOIDS_3D
];

export const ProjectVideoSpecializationNotExist: ProjectSpecialization[] = [
  ProjectSpecialization.ROTATED_BOUNDING_BOX,
  ProjectSpecialization.POLYGONS,
  ProjectSpecialization.SEMANTIC_SEGMENTS,
  ProjectSpecialization.POLYLINE,
  ProjectSpecialization.CUBOIDS_3D
];

export const PROJECT_TYPES: Array<{
  id: string;
  label: string;
  value: string;
  icon: React.ReactElement;
  iconType?: 'fill' | 'stroke';
  iconComponent: React.FunctionComponent;
  disabled?: boolean;
}> = [
  {
    id: 'VA',
    label: ProjectTypeLabel.VIDEO_ANNOTATION,
    value: ProjectType.VIDEO_ANNOTATION,
    icon: <VideoAnnSvg />,
    iconComponent: VideoAnnSvg
  },
  {
    id: 'IA',
    label: ProjectTypeLabel.IMAGE_ANNOTATION,
    value: ProjectType.IMAGE_ANNOTATION,
    icon: <ImageAnnSvg />,
    iconComponent: ImageAnnSvg
  },
  {
    id: 'CM',
    label: ProjectTypeLabel.CONTENT_MODERATION,
    value: ProjectType.CONTENT_MODERATION,
    icon: <ContentModerationSvg />,
    iconType: 'fill',
    iconComponent: ContentModerationSvg
  },
  // {
  //   id: 'DG',
  //   label: ProjectTypeLabel.DIGITIZATION,
  //   value: ProjectType.DIGITIZATION,
  //   icon: <DigitizationSvg />,
  //   iconComponent: DigitizationSvg
  // },
  {
    id: 'DC',
    label: ProjectTypeLabel.DATA_SET_COLLECTION,
    value: ProjectType.DATA_SET_COLLECTION,
    icon: <DatasetCollectionSvg />,
    iconType: 'fill',
    iconComponent: DatasetCollectionSvg
  },
  {
    id: 'NLP',
    label: ProjectTypeLabel.NLP,
    value: ProjectType.NLP,
    icon: <NLPSvg />,
    iconComponent: NLPSvg
  }
];

export const MEDIA_BREAKPOINTS = {
  mobile: '1000px'
};
export const MEDIA_QUERY_SETTINGS = {
  isMobile: { query: `(max-width: ${MEDIA_BREAKPOINTS.mobile})` }
};

export const LABEL_MAX_CHARS = 50;

export const APP_STATE: AppState = {
  currentUser: CUR_USER_SESSION_INIT_DATA
};

export const APP_STATE_CONTEXT_RETURN_INIT: AppStateContextReturn = {
  currentUser: CUR_USER_SESSION_INIT_DATA,
  updateCurrentUser: () => null,
  signOut: () => Promise.resolve(),
  signIn: () => null
};

export const PROJECTS_LIST_ALL_PAGE = 'PROJECTS_LIST_ALL_PAGE';
export const PROJECTS_LIST_ALL_SORT = 'PROJECTS_LIST_ALL_SORT';
export const PROJECTS_LIST_PENDING_PAGE = 'PROJECTS_LIST_PENDING_PAGE';
export const PROJECTS_LIST_PENDING_SORT = 'PROJECTS_LIST_PENDING_SORT';
export const CLIENTS_LIST_PAGE = 'CLIENTS_LIST_PAGE';
export const USERS_LIST_PAGE = 'USERS_LIST_PAGE';
export const WORK_STATISTICS_PAGE = 'WORK_STATISTICS_PAGE';
export const CLIENTS_LIST_SORT = 'CLIENTS_LIST_SORT';
export const LESSONS_PAGE = 'LESSONS_PAGE';
