import { useMemo } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { LESSONS_PAGE } from 'appConstants';

interface LessonPageProps {
  topicId: number;
  lessonId: number;
}

const INIT_PAGE = {
  topicId: 1,
  lessonId: 1
};

function useGetLessonPageFromStorage(): [
  LessonPageProps,
  (data: LessonPageProps) => void
] {
  const [page, setPage] = useLocalStorage<LessonPageProps>(
    LESSONS_PAGE,
    INIT_PAGE,
    { preserve: true }
  );

  return useMemo(() => [page || INIT_PAGE, setPage], [page, setPage]);
}

export default useGetLessonPageFromStorage;
