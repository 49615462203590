import { PlayIcon, PauseIcon } from 'assets';
import React from 'react';
import { PlayButtonStyled } from '../VideoPlayer.ui';

function PlayButton({
  isPlaying,
  onClick
}: {
  isPlaying: boolean;
  onClick: React.MouseEventHandler;
}) {
  return (
    <PlayButtonStyled onClick={onClick}>
      {isPlaying ? <PauseIcon /> : <PlayIcon />}
    </PlayButtonStyled>
  );
}

export default PlayButton;
