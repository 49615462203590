import { Helper } from 'generated/graphql';

export default function mapHelperAssignList(
  helpersAssignedToProject: Helper[]
) {
  const helpersIds =
    helpersAssignedToProject
      ?.filter((helper) => helper && helper.assignedToProjectAsHelper)
      .map((helper) => helper && parseInt(helper.id, 10)) ?? [];
  const auditorsIds =
    helpersAssignedToProject
      ?.filter((helper) => helper && helper.assignedToProjectAsAuditor)
      .map((helper) => helper && parseInt(helper.id, 10)) ?? [];

  return {
    helpersIds,
    auditorsIds
  };
}
