import React from 'react';
import { Input } from 'components/Input';
import { InputLabel } from 'components/InputLabel';
import { InputLabelFieldProps } from './InputLabelField.types';
import { mapInputProps } from './InputLabelField.utils';
import { useField } from 'formik';

function InputLabelField<T extends string | number = string>(
  props: InputLabelFieldProps<T>
) {
  const { componentProps, restProps } = mapInputProps<T>(props);

  const { children, name, checkbox, formikFieldProps, ...labelProps } =
    restProps;

  const [field, meta, helper] = useField({
    name,
    ...(checkbox && { type: 'checkbox' }),
    ...formikFieldProps
  });

  return (
    <InputLabel {...labelProps} meta={meta} helper={helper}>
      {children && React.isValidElement(children) ? (
        React.cloneElement(children, {
          ...children?.props,
          ...componentProps,
          ...field,
          meta,
          helper
        })
      ) : (
        <Input {...field} {...componentProps} />
      )}
    </InputLabel>
  );
}

export default InputLabelField;
