import React, { useRef } from 'react';
import { AnnotationUnitProps } from '../../AnnotationSystem.types';
import { useAnnotationSystemState } from '../../context';
import { InnerCircle, MainBorderCircle, Root } from './Dot.ui';
import { Col, ButtonInfo, Text } from 'components/_main';
import ReactDOM from 'react-dom';
import { DOT_CIRCLE_SIZE } from './Dot.constants';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import useConnectionLinesUpdate from './useConnectionLinesUpdate';
import useHandleState from '../../hooks/useHandleState';
import useDebouncedStateUpdate from 'containers/AnnotationSystem/hooks/useDebouncedStateUpdate';
import useSvgLayerMouseMove from 'containers/AnnotationSystem/hooks/useSvgLayerMouseMove';
import useSvgLayerMouseUp from 'containers/AnnotationSystem/hooks/useSvgLayerMouseUp';
import useMouseOver from '../../hooks/useMouseOver';
import useMouseDown from '../../hooks/useMouseDown';
import useUpdateOuterRectOnDotsChange from './useUpdateOuterRectOnDotsChange';
import useIndexColor from 'containers/AnnotationSystem/hooks/useIndexColor';
import Hint from './Hint';

export default function Dot({
  unitProps
}: React.PropsWithChildren<{ unitProps: AnnotationUnitProps }>) {
  const { isViewOnly } = useAnnotationSystemState();
  const svgRef = useRef<SVGSVGElement>(null);
  const toolbarLabel = document.getElementById('toolbar-label');

  const { props } = useHandleState({ props: unitProps });

  const { h, unitId, label, w, x, y, groupIndex, type, description } = props;

  const onStateUpdate = useDebouncedStateUpdate({
    props,
    ref: svgRef
  });

  useMouseOver({ unitId, ref: svgRef });
  useMouseDown({ unitId, ref: svgRef });

  const onConnectionLinesUpdate = useConnectionLinesUpdate({
    unitId
  });

  const onOuterRectUpdate = useUpdateOuterRectOnDotsChange({
    groupIndex,
    unitId
  });

  useSvgLayerMouseMove({
    unitId,
    ref: svgRef,
    onDrag: (props) => {
      onConnectionLinesUpdate(props);
      onOuterRectUpdate(props);
    }
  });

  useSvgLayerMouseUp({
    onStateUpdate,
    unitId
  });

  const { isActive, isDrag, isHidden, isDotSel } = useStatus(props);

  const renderLabelInput = (
    <Col flexCenter>
      <Text>Description:</Text>
      <ButtonInfo>{unitProps?.description}</ButtonInfo>
    </Col>
  );

  const indexColor = useIndexColor(groupIndex);

  if (!x || !y || !w || !h) {
    return null;
  }

  if (isViewOnly || isHidden) {
    return null;
  }

  return (
    <>
      <Root
        color={indexColor}
        data-type="dot"
        data-group-index={groupIndex}
        className="skeletonPoint noselect"
        ref={svgRef}
        x={x}
        y={y}
        width={DOT_CIRCLE_SIZE}
        height={DOT_CIRCLE_SIZE}
        data-is-active={isActive}
        data-is-drag={isDrag}
        data-label={label}
        data-id={unitId}
        data-unit-id={unitId}
      >
        <>
          {isDotSel && (
            <>
              <MainBorderCircle
                isDrag={isDrag}
                isActive={isActive}
                color={indexColor}
                data-change-type="drag"
                cx="0"
                cy="0"
                r="100%"
              />
              <InnerCircle data-change-type="drag" cx="0" cy="0" r="20%" />
            </>
          )}
          {isActive &&
            toolbarLabel &&
            ReactDOM.createPortal(renderLabelInput, toolbarLabel)}
        </>
      </Root>
      <Hint svgRef={svgRef} hintDescription={description} />
    </>
  );
}
