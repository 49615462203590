import React, { useRef } from 'react';
import {
  AnnotationSystemModesEnum,
  AnnotationUnitProps
} from '../../AnnotationSystem.types';
import ResizePoints from '../ResizePoints';
import { MainBorderRect, Root } from './DotsWrapper.ui';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import useHandleState from 'containers/AnnotationSystem/hooks/useHandleState';
import useDebouncedStateUpdate from 'containers/AnnotationSystem/hooks/useDebouncedStateUpdate';
import useSvgLayerMouseUp from 'containers/AnnotationSystem/hooks/useSvgLayerMouseUp';
import useUpdateDotsOnOuterRectMove from './useUpdateDotsOnOuterRectMove';
import UnitToolbarWithLabelSelect from 'containers/AnnotationSystem/components/UnitToolbarWithLabelSelect';
import useIndexColor from 'containers/AnnotationSystem/hooks/useIndexColor';
import useUpdateDotsOnOuterRectResize from './useUpdateDotsOnOuterRectResize';
import useMouseDown from 'containers/AnnotationSystem/hooks/useMouseDown';
import useSvgLayerMouseMove from 'containers/AnnotationSystem/hooks/useSvgLayerMouseMove';
import { initStartPos, onResizeMouseDown } from './utils';
import useOnCopy from '../../hooks/useOnCopy';
import useOnRevertDots from '../../hooks/useOnRevertDots';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';

interface Props extends AnnotationUnitProps {}

export default function AnnotationUnitsWrapper(propsArg: Props) {
  const svgRef = useRef<SVGSVGElement>(null);
  const firstClickId = useRef<any>(0);

  const { isViewOnly, isUnitCreatingProcess } = useAnnotationSystemState();
  const { onSetCurMode } = useAnnotationSystemActions();
  const { props } = useHandleState({ props: propsArg });

  const onStateUpdate = useDebouncedStateUpdate({
    props,
    ref: svgRef
  });

  let { x, y, w, h, unitId, label, type, groupIndex } = props;

  const onMove = useUpdateDotsOnOuterRectMove({
    groupIndex,
    svgRef
  });

  const onResize = useUpdateDotsOnOuterRectResize({
    groupIndex,
    svgRef
  });

  const { isActive, isResize, isHidden, isDotSel, isDrag } = useStatus(props);

  const handleCopy = useOnCopy();
  const handleRevertDots = useOnRevertDots();

  const indexColor = useIndexColor(groupIndex);

  useMouseDown({
    unitId,
    ref: svgRef,
    onMouseDown: () => {
      /** On double click */
      if (firstClickId.current) {
        firstClickId.current = 0;
        onSetCurMode(AnnotationSystemModesEnum.DOT_SELECT);
        return;
      }

      firstClickId.current = 1;
      setTimeout(() => {
        firstClickId.current = 0;
      }, 200);
    },
    onResizeMouseDown: () => {
      initStartPos(svgRef?.current);
      onResizeMouseDown({ h, w, x, y, groupIndex });
    },
    onDragMouseDown: () => {
      initStartPos(svgRef?.current);
      onResizeMouseDown({ h, w, x, y, groupIndex });
    }
  });

  useSvgLayerMouseMove({
    ref: svgRef,
    unitId
  });

  useSvgLayerMouseUp({
    onStateUpdate,
    unitId,
    onDragMouseUp: onMove,
    onResizeMouseUp: onResize
  });

  if (isDotSel || isHidden) return null;

  return (
    <Root
      data-unit-id={unitId}
      color={indexColor}
      ref={svgRef}
      isActive={isActive}
      isCreate={isUnitCreatingProcess}
      x={x}
      y={y}
      width={w}
      height={h}
      data-id={unitId}
      data-is-active={isActive}
      data-is-drag={isDrag}
      data-type={type}
      data-label={label}
      data-group-index={groupIndex}
    >
      <MainBorderRect
        color={indexColor}
        isActive={isActive}
        data-change-type="drag"
        width="100%"
        height="100%"
      />
      {isActive && !isResize && !isDrag && <ResizePoints color={indexColor} />}
      {!isViewOnly && (
        <UnitToolbarWithLabelSelect
          onEdit={() => onSetCurMode(AnnotationSystemModesEnum.DOT_SELECT)}
          unitProps={props}
          show={isActive && !isResize && !isDrag}
          svgRef={svgRef}
          label={label}
          onCopy={handleCopy}
          onRevertHorizontalDots={() => handleRevertDots('horizontal')}
          onRevertVerticalDots={() => handleRevertDots('vertical')}
          isEditDisabled
          color={indexColor}
        />
      )}
    </Root>
  );
}
