import React from 'react';
import { toast as toastify } from 'react-toastify';
import Toast from 'components/Toast';
import { Id, ToastContent, ToastOptions } from 'react-toastify/dist/types';

export const toast = {
  success: (text: string, options?: ToastOptions) =>
    toastify.success(<Toast text={text} />, options),
  error: (text: string, options?: ToastOptions) =>
    toastify.error(<Toast type="error" text={text} />, options),
  info: (text: string, title?: string, options?: ToastOptions) =>
    toastify.info(<Toast type="info" title={title} text={text} />, options),
  warning: (text: ToastContent, options?: ToastOptions) =>
    toastify.warning(text, options),
  dismiss: (id?: Id) => toastify.dismiss(id)
};
