import {
  AnnotationUnitProps,
  AnnotationSystemEvents,
  AnnotationSystemProps,
  VideoStatus,
  AnnotationUnits,
  ResizePosition
} from '../AnnotationSystem.types';
import {
  AddAnnotationUnit,
  DeleteAnnotationUnit,
  ModifyAnnotationUnit,
  OnInit,
  OnReset,
  ReducerActions,
  SetCurEvent,
  SetCurHoverUnitId,
  SetCurSelUnitId,
  SetCursorInitPos,
  SetCurVideoStatus,
  SetCurVideoTime,
  SetH,
  SetInitSize,
  SetMediaImageRef,
  SetMediaVideoRef,
  SetSvgLayerRef,
  SetW,
  SetX,
  SetZoomLevel,
  ZoomIn,
  ZoomOut,
  SetMediaLoadingFinish,
  InitAnnotationUnits,
  ZoomReset,
  SetHideAnnotationUnitId,
  SetSvgLayerOpacityLevel,
  SetCurMode,
  SetEventResize,
  DeleteTransitionPoint,
  ModifyTransitionPoint,
  SetIsLoading,
  SetAnnotationAccuracy,
  SetPageFrames,
  SetCopyUnitId,
  AddPolygonPoint,
  DeletePolygonPoint,
  ModifyPolygonPoint,
  SetCurSelPointId,
  AddAtStrokePolygonPoint,
  SetPolygonPoints,
  ToggleLabelSelectDropdown,
  SetAnnotationPracticeProgress
} from './types';

export function setMediaVideoRef(
  ref: HTMLVideoElement | null
): SetMediaVideoRef {
  return {
    payload: ref,
    type: ReducerActions.SET_MEDIA_VIDEO_REF
  };
}

export function setMediaLoadingFinish(
  payload: SetMediaLoadingFinish['payload']
): SetMediaLoadingFinish {
  return {
    payload,
    type: ReducerActions.SET_MEDIA_LOADING_FINISH
  };
}

export function setMediaImageRef(
  ref: HTMLImageElement | null
): SetMediaImageRef {
  return {
    payload: ref,
    type: ReducerActions.SET_MEDIA_IMAGE_REF
  };
}

export function setSvgLayerRef(ref: SVGSVGElement | null): SetSvgLayerRef {
  return {
    payload: ref,
    type: ReducerActions.SET_SVG_LAYER_REF
  };
}

export function setCurEvent(event: AnnotationSystemEvents): SetCurEvent {
  return {
    payload: event,
    type: ReducerActions.SET_CUR_EVENT
  };
}

export function setH(h: number): SetH {
  return {
    payload: h,
    type: ReducerActions.SET_H
  };
}

export function setW(w: number): SetW {
  return {
    payload: w,
    type: ReducerActions.SET_W
  };
}

export function setX(x: number): SetX {
  return {
    payload: x,
    type: ReducerActions.SET_X
  };
}

export function setY(y: number) {
  return {
    payload: y,
    type: ReducerActions.SET_Y
  };
}

export function addAnnotationUnit(
  annotationUnit: AnnotationUnitProps
): AddAnnotationUnit {
  return {
    payload: annotationUnit,
    type: ReducerActions.ADD_ANNOTATION_UNIT
  };
}

export function modifyAnnotationUnit(
  annotationUnit: Partial<AnnotationUnitProps>
): ModifyAnnotationUnit {
  return {
    payload: annotationUnit,
    type: ReducerActions.MODIFY_ANNOTATION_UNIT
  };
}

export function deleteAnnotationUnit(unitId: string): DeleteAnnotationUnit {
  return {
    payload: unitId,
    type: ReducerActions.DELETE_ANNOTATION_UNIT
  };
}

export function deleteTransitionPoint(
  payload: DeleteTransitionPoint['payload']
): DeleteTransitionPoint {
  return {
    payload,
    type: ReducerActions.DELETE_TRANSITION_POINT
  };
}

export function modifyTransitionPoint(
  payload: ModifyTransitionPoint['payload']
): ModifyTransitionPoint {
  return {
    payload,
    type: ReducerActions.MODIFY_TRANSITION_POINT
  };
}

export function initAnnotationUnits(
  newUnits: AnnotationUnits
): InitAnnotationUnits {
  return {
    payload: newUnits,
    type: ReducerActions.INIT_ANNOTATION_UNITS
  };
}

export function zoomIn(): ZoomIn {
  return {
    payload: null,
    type: ReducerActions.ZOOM_IN
  };
}

export function zoomOut(): ZoomOut {
  return {
    payload: null,
    type: ReducerActions.ZOOM_OUT
  };
}

export function setZoomLevel(zoomLevel: number): SetZoomLevel {
  return {
    payload: zoomLevel,
    type: ReducerActions.SET_ZOOM_LEVEL
  };
}

export function zoomReset(): ZoomReset {
  return {
    payload: null,
    type: ReducerActions.ZOOM_RESET
  };
}

export function setInitSize({ w, h }: { w: number; h: number }): SetInitSize {
  return {
    payload: {
      w,
      h
    },
    type: ReducerActions.SET_INIT_SIZE
  };
}

export function setCurSelUnitId(
  payload: SetCurSelUnitId['payload']
): SetCurSelUnitId {
  return {
    payload,
    type: ReducerActions.SET_CUR_SEL_UNIT_ID
  };
}

export function setCurSelPointId(
  payload: SetCurSelPointId['payload']
): SetCurSelPointId {
  return {
    payload,
    type: ReducerActions.SET_CUR_SEL_POINT_ID
  };
}

export function setHideAnnotationUnitId(
  ids: string[]
): SetHideAnnotationUnitId {
  return {
    payload: ids,
    type: ReducerActions.SET_HIDE_ANNOTATION_UNIT_ID
  };
}

export function setCurHoverUnitId(unitId: string | null): SetCurHoverUnitId {
  return {
    payload: unitId,
    type: ReducerActions.SET_CUR_HOVER_UNIT_ID
  };
}

export function setCurVideoTime(time: number): SetCurVideoTime {
  return {
    payload: time,
    type: ReducerActions.SET_CUR_VIDEO_TIME
  };
}

export function setCurVideoStatus(status: VideoStatus): SetCurVideoStatus {
  return {
    payload: status,
    type: ReducerActions.SET_CUR_VIDEO_STATUS
  };
}

export function setCursorInitPos(
  props: SetCursorInitPos['payload']
): SetCursorInitPos {
  return {
    payload: props,
    type: ReducerActions.SET_CURSOR_INIT_POS
  };
}
export function setSvgLayerOpacityLevel(
  opacityLevel: SetSvgLayerOpacityLevel['payload']
): SetSvgLayerOpacityLevel {
  return {
    payload: opacityLevel,
    type: ReducerActions.SET_SVG_LAYER_OPACITY_LEVEL
  };
}

export function setCurMode(opacityLevel: SetCurMode['payload']): SetCurMode {
  return {
    payload: opacityLevel,
    type: ReducerActions.SET_CUR_MODE
  };
}

export function setEventResize(resizePosition: ResizePosition): SetEventResize {
  return {
    payload: resizePosition,
    type: ReducerActions.SET_EVENT_RESIZE
  };
}

export function onReset(): OnReset {
  return {
    payload: null,
    type: ReducerActions.RESET
  };
}

export function onInit(newInit: Partial<AnnotationSystemProps>): OnInit {
  return {
    payload: newInit,
    type: ReducerActions.INIT
  };
}

export function setPageFrames(page: SetPageFrames['payload']): SetPageFrames {
  return {
    payload: page,
    type: ReducerActions.SET_PAGE_FRAMES
  };
}

export function setIsLoading(payload: SetIsLoading['payload']): SetIsLoading {
  return {
    payload,
    type: ReducerActions.SET_IS_LOADING
  };
}

export function setAnnotationAccuracy(
  payload: SetAnnotationAccuracy['payload']
): SetAnnotationAccuracy {
  return {
    payload,
    type: ReducerActions.SET_ANNOTATION_ACCURACY
  };
}

export function setAnnotationPracticeProgress(
  payload: SetAnnotationPracticeProgress['payload']
): SetAnnotationPracticeProgress {
  return {
    payload,
    type: ReducerActions.SET_ANNOTATION_PRACTICE_PROGRESS
  };
}

export function setCopyUnitId(
  payload: SetCopyUnitId['payload']
): SetCopyUnitId {
  return {
    payload: null,
    type: ReducerActions.SET_COPY_UNIT_ID
  };
}

export function addPolygonPoint(
  payload: AddPolygonPoint['payload']
): AddPolygonPoint {
  return {
    payload,
    type: ReducerActions.ADD_POLYGON_POINT
  };
}

export function addAtStrokePolygonPoint(
  payload: AddAtStrokePolygonPoint['payload']
): AddAtStrokePolygonPoint {
  return {
    payload,
    type: ReducerActions.ADD_AT_STROKE_POLYGON_POINT
  };
}

export function deletePolygonPoint(
  payload: DeletePolygonPoint['payload']
): DeletePolygonPoint {
  return {
    payload,
    type: ReducerActions.DELETE_POLYGON_POINT
  };
}

export function modifyPolygonPoint(
  payload: ModifyPolygonPoint['payload']
): ModifyPolygonPoint {
  return {
    payload,
    type: ReducerActions.MODIFY_POLYGON_POINT
  };
}

export function setPolygonPoints(
  payload: SetPolygonPoints['payload']
): SetPolygonPoints {
  return {
    payload,
    type: ReducerActions.SET_POLYGON_POINTS
  };
}

export function toggleLabelSelectDropdown(
  payload: null
): ToggleLabelSelectDropdown {
  return {
    payload,
    type: ReducerActions.TOGGLE_LABEL_SELECT_DROPDOWN
  };
}
