import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../../context';
import {
  AnnotationUnitProps,
  AnnotationUnitType,
  TimelineTransition
} from '../../AnnotationSystem.types';
import { Rect, SvgRoot } from './CreateBox.ui';
import { useMousePositionRef } from 'hooks/useMousePositionRef';
import { saveWithZoom } from '../../utils';
import useMouseMove from './useMouseMove';
import useMouseDown from './useMouseDown';
import { toast } from 'utils/toast';
import UnitToolbarWithLabelSelect from '../UnitToolbarWithLabelSelect';
import { useTheme } from 'styled-components/macro';
import { useKeyDown } from 'hooks/useKeyDown';

export default function CreateBox() {
  const svgRef = useRef<SVGSVGElement>(null);
  const { type, mediaVideoRef, curZoomLevel } = useAnnotationSystemState();

  const theme = useTheme();

  const { onSetCurSelUnitId, onAddAnnotationUnit } =
    useAnnotationSystemActions();

  const { onStart, stateRef, onReset, onUpdate } = useMousePositionRef();

  const {
    isClicked,
    preFinalPosStateRef,
    showLabel,
    setShowLabel,
    setIsClicked
  } = useMouseDown({
    svgRef,
    onReset,
    onStart,
    stateRef
  });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsClicked(false);
    }
  };

  useKeyDown(handleKeyDown);

  useMouseMove({ svgRef, onUpdate, stateRef });

  const handleSuccess = (labelProp: string) => {
    const vid = mediaVideoRef;

    let timelineTransitions: TimelineTransition[] = [];

    if (!preFinalPosStateRef.current) {
      return;
    }

    if (type === 'video') {
      timelineTransitions = [
        {
          ...saveWithZoom(
            { ...preFinalPosStateRef.current } as AnnotationUnitProps,
            curZoomLevel
          ),
          timestamp: (vid as HTMLVideoElement).currentTime ?? 0
        }
      ];
    }

    if (!labelProp) {
      toast.error('Label required');
      return;
    }

    onAddAnnotationUnit({
      ...saveWithZoom(
        { ...preFinalPosStateRef.current } as AnnotationUnitProps,
        curZoomLevel
      ),
      unitId: uuidv4(),
      label: labelProp,
      timelineTransitions,
      isLabelDropdownOpen: false,
      type: 'box' as AnnotationUnitType
    });

    onSetCurSelUnitId(null);
    setShowLabel(false);
    setIsClicked(false);
  };

  const handleLabelSave = (labelProp: string) => {
    handleSuccess(labelProp);
  };

  if (!isClicked) return null;

  return (
    <SvgRoot ref={svgRef} x={0} y={0} width={0} height={0}>
      <Rect width="100%" height="100%" x={0} y={0}></Rect>
      <UnitToolbarWithLabelSelect
        color={theme.colors.primary}
        show={showLabel}
        svgRef={svgRef}
        onSelect={handleLabelSave}
      />
    </SvgRoot>
  );
}
