import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../context';

export default function useAnnotationUnitDelete() {
  const { onDeleteAnnotationUnit } = useAnnotationSystemActions();
  const { curSelUnitId } = useAnnotationSystemState();
  const handleOnDelete = () => {
    if (!curSelUnitId) return;
    onDeleteAnnotationUnit(curSelUnitId);
  };

  return handleOnDelete;
}
