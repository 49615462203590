import useDashboardCtx from './useDashboardCtx';
import { useEffect } from 'react';

export default function useSetAppBarTitle(title: string) {
  const { setAppBarTitle } = useDashboardCtx();

  useEffect(() => {
    setAppBarTitle(title);
  }, [title, setAppBarTitle]);
}
