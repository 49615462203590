import { Link } from 'react-router-dom';
import { Row, Box, Text } from 'components/_main';
import { Check } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { LearningMaterialsTypes } from 'containers/LearningMaterials/LearningMaterial.types';

interface LessonsNavProps {
  data: LearningMaterialsTypes[];
}

function LessonsNav({ data }: LessonsNavProps) {
  const theme = useTheme();
  const params: any = useParams();
  const lessonId = parseInt(params?.lessonId, 10);
  const topicId = parseInt(params?.topicId, 10);

  return (
    <Row gridGap="20px" height="100%" overflowY="auto">
      {data.map(({ id, title, lessons }) => (
        <Row gridGap="20px" key={id}>
          <Box
            displayFlex
            alignItems="center"
            gridGap="14px"
            paddingLeft="15px"
          >
            <Box
              displayFlex
              width="35px"
              minWidth="35px"
              height="35px"
              alignItems="center"
              justifyContent="center"
              borderRadius="50%"
              backgroundColor={theme.colors.primary}
            >
              <Text variant="ui-2-bold" colorVariant="invert">
                {id}
              </Text>
            </Box>
            <Text variant="ui-1-bold" lineHeight="22px" maxWidth="244px">
              {title}
            </Text>
          </Box>
          <Row gridGap="4px">
            {lessons.map(({ id: itemId, title: lessonTitle, isDone }, key) => {
              const isActive = topicId === id && lessonId === itemId;

              return (
                <Link
                  to={`/learning/lessons/${id}/${itemId}`}
                  key={`${id}${itemId}`}
                >
                  <Box
                    display="grid"
                    gridAutoFlow="column"
                    gridAutoColumns="max-content 1fr"
                    gridGap="24px"
                    padding="6px 30px 6px 20px"
                    borderRadius="10px"
                    backgroundColor={isActive ? theme.colors.primaryLight : ''}
                    css={`
                      &:hover {
                        background-color: ${theme.colors.lightest};
                      }
                    `}
                  >
                    <Box
                      displayFlex
                      alignItems="center"
                      justifyContent="center"
                    >
                      {isDone ? (
                        <Check
                          size={18}
                          weight="bold"
                          color={theme.colors.primary}
                        />
                      ) : (
                        <Box padding="0 7px">
                          <Box
                            width="6px"
                            height="6px"
                            borderRadius="50%"
                            backgroundColor={
                              isActive
                                ? theme.colors.primary
                                : theme.colors.lighter
                            }
                          />
                        </Box>
                      )}
                    </Box>
                    <Text variant="ui-1-regular" lineHeight="22px">
                      <Text
                        variant="ui-1-bold"
                        colorVariant="primary"
                        paddingRight="4px"
                      >
                        Lesson {key + 1}:
                      </Text>
                      {lessonTitle}
                    </Text>
                  </Box>
                </Link>
              );
            })}
          </Row>
        </Row>
      ))}
    </Row>
  );
}

export default LessonsNav;
