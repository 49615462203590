import { v4 as uuidv4 } from 'uuid';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useCurSelUnitProps from 'containers/AnnotationSystem/hooks/useCurSelUnitProps';
import { findLastGroupIndex } from 'containers/AnnotationSystem/utils';
import useAnnotationVideo from './useAnnotationVideo';
import { AnnotationUnitProps } from '../AnnotationSystem.types';

export default function useOnCopy(customUnitId?: string | null) {
  const { annotationUnits, annotationSpecificationType } =
    useAnnotationSystemState();
  const { onInitAnnotationUnits, onAddAnnotationUnit } =
    useAnnotationSystemActions();

  const { curVidTime } = useAnnotationVideo();

  const curSelUnitProps = useCurSelUnitProps(customUnitId);

  const handleCopyDots = () => {
    const lastGroutIndex = findLastGroupIndex(annotationUnits);
    const curGroupIndex = curSelUnitProps?.groupIndex;
    const newGroupIndex = lastGroutIndex;

    const unitsWithSameGroupIndex = annotationUnits.filter(
      (unit) => unit.groupIndex === curGroupIndex
    );

    /** Modify coords a bit */
    const newUnitWithShiftedCoords: AnnotationUnitProps[] =
      unitsWithSameGroupIndex.map((unit) => ({
        ...unit,
        unitId: unit.unitId.replace(`${curGroupIndex}-`, `${newGroupIndex}-`),
        groupIndex: newGroupIndex,
        x: unit.x + 20,
        y: unit.y + 20
      }));

    onInitAnnotationUnits([...newUnitWithShiftedCoords, ...annotationUnits]);
  };

  const handleCopy = () => {
    if (!curSelUnitProps) {
      return;
    }

    const copyAnnotationUnit: AnnotationUnitProps = {
      ...curSelUnitProps,
      unitId: uuidv4(),
      x: curSelUnitProps.x + 20,
      y: curSelUnitProps.y + 20,
      timelineTransitions: [
        {
          x: curSelUnitProps.x,
          y: curSelUnitProps.y,
          w: curSelUnitProps.w,
          h: curSelUnitProps.h,
          timestamp: curVidTime,
          isEnd: false
        }
      ]
    };

    onAddAnnotationUnit(copyAnnotationUnit);
  };

  const isDots = annotationSpecificationType === 'dot';
  const isBoxes = annotationSpecificationType === 'box';
  if (isDots) {
    return handleCopyDots;
  }

  if (isBoxes) {
    return handleCopy;
  }

  return () => null;
}
