import { Slider } from 'antd';
import styled, { css } from 'styled-components/macro';

const AntdSlider = styled(Slider)`
  .ant-slider-handle {
    border: 1.5px solid ${({ theme }) => theme.colors.dark};
    width: 16px;
    height: 16px;
  }
  .ant-slider-rail {
    background-color: ${({ theme }) => theme.colors.dark};
  }
  .ant-slider-track {
    background-color: ${({ theme }) => theme.colors.dark};
  }
  &:hover .ant-slider-track {
    background-color: ${({ theme }) => theme.colors.dark};
  }
`;

export default AntdSlider;
