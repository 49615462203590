import React, { useCallback } from 'react';
import { useAppContext } from 'containers/App';
import AuthPageViewNew from 'containers/AuthPageViewNew';
import {
  Container,
  Row,
  Col,
  Text,
  Button,
  Link as LinkMain
} from 'components/_main';
import { FormikError, InputLabelField } from 'components/_form';
import { Formik, useField, useFormikContext } from 'formik';
import AntdCheckbox from '../../components/AntdCheckbox';
import { useRedirect } from 'hooks/useRedirect';
import {
  consoleError,
  isClient,
  parseAuthSuccessResp,
  signupClientValidationSchemaNew,
  varEnv
} from 'utils';
import {
  RegisterClientMutation,
  useRegisterClientMutation,
  useRegisterHelperMutation,
  RegisterHelperMutation
} from 'generated/graphql';
import { toast } from 'utils/toast';
import TermsStaticPage from 'static/Terms';
import useToggleModal from 'hooks/useToggleModal';
import PrivacyPolicy from 'static/PrivacyPolicy';
import GoogleAuth from 'containers/AuthPageViewNew/GoogleAuth';
import AuthFormWrapper from 'containers/AuthPageViewNew/AuthFormWrapper';
import { LinkStyled } from 'containers/AuthPageViewNew/AuthPageView.ui';

function SignUp() {
  const { updateCurrentUser } = useAppContext();

  const formInitValues = {
    firstName: '',
    lastName: '',
    companyName: '',
    companyEmail: '',
    password: '',
    checkbox: false
  };

  const handleOnComplete = useCallback(
    (data: RegisterClientMutation | RegisterHelperMutation) => {
      const newUserData = parseAuthSuccessResp('register', data);
      updateCurrentUser(newUserData);
    },
    [updateCurrentUser]
  );

  const [registerHelper] = useRegisterHelperMutation({
    onCompleted: handleOnComplete
  });
  const [registerClient] = useRegisterClientMutation({
    onCompleted: handleOnComplete
  });

  const regMutation = isClient() ? registerClient : registerHelper;

  const handleRedirect = useRedirect();

  const handleSubmit = async (values: any) => {
    try {
      await regMutation({
        variables: {
          companyName: values.companyName,
          password: values.password,
          email: values.companyEmail,
          firstName: values.firstName,
          lastName: values.lastName,
          termsVersion: '1'
        }
      });
      setTimeout(() => {
        handleRedirect('/activate');
      }, 500);
      /** TODO: Unify, make proper error message handler, to wrap any async BE request with try/catch/error show */
    } catch (e: any) {
      consoleError({ error: e });
      // @ts-ignore
      if (e && 'message' in e && e?.message?.match(/^\[0039]/)) {
        toast.error('Email already registered');
        return;
      }
      toast.error('Server connection error. Please, try again later');
    }
  };

  return (
    <>
      <AuthPageViewNew>
        <AuthPageViewNew.PaneChild>
          <Row height="100%" width="100%" gridAutoRows="max-content 1fr">
            <Row justifyItems="flex-end">
              <Text>
                Back to{' '}
                <LinkMain href={varEnv.taskwareIoUrl} target="_blank">
                  taskware.io
                </LinkMain>
              </Text>
            </Row>
            <Row gridGap="2.5rem" justifyItems="center" alignItems="center">
              <Container width="440px">
                <Formik
                  initialValues={formInitValues}
                  onSubmit={handleSubmit}
                  validationSchema={signupClientValidationSchemaNew}
                >
                  <FormikBody />
                </Formik>
              </Container>
              <Text>
                Already have an account?{' '}
                <LinkStyled to="/signin">
                  <Text variant="ui-1-bold" colorVariant="primary">
                    Sign in
                  </Text>
                </LinkStyled>
              </Text>
            </Row>
          </Row>
        </AuthPageViewNew.PaneChild>
      </AuthPageViewNew>
    </>
  );
}

function FormikBody() {
  const { dirty, isValid, isSubmitting, isValidating, submitForm } =
    useFormikContext();
  const [field] = useField({ name: 'checkbox', type: 'checkbox' });

  const { on: onTermsOpen, modalComponent: termsModal } = useToggleModal({
    modalContent: <TermsStaticPage />,
    modalProps: {
      title: 'Term & Conditions'
    }
  });

  const { on: onPolicyOpen, modalComponent: policyModal } = useToggleModal({
    modalContent: <PrivacyPolicy />,
    modalProps: {
      title: 'Privacy Policy'
    }
  });

  return (
    <AuthFormWrapper submitForm={submitForm}>
      <Row gridGap="2rem">
        <Row justifyItems="center" gridGap=".5rem">
          <Text variant="ui-4-bold">Sign up</Text>
          <Text variant="ui-1-regular">All fields are required</Text>
        </Row>

        <Row gridGap="10px">
          <Col width="100%" gridAutoColumns="1fr 1fr">
            <InputLabelField name="firstName" placeholder="First Name" />
            <InputLabelField name="lastName" placeholder="Last Name" />
          </Col>
          <InputLabelField name="companyEmail" placeholder="Company Email" />
          <InputLabelField name="companyName" placeholder="Company Name" />
          <InputLabelField name="password" placeholder="Password" password />
          <InputLabelField
            name="passwordConfirmation"
            placeholder="Password Confirmation"
            password
          />
        </Row>

        <Row noGap>
          <Col width="100%" gridAutoColumns="auto">
            <AntdCheckbox {...field}>
              <Text ml="1rem" display="inline-block" textAlign="left">
                I agree with{' '}
                <LinkMain onClick={onTermsOpen}>Terms and Conditions</LinkMain>{' '}
                and <LinkMain onClick={onPolicyOpen}>Privacy Policy</LinkMain>
              </Text>
            </AntdCheckbox>
          </Col>
          <FormikError name="checkbox" type="checkbox" />
        </Row>

        <Row justifyItems="center">
          <Button
            size="big"
            width="100%"
            maxWidth="100%"
            disabled={!isValid || !dirty}
            isLoading={isValidating || isSubmitting}
            onClick={submitForm}
          >
            SIGN UP
          </Button>
          {isClient() && <GoogleAuth title="Sign up" />}
        </Row>

        {termsModal}
        {policyModal}
      </Row>
    </AuthFormWrapper>
  );
}

export default SignUp;
