import React from 'react';
import Text from 'components/_main/Text';
import { useField } from 'formik';

function ErrorFormik({ name, type }: { name: string; type?: string }) {
  const [, meta] = useField({ name, type });
  return (
    <Text colorVariant="warning">
      {meta?.touched && meta?.error && (
        <Text colorVariant="warning">{meta?.error}</Text>
      )}
    </Text>
  );
}

export default ErrorFormik;
