import { ColorsMinRequired } from 'appTypes';
import colorsBase from './colors';

export interface Theme {
  colors: ColorsMinRequired & Record<keyof typeof colorsBase, string>;
  [x: string]: any;
}

const colors = colorsBase;

const theme: Theme = {
  fonts: {
    main: { family: 'Montserrat', weight: 400 }
  },
  colors: colorsBase,
  button: {
    primary: {
      normal: colors.primary,
      hover: colors.primaryHover,
      disabled: colors.grayLight
    },
    secondary: {
      normal: colors.secondary,
      hover: colors.secondaryHover,
      disabled: colors.grayLight
    },
    alternative: {
      normal: colors.alternate,
      hover: colors.alternateHover,
      disabled: colors.grayLight
    },
    outline: {
      normal: colors.gray,
      hover: colors.grayDeep,
      disabled: colors.grayLight
    },
    square: {
      font_color: colors.grayLighter,
      normal: colors.grayLightest,
      hover: colors.grayDeep,
      disabled: colors.grayLight
    }
  }
};

export default theme;
