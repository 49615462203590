import React, { ReactNode, useEffect } from 'react';
import { Container, Row, Box, Text } from 'components/_main';
import useRedirect from 'hooks/useRedirect';
import { CheckCircle } from '@phosphor-icons/react';
import { ROUTES } from 'appConstants';
import { useTheme } from 'styled-components/macro';

export interface ResultComponentProps {
  type?: ActionResultTypes;
  resultTitle: string;
  resultMessage: string | ReactNode;
  actionParams?: ActionParams;
}

export enum ActionResultTypes {
  SUCCESS = 'success'
}

export interface ActionParams {
  actionMessage: string;
  path: string;
  linkTitle: string;
}

const REDIRECT_TIMEOUT = 3000;

export const Result: React.FC<ResultComponentProps> = ({
  resultTitle,
  resultMessage
}: ResultComponentProps) => {
  const redirect = useRedirect();
  const theme = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => {
      redirect(ROUTES.signIn);
    }, REDIRECT_TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Container width="440px" minWidth="440px" flexDirection="column">
      <Box padding="24px 24px 0">
        <CheckCircle size={116} color={theme.colors.primary} weight="thin" />
      </Box>
      <Row displayFlex gridGap="24px">
        <Text variant="ui-4-bold" center>
          {resultTitle}
        </Text>
        <Row displayFlex gridGap="8px">
          <Text variant="ui-1-regular" center>
            {resultMessage}
          </Text>
          <Text variant="ui-small-regular" colorVariant="light" center>
            Redirect to sing in after 3 sec...
          </Text>
        </Row>
      </Row>
    </Container>
  );
};
