import styled, { css } from 'styled-components/macro';
import { ProgressProps } from './ProjectProgress.types';
import { Progress } from 'antd';

export const StyledNumber = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grayLight};
`;

export const ProgressStyled = styled(
  ({ className, ...restProps }: ProgressProps) => {
    return <Progress {...restProps} className={className} />;
  }
)(
  () => css`
    width: 100%;
    padding-right: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-progress-inner {
      height: 5px;
    }

    .ant-progress-outer {
      margin: 0;
      padding-right: 0 !important;
    }

    .ant-progress-bg {
      background-color: ${({ theme }) => theme.colors.light} !important;
    }

    .ant-progress-text {
      color: ${({ theme }) => theme.colors.light};
      font-weight: 700;
      font-size: 14px;
    }
  `
);
