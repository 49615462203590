import { Box } from 'components/_main';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import React from 'react';
import { Slider, ToolbarSection } from './AnnotationToolbar.ui';

export default function SectionSvgLayerOpacity() {
  const { svgLayerOpacityLevel } = useAnnotationSystemState();

  const { onSetSvgOpacityLevel } = useAnnotationSystemActions();

  return (
    <ToolbarSection
      px="1rem"
      width="150px"
      gridAutoColumns="100%"
      height="100%"
    >
      <Box width="100%">
        <Slider
          value={svgLayerOpacityLevel}
          min={0}
          max={1}
          step={0.1}
          onChange={onSetSvgOpacityLevel}
        />
      </Box>
    </ToolbarSection>
  );
}
