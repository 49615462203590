import React, { useMemo } from 'react';
import StyledTable from 'components/Table';
import { useGetClientProjectsHistoryData } from 'hooks/useClientProjectsHistoryDataProvider';
import { MappedListProjects } from 'appTypes';
import { formatListProjects } from 'utils/formatListProjects';
import { useClientCardCtx } from '../ctx';
import { ClientProjectsHistoryRoot } from './ClientProjectsHistory.ui';
import columns from './ClientProjectsHistory.columns';
import { Project } from 'generated/graphql';

function ClientProjectsHistory() {
  const [tableData, loading] = useGetClientProjectsHistoryData();
  const { setCurPrjData, isFromProjectDetailsCard } = useClientCardCtx();

  const finalTableData: (MappedListProjects | null)[] | [] = useMemo(() => {
    if (!tableData) return [];
    return formatListProjects(tableData);
  }, [tableData]);

  const finalColumns = useMemo(
    () =>
      columns({
        isFromProjectDetailsCard,
        onEdit: (data: Project) => {
          setCurPrjData(data);
        }
      }),
    [isFromProjectDetailsCard, setCurPrjData]
  );

  return (
    <ClientProjectsHistoryRoot>
      <StyledTable
        loading={loading}
        size="small"
        columns={finalColumns}
        dataSource={finalTableData as any}
      />
    </ClientProjectsHistoryRoot>
  );
}

export default ClientProjectsHistory;
