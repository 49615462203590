import React from 'react';
import { upper } from 'utils/_stringUtilsLegacy';
import projectStatusColumn from 'components/Table/_columnRender/projectStatusColumn';
import projectProgressColumn from 'components/Table/_columnRender/projectProgressColumn';
import { Project } from 'generated/graphql';
import { Text } from 'components/_main';
import { specializationIconColumn } from 'components/Table/_columnRender';
import { ProjectEntity } from 'components/Table/Table.types';
import { ColumnsClientProps } from 'containers/Projects/columns.types';
import downloadResultsColumn from 'containers/Projects/DownloadResultsColumn';
import clientActions from 'containers/Projects/ProjectsClient/clientActions';

export const columns = ({
  activeProjectId,
  onEdit,
  onCopyProject
}: ColumnsClientProps) => [
  {
    title: upper('project'),
    width: 520,
    render: (data: Project) => {
      return <Text colorVariant="normal">{data?.title}</Text>;
    }
  },
  {
    title: upper('specialization'),
    width: 220,
    render: (data: ProjectEntity) => {
      return <Text>{data?.mappedType}</Text>;
    }
  },
  {
    title: upper('type'),
    width: 80,
    render: specializationIconColumn
  },
  {
    title: upper('Status'),
    render: projectStatusColumn,
    width: 180
  },
  {
    title: upper('Progress'),
    render: projectProgressColumn({
      showDetails: false,
      inPercents: false,
      showProgressBar: true,
      showAbsNumbersBesideProgressBar: true
    }),
    width: 220
  },
  {
    width: 100,
    title: upper('helpers'),
    render: (data: Project) => {
      if (!data?.['helpersCount']) {
        return <Text>0</Text>;
      }

      return <Text>{data?.['helpersCount']}</Text>;
    }
  },
  {
    width: 200,
    title: upper('Results'),
    render: downloadResultsColumn(activeProjectId)
  },
  {
    render: clientActions({ onEdit, onCopyProject }),
    width: 50
  }
];

export default columns;
