import styled from 'styled-components/macro';
import { TableProps } from 'antd/es/table';
import { Table } from 'antd';

export const StyledTable = styled(Table)<TableProps<any>>`
  margin-top: 20px;

  &.ant-table-wrapper {
    margin-top: 0;
  }

  .ant-table-container {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighter};
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 0;
    padding: 6px 16px;
    height: 44px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${({ theme }) => theme.colors.lightest};
  }

  .ant-table-cell {
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-table {
    background: transparent;
    tbody {
      background: transparent;
    }

    table tr td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    table tr td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  table > thead > tr > th {
    font-size: 12px;
    font-weight: bold;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lighter};
    color: ${({ theme }) => theme.colors.light};
    text-transform: uppercase;

    ::before {
      display: none;
    }
  }
  table > tbody > tr .row-actions {
    width: 100%;
    justify-content: flex-end;
    opacity: 0;
    transition: all 0.3s;
  }
  table > tbody > tr:hover .row-actions {
    transition: all 0.3s;
    opacity: 1;
  }

  .ant-table table > tbody .ant-table-row.ant-table-row-level-0.archived {
    color: ${({ theme }) => theme.colors.lighter};

    span[class^='Text'] {
      color: ${({ theme }) => theme.colors.lighter};
    }
  }

  .ant-pagination.ant-table-pagination.ant-table-pagination-right {
    justify-content: center;
    padding-top: 1rem;
  }

  .ant-pagination-item {
    border-radius: 100px;
    border: 0;
    a {
      color: ${({ theme }) => theme.colors.fg} !important;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.lighter};
    }
  }

  .ant-pagination-item-link {
    border-radius: 100px;
    border: 0;

    svg {
      color: ${({ theme }) => theme.colors.fg} !important;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.lighter};
    }
  }

  .ant-pagination-item.ant-pagination-item-active {
    background-color: ${({ theme }) => theme.colors.fg};
    a {
      color: ${({ theme }) => theme.colors.bg} !important;
    }
  }
`;

export default StyledTable;
