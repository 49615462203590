import React, { PropsWithChildren } from 'react';
import { consoleError } from 'utils';
import { GoogleOAuthProvider } from '@react-oauth/google';
import envVars from 'utils/envVars';

function GoogleAuthProvider({ children }: PropsWithChildren) {
  if (!envVars.googleOAuth2ClientId) {
    consoleError('No env var GOOGLE_OAUTH_CLIENT_ID');
    return null;
  }

  return (
    <GoogleOAuthProvider clientId={envVars.googleOAuth2ClientId}>
      {children}
    </GoogleOAuthProvider>
  );
}

export default GoogleAuthProvider;
