import React from 'react';
import styled, { css } from 'styled-components/macro';
import useIndexColor from '../hooks/useIndexColor';
import useStatus from '../hooks/useStatus';

interface Coords {
  x: number;
  y: number;
}

interface Props {
  className?: string;
  from: Coords;
  to: Coords;
  label: string;
  dataTestId?: string;
  dataIdFrom?: string;
  dataIdTo?: string;
  groupIndex?: number;
}

interface LineProps {
  color: string;
  isDashed?: boolean;
}

const Line = styled.line(
  ({ color, isDashed }: LineProps) => css`
    stroke: ${color};
    stroke-width: 2px;
    pointer-events: none;

    ${isDashed &&
    css`
      stroke-dasharray: 5 5;
    `}
  `
);

function DrawLineBetweenDots({
  dataTestId,
  className,
  dataIdFrom,
  dataIdTo,
  from,
  to,
  groupIndex
}: React.PropsWithChildren<Props>) {
  const indexColor = useIndexColor(groupIndex);

  const { isHidden, isDotSel, isActive } = useStatus({
    type: 'dotConnectionLine',
    groupIndex
  });

  if (isHidden) return null;

  return (
    <Line
      isDashed={isDotSel}
      color={indexColor}
      className={className}
      data-id-from={dataIdFrom}
      data-id-to={dataIdTo}
      data-testid={dataTestId}
      x1={from.x}
      x2={to.x}
      y1={from.y}
      y2={to.y}
      data-group-index={groupIndex}
    />
  );
}

export default DrawLineBetweenDots;
