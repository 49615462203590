import { useGetProjectByIdQuery } from 'generated/graphql';

const useGetProjectById = ({ projectId }: { projectId?: number | string }) => {
  const { loading, data, error } = useGetProjectByIdQuery({
    fetchPolicy: 'network-only',
    variables: {
      projectId: projectId
        ? typeof projectId === 'number'
          ? projectId
          : parseInt(projectId, 10)
        : 0
    },
    skip: !projectId
  });

  return {
    data: data?.project,
    error,
    loading
  };
};

export default useGetProjectById;
