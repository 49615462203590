import { consoleLogDebounced } from 'utils';
import { MAX_ZOOM, MIN_ZOOM } from '../AnnotationSystem.constants';
import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum,
  AnnotationSystemProps
} from '../AnnotationSystem.types';
import { REDUCER_INIT } from './constants';
import deleteTransitionPointReducerCase from './reducerCases/deleteTransitionPointReducerCase';
import modifyAnnotationUnitReducerCase from './reducerCases/modifyAnnotationUnitReducerCase';
import modifyTransitionPointReducerCase from './reducerCases/modifyTransitionPointReducerCase';
import setMediaLoadingFinishReducerCase from './reducerCases/setMediaLoadingFinishReducerCase';
import { ReducerActions, ReducerActionTypes } from './types';
import { getCurSelUnitId, getUnit, removeUnfinishedPolygons } from './utils';
import createPolygonAnnotationUnit from 'containers/AnnotationSystem/reducer/reducerCases/createPolygonAnnotationUnit';
import { AnnotationSpecificationType } from 'appTypes';

/**
 *   TODO: Maybe create sub reducers, were logic for each annotation type will be handled, so there will be on common, and sub reducers
 *        finalState = annotTypeSpecificReducer(reducer(state))
 *        To separate logic, make things more clean and easier to handle
 *        Also move all annot logic from all other place to such specialized reducers. From sidebar, top toolbars, units toolbars,
 *        labels dropdown, statusbar, svg layers, annotation components and so on
 */
function reducer(
  state = REDUCER_INIT,
  action: ReducerActionTypes
): AnnotationSystemProps {
  consoleLogDebounced({
    action,
    prevState: state,
    type: action.type.toString()
  });

  const isPolygons = state.annotationSpecificationType === 'polygon';
  const selectedUnit = getCurSelUnitId(state);

  switch (action.type) {
    case ReducerActions.SET_H:
      return {
        ...state,
        h: action.payload
      };
    case ReducerActions.SET_W:
      return {
        ...state,
        w: action.payload
      };
    case ReducerActions.SET_X:
      return {
        ...state,
        x: action.payload
      };
    case ReducerActions.SET_Y:
      return {
        ...state,
        y: action.payload
      };
    case ReducerActions.ADD_ANNOTATION_UNIT: {
      return {
        ...state,
        annotationUnits: [action.payload, ...state.annotationUnits]
      };
    }
    case ReducerActions.MODIFY_ANNOTATION_UNIT: {
      return modifyAnnotationUnitReducerCase(state, action.payload);
    }
    case ReducerActions.DELETE_ANNOTATION_UNIT: {
      return {
        ...state,
        curEvent: null,
        annotationUnits: [
          ...state.annotationUnits.filter(
            ({ unitId }) => unitId !== action.payload
          )
        ]
      };
    }
    case ReducerActions.INIT_ANNOTATION_UNITS: {
      return {
        ...state,
        annotationUnits: action.payload
      };
    }
    case ReducerActions.SET_ZOOM_LEVEL:
      return {
        ...state,
        curZoomLevel: isFinite(action.payload) ? action.payload : 1
      };
    case ReducerActions.ZOOM_IN: {
      let newZoomLevel = state.curZoomLevel + state.zoomStep;

      if (newZoomLevel > MAX_ZOOM) {
        newZoomLevel = MAX_ZOOM;
      }

      return {
        ...state,
        curZoomLevel: newZoomLevel
      };
    }
    case ReducerActions.ZOOM_OUT: {
      let newZoomLevel = state.curZoomLevel - state.zoomStep;

      if (newZoomLevel < MIN_ZOOM) {
        newZoomLevel = MIN_ZOOM;
      }

      return {
        ...state,
        curZoomLevel: newZoomLevel
      };
    }
    case ReducerActions.ZOOM_RESET: {
      const h = document
        .getElementById('AnnotationViewPort')
        ?.getBoundingClientRect()?.height;
      let zoomLevel = state.curZoomLevel;
      if (h) {
        zoomLevel = ((h * 100) / state.initH || 1) / 100;
      }

      return {
        ...state,
        curZoomLevel: zoomLevel
      };
    }
    case ReducerActions.SET_CUR_EVENT: {
      let newAnnotationUnits = [...state.annotationUnits];
      let resetProps: Partial<AnnotationSystemProps> = {
        curEvent: action.payload,
        curMode: AnnotationSystemModesEnum.UNIT_SELECT,
        curSelPointId: null,
        annotationUnits: newAnnotationUnits,
        curSelUnitId: null
      };

      if (isPolygons) {
        /** TODO: Move such type-specific logic to sep place, also check all other places where logic is and also separate it.
         *        Like in sidebar, toolbars, unit toolbar, statusbar, SvgLayer, components (annot. units) and so on
         */
        newAnnotationUnits = removeUnfinishedPolygons(state.annotationUnits);
        resetProps.curMode = AnnotationSystemModesEnum.UNIT_SELECT;
      }

      switch (action.payload) {
        case null: {
          if (state.curEvent !== AnnotationSystemEventsEnum.CREATE_MODE) {
            /** Check if prev state was not CREATE, so if it was CREATE - just go to NULL */
            if (isPolygons) {
              /** Spec case for polygons - Create dummy polygon for Creation mode */
              resetProps = {
                ...resetProps,
                ...createPolygonAnnotationUnit(state.annotationUnits)
              };
            }

            return {
              ...state,
              ...resetProps,
              curEvent: AnnotationSystemEventsEnum.CREATE_MODE
            };
          }
          return {
            ...state,
            ...resetProps
          };
        }
        case AnnotationSystemEventsEnum.CREATE_MODE: {
          if (isPolygons) {
            return {
              ...state,
              ...resetProps,
              ...createPolygonAnnotationUnit(state.annotationUnits)
            };
          }
          return {
            ...state,
            ...resetProps
          };
        }
        /**
         * TODO:
         * - Create smth like "POINT SEL MODE" for poly points, that will not erase curSelPointId & would not change cur mode to UNIT SELECT
         * - General SELECTED - should clean cur sel point and mode
         */
        case AnnotationSystemEventsEnum.SELECTED_MODE: {
          const defActionForSelectedMode = {
            /** Close all label dropdowns */
            annotationUnits: state.annotationUnits.map((unit) => ({
              ...unit,
              isLabelDropdownOpen: false
            }))
          };

          /** If prev event was DRAG, not erase cur point and cur mode */
          if (
            isPolygons &&
            state.curEvent === AnnotationSystemEventsEnum.DRAG_MODE
          ) {
            return {
              ...state,
              curEvent: action.payload,
              ...defActionForSelectedMode
            };
          }

          if (isPolygons) {
            return {
              ...state,
              curEvent: action.payload,
              curMode: AnnotationSystemModesEnum.UNIT_SELECT,
              curSelPointId: null,
              ...defActionForSelectedMode
            };
          }

          return {
            ...state,
            curEvent: action.payload,
            ...defActionForSelectedMode
          };
        }
        default: {
          return {
            ...state,
            curEvent: action.payload
          };
        }
      }
    }
    case ReducerActions.SET_INIT_SIZE: {
      return {
        ...state,
        initW: action.payload.w,
        initH: action.payload.h,
        w: action.payload.w,
        h: action.payload.h
      };
    }
    case ReducerActions.SET_CUR_SEL_UNIT_ID: {
      if (
        state.curEvent === AnnotationSystemEventsEnum.ZOOM_NAVIGATION ||
        !state.svgLayerRef
      ) {
        return state;
      }

      const selectedUnitId = action.payload;

      const unit = getUnit(state, selectedUnitId);

      let newState: Partial<AnnotationSystemProps> = {
        curSelUnitId: action.payload,
        curEvent:
          action.payload === null
            ? AnnotationSystemEventsEnum.CREATE_MODE
            : AnnotationSystemEventsEnum.SELECTED_MODE
      };

      /**
       * TODO: The idea is to ignore SELECT UNIT action and not go to SELECTED mode
       *       if we draw new polygon. So ideally we should get rid of SET_CUR_SEL_UNIT_ID
       *       event and create just more general like SELECT_UNIT or smth, because
       *       such kind of selection happens quite often and we should repeat it every time,
       *       DIY and grouping should be done. This action still almost every time goes with
       *       some other action, like SELECT SMTH, or similar, so no need to set this param
       *       separately
       * TODO: Also, creation flow for Polygons should be modified. There should not be
       *       active "curSelUnitId" prop activated, maybe store all such info in some temp
       *       prop like "newPolygon", or "creationDetail" or smth. So data then would be
       *       copied to "annotationUnits" prop if creation succeeded, or "creationDetails"
       *       would be erased otherwise
       */
      if (isPolygons && unit?.polygonPoints?.length! === 1) {
        newState.curEvent = AnnotationSystemEventsEnum.CREATE_MODE;
      }

      if (isPolygons && unit?.polygonPoints?.length! !== 1) {
        newState.curEvent = AnnotationSystemEventsEnum.SELECTED_MODE;
      }

      return {
        ...state,
        ...newState
      };
    }
    case ReducerActions.SET_HIDE_ANNOTATION_UNIT_ID:
      return {
        ...state,
        hideAnnotationUnitIds: action.payload
      };
    case ReducerActions.SET_SVG_LAYER_REF:
      return {
        ...state,
        svgLayerRef: action.payload
      };
    case ReducerActions.SET_MEDIA_IMAGE_REF:
      return {
        ...state,
        mediaImageRef: action.payload,
        isInitMediaLoading: false
      };
    case ReducerActions.SET_MEDIA_VIDEO_REF:
      return {
        ...state,
        mediaVideoRef: action.payload,
        isInitMediaLoading: false
      };
    case ReducerActions.SET_MEDIA_LOADING_FINISH: {
      return setMediaLoadingFinishReducerCase(state, action.payload);
    }
    case ReducerActions.SET_CUR_VIDEO_STATUS: {
      return {
        ...state,
        curVideoStatus: action.payload
      };
    }
    case ReducerActions.SET_CURSOR_INIT_POS: {
      return {
        ...state,
        cursorInitX: action.payload.x,
        cursorInitY: action.payload.y
      };
    }
    case ReducerActions.SET_SVG_LAYER_OPACITY_LEVEL: {
      return {
        ...state,
        svgLayerOpacityLevel: action.payload
      };
    }
    case ReducerActions.SET_EVENT_RESIZE: {
      return {
        ...state,
        curEvent: AnnotationSystemEventsEnum.RESIZE_MODE,
        resizePosition: action.payload
      };
    }
    case ReducerActions.SET_CUR_MODE: {
      let newCurSelUnitId = state.curSelUnitId;

      const curUnit = state.annotationUnits.find(
        (unit) => unit.unitId === newCurSelUnitId
      );

      let newCurEvent = null;

      if (newCurSelUnitId) {
        newCurEvent = AnnotationSystemEventsEnum.SELECTED_MODE;
      }

      if (
        action.payload === AnnotationSystemModesEnum.UNIT_SELECT &&
        curUnit &&
        curUnit.type === 'dot'
      ) {
        newCurSelUnitId = `wrapper-${curUnit.groupIndex}`;
      }

      return {
        ...state,
        curEvent: newCurEvent,
        curSelUnitId: newCurSelUnitId,
        curMode: action.payload
      };
    }
    case ReducerActions.DELETE_TRANSITION_POINT: {
      return deleteTransitionPointReducerCase(state, action.payload);
    }
    case ReducerActions.MODIFY_TRANSITION_POINT: {
      return modifyTransitionPointReducerCase(state, action.payload);
    }
    case ReducerActions.DELETE_POLYGON_POINT: {
      const indexToDelete = action.payload;
      if (!selectedUnit) return state;
      return modifyAnnotationUnitReducerCase(state, {
        unitId: selectedUnit.unitId,
        polygonPoints: selectedUnit.polygonPoints?.filter(
          (unit, index) => index !== indexToDelete
        )
      });
    }
    case ReducerActions.SET_POLYGON_POINTS: {
      const { newPolyPoints, unitId } = action.payload;
      return modifyAnnotationUnitReducerCase(state, {
        unitId,
        polygonPoints: newPolyPoints
      });
    }
    case ReducerActions.TOGGLE_LABEL_SELECT_DROPDOWN: {
      if (!selectedUnit) return state;
      /** TODO: Kill cur unfinished polygon. Move this functional in one place. Its also used in saveResults handler, so should be merged */
      if (isPolygons) {
        /** TODO: Move to utils func for return just modified annotationUnits array */
        let newUnits = modifyAnnotationUnitReducerCase(state, {
          unitId: selectedUnit.unitId,
          isLabelDropdownOpen: !selectedUnit.isLabelDropdownOpen
        })['annotationUnits'];

        return {
          ...state,
          annotationUnits: removeUnfinishedPolygons(newUnits)
        };
      }

      return modifyAnnotationUnitReducerCase(state, {
        unitId: selectedUnit.unitId,
        isLabelDropdownOpen: !selectedUnit.isLabelDropdownOpen
      });
    }
    case ReducerActions.SET_IS_LOADING: {
      return {
        ...state,
        isInitMediaLoading: action.payload
      };
    }
    case ReducerActions.SET_ANNOTATION_ACCURACY: {
      return {
        ...state,
        annotationAccuracy: action.payload,
        annotationUnits: []
      };
    }
    case ReducerActions.SET_ANNOTATION_PRACTICE_PROGRESS: {
      return {
        ...state,
        annPracticeProgress: action.payload,
        annotationUnits: []
      };
    }
    case ReducerActions.SET_PAGE_FRAMES: {
      return { ...state, pageFrames: action.payload };
    }
    case ReducerActions.SET_COPY_UNIT_ID: {
      return { ...state, copyUnitId: state.curSelUnitId };
    }
    case ReducerActions.SET_CUR_SEL_POINT_ID: {
      return { ...state, curSelPointId: action.payload };
    }
    case ReducerActions.RESET: {
      return {
        ...REDUCER_INIT
      };
    }
    case ReducerActions.INIT: {
      if (state.mediaVideoRef && action.payload.videoStart) {
        state.mediaVideoRef.currentTime = action.payload.videoStart;
      }

      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export default function reducerWrapper(
  state = REDUCER_INIT,
  action: ReducerActionTypes
) {
  const newState = reducer(state, action);

  consoleLogDebounced({
    newState
  });

  return newState;
}
