import React, { useState } from 'react';
import { ImageAnnotationDatasetProcessingType, ProjectType } from 'appTypes';
import { useAddProjectWizardContext } from 'containers/AddProjectWizard/AddProjectWizard.context';
import StepUploadDatasetMultiple from './StepUploadDatasetMultiple';
import StepUploadDatasetSingle from './StepUploadDatasetSingle';
import ProjectSpecializationTypeSubTypeSelect from '../components/ProjectSpecializationTypeSubTypeSelect';
import { Col, Row, Text } from 'components/_main';
import { Radio, RadioGroup } from 'components/Radio';
import { FormikError } from 'components/_form';
import { UploadCloudServiceIcon, UploadFileItem } from '../../../UploadFiles';
import { AzureIcon, AwsIcon, DropboxIcon } from 'assets';
import { GoogleDriveFile } from 'containers/AddProjectWizard/AddProjectWizard.types';
import { ADD_WIZARD_FIELDS } from 'appConstants';
import { convertToFileStates } from 'containers/UploadFiles';
import StepUploadDatasetRestriction from './StepUploadDatasetRestriction';
import StepUploadDatasetVideoSingleDataProvider from 'containers/AddProjectWizard/steps/StepUploadDataset/StepUploadDatasetVideoSingle';
import GoogleDrive from './GoogleDrive';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import CautionPlateResendActivationLink from 'containers/CautionPlateResendActivationLink';
import { ALLOWED_FILE_FORMAT_FOR_NLP } from 'containers/AddProjectWizard/AddProjectWizard.constants';

interface StepUploadDatasetManuallyOrCloudTypeSelectProps {
  uploadType?: 'manually' | 'cloudService';
  isMultipleType?: boolean;
  manuallyBlockRender: React.ReactNode;
  cloudBlockRender: React.ReactNode;
}

function WithSettings({ children }: React.PropsWithChildren<{}>) {
  return (
    <Row height="100%" gridGap="40px">
      <Row gridGap="20px">
        <Text upper variant="ui-small-2-bold">
          Settings
        </Text>
        <ProjectSpecializationTypeSubTypeSelect />
      </Row>
      {children}
    </Row>
  );
}

function StepUploadDataset() {
  const { type, imageAnnotationSpecSubType, videoAnnotationSpecSubType } =
    useAddProjectWizardContext();
  const userSessionData = useGetUserSessionData();

  /** Not activated user */
  if (!userSessionData.activated) {
    return (
      <Row gridGap="40px">
        <CautionPlateResendActivationLink contentText="Please check your email and activate your account to proceed with the project." />
      </Row>
    );
  }

  /** ZIP archive */
  if (
    type === ProjectType.IMAGE_ANNOTATION &&
    imageAnnotationSpecSubType ===
      ImageAnnotationDatasetProcessingType.SINGLE_ZIP_ARCHIVE
  ) {
    return (
      <Row gridGap="40px">
        <StepUploadDatasetRestriction />
        <WithSettings>
          <StepUploadDatasetSingle />
        </WithSettings>
      </Row>
    );
  }

  /** Single video files */
  if (type === ProjectType.VIDEO_ANNOTATION) {
    return (
      <Row gridGap="40px">
        <StepUploadDatasetRestriction />
        <WithSettings key={videoAnnotationSpecSubType}>
          <Row gridGap="20px">
            <Text variant="ui-small-2-bold" upper>
              Upload files
            </Text>
            <StepUploadDatasetManuallyOrCloudTypeSelect
              cloudBlockRender={<StepUploadDatasetCloud />}
              manuallyBlockRender={
                <StepUploadDatasetVideoSingleDataProvider
                  uploadFilesBlock={null}
                />
              }
            />
          </Row>
        </WithSettings>
      </Row>
    );
  }

  /** Multiple files --- Image Annotations */
  if (
    type === ProjectType.IMAGE_ANNOTATION &&
    imageAnnotationSpecSubType ===
      ImageAnnotationDatasetProcessingType.MULTIPLE_IMAGES
  ) {
    return (
      <Row gridGap="40px">
        <StepUploadDatasetRestriction />
        <WithSettings>
          <Row>
            <Text variant="ui-small-2-bold" upper>
              Upload files
            </Text>
            <Row minHeight="200px" height="max-content">
              <StepUploadDatasetManuallyOrCloudTypeSelect
                isMultipleType={true}
                cloudBlockRender={<StepUploadDatasetCloud />}
                manuallyBlockRender={
                  <StepUploadDatasetMultiple uploadFilesBlock={null} />
                }
              />
            </Row>
          </Row>
        </WithSettings>
      </Row>
    );
  }

  if (type === ProjectType.NLP) {
    return (
      <Row gridGap="40px">
        <StepUploadDatasetRestriction />
        <WithSettings>
          <StepUploadDatasetSingle accept={ALLOWED_FILE_FORMAT_FOR_NLP} />
        </WithSettings>
      </Row>
    );
  }

  return (
    <Row gridGap="40px">
      <StepUploadDatasetRestriction />
      <StepUploadDatasetSingle />
    </Row>
  );
}

function StepUploadDatasetCloud() {
  const { onSetAnnotationMultipleFiles, annotationMultipleFiles } =
    useAddProjectWizardContext();

  const uploaderFiles = convertToFileStates(annotationMultipleFiles);

  return (
    <Row>
      <Col>
        <GoogleDrive />
        <UploadCloudServiceIcon disabled>
          <AwsIcon />
        </UploadCloudServiceIcon>
        <UploadCloudServiceIcon disabled>
          <AzureIcon />
        </UploadCloudServiceIcon>
        <UploadCloudServiceIcon disabled>
          <DropboxIcon />
        </UploadCloudServiceIcon>
      </Col>
      {annotationMultipleFiles && annotationMultipleFiles?.length > 0 && (
        <Row>
          <Text variant="ui-small-2-bold" upper>
            Upload files
          </Text>
          {uploaderFiles.map((file, index) => (
            <UploadFileItem
              key={file.fileName}
              index={index}
              state={file.state}
              fileName={file.fileName}
              onDelete={(fileName) =>
                onSetAnnotationMultipleFiles(
                  (annotationMultipleFiles as GoogleDriveFile[])?.filter(
                    (file: GoogleDriveFile) => file.name !== fileName
                  )
                )
              }
            />
          ))}
        </Row>
      )}
      <FormikError name={ADD_WIZARD_FIELDS.media} />
    </Row>
  );
}

function mapAnnotationFileUploadTypeToUiForm(uploadType: string) {
  return uploadType === 'googleDrive' ? 'cloudService' : 'manually';
}

function StepUploadDatasetManuallyOrCloudTypeSelect({
  isMultipleType,
  uploadType: uploadTypeProp,
  cloudBlockRender,
  manuallyBlockRender
}: StepUploadDatasetManuallyOrCloudTypeSelectProps) {
  const {
    annotationFilesUploadType,
    onSetAnnotationMultipleFiles,
    onSetAnnotationFilesUploadType
  } = useAddProjectWizardContext();

  const [uploadType, setUploadType] = useState<'manually' | 'cloudService'>(
    mapAnnotationFileUploadTypeToUiForm(
      uploadTypeProp ?? annotationFilesUploadType
    )
  );

  const handleUploadType: React.EventHandler<any> = (e) => {
    const newValue = e?.target?.value;

    switch (newValue) {
      case 'cloudService':
        onSetAnnotationFilesUploadType('googleDrive');
        break;
      case 'manually':
      default:
        onSetAnnotationFilesUploadType(isMultipleType ? 'multiple' : 'single');
        break;
    }

    onSetAnnotationMultipleFiles([]);
    setUploadType(newValue);
  };

  return (
    <Row height="100%" gridGap="30px">
      <Row>
        <RadioGroup value={uploadType} onChange={handleUploadType}>
          <Col>
            <Radio value={'manually'}>Upload manually</Radio>
            <Radio disabled value={'cloudService'}>
              Use cloud service
            </Radio>
          </Col>
        </RadioGroup>
      </Row>
      <Row>
        {uploadType === 'cloudService' ? (
          <>{cloudBlockRender}</>
        ) : (
          <>{manuallyBlockRender}</>
        )}
      </Row>
    </Row>
  );
}

export default StepUploadDataset;
