import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { PolygonPoints } from 'containers/AnnotationSystem/reducer';

export { default as addAtStrokePolygonPoint } from './addAtStrokePolygonPoint';
export { default as addPolygonPoint } from './addPolygonPoint';
export { default as deletePolygonPoint } from './deletePolygonPoint';
export { default as modifyPolygonPoint } from './modifyPolygonPoint';

export const savePolygonZoom = (
  polygon: AnnotationUnitProps,
  zoomLevel: number
) => {
  return {
    ...polygon,
    polygonPoints: savePolyPointsZoom(polygon.polygonPoints ?? [], zoomLevel)
  };
};

export const loadPolygonZoom = (
  polygon: AnnotationUnitProps,
  zoomLevel: number
) => {
  return {
    ...polygon,
    polygonPoints: loadPolyPointsZoom(polygon.polygonPoints ?? [], zoomLevel)
  };
};

export const savePolyPointsZoom = (
  polyPoints: PolygonPoints,
  zoomLevel: number = 1
): PolygonPoints => {
  return polyPoints.map((point) => savePolyPointZoom(point, zoomLevel));
};

export const loadPolyPointsZoom = (
  polyPoints: PolygonPoints,
  zoomLevel: number = 1
): PolygonPoints => {
  return polyPoints.map((point) => loadPolyPointZoom(point, zoomLevel));
};

export function loadPolyPointZoom(
  polyPoint: [number, number],
  zoomLevel: number = 1
): [number, number] {
  const [x, y] = polyPoint;
  return [x * zoomLevel, y * zoomLevel];
}

export function savePolyPointZoom(
  polyPoint: [number, number],
  zoomLevel: number = 1
): [number, number] {
  const [x, y] = polyPoint;
  return [x / zoomLevel, y / zoomLevel];
}
