import React from 'react';
import { TableProps } from 'antd/es/table';
import { StyledTable } from './Table.styled';
import { TABLE_ITEMS_PER_PAGE } from 'appConstants';

type TTable = {
  columns: any;
  page?: number;
  pagesCount?: number;
  setCurTablePage?: (page: number) => void;
};

const Table: React.FC<TableProps<any> & TTable> = ({
  className,
  setCurTablePage,
  page,
  pagesCount,
  columns,
  ...rest
}) => {
  const pagination = setCurTablePage
    ? {
        pageSize: TABLE_ITEMS_PER_PAGE,
        onChange: (page: number) => {
          setCurTablePage(page - 1);
        },
        hideOnSinglePage: true,
        total: TABLE_ITEMS_PER_PAGE * (pagesCount ?? 1),
        ...{
          ...(page ? { current: page + 1 } : {})
        }
      }
    : false;

  return (
    <StyledTable
      rowKey={'id'}
      columns={columns as any}
      className={className}
      pagination={pagination}
      {...rest}
    />
  );
};

export default Table;
