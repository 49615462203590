import { UploadFilesContextValues } from './UploadFiles.types';

export const MAX_FILES = 100;

export const CONTEXT_VALUES: UploadFilesContextValues = {
  files: [],
  onDelete: () => null,
  onCancel: () => null,
  onError: () => null,
  onFinishUploading: () => null,
  onGetFile: () => null,
  onStartUploading: () => true
};
