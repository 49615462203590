import { TMapTaskData } from 'containers/TasksRouter/Tasks.types';
import { TASK_DATA_INIT, PROJECT_INIT, CLIENT_INIT } from 'appConstants';

const mapTaskData: TMapTaskData = (taskData) => {
  const {
    id: taskId,
    projectId,
    updatedAt,
    result,
    createdAt,
    type,
    project,
    history,
    status,
    mediaUrl,
    videoStart,
    videoEnd,
    labels,
    helperTimeSpent,
    returnedFromAuditor,
    timesReturnedFromAuditor,
    isAvailableForHelper
  } = { ...TASK_DATA_INIT, ...taskData } ?? {};
  const { id, title, mlLabels, client, customLabels } = project ?? PROJECT_INIT;
  const { firstName, lastName } = client ?? CLIENT_INIT;
  const mediaFileName = project?.mediaFileName;

  return {
    result: result?.data ?? result ?? [],
    videoStart,
    videoEnd,
    videoFPS: taskData.videoFPS,
    videoFrameRate: taskData.videoFrameCount,
    taskId,
    mlLabels,
    status,
    projectId,
    mediaUrl: mediaUrl || '',
    mediaFileName,
    updatedAt,
    createdAt,
    helperTimeSpent,
    type,
    taskType: type,
    project,
    customLabels,
    id,
    title,
    labels: project?.labels ?? labels ?? [],
    client,
    firstName,
    lastName,
    returnedFromAuditor: returnedFromAuditor ?? false,
    timesReturnedFromAuditor: timesReturnedFromAuditor ?? 0,
    history:
      history?.sort(
        (a, b) =>
          new Date(b.actionWasTakenAt).getTime() -
          new Date(a.actionWasTakenAt).getTime()
      ) ?? null,
    isAvailableForHelper: !!isAvailableForHelper
  };
};

export default mapTaskData;
