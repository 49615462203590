import styled from 'styled-components/macro';

export const Body = styled.div`
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #373838;
  }
  p,
  ul li,
  ol li {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #373838;
  }
  p,
  h3 {
    margin-bottom: 1rem;
  }
  ul,
  ol {
    margin-bottom: 1.5rem;
  }
`;
