import React from 'react';
import HeaderAppBar from 'containers/HeaderAppBar';
import { Box, Col } from 'components/_main';
import { Breadcrumb } from 'antd';
import { ReactComponent as ArrowRightIcon } from './arrowRight.svg';
import { TaskingType } from 'appTypes';

interface AnnotationHeaderProps {
  title: string;
  taskingType?: TaskingType;
  firstName?: string;
  lastName?: string;
}

function AnnotationHeader({
  title,
  taskingType,
  firstName,
  lastName
}: AnnotationHeaderProps) {
  return (
    <Box data-component="app-bar" gridColumn="1 / -1" gridRow="1 / 2">
      <HeaderAppBar
        title={title}
        firstName={firstName}
        lastName={lastName}
        sidePadding="2rem"
        renderNavigation={
          <Col flexCenter>
            <Breadcrumb separator={<ArrowRightIcon />}>
              {taskingType === 'practice' ? (
                <Breadcrumb.Item href="/learning">Lessons</Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
              )}
              <Breadcrumb.Item>{title}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        }
      />
    </Box>
  );
}

export default AnnotationHeader;
