import { useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useNavigate();

  return useMemo(() => {
    return {
      push: history,
      replace: history,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params
      },
      location,
      history
    };
  }, [params, location, history]);
}

export default useRouter;
