import React from 'react';
import { Box, Text, Row } from '../../../components/_main';
import SpinnerLoader from '../../../components/SpinnerLoader';
import { useAddProjectWizardContext } from '../AddProjectWizard.context';
import { StepFinalSuccessIcon } from '../assets';

export interface StepFinalProps {
  isLoading?: boolean;
  error?: string;
}

export function StepFinal({ error, isLoading = true }: StepFinalProps) {
  if (isLoading) {
    return (
      <Box
        height="100%"
        displayFlex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap="46px"
      >
        <Box grid flexCenter>
          <Box width="128px" height="128px" p="10px">
            <SpinnerLoader />
          </Box>
        </Box>
        <Row width="336px" gridGap="20px">
          <Text center variant="ui-4-bold">
            Uploading...
          </Text>
          <Text center variant="ui-3-regular">
            We upload your assets. This might take a while. Stay tuned :)
          </Text>
        </Row>
      </Box>
    );
  }

  if (!isLoading && !error) {
    return (
      <Box
        height="100%"
        displayFlex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap="46px"
      >
        <Box grid flexCenter>
          <Box width="128px" height="128px">
            <StepFinalSuccessIcon />
          </Box>
        </Box>
        <Row width="380px" gridGap="20px">
          <Text center variant="ui-4-bold">
            Done!
          </Text>
          <Text center variant="ui-3-regular" lineHeight="28px">
            Your project has been submitted for review. Our admins will check it
            and put into work as soon as possible.
          </Text>
        </Row>
      </Box>
    );
  }
  return null;
}

function StepFinalDataProvider() {
  const { isLoading } = useAddProjectWizardContext();

  return <StepFinal isLoading={isLoading} />;
}

export default StepFinalDataProvider;
