import React, { forwardRef, PropsWithChildren } from 'react';
import { ButtonProps } from './types';
import { useTheme } from 'styled-components/macro';
import {
  Root,
  TextContent,
  IconContent,
  IconSuffixContent,
  IconPrefixContent
} from './styled';
import Box from '../Box';
import SpinnerLoader from 'components/SpinnerLoader';

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      children,
      isLoading,
      onClick,
      type,
      htmlType,
      iconPrefix,
      iconSuffix,
      disabled,
      iconStyle,
      ...restProps
    },
    ref
  ) => {
    const theme = useTheme();

    const loadingRender = isLoading && (
      <Box
        position="relative"
        displayFlex
        flexCenter
        size={restProps.mini ? '12px' : '16px'}
        mr=".5rem"
      >
        <SpinnerLoader color={theme.colors.bg} />
      </Box>
    );

    const Content = iconStyle ? IconContent : TextContent;

    const props = {
      ...((isLoading || disabled) && {
        onClick: undefined
      }),
      disabled: isLoading || disabled,
      type: (type ??
        htmlType ??
        'button') as React.ButtonHTMLAttributes<HTMLButtonElement>['type']
    };

    return (
      <Root
        iconStyle={iconStyle}
        ref={ref}
        onClick={onClick}
        {...props}
        {...restProps}
      >
        {loadingRender}
        {iconPrefix && <IconPrefixContent>{iconPrefix}</IconPrefixContent>}
        <Content>{children}</Content>
        {iconSuffix && <IconSuffixContent>{iconSuffix}</IconSuffixContent>}
      </Root>
    );
  }
);

export default Button;
