import React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { TUseWidth, useWidth } from 'utils/_styleUtilsLegacy';
import { InputProps as InputBaseProps, Input as AntdInput } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import { EyeSlash, Eye } from '@phosphor-icons/react';

interface InputCustomProps {
  small?: boolean;
  restProps?: string;
  password?: boolean;
  textarea?: boolean;
}

export type InputProps = InputCustomProps &
  InputBaseProps &
  TextAreaProps &
  TUseWidth;

const Input = styled(
  ({ small, password, textarea, ...restProps }: InputProps) => {
    const theme = useTheme();
    let InputComponent:
      | typeof AntdInput
      | typeof AntdInput['Password']
      | typeof AntdInput['TextArea'] = AntdInput;

    if (password) {
      InputComponent = AntdInput.Password;
    }

    if (textarea) {
      InputComponent = AntdInput.TextArea;
    }

    return (
      <InputComponent
        {...(password && {
          type: 'password',
          iconRender: (visible: boolean) =>
            visible ? (
              <EyeSlash size={22} color={theme.colors.light} />
            ) : (
              <Eye size={22} color={theme.colors.light} />
            )
        })}
        {...restProps}
      />
    );
  }
)<InputProps>(
  ({ textarea, small, disabled, theme }) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 5px;
    border: solid 1px ${({ theme }) => theme.colors.lighter};
    padding: 12px 20px;
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
    }
    ::placeholder,
    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${({ theme }) => theme.colors.light};
      opacity: 1; /* Firefox */
      font-weight: 300;
    }

    &.ant-input-affix-wrapper-disabled {
      background-color: transparent;
    }

    &.ant-input[disabled] {
      background-color: transparent;
    }

    ${small &&
    css`
      height: 30px;
      padding: 5px 8px 4px 15px;
    `}
    ${useWidth};
    ${!textarea &&
    css`
      height: 50px;
    `};
    ${textarea &&
    css`
      padding: 12px 20px;
    `};

    ${disabled &&
    css`
      background-color: ${theme.colors.lightest} !important;
    `}
  `
);

export default Input;
