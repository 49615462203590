import { useField, useFormikContext } from 'formik';
import { ButtonInfo } from 'components/_main';
import ProjectStatus from 'components/ProjectStatus';
import { ProjectStatus as ProjectStatusType } from 'generated/graphql';
import React from 'react';
import {
  getErrorFieldsArrayFromContext,
  showFillRequiredFieldsToastForFromReviewToPublished,
  isReadyToChangeStatusFromUnreviewedToPublished
} from '../utils';
import { useCtx } from '../ProjectDetailsCard.Context';
import { useTheme } from 'styled-components/macro';
import { ProjectType } from 'appTypes';

const ProjectStatusRenderFormikWrapper = () => {
  const { values, isValid, errors } = useFormikContext<any>();
  const { isMediaUploading } = useCtx();
  const [field, , helpers] = useField('status');
  const [prevState] = React.useState<string>(field.value);

  const { setValue } = helpers;
  const errorFields = getErrorFieldsArrayFromContext(errors);

  const theme = useTheme();

  if (
    prevState === ProjectStatusType.Active &&
    values?.type === ProjectType.NLP
  )
    return (
      <ProjectStatus
        defaultValue={ProjectStatusType.Active}
        value={field.value as ProjectStatusType}
        options={[
          { value: ProjectStatusType.Active, title: 'Active' },
          { value: ProjectStatusType.Completed, title: 'Done' }
        ]}
        onSelect={(value) => {
          setValue(value);
        }}
      />
    );

  if (isReadyToChangeStatusFromUnreviewedToPublished(values, isValid))
    return (
      <ProjectStatus
        defaultValue={ProjectStatusType.Unreviewed}
        value={field.value as ProjectStatusType}
        options={[
          { value: ProjectStatusType.Unreviewed, title: 'Pending' },
          { value: ProjectStatusType.Active, title: 'Active' }
        ]}
        onSelect={(value) => {
          setValue(value);
        }}
      />
    );

  if (prevState === ProjectStatusType.InProcess || isMediaUploading) {
    return (
      <ButtonInfo
        onClick={() => {}}
        color={theme.colors.fg}
        backgroundColor={theme.colors.yellow}
      >
        Uploading...
      </ButtonInfo>
    );
  }

  if (prevState === ProjectStatusType.Draft) {
    return (
      <ButtonInfo
        onClick={() => {}}
        color={theme.colors.fg}
        backgroundColor={theme.colors.orange}
      >
        Draft
      </ButtonInfo>
    );
  }

  return (
    <ButtonInfo
      onClick={() => {
        showFillRequiredFieldsToastForFromReviewToPublished(
          errorFields,
          values
        );
      }}
      color={theme.colors.fg}
      backgroundColor={theme.colors.purple}
      css={`
        &:hover {
          color: ${theme.colors.fg};
          background-color: ${theme.colors.purple};
        }
      `}
    >
      Waiting
    </ButtonInfo>
  );
};
export default ProjectStatusRenderFormikWrapper;
