import React, { useEffect } from 'react';
import SelectFromData from 'components/SelectFromData';
import useGetListProjects, {
  TUseGetListProjectsItemData
} from 'hooks/useGetListProjects';
import { Rule } from 'antd/lib/form';

interface SelectProjectsProps {
  showArchived?: boolean;
  clientId?: string;
  isClientRequired?: boolean;
  onSelect?: (prjId?: string) => void;
  isRequired?: boolean;
  value?: string;
  setValue?: (arg0?: string) => void;
  name?: string;
  rules?: Rule[];
  placeholder?: string;
  isReadyOnly?: boolean;
}

const SelectProjects: React.FC<SelectProjectsProps> = ({
  showArchived = false,
  value,
  setValue,
  rules,
  name,
  onSelect,
  isRequired,
  isClientRequired = true,
  clientId,
  placeholder,
  isReadyOnly
}) => {
  const { data, loading } = useGetListProjects(
    clientId,
    isClientRequired,
    showArchived,
    true,
    isReadyOnly
  );

  useEffect(() => {
    setValue && setValue(undefined);
    onSelect && onSelect(undefined);
  }, [clientId, onSelect, setValue]);

  return (
    <>
      <SelectFromData<TUseGetListProjectsItemData>
        rules={rules}
        name={name}
        placeholder={placeholder}
        layout={'vertical'}
        disabled={isClientRequired && !clientId}
        isRequired={isRequired}
        loading={loading}
        value={value}
        data={data}
        idKey={'id'}
        valueRender={({ title }) => <>{`${title}`}</>}
        label={'Project'}
        onSelect={(projectId: string) => {
          setValue && setValue(projectId);
          onSelect && onSelect(projectId);
        }}
      />
    </>
  );
};

export default SelectProjects;
