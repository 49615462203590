import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { MutableRefObject, useEffect } from 'react';

interface Props {
  ref: MutableRefObject<SVGElement | null>;
  unitId: string;
  onMouseOver?: () => void;
}

export default function useMouseOver({ onMouseOver, ref, unitId }: Props) {
  const { curSelUnitId, curEvent, isUnitCreatingProcess, curMode } =
    useAnnotationSystemState();
  const { onSetCurEvent, onSetCurSelUnitId } = useAnnotationSystemActions();

  const handleMouseOver = (event: MouseEvent) => {
    if (
      /** Not when in create mode */
      !isUnitCreatingProcess &&
      /** Not self */
      curSelUnitId !== unitId &&
      /** Not in drag mode - left mouse button not released (pressed) */
      event.buttons !== 1 &&
      /** Not in drag mode */
      curEvent !== AnnotationSystemEventsEnum.DRAG_MODE
    ) {
      onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
      onSetCurSelUnitId(unitId);
      onMouseOver && onMouseOver();
      return;
    }
  };

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    element.addEventListener('mouseover', handleMouseOver);

    return () => {
      element.removeEventListener('mouseover', handleMouseOver);
    };
  });
}
