import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Box, Col, Row, Text } from 'components/_main';
import {
  LabelsDropdownContentProps,
  LabelElementProps
} from './LabelsDropdown.types';

import useLabelColor from 'containers/AnnotationSystem/hooks/useLabelColor';

export function LabelsDropdownContent({ data }: LabelsDropdownContentProps) {
  const theme = useTheme();

  return (
    <Row width="210px" gridGap="4px">
      {data.map((elem) => (
        <Box
          key={elem.id}
          padding="10px"
          bg="transparent"
          css={`
            &:hover {
              background-color: ${theme.colors.lightest};
              transition: background-color 200ms ease-in;
            }
          `}
          onClick={() => {}}
        >
          <Text>{elem.label}</Text>
        </Box>
      ))}
    </Row>
  );
}

export function LabelElement({ label, expandable = false }: LabelElementProps) {
  const labelColor = useLabelColor(label);

  return (
    <Box
      width="100%"
      displayFlex
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
    >
      <Col alignItems="center" gridGap="8px">
        <Box width="14px" height="14px" borderRadius="50%" bg={labelColor} />
        <Text>{label}</Text>
      </Col>
    </Box>
  );
}
