import React from 'react';
import { Popover } from 'antd';
import { Button, Text, Row, Box } from 'components/_main';
import useOpen from 'hooks/useOpen';
import { useTheme } from 'styled-components/macro';
import PricingPlansModal from 'containers/PricingPlans';
import { Z_INDEXES } from 'appConstants';
import { CloseIcon } from 'assets';

function BadgePricingPlanContent({ onHide }: { onHide: () => void }) {
  const theme = useTheme();
  const { isOpen, onClose, onOpen } = useOpen();

  return (
    <Row position="relative" width="390px" padding="20px">
      <Box
        position="absolute"
        top="10px"
        right="10px"
        css="cursor: pointer"
        onClick={onHide}
      >
        <CloseIcon
          css={`
            path {
              fill: ${theme.colors.light};
            }
          `}
        />
      </Box>
      <Text>
        Your <Text variant="ui-1-bold">Freemium</Text> access includes:
      </Text>
      <ol style={{ paddingLeft: '20px', marginBottom: 0 }}>
        <li>
          <Text lineHeight="26px">
            Two projects with datasets up to 500 MB (1000 MB in total)
          </Text>
        </li>
        <li>
          <Text lineHeight="26px">
            Two teammates (your team for annotation)
          </Text>
        </li>
        <li>
          <Text lineHeight="26px">Access to full-scale statistics</Text>
        </li>
        <li>
          <Text lineHeight="26px">JSON and COCO reports</Text>
        </li>
      </ol>
      <Text lineHeight="26px">
        You can always scale on demand and add our helpers to any of your
        projects. Also, if you haven't found matching specifications, please get
        in touch with our team. We will be happy to help you out
        <Text variant="ui-1-bold" colorVariant="primary" paddingLeft="4px">
          taskware@helpware.com
        </Text>
        !
      </Text>
      <Button marginTop="10px" onClick={onOpen}>
        Upgrade plan
      </Button>
      <PricingPlansModal isOpen={isOpen} onClose={onClose} />
    </Row>
  );
}

export default function BadgePricingPlan() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
  };

  const handleHidePopover = () => {
    handleOpenChange(false);
  };

  return (
    <>
      <Popover
        trigger="click"
        visible={open}
        placement="bottomRight"
        overlayStyle={{ zIndex: Z_INDEXES.popover }}
        overlayInnerStyle={{ borderRadius: '10px' }}
        content={<BadgePricingPlanContent onHide={handleHidePopover} />}
        onVisibleChange={handleOpenChange}
      >
        <Button
          capitalize
          bg={theme.colors.light}
          padding={'2px 10px'}
          borderRadius="5px"
          height={'26px'}
          small
        >
          <Text variant="ui-small-bold" color="white">
            Freemium
          </Text>
        </Button>
      </Popover>
    </>
  );
}
