import React, { useRef } from 'react';
import { TagsStyled } from './Tags.styled';
import { TagsProps } from './Tags.types';

function Tags({ options, ...restProps }: TagsProps) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref} data-type="select-wrapper">
      <TagsStyled mode="tags" {...restProps} />
    </div>
  );
}

export default Tags;
