import React from 'react';
import { TAntdFormItem } from 'appTypes';
import InputLabel from 'components/InputLabel';
import Input from 'components/Input';
import { rules as defaultRules } from './constants';

const AntdFormItemInputEmail: React.FC<TAntdFormItem> = ({
  label,
  rules = defaultRules,
  ...props
}) => {
  return (
    <InputLabel hasFeedback label={label} rules={rules} {...props}>
      <Input type="text" placeholder={label} />
    </InputLabel>
  );
};
export default AntdFormItemInputEmail;
