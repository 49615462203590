import { css } from 'styled-components/macro';
import { ButtonProps } from './types';
import numberToPx from '../../../utils/numberToPx';
import {
  FALLBACK_ICON_SIZE,
  NORMAL_HEIGHT,
  ICON_DEF_PADDING,
  NORMAL_MAX_WIDTH,
  NORMAL_MIN_WIDTH,
  OUTLINE_BORDER_WIDTH,
  ROUND_DEF_SIZE,
  ROUNDING,
  SIDES_SPACING
} from './constants';
import { TextContent } from './styled';
import { px } from 'utils';

export const styleButtonBase = ({ theme, disabled }: ButtonProps) => css`
  position: relative;
  outline: none;
  border: none;
  border-radius: ${numberToPx(ROUNDING)};
  padding: 0 ${numberToPx(SIDES_SPACING)};
  cursor: pointer;
  box-sizing: border-box;
  transition: all 200ms;
  display: grid;
  align-items: center;
  gap: 0;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-auto-rows: max-content;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    color: ${theme?.colors.bg};
    background-color: ${theme?.colors.secondary};
  }

  ${TextContent} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.02rem;
  }

  ${disabledStyle};
`;

export const styleRound = ({ roundSize }: Partial<ButtonProps>) => {
  const sizeFinal = px(roundSize ?? ROUND_DEF_SIZE);

  return css`
    padding: 0 0;
    min-width: ${sizeFinal};
    width: ${sizeFinal};
    max-width: ${sizeFinal};
    min-height: ${sizeFinal};
    height: ${sizeFinal};
    max-height: ${sizeFinal};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50rem;
  `;
};

export const styleIcon = ({
  iconSize,
  w = FALLBACK_ICON_SIZE,
  h = FALLBACK_ICON_SIZE,
  iconPadding = ICON_DEF_PADDING,
  hoverSvgFillColor,
  hoverSvgStrokeColor
}: Partial<ButtonProps>) => {
  let sizeW: string | number = px(iconSize ?? w);
  let sizeH: string | number = px(iconSize ?? h);

  return css`
    padding: ${px(iconPadding ?? 0)};
    border-radius: 100rem;
    width: ${sizeW};
    min-width: ${sizeW};
    max-width: ${sizeW};
    height: ${sizeH};
    max-height: ${sizeH};
    min-height: ${sizeH};
    font-size: ${px(iconSize)};

    &:hover {
      svg {
        transition: all 300ms;
        path,
        circle,
        rect {
          ${hoverSvgFillColor && `fill: ${hoverSvgFillColor} !important;`}
          ${hoverSvgStrokeColor && `stroke: ${hoverSvgStrokeColor} !important;`}
        }
      }
    }
  `;
};

export const styleSquare = () => css`
  border-radius: 4px;
`;

export const styleCapitalize = () => css`
  ${TextContent} {
    text-transform: capitalize;
  }
`;

export const styleWarning = ({ theme }: ButtonProps) =>
  css`
    background-color: ${theme?.colors.red};
    color: ${theme?.colors.bg};
    &:hover {
      background-color: ${theme?.colors.redHover};
    }
  `;

export const styleSuccess = ({ theme }: ButtonProps) =>
  css`
    background-color: ${theme?.colors.greenLight};
    color: ${theme?.colors.bg};
    &:hover {
      background-color: ${theme?.colors.successHover};
    }
  `;

const disabledStyle = css(
  ({ theme, disabled }: ButtonProps) =>
    disabled &&
    css`
      cursor: not-allowed;

      &,
      &:hover {
        -webkit-filter: grayscale(90%);
        filter: grayscale(90%);
        /* border-color: ${theme?.colors?.disabled}; */
        /* background-color: ${theme?.colors?.disabled}; */
        /* color: ${theme?.colors?.disabledFg}; */
      }
    `
);

/**
 * Sizes
 * =====
 */

export const styleBig = () => css`
  width: max-content;
  min-width: max-content;
  max-width: max-content;
  height: 50px;
  padding: 13px 30px;
  font-weight: 700;
  font-size: 13px;
`;

export const styleSmall = () => css`
  height: 34px;
  padding: 5px 20px;
  font-size: 10px;
`;

export const styleNormal = () => css`
  height: ${numberToPx(NORMAL_HEIGHT)};
  min-width: ${numberToPx(NORMAL_MIN_WIDTH)};
  max-width: ${numberToPx(NORMAL_MAX_WIDTH)};
  width: max-content;
`;

export const styleMini = () => css`
  height: 20px;
  padding: 5px 20px;
  font-size: 10px;
`;

export const styleMicro = () => css``;

/**
 * Variants
 * ========
 */

export const stylePrimary = ({ theme }: ButtonProps) =>
  css`
    background-color: ${theme?.colors.primary};
    color: ${theme?.colors.bg};
    &:hover {
      background-color: ${theme?.colors.primaryHover};
    }

    ${disabledStyle};
  `;

export const styleSecondary = ({ theme }: ButtonProps) =>
  css`
    background-color: ${theme?.colors.secondary};
    color: ${theme?.colors.bg};
    &:hover {
      background-color: ${theme?.colors.secondaryHover};
    }
  `;

export const styleAlternate = ({ theme }: ButtonProps) =>
  css`
    background-color: ${theme?.colors.alternate};
    color: ${theme?.colors.bg};
    &:hover {
      background-color: ${theme?.colors.alternateHover};
    }
  `;

export const styleOutlined = ({ theme, disabled }: ButtonProps) => {
  return css`
    border: ${px(OUTLINE_BORDER_WIDTH)} solid ${theme?.colors.primary};
    background-color: ${theme?.colors.bg};
    color: ${theme?.colors.primary};
    &:hover {
      background-color: ${theme?.colors.bg};
      border-color: ${theme?.colors.primaryHover};
      color: ${theme?.colors.primaryHover};
    }

    ${disabled &&
    css`
      &,
      &:hover {
        background-color: transparent;
      }
    `}
  `;
};

export const styleGhost = ({
  variant = 'primary',
  theme,
  disabled
}: ButtonProps) =>
  (!variant || variant === 'primary' || variant === 'ghost') &&
  css`
    background-color: transparent;
    color: ${theme?.colors.primary};
    &:hover {
      color: ${theme?.colors.dark};
      background-color: ${theme?.colors.lighter};
    }

    ${disabledStyle};

    ${disabled &&
    css`
      &,
      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    `}
  `;
