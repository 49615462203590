import styled, { css } from 'styled-components/macro';
import React from 'react';
import { Text } from 'components/_main';

interface AvatarGeneratorProps {
  firstName: string;
  lastName: string;
  loading?: boolean;
  className?: string;
  backgroundColor?: string;
}

const AvatarGenerator = styled(
  ({ className, firstName, lastName }: AvatarGeneratorProps) => (
    <div className={className}>
      {firstName && (
        <Text variant="ui-2-bold" colorVariant="invert" upper>
          {firstName[0]}
        </Text>
      )}
      {lastName && (
        <Text variant="ui-2-bold" colorVariant="invert" upper>
          {lastName[0]}
        </Text>
      )}
    </div>
  )
)(
  ({ theme, backgroundColor }) => css`
    width: 40px;
    height: 40px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: column;
    gap: 0;
    border-radius: 100rem;
    font-size: 20px;
    color: white;
    background-color: ${backgroundColor ?? theme.colors.secondary};
  `
);

export default AvatarGenerator;
