import { Select } from 'antd';
import styled, { css } from 'styled-components/macro';
import { ProjectStatus as ProjectStatusType } from 'generated/graphql';

interface Props {
  value: ProjectStatusType;
}

export const SelectProjectStatusStyled = styled(Select<ProjectStatusType>)(
  () => css<Props>`
    width: max-content !important;
    text-align: center;

    &:not(.ant-select-disabled) .ant-select-selector {
      border: none;
      color: white;
      font-weight: bold;
      padding: 0 1rem;

      ${({ value, theme }) => {
        switch (value) {
          case ProjectStatusType.Active:
            return css`
              background-color: ${theme.colors.primary};
            `;
          case ProjectStatusType.Completed:
            return css`
              background-color: ${theme.colors.greenLight};
            `;
          case ProjectStatusType.Unreviewed:
          default:
            return css`
              background-color: ${theme.colors.orange};
            `;
        }
      }}
    }
  `
);
