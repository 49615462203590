import styled, { css } from 'styled-components/macro';

interface RootProps {
  isActive: boolean;
  isDrag: boolean;
  color: string;
}

export const Root = styled.svg(
  () => css`
    overflow: visible;
    position: relative;
    pointer-events: all;
    transition: all 0.3ms;
  `
);

export const MainBorderCircle = styled.circle(
  ({ isActive, isDrag, color }: RootProps) => css`
    fill: ${color};
    pointer-events: all;
    position: relative;
    transform: scale(1);
    transition: all 0.3ms;

    ${!isDrag &&
    css`
      &:hover {
        transform: scale(2);
      }
    `}

    ${isActive &&
    !isDrag &&
    css`
      fill: ${({ theme }) => theme.colors.fg};
      cursor: grab;
    `}

    ${isActive &&
    isDrag &&
    css`
      cursor: move;
    `}
  `
);

export const InnerCircle = styled.circle(
  () => css`
    pointer-events: none;
    fill: ${({ theme }) => theme.colors.bg};
  `
);

export const MainBorderRectViewOnly = styled.rect(
  () => css`
    pointer-events: all;
    stroke-width: 0;
    fill: ${({ theme }) => theme.colors.fg};
    position: relative;
  `
);
