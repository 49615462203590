import React from 'react';
import { Text, BoxProps, Box, Row } from 'components/_main';
import { TaskwareLogoWithTitleIcon } from 'assets';
import 'styled-components/macro';
import { useTheme } from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_QUERY_SETTINGS } from 'appConstants';
import AnimatedIcon from 'components/AnimatedIcons/AnimatedIcon';
import { isHelper } from 'utils';

export interface AuthLayoutProps {}

function Wrapper(
  props: React.PropsWithChildren<
    BoxProps & {
      isMobile?: boolean;
    }
  >
) {
  return (
    <Box
      display="grid"
      width="100%"
      height="100%"
      gridAutoFlow="column"
      gridTemplateColumns={props?.isMobile ? '1fr' : '38% 1fr'}
      gridGap="0"
      {...props}
    />
  );
}
function Pane(
  props: React.PropsWithChildren<
    BoxProps & {
      isMobile?: boolean;
    }
  >
) {
  const theme = useTheme();
  if (props?.isMobile) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100vh"
      p="30px 40px"
      backgroundColor={isHelper() ? theme.colors.lightest : theme.colors.bg}
      {...props}
    />
  );
}

function PaneChild(props: React.PropsWithChildren<BoxProps>) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      p="30px 40px"
      overflowY="auto"
      backgroundColor={isHelper() ? theme.colors.bg : theme.colors.lightest}
      {...props}
    />
  );
}

function AuthPageView({ children }: React.PropsWithChildren<AuthLayoutProps>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(MEDIA_QUERY_SETTINGS.isMobile);
  const curYear = new Date().getFullYear();

  return (
    <Wrapper isMobile={isMobile}>
      <Pane isMobile={isMobile} color={theme.colors.fg}>
        <Box
          width="100%"
          height="100%"
          grid
          gridAutoFlow="row"
          gridAutoRows="max-content 1fr max-content"
          alignItems="flex-start"
          gridGap="50px"
        >
          <Box display="flex" justifyItems="flex-start">
            <TaskwareLogoWithTitleIcon />
          </Box>
          <Row justifyItems="center" gridGap="25px" alignSelf="center">
            <AnimatedIcon
              bgColor={isHelper() ? theme.colors.bg : theme.colors.lightest}
            />
            <Text variant="header-1" paddingTop="20px" center>
              {isHelper() ? 'Welcome Back!' : 'Join Taskware!'}
            </Text>
            <Text maxWidth="336px" textAlign="center" variant="ui-3-regular">
              {isHelper()
                ? 'Participate in training neural network projects, help create great things!'
                : 'Improve your AI with end-to-end data management and an efficient data labeling service.'}
            </Text>
          </Row>
          <Row flexCenter>
            <Text colorVariant="light">© {curYear} Taskware</Text>
          </Row>
        </Box>
      </Pane>
      <>{children}</>
    </Wrapper>
  );
}

AuthPageView.PaneChild = PaneChild;

export default AuthPageView;
