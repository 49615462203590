import {
  useAnnotationSystem,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { showUnitsWithZoomLevel } from 'containers/AnnotationSystem/utils';
import React from 'react';
import AnnotationUnitsWrapper from '../DotsWrapper';
import CreateDot from '../CreateDots';
import Dot from '../Dot';
import useRenderLines from './useRenderLines';
import useSvgLayerMouseDown from 'containers/AnnotationSystem/hooks/useSvgLayerMouseDown';
import useSvgLayerMouseMove from 'containers/AnnotationSystem/hooks/useSvgLayerMouseMove';
import useSvgLayerMouseUp from 'containers/AnnotationSystem/hooks/useSvgLayerMouseUp';

export default function SvgLayerDot() {
  const { isUnitCreatingProcess, curZoomLevel } = useAnnotationSystemState();
  const annotationUnits = useAnnotationSystem();
  const units = showUnitsWithZoomLevel(annotationUnits, curZoomLevel);
  const lines = useRenderLines({
    dots: units.filter((dot) => dot.type === 'dot')
  });

  useSvgLayerMouseDown({});
  useSvgLayerMouseMove({});
  useSvgLayerMouseUp({});

  return (
    <>
      {isUnitCreatingProcess && <CreateDot />}
      {lines}
      {units &&
        Array.isArray(units) &&
        units.map((dot) => {
          if (dot.type === 'dot') {
            return (
              <Dot key={`${dot.unitId}-${dot.x}-${dot.y}`} unitProps={dot} />
            );
          }

          if (dot.type === 'wrapper') {
            return (
              <AnnotationUnitsWrapper
                key={`${dot.unitId}-${dot.x}-${dot.y}`}
                {...dot}
              />
            );
          }

          return null;
        })}
    </>
  );
}
