import React from 'react';
import {
  Buildings,
  CheckSquareOffset,
  Users,
  ChartLineUp,
  Student,
  Question,
  PuzzlePiece,
  ProjectorScreenChart
} from '@phosphor-icons/react';
import { Box } from 'components/_main';
import { useRedirect } from 'hooks/useRedirect';
import { useTheme } from 'styled-components/macro';
import { isAdmin, isClient, isHelper } from 'utils/role';
import useGetUserSessionData from '../../hooks/useGetUserSessionData';
import env, { varEnv } from 'utils/envVars';
import { ROUTES, Z_INDEXES } from 'appConstants';
import { TaskwareLogoWithTitleWhiteIcon } from 'assets';
import { MenuItem } from './SidebarNav.ui';

const SidebarNav = () => {
  const theme = useTheme();
  const handleRedirect = useRedirect();
  const userSessionData = useGetUserSessionData();
  const [opened, setOpened] = React.useState<boolean>(false);

  const toggleOpen = () => setOpened(true);
  const toggleClose = () => setOpened(false);

  const handleClickHelp = () => {
    const element = document.createElement('A');
    element.setAttribute(
      'href',
      `mailto:${env.emailHelpAddresses}?subject="Support Taskware: ${userSessionData.firstName} ${userSessionData.lastName} / ${userSessionData.id}"`
    );
    element.click();
    element.remove();
  };

  return (
    <Box width="100%" minWidth="60px" flex="0 0 60px">
      <Box
        data-opened={opened}
        height="100%"
        position="fixed"
        width={opened ? '220px' : '60px'}
        background={theme.colors.dark}
        overflow="hidden"
        zIndex={Z_INDEXES.sidebarMain}
        css={`
          transition: all 300ms;
        `}
        onMouseEnter={toggleOpen}
        onMouseLeave={toggleClose}
      >
        <Box
          height="100vh"
          minWidth="200px"
          displayFlex
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Box
              position="relative"
              display="flex"
              height="50px"
              padding="12px 0 20px 15px"
              css={`
                box-sizing: content-box;
              `}
              onClick={() => handleRedirect('/')}
            >
              <Box display="flex" alignItems="center">
                <TaskwareLogoWithTitleWhiteIcon />
              </Box>
            </Box>
            <Box
              borderRight="none"
              width="100%"
              justifyItems="center"
              alignItems="center"
            >
              {isClient() && (
                <MenuItem
                  key={'main-nav-item-microtasking'}
                  text="Microtasking"
                  icon={<PuzzlePiece />}
                  link="/microtasking"
                  data-testid={'main-nav-item-microtasking'}
                />
              )}
              <MenuItem
                key={'main-nav-item-projects'}
                text="Projects"
                icon={<CheckSquareOffset />}
                link="/projects"
                data-testid={'main-nav-item-projects'}
              />
              {isHelper() && (
                <MenuItem
                  key={'main-nav-work-statistic'}
                  text="Work statistic"
                  icon={<ProjectorScreenChart />}
                  link={ROUTES.workStatistics}
                  data-testid={'main-nav-work-statistic'}
                />
              )}
              {isHelper() && (
                <MenuItem
                  key={'main-nav-item-learning'}
                  text="Learning materials"
                  icon={<Student />}
                  link="/learning"
                  data-testid={'main-nav-item-learning'}
                />
              )}
              {isAdmin() && (
                <MenuItem
                  key={'main-nav-item-clients'}
                  text="Clients"
                  icon={<Buildings />}
                  link="/clients"
                  data-testid={'main-nav-item-clients'}
                />
              )}
              {isAdmin() && (
                <MenuItem
                  key={'main-nav-item-users'}
                  text="Users"
                  icon={<Users />}
                  link="/users"
                  data-testid={'main-nav-item-users'}
                />
              )}
              {isAdmin() && (
                <MenuItem
                  key={'main-nav-item-reports'}
                  text="Reports"
                  icon={<ChartLineUp />}
                  link="/reports"
                  data-testid={'main-nav-item-reports'}
                />
              )}
            </Box>
          </Box>
          <Box borderRight="none" justifyItems="center" alignItems="center">
            {isClient() && !varEnv.isProd && (
              <MenuItem
                key={'main-nav-item-help'}
                text="Help"
                icon={<Question />}
                link="/help"
                data-testid={'main-nav-item-help'}
                onClick={handleClickHelp}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarNav;
