interface SetSvgPosProps {
  ref: SVGSVGElement | null;
  pos: Partial<SvgPos>;
}

interface SvgPos {
  x: number;
  y: number;
  w: number;
  h: number;
  isEnd?: boolean;
}

export function setSvgPos({ ref, pos: { x, w, h, y, isEnd } }: SetSvgPosProps) {
  if (!ref) return;

  x && ref.setAttribute('x', x.toString());
  y && ref.setAttribute('y', y.toString());
  w && ref.setAttribute('width', w.toString());
  h && ref.setAttribute('height', h.toString());
  ref.setAttribute('data-is-end', isEnd === true ? 'true' : 'false');
}

export function getSvgPos(ref: SVGSVGElement | null) {
  if (!ref) {
    return {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      isEnd: false
    };
  }

  return {
    x: parseInt(ref.getAttribute('x') ?? '0'),
    y: parseInt(ref.getAttribute('y') ?? '0'),
    w: parseInt(ref.getAttribute('width') ?? '0'),
    h: parseInt(ref.getAttribute('height') ?? '0'),
    isEnd: ref.getAttribute('data-is-end') === 'true'
  };
}
