import {
  MousePositionRef,
  MousePosStateRef
} from './useMousePositionRef.types';

export const INIT_REF = {
  current: null
};

export const INIT: MousePositionRef = {
  ref: INIT_REF
};

export const INIT_STATE_REF: MousePosStateRef = {
  initX: 0,
  initY: 0,
  dX: 0,
  dY: 0,
  x: 0,
  y: 0
};
