import React from 'react';
import { Row, Text } from 'components/_main';
import {
  BoundingBoxesImage,
  ContentModerationIcon,
  CuboidsImage,
  DatasetCollectionIcon,
  DigitizationTypeIcon,
  HumanPosesImage,
  ImageTypeIcon,
  PolygonsImage,
  PolylineImage,
  SemanticImage,
  VideoTypeIcon
} from 'assets';
import {
  WizardFormValues,
  AddProjectWizardContextState,
  ProjectSpecializationHelpItem,
  ProjectTypeHelpItem
} from './AddProjectWizard.types';
import {
  ImageAnnotationDatasetProcessingType,
  ProjectSpecialization,
  ProjectType,
  VideoAnnotationDatasetProcessingType
} from 'appTypes';
import { CHUNK_LENGTH } from 'appConstants';
import { Platform } from 'generated/graphql';

export const ADD_PROJECT_WIZARD_CONTEXT_STATE_INIT: AddProjectWizardContextState =
  {
    onSetMediaFile: () => {},
    mediaFile: null,
    isRequestCustomHelpers: false,
    isOnlyRequestCustomHelpers: false,
    onSetInvitedHelpers: () => null,
    invitedHelpers: null,
    isUserActivated: undefined,
    onSetProjectSpecialization: () => {},
    onSetProjectPlatform: () => {},
    onSetProjectType: () => {},
    type: ProjectType.VIDEO_ANNOTATION,
    platform: Platform.Hosted,
    specialization: ProjectSpecialization.BOUNDING_BOX,
    onSetAuthToken: () => {},
    authToken: null,
    onSetAnnotationMultipleFiles: () => {},
    annotationMultipleFiles: null,
    onSetLoading: () => {},
    videoMediaFile: null,
    videoFileDuration: 0,
    isLoading: false,
    videoLoadProgressLoaded: 0,
    videoLoadProgressTotal: 0,
    onVideoLoadProgress: () => {},
    onSetDuration: () => {},
    onSetVideoMediaFile: function () {},
    annotationFilesUploadType: 'single' as const,
    isMultiLevelLabels: false,
    videoAnnotationSpecSubType:
      VideoAnnotationDatasetProcessingType.ENTIRE_VIDEO,
    imageAnnotationSpecSubType:
      ImageAnnotationDatasetProcessingType.SINGLE_ZIP_ARCHIVE,
    isLabelsCorrect: false,
    onSetAnnotationFilesUploadType: () => null,
    onSetImageAnnotationSpecSubType: () => null,
    onSetIsLabelsCorrect: () => null,
    onSetIsMultiLevelLabels: () => null,
    onSetVideoAnnotationSpecSubType: () => null,
    onSetVideoAnnotationSplitType: () => null,
    onSetFormRef: () => null,
    formRef: { current: null },
    onSetFormOptions: () => null,
    formOptions: {
      currentStep: 0
    },
    onSetCurrentStep: () => null
  };

export const WIZARD_FORM_INITIAL_VALUES: WizardFormValues = {
  customMessageInviteHelpersRequest: '',
  title: '',
  clientId: null,
  type: ProjectType.VIDEO_ANNOTATION,
  mediaFileName: '',
  authToken: null,
  labels: [],
  auditorRate: null,
  helperRate: null,
  customLabels: false,
  adminInstructionFileName: '',
  adminShortInstruction: '',
  clientInstructionFileNames: [],
  clientShortInstruction: '',
  mlLabels: { multilabels: [] },
  projectSettings: {
    chunkLength: CHUNK_LENGTH.unlim,
    subType: 'boxes'
  }
};

export const projectSpecializationHelpList: ProjectSpecializationHelpItem[] = [
  {
    id: 1,
    title: 'Video Annotation',
    description: (
      <Row gridGap="10px">
        <Text>
          Video annotation is the process of labeling or tagging video clips
          used to train computer vision models to detect or identify objects.
        </Text>
        <Text>
          With TaskWare, you can annotate video clips by frames, seconds, or the
          entire video without splitting.
        </Text>
      </Row>
    ),
    icon: <VideoTypeIcon />
  },
  {
    id: 2,
    title: 'Image Annotation',
    description: (
      <Row gridGap="10px">
        <Text>
          Image annotation is the process of labeling or classifying an image
          using text, annotation tools, or both to show the data features you
          want your model to recognise on its own.
        </Text>
        <Text>
          By annotating a picture, you are adding metadata to a dataset.
        </Text>
      </Row>
    ),
    icon: <ImageTypeIcon />
  },
  {
    id: 3,
    title: 'Content Moderation',
    description: (
      <Row gridGap="10px">
        <Text>
          It is the process of content screening and monitoring online and
          offline. We are providing a safe environment for both users and
          brands.
        </Text>
        <Text>
          We are helping platforms moderate content to ensure that it falls
          within pre-established guidelines of acceptable behavior specific to
          the platform and its audience.
        </Text>
      </Row>
    ),
    icon: <ContentModerationIcon />
  },
  // {
  //   id: 4,
  //   title: 'Digitization',
  //   description: 'The process of transforming handwritten text into digital.',
  //   icon: <DigitizationTypeIcon />
  // },
  {
    id: 5,
    title: 'Dataset Collection',
    description: (
      <Row gridGap="10px">
        <Text>
          With TaskWare, you can submit your requirements for dataset
          collection.
        </Text>
        <Text>
          Our team of more than a hundred helpers will help you collect, label,
          and deliver to you, meeting the deadlines.
        </Text>
      </Row>
    ),
    icon: <DatasetCollectionIcon />
  }
];

export const projectTypeHelpList: ProjectTypeHelpItem[] = [
  {
    id: 1,
    title: 'Bounding Boxes',
    description:
      'A bounding box is an imaginary rectangle that serves as a reference\n' +
      ' point for object detection and creates a collision box for that\n' +
      ' object. You can outline the thing of interest within each image by\n' +
      ' defining its X and Y coordinates with bounding boxes.',
    image: <BoundingBoxesImage />
  },
  {
    id: 2,
    title: 'Polygons',
    description:
      'Polygon annotation is a precise way to annotate objects by selecting\n' +
      ' a series of x and y coordinates along its edges. Polygon annotation\n' +
      ' can hence have pixel-perfect precision while being highly flexible\n' +
      ' and adaptable to various shapes.',
    image: <PolygonsImage />
  },
  {
    id: 3,
    title: 'Dots',
    description:
      'Dot Estimation identifies and classifies the poses of human\n' +
      ' body parts and joints in images or videos. A model-based technique\n' +
      ' is used to represent and infer human body poses in 2D and 3D space.\n' +
      ' With our predefined skeletons, we can ensure that all the datasets\n' +
      ' will be annotated consistently in each frame, image, or video.',
    image: <HumanPosesImage />
  },
  {
    id: 4,
    title: 'Semantic Segmentation',
    description:
      'Semantic Segmentation is assigning a label to every pixel in the\n' +
      ' image. This is in stark contrast to classification, where a single\n' +
      ' label is given to the entire picture. Semantic segmentation treats\n' +
      ' multiple objects of the same class as a single entity.',
    image: <SemanticImage />
  },
  {
    id: 5,
    title: 'Polyline',
    description:
      'Polyline annotation is defining linear structures in images and\n' +
      ' video. By the small lines, connected at vertices, to trace the shape\n' +
      ' of structures like roads, rail tracks, and pipelines.',
    image: <PolylineImage />
  },
  {
    id: 6,
    title: 'Cuboids (3D)',
    description: '',
    image: <CuboidsImage />
  }
];

export const ALLOWED_FILE_FORMAT_FOR_NLP = '.csv, .xlsx, .xls, .xlsm';
