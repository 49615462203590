import React from 'react';
import { Box, Col, ButtonIcon } from 'components/_main';
import { Text } from 'components/_main/Text';
import { X } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';
import Icon from 'components/Icon';

interface DrawerHeaderProps {
  gridArea?: string;
  onClose?: () => void;
  title?: string;
}

function DrawerHeader({ gridArea, title, onClose }: DrawerHeaderProps) {
  const theme = useTheme();

  return (
    <Box gridArea={gridArea} width="100%" height="100%">
      <Col
        alignItems="center"
        px="25px"
        gridAutoRows="100%"
        width="100%"
        height="100%"
        gridAutoColumns="1fr max-content"
        backgroundColor={theme.colors.lightest}
      >
        <Text variant="ui-3-bold">{title}</Text>
        {onClose && (
          <Icon hoverFillColor={theme.colors.gray3} onClick={onClose}>
            <X size={20} color={theme.colors.light} />
          </Icon>
        )}
      </Col>
    </Box>
  );
}

export default DrawerHeader;
