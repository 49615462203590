import React from 'react';
import { useToggleClientAccountMutation } from 'generated/graphql';

function useClientAvailableToggleMutationProvider({
  component: Component,
  refetchQueries
}: any) {
  const [toggleAccount, { loading }] = useToggleClientAccountMutation({
    refetchQueries: () => refetchQueries
  });

  const handleToggle = (id: any) => {
    return toggleAccount({
      variables: {
        clientId: parseInt(id, 10)
      }
    });
  };

  return [
    <Component onToggle={handleToggle} loading={loading} />,
    loading
  ] as const;
}

export default useClientAvailableToggleMutationProvider;
