import styled from 'styled-components/macro';
import { RowLegacy } from 'components/_main';

export const Root = styled.div``;
export const ColHeaderStyled = styled(RowLegacy)`
  gap: 1rem;
  grid-auto-columns: max-content max-content 150px 150px max-content 1fr
    max-content max-content;
  width: 100%;
  align-items: baseline;
`;

export const ButtonCellStyled = styled(RowLegacy)`
  align-self: center;
  height: 100%;
  align-items: center;
`;
