import { upper } from 'utils/_stringUtilsLegacy';
import { ColumnsType } from 'antd/es/table';

const columns: ColumnsType<object> = [
  {
    title: upper('Client'),
    dataIndex: ['client', 'companyName'],
    width: 50
  },
  {
    title: upper('Project'),
    dataIndex: 'title',
    width: 50
  },
  {
    title: upper('New'),
    dataIndex: 'newTasksCount',
    width: 50
  },
  {
    title: upper('In progress'),
    dataIndex: 'startedTasksCount',
    width: 50
  },
  {
    title: upper('Ready for audit'),
    dataIndex: 'readyForAudit',
    width: 50
  },
  {
    title: upper('In audit'),
    dataIndex: 'inAuditTasksCount',
    width: 50
  },
  {
    title: upper('Completed'),
    dataIndex: 'completedTasksCount',
    width: 50
  },
  {
    title: upper('Rejected'),
    dataIndex: 'rejectedTasksCount',
    width: 50
  },
  {
    title: upper('Work points'),
    dataIndex: 'workPoints',
    width: 50
  }
];

export default columns;
