import React, { PropsWithChildren } from 'react';
import styled, { useTheme, css } from 'styled-components/macro';
import Button from './Button';
import { ButtonProps, Variant } from './types';
import { buttonWithVariant } from './utils';

export const ButtonIcon = ({
  children,
  ...restProps
}: PropsWithChildren<Partial<ButtonProps>>) => {
  const theme = useTheme();

  return (
    <Button
      iconStyle
      variant="ghost"
      iconPadding={2}
      iconSize={24}
      color={theme.colors.primary}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export const ButtonSecondary = buttonWithVariant(Variant.secondary);
export const ButtonPrimary = buttonWithVariant(Variant.primary);
export const ButtonAlternate = buttonWithVariant(Variant.alternate);
export const ButtonWarning = buttonWithVariant(Variant.warning);
export const ButtonSuccess = buttonWithVariant(Variant.success);
export const ButtonGhost = buttonWithVariant(Variant.ghost);
export const ButtonOutline = buttonWithVariant(Variant.outlined);
export const ButtonInfo = buttonWithVariant(Variant.primary, {
  small: true,
  square: true
});
export const ButtonSquare = buttonWithVariant(Variant.primary, {
  square: true
});
export const ButtonSmallSquare = buttonWithVariant(Variant.primary, {
  square: true,
  small: true
});
