import {
  Project,
  ProjectResultsQuery,
  useProjectResultsLazyQuery
} from 'generated/graphql';
import { toast } from 'utils/toast';
import downloadContent from 'utils/downloadContent';
import { useCallback } from 'react';
import { consoleError } from 'utils';

const useDownloadProjectsJsonResults = () => {
  const [getProjectResults, { loading: projectResultLoading }] =
    useProjectResultsLazyQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        toast.error(error.message);
      }
    });

  const startDownload = useCallback(
    (projectData: Project, data?: ProjectResultsQuery) => {
      const results = data?.projectResults?.results;
      const projectId = data?.projectResults.id;

      if (!results) {
        consoleError('No results while downloading JSON report');
        return;
      }

      const base64results = window.btoa(JSON.stringify(results));

      const { client, title: projectTitle } = projectData ?? {};
      const clientTitle = `${
        client?.companyName ?? `${client?.firstName} ${client?.lastName}`
      }`;

      if (base64results) {
        downloadContent(
          `${clientTitle} - ${projectTitle} - JSON results`,
          base64results,
          'data:application/json;base64,'
        );
      }

      toast.success(
        `Results for project with ID ${projectId} downloaded successfully`
      );
    },
    []
  );

  return [
    useCallback(
      async (projectData?: Project) => {
        const projectId = projectData?.id;
        if (!projectId) return;

        try {
          const results = await getProjectResults({
            variables: {
              projectId: projectId
            }
          });
          startDownload(projectData, results?.data);
        } catch (e) {
          consoleError(e);
        }

        return;
      },
      [startDownload, getProjectResults]
    ),
    projectResultLoading
  ] as const;
};

export default useDownloadProjectsJsonResults;
