import { PolygonPoints } from 'containers/AnnotationSystem/reducer';

export default function modifyPolygonPoint(
  polyPoints: PolygonPoints,
  pointToModify: {
    index: number;
    newPosition: [number, number];
  }
) {
  const newPoints = polyPoints.map((point, index) =>
    index === pointToModify.index ? pointToModify.newPosition : point
  );

  return [...newPoints];
}
