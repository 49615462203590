import {
  AnnotationUnitProps,
  TimelineTransition
} from '../AnnotationSystem.types';
import { setSvgPos } from 'utils';

export interface HandleTransAnimationProps {
  timelineTransitions: AnnotationUnitProps['timelineTransitions'];
  curTime: number;
  prevTime: number;
  svgRef: SVGSVGElement | null;
  videoIsPlaying: boolean;
  forceUpdate?: boolean;
}

function handleAnimateTransitions({
  curTime,
  prevTime,
  timelineTransitions,
  svgRef,
  videoIsPlaying,
  forceUpdate
}: HandleTransAnimationProps) {
  let posX: number;
  let posY: number;
  let posW: number;
  let posH: number;
  let timeGapBetweenNextPrevPos: number;
  let prevPos: TimelineTransition | null = null;
  let nextPos: TimelineTransition | null = null;
  let stepX: number;
  let stepY: number;
  let stepH: number;
  let stepW: number;
  let lastTimestampReached: boolean = false;
  let firstTimestampReached: boolean = false;

  const onLastPosReached = (nextPos: any) => {
    setSvgPos({
      ref: svgRef,
      pos: {
        ...nextPos
      }
    });
    lastTimestampReached = true;
    return;
  };

  const onFirstPosReached = (prevPos: any) => {
    setSvgPos({
      ref: svgRef,
      pos: {
        ...prevPos
      }
    });
    firstTimestampReached = true;
    return;
  };

  let doAnimation = true;

  if (!svgRef) {
    return;
  }

  const setVisibility = (visible?: boolean) => {
    if (svgRef?.dataset?.sidebarVisibilityStatus === 'false') {
      svgRef.style.visibility = 'hidden';
      return;
    }

    if (visible) {
      svgRef.style.visibility = 'visible';
      return;
    }

    svgRef.style.visibility = 'hidden';
  };

  setVisibility(true);
  svgRef.style.opacity = '1';

  if (!timelineTransitions || timelineTransitions.length === 0) {
    return;
  }

  const nextPosIdx = timelineTransitions.findIndex((item) => {
    return item.timestamp >= curTime;
  });

  /** Prev. & next pos. */
  prevPos = timelineTransitions[0];

  if (timelineTransitions.length === 0) {
    prevPos = timelineTransitions[0];
    nextPos = timelineTransitions[0];
  } else if (nextPosIdx === -1) {
    nextPos = timelineTransitions[timelineTransitions.length - 1];
    if (timelineTransitions.length > 1) {
      prevPos = timelineTransitions[timelineTransitions.length - 2];
    }
  } else if (nextPosIdx === 0) {
    prevPos = timelineTransitions[0];
    nextPos = timelineTransitions[0];
  } else {
    nextPos = timelineTransitions[nextPosIdx];
    prevPos = timelineTransitions[nextPosIdx - 1];
  }

  /** Time, check if show animation */
  lastTimestampReached = false;
  firstTimestampReached = false;

  if (curTime > nextPos.timestamp) {
    if (!lastTimestampReached) {
      onLastPosReached(nextPos);
    }

    doAnimation = false;
  }

  if (curTime < prevPos?.timestamp) {
    if (!firstTimestampReached) {
      onFirstPosReached(prevPos);
    }

    doAnimation = false;
  }

  /** Visibility */
  setVisibility(true);
  svgRef.style.opacity = '1';
  if (curTime > nextPos.timestamp && nextPos.isEnd) {
    svgRef.style.opacity = '0.5';
  }

  if (curTime < prevPos.timestamp) {
    svgRef.style.opacity = '0.5';
  }

  if (curTime < nextPos.timestamp && prevPos.isEnd) {
    svgRef.style.opacity = '0.5';
  }

  if (videoIsPlaying) {
    svgRef.style.visibility = 'visible';

    if (curTime < prevPos.timestamp) {
      svgRef.style.visibility = 'hidden';
    }

    if (curTime < nextPos.timestamp && prevPos.isEnd) {
      svgRef.style.visibility = 'hidden';
    }

    if (curTime > nextPos.timestamp && nextPos.isEnd) {
      svgRef.style.visibility = 'hidden';
    }
  }

  const update = () => {
    if (nextPos && prevPos) {
      timeGapBetweenNextPrevPos = nextPos.timestamp - prevPos.timestamp;
      stepX = (nextPos.x - prevPos.x) / timeGapBetweenNextPrevPos;
      stepY = (nextPos.y - prevPos.y) / timeGapBetweenNextPrevPos;
      stepW = (nextPos.w - prevPos.w) / timeGapBetweenNextPrevPos;
      stepH = (nextPos.h - prevPos.h) / timeGapBetweenNextPrevPos;
      posX = prevPos.x + stepX * (curTime - prevPos.timestamp);
      posY = prevPos.y + stepY * (curTime - prevPos.timestamp);
      posW = prevPos.w + stepW * (curTime - prevPos.timestamp);
      posH = prevPos.h + stepH * (curTime - prevPos.timestamp);

      if (nextPos.timestamp <= curTime) {
        setSvgPos({
          ref: svgRef,
          pos: {
            x: nextPos.x,
            y: nextPos.y,
            w: nextPos.w,
            h: nextPos.h,
            isEnd: nextPos.isEnd
          }
        });

        return;
      }

      setSvgPos({
        ref: svgRef,
        pos: {
          x: posX,
          y: posY,
          w: posW,
          h: posH,
          isEnd: nextPos.isEnd
        }
      });
    }
  };

  /** Animate timeline update */
  if (
    doAnimation &&
    curTime !== prevTime &&
    nextPos.timestamp !== prevPos.timestamp &&
    nextPos.timestamp > prevPos.timestamp
  ) {
    prevTime = curTime;
    update();
  }

  if (forceUpdate) {
    prevTime = curTime;
    update();
  }
}

export default handleAnimateTransitions;
