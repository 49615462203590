import React from 'react';
import { Box, Text } from 'components/_main';
import { css, useTheme } from 'styled-components/macro';
import { useRedirect } from 'hooks/useRedirect';
import useActiveRoute from 'hooks/useActiveRoute';
import Icon from 'components/Icon';
import useHover from 'hooks/useHover';

interface MenuItemProps {
  text: string;
  link: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

export function MenuItem({ text, link, icon, onClick }: MenuItemProps) {
  const theme = useTheme();
  const handleRedirect = useRedirect();
  const curActiveRoute = useActiveRoute();
  const [ref, hover] = useHover();

  const active = !!curActiveRoute.match(link);

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    handleRedirect(link);
  };

  return (
    <Box
      ref={ref}
      data-is-active={active}
      displayFlex
      alignItems="center"
      width="100%"
      padding="14px 12px"
      backgroundColor={active ? theme.colors.darker : ''}
      onClick={handleClick}
    >
      <Box data-role="menu-item-icon" displayFlex alignItems="center">
        <Icon size={32} color={active || hover ? 'bg' : 'grayLight'}>
          {icon}
        </Icon>
      </Box>
      <Text
        nowrap
        data-role="menu-item-text"
        color={active || hover ? theme.colors.bg : theme.colors.grayLight}
      >
        {text}
      </Text>
    </Box>
  );
}
