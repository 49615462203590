import styled, { css } from 'styled-components/macro';

interface GroupProps {
  selected?: boolean;
  color?: string;
}

export const Group = styled('g')<GroupProps>(
  ({ selected, color }) => css`
    &.hover,
    &:hover {
      rect {
        stroke: ${color};
      }
    }

    ${selected &&
    css`
      rect {
        stroke: ${color};
      }
    `}
  `
);
