import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { savePolygonZoom, savePolyPointsZoom } from './utils';

interface Props {
  props: AnnotationUnitProps;
  unitId: string;
}

export default function useHandleState({ unitId, props: initProps }: Props) {
  const { curZoomLevel } = useAnnotationSystemState();
  const [props, setPropsRaw] = useState<AnnotationUnitProps>(initProps);
  const { onSetPolygonPoints } = useAnnotationSystemActions();

  const setPartialProps = useCallback(
    (newProps: Partial<AnnotationUnitProps>) => {
      setPropsRaw((prev) => ({ ...prev, ...newProps }));
    },
    []
  );

  useEffect(() => {
    setPropsRaw(initProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    curZoomLevel,
    props.label,
    initProps?.label,
    initProps?.isLabelDropdownOpen,
    initProps?.polygonPoints?.length
  ]);

  const handleReducerUpdate = useCallback(
    (newProps: Partial<AnnotationUnitProps>) => {
      onSetPolygonPoints({
        unitId,
        newPolyPoints: savePolyPointsZoom(
          newProps.polygonPoints ?? [],
          curZoomLevel
        )
      });
    },
    [curZoomLevel, onSetPolygonPoints, unitId]
  );

  const handleReducerUpdateDebounced = useDebouncedCallback(
    handleReducerUpdate,
    50
  );

  const setPropsWithReducerUpdate = useCallback(
    (newProps: Partial<AnnotationUnitProps>) => {
      setPropsRaw((prev) => {
        const props: AnnotationUnitProps = { ...prev, ...newProps };
        handleReducerUpdateDebounced(props);
        return props;
      });
    },
    [handleReducerUpdateDebounced]
  );

  return useMemo(
    () => ({
      props,
      setProps: setPartialProps,
      setPropsWithReducerUpdate,
      onReducerUpdate: handleReducerUpdateDebounced
    }),
    [
      handleReducerUpdateDebounced,
      props,
      setPartialProps,
      setPropsWithReducerUpdate
    ]
  );
}
