import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { ADD_WIZARD_FIELDS } from 'appConstants';
import { GoogleDriveIcon } from 'assets';
import { useAddProjectWizardContext } from 'containers/AddProjectWizard/AddProjectWizard.context';
import { mapGoogleDriveResponseToAnnotationMultipleFiles } from 'containers/AddProjectWizard/AddProjectWizard.utils';
import { UploadCloudServiceIcon } from 'containers/UploadFiles';
import { useField } from 'formik';
import React from 'react';
import { consoleError } from 'utils';
import envVars from 'utils/envVars';

export interface GoogleDriveDataResultDoc {
  id: string;
  type: 'photo' | 'video';
  name: string;
}

export interface GoogleDriveDataResult {
  action: 'picked' | 'loaded';
  docs: GoogleDriveDataResultDoc[];
}

function loadPicker(token: string, finalCallback: (token: string) => void) {
  // @ts-ignore
  gapi.load('picker', { callback: () => createPicker(token, finalCallback) });
}

function createPicker(token: string, finalCallback: (token: string) => void) {
  if (token) {
    // @ts-ignore
    const pickerLib = google.picker;
    var view = new pickerLib.View(pickerLib.ViewId.DOCS);
    view.setMimeTypes('image/png,image/jpeg,image/jpg');
    var picker = new pickerLib.PickerBuilder()
      .enableFeature(pickerLib.Feature.NAV_HIDDEN)
      .enableFeature(pickerLib.Feature.MULTISELECT_ENABLED)
      .setAppId(envVars.googleProjectNumber)
      .setOAuthToken(token)
      .addView(view)
      .addView(new pickerLib.DocsUploadView())
      .setCallback(finalCallback(token))
      .build();
    picker.setVisible(true);
  }
}

export default function GoogleDrive() {
  if (!envVars.googleOAuth2ClientId) {
    consoleError('No env var GOOGLE_OAUTH2_CLIENT_ID');
    return null;
  }

  return (
    <GoogleOAuthProvider clientId={envVars.googleOAuth2ClientId}>
      <Consumer />
    </GoogleOAuthProvider>
  );
}

function Consumer() {
  const { onSetAuthToken, onSetAnnotationMultipleFiles } =
    useAddProjectWizardContext();

  const [, , helper] = useField(ADD_WIZARD_FIELDS.media);

  const finalCallback =
    (authToken: string) => (data: GoogleDriveDataResult) => {
      if (data?.action !== 'picked') {
        consoleError('No data?.action', data?.action);
        return;
      }

      onSetAuthToken(authToken);

      const finalFiles = mapGoogleDriveResponseToAnnotationMultipleFiles(data);

      onSetAnnotationMultipleFiles(finalFiles);
      helper.setError(undefined);
    };

  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      loadPicker(credentialResponse.access_token, finalCallback);
    },
    onError: () => {
      consoleError('Login Failed');
    }
  });

  return (
    <UploadCloudServiceIcon onClick={() => login()}>
      <GoogleDriveIcon />
    </UploadCloudServiceIcon>
  );
}
