import { APP_BAR_HEIGHT } from 'appConstants';
import { Box, BoxProps } from 'components/_main';

const RouteContent = ({
  children,
  ...restProps
}: React.PropsWithChildren<BoxProps>) => {
  return (
    <Box
      data-testid="RouteContent"
      className="dashboard-page-content"
      p="2rem 28px 3rem"
      bg="#fff"
      minHeight={`calc(100vh - ${APP_BAR_HEIGHT});`}
      maxHeight={`calc(100vh - ${APP_BAR_HEIGHT});`}
      overflowX="hidden"
      overflowY="auto"
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default RouteContent;
