import useLocalStorage from 'hooks/useLocalStorage';
import { SortByDateOrder } from 'generated/graphql';
import { useCallback, useMemo } from 'react';
import useGetUrlParams from './useGetUrlParams';

export default function useTableSortMethod(key: string) {
  const { params, update } = useGetUrlParams();

  const curSort: SortByDateOrder =
    (params.get('sort') as SortByDateOrder) ?? SortByDateOrder.Desc;

  const [sortMethod, setSortMethod] = useLocalStorage<SortByDateOrder>(
    key,
    curSort
  );

  const handleSetMethod = useCallback(
    (newSort: SortByDateOrder) => {
      setSortMethod(newSort);
      params.set('sort', newSort);
      update();
    },
    [params, setSortMethod, update]
  );

  const handleSortMethodToggle = useCallback(() => {
    const newSortMethod =
      sortMethod === SortByDateOrder.Desc
        ? SortByDateOrder.Asc
        : SortByDateOrder.Desc;
    handleSetMethod(newSortMethod);
  }, [handleSetMethod, sortMethod]);

  const handleSortMethodReset = useCallback(() => {
    handleSetMethod(SortByDateOrder.Desc);
  }, [handleSetMethod]);

  return useMemo(
    () => ({
      sortMethod: sortMethod ?? SortByDateOrder.Desc,
      setSortMethod: handleSetMethod,
      onSortMethodToggle: handleSortMethodToggle,
      onSortMethodReset: handleSortMethodReset
    }),
    [handleSetMethod, handleSortMethodReset, handleSortMethodToggle, sortMethod]
  );
}
