import React from 'react';
import { CursorIcon } from 'assets/taskingTopToolbar';
import { SkeletonPointsIcon } from 'assets';
import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { ToolbarIcon, ToolbarSection } from './AnnotationToolbar.ui';
import { Button, Col } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import SectionZoom from './SectionZoom';
import SectionSvgLayerOpacity from './SectionSvgLayerOpacity';
import SectionHand from './SectionHand';

export default function DotsAnnotationToolbar() {
  const theme = useTheme();
  const { isUnitCreatingProcess, curMode, curEvent, isViewOnly } =
    useAnnotationSystemState();

  const { onSetCurEvent, onSetCurMode } = useAnnotationSystemActions();

  const isDrag = curEvent === AnnotationSystemEventsEnum.DRAG_MODE;
  const isSelectedEvent = curEvent === AnnotationSystemEventsEnum.SELECTED_MODE;
  const isDotMode = curMode === AnnotationSystemModesEnum.DOT_SELECT;

  const activeCreate =
    curEvent === AnnotationSystemEventsEnum.CREATE_MODE ||
    curEvent === AnnotationSystemEventsEnum.CREATING_SIZE ||
    curEvent === AnnotationSystemEventsEnum.CREATING_LABEL;

  const activeDotMode =
    curMode === AnnotationSystemModesEnum.DOT_SELECT && !activeCreate;

  const handleStartCreateMode = () => {
    if (isUnitCreatingProcess) {
      onSetCurEvent(null);
      return;
    }
    onSetCurEvent(AnnotationSystemEventsEnum.CREATE_MODE);
  };

  const handleSetSelectDotMode = () => {
    onSetCurMode(AnnotationSystemModesEnum.DOT_SELECT);
  };

  const handleEndMoveMode = () => {
    onSetCurMode(AnnotationSystemModesEnum.UNIT_SELECT);
    onSetCurEvent(null);
  };

  return (
    <Col alignItems="center">
      <ToolbarSection>
        <ToolbarIcon
          icon={<SkeletonPointsIcon />}
          active={activeCreate}
          disabled={isViewOnly}
          onClick={handleStartCreateMode}
        />
      </ToolbarSection>
      <SectionHand />
      {isSelectedEvent && (
        <ToolbarSection>
          <ToolbarIcon
            icon={<CursorIcon />}
            active={activeDotMode}
            disabled={isViewOnly}
            onClick={handleSetSelectDotMode}
          />
          {isDotMode && !isDrag && (
            <ToolbarIcon onClick={handleEndMoveMode}>DONE</ToolbarIcon>
          )}
        </ToolbarSection>
      )}
      <SectionZoom />
      <SectionSvgLayerOpacity />
    </Col>
  );
}
