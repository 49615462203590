import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import { useAppContext } from 'containers/App';
import AuthPageViewNew from 'containers/AuthPageViewNew';
import {
  Container,
  Row,
  Text,
  Button,
  Col,
  Link as MainLink,
  Text as MainText
} from 'components/_main';
import { InputLabelField } from 'components/_form';
import {
  currentRole,
  isClient,
  loginByUserRole,
  parseAuthSuccessResp,
  signinValidationSchema,
  varEnv
} from 'utils';
import { toast } from 'utils/toast';
import { useMutation } from '@apollo/client';
import { SignInValues } from 'appTypes';
import GoogleAuth from 'containers/AuthPageViewNew/GoogleAuth';
import AuthFormWrapper from 'containers/AuthPageViewNew/AuthFormWrapper';
import { LinkStyled } from 'containers/AuthPageViewNew/AuthPageView.ui';

function SignIn() {
  const { signIn } = useAppContext();

  const formInitValues = {
    email: '',
    password: ''
  };

  const [signInMutation, { loading }] = useMutation(
    loginByUserRole(currentRole()),
    {
      onCompleted(data) {
        toast.success('You successfully logged in');
        const newUserData = parseAuthSuccessResp('login', data);
        signIn(newUserData);
      },
      onError({ message }) {
        toast.error(message);
      }
    }
  );

  const handleSubmit = async (values: SignInValues) => {
    await signInMutation({ variables: values });
  };

  return (
    <>
      <AuthPageViewNew>
        <AuthPageViewNew.PaneChild>
          <Row height="100%" width="100%" gridAutoRows="max-content 1fr">
            <Row justifyItems="flex-end">
              <Text>
                Back to{' '}
                <MainLink href={varEnv.taskwareIoUrl} target="_blank">
                  taskware.io
                </MainLink>
              </Text>
            </Row>
            <Row gridGap="2.5rem" justifyItems="center" alignItems="center">
              <Container width="440px" minWidth="440px">
                <Formik
                  initialValues={formInitValues}
                  onSubmit={handleSubmit}
                  validationSchema={signinValidationSchema}
                >
                  <FormikBody />
                </Formik>
              </Container>
              {isClient() && (
                <Text>
                  Don't have an account?{' '}
                  <LinkStyled to="/signup">
                    <Text variant="ui-1-bold" colorVariant="primary">
                      Sign up
                    </Text>
                  </LinkStyled>
                </Text>
              )}
            </Row>
          </Row>
        </AuthPageViewNew.PaneChild>
      </AuthPageViewNew>
    </>
  );
}

function FormikBody() {
  const { isSubmitting, isValidating, submitForm } = useFormikContext();

  return (
    <AuthFormWrapper submitForm={submitForm}>
      <Row gridGap="2rem">
        <Row justifyItems="center" gridGap=".5rem">
          <Text variant="ui-4-bold" letterSpacing="0.5px">
            Sign in
          </Text>
        </Row>
        <Row gridGap="10px">
          <InputLabelField name="email" placeholder="Email" />
          <InputLabelField name="password" placeholder="Password" password />
        </Row>
        <Row justifyItems="center">
          <Button
            size="big"
            width="100%"
            maxWidth="100%"
            isLoading={isValidating || isSubmitting}
            onClick={submitForm}
          >
            SIGN IN
          </Button>
          {isClient() && <GoogleAuth />}
        </Row>
        <Col wide justifyItems="center" margin="8px 0">
          <LinkStyled to="/forgot-password">
            <MainText variant="ui-1-bold" colorVariant="primary">
              Forgot password?
            </MainText>
          </LinkStyled>
        </Col>
      </Row>
    </AuthFormWrapper>
  );
}

export default SignIn;
