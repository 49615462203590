import React, { useState } from 'react';
import { Col, Link, Row, Text } from 'components/_main';
import { Radio, RadioGroup } from 'components/Radio';
import InviteHelpersTab from './components/InviteHelpersTab';
import { useInviteHelpersContext } from './InviteHelpers.context';
import CustomHelpersRequestTab from './components/CustomHelpersRequestTab';
import { Tabs } from './InviteHelpers.types';
import { Form, RadioChangeEvent } from 'antd';
import PricingPlansModal from 'containers/PricingPlans';
import useOpen from 'hooks/useOpen';
import { useCtx } from 'containers/ProjectDetailsCard/ProjectDetailsCard.Context';

interface Props {
  withDescription?: boolean;
  isEditable?: boolean;
}

function InviteHelpers({ withDescription = true, isEditable }: Props) {
  const { isPremium, setIsRequestCustomHelpers } = useInviteHelpersContext();
  const [radio, setRadio] = useState<Tabs>('invite');
  const { isOpen, onOpen, onClose } = useOpen();
  const { isReadOnly } = useCtx();

  const handleRadioChange = (value: RadioChangeEvent) => {
    const newValue = value?.target?.value as Tabs;
    if (newValue) {
      setRadio(() => {
        setIsRequestCustomHelpers(newValue);

        return newValue;
      });
    }
  };

  return (
    <>
      <Row gridGap="2rem">
        {withDescription && (
          <Row gridGap="0">
            <Text>Please add helpers to proceed with the project</Text>
            {!isPremium && (
              <Text>
                You can add up to 2 helpers to this project in your plan.{' '}
                <Link onClick={onOpen}>Upgrade Plan</Link>
              </Text>
            )}
          </Row>
        )}

        {isReadOnly ? (
          <InviteHelpersTab isEditable={false} />
        ) : (
          <>
            <RadioGroup defaultValue={radio} onChange={handleRadioChange}>
              <Col>
                <Radio value="invite">
                  <Text>Invite Your Teammates</Text>
                </Radio>
                <Radio value="request">
                  <Text>Request Helpers</Text>
                </Radio>
              </Col>
            </RadioGroup>
            {radio === 'invite' && <InviteHelpersTab isEditable={isEditable} />}
            {radio === 'request' && <CustomHelpersRequestTab />}
          </>
        )}
      </Row>
      <PricingPlansModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export default InviteHelpers;
