import { gql } from '@apollo/client';

export const HELPERS_LIST = gql`
  query Helpers($page: Int, $returnAllData: Boolean) {
    helpers(returnAllData: $returnAllData, page: $page) {
      pagesCount
      helpers {
        activated
        email
        firstName
        id
        isDeleted
        lastName
        enabled
        assignedToProjectAsAuditor
        assignedToProjectAsHelper
      }
    }
  }
`;

export const GET_HELPER_BY_ID = gql`
  query GetHelperById($helperId: Int!) {
    helper(helperId: $helperId) {
      assignedToProjectAsAuditor
      assignedToProjectAsHelper
      activated
      email
      enabled
      firstName
      id
      isDeleted
      lastName
    }
  }
`;

export const GET_ASSIGNED_HELPERS_LIST = gql`
  query GetAssignedHelpersList($projectId: Int) {
    helpers(active: true, projectId: $projectId, returnAllData: true) {
      pagesCount
      helpers {
        activated
        email
        firstName
        id
        isDeleted
        lastName
        enabled
        assignedToProjectAsAuditor
        assignedToProjectAsHelper
      }
    }
  }
`;

export const REPORTS_GET_HELPER_STATS_BY_PRJ_ID = gql`
  query ReportsGetHelpersStatsByPrjId($projectId: Int!) {
    helpersStatsByProject(projectId: $projectId, responseFormat: JSON) {
      JSON {
        firstName
        helperPoints
        helperRate
        auditorPoints
        id
        auditorPoints
        auditorRate
        lastName
        penalties
        auditorTasksCount
      }
      CSV
    }
  }
`;

export const REPORTS_GET_HELPER_STATS_BY_PRJ_ID_CSV = gql`
  query ReportsGetHelpersStatsByPrjIdCsv($projectId: Int!) {
    helpersStatsByProject(projectId: $projectId, responseFormat: CSV) {
      CSV
    }
  }
`;

export const GET_LIST_OF_HELPERS = gql`
  query GetListOfHelpers($projectId: Int) {
    getListOfHelpers(projectId: $projectId) {
      invited {
        lastName
        firstName
        email
      }
      requested {
        lastName
        firstName
        email
      }
      pending {
        lastName
        firstName
        email
      }
    }
  }
`;

export const GET_LEARNING_PROGRESS = gql`
  query GetLearningProgress {
    getLearningProgress {
      id
      lessons {
        id
      }
    }
  }
`;

export const GET_ALL_TIME_STATS = gql`
  query GetAllTimeStats {
    getHelperStatistic {
      dailyStat {
        projectDone
        taskCompleted
        taskReturned
        totalEarned
      }
      projectDone
      taskCompleted
      taskReturned
      totalEarned
    }
  }
`;

export const GET_LEARNING_MATERIALS_STRUCTURE = gql`
  query GetLearningMaterialsStructure {
    getLearningMaterialsStructure {
      topics {
        id
        title
        lessons {
          id
          title
          isDone
        }
      }
    }
  }
`;

export const GET_LESSON_LEARNING_MATERIAL = gql`
  query GetLessonLearningMaterial(
    $helperId: Int!
    $lessonId: Int!
    $topicId: Int!
  ) {
    getLessonLearningMaterial(
      helperId: $helperId
      lessonId: $lessonId
      topicId: $topicId
    ) {
      description
      images
      practice {
        id
        title
        avgResult
      }
    }
  }
`;

export const START_PRACTICE_SESSION = gql`
  query StartPracticeSession(
    $lessonId: Int!
    $practiceId: Int!
    $topicId: Int!
  ) {
    startPracticSession(
      lessonId: $lessonId
      practiceId: $practiceId
      topicId: $topicId
    ) {
      task {
        type
        mediaFileName
        mediaUrl
        settings {
          chunkLength
          subType
          annotationType
        }
        labels
        videoStart
        videoEnd
        videoFPS
        videoFrameCount
        videoChunkLength
      }
      sessionEndDate
      totalSessionSize
    }
  }
`;
