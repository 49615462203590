import { useCallback, useEffect, useMemo, useState } from 'react';
import consoleError from '../utils/consoleError';

interface Options {
  preserve?: boolean;
}

function useLocalStorage<T>(key: string, initialValue: T, options?: Options) {
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      consoleError(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((val: T | undefined) => T | undefined)) => {
      setStoredValue((prevValue) => {
        try {
          const valueToStore =
            value instanceof Function ? value(prevValue) : value;
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
          return valueToStore;
        } catch (error) {
          consoleError(error);
          return undefined;
        }
      });
    },
    [key]
  );

  useEffect(() => {
    if (options?.preserve) {
      return;
    }
    setStoredValue(initialValue);
    return () => window.localStorage.removeItem(key);
  }, [key, initialValue, options?.preserve]);

  return useMemo(
    () => [storedValue, setValue] as const,
    [setValue, storedValue]
  );
}

export default useLocalStorage;
