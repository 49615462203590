import gql from 'graphql-tag';
import { CLIENT_DETAIL_FILED } from '../fragments/client';

export const LIST_CLIENTS = gql`
  ${CLIENT_DETAIL_FILED}
  query ListClients($helperId: Int, $page: Int, $returnAllData: Boolean) {
    listClients(
      helperId: $helperId
      page: $page
      returnAllData: $returnAllData
    ) {
      pagesCount
      clients {
        ...ClientDetailsFields
      }
    }
  }
`;

export const CLIENT_CURRENT_TASKS_STATE_REPORT = gql`
  query ClientCurrentTasksStateReport(
    $clientId: Int!
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $projectId: Int
  ) {
    clientProjectsReport(
      responseFormat: JSON
      projectId: $projectId
      dateFrom: $dateFrom
      clientId: $clientId
      dateTo: $dateTo
    ) {
      JSON {
        helperRate
        client {
          companyName
        }
        id
        labels
        newTasksCount
        startedTasksCount
        inAuditTasksCount
        rejectedTasksCount
        completedTasksCount
        totalTasksCount
        title
        type
        auditorRate
        auditorsCount
        workPoints
        updatedAt
        createdAt
      }
    }
  }
`;

export const CLIENT_CURRENT_TASKS_STATE_REPORT_CSV = gql`
  query ClientCurrentTasksStateReportCsv(
    $clientId: Int!
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $projectId: Int
  ) {
    clientProjectsReport(
      responseFormat: CSV
      projectId: $projectId
      dateFrom: $dateFrom
      clientId: $clientId
      dateTo: $dateTo
    ) {
      CSV
    }
  }
`;
