import React, { PropsWithChildren } from 'react';
import { AnnotationSystemContextProps } from './AnnotationSystem.types';
import ContextProvider from './context';

export default function AnnotationSystemContext({
  children,
  ...restProps
}: PropsWithChildren<AnnotationSystemContextProps>) {
  return (
    <>
      <ContextProvider {...restProps}>{children}</ContextProvider>
    </>
  );
}
