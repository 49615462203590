import React, { useRef, useEffect } from 'react';
import { Row, Col, Button, Text, Box } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import LessonPracticeBlock from 'containers/LearningMaterials/LessonPages/LessonPracticeBlock';
import { Body } from './LessonPages.styles';
import useGetLessonMaterials from 'containers/LearningMaterials/hooks/useGetLessonMaterials';
import SpinnerLoader from 'components/SpinnerLoader';

function LessonPageContent() {
  const theme = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    lessonMaterialData,
    isFirstLesson,
    isLastUnreadLesson,
    isFinished,
    isLoading,
    handlePrevLesson,
    handleNextLesson
  } = useGetLessonMaterials();

  useEffect(() => {
    wrapperRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [lessonMaterialData]);

  if (!lessonMaterialData) {
    return null;
  }

  const {
    id,
    key,
    topicId,
    topicTitle,
    title,
    description,
    practice,
    images,
    isDone
  } = lessonMaterialData;

  if (isLoading) {
    return (
      <Box grid flexCenter height="100%">
        <Box width="68px" height="68px" p="10px">
          <SpinnerLoader />
        </Box>
      </Box>
    );
  }

  return (
    <Row
      ref={wrapperRef}
      gridGap="30px"
      padding="30px"
      height="100%"
      overflowY="auto"
      alignItems="flex-start"
      justifyItems="center"
      gridTemplateRows="1fr max-content"
    >
      <Row gridGap="30px" width="580px">
        <Row gridGap="10px">
          <Text variant="ui-1-regular" colorVariant="light">
            Section {topicId}: {topicTitle}
          </Text>
          <Text variant="header-2">
            <Text variant="header-2" colorVariant="primary" paddingRight="8px">
              Lesson {key}:
            </Text>
            {title}
          </Text>
        </Row>
        <Body
          dangerouslySetInnerHTML={{
            __html: `${description}`
          }}
        />
      </Row>
      {images?.map((src: string) => (
        <img key={src} src={src} alt="annotation" style={{ width: '580px' }} />
      ))}
      {practice && practice?.length > 0 && (
        <LessonPracticeBlock data={practice} />
      )}
      {!isFinished && (
        <Col
          width="100%"
          displayFlex
          justifyContent="space-between"
          paddingTop="30px"
          borderTop={`1px solid ${theme.colors.lighter}`}
        >
          <Button
            variant="outlined"
            disabled={isFirstLesson}
            onClick={() => handlePrevLesson(topicId, id)}
          >
            prev lesson
          </Button>
          <Button onClick={() => handleNextLesson(topicId, id)}>
            {isLastUnreadLesson && !isDone ? 'Finished' : 'Next lesson'}
          </Button>
        </Col>
      )}
    </Row>
  );
}

export default LessonPageContent;
