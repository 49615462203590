import { GoogleDriveFile } from '../AddProjectWizard/AddProjectWizard.types';
import { FileState } from './UploadFiles.types';

export function convertToFileStates(
  fileNamesProp?:
    | string
    | string[]
    | FileState[]
    | File[]
    | null
    | GoogleDriveFile[]
): FileState[] {
  let fileNames = fileNamesProp;

  if (!fileNames || (Array.isArray(fileNames) && fileNames.length === 0)) {
    return [];
  }

  /** Case: string */
  if (typeof fileNames === 'string') {
    return [
      {
        fileName: fileNames,
        state: 'uploaded'
      }
    ];
  }

  /** Case: Array<any | null> -> any[] */
  if (Array.isArray(fileNamesProp)) {
    fileNames = (fileNamesProp as any[]).filter(Boolean);
  }

  if (fileNames.length === 0) {
    return [];
  }

  /** Case: string[] */
  if (typeof fileNames?.[0] === 'string') {
    return (fileNames as string[]).map((file) => ({
      fileName: file,
      state: 'uploaded'
    }));
  }

  /** Case: File[] */
  if (fileNames?.[0] instanceof File) {
    return (fileNames as File[]).map((file) => ({
      file,
      fileName: file.name,
      state: 'uploaded'
    }));
  }

  /** Case: GoogleDrive[] */
  if ('id' in fileNames?.[0]) {
    return (fileNames as GoogleDriveFile[]).map((file) => ({
      fileName: file.name,
      state: 'uploaded'
    }));
  }

  /** Case: FileState[] */
  return fileNames as FileState[];
}

export function convertFileStatesToFile(
  files: FileState[],
  setter?: (newFiles: File[]) => void
) {
  if (!setter) {
    return files
      .map((file) => file.file ?? new File([], file.fileName))
      .filter(Boolean) as File[];
  }

  setter(files.map((file) => file.file ?? null).filter(Boolean) as File[]);

  return [];
}
