import {
  AddProjectWizardContextState,
  WizardFormValues
} from './AddProjectWizard.types';
import { parseRate } from 'utils';
import { ProcessingType, ProjectType } from 'appTypes';
import settings from '../AnnotationSystem/AnnotationSystem.config';
import { UPLOAD_TYPES, mapProjectSpecToAnnoUnitType } from 'appConstants';
import { toast } from 'utils/toast';
import parseSubType from 'utils/parseSubType';
import { Platform } from 'generated/graphql';

interface MapFormValuesForSubmitProps {
  values: WizardFormValues;
  context: AddProjectWizardContextState;
  multipleFilenames?: string[] | null;
  isClient?: boolean;
  userId: string;
  ifSaveDraftProject?: boolean;
  authToken: string | null;
  filename?: string | null;
}

function mapFormValuesForSubmit({
  values,
  context,
  userId,
  isClient,
  multipleFilenames,
  ifSaveDraftProject,
  authToken,
  filename
}: MapFormValuesForSubmitProps) {
  const { annotationFilesUploadType } = context;

  const {
    customLabels,
    mlLabels,
    clientInstructionFileNames,
    projectSettings,
    mediaFileName,
    labels,
    annotationImages,
    clientId,
    customMessageInviteHelpersRequest,
    adminInstructionFileName,
    adminShortInstruction,
    ...restFinalValues
  } = values;

  if (userId && values && isClient) {
    values['clientId'] = parseInt(userId, 10);
  }

  let processingType: ProcessingType = ProcessingType.NONE;

  if (
    values.projectSettings?.chunkLength !== 9999 &&
    !values.projectSettings?.subType?.match('/frames')
  ) {
    processingType = ProcessingType.FILMS;
  }

  if (values.projectSettings?.subType?.match('/frames')) {
    processingType = ProcessingType.FRAMES;
  }

  let finalInput: Partial<WizardFormValues> = {
    ...restFinalValues,
    ...(!ifSaveDraftProject && {
      clientId: values.clientId ?? userId
    }),
    customLabels: values.customLabels,
    auditorRate: values.auditorRate ? parseRate(values.auditorRate) : undefined,
    helperRate: values.helperRate ? parseRate(values.helperRate) : undefined,
    clientInstructionFileNames:
      values.clientInstructionFileNames &&
      values.clientInstructionFileNames.filter(Boolean).length > 0
        ? values.clientInstructionFileNames.filter(Boolean)
        : undefined,
    labels: values.labels,
    mlLabels: values.mlLabels,
    mediaFileName,
    projectSettings: {
      chunkLength: values.projectSettings?.chunkLength ?? 9999,
      annotationType: mapProjectSpecToAnnoUnitType[context.specialization],
      processingType
    },
    platform: context.platform,
    authToken
  };

  if (multipleFilenames && multipleFilenames?.length > 0) {
    finalInput.files = [];
    finalInput.mediaFileName = [];
  }

  if (
    annotationFilesUploadType === 'googleDrive' &&
    multipleFilenames &&
    multipleFilenames?.length > 0
  ) {
    finalInput.files = multipleFilenames;
    finalInput.mediaFileName = '';
  }

  if (annotationFilesUploadType === 'googleDrive' && authToken) {
    finalInput.authToken = authToken;
  }

  /** For single video file */
  if (annotationFilesUploadType === 'single' && filename) {
    delete finalInput.authToken;
    finalInput.mediaFileName = [filename];
  }

  /** After upload ZIP, BE should return string[] with links to all files */
  if (annotationFilesUploadType === 'single' && multipleFilenames) {
    delete finalInput.authToken;
    finalInput.mediaFileName = multipleFilenames;
  }

  if (
    values.type === ProjectType.VIDEO_ANNOTATION &&
    context.platform === Platform.Own
  ) {
    delete finalInput.authToken;
  }

  if (
    values.type === ProjectType.VIDEO_ANNOTATION &&
    annotationFilesUploadType === 'multiple'
  ) {
    finalInput.mediaFileName = (multipleFilenames ?? [filename] ??
      []) as string[];
    delete finalInput.authToken;
  }

  if (
    parseSubType(values?.projectSettings?.annotationType) === 'dot' &&
    settings['dot']
  ) {
    finalInput.labels = Object.keys(settings['dot'].ids);
  }

  if (
    values.type === ProjectType.IMAGE_ANNOTATION &&
    annotationFilesUploadType === UPLOAD_TYPES.multiple
  ) {
    finalInput.annotationImages = multipleFilenames;
  }

  if (ifSaveDraftProject) {
    if (!values?.title) {
      toast.error('Please, enter title to save as draft');
      return;
    }

    delete finalInput?.clientId;
    delete finalInput?.authToken;
    delete finalInput?.adminInstructionFileName;
    delete finalInput?.adminShortInstruction;
    delete finalInput?.files;
  }

  return finalInput;
}

export default mapFormValuesForSubmit;
