import { css } from 'styled-components/macro';
import { BoxProps } from '../Box.types';

export const displayGrid = ({ grid }: BoxProps) =>
  grid &&
  css`
    display: grid;
  `;

export const maxSize = ({ maxSize }: BoxProps) =>
  maxSize &&
  css`
    width: 100%;
    height: 100%;
  `;

export const flexCenter = ({ flexCenter }: BoxProps) =>
  flexCenter &&
  css`
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
  `;

export const flexCenterInverted = ({ flexCenterInverted }: BoxProps) =>
  flexCenterInverted &&
  css`
    justify-items: center;
    align-content: center;
  `;

export const noGap = ({ noGap }: BoxProps) =>
  noGap &&
  css`
    gap: 0;
  `;

export const widthWide =
  (defaultValue: string = '100%') =>
  ({ wide, width }: BoxProps) =>
    css`
      width: ${wide ? '100%' : width ?? defaultValue};
    `;

export const horizCenter =
  (isInvert: boolean = false) =>
  ({ horizCenter, justifyContent, alignItems }: BoxProps) =>
    isInvert
      ? css`
          justify-content: ${horizCenter ? 'center' : justifyContent};
        `
      : css`
          align-items: ${horizCenter ? 'center' : alignItems};
        `;

export const vertCenter =
  (isInvert: boolean = false) =>
  ({ vertCenter, alignItems }: BoxProps) =>
    isInvert
      ? css``
      : css`
          align-items: ${vertCenter ? 'center' : alignItems};
        `;

export const displayFlex = ({ displayFlex }: BoxProps) =>
  displayFlex &&
  css`
    display: flex;
  `;

export const clickable = ({ onClick }: BoxProps) =>
  onClick &&
  css`
    cursor: pointer; ;
  `;
