import { useState, useRef, useLayoutEffect } from 'react';

function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useLayoutEffect(() => {
    const node = ref.current as unknown as HTMLElement;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }

    return;
    // eslint-disable-next-line
  }, [ref.current]);
  return [ref, value] as const;
}

export default useHover;
