import { useContext, useMemo } from 'react';
import {
  addAnnotationUnit,
  deleteAnnotationUnit,
  initAnnotationUnits,
  modifyAnnotationUnit,
  onReset,
  setCurEvent,
  setCurSelUnitId,
  setHideAnnotationUnitId,
  setCursorInitPos,
  setCurVideoStatus,
  setCurVideoTime,
  setInitSize,
  setMediaImageRef,
  setMediaLoadingFinish,
  setMediaVideoRef,
  setSvgLayerRef,
  zoomIn,
  zoomOut,
  zoomReset,
  setSvgLayerOpacityLevel,
  setCurMode,
  setEventResize,
  deleteTransitionPoint,
  modifyTransitionPoint,
  setZoomLevel,
  setPageFrames,
  onInit,
  setIsLoading,
  setAnnotationAccuracy,
  setAnnotationPracticeProgress,
  setCopyUnitId,
  addPolygonPoint,
  deletePolygonPoint,
  modifyPolygonPoint,
  setCurSelPointId,
  addAtStrokePolygonPoint,
  setPolygonPoints,
  toggleLabelSelectDropdown
} from '../reducer';
import { withDispatch } from '../reducer/utils';
import context from './context';

export default function useAnnotationSystemActions() {
  const [, dispatch] = useContext(context);

  return useMemo(() => {
    return {
      toggleLabelSelectDropdown: withDispatch(dispatch)(
        toggleLabelSelectDropdown
      ),
      onSetPolygonPoints: withDispatch(dispatch)(setPolygonPoints),
      onAddAtStrokePolygonPoint: withDispatch(dispatch)(
        addAtStrokePolygonPoint
      ),
      onSetCurSelPointId: withDispatch(dispatch)(setCurSelPointId),
      onAddPolygonPoint: withDispatch(dispatch)(addPolygonPoint),
      onModifyPolygonPoint: withDispatch(dispatch)(modifyPolygonPoint),
      onDeletePolygonPoint: withDispatch(dispatch)(deletePolygonPoint),
      onSetEventResize: withDispatch(dispatch)(setEventResize),
      onInitAnnotationUnits: withDispatch(dispatch)(initAnnotationUnits),
      onZoomIn: withDispatch(dispatch)(zoomIn),
      onZoomOut: withDispatch(dispatch)(zoomOut),
      onSetZoomLevel: withDispatch(dispatch)(setZoomLevel),
      onZoomReset: withDispatch(dispatch)(zoomReset),
      onReset: withDispatch(dispatch)(onReset),
      onSetCursorInitPos: withDispatch(dispatch)(setCursorInitPos),
      onSetCurVideoTime: withDispatch(dispatch)(setCurVideoTime),
      onSetCurEvent: withDispatch(dispatch)(setCurEvent),
      onSetInitSize: withDispatch(dispatch)(setInitSize),
      onAddAnnotationUnit: withDispatch(dispatch)(addAnnotationUnit),
      onSetCurSelUnitId: withDispatch(dispatch)(setCurSelUnitId),
      onSetHideAnnotationUnitId: withDispatch(dispatch)(
        setHideAnnotationUnitId
      ),
      onSetSvgLayerRef: withDispatch(dispatch)(setSvgLayerRef),
      onSetMediaVideoRef: withDispatch(dispatch)(setMediaVideoRef),
      onSetMediaImageRef: withDispatch(dispatch)(setMediaImageRef),
      onModifyAnnotationUnit: withDispatch(dispatch)(modifyAnnotationUnit),
      onSetCurVideoStatus: withDispatch(dispatch)(setCurVideoStatus),
      onSetMediaLoadingFinish: withDispatch(dispatch)(setMediaLoadingFinish),
      onDeleteAnnotationUnit: withDispatch(dispatch)(deleteAnnotationUnit),
      onSetSvgOpacityLevel: withDispatch(dispatch)(setSvgLayerOpacityLevel),
      onSetCurMode: withDispatch(dispatch)(setCurMode),
      onDeleteTransitionPoint: withDispatch(dispatch)(deleteTransitionPoint),
      onModifyTransitionPoint: withDispatch(dispatch)(modifyTransitionPoint),
      onSetPageFrames: withDispatch(dispatch)(setPageFrames),
      onInit: withDispatch(dispatch)(onInit),
      onSetIsLoading: withDispatch(dispatch)(setIsLoading),
      onSetAnnotationAccuracy: withDispatch(dispatch)(setAnnotationAccuracy),
      onSetAnnotationPracticeProgress: withDispatch(dispatch)(
        setAnnotationPracticeProgress
      ),
      onSetCopyUnitId: withDispatch(dispatch)(setCopyUnitId)
    };
  }, [dispatch]);
}
