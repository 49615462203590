import { PolygonPoints } from 'containers/AnnotationSystem/reducer';

export default function addAtStrokePolygonPoint(
  polyPoints: PolygonPoints,
  newCoords: [number, number]
) {
  const [x, y] = newCoords;

  let nearestIndex = -1;
  const { sqrt, pow } = Math;
  const pow2 = (int: number) => pow(int, 2);

  for (let i = 0; i < polyPoints.length; i++) {
    const nextPoint = i === polyPoints.length - 1 ? 0 : i + 1;
    const x1 = polyPoints[i][0];
    const x2 = polyPoints[nextPoint][0];
    const y1 = polyPoints[i][1];
    const y2 = polyPoints[nextPoint][1];
    const length1 = sqrt(pow2(x1 - x) + pow2(y1 - y));
    const length2 = sqrt(pow2(x - x2) + pow2(y - y2));
    const lengthBetweenPoints = sqrt(pow2(x1 - x2) + pow2(y1 - y2));

    if (
      nearestIndex === -1 ||
      (length1 + length2 >= lengthBetweenPoints - 5 &&
        length1 + length2 <= lengthBetweenPoints + 5)
    ) {
      nearestIndex = i;
    }
  }

  if (nearestIndex === -1) {
    return polyPoints;
  }

  polyPoints.splice(nearestIndex + 1, 0, [x, y]);

  return [...polyPoints];
}
