import { ColumnType } from 'antd/lib/table';
import { Project } from 'generated/graphql';
import { renderRate } from 'utils';

const columns: ColumnType<Project>[] = [
  {
    title: 'Project',
    dataIndex: 'title'
  },
  {
    title: 'Task completed',
    render: (_, record) => {
      return record?.projectStats?.[0]?.completedTasksCount || '-';
    }
  },
  {
    title: 'Task returned',
    render: (_, record) => {
      return record?.projectStats?.[0]?.returnedTasksCount || '-';
    }
  },
  {
    title: 'Earned',
    render: (_, record) => {
      return renderRate(record?.cost) || '-';
    }
  }
];

export default columns;
