import React from 'react';
import { useNavigate } from 'react-router-dom';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { Col, Box, Text } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import { HeaderAppBarMenu } from './HeaderAppBar.ui';
import { HeaderAppBarProps } from 'containers/HeaderAppBar/HeaderAppBar.types';
import { APP_BAR_HEIGHT } from 'appConstants';

const HeaderAppBar = ({
  title,
  firstName,
  lastName,
  isBack,
  backPath,
  sidePadding,
  renderNavigation
}: HeaderAppBarProps) => {
  const {
    lastName: lastNameSession,
    firstName: firstNameSession,
    companyName
  } = useGetUserSessionData();

  const theme = useTheme();

  return (
    <Box
      data-testid="header-app-bar"
      displayFlex
      justifyContent="space-between"
      alignItems="center"
      bg={theme.colors.lightest}
      padding={`0 ${sidePadding ? sidePadding : '1.5rem'}`}
      height={APP_BAR_HEIGHT}
      position="sticky"
      top="0"
      zIndex="1"
    >
      <Col
        width="100%"
        alignItems="center"
        gridGap="1rem"
        gridAutoColumns="1fr max-content"
      >
        {!renderNavigation && (
          <Text variant="ui-1-bold">{title ?? companyName}</Text>
        )}
        {renderNavigation && renderNavigation}
        <Col alignItems="center" gridGap="2rem" css="cursor: pointer">
          <HeaderAppBarMenu
            firstName={firstNameSession ?? firstName}
            lastName={lastNameSession ?? lastName}
          />
        </Col>
      </Col>
    </Box>
  );
};

export default HeaderAppBar;
