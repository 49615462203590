import theme from 'theme';

import {
  AnnotationUnitCoords,
  AnnotationUnitProps
} from './AnnotationSystem.types';

export const ANNOTATION_BOX_POS_INIT: AnnotationUnitCoords = {
  h: 0,
  x: 0,
  w: 0,
  y: 0
};

export const EMPTY_ARRAY = [];
export const EMPTY_FUNC = () => null;
export const MIN_DELTA_SIZE = 0;
export const SINGLE_SAVE_STORAGE_KEY = 'annotationSaved';

export const VIDEO_STEP = 1 / 58;

export const UNIT_COLORS = [
  theme.colors.yellow,
  theme.colors.blue,
  theme.colors.purple,
  theme.colors.red,
  theme.colors.orange,
  theme.colors.greenLight,
  theme.colors.primary
];

export const prevWrapperSizeInit = {
  w: 0,
  h: 0
};

export const MAX_ZOOM = 4;
export const MIN_ZOOM = 1;

export const CONTENT_MODERATION_UNIT_INIT: AnnotationUnitProps = {
  unitId: '1',
  reasons: [],
  status: 'valid',
  type: 'contentModeration',
  label: '',
  isEnd: false,
  ...ANNOTATION_BOX_POS_INIT
};

export const POLYGON_UNIT_INIT: AnnotationUnitProps = {
  unitId: '1',
  label: '',
  type: 'polygon',
  polygonPoints: [],
  isLabelDropdownOpen: false,
  isEnd: false,
  ...ANNOTATION_BOX_POS_INIT
};

export const DEFAULT_COPY_SHIFT = 50;

export const CUR_FPS = 30;
