import { consoleError, consoleLog, varEnv } from '../../utils';
import _ from 'lodash';
import { MutableRefObject } from 'react';

interface HandleUploadGenericProps {
  abortController: MutableRefObject<AbortController>;
  resolvePath: string;
  uploadMutation: Promise<any>;
}

export default function handleUploadGeneric({
  uploadMutation,
  resolvePath,
  abortController
}: HandleUploadGenericProps): Promise<string> {
  return new Promise((resolve, reject) => {
    uploadMutation
      .then((result) => {
        if (abortController.current.signal.aborted) {
          consoleError('Upload aborted');
          throw new Error('Upload aborted');
        }
        const fileNameFromBackend: string = _.get(result, resolvePath);
        varEnv.isSb &&
          consoleLog('Finish getting filename from S3, ' + fileNameFromBackend);
        resolve(fileNameFromBackend);
      })
      .catch((e) => {
        e?.message && consoleError(e.message);
        reject(e);
      });
  });
}
