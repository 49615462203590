import React from 'react';
import { useField } from 'formik';
import { Row } from '../../../../components/_main';
import {
  UploadFiles,
  UploadFilesContextProviderProps,
  UploadOnError
} from '../../../UploadFiles';
import FormikError from '../../../../components/_form/FormikError';
import { toast } from 'utils/toast';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { isAdmin } from 'utils';
import { useAddProjectWizardContext } from 'containers/AddProjectWizard/AddProjectWizard.context';

interface StepUploadDatasetZipSingleDataProviderProps {
  mediaFileNameName?: string;
  accept?: string;
}

const maxSize = 500_000_000;

function StepUploadDatasetSingleDataProvider({
  mediaFileNameName = 'mediaFileName',
  accept = '.zip'
}: StepUploadDatasetZipSingleDataProviderProps) {
  const { onSetMediaFile } = useAddProjectWizardContext();

  const [, , helper] = useField(mediaFileNameName);

  const handleError: UploadOnError = (_, error) => {
    if (!error) return;
    error && toast.error(error);
    helper.setError(error);
    helper.setTouched(true);
  };

  const { isPremium } = useGetUserSessionData();

  let uploadSizeLimit = isPremium ? null : maxSize;

  if (isAdmin()) {
    uploadSizeLimit = null;
  }

  return (
    <Row>
      <StepUploadDatasetSingle
        initialData={{
          onDelete: () => {
            onSetMediaFile(null);
          },
          onFilesUpdate: () => {
            helper.setError(undefined);
          },
          onSuccess: ({ files }) => {
            onSetMediaFile(files[0]);
          },
          onError: handleError,
          maxTotalSize: uploadSizeLimit
        }}
        uploaderProps={{
          uploadFilesBlock: null,
          accept: accept,
          maxFiles: 1
        }}
      />
      <FormikError name={mediaFileNameName} />
    </Row>
  );
}

function StepUploadDatasetSingle(props: UploadFilesContextProviderProps) {
  return (
    <Row>
      <UploadFiles {...props} />
    </Row>
  );
}

export default StepUploadDatasetSingleDataProvider;
