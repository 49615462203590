import React from 'react';
import { useTheme } from 'styled-components/macro';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Box, Text } from 'components/_main';
import { Tab, Tabs } from 'components/Tabs/Tabs.styled';
import SidebarFoldable from 'components/SidebarFoldable';
import HeaderAppBar from 'containers/HeaderAppBar';
import LessonTabContent from './LessonPages/LessonTabContent';
import CertificationTabContent from './CertificationPage/CertificationTabContent';
import LessonPageContent from 'containers/LearningMaterials/LessonPages';
import CertificationPage from 'containers/LearningMaterials/CertificationPage';
import { LearningTabType } from 'containers/LearningMaterials/LearningMaterial.constants';
import { LearningMaterialsDataProvider } from 'containers/LearningMaterials/hooks/useLearningMaterialDataProvider';

const LearningLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const theme = useTheme();

  return (
    <Box
      data-component="help-center"
      display="grid"
      gridGap="0px"
      height="100vh"
      overflow="hidden"
      backgroundColor={theme.colors.bg}
      gridTemplateRows="max-content 1fr"
    >
      <HeaderAppBar
        renderNavigation={<Text variant="ui-1-bold">Learning Materials</Text>}
      />
      <Col
        width="100%"
        height="100%"
        overflow="hidden"
        gridTemplateRows="1fr auto"
        gridTemplateColumns="max-content 1fr"
      >
        {children}
      </Col>
    </Box>
  );
};

function LearningMaterials() {
  const history = useNavigate();
  const { curPage } = useParams();

  let page = <LessonPageContent />;
  if (curPage === LearningTabType.Certification) {
    page = <CertificationPage />;
  }

  const handleSelectTab = (tabKey: string) => {
    history(`/learning/${tabKey}`);
  };

  return (
    <LearningLayout>
      <LearningMaterialsDataProvider>
        <SidebarFoldable
          isRightPosition={false}
          gridRow="1 / 3"
          gridColumn="1 / 2"
          padding="0 15px"
        >
          <Box
            display="grid"
            minWidth="330px"
            height="100%"
            gridAutoRows="1fr"
            alignItems="flex-start"
          >
            <Tabs
              mini
              activeKey={curPage}
              defaultActiveKey={LearningTabType.Lessons}
              onChange={handleSelectTab}
            >
              <Tab
                data-testid="tab-lessons"
                tab="Lessons"
                key={LearningTabType.Lessons}
              >
                <LessonTabContent />
              </Tab>
              <Tab
                data-testid="tab-certification"
                tab="Certification"
                key={LearningTabType.Certification}
              >
                <CertificationTabContent />
              </Tab>
            </Tabs>
          </Box>
        </SidebarFoldable>
        <Box gridRow="1 / 3" gridColumn="2 / 3">
          {page}
        </Box>
      </LearningMaterialsDataProvider>
    </LearningLayout>
  );
}

export default LearningMaterials;
