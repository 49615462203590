interface Props {
  unitId: string;
}

interface UpdateProps {
  newX?: number;
  newY?: number;
}

export default function useConnectionLinesUpdate({ unitId }: Props) {
  const linesToModifyStarts = document.querySelectorAll(
    `.skeletonLine[data-id-from="${unitId}"]`
  );
  const linesToModifyEnds = document.querySelectorAll(
    `.skeletonLine[data-id-to="${unitId}"]`
  );

  const onUpdate = ({ newX, newY }: UpdateProps) => {
    if (!newX || !newY) return;

    linesToModifyStarts.forEach((el) => {
      el.setAttribute('x1', newX.toString());
      el.setAttribute('y1', newY.toString());
    });
    linesToModifyEnds.forEach((el) => {
      el.setAttribute('x2', newX.toString());
      el.setAttribute('y2', newY.toString());
    });
  };

  return onUpdate;
}
