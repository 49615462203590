import React, { useMemo } from 'react';
import { Button, Col, Row, Text, Box } from 'components/_main';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { ProjectStatus } from 'generated/graphql';
import columnsClient from 'containers/Projects/ProjectsClient/columns.client';
import {
  ProjectDetailsCardDataProvider,
  ProjectDetailsCardDataWrapperWithCloneElement,
  ProjectDetailsCardModal
} from 'containers/ProjectDetailsCard';
import CopyEnterNewNameModal from 'containers/CopyEnterNewNameModal';
import { AddProjectWizard } from 'containers/AddProjectWizard';
import { mapProjectDataToWizard } from 'containers/AddProjectWizard/AddProjectWizard.utils';
import { upper } from 'utils';
import CautionPlateResendActivationLink from 'containers/CautionPlateResendActivationLink';
import CautionPlate from 'components/CautionPlate';
import PricingPlansModal from 'containers/PricingPlans';
import useClientProject from 'containers/Projects/hooks/useClientProject';
import useGetListProjects from 'containers/Projects/hooks/useGetListProject';
import ProjectsTable from 'containers/Projects/ProjectsTable';
import { useProjectsContext } from 'containers/Projects/Projects.context';
import useRedirect from 'hooks/useRedirect';

const ProjectsClient = () => {
  const userData = useGetUserSessionData();
  const redirect = useRedirect();
  const { currentProjectData, setCurrentProjectData } = useProjectsContext();
  const { page, sortMethod, ...projectListProps } = useGetListProjects();
  const {
    isProjectDetailsOpen,
    isCreateProjectOpen,
    isRenameProjectOpen,
    isPricingPlansOpen,
    handleProjectDetailsOpen,
    handleProjectDetailsClose,
    onCreateProjectClose,
    onPricingPlansClose,
    onProjectRenameOk,
    onProjectRenameCancel,
    handlePricingPlansUpgrade,
    handleProjectRenameOpen
  } = useClientProject({
    currentProjectData,
    page,
    sortMethod,
    setCurrentProjectData
  });

  const columnMemo = useMemo(
    () =>
      columnsClient({
        activeProjectId: currentProjectData?.id,
        onEdit: handleProjectDetailsOpen,
        onCopyProject: handleProjectRenameOpen
      }),
    [currentProjectData, handleProjectDetailsOpen, handleProjectRenameOpen]
  );

  const isBlockedAddProject =
    !userData.isPremium && projectListProps.listProjects.length >= 2;
  const isDraftProject = currentProjectData?.status === ProjectStatus.Draft;

  const handleAddProjectClick = () => {
    redirect('/microtasking');
  };

  return (
    <>
      {!userData.activated && (
        <Row flexCenter width="100%">
          <CautionPlateResendActivationLink />
        </Row>
      )}
      <Col width="100%" justifyContent="space-between">
        <Text variant="ui-4-bold">Projects</Text>
        {userData.enabled && (
          <Button
            disabled={isBlockedAddProject}
            onClick={handleAddProjectClick}
          >
            {upper('Add Project')}
          </Button>
        )}
      </Col>
      <ProjectsTable columns={columnMemo} page={page} {...projectListProps} />
      {isBlockedAddProject && (
        <Box displayFlex flexCenter padding="30px 0">
          <CautionPlate
            contentText="Oops. It seems like you have added
                two projects to your freemium account. Please upgrade your account
                to unlock the full potential of the Taskware platform."
            size="normal"
            actionTitle="upgrade plan"
            onActionButton={handlePricingPlansUpgrade}
          />
        </Box>
      )}
      {!isDraftProject && (
        <ProjectDetailsCardDataProvider
          projectId={currentProjectData?.id}
          key={`edit-project-${currentProjectData?.id}`}
        >
          <ProjectDetailsCardDataWrapperWithCloneElement>
            <ProjectDetailsCardModal
              isOpen={isProjectDetailsOpen}
              onCancel={handleProjectDetailsClose}
            />
          </ProjectDetailsCardDataWrapperWithCloneElement>
        </ProjectDetailsCardDataProvider>
      )}
      {isDraftProject || !currentProjectData?.id ? (
        <AddProjectWizard
          key={`create-or-edit-draft-project`}
          initialFormData={
            currentProjectData?.id
              ? mapProjectDataToWizard(currentProjectData)
              : {}
          }
          isOpen={isCreateProjectOpen}
          onClose={onCreateProjectClose}
        />
      ) : null}
      <CopyEnterNewNameModal
        key={`copy-project-${currentProjectData?.id}`}
        data={currentProjectData}
        onOk={onProjectRenameOk}
        onCancel={onProjectRenameCancel}
        isVisible={isRenameProjectOpen}
      />
      <PricingPlansModal
        key="upgrade-plan"
        isOpen={isPricingPlansOpen}
        onClose={onPricingPlansClose}
      />
    </>
  );
};

export default ProjectsClient;
