import React from 'react';
import { Text } from 'components/_main';

function activeStatusColumn(data: any) {
  switch (true) {
    case data.isDeleted:
      return <Text color="secondary">💀 Deleted</Text>;
    case !data.enabled:
      return <Text>⛔ Disabled</Text>;
    case data.activated:
      return data.activated && <Text>✅ Activated</Text>;
  }

  return <Text>☑️ Not activated</Text>;
}

export default activeStatusColumn;
