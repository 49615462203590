export function arrayModify<T>(
  arr: T[],
  newItem: Partial<T>,
  key: keyof T
): T[] {
  return arr.map((el) =>
    el[key] === newItem[key] ? { ...el, ...newItem } : el
  );
}

export default arrayModify;
