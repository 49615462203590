export enum LearningTabType {
  Lessons = 'lessons',
  Certification = 'certification'
}

export const certificationTypes = [
  'Basic Helper',
  'Advanced Helper',
  'Super Helper'
];
