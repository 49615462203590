import React, { forwardRef } from 'react';
import { Progress } from 'antd';
import { Text } from 'components/_main';
import { ReactComponent as CloseIcon } from './close.svg';
import { IFileInputProps } from './types';
import Icon from 'components/Icon';

const ControlledProgressBar = forwardRef<typeof Progress, IFileInputProps>(
  ({ loading, value, handleOnStopUpload }, ref) => {
    return (
      <div className="file-upload-progress">
        <>
          {value && (
            <div className="upload-progress-info">
              <Text
                variant="ui-1-bold"
                colorVariant="primary"
                maxHeight="50px"
                breakAll
              >
                {!loading && value}
              </Text>
            </div>
          )}
          {loading && (
            <div className="!filename">
              <div
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  justifyContent: 'center',
                  gap: '1rem',
                  width: '100%',
                  gridAutoColumns: '1fr max-content',
                  maxHeight: '50px'
                }}
              >
                <span
                  style={{
                    maxHeight: '50px'
                  }}
                >
                  Uploading the file...
                </span>
                <Icon
                  hoverStrokeColor="fg"
                  size={24}
                  onClick={handleOnStopUpload}
                >
                  <CloseIcon />
                </Icon>
              </div>
              <Progress
                percent={100}
                status="active"
                showInfo={false}
                strokeColor="#11d3b9"
              />
            </div>
          )}
        </>
      </div>
    );
  }
);

export default ControlledProgressBar;
