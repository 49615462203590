import { Box, BoxProps } from 'components/_main';
import React, { PropsWithChildren } from 'react';
import { createGlobalStyle } from 'styled-components/macro';
import { Z_INDEXES } from '../../appConstants';

export function Layout({
  children,
  ...restProps
}: PropsWithChildren<BoxProps>) {
  return (
    <Box
      display="grid"
      gridTemplateAreas='
        "header header"
        "sidebar main"
      '
      {...restProps}
    >
      {children}
    </Box>
  );
}

export const Styles = createGlobalStyle`
  .ant-drawer.ant-drawer-right.ant-drawer-open {
    z-index: ${Z_INDEXES.addProjectWiz};
  }

  .ant-drawer-body {
    padding: 0;
  }
`;
