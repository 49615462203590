import { OnResizeMouseDownProps } from './types';

/** Move */

/** Resize */

export function onResizeMouseDown({
  groupIndex,
  w,
  h,
  x,
  y
}: OnResizeMouseDownProps) {
  if (!groupIndex) return;

  const dots = document.querySelectorAll(
    `[data-group-index="${groupIndex}"][data-type='dot']`
  );

  if (!dots) return;

  for (const dot of Array.from(dots as unknown as HTMLElement[])) {
    const dotInnerX = parseInt(dot.getAttribute('x') ?? '0', 10) - x;
    const dotInnerY = parseInt(dot.getAttribute('y') ?? '0', 10) - y;
    dot.dataset.relPercentX = `${dotInnerX > 0 ? (dotInnerX * 100) / w : 0}`;
    dot.dataset.relPercentY = `${dotInnerY > 0 ? (dotInnerY * 100) / h : 0}`;
  }
}

export function initStartPos(element?: SVGSVGElement | null) {
  if (!element) return;
  element.dataset.startPosX = element.getAttribute('x') ?? '0';
  element.dataset.startPosY = element.getAttribute('y') ?? '0';
  element.dataset.startPosW = element.getAttribute('width') ?? '0';
  element.dataset.startPosH = element.getAttribute('height') ?? '0';
}
