import styled from 'styled-components/macro';

export const SvgRoot = styled.svg`
  overflow: visible;
`;

export const Foreign = styled.foreignObject`
  overflow: visible;
`;

export const Rect = styled.rect`
  stroke-dasharray: 5 5;
  stroke: ${({ theme }) => theme.colors.primary};
  stroke-width: 2px;
  fill: transparent;
`;
