import { InvitedHelper } from 'appTypes';
import CautionPlate from 'components/CautionPlate';
import { Button, ButtonIcon, Col, Row, Text } from 'components/_main';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import {
  consoleError,
  emailValidationField,
  nameValidationField,
  stateArrayAdd,
  stateArrayRemove
} from 'utils';
import {
  ANIMATION_TRANS_TIME,
  TRANSITION_GROUP_WRAPPER_ID
} from '../InviteHelpers.constants';
import { useInviteHelpersContext } from '../InviteHelpers.context';
import { FromValues } from '../InviteHelpers.types';
import { GlobalStyle, TransitionGroupStyled } from '../InviteHelpers.ui';
import * as yup from 'yup';
import arrayIsElExist from 'utils/arrayIsElExist';
import { InputLabelField } from 'components/_form';
import InviteHelpersModal from './InviteHelpersModal';
import HelperCard from './HelperCard';
import { ReactComponent as TrashIcon } from '../trash.svg';
import { CSSTransition } from 'react-transition-group';
import { toast } from 'utils/toast';
import Icon from 'components/Icon';

interface InviteHelpersTabProps {
  isEditable?: boolean;
}

function InviteHelpersTab({ isEditable = true }: InviteHelpersTabProps) {
  const {
    selectedHelpers,
    setSelectedHelpers,
    isAllowToInviteMore,
    onSendInvite,
    allowAddTeammates,
    allowRemoveHelpers
  } = useInviteHelpersContext();

  const handleSendInvite = async (
    values: FromValues,
    formikHelper: FormikHelpers<FromValues>
  ) => {
    try {
      if (onSendInvite) {
        await onSendInvite(values.email, values.firstName, values.lastName);
      }

      toast.success('Invent sent successfully');

      setSelectedHelpers(
        stateArrayAdd({
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          isPending: true
        } as InvitedHelper)
      );

      formikHelper?.resetForm();
      const el = document.getElementById(TRANSITION_GROUP_WRAPPER_ID);
      if (!el) return;
      const computedH = el.getBoundingClientRect().height;
      const computedBottom = el.getBoundingClientRect().bottom;
      el.scrollTop = computedH + computedBottom;
    } catch (error) {
      consoleError({
        message: 'Error during sending invite',
        email: values.email,
        error
      });
    }
  };

  const handleRemove = (helper: InvitedHelper) => () => {
    setSelectedHelpers(stateArrayRemove(helper, 'email'));
  };

  return (
    <Row gridGap="2rem">
      <GlobalStyle />
      {!isAllowToInviteMore && (
        <CautionPlate contentText="Maximum amount of helpers already added to the project" />
      )}
      {isAllowToInviteMore && isEditable && (
        <Row>
          <Formik<FromValues>
            initialValues={{
              firstName: '',
              lastName: '',
              email: ''
            }}
            validationSchema={yup.object({
              email: emailValidationField.test(
                'uniq',
                'Email shoud be uniq',
                (value) => {
                  if (
                    arrayIsElExist<Pick<InvitedHelper, 'email'>>(
                      selectedHelpers,
                      { email: value ?? '' },
                      'email'
                    )
                  ) {
                    return false;
                  }
                  return true;
                }
              ),
              firstName: nameValidationField,
              lastName: nameValidationField
            })}
            onSubmit={handleSendInvite}
          >
            <Form>
              <Col width="100%" gridTemplateColumns="1fr 1fr 1fr max-content">
                <InputLabelField
                  placeholder="Email"
                  disabled={!isAllowToInviteMore}
                  name="email"
                />
                <InputLabelField
                  disabled={!isAllowToInviteMore}
                  name="firstName"
                  placeholder="First Name"
                />
                <InputLabelField
                  disabled={!isAllowToInviteMore}
                  name="lastName"
                  placeholder="Last Name"
                />
                <Button disabled={!isAllowToInviteMore} htmlType="submit">
                  Send invite
                </Button>
              </Col>
            </Form>
          </Formik>
        </Row>
      )}
      {allowAddTeammates && <InviteHelpersModal />}
      {!!selectedHelpers?.length ? (
        <Row>
          <Text variant="ui-1-bold" upper>
            Invited teammates
          </Text>
          <TransitionGroupStyled id={TRANSITION_GROUP_WRAPPER_ID}>
            {selectedHelpers.map((helper) => {
              return (
                <CSSTransition
                  key={helper.email}
                  timeout={ANIMATION_TRANS_TIME}
                  classNames="item"
                >
                  <HelperCard
                    key={helper.email}
                    lastName={helper.lastName}
                    firstName={helper.firstName}
                    email={helper.email}
                    isPending={helper.isPending}
                    rightPart={
                      allowRemoveHelpers ? (
                        <Icon
                          onClick={handleRemove(helper)}
                          hoverStrokeColor="fg"
                          size={22}
                        >
                          <TrashIcon />
                        </Icon>
                      ) : null
                    }
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroupStyled>
        </Row>
      ) : !isEditable ? (
        <Text colorVariant="light">You don't have invited teammates.</Text>
      ) : null}
    </Row>
  );
}

export default InviteHelpersTab;
