import { useState } from 'react';
import { useProjectTitleUniqLazyQuery } from 'generated/graphql';
import { useDebouncedCallback } from 'use-debounce';
import { useProjectsContext } from 'containers/Projects/Projects.context';

interface Props {
  isLoading: boolean;
  handleDuplicationValidation(value: string): void;
}

const useTitleDuplicationValidation = (clientId?: number | null): Props => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentProjectData } = useProjectsContext();

  const [isProjectTitleUniq] = useProjectTitleUniqLazyQuery({
    fetchPolicy: 'no-cache'
  });
  const isProjectTitleUniqDebounced = useDebouncedCallback(
    isProjectTitleUniq,
    300
  );

  const handleDuplicationValidation = async (value: string) => {
    if (!clientId || currentProjectData.title === value) return '';
    try {
      setIsLoading(true);
      const response = await isProjectTitleUniqDebounced({
        variables: {
          clientId,
          title: value
        }
      });
      if (
        response?.error?.message &&
        (response?.error?.message).match(/Project title is already in use/)
      ) {
        return 'Project with such title already exists. Please, enter the new one';
      }
    } finally {
      setIsLoading(false);
    }
    return '';
  };

  return { handleDuplicationValidation, isLoading };
};

export default useTitleDuplicationValidation;
