import React from 'react';
import {
  ProjectDetailsCardDataProviderProps,
  ProjectDetailsCardModalProps
} from './types';
import { CtxProvider, useCtx } from './ProjectDetailsCard.Context';

export function ProjectDetailsCardDataWrapper({
  children: Component,
  ...restProps
}: Omit<ProjectDetailsCardModalProps, 'projectData'> & {
  children: React.FunctionComponent<Partial<ProjectDetailsCardModalProps>>;
}) {
  const { projectData } = useCtx();

  return projectData ? (
    <Component projectData={projectData} {...restProps} />
  ) : null;
}

export function ProjectDetailsCardDataProvider({
  projectId,
  children,
  ...restProps
}: React.PropsWithChildren<Partial<ProjectDetailsCardDataProviderProps>>) {
  return projectId ? (
    <CtxProvider projectId={projectId} {...restProps}>
      {children}
    </CtxProvider>
  ) : null;
}

export function ProjectDetailsCardDataWrapperWithCloneElement({
  children,
  ...restProps
}: React.PropsWithChildren<Partial<ProjectDetailsCardDataProviderProps>>) {
  const contextData = useCtx();

  if (!React.isValidElement(children)) {
    return null;
  }

  return React.cloneElement(children, {
    ...contextData,
    ...restProps,
    ...children.props
  });
}

export default ProjectDetailsCardDataProvider;
