import { useState, MouseEvent } from 'react';

interface Props {
  initial?: boolean;
  onClose?: () => void;
  onOpen?: (event?: MouseEvent) => void;
  onToggle?: (state?: boolean) => void;
}

function useOpen(
  { initial, onOpen, onToggle, onClose }: Props = {
    initial: false
  }
) {
  const [isOpen, setIsOpen] = useState(initial ?? false);

  const handleOpen = (event?: MouseEvent) => {
    setIsOpen(true);
    onOpen && onOpen(event);
  };

  const handleOpenCb =
    (cb?: (event?: MouseEvent) => void) => (event?: MouseEvent) => {
      setIsOpen(true);
      onOpen && onOpen(event);
      cb && cb(event);
    };

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const handleCloseCb = (cb?: () => void) => {
    setIsOpen(false);
    onClose && onClose();
    cb && cb();
  };

  const handleToggle = () => {
    setIsOpen((prevState) => {
      onToggle && onToggle(prevState);
      return !prevState;
    });
  };

  const handleToggleCb = (cb?: (state?: boolean) => void) => {
    setIsOpen((prevState) => {
      onToggle && onToggle(prevState);
      cb && cb(prevState);
      return !prevState;
    });
  };

  const methods = {
    isOpen,
    onOpen: handleOpen,
    onOpenCb: handleOpenCb,
    onClose: handleClose,
    onCloseCb: handleCloseCb,
    onToggle: handleToggle,
    onToggleCb: handleToggleCb
  };

  return methods;
}

export default useOpen;
