import React from 'react';

export default function getHelperAssignedToProjectWarning(
  assignedProjectsList: string[],
  type: 'deletion' | 'disable' = 'deletion'
) {
  return (
    <div>
      This helper still assigned on those projects:{' '}
      <ul>
        {assignedProjectsList.map((prj) => (
          <li key={prj}>{prj}</li>
        ))}
      </ul>
      Please, unassigned first before {type}.
    </div>
  );
}
