import { EditOutlined } from '@ant-design/icons';
import { ButtonPrimary, ButtonSecondary } from 'components/_main';
import InputLabelField from 'components/_form/InputLabelField';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { toast } from 'utils/toast';
import styled from 'styled-components/macro';
import { helperNameValidationSchema } from 'utils/formikFormValidationHelpers';

const TitleComponentView = ({
  className,
  firstName,
  lastName,
  email,
  loading,
  onSubmit
}: any) => {
  const [isEdit, setIsEdit] = useState(false);

  if (isEdit) {
    return (
      <Formik
        onSubmit={async (values) => {
          try {
            onSubmit && (await onSubmit(values));
            setIsEdit(false);
          } catch (e: any) {
            toast.error(e && 'message' in e && e.message);
          }
        }}
        initialValues={{
          firstName: firstName,
          lastName: lastName,
          email
        }}
        validationSchema={helperNameValidationSchema}
      >
        {({ submitForm }) => (
          <div className={`${className} editable`}>
            <InputLabelField
              small
              layout="vertical"
              role="firstName"
              name="firstName"
              label="First Name"
              forInputField
            />
            <InputLabelField
              small
              layout="vertical"
              role="lastName"
              name="lastName"
              label="Last Name"
              forInputField
            />
            <InputLabelField
              small
              layout="vertical"
              role="email"
              name="email"
              label="Email"
              forInputField
            />
            <div className="control-buttons">
              <ButtonPrimary
                iconStyle
                data-testid="save-title"
                isLoading={loading}
                iconSize="2rem"
                onClick={() => submitForm()}
              >
                ✔
              </ButtonPrimary>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <ButtonSecondary
                iconStyle
                data-testid="cancel-title"
                iconSize="2rem"
                onClick={() => setIsEdit(false)}
              >
                ❌
              </ButtonSecondary>
            </div>
          </div>
        )}
      </Formik>
    );
  }

  return !loading ? (
    <div className={`${className} normal`}>
      <span className="title">
        {firstName} {lastName}
      </span>
      <span className="email">{email}</span>
      <span className="editButton" onClick={() => setIsEdit(true)}>
        <EditOutlined />
      </span>
    </div>
  ) : (
    '...loading'
  );
};

export const TitleView = styled<any>(TitleComponentView)`
  &.normal,
  &.editable {
    display: grid;
    grid-template:
      'title editButton' 1fr
      'email editButton' 1fr / 1fr 100px;
    width: max-content;
    gap: 0.5rem 0;

    & > .title {
      font-size: 22px;
      grid-area: title;
      font-weight: 500;
      margin-bottom: 0;
    }

    & > .email {
      grid-area: email;
      font-size: 15px;
      font-weight: 500;
    }

    & > .editButton {
      grid-area: editButton;
      display: grid;
      justify-content: center;
      align-items: center;

      & svg {
        fill: #9e9e9e;
        cursor: pointer;
        width: 25px;
        height: 25px;
        transition: fill 300ms;
        &:hover {
          fill: #404040;
        }
      }
    }
  }

  &.editable {
    max-width: 707px;
    grid-template: 'first-name last-name email control-buttons' 1fr / 1fr 1fr 1fr max-content;
    gap: 10px;

    [data-role='firstName'] {
      grid-area: first-name;
    }

    [data-role='lastName'] {
      grid-area: last-name;
    }

    [data-role='email'] {
      grid-area: email;
    }

    .control-buttons {
      grid-area: control-buttons;
      display: grid;
      grid-auto-flow: column;
      grid-auto-rows: max-content;
      justify-content: center;
      align-content: center;
      align-self: flex-end;
      padding-bottom: 6px;
      align-items: center;
      grid-gap: 10px;
    }
  }
`;
