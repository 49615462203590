import React from 'react';
import { upper } from 'utils/_stringUtilsLegacy';
import { renderRate } from 'utils/_rateUtils';
import projectStatusColumn from 'components/Table/_columnRender/projectStatusColumn';
import projectProgressColumn from 'components/Table/_columnRender/projectProgressColumn';
import downloadResultsColumn from 'containers/Projects/DownloadResultsColumn';
import { Project } from 'generated/graphql';
import specializationIconColumn from 'components/Table/_columnRender/specializationIconColumn';
import { ColumnsAdminActiveProjectsProps } from 'containers/Projects/columns.types';
import adminActiveProjectsActions from 'containers/Projects/ProjectsAdmin/ActiveProjects/rowActions';
import { ColumnType } from 'antd/lib/table';

const columnsActiveProjectAdmin = ({
  activeProjectId,
  onEdit,
  onArchive
}: ColumnsAdminActiveProjectsProps): ColumnType<Project>[] => [
  {
    title: upper('client'),
    width: 200,
    dataIndex: 'formattedClient',
    key: 'client'
  },
  {
    title: upper('project'),
    width: 600,
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: upper('created'),
    width: 150,
    dataIndex: 'formattedCreatedAt',
    key: 'createdAt'
  },
  {
    title: upper('specialization'),
    width: 250,
    dataIndex: 'mappedType',
    key: 'type'
  },
  {
    title: upper('type'),
    width: 120,
    key: 'specType',
    render: specializationIconColumn
  },
  {
    title: upper('Status'),
    render: projectStatusColumn,
    width: 150
  },
  {
    title: upper('Progress'),
    render: projectProgressColumn(),
    width: 200
  },
  {
    title: upper('Rate'),
    width: 450,
    render: (data: Project) => {
      const { helperRate, auditorRate } = data;

      if (helperRate && auditorRate) {
        return (
          <>
            {renderRate(helperRate)} / {renderRate(auditorRate)}
          </>
        );
      }

      if (helperRate || auditorRate) {
        return <>{renderRate(helperRate || auditorRate)}</>;
      }

      return null;
    }
  },
  {
    title: upper('Results'),
    render: downloadResultsColumn(activeProjectId),
    width: 400
  },
  {
    title: '',
    render: adminActiveProjectsActions({ onEdit, onArchive }),
    width: 150
  }
];

export default columnsActiveProjectAdmin;
