import { gql } from 'graphql-tag';
import { CLIENT_DETAIL_FILED } from '../fragments/client';

export const PROJECT_DETAIL_FIELDS = gql`
  ${CLIENT_DETAIL_FILED}
  fragment ProjectDetailFields on Project {
    id
    title
    type
    createdAt
    updatedAt
    isDeleted
    labels
    helperRate
    auditorRate
    workPoints
    status
    platform
    customLabels
    helpersCount
    auditorsCount
    adminInstructionUrl
    adminShortInstruction
    clientInstructionUrl
    clientShortInstruction
    mediaFileName
    cost
    projectSettings {
      chunkLength
      subType
      annotationType
      processingType
    }
    projectStats {
      createdAt
      completedTasksCount
      inAuditTasksCount
      newTasksCount
      rejectedTasksCount
      returnedTasksCount
      startedTasksCount
      totalTasksCount
      readyForAuditTasksCount
    }
    inAuditTasksCount
    totalTasksCount
    completedTasksCount
    isUserHelper
    isUserAuditor
    mlLabels {
      multilabels {
        entities
        id
        name
        trigger {
          items
          parentId
        }
        type
      }
    }
    client {
      ...ClientDetailsFields
    }
  }
`;
