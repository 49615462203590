import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const LinkStyled = styled(Link)`
  &:hover,
  &:focus {
    span {
      transition: all 0.3s;
      color: ${({ theme }) => theme.colors.dark};
    }
  }
`;
