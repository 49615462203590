import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AnnotationUnitProps } from '../AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../context';
import { saveWithZoom } from '../utils';

interface Props {
  ref: React.MutableRefObject<SVGSVGElement | null>;
  props: AnnotationUnitProps;
}

export default function useDebouncedStateUpdate({ ref, props }: Props) {
  const { curZoomLevel } = useAnnotationSystemState();
  const { onModifyAnnotationUnit } = useAnnotationSystemActions();

  const handleOnContextStateUpdate = useCallback(() => {
    const element = ref.current;

    if (!element) return;

    onModifyAnnotationUnit({
      ...saveWithZoom(
        {
          ...props,
          x: parseInt(element.getAttribute('x') ?? '0'),
          y: parseInt(element.getAttribute('y') ?? '0'),
          w: parseInt(element.getAttribute('width') ?? '0'),
          h: parseInt(element.getAttribute('height') ?? '0'),
          label: element.dataset.label ?? props.label
        },
        curZoomLevel
      ),
      unitId: props.unitId
    });
  }, [curZoomLevel, onModifyAnnotationUnit, props, ref]);

  const handleOnContextStateUpdateDebounced = useDebouncedCallback(
    handleOnContextStateUpdate,
    50
  );

  return handleOnContextStateUpdateDebounced;
}
