import React from 'react';
import SelectFromData from '../SelectFromData';
import useGetListClients, {
  TUseGetListClientsItemData
} from 'hooks/useGetListClients';
import { Rule } from 'antd/lib/form';

interface SelectClientsProps {
  onSelect?: (clientId?: string) => void;
  isRequired?: boolean;
  name?: string;
  value?: string;
  setValue?: (arg0?: string) => void;
  rules?: Rule[];
}

const SelectClients: React.FC<SelectClientsProps> = ({
  rules,
  value,
  setValue,
  name,
  onSelect,
  isRequired
}) => {
  const { data, loading } = useGetListClients();

  return (
    <>
      <SelectFromData<TUseGetListClientsItemData>
        rules={rules}
        name={name}
        layout={'vertical'}
        value={value}
        data={data}
        loading={loading}
        isRequired={isRequired}
        idKey={'id'}
        valueRender={({ companyName }) => <>{`${companyName}`}</>}
        label={'Client'}
        onSelect={(client: string) => {
          setValue && setValue(client);
          onSelect && onSelect(client);
        }}
      />
    </>
  );
};

export default SelectClients;
