import React, { useState } from 'react';
import Tabs from 'components/Tabs';
import {
  ModalContentWithButtonsRoot,
  ModalFooterButtons,
  TabTitleUi
} from './ui';
import General from './tabs/General';
import { useClientCardCtx } from './ctx';
import { ClientProjectsHistoryDataProvider } from 'hooks/useClientProjectsHistoryDataProvider';
import ClientProjectsHistory from './tabs/ClientProjectsHistory';
import { ButtonPrimary } from 'components/_main/Button';
import {
  ProjectDetailsCardModalContent,
  ProjectDetailsCardDataProvider,
  ProjectDetailsCardDataWrapperWithCloneElement
} from '../ProjectDetailsCard';

function ClientCardModalContent() {
  const [tab, setTab] = useState<any>('general');

  const { data, curPrjData, onCurPrjClose, onClose, isFromProjectDetailsCard } =
    useClientCardCtx();

  if (!isFromProjectDetailsCard && curPrjData?.data?.id && curPrjData?.isOpen) {
    return (
      <ProjectDetailsCardDataProvider
        projectId={curPrjData.data.id}
        isFromClientCard
      >
        <ProjectDetailsCardDataWrapperWithCloneElement>
          <ProjectDetailsCardModalContent onCancel={onCurPrjClose} />
        </ProjectDetailsCardDataWrapperWithCloneElement>
      </ProjectDetailsCardDataProvider>
    );
  }

  return (
    <ModalContentWithButtonsRoot>
      <Tabs
        small
        activeKey={tab}
        onChange={(tabKey: string) => setTab(tabKey)}
        defaultActiveKey={'general'}
      >
        <Tabs.TabPane
          id="tab-general"
          key={'general'}
          tab={<TabTitleUi data-testid={'tab-general'}>General</TabTitleUi>}
        >
          <General />
        </Tabs.TabPane>
        <Tabs.TabPane
          id="tab-project-history"
          key={'project-history'}
          tab={
            <TabTitleUi data-testid={'tab-project-history'}>
              Project History
            </TabTitleUi>
          }
        >
          <ClientProjectsHistoryDataProvider clientId={data?.id}>
            <ClientProjectsHistory />
          </ClientProjectsHistoryDataProvider>
        </Tabs.TabPane>
      </Tabs>
      <ModalFooterButtons>
        <ButtonPrimary
          small
          onClick={() => {
            onClose && onClose();
          }}
        >
          {isFromProjectDetailsCard ? 'Back to Project Details' : 'Close'}
        </ButtonPrimary>
      </ModalFooterButtons>
    </ModalContentWithButtonsRoot>
  );
}

export default ClientCardModalContent;
