import React from 'react';
import { Row, Col, Text } from 'components/_main';
import AvatarGenerator from 'components/AvatarGenerator';
import { DeleteOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components/macro';
import { HelpersListProps } from 'containers/ProfileDetails/ProfileDetails.types';

function ProfileDetailsHelpersList({
  data,
  title,
  isRemoved,
  backgroundAvatarColor
}: HelpersListProps) {
  const theme = useTheme();

  return (
    <Row gridGap="20px" mb="20px">
      <Text variant="ui-small-bold" upper>
        {title}
      </Text>
      {data.length ? (
        data.map(({ firstName, lastName, email }) => (
          <Col
            key={email}
            gridGap="10px"
            padding="20px"
            borderRadius="5px"
            boxShadow={`0px 4px 10px 0px ${theme.colors.shadow}`}
            justifyContent="space-between"
            minWidth="400px"
          >
            <Col>
              <AvatarGenerator
                firstName={firstName}
                lastName={lastName}
                backgroundColor={backgroundAvatarColor}
              />
              <Row gridGap="0">
                <Text variant="ui-1-bold">
                  {firstName} {lastName}
                </Text>
                <Text variant="ui-small-regular" colorVariant="light">
                  {email}
                </Text>
              </Row>
            </Col>
            {isRemoved && (
              <DeleteOutlined
                css={`
                  display: flex;
                  align-items: center;
                  font-size: 20px;
                  path {
                    fill: ${theme.colors.primary};
                  }
                `}
              />
            )}
          </Col>
        ))
      ) : (
        <Text variant="ui-small-regular" colorVariant="light">
          You don't have any {title}.
        </Text>
      )}
    </Row>
  );
}

export default ProfileDetailsHelpersList;
