import React from 'react';
import { Col, Box, Button, Row, Text } from 'components/_main';
import { Modal } from 'components/_modals';
import getPracticeResultData from 'containers/LearningMaterials/utils/getPracticeResultData';
import { useTheme } from 'styled-components/macro';
import useRedirect from 'hooks/useRedirect';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';

interface PracticeResultModalProps {
  isOpen: boolean;
  isTimeOut?: boolean;
  onClose: () => void;
  startPracticeSession?: () => void;
}

function AnnotationPracticeResultModal({
  isOpen,
  isTimeOut,
  onClose,
  startPracticeSession
}: PracticeResultModalProps) {
  const theme = useTheme();
  const { annotationAccuracy } = useAnnotationSystemState();
  const { onSetAnnotationPracticeProgress } = useAnnotationSystemActions();
  const { title, desc, textColor, bgColor, cancelText } =
    getPracticeResultData(annotationAccuracy);
  const redirect = useRedirect();

  const handleStartTask = () => {
    if (startPracticeSession) {
      startPracticeSession();
    }
    onSetAnnotationPracticeProgress(0);
    onClose();
  };

  const handleClose = () => {
    redirect('/learning/lessons');
    onClose();
  };

  return (
    <Modal
      width="520px"
      title={isTimeOut ? 'Time is out!' : 'Results'}
      isVisible={isOpen}
      onCancel={handleClose}
      footer={false}
      bodyStyle={{ height: '510px', padding: '30px 40px' }}
    >
      <Row
        gridGap="30px"
        height="100%"
        alignItems="flex-start"
        justifyItems="center"
        gridTemplateRows="1fr max-content"
      >
        <Row justifyItems="center">
          <Box
            displayFlex
            width="140px"
            height="140px"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            backgroundColor={theme.colors[bgColor]}
          >
            <Text
              center
              variant="header-3"
              fontSize="50px"
              color={theme.colors[textColor]}
            >
              {annotationAccuracy}
            </Text>
          </Box>
          <Row gridGap="10px" justifyItems="center">
            <Text variant="header-2">{title}</Text>
            <Text maxWidth="440px" lineHeight="26px">
              {isTimeOut
                ? "Sorry, it seems like you ran out of time. But don't worry. You can try again within the allotted time for this test. We have faith in you! 🖖"
                : desc}
            </Text>
          </Row>
        </Row>
        {cancelText ? (
          <Col>
            <Button variant="outlined" onClick={handleClose}>
              {cancelText}
            </Button>
            <Button onClick={handleStartTask}>try again</Button>
          </Col>
        ) : (
          <Button onClick={handleClose}>back to lesson</Button>
        )}
      </Row>
    </Modal>
  );
}

export default AnnotationPracticeResultModal;
