import React from 'react';
import { Col } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import { useGetListOfHelpersQuery } from 'generated/graphql';
import { InvitedHelper } from 'appTypes';
import ProfileDetailsHelpersList from './ProfileDetails.ui';

function HelpersTab() {
  const theme = useTheme();
  const { data: helpers } = useGetListOfHelpersQuery({
    fetchPolicy: 'network-only'
  });
  const invitedHelpers =
    (helpers?.getListOfHelpers?.invited as InvitedHelper[]) ?? [];
  const requestedHelpers =
    (helpers?.getListOfHelpers?.requested as InvitedHelper[]) ?? [];

  return (
    <Col
      gridGap="60px"
      width="100%"
      height="calc(100vh - 200px)"
      gridTemplateColumns="1fr 1fr"
      overflow="auto"
    >
      <ProfileDetailsHelpersList
        title="Invited helpers"
        data={invitedHelpers}
      />
      <ProfileDetailsHelpersList
        title="helpers from taskware"
        data={requestedHelpers}
        backgroundAvatarColor={theme.colors.primary}
      />
    </Col>
  );
}

export default HelpersTab;
