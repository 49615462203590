import { Input } from 'components/Input';
import React, { useState } from 'react';
import { useRef } from 'react';
import { FieldProps } from 'formik';
import { useTheme } from 'styled-components/macro';

type AdminShortInstructionsTextAreaFieldProps = FieldProps & {
  disabled?: boolean;
};

const AdminShortInstructionsTextAreaField = ({
  meta,
  field,
  form,
  disabled
}: AdminShortInstructionsTextAreaFieldProps) => {
  const theme = useTheme();
  const timer = useRef<any>(0);
  const [val, setVal] = useState<string>(field.value ?? '');

  const handleOnChange = (e: any) => {
    if (e?.target?.value === undefined) return;
    const val = e?.target?.value;
    setVal(val);
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      form.setFieldValue(field.name, val);
      form.validateField(field.name);
    }, 500);
  };

  return (
    <div>
      <Input
        {...field}
        textarea
        value={val}
        onChange={handleOnChange}
        style={{
          width: '100%',
          borderRadius: '5px',
          borderColor: `${theme.colors.lighter}`
        }}
        autoSize={{
          minRows: 4
        }}
        placeholder="Your short instructions"
        disabled={disabled}
      />
      {meta?.error && (
        <div className="error" style={{ color: 'red', paddingTop: '1rem' }}>
          {meta?.error}
        </div>
      )}
    </div>
  );
};

export default AdminShortInstructionsTextAreaField;
