import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import { useEffect, useMemo, useState } from 'react';

interface Props {
  props: AnnotationUnitProps;
}

export default function useHandleState({ props: propsInit }: Props) {
  const { type } = useAnnotationSystemState();
  const [props, setProps] = useState<AnnotationUnitProps>(propsInit);

  useEffect(() => {
    if (
      propsInit.x !== props.x ||
      propsInit.y !== props.y ||
      propsInit.h !== props.h ||
      propsInit.w !== props.w ||
      propsInit.timelineTransitions !== props.timelineTransitions
    ) {
      setProps({
        ...propsInit
      });
    }
  }, [
    props.h,
    props.timelineTransitions,
    props.w,
    props.x,
    props.y,
    propsInit,
    type
  ]);

  return useMemo(() => ({ props, setProps }), [props]);
}
