import React, { PropsWithChildren } from 'react';
import { Popover } from 'antd';
import { Row, Text } from 'components/_main';
import { QuestionTipIcon } from 'assets/tips';
import Icon from 'components/Icon';

export function VideoAnnotationTooltip({ children }: PropsWithChildren<{}>) {
  return (
    <Popover
      trigger="click"
      placement="bottom"
      content={
        <Row gridGap="4px" maxWidth="300px" padding="4px" borderRadius="10px">
          {children}
        </Row>
      }
    >
      <Icon hoverStrokeColor="fg">
        <QuestionTipIcon />
      </Icon>
    </Popover>
  );
}

export function EntireVideoAnnotationTooltip() {
  return (
    <VideoAnnotationTooltip>
      <Text>
        We won't be splitting your video into frames. Instead, you have an
        entire clip with the possibility to pause on specific moments to
        annotate objects when they appear on the video.
      </Text>
      <Text>Use cases: outside camera with moving objects, sports game.</Text>
    </VideoAnnotationTooltip>
  );
}

export function StandardVideoAnnotationTooltip() {
  return (
    <VideoAnnotationTooltip>
      <Text>You can choose the time freeze of your video.</Text>
      <Text>
        Example: 1 second - means the video will be split every second. If the
        video is 10 seconds, you will get ten frames.
      </Text>
    </VideoAnnotationTooltip>
  );
}

export function DetailedVideoAnnotationTooltip() {
  return (
    <VideoAnnotationTooltip>
      <Text>
        We will define your frame rate per second and divide it by that amount.
      </Text>
      <Text>
        As a rule, there are 24 FPS - for films, 30 FPS - for movies, and 60 FPS
        - for sports, etc.
      </Text>
    </VideoAnnotationTooltip>
  );
}
