import styled, { css } from 'styled-components/macro';
import { useWidth } from 'utils/_styleUtilsLegacy';

export const Root = styled.div<{ hidden?: boolean; width?: number }>`
  ${useWidth};

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `};

  min-width: 200px;
`;
