import React from 'react';
import { isClient } from 'utils/role';
import { ButtonOutline } from 'components/_main';
import { useField, useFormikContext } from 'formik';
import { isReadyToChangeStatusFromDraftToUnreviewed } from '../utils';
import { ProjectStatus } from 'generated/graphql';
import { Popconfirm } from 'antd';
import { useCtx } from '../ProjectDetailsCard.Context';

function PublishButton({ onConfirm }: { onConfirm: () => Promise<void> }) {
  const { isValid, values } = useFormikContext();
  const [, , statusFieldHelper] = useField<any>('status');
  const { isMediaUploading } = useCtx();

  const isDisabled =
    !isReadyToChangeStatusFromDraftToUnreviewed(values, isValid) ||
    isMediaUploading;

  if (!isClient()) {
    return null;
  }

  const handlePublish = () => {
    statusFieldHelper.setValue(ProjectStatus.Unreviewed);
    onConfirm();
  };

  return (
    <Popconfirm
      title="After the project is submitted, changes won’t be allowed. Do you want to proceed?"
      disabled={isDisabled}
      onConfirm={handlePublish}
      onCancel={() => {}}
      okText="Yes"
      cancelText="No"
    >
      <ButtonOutline small disabled={isDisabled}>
        Publish
      </ButtonOutline>
    </Popconfirm>
  );
}

export default PublishButton;
