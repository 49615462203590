import React, { useCallback, useMemo } from 'react';
import { Box, Button, Col, Text } from 'components/_main';
import SpinnerLoader from 'components/SpinnerLoader';
import 'styled-components/macro';
import { css, useTheme } from 'styled-components/macro';
import useHover from 'hooks/useHover';
import {
  IconButtonProps,
  UploadCloudServiceIconProps,
  UploadFileItemProps
} from './UploadFiles.types';
import { DeleteOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Icon from 'components/Icon';

export function TrashIconButton({ onClick }: IconButtonProps) {
  return (
    <Icon fill="primary" hoverFillColor="fg" onClick={onClick} size={16}>
      <DeleteOutlined />
    </Icon>
  );
}

export function UploadingButton({ onClick }: IconButtonProps) {
  const [ref, isHovered] = useHover();

  return (
    <div ref={ref}>
      <Icon fill="primary" hoverFillColor="fg" onClick={onClick} size={16}>
        {isHovered ? (
          <ClockCircleOutlined />
        ) : (
          <Box width="16px" height="16px">
            <SpinnerLoader />
          </Box>
        )}
      </Icon>
    </div>
  );
}

export function UploadFileItem({
  state,
  fileName,
  index,
  isLast,
  onDelete,
  onCancel
}: UploadFileItemProps) {
  const theme = useTheme();

  const handleDelete = useCallback(() => {
    fileName && onDelete && onDelete(fileName);
  }, [fileName, onDelete]);

  const handleCancel = useCallback(() => {
    fileName && onCancel && onCancel(fileName);
  }, [fileName, onCancel]);

  const rightPartRender = useMemo(() => {
    switch (state) {
      case 'new':
      case 'uploading':
        return <UploadingButton onClick={handleCancel} />;
      default:
        return <TrashIconButton onClick={handleDelete} />;
    }
  }, [handleCancel, handleDelete, state]);

  if (!fileName) {
    return null;
  }

  return (
    <Col
      gridAutoColumns="max-content 1fr max-content"
      height="32px"
      minWidth="370px"
      width="100%"
      maxWidth="800px"
      alignItems="center"
      {...(!isLast && {
        borderBottom: `1px solid ${theme.colors.lighter}`
      })}
    >
      <Text colorVariant="light">{(index ?? 0) + 1}.</Text>
      <Text
        width="300px"
        overflow="hidden"
        css={`
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {fileName}
      </Text>
      {rightPartRender}
    </Col>
  );
}

export function UploadCloudServiceIcon({
  children,
  disabled,
  onClick
}: React.PropsWithChildren<UploadCloudServiceIconProps>) {
  const theme = useTheme();

  return (
    <Box
      onClick={onClick}
      css={`
        cursor: pointer;
        position: relative;

        ${disabled &&
        css`
          cursor: auto;
          pointer-events: none;

          &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: ' ';
            background-color: ${theme.colors.lighter};
            opacity: 0.5;
            z-index: 100;
          }

          svg {
            filter: grayscale(100%);
          }
        `}
      `}
      backgroundColor={theme.colors.lighter}
      grid
      flexCenter
      width="238px"
      height="123px"
      {...(disabled && {
        cursor: undefined,
        onClick: undefined
      })}
    >
      {children}
    </Box>
  );
}
