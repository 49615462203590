import React from 'react';
import { Modal } from 'components/_modals';
import ProjectDetailsCardModalWrapper from './ProjectDetailsCard.Modal.ContentWrapper';
import { ProjectDetailsCardModalProps } from './types';
import { Box } from 'components/_main';

const ProjectDetailsCardModal = ({
  isOpen,
  onCancel,
  projectData,
  loading,
  closable = true
}: ProjectDetailsCardModalProps) => {
  if (!projectData || !isOpen) {
    return null;
  }

  return (
    <Modal
      width="860px"
      title={projectData?.title}
      destroyOnClose={true}
      isVisible={isOpen}
      footer={false}
      formLayout="vertical"
      onCancel={closable ? onCancel : undefined}
      bodyStyle={{ padding: 0, height: '580px' }}
    >
      <Box overflow="hidden" height="100%">
        <ProjectDetailsCardModalWrapper
          loading={loading}
          onCancel={onCancel}
          projectData={projectData}
        />
      </Box>
    </Modal>
  );
};

export default ProjectDetailsCardModal;
