import React from 'react';
import { useField } from 'formik';
import InputLabel from 'components/InputLabel';
import Input from 'components/Input';
import { Text } from 'components/_main';
import useTitleDuplicationValidation from 'components/ProjectTitle/useTitleDuplicationValidation';

interface ProjectTitleProps {
  name: string;
  placeholder?: string;
  clientId?: number | null;
  disabled?: boolean;
  readOnly?: boolean;
}

export const ProjectTitle: React.FC<ProjectTitleProps> = ({
  name,
  placeholder = `Enter the project name`,
  clientId,
  disabled,
  readOnly
}) => {
  const { handleDuplicationValidation, isLoading } =
    useTitleDuplicationValidation(clientId);

  const [field, meta] = useField({
    name,
    validate: handleDuplicationValidation
  });

  return (
    <>
      {readOnly ? (
        <Text lineHeight="26px">{field.value}</Text>
      ) : (
        <InputLabel
          loading={isLoading}
          layout="vertical"
          meta={meta}
          id={field.name}
          disabled={disabled}
        >
          <Input {...field} readOnly={readOnly} placeholder={placeholder} />
        </InputLabel>
      )}
    </>
  );
};

export default ProjectTitle;
