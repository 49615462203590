import React from 'react';
import { Modal } from 'components/_modals';
import { Button, Text, Row, Col } from 'components/_main';
import { Z_INDEXES } from 'appConstants';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}

function ConfirmModal({ title, isOpen, onClose, onSubmit }: Props) {
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    onClose();
  };

  return (
    <Modal
      width={440}
      zIndex={Z_INDEXES.confirmModal}
      isVisible={isOpen}
      onCancel={onClose}
      footer={false}
      closable={false}
    >
      <Row gridGap="30px">
        <Text variant="ui-3-regular" lineHeight="26px" center>
          {title}
        </Text>
        <Col flexCenter width="100%">
          <Button variant="outlined" onClick={onClose}>
            No
          </Button>
          <Button onClick={handleSubmit}>Yes, do it</Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default ConfirmModal;
