import React from 'react';
import { ModalForm } from 'components/_modals';
import { Form } from 'antd';
import AntdFormItemInput from 'components/AntdFormItemInput';
import AntdFormItemInputEmail from 'components/AntdFormItemInputEmail';
import { initialValues } from '../Users.constants';
import { TData } from '../Users.types';
import { useCreateHelperMutation } from 'generated/graphql';
import { toast } from 'utils/toast';
import { titleRules } from 'utils/finalFormValidationHelpers';
import { TRefetchQueries } from 'appTypes';

function ModalCreate<TDataGeneric extends { id: string } = TData>({
  isOpen,
  setModalOpen,
  refetchQueries
}: {
  curData?: TData;
  isOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  refetchQueries: TRefetchQueries;
}) {
  const [formHook] = Form.useForm<TData>();

  const handleOnClose = () => {
    setModalOpen(false);
    formHook.resetFields();
  };

  const [createHelper, { loading }] = useCreateHelperMutation({
    onCompleted: () => {
      toast.success('Helper successfully created');
      handleOnClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: () => refetchQueries
  });

  return (
    <ModalForm<TDataGeneric>
      title={'Add user'}
      form={formHook}
      isVisible={isOpen}
      initialValues={initialValues as unknown as TDataGeneric}
      onOk={() => {
        const values = formHook.getFieldsValue();
        createHelper({ variables: { ...values } });
      }}
      onCancel={() => {
        handleOnClose();
      }}
      loading={loading}
      layout={'vertical'}
    >
      <AntdFormItemInputEmail label={'Email'} name={'email'} />
      <AntdFormItemInput
        rules={[...titleRules]}
        label={'First name'}
        name={'firstName'}
      />
      <AntdFormItemInput
        rules={[...titleRules]}
        label={'Last name'}
        name={'lastName'}
      />
    </ModalForm>
  );
}

export default ModalCreate;
