import { v4 as uuidv4 } from 'uuid';
import {
  AnnotationSystemEventsEnum,
  AnnotationSystemProps,
  AnnotationUnits
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import { POLYGON_UNIT_INIT } from 'containers/AnnotationSystem/AnnotationSystem.constants';
import { isValidPolygon } from 'containers/AnnotationSystem/utils';

export default function createPolygonAnnotationUnit(
  annotationUnits: AnnotationUnits
): Partial<AnnotationSystemProps> {
  const newAnnotationUnits = [
    ...annotationUnits.filter((unit) => isValidPolygon(unit.polygonPoints))
  ];
  const newUnitId: string = uuidv4();

  newAnnotationUnits.push({
    ...POLYGON_UNIT_INIT,
    unitId: newUnitId,
    polygonPoints: [[0, 0]]
  });

  return {
    curEvent: AnnotationSystemEventsEnum.CREATE_MODE,
    annotationUnits: [...newAnnotationUnits],
    curSelUnitId: newUnitId
  };
}
