import {
  TNewTask,
  TSubmitTaskReturn
} from 'containers/TasksRouter/Tasks.types';
import { useNavigate, useParams } from 'react-router-dom';
import { TaskAction, useSubmitLessonPracticeMutation } from 'generated/graphql';
import { toast } from 'utils/toast';
import mapTaskActionsToastMsg from '../utils/mapTaskActionsToToastMessage';
import useGetSubmitTaskMutation from './useGetSubmitTaskMutation';
import useGetResults from 'containers/AnnotationSystem/hooks/useGetResults';
import getTaskingUrl from '../utils/getTaskingUrl';
import { consoleError } from 'utils';
import useSaveResultsLocally from 'hooks/useSaveResultsLocally';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { isValidPolygon } from 'containers/AnnotationSystem/utils';
import { TaskingType } from 'appTypes';
import getPracticalTask from 'containers/TasksRouter/utils/getPracticalTask';

type THandleOnTaskSubmit = (
  taskId: number,
  projectId: number,
  taskAction: TaskAction,
  comment?: string
) => void;

type THandleOnPracticeTaskSubmit = (
  taskId: number,
  projectId: number,
  taskAction: TaskAction,
  onPracticeResultOpen: () => void,
  noResults?: boolean
) => void;

const useHandleOnTaskSubmit = (
  setTaskData: (data: TNewTask) => void,
  type: TaskingType = 'tasking'
): [THandleOnTaskSubmit, THandleOnPracticeTaskSubmit, boolean, boolean] => {
  const history = useNavigate();
  const { annotationSpecificationType } = useAnnotationSystemState();
  const {
    onSetIsLoading,
    onSetAnnotationAccuracy,
    onSetAnnotationPracticeProgress
  } = useAnnotationSystemActions();
  const [submitTask, submitTaskLoading] = useGetSubmitTaskMutation();
  const [submitLessonPractice, { loading: submitLessonPracticeLoading }] =
    useSubmitLessonPracticeMutation();
  const getResults = useGetResults();
  const { saveResultsLocally } = useSaveResultsLocally();
  const { annotationUnits } = useAnnotationSystemState();
  const params: any = useParams();
  const isPolygon = annotationSpecificationType === 'polygon';

  const checkResults = (taskAction: TaskAction, noResultsData?: boolean) => {
    const isAllowWithoutResults =
      taskAction === TaskAction.Skip ||
      taskAction === TaskAction.Return ||
      taskAction === TaskAction.Reject ||
      taskAction === TaskAction.RejectAsAuditor;

    let results = getResults({ taskAction })?.data;
    let noResults = annotationUnits.length === 0;
    let withoutLabel = false;

    if (isPolygon) {
      noResults =
        noResults ||
        !Boolean(
          annotationUnits.filter(
            /** Check if all polygons have 3 and more points */
            ({ polygonPoints }) => isValidPolygon(polygonPoints)
          ).length
        );
    }

    withoutLabel = annotationUnits.some(
      ({ label, polygonPoints }) => !label && isValidPolygon(polygonPoints)
    );

    if (!noResults && withoutLabel) {
      throw new Error('Please set labels for every annotation unit');
    }

    if (!isAllowWithoutResults && !noResultsData && noResults) {
      throw new Error('Can not submit without results');
    }

    if (!results) {
      throw new Error('No results');
    }

    return results;
  };

  const handleOnTaskSubmit: THandleOnTaskSubmit = async (
    taskId,
    projectId,
    taskAction,
    comment
  ) => {
    /** TODO: get all such statuses from reducer. For all places, like inside annotation units, sidebars, toolbars, status bars and so on */
    onSetIsLoading(true);
    try {
      const results = checkResults(taskAction);
      let data: TSubmitTaskReturn;

      saveResultsLocally(taskId, results);

      const response = await submitTask({
        variables: {
          taskId: taskId,
          action: taskAction,
          result: { data: results },
          comment: comment || ''
        }
      });
      data = response?.data;

      if (!data) {
        throw new Error('Server response error. Please try again later');
      }

      const newTask = data?.submitTask?.newTask;
      const newTaskId = newTask?.id;
      const isTheSameTask = newTaskId === taskId;

      mapTaskActionsToastMsg(taskAction, isTheSameTask);

      const resultTask = newTask?.result?.labels || newTask?.result;

      if (newTaskId && newTaskId !== taskId) {
        toast.success(`Moving to the next task with ID ${newTaskId}`);

        const newUrl = getTaskingUrl(newTask, projectId, type);

        history(newUrl);
        newTask &&
          setTaskData({
            ...newTask,
            result: resultTask,
            isAvailableForHelper: true
          });
        return;
      }

      if (!newTaskId) {
        toast.success(`No new tasks`);
        history('/projects');
      }
    } catch (error) {
      onSetIsLoading(false);
      consoleError(error);
      return;
    }
  };

  const handleOnPracticeTaskSubmit: THandleOnPracticeTaskSubmit = async (
    taskId,
    projectId,
    taskAction,
    onPracticeResultOpen,
    noResults
  ) => {
    const lessonId = parseInt(params?.lessonId, 10);
    const topicId = parseInt(params?.topicId, 10);

    onSetIsLoading(true);
    try {
      const results = checkResults(taskAction, noResults);
      const { data } = await submitLessonPractice({
        variables: {
          topicId: topicId,
          lessonId: lessonId,
          practiceId: taskId,
          result: { data: results }
        }
      });

      if (!data) {
        throw new Error('Server response error. Please try again later');
      }

      const newTask = data?.submitLessonPractice?.next;
      const result = data?.submitLessonPractice?.result;
      const currentTaskIndex = data?.submitLessonPractice?.currentTaskIndex;
      const totalSessionSize = data?.submitLessonPractice?.totalSessionSize;
      const progress =
        currentTaskIndex && totalSessionSize
          ? Math.round((currentTaskIndex / totalSessionSize) * 100)
          : 0;
      onSetAnnotationPracticeProgress(progress);
      mapTaskActionsToastMsg(taskAction);

      if (newTask) {
        toast.success(`Moving to the next task`);

        const newTaskData = getPracticalTask(taskId, newTask);

        const newUrl = getTaskingUrl(
          newTaskData,
          projectId,
          type,
          topicId,
          lessonId
        );

        history(newUrl);

        setTaskData(newTaskData);
        return;
      }

      if (!newTask) {
        onPracticeResultOpen();
        if (result) {
          onSetAnnotationAccuracy(result);
        }
      }
    } catch (error) {
      onSetIsLoading(false);
      consoleError(error);
      return;
    }
  };

  return [
    handleOnTaskSubmit,
    handleOnPracticeTaskSubmit,
    submitTaskLoading,
    submitLessonPracticeLoading
  ];
};

export default useHandleOnTaskSubmit;
