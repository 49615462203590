import { Button } from 'components/_main';
import React from 'react';
import { FooterStyled } from '../ui';
import { ModalProps } from './Modal.types';

function Footer({
  onCancel,
  onOk,
  cancelText = 'Close',
  okText = 'Submit',
  okButton,
  loading,
  isOkDisabled
}: Pick<
  ModalProps,
  | 'onOk'
  | 'onCancel'
  | 'cancelText'
  | 'okText'
  | 'okButton'
  | 'loading'
  | 'isOkDisabled'
>) {
  return (
    <FooterStyled>
      <>
        {onCancel && cancelText ? (
          <Button variant="outlined" onClick={onCancel}>
            {cancelText}
          </Button>
        ) : (
          <div> </div>
        )}
        {okButton && okButton}
        {!okButton && onOk && okText && (
          <Button
            disabled={isOkDisabled}
            isLoading={loading}
            onClick={onOk}
            htmlType="submit"
          >
            {okText}
          </Button>
        )}
      </>
    </FooterStyled>
  );
}

export default Footer;
