import { TaskAction } from 'generated/graphql';
import { toast } from 'utils/toast';

const mapTaskActionsToastMsg = (
  taskAction: TaskAction,
  isTheSameTask?: boolean
) => {
  switch (taskAction) {
    case TaskAction.Return:
      toast.info('Task returned');
      break;
    case TaskAction.Reject:
      toast.info('Task rejected');
      break;
    case TaskAction.ApproveWithPenalty:
      toast.info('Task approved with penalty');
      break;
    case TaskAction.Approve:
      toast.success('Task approved');
      break;
    case TaskAction.Submit:
      toast.success('Task submitted');
      break;
    case TaskAction.Skip:
      if (isTheSameTask) {
        toast.info(`Task can't be skipped a second time`);
      } else {
        toast.info('Task skipped');
      }
      break;
    default:
      break;
  }
};

export default mapTaskActionsToastMsg;
