import Box from 'components/_main/Box';
import styled, { css } from 'styled-components/macro';
import retPxOrString from '../../utils/retPxOrString';
import SpinnerLoader from 'components/SpinnerLoader';
import { scrollStyled } from 'theme';

export const Root = styled(Box)(
  ({ x, y }: { x: number | string; y: number | string }) => css`
    position: relative;
    left: ${retPxOrString(x)};
    top: ${retPxOrString(y)};
    width: 100%;
    height: 100%;
    overflow: auto;
    ${scrollStyled};
  `
);

export const LoadingSpinner = () => (
  <Box
    position="absolute"
    display="flex"
    flexCenter
    width="100%"
    height="100%"
    zIndex="1"
  >
    <Box width="40px" height="40px">
      <SpinnerLoader />
    </Box>
  </Box>
);
