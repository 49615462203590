import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import useGetUserSessionData from '../../hooks/useGetUserSessionData';
import {
  consoleError,
  consoleLog,
  isAdmin,
  isClient,
  upper
} from '../../utils';
import { toast } from 'utils/toast';
import {
  Platform,
  TaskType,
  useProjectMediaUploadMutation,
  useRequestHelpersMutation,
  useSendRequestToHelperMutation
} from 'generated/graphql';
import { useAddProjectWizardContext } from './AddProjectWizard.context';
import { ProjectType } from 'appTypes';
import {
  AddProjectWizardContextState,
  FormRef,
  StepsDescriptions,
  WizardFormValues
} from './AddProjectWizard.types';
import { WIZARD_FORM_INITIAL_VALUES } from './AddProjectWizard.constants';
import Wizard from './components/Wizard';
import { SaveAsDraftIcon } from './assets';
import { Col } from 'components/_main';
import { Button } from 'components/_main/Button';
import { Text } from 'components/_main/Text';
import useGetProjectCreateMutation from './hooks/useGetProjectCreateMutation';
import mapFormValuesForSubmit from './mapFormValuesForSubmit';
import { MESSAGES } from 'appConstants';
import { useMutation } from '@apollo/client';
import { useProjectsContext } from 'containers/Projects/Projects.context';

interface AddProjectWizardMainLegacyProps {
  showSaveDraftButton?: boolean;
  showSkipStepButton?: boolean;
  isDisabledSaveButton?: boolean;
  closeWizardWindow: () => void;
  initialValuesProps?: Partial<WizardFormValues>;
  validationSchema?: (context?: Partial<AddProjectWizardContextState>) => any;
}

function AddProjectWizardMainLegacy({
  showSaveDraftButton = true,
  showSkipStepButton = true,
  closeWizardWindow,
  initialValuesProps,
  isDisabledSaveButton,
  children,
  validationSchema
}: React.PropsWithChildren<AddProjectWizardMainLegacyProps>) {
  const context = useAddProjectWizardContext();

  const { id: userId } = useGetUserSessionData();

  const formRef = useRef<FormRef | null>(null);
  const [, update] = useState(0);

  const [projectMediaUpload] = useProjectMediaUploadMutation();

  const [sendRequestToHelper] = useSendRequestToHelperMutation();
  const [requestHelpers] = useRequestHelpersMutation();
  const { currentProjectData, setCurrentProjectData } = useProjectsContext();

  const {
    videoMediaFile,
    isLoading: contextLoading,
    onSetLoading,
    onVideoLoadProgress,
    annotationMultipleFiles,
    annotationFilesUploadType,
    onSetFormRef,
    formOptions,
    onSetCurrentStep,
    authToken,
    invitedHelpers,
    isRequestCustomHelpers,
    isOnlyRequestCustomHelpers
  } = context;

  useLayoutEffect(() => {
    if (formRef.current) {
      onSetFormRef(formRef.current);
      return;
    }

    consoleError('No form ref');
  }, [onSetFormRef]);

  const { getCreateProjectMutation, loading } = useGetProjectCreateMutation();

  const handleCreateProject = useCallback(
    async ({
      values,
      context,
      filename,
      multipleFilenames,
      ifSaveDraftProject
    }: {
      context: AddProjectWizardContextState;
      values: WizardFormValues;
      filename?: string | null;
      multipleFilenames?: string[] | null;
      ifSaveDraftProject?: boolean;
    }) => {
      if (!values) {
        throw new Error('values undefined');
      }

      const { customMessageInviteHelpersRequest, ...finalValues } = values;

      if (
        !ifSaveDraftProject &&
        context.platform === Platform.Hosted &&
        values.type !== ProjectType.DATA_SET_COLLECTION &&
        !filename &&
        (!multipleFilenames || !Array.isArray(multipleFilenames))
      ) {
        consoleError({
          message: 'Something went wrong during project create',
          filename,
          mediaFileName: finalValues?.mediaFileName,
          annotationFilesUploadType,
          multipleFilenames,
          ifSaveDraftProject,
          type: finalValues.type,
          platform: context.platform
        });
        throw new Error('Something went wrong during project create');
      }

      const finalInput = mapFormValuesForSubmit({
        userId,
        multipleFilenames,
        context,
        values: finalValues,
        isClient: isClient(),
        filename,
        ifSaveDraftProject,
        authToken
      });

      const { createProjectFinalFunc: createProject, resultKey } =
        getCreateProjectMutation(
          finalValues,
          context.annotationFilesUploadType,
          ifSaveDraftProject
        );

      const createProjectResults = await createProject({
        variables: {
          input: finalInput
        }
      });

      await (async () => {
        if (
          context.formOptions?.skippedStepsNames?.some(
            (step) => step === StepsDescriptions.InviteHelpers
          )
        ) {
          return;
        }

        const projectId = createProjectResults?.data?.[resultKey]?.id;

        if (!projectId) {
          consoleError({
            message: 'No projectId'
          });
          return;
        }

        if (ifSaveDraftProject) {
          setCurrentProjectData({
            ...currentProjectData,
            id: projectId,
            title: finalValues.title,
            type: finalValues.type as unknown as TaskType
          });
        }

        if (
          (isRequestCustomHelpers || isOnlyRequestCustomHelpers) &&
          customMessageInviteHelpersRequest
        ) {
          try {
            await requestHelpers({
              variables: {
                comment: customMessageInviteHelpersRequest,
                projectId: projectId
              }
            });
            return;
          } catch (error) {
            consoleError({
              message: 'Error during sending message for request helpers',
              messageText: customMessageInviteHelpersRequest,
              error
            });
          }
        }

        if (invitedHelpers === null) {
          return;
        }

        if (invitedHelpers?.length === 0) {
          consoleError({
            message: 'Invalid invitedHelpers',
            invitedHelpers
          });
          return;
        }

        for (const helper of invitedHelpers) {
          consoleLog({
            message: 'Sending invite',
            user: helper.email,
            projectId
          });
          try {
            await sendRequestToHelper({
              variables: {
                email: helper.email,
                projectId,
                firstName: helper.firstName,
                lastName: helper.lastName
              }
            });
          } catch (error) {
            consoleError({
              message: 'Error during sending invite',
              user: helper.email,
              projectId,
              error
            });
          }
        }
      })();
    },
    [
      annotationFilesUploadType,
      authToken,
      getCreateProjectMutation,
      invitedHelpers,
      isRequestCustomHelpers,
      isOnlyRequestCustomHelpers,
      currentProjectData,
      requestHelpers,
      sendRequestToHelper,
      setCurrentProjectData,
      userId
    ]
  );

  const handleFormSubmitCb = useCallback(
    async (values: WizardFormValues, context: AddProjectWizardContextState) => {
      const { type } = values;

      const clientId = isAdmin() ? (values?.clientId as number) : undefined;

      if (!annotationFilesUploadType) {
        consoleError(
          { annotationFilesUploadType },
          'Invalid annotationMultipleFiles'
        );
        toast.error(MESSAGES.serverError.common);
        throw new Error('Invalid annotationMultipleFiles');
      }

      /**
       * DATASET COLLECTION - REQUEST CUSTOM PROJECT MESSAGE ONLY
       * --------------------------------------------------------
       */
      if (type === ProjectType.DATA_SET_COLLECTION) {
      }

      if (
        context.platform !== Platform.Own &&
        (type === ProjectType.IMAGE_ANNOTATION ||
          type === ProjectType.CONTENT_MODERATION ||
          type === ProjectType.NLP) &&
        annotationFilesUploadType === 'single'
      ) {
        /**
         * TYPES: IMAGE, CONTENT MODERATION, NLP
         *
         * MEDIA TYPE: SINGLE ZIP ARCHIVE (WITH IMAGES INSIDE) OR
         * CSV OR EXCEL FILES FOR NLP PROJECT
         * -----------------
         */
        onSetLoading(true);
        try {
          consoleLog({ zipMediaFile: context.mediaFile });
          const fileNameFromBe = await projectMediaUpload({
            variables: {
              clientId,
              file: context.mediaFile
            }
          });
          consoleLog({ fileNameFromBe });
          await handleCreateProject({
            values,
            context,
            multipleFilenames: fileNameFromBe.data
              ?.projectMediaUpload as string[]
          });

          return;
        } catch (error: any) {
          consoleError(error);
          toast.error(MESSAGES.serverError.common);
          throw new Error(error);
        } finally {
          onSetLoading(false);
        }
      }

      if (
        context.platform !== Platform.Own &&
        type === ProjectType.VIDEO_ANNOTATION &&
        annotationFilesUploadType === 'single'
      ) {
        /**
         * SINGLE VIDEO
         * ------------
         */
        if (!videoMediaFile) {
          consoleError('Add new project -> Submit. Media file not found');
          toast.error(MESSAGES.serverError.common);
          return;
        }

        try {
          consoleLog('Starting loading single video');
          onSetLoading(true);

          const fileNameFromBe = await projectMediaUpload({
            variables: {
              clientId,
              file: videoMediaFile
            }
          });
          await handleCreateProject({
            values,
            context,
            filename: fileNameFromBe.data?.projectMediaUpload?.[0] ?? ''
          });
          return;
        } catch (error: any) {
          consoleError(error);
          toast.error(MESSAGES.serverError.common);
          throw new Error(error);
        } finally {
          onSetLoading(false);
        }
      }

      if (
        context.platform !== Platform.Own &&
        annotationFilesUploadType === 'multiple'
      ) {
        /**
         * MULTIPLE FILES (IMAGE OR VIDEO)
         * -------------------------------
         */
        if (!annotationMultipleFiles || annotationMultipleFiles.length === 0) {
          consoleError({
            message: 'annotationMultipleFiles on valid',
            annotationMultipleFiles
          });
          return;
        }

        try {
          const allFileUploadPromisesArray = [];
          onSetLoading(true);
          for (const file of annotationMultipleFiles as File[]) {
            allFileUploadPromisesArray.push(
              projectMediaUpload({
                variables: {
                  file: file,
                  // @ts-ignore
                  clientId: isAdmin() ? values.clientId : undefined
                }
              })
            );
          }

          const fileNamesFromBeResponses = await Promise.all(
            allFileUploadPromisesArray
          );

          const fileNamesFromBe = fileNamesFromBeResponses.map(
            (f) => f.data?.projectMediaUpload?.[0] ?? ''
          );

          await handleCreateProject({
            values,
            context,
            multipleFilenames: fileNamesFromBe
          });
          return;
        } catch (error: any) {
          consoleError(error, 'Project multiple videos');
          toast.error(MESSAGES.serverError.common);
          throw new Error(error);
        } finally {
          onSetLoading(false);
        }
      }

      /**
       * GOOGLE DRIVE CLOUD
       * ===================
       */
      if (
        context.platform !== Platform.Own &&
        annotationFilesUploadType === 'googleDrive'
      ) {
        try {
          onSetLoading(true);
          await handleCreateProject({
            values,
            context,
            multipleFilenames: annotationMultipleFiles as unknown as string[]
          });
          return;
        } catch (error: any) {
          consoleError(error, 'Google Drive project create error');
          toast.error(MESSAGES.serverError.common);
          throw new Error(error);
        } finally {
          onSetLoading(false);
        }
      }

      /**
       * PLATFORM HOSTED OR PROJECT TYPE DATASET COLLECTION
       * ===================
       */

      if (
        context.platform === Platform.Own ||
        (context.platform === Platform.Hosted &&
          type === ProjectType.DATA_SET_COLLECTION)
      ) {
        try {
          await handleCreateProject({
            values,
            context
          });
          return;
        } catch (error: any) {
          consoleError(error, 'Google Drive project create error');
          toast.error(MESSAGES.serverError.common);
          throw new Error(error);
        } finally {
          onSetLoading(false);
        }
      }
    },
    [
      annotationFilesUploadType,
      annotationMultipleFiles,
      handleCreateProject,
      onSetLoading,
      projectMediaUpload,
      videoMediaFile
    ]
  );

  useLayoutEffect(() => {
    update(Date.now());
  }, []);

  const handleSaveDraft = useCallback(() => {
    const form = formRef.current;

    if (!form) {
      consoleError('Form reference not defined');
      return;
    }

    form.setTouched({ title: true });
    form.validateForm().then((error) => {
      if (error?.title) {
        return;
      }
      handleCreateProject({
        context,
        values: form?.values,
        ifSaveDraftProject: true
      });
    });
  }, [context, handleCreateProject]);

  const saveDraftButtonRender = useMemo(
    () => (
      <Button
        onClick={handleSaveDraft}
        noHover
        noPadding
        variant="ghost"
        normal
        iconPrefix={<SaveAsDraftIcon />}
      >
        <Text colorVariant="primary" variant="ui-small-bold">
          {upper('SAVE AS A DRAFT')}
        </Text>
      </Button>
    ),
    [handleSaveDraft]
  );

  const finalInitialValues = useMemo(
    () => ({
      ...WIZARD_FORM_INITIAL_VALUES,
      ...initialValuesProps
    }),
    [initialValuesProps]
  );

  const handleErrors = async (
    props: WizardFormValues,
    context: AddProjectWizardContextState
  ) => {
    try {
      await handleFormSubmitCb(props, context);
    } catch (error: any) {
      consoleError(error);
      toast.error(error?.message ? error.message : error);
    }
  };

  return (
    <Wizard
      showSaveDraftProjectButton={showSaveDraftButton}
      showSkipStepButton={showSkipStepButton}
      saveDraftProjectButton={saveDraftButtonRender}
      validationSchema={
        validationSchema ? validationSchema(context) : undefined
      }
      stepNumber={formOptions?.currentStep}
      onStepChange={onSetCurrentStep}
      formRef={formRef}
      initialValues={finalInitialValues}
      isDisabledSaveButton={isDisabledSaveButton}
      onSubmit={!formOptions?.finalSubmitStepDisabled && handleErrors}
      onClose={closeWizardWindow}
      loading={loading || contextLoading}
    >
      {children}
    </Wizard>
  );
}

export default React.memo(AddProjectWizardMainLegacy);
