import { Box } from 'components/_main';
import styled, { css } from 'styled-components/macro';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  space,
  position,
  shadow,
  typography,
  compose
} from 'styled-system';

interface Props {
  noBorder?: boolean;
}

export const Root = styled(Box)<Props>(
  ({ noBorder }) => css`
    & .ant-input-search {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.bg};

      .ant-input-wrapper.ant-input-group {
        margin-top: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .ant-input-affix-wrapper:hover {
        border: ${noBorder ? 'none' : `1px solid`};
        border-color: ${({ theme }) => theme.colors.lighter};
      }
      .ant-input-affix-wrapper {
        border-radius: 5px !important;
        border: ${noBorder ? 'none' : `1px solid`};
        border-color: ${({ theme }) => theme.colors.lighter};
        height: 100%;
      }

      .ant-input {
        height: 100%;
        padding: 0 0 0 15px;
        border: none;
        background-color: transparent;
      }

      .ant-input-group-addon {
        background-color: transparent;
        .ant-btn {
          display: none;
        }
      }

      ${compose(
        grid,
        flexbox,
        layout,
        border,
        background,
        space,
        color,
        position,
        shadow,
        typography
      )}
    }
  `
);
