import React from 'react';
import HeaderAppBar from '../HeaderAppBar';
import { RobotAvatarPlaceholderImage } from 'assets';
import { Box, Button, Row, Text } from 'components/_main';
import { RouteContent } from 'components/RouteContent';
import { isClient, upper } from 'utils';
import 'styled-components/macro';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { APP_BAR_HEIGHT, DASHBOARD_MAIN_PADDING } from 'appConstants';
import CautionPlateResendActivationLink from 'containers/CautionPlateResendActivationLink';
import { useTheme } from 'styled-components/macro';
import useRedirect from 'hooks/useRedirect';

function DashboardEmpty() {
  const { activated, enabled } = useGetUserSessionData();
  const theme = useTheme();
  const redirect = useRedirect();

  const handleAddProjectClick = () => {
    redirect('/microtasking');
  };

  return (
    <>
      <Box minHeight="100vh">
        {/** TODO: Move dashboard layout with AppBar & Sidebar to one place (component, DashboardLayout) */}
        <HeaderAppBar />
        <RouteContent>
          <Box width="100%" height="100%">
            <Box
              display="grid"
              alignContent={'center'}
              justifyContent={'center'}
              width="100%"
              height="100%"
              minHeight={`calc(100vh - ${DASHBOARD_MAIN_PADDING} - ${APP_BAR_HEIGHT})`}
            >
              <Row
                width="100%"
                height="100%"
                alignItems="center"
                justifyItems="center"
                gridGap="2rem"
              >
                <Box
                  width={276}
                  height={276}
                  borderRadius="50%"
                  bg={theme.colors.lightest}
                >
                  <RobotAvatarPlaceholderImage width="100%" height="100%" />
                </Box>
                <Text variant="header-1">Welcome to Taskware</Text>
                {isClient() &&
                  (!activated ? (
                    <CautionPlateResendActivationLink />
                  ) : (
                    <Row gridGap="6px" justifyItems="center">
                      <Text variant="ui-2-regular" colorVariant="light">
                        Thank you for confirming your email address.
                      </Text>
                      <Text variant="ui-2-regular" colorVariant="light">
                        Now you can create your first project!
                      </Text>
                    </Row>
                  ))}
                <Button
                  disabled={!enabled}
                  onClick={handleAddProjectClick}
                  data-testid="add-project-button"
                >
                  {`${upper('add project')}`}
                </Button>
              </Row>
            </Box>
          </Box>
        </RouteContent>
      </Box>
    </>
  );
}

export default DashboardEmpty;
