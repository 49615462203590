import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useCurSelUnitProps from 'containers/AnnotationSystem/hooks/useCurSelUnitProps';
import { useEffect } from 'react';

export default function useHotkeys() {
  const { curSelPointId, curEvent, curMode, curSelUnitId } =
    useAnnotationSystemState();
  const { toggleLabelSelectDropdown } = useAnnotationSystemActions();

  const { polygonPoints = [], isLabelDropdownOpen } =
    useCurSelUnitProps() ?? {};
  const {
    onSetCurSelPointId,
    onSetCurSelUnitId,
    onSetCurEvent,
    onDeletePolygonPoint,
    onSetCurMode,
    onDeleteAnnotationUnit
  } = useAnnotationSystemActions();

  useEffect(() => {
    const delCtrlPoint = () => {
      onSetCurSelPointId(null);
      if (typeof curSelPointId !== 'number') return;
      onDeletePolygonPoint(curSelPointId);
    };

    const handler = (e: KeyboardEvent) => {
      const endCreate = () => {
        /** No dots placed -> Go to default mode, kinda exit from create mode and go to select */
        if (polygonPoints.length === 1) {
          onSetCurEvent(null);
          return;
        }

        /** Less then 3 dots placed -> Remove polygon */
        if (polygonPoints.length < 4) {
          onSetCurEvent(AnnotationSystemEventsEnum.CREATE_MODE);
          /** TODO: Add DELETE_CUR_ANNOTATION_UNIT to reducer */
          curSelUnitId && onDeleteAnnotationUnit(curSelUnitId);
          return;
        }

        /** More then 3 placed dots, & 1 floating, removing last one and select created polygon */
        polygonPoints && onDeletePolygonPoint(polygonPoints?.length - 1);
        onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
      };

      switch (e.key) {
        case 'Escape': {
          if (
            curEvent === AnnotationSystemEventsEnum.CREATE_MODE &&
            curSelUnitId
          ) {
            onDeleteAnnotationUnit(curSelUnitId);
            break;
          }

          if (curEvent === null) {
            onSetCurEvent(AnnotationSystemEventsEnum.CREATE_MODE);
            break;
          }

          if (curEvent === AnnotationSystemEventsEnum.APP_POLYGON_POINT) {
            endCreate();
            break;
          }

          if (
            curMode === AnnotationSystemModesEnum.DOT_SELECT &&
            curEvent === AnnotationSystemEventsEnum.SELECTED_MODE
          ) {
            onSetCurMode(AnnotationSystemModesEnum.UNIT_SELECT);
            onSetCurSelPointId(null);
            break;
          }

          if (
            curMode === AnnotationSystemModesEnum.UNIT_SELECT &&
            curEvent === AnnotationSystemEventsEnum.SELECTED_MODE &&
            !isLabelDropdownOpen
          ) {
            onSetCurEvent(AnnotationSystemEventsEnum.CREATE_MODE);
            /**
             * TODO: Check/remove all custom action like selCurUnitId and so on, make special events in reducer, same as CREATE,
             *       so such things would be cleaned inside special events. Or move all them to spec. hooks
             */

            break;
          }

          if (
            curMode === AnnotationSystemModesEnum.UNIT_SELECT &&
            isLabelDropdownOpen &&
            curEvent === AnnotationSystemEventsEnum.SELECTED_MODE
          ) {
            toggleLabelSelectDropdown(null);
            break;
          }

          onSetCurEvent(null);

          break;
        }
        case 'Enter':
          if (
            curEvent === AnnotationSystemEventsEnum.CREATE_MODE &&
            polygonPoints.length > 3
          ) {
            endCreate();
            toggleLabelSelectDropdown(null);
            break;
          }

          if (curEvent === AnnotationSystemEventsEnum.APP_POLYGON_POINT) {
            endCreate();
            break;
          }

          if (
            curMode === AnnotationSystemModesEnum.DOT_SELECT &&
            curEvent === AnnotationSystemEventsEnum.SELECTED_MODE
          ) {
            onSetCurMode(AnnotationSystemModesEnum.UNIT_SELECT);
            onSetCurSelPointId(null);
            break;
          }

          break;
        case 'Delete':
        case 'Backspace':
          if (curSelPointId === null) break;
          delCtrlPoint();
          break;
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [
    curEvent,
    curMode,
    curSelPointId,
    onDeletePolygonPoint,
    onSetCurEvent,
    onSetCurMode,
    onSetCurSelPointId,
    onSetCurSelUnitId,
    toggleLabelSelectDropdown,
    polygonPoints,
    isLabelDropdownOpen,
    curSelUnitId,
    onDeleteAnnotationUnit
  ]);
}
