import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useSaveLearningProgressMutation,
  useGetLessonLearningMaterialQuery
} from 'generated/graphql';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import {
  getTotalLessons,
  getTotalReadLessons
} from 'containers/LearningMaterials/utils/getTotalLessons';
import useGetLessonPageFromStorage from 'containers/LearningMaterials/hooks/useGetLessonPageFromStorage';
import { useGetLearningMaterialsDataCtx } from 'containers/LearningMaterials/hooks/useLearningMaterialDataProvider';
import {
  getNextLesson,
  getPrevLesson
} from 'containers/LearningMaterials/utils/goBetweenLessons';
import { arrayModify } from 'utils/arrayModify';
import { LessonDataTypes } from 'containers/LearningMaterials/LearningMaterial.types';
import getLessonUrl from 'containers/LearningMaterials/utils/getLessonUrl';

interface LearningMaterialProps {
  lessonMaterialData: LessonDataTypes;
  isFirstLesson: boolean;
  isLastUnreadLesson: boolean;
  isFinished: boolean;
  isLoading: boolean;
  handlePrevLesson(topicId: number, lessonId: number): void;
  handleNextLesson(topicId: number, lessonId: number): void;
}

const useGetLessonMaterials = (): LearningMaterialProps => {
  const params: any = useParams();
  const history = useNavigate();
  const [page, setPage] = useGetLessonPageFromStorage();

  const { learningMaterials, setLearningMaterials } =
    useGetLearningMaterialsDataCtx();
  const { id } = useGetUserSessionData();

  const topicId = parseInt(params?.topicId, 10) ?? 1;
  const lessonId = parseInt(params?.lessonId, 10) ?? 1;

  const { data: lessonLearningMaterial, loading } =
    useGetLessonLearningMaterialQuery({
      fetchPolicy: 'network-only',
      variables: {
        helperId: parseInt(id, 10),
        lessonId,
        topicId
      }
    });
  const lessonMaterialData = lessonLearningMaterial?.getLessonLearningMaterial;

  const [saveLearningProgress] = useSaveLearningProgressMutation();

  const topicIndex = learningMaterials.findIndex((item) => item.id === topicId);
  const lessonIndex =
    learningMaterials?.[topicIndex]?.lessons?.findIndex(
      (item) => item?.id === lessonId
    ) ?? 0;
  const topicData = learningMaterials[topicIndex];
  const lessonData = {
    ...topicData?.lessons?.[lessonIndex],
    key: lessonIndex + 1
  };

  useEffect(() => {
    if (!topicId && !lessonId) {
      history(getLessonUrl(page.topicId, page.lessonId));
    }
  }, [page, topicId, lessonId, history]);

  useEffect(() => {
    if (topicId && lessonId) {
      setPage({ topicId, lessonId });
    }
  }, [topicId, lessonId, setPage]);

  const isFirstLesson =
    learningMaterials[0]?.id === topicId &&
    learningMaterials[0]?.lessons?.[0]?.id === lessonId;
  const unreadLessonsCount =
    getTotalLessons(learningMaterials) - getTotalReadLessons(learningMaterials);
  const isLastUnreadLesson = unreadLessonsCount === 1;
  const isFinished = unreadLessonsCount === 0;

  const handleChangeLessonPage = (topicId: number, lessonId: number) => {
    history(getLessonUrl(topicId, lessonId));
  };

  const handlePrevLesson = (curTopicId: number, curLessonId: number) => {
    const { topicId, lessonId } = getPrevLesson(
      curTopicId,
      curLessonId,
      learningMaterials
    );

    if (topicId && lessonId) {
      handleChangeLessonPage(topicId, lessonId);
    }
  };

  const handleNextLesson = (curTopicId: number, curLessonId: number) => {
    const currentTopic = learningMaterials.find(({ id }) => id === curTopicId);

    if (currentTopic) {
      saveLearningProgress({
        variables: {
          topic: curTopicId,
          lesson: curLessonId
        }
      });

      const updatedData = arrayModify(
        learningMaterials,
        {
          lessons: arrayModify(
            currentTopic.lessons,
            { isDone: true, id: curLessonId },
            'id'
          ),
          id: curTopicId
        },
        'id'
      );
      setLearningMaterials(updatedData);
    }

    const { topicId, lessonId } = getNextLesson(
      curTopicId,
      curLessonId,
      learningMaterials
    );

    if (topicId && lessonId) {
      handleChangeLessonPage(topicId, lessonId);
    }
  };

  return {
    lessonMaterialData: {
      topicId: topicData?.id,
      topicTitle: topicData?.title,
      ...lessonData,
      images: lessonMaterialData?.images as string[],
      practice: lessonMaterialData?.practice,
      description: lessonMaterialData?.description
    },
    isFirstLesson,
    isLastUnreadLesson,
    isFinished,
    isLoading: loading,
    handlePrevLesson,
    handleNextLesson
  };
};

export default useGetLessonMaterials;
