import React from 'react';
import { Select as AntdSelect } from 'antd';
import { Props } from './SelectFromData.types';
import { Root } from './SelectFromData.styled';
import Select from 'components/Select';
import InputLabel from 'components/InputLabel';
const { Option } = AntdSelect;

const SelectFromData = <T extends object>({
  label,
  valueRender,
  valueKey,
  onSelect,
  data,
  idKey,
  hidden = false,
  disabled,
  value,
  isRequired,
  layout,
  name,
  rules,
  ...rest
}: Props<T>) => {
  let inputLabelProps: {
    name?: string;
  } = {
    name: name ?? ''
  };

  if (name) inputLabelProps.name = name;

  return (
    <Root hidden={hidden}>
      <InputLabel
        rules={rules}
        layout={layout}
        required={isRequired}
        label={label}
        shouldUpdate={true}
        {...inputLabelProps}
      >
        <Select<string>
          {...rest}
          autoClearSearchValue={true}
          onClear={() => onSelect && onSelect('')}
          allowClear={true}
          disabled={disabled}
          defaultValue={value}
          onSelect={onSelect}
          value={value}
        >
          {data
            ? data.map((value: T) => {
                const valueIdKey =
                  idKey && idKey in value
                    ? value[idKey]
                    : JSON.stringify(value);
                return (
                  <Option
                    value={valueIdKey as string}
                    key={valueIdKey as string}
                  >
                    {
                      (valueRender
                        ? valueRender(value)
                        : valueKey
                        ? value[valueKey]
                        : JSON.stringify(value)) as React.ReactNode
                    }
                  </Option>
                );
              })
            : []}
        </Select>
      </InputLabel>
    </Root>
  );
};
export default SelectFromData;
