import React from 'react';
import { useGetHelperStatisticsDataQuery } from 'generated/graphql';

function StatisticsDataProvider({
  helperId,
  children
}: {
  helperId: number;
  children: React.ReactElement<{ data: any; loading: boolean }>;
}) {
  const { data, loading } = useGetHelperStatisticsDataQuery({
    variables: {
      helperId
    },
    fetchPolicy: 'network-only'
  });

  return (
    <>
      {React.isValidElement(children) &&
        React.cloneElement(children, {
          ...children.props,
          data: data?.helpersStats?.JSON ?? [],
          loading
        })}
    </>
  );
}

export default StatisticsDataProvider;
