import React from 'react';
import { Slide, ToastContainer as ToastContainerBase } from 'react-toastify';
import styled from 'styled-components/macro';
import { Z_INDEXES } from '../../appConstants';
import { ToastCloseButton, ToastInfoIcon } from 'components/Toast';
import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = styled(({ className }) => {
  return (
    <div className={className}>
      <ToastContainerBase
        icon={({ type }) => type === 'info' && <ToastInfoIcon />}
        transition={Slide}
        position="top-center"
        autoClose={1500}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        limit={5}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        closeButton={<ToastCloseButton />}
      />
    </div>
  );
})`
  /** Used to define container behavior: width, position: fixed etc... **/
  .Toastify__toast-container {
    z-index: ${Z_INDEXES.toaster};
  }
  /** Used to define the position of the ToastContainer **/
  .Toastify__toast-container--top-left {
  }
  .Toastify__toast-container--top-center {
  }
  .Toastify__toast-container--top-right {
  }
  .Toastify__toast-container--bottom-left {
  }
  .Toastify__toast-container--bottom-center {
  }
  .Toastify__toast-container--bottom-right {
  }
  /** Classes for the displayed toast **/
  .Toastify__toast {
    font-family: ${({ theme }) => theme.fonts.main.family};
    font-weight: 500;
    width: max-content;
    min-width: 200px;
    place-items: center;
    border-radius: 0.25rem;
    box-shadow: 0 0 3rem 0.125rem var(0, 0, 0, 0.2);
    position: relative;
    padding: 0;

    &:before {
      content: '';
      width: 10px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.bg};
    }
  }
  .Toastify__toast-icon {
  }
  .Toastify__toast--rtl {
  }
  .Toastify__toast--dark {
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--info {
    min-height: 50px;
    border: 1px solid ${({ theme }) => theme.colors.yellow};

    &:before {
      width: 0;
    }

    .Toastify__toast-icon {
      width: 40px;
    }
  }
  .Toastify__toast--success {
    &:before {
      background-color: ${({ theme }) => theme.colors.greenLight};
    }
  }
  .Toastify__toast--warning {
    &:before {
      background-color: ${({ theme }) => theme.colors.orange};
    }
  }
  .Toastify__toast--error {
    &:before {
      background-color: ${({ theme }) => theme.colors.red};
    }
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
    max-width: 440px;
  }
  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__close-button {
  }
  .Toastify__close-button--default {
  }
  .Toastify__close-button > svg {
  }
  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
  }
  /** Classes for the progress bar **/
  .Toastify__progress-bar {
  }
  .Toastify__progress-bar--animated {
  }
  .Toastify__progress-bar--controlled {
  }
  .Toastify__progress-bar--rtl {
  }
  .Toastify__progress-bar--default {
  }
  .Toastify__progress-bar--dark {
  }
`;

export default ToastContainer;
