import { Rule } from 'antd/lib/form';

export const specialChars = `_!-@#$%^&*()+={}[\\]:;"',./?\\\\|~`;
const regexMatchPassword = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${specialChars}])(?=.{8,})`
);

export const ruleSpecCharsNumsLetters = {
  pattern: new RegExp(`^[${specialChars}A-Za-z0-9 ]*$`, 'g'),
  message:
    'Must contain valid characters (latin symbols, numbers & special characters)'
};

export const inputFieldBasicRules = [
  {
    pattern: new RegExp(`^[-A-Za-z0-9 ]*$`, 'g'),
    message: 'Must contain valid characters (latin symbols, numbers)'
  },
  {
    required: true,
    whitespace: false,
    transform: (value: string) => value.trim(),
    message: 'Please enter a valid project name!'
  }
];

export const inputFieldPassword = [
  {
    pattern: regexMatchPassword,
    message:
      'Must contain at least one uppercase letter, lowercase latter, number & special character'
  }
];

export const inputFieldMustContainAtLeastOneLetter = [
  {
    pattern: new RegExp(`(?=.*[A-Za-z])`, 'g'),
    message: 'Must contain at least one letter'
  }
];

export const inputFieldMustNotContainOnlyDigitsRules = [
  {
    pattern: new RegExp(`^[^0-9]*$`, 'g'),
    message: 'Must not contain only digits'
  }
];

export const inputFieldMustNotContainOnlySpecCharsRules = [
  {
    pattern: new RegExp(`^[^${specialChars}]*$`, 'g'),
    message: 'Must not contain only special characters'
  }
];

export const titleRules = [
  ...inputFieldMustContainAtLeastOneLetter,
  ...inputFieldBasicRules
];

export const positiveNumberRules: Rule[] = [
  () => ({
    validator(rule, value) {
      if (!value) return Promise.resolve();

      if (value >= 0) {
        return Promise.resolve();
      } else if (!value) {
        return Promise.resolve();
      } else {
        return Promise.reject('Cant be lower than zero');
      }
    }
  })
];
export const passwordRules = [
  ...inputFieldPassword,
  ...[
    {
      min: 8,
      message: 'Must be contain at least 8 characters'
    }
  ]
];

const shouldContainOnlyLatinLettersNumbersSpaces = (value: string) =>
  !!(value && value.match(new RegExp(`^[A-Za-z0-9 ]*$`, 'g')));

const shouldNotContainOnlyNumbersAndSpace = (value: string) =>
  !!(value && value.match(/(?=.*[a-zA-Z])/g));

const shouldBeMoreThanNChars = (value: string, charsNum: number) =>
  !!(value && value.length >= charsNum);

export const checkIfTitleError = (value: string) => {
  if (!value) return false;

  if (!shouldContainOnlyLatinLettersNumbersSpaces(value)) {
    return 'Should contain only latin letters, numbers & spaces';
  }

  if (!shouldNotContainOnlyNumbersAndSpace(value)) {
    return 'Should not contain only numbers or spaces';
  }

  if (!shouldBeMoreThanNChars(value, 3)) {
    return 'Should contain at least 3 symbols';
  }

  return false;
};
