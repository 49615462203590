import React, { ReactElement } from 'react';
import { Box, Button, Row, Text } from 'components/_main';
import { useTheme } from 'styled-components/macro';

interface Props {
  icon: ReactElement;
  title: string;
  description: string;
  cardWidth?: number;
  onAdd?: () => void;
}

export function Card({ description, icon, onAdd, title, cardWidth }: Props) {
  const theme = useTheme();
  return (
    <Row
      noGap
      p="30px"
      borderColor={theme.colors.lighter}
      borderRadius="5px"
      borderWidth="1px"
      borderStyle="solid"
      gridAutoRows="max-content max-content 1fr max-content"
      height="230px"
      width={cardWidth}
    >
      <Box justifySelf="flex-start">{icon}</Box>
      <Text pt="20px" variant="ui-1-bold">
        {title}
      </Text>
      <Box pt="10px" alignSelf="flex-start" overflow="hidden">
        <Text truncate>{description}</Text>
      </Box>
      <Box>
        <Button
          onClick={onAdd}
          variant="ghost"
          noPadding
          noHover
          height="max-content"
        >
          + ADD TASK
        </Button>
      </Box>
    </Row>
  );
}
