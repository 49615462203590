import { Col } from 'components/_main';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import React from 'react';
import { varEnv } from 'utils/envVars';
import { PricingPlan, PricingPlanFeature } from './PricingPlans.ui';

interface Props {
  onClick?: () => void;
}

function PricingPlans({ onClick }: Props) {
  const { id } = useGetUserSessionData();

  const handleContactSales = (upgradeToPlan: string) => () => {
    const a = document.createElement('a');
    a.href = `mailto:${varEnv.emailHelpAddresses}?subject="Client ID: ${id}. Upgrade plan to: ${upgradeToPlan}"`;
    a.click();
    a.remove();
    onClick && onClick();
  };

  return (
    <Col width="100%" flexCenter pt="25px" gridGap="30px">
      <PricingPlan
        onContactSales={handleContactSales('Team')}
        title="Team"
        description="By choosing the Team plan, our support will help you take the best possible pricing model, considering your requirements and deadlines."
        planType="team"
      >
        <PricingPlanFeature title="All types of data labeling" />
        <PricingPlanFeature title="All types of report" />
        <PricingPlanFeature title="No limitation with data uploads" />
        <PricingPlanFeature
          title="Scale on-demand"
          description="100+ helpers available"
        />
        <PricingPlanFeature
          title="Agile pricing strategies"
          description="pay per image, pay per frame, pay per hour, pay per project, pay per helper."
        />
      </PricingPlan>
      <PricingPlan
        onContactSales={handleContactSales('Enterprise')}
        title="Enterprise"
        description="In the Enterprise plan, we cover all the processes of creating a team or a back-office for your project. The team can work on our software or your own."
        planType="enterprise"
      >
        <PricingPlanFeature title="Recruitment processes" />
        <PricingPlanFeature title="Dedicated helpers working only for your projects" />
        <PricingPlanFeature title="Specific domain knowledge" />
        <PricingPlanFeature title="Web application for training data and requirements for data trainers" />
      </PricingPlan>
    </Col>
  );
}

export default PricingPlans;
