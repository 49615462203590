import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import { useTheme, css } from 'styled-components/macro';
import { Box, Col, ButtonIcon, Row, Text } from 'components/_main';
import { CrossIcon } from 'assets';
import { SectionFramesPagination } from './SectionFramePagination';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import {
  COUNT_PER_PAGE,
  getTaskIndex,
  STATUS_OPTIONS
} from './SectionFrames.constants';
import {
  SectionFramesContentProps,
  SectionFramesHeaderProps,
  FrameTooltipProps
} from './SectionFrames.types';
import Icon from 'components/Icon';

export function SectionFramesContent({
  taskList,
  total,
  activeTaskId,
  handleSelectTask,
  handleHidePopover
}: SectionFramesContentProps) {
  const theme = useTheme();
  const { pageFrames } = useAnnotationSystemState();

  const data = useMemo(
    () =>
      taskList?.slice(
        pageFrames * COUNT_PER_PAGE,
        (pageFrames + 1) * COUNT_PER_PAGE
      ) || [],
    [taskList, pageFrames]
  );

  const getTooltipTitle = (taskId: number) => {
    if (!taskList?.length) return '';

    const frameIndex = getTaskIndex(taskList, +taskId);
    return `${frameIndex}/${total}`;
  };

  return (
    <Row minWidth="544px" noGap>
      <SectionFramesHeader handleHidePopover={handleHidePopover} />
      <SectionFramesPagination total={total}>
        <Col
          gridColumn="2/3"
          gridRow="1/2"
          gridAutoColumns="max-content 1fr"
          alignItems="center"
          gridGap="2px"
          width="100%"
        >
          <Col gridGap="2px" height={60} alignItems="center">
            {data?.map(({ taskId, isAvailable }) => (
              <FrameTooltip
                key={taskId}
                title={getTooltipTitle(taskId)}
                isAvailable={isAvailable}
                isActive={activeTaskId === taskId}
                onClick={() => handleSelectTask({ taskId, isAvailable })}
              />
            ))}
          </Col>
          <Box
            height="40px"
            width="100%"
            backgroundColor={theme.colors.lighter}
            borderRadius="5px"
          ></Box>
        </Col>

        <Col
          width="100%"
          justifyContent="space-between"
          gridColumn="2/3"
          gridRow="2/3"
        >
          <Text ml="0" variant="ui-small-regular" color={theme.colors.light}>
            {pageFrames * COUNT_PER_PAGE + 1}
          </Text>
          <Text mr="0" variant="ui-small-regular" color={theme.colors.light}>
            {(pageFrames + 1) * COUNT_PER_PAGE}
          </Text>
        </Col>
      </SectionFramesPagination>
    </Row>
  );
}

function SectionFramesHeader({ handleHidePopover }: SectionFramesHeaderProps) {
  const theme = useTheme();

  return (
    <Col
      width="100%"
      justifyContent="space-between"
      padding="5px 10px 15px 20px"
      borderBottom={`1px solid ${theme.colors.lightest}`}
      alignItems="center"
    >
      <Col gridGap="30px" alignItems="center">
        <Text variant="ui-3-bold">Select Frame</Text>
        <Col gridGap="20px">
          {STATUS_OPTIONS.map(({ id, label, color }) => (
            <Col key={id}>
              <Box
                width="8px"
                height="16px"
                borderRadius="5px"
                backgroundColor={theme.colors[color]}
              />
              <Text variant="ui-small-regular">{label}</Text>
            </Col>
          ))}
        </Col>
      </Col>
      <Icon
        hoverStrokeColor="fg"
        size={20}
        color={theme.colors.light}
        onClick={handleHidePopover}
      >
        <CrossIcon />
      </Icon>
    </Col>
  );
}

function FrameTooltip({
  title,
  isAvailable,
  isActive,
  onClick
}: FrameTooltipProps) {
  const theme = useTheme();
  const { isViewOnly } = useAnnotationSystemState();

  const activeStyle = css`
    height: 60px;
    background-color: ${theme.colors.orange};
    &:hover {
      height: 60px;
    }
  `;

  const availableStyle = css`
    &:hover {
      cursor: pointer;
      height: 48px;
    }
    background-color: ${theme.colors.primary};
  `;

  const activeDisabledStyle = css`
    background-color: ${theme.colors.light};
  `;

  return (
    <Tooltip title={title}>
      <Box
        width="8px"
        height="40px"
        backgroundColor={theme.colors.light}
        borderRadius="5px"
        css={`
          transition: all 0.3ms;
          ${isAvailable && availableStyle}
          ${isActive && activeStyle}
          ${isViewOnly && isActive && activeDisabledStyle}
        `}
        onClick={onClick}
      />
    </Tooltip>
  );
}
