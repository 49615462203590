import React from 'react';
import { Tooltip } from 'antd';
import { Col } from 'components/_main';
import { ActivateIcon, EditIcon } from 'assets/tableRowActions';
import {
  ACTIVATE_DATA_COMPONENT,
  EDIT_DATA_COMPONENT
} from 'containers/Projects/constants';
import { Project } from 'generated/graphql';
import { ProjectDetails } from 'appTypes';
import Icon from 'components/Icon';

interface AdminActionsPendingProjectsProps {
  onEdit: (data: ProjectDetails) => void;
  onActivate: (data: ProjectDetails) => void;
}

type RowActionsPendingProjectsProps = AdminActionsPendingProjectsProps & {
  data: Project;
};

function RowActionsPendingProjects({
  data,
  onEdit,
  onActivate
}: RowActionsPendingProjectsProps) {
  const handleEdit = () => {
    onEdit && onEdit(data);
  };

  const handleActivate = () => {
    onActivate && onActivate(data);
  };

  return (
    <Col className="row-actions">
      <Tooltip title="Activate">
        <Icon
          hoverStrokeColor="fg"
          data-component={ACTIVATE_DATA_COMPONENT}
          onClick={handleActivate}
        >
          <ActivateIcon />
        </Icon>
      </Tooltip>
      <Tooltip title="Edit">
        <Icon
          hoverStrokeColor="fg"
          data-component={EDIT_DATA_COMPONENT}
          onClick={handleEdit}
        >
          <EditIcon />
        </Icon>
      </Tooltip>
    </Col>
  );
}

const adminPendingProjectsActions =
  ({ onEdit, onActivate }: AdminActionsPendingProjectsProps) =>
  (data: Project) =>
    (
      <RowActionsPendingProjects
        data={data}
        onEdit={onEdit}
        onActivate={onActivate}
      />
    );

export default adminPendingProjectsActions;
