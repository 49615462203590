export const RATE_MULITPLIER = 100;

export const parseRate = (rate?: number | string | null) => {
  return rate
    ? Math.trunc(parseFloat(rate as unknown as string) * RATE_MULITPLIER)
    : null;
};

export const rateToFloat = (rate?: number | null): number | undefined => {
  return rate ? parseFloat((rate / RATE_MULITPLIER).toFixed(2)) : undefined;
};

export const renderRate = (
  rate?: number | null,
  {
    afterDecimal,
    beforeDecimal,
    defaultReturn = ''
  }: {
    beforeDecimal?: boolean;
    afterDecimal?: boolean;
    defaultReturn?: string;
  } = {}
) => {
  if (beforeDecimal) {
    return rate
      ? `$${parseInt((rate / RATE_MULITPLIER).toString(), 10).toLocaleString()}`
      : defaultReturn;
  }

  if (afterDecimal) {
    return rate
      ? `${((rate / RATE_MULITPLIER) % 1)
          .toFixed(2)
          .substring(1)
          .toLocaleString()}`
      : defaultReturn;
  }

  return rate ? `$${(rate / RATE_MULITPLIER).toLocaleString()}` : defaultReturn;
};
