import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'utils/toast';
import { Col, Row, Button } from 'components/_main';
import { InputLabelField } from 'components/_form';
import {
  UpdateClientInfoMutation,
  useUpdateClientInfoMutation
} from 'generated/graphql';
import { updateClientInfoValidationSchema } from 'utils';
import { ProfileDetailsProps } from 'containers/ProfileDetails/ProfileDetails.types';
import { useAppContext } from 'containers/App';

function PersonalInfo(props: ProfileDetailsProps) {
  const initialValues = {
    firstName: props?.firstName,
    lastName: props?.lastName,
    companyName: props?.companyName,
    email: props?.email
  };

  const { updateCurrentUser } = useAppContext();

  const handleOnComplete = useCallback(
    ({ updateClientInfo }: UpdateClientInfoMutation) => {
      updateCurrentUser({ ...updateClientInfo }, true);
      toast.success('Profile information updated successfully.');
    },
    [updateCurrentUser]
  );

  const [updateClientInfo] = useUpdateClientInfoMutation({
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: handleOnComplete
  });

  const handleSubmit = async (values: any) => {
    await updateClientInfo({
      variables: { ...values, clientId: parseInt(props?.id, 10) }
    });
  };

  return (
    <Col gridGap="40px" wide maxWidth="600px">
      <Formik
        initialValues={initialValues}
        validationSchema={updateClientInfoValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <Col gridAutoColumns="1fr 1fr" width="100%">
              <InputLabelField
                name="firstName"
                placeholder="First Name"
                label="First Name *"
                layout="vertical"
              />
              <InputLabelField
                name="lastName"
                placeholder="Last Name"
                label="Last Name *"
                layout="vertical"
              />
            </Col>
            <InputLabelField
              name="email"
              placeholder="Email"
              label="Email"
              layout="vertical"
              disabled
            />
            <InputLabelField
              name="companyName"
              placeholder="Company"
              label="Company"
              layout="vertical"
              disabled
            />
          </Row>
          <Button type="submit" marginTop="30px">
            UPDATE
          </Button>
        </Form>
      </Formik>
    </Col>
  );
}

export default PersonalInfo;
