import { useEffect, useState } from 'react';
import {
  GetProjectDetailsChartDataQuery,
  useGetProjectDetailsChartDataLazyQuery
} from 'generated/graphql';

const useGetChartData = (prjId?: number | string) => {
  const [prjParsedData, setPrjParsedData] =
    useState<GetProjectDetailsChartDataQuery['project']>();

  const parsedPrjId = typeof prjId === 'string' ? parseInt(prjId, 10) : prjId;

  const [getPrjDetails, { data: prjDataResp, loading, error }] =
    useGetProjectDetailsChartDataLazyQuery({
      fetchPolicy: 'network-only'
    });

  useEffect(() => {
    if (!parsedPrjId) return;

    getPrjDetails({
      variables: {
        projectId: parsedPrjId
      }
    });
  }, [getPrjDetails, parsedPrjId]);

  useEffect(() => {
    const prjData = prjDataResp?.project;

    if (!prjData || prjData.__typename !== 'Project') return;

    setPrjParsedData(prjData);
  }, [prjDataResp]);

  return {
    data: prjParsedData,
    error,
    loading
  };
};

export default useGetChartData;
