import React from 'react';
import { ProjectStatus as ProjectStatusType } from 'generated/graphql';
import { Select } from 'antd';
import { SelectProjectStatusStyled } from './ProjectStatus.ui';

interface ProjectStatusOptionType {
  value: ProjectStatusType;
  title: string;
}

interface ProjectStatusComponentType {
  value: ProjectStatusType;
  defaultValue: ProjectStatusType;
  options: ProjectStatusOptionType[];
  onSelect?: (value: ProjectStatusType) => void;
}

const ProjectStatus = ({
  value,
  defaultValue,
  options,
  onSelect
}: ProjectStatusComponentType) => {
  const handleSelect = (value: ProjectStatusType) => {
    onSelect && onSelect(value);
  };

  return (
    <SelectProjectStatusStyled
      value={value}
      defaultValue={defaultValue}
      onSelect={handleSelect}
    >
      <>
        {options.map(({ value, title }) => (
          <Select.Option value={value}>{title}</Select.Option>
        ))}
      </>
    </SelectProjectStatusStyled>
  );
};

export default ProjectStatus;
