import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { upper, RATE_MULITPLIER, renderRate } from 'utils';
import { HelperStats } from 'generated/graphql';
import HelperName from 'components/HelperName';

const columns: ColumnsType<object> = [
  {
    title: upper('Helper'),
    width: 50,
    render: HelperName
  },
  {
    title: upper('Submitted Tasks'),
    width: 50,
    render: (value: HelperStats) => {
      const { helperPoints } = value || {};
      return <>{helperPoints}</>;
    }
  },
  {
    title: upper('Penalties'),
    dataIndex: 'penalties',
    width: 50
  },
  {
    title: upper('Helper points'),
    width: 50,
    render: (value: HelperStats) => {
      const { helperPoints, penalties } = value || {};
      return <>{helperPoints - penalties}</>;
    }
  },
  {
    title: upper('Annotation rate'),
    width: 50,
    render: (value: HelperStats) => {
      const { helperRate } = value || {};
      return <>{renderRate(helperRate)}</>;
    }
  },
  {
    title: upper('Audited tasks'),
    width: 50,
    render: (value: HelperStats) => {
      const { auditorTasksCount } = value || {};
      return <>{auditorTasksCount}</>;
    }
  },
  {
    title: upper('Audited points'),
    width: 50,
    render: (value: HelperStats) => {
      const { auditorPoints } = value || {};
      return <>{auditorPoints}</>;
    }
  },
  {
    title: upper('Audit rate'),
    width: 50,
    render: (value: HelperStats) => {
      const { auditorRate } = value || {};
      return <>{renderRate(auditorRate)}</>;
    }
  },
  {
    title: upper('Total earned'),
    width: 50,
    render: (value: HelperStats) => {
      const {
        auditorRate,
        auditorPoints,
        helperRate,
        helperPoints,
        penalties
      } = value || {};
      const result =
        auditorPoints * auditorRate + (helperPoints - penalties) * helperRate;
      return <>{`$${result ? (result / RATE_MULITPLIER).toFixed(3) : 0}`}</>;
    }
  }
];

export default columns;
