import {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import { consoleLog } from 'utils';
import getHtmlElementSize from 'utils/getHtmlElementSize';
import { prevWrapperSizeInit } from '../AnnotationSystem.constants';
import {
  AnnotationSystemLayerProps,
  AnnotationSystemProps,
  PrevWrapperSize
} from '../AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../context';
import { getSizeInsideWrapper, Media } from '../utils';

interface Props
  extends Pick<
      AnnotationSystemLayerProps,
      | 'onGetWrapperSize'
      | 'wrapperRef'
      | 'isAutoSetSizeToWrapper'
      | 'onMediaLoadSuccess'
    >,
    Pick<
      AnnotationSystemProps,
      | 'mediaUrl'
      | 'videoStart'
      | 'videoEnd'
      | 'taskId'
      | 'results'
      | 'isViewOnly'
    > {
  mediaRef: MutableRefObject<Media | null>;
  adjustToHeight?: boolean;
}

export default function useAdjustSizeToWrapper({
  isAutoSetSizeToWrapper,
  onGetWrapperSize,
  wrapperRef,
  mediaRef,
  onMediaLoadSuccess,
  adjustToHeight,
  mediaUrl,
  results,
  taskId,
  videoEnd,
  videoStart,
  isViewOnly
}: Props) {
  const prevWrapperSize = useRef<PrevWrapperSize>(prevWrapperSizeInit);

  const { onSetInitSize, onSetMediaLoadingFinish, onSetIsLoading } =
    useAnnotationSystemActions();
  const { w, h, type } = useAnnotationSystemState();

  const handleAdjustSizeToWrapper = useCallback(
    (event?: MouseEvent) => {
      /** Resize media asset to match outer (wrapper) size (width & height) */
      const mediaElement: any = event?.currentTarget ?? mediaRef.current;

      if (!mediaElement) {
        return;
      }

      /** When auto-size is on and custom resize handler provided */
      if (isAutoSetSizeToWrapper && onGetWrapperSize) {
        const { wrapperW, wrapperH } = onGetWrapperSize();
        const { w, h, initW, initH } = getSizeInsideWrapper(
          mediaElement,
          wrapperW,
          wrapperH
        );
        setTimeout(() => {
          onSetMediaLoadingFinish({
            ref: mediaElement,
            w,
            h,
            initW,
            initH,
            mediaUrl,
            results,
            taskId,
            videoEnd,
            videoStart,
            isViewOnly
          });
        }, 0);
      }

      /** When custom resize handler NOT provided */
      if (isAutoSetSizeToWrapper && !onGetWrapperSize && wrapperRef) {
        const { w: wrapperW, h: wrapperH } = getHtmlElementSize(wrapperRef);

        if (wrapperW === 0 || wrapperH === 0) {
          consoleLog({
            message: 'Wrapper size is zero',
            wrapperW,
            wrapperH
          });
        }

        const { w, h, initH, initW } = getSizeInsideWrapper(
          mediaElement,
          wrapperW,
          wrapperH,
          adjustToHeight
        );

        setTimeout(() => {
          onSetMediaLoadingFinish({
            ref: mediaElement,
            w,
            h,
            initW,
            initH,
            mediaUrl,
            results,
            taskId,
            videoEnd,
            videoStart,
            isViewOnly
          });
        }, 0);
      }

      onMediaLoadSuccess &&
        onMediaLoadSuccess({
          mediaElement:
            type === 'video'
              ? (mediaElement as HTMLVideoElement)
              : (mediaElement as HTMLImageElement),
          onSetInitSizeCb: onSetInitSize,
          onSetIsLoadingCb: onSetIsLoading
        });
    },
    [
      adjustToHeight,
      isAutoSetSizeToWrapper,
      isViewOnly,
      mediaRef,
      mediaUrl,
      onGetWrapperSize,
      onMediaLoadSuccess,
      onSetInitSize,
      onSetIsLoading,
      onSetMediaLoadingFinish,
      results,
      taskId,
      type,
      videoEnd,
      videoStart,
      wrapperRef
    ]
  );

  useLayoutEffect(() => {
    /** Control dynamic re-size of wrapper */
    const resize_ob = new ResizeObserver(function (entries) {
      for (let entry of entries) {
        const entryBox = entry.contentBoxSize?.[0];
        const prevSize = prevWrapperSize.current;
        if (entryBox) {
          if (
            prevSize.h !== entryBox.blockSize ||
            prevSize.w !== entryBox.inlineSize
          ) {
            // handleAdjustSizeToWrapper(undefined);
            prevSize.h = entryBox.blockSize;
            prevSize.w = entryBox.inlineSize;
          }
        }
      }
    });

    if (wrapperRef) {
      /** Initialize */
      resize_ob.observe(wrapperRef);
    }

    if (!w || !h) {
      handleAdjustSizeToWrapper(undefined);
    }
  }, [h, handleAdjustSizeToWrapper, w, wrapperRef]);

  return useMemo(
    () => ({
      handleAdjustSizeToWrapper
    }),
    [handleAdjustSizeToWrapper]
  );
}
