import React from 'react';
import { MainRootUi, ModalStyled, TitleHeader } from './ui';
import AvatarGenerator from 'components/AvatarGenerator';
import BackToProjectOrClientModalTitle from 'components/BackToProjectOrClientModalTitle';
import ClientCardModalContent from './ClientCard.Modal.Content';
import { useClientCardCtx } from './ctx';
import useEditMutationProvider from 'hooks/useClientEditMutationProvider';
import TitleHeaderContent from './components/TitleHeaderContent';

function ClientCardModal() {
  const {
    data,
    curPrjData,
    onClose,
    isOpen,
    refetchQueries,
    setLoading,
    onCurPrjClose
  } = useClientCardCtx();

  const [editRender, editLoading] = useEditMutationProvider({
    component: React.useMemo(
      () =>
        ({ onSubmit, loading }: any) =>
          data ? (
            <TitleHeaderContent
              email={data.email}
              firstName={data.firstName}
              lastName={data.lastName}
              companyName={data.companyName}
              id={data.id}
              isEditable={!curPrjData?.isOpen}
              onSubmit={onSubmit}
              loading={loading}
            />
          ) : null,
      [curPrjData, data]
    ),
    refetchQueries
  });

  React.useEffect(() => {
    setLoading({ editLoading });
  }, [editLoading, setLoading]);

  if (!data) return null;

  return (
    <ModalStyled
      destroyOnClose
      title={
        curPrjData?.isOpen ? (
          <BackToProjectOrClientModalTitle
            {...data}
            onClose={onCurPrjClose}
            projectTitle={curPrjData?.data?.title}
          />
        ) : (
          <TitleHeader>
            <>
              <AvatarGenerator
                firstName={data?.firstName}
                lastName={data?.lastName}
              />
              {editRender}
            </>
          </TitleHeader>
        )
      }
      visible={isOpen}
      footer={false}
      onCancel={onClose}
      centered={true}
    >
      <MainRootUi>
        <ClientCardModalContent />
      </MainRootUi>
    </ModalStyled>
  );
}

export default ClientCardModal;
