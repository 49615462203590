import React, { useContext } from 'react';
import { useListProjectsQuery } from 'generated/graphql';

export const ctx = React.createContext<any>([{}, false]);

function useClientProjectsHistoryDataProvider({ clientId }: any) {
  const { data, loading } = useListProjectsQuery({
    variables: {
      clientId: parseInt(clientId, 10)
    }
  });

  const finalData = data?.listProjects?.projects;

  return [
    ({ children }: any) => (
      <ctx.Provider value={[finalData, loading]}>{children}</ctx.Provider>
    ),
    finalData,
    loading
  ] as const;
}

export function ClientProjectsHistoryDataProvider({ children, clientId }: any) {
  const { data, loading } = useListProjectsQuery({
    variables: {
      clientId: parseInt(clientId, 10)
    }
  });

  const finalData = [data?.listProjects?.projects, loading];

  return <ctx.Provider value={finalData}>{children}</ctx.Provider>;
}

export function useGetClientProjectsHistoryData() {
  const [data, loading] = useContext<any>(ctx);

  return [data, loading];
}

export default useClientProjectsHistoryDataProvider;
