import { Box } from 'components/_main';
import { Client } from 'generated/graphql';
import React from 'react';

export default function pricingPlanStatusColumn(data: Client) {
  if (data.isPremium) {
    return <Box>⭐ Premium</Box>;
  }

  return null;
}
