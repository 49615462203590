import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Form as AntForm } from 'antd';
import { ResetPasswordFormValues } from './types';
import { toast } from 'utils/toast';
import * as yup from 'yup';
import { password } from 'utils/formikFormValidationHelpers';
import { useRedirect } from 'hooks/useRedirect';
import { usePasswordResetMutation } from 'generated/graphql';
import AuthPageView from 'containers/AuthPageViewNew';
import { Container, Button, Text } from 'components/_main';
import { InputLabelField } from 'components/_form';
import AuthFormWrapper from 'containers/AuthPageViewNew/AuthFormWrapper';

const FormItem = AntForm.Item;

export const ResetPasswordForm = () => {
  const [, , token] = window.location.pathname.split('/');
  const handleRedirect = useRedirect();
  const [resetPassword, { loading }] = usePasswordResetMutation({
    onCompleted: () => {
      toast.success('Your password successfully changed');
      handleRedirect('/signin');
    },
    onError: (e: Error) => toast.error(e.message)
  });

  const initialValues: ResetPasswordFormValues = {
    token,
    new_password: ''
  };
  React.useEffect(() => {
    if (!token) {
      handleRedirect('/signin');
      toast.error('Invalid one time password');
    }
  }, [token, handleRedirect]);

  return (
    <AuthPageView>
      <AuthPageView.PaneChild>
        <Container
          width="440px"
          minWidth="440px"
          flexDirection="column"
          gridGap="30px"
        >
          <Text variant="ui-4-bold">Reset Password</Text>
          <Formik
            initialValues={initialValues}
            validationSchema={yup.object({
              new_password: password
            })}
            onSubmit={async (values: any) => {
              if (!token) return;
              await resetPassword({ variables: values });
            }}
          >
            {({ submitForm }: FormikProps<ResetPasswordFormValues>) => (
              <AuthFormWrapper submitForm={submitForm}>
                <InputLabelField
                  name="new_password"
                  password
                  placeholder="Please, enter new password"
                />

                <FormItem noStyle>
                  <Button
                    width="100%"
                    maxWidth="100%"
                    isLoading={loading}
                    onClick={submitForm}
                  >
                    RESET
                  </Button>
                </FormItem>
              </AuthFormWrapper>
            )}
          </Formik>
        </Container>
      </AuthPageView.PaneChild>
    </AuthPageView>
  );
};
