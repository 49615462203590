import { rateCantBeBelowZero } from '../../../utils';
import * as yup from 'yup';

export const stepPricesValidation = yup.object({
  helperRate: yup
    .string()
    .nullable()
    .test('cantBeBelowZero', "Can't be lower than 0", rateCantBeBelowZero),
  auditorRate: yup
    .string()
    .nullable()
    .test('cantBeBelowZero', "Can't be lower than 0", rateCantBeBelowZero)
});
