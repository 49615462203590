import { Select as AntdSelect } from 'antd';
import { SelectProps } from 'appTypes';
import React, { useRef } from 'react';
import { SelectStyled } from './styled';
import { ReactComponent as ArrowDownIcon } from './arrowDown.svg';
import Icon from 'components/Icon';

const { Option: SelectOption } = AntdSelect;

export { SelectOption };

function Select<T extends object | string = string>({
  small,
  ...restProps
}: SelectProps<T>) {
  const ref = useRef<HTMLDivElement>(null);

  const props = {
    ...(!restProps.loading && {
      suffixIcon: (
        <Icon>
          <ArrowDownIcon />
        </Icon>
      )
    })
  };

  return (
    <div ref={ref} data-type="select-wrapper">
      <SelectStyled
        small={small}
        getPopupContainer={() => ref.current ?? new HTMLElement()}
        {...props}
        {...restProps}
      />
    </div>
  );
}

export default Select;
