import { Client, useListClientsLazyQuery } from 'generated/graphql';
import { useEffect, useMemo } from 'react';

export type TUseGetListClientsItemData = {} & Pick<
  Client,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'isDeleted'
  | 'activated'
  | 'enabled'
  | 'companyName'
>;

const useGetListClients = (helperId?: string | number | null) => {
  const [query, { data, loading }] = useListClientsLazyQuery({
    variables: {
      returnAllData: true
    }
  });

  useEffect(() => {
    if (helperId) {
      query({
        variables: {
          helperId:
            typeof helperId === 'number' ? helperId : parseInt(helperId, 10)
        }
      });
    } else {
      query();
    }
  }, [helperId, query]);

  const finalData = useMemo(() => {
    return data?.listClients?.clients?.reduce(
      (accum: TUseGetListClientsItemData[], value) => {
        value &&
          value.enabled &&
          !value.isDeleted &&
          accum.push({
            id: value?.id as unknown as string,
            firstName: value?.firstName,
            lastName: value?.lastName,
            companyName: value?.companyName,
            isDeleted: value?.isDeleted,
            activated: value?.activated,
            enabled: value?.enabled
          });

        return accum;
      },
      []
    );
  }, [data]);

  return {
    data: finalData,
    loading: loading
  };
};

export default useGetListClients;
