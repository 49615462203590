import { useCallback, useEffect, useRef, useState } from 'react';
import { useAutosaveTaskMutation } from 'generated/graphql';
import { toast } from 'utils/toast';
import { AUTOSAVE_TIMEOUT, AUTOSAVE_LOADER_DELAY } from 'appConstants';
import useGetResults from 'containers/AnnotationSystem/hooks/useGetResults';
import { consoleLog } from 'utils';
import envVars from 'utils/envVars';
import useSaveResultsLocally from 'hooks/useSaveResultsLocally';
import { TTasksTaskDataResult } from 'containers/TasksRouter/Tasks.types';

function useAutoSaveTask({
  taskId,
  onCompleted
}: {
  taskId: number;
  taskType: string;
  onCompleted?: () => void;
}) {
  const getResults = useGetResults();
  const { saveResultsLocally } = useSaveResultsLocally();

  const autosaveInterval = useRef<number>(0);
  const resultAnnotation = useRef<{ data: TTasksTaskDataResult }>({ data: [] });
  const [loadingAutosave, setLoadingAutosave] = useState(false);

  const [startAutosaveTask, { loading }] = useAutosaveTaskMutation({
    onCompleted: () => {
      if (onCompleted) {
        onCompleted();
      }
    },
    onError: () => {
      toast.error('Autosave error', {
        toastId: 'autosaveError'
      });
      clearInterval(autosaveInterval.current);
    }
  });

  /**
   * Show auto save loading with delay
   */
  useEffect(() => {
    if (loading) {
      setLoadingAutosave(loading);

      setTimeout(() => {
        setLoadingAutosave(false);
      }, AUTOSAVE_LOADER_DELAY);
    }
  }, [loading]);

  /**
   * Auto save every 30 seconds
   */
  useEffect(() => {
    if (envVars.isSb) return;

    autosaveInterval.current = setInterval(async () => {
      if (!resultAnnotation.current) {
        return;
      }

      startAutosaveTask({
        variables: {
          result: resultAnnotation.current,
          taskId
        }
      });
      saveResultsLocally(taskId, resultAnnotation.current.data);
    }, AUTOSAVE_TIMEOUT) as unknown as number;

    return () => {
      clearInterval(autosaveInterval.current);
    };
  }, [startAutosaveTask, taskId, saveResultsLocally]);

  useEffect(() => {
    const results = getResults();

    if (!results) {
      return;
    }

    resultAnnotation.current = results;
  }, [getResults]);

  const handleOnSave = useCallback(async () => {
    const results = getResults();

    if (!results) {
      consoleLog('No results');
      return;
    }

    await startAutosaveTask({
      variables: {
        result: results,
        taskId
      }
    });
    saveResultsLocally(taskId, results.data);
  }, [getResults, startAutosaveTask, taskId, saveResultsLocally]);

  return { loadingAutosave, handleOnSave, startAutosaveTask };
}

export default useAutoSaveTask;
