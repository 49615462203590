import styled, { css } from 'styled-components/macro';
import { Select as AntdSelect } from 'antd';
import { FunctionComponent } from 'react';
import { SelectProps } from 'appTypes';

export const SelectStyled = styled<FunctionComponent<SelectProps<any>>>(
  AntdSelect
)(
  ({ small, theme }) => css`
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 50px;
      display: flex;
      align-items: center;
      min-width: 120px;
      border-radius: 5px;
      border: 1px solid ${theme.colors.lighter};
      padding: 0 20px;
    }

    &.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none !important;
      border-color: ${theme.colors.primary};
    }

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: ${theme.colors.primary};
    }

    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 125%;
    }

    & .ant-select-selection-placeholder {
      line-height: 20px !important;
    }

    & .ant-select-selection-search {
      display: grid;
      align-items: center;
    }

    &.ant-select-arrow {
      svg,
      svg path {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }

    &.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      right: 20px;
      left: 20px;
    }

    &.ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-placeholder {
      font-weight: 300;
      color: ${({ theme }) => theme.colors.light};
      opacity: 1;
    }

    ${small &&
    css`
      &.ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector {
        height: 30px;
        padding: 5px 8px 4px 15px;
      }
    `}
  `
);
