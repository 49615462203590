import * as yup from 'yup';
import { AddProjectWizardContextState } from '../AddProjectWizard.types';

type Props = Partial<Pick<AddProjectWizardContextState, 'isMultiLevelLabels'>>;

const StepLabelsValidation = ({ isMultiLevelLabels, ...restProps }: Props) => {
  return yup.object({
    // @ts-ignore
    isLabelsCorrect: yup.string().test(function () {
      // @ts-ignore
      const { labels, mlLabels } = this.parent;

      if (isMultiLevelLabels) {
        if (
          !mlLabels ||
          !mlLabels?.multilabels ||
          mlLabels.multilabels.length === 0
        ) {
          const errorMessage = 'Please create at least one multi-label';
          return this.createError({
            message: errorMessage,
            path: 'mlLabels.multilabels'
          });
        }
        return true;
      } else {
        if (!labels || labels.length === 0) {
          return this.createError({
            message: 'Please create at least one label',
            path: 'labels'
          });
        }

        return true;
      }
    })
  });
};

export default StepLabelsValidation;
