import React from 'react';
import ClientCardModal from './ClientCard.Modal';
import { ClientCardCtxProvider } from './ctx';
import { ClientCardProps } from './types';

function ClientCard({
  data,
  isOpen,
  onClose,
  refetchQueries
}: ClientCardProps) {
  return (
    <ClientCardCtxProvider
      data={data}
      isOpen={isOpen}
      onClose={onClose}
      refetchQueries={refetchQueries}
    >
      <ClientCardModal />
    </ClientCardCtxProvider>
  );
}

export default ClientCard;
