import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  AnnotationSystemEventsEnum,
  AnnotationUnitCoords,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystem,
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import {
  convertPercentageToPixelsRelativeToOuterRect,
  findLastGroupIndex,
  getAnnotationUnitsWrapperSize,
  preventResizeInverse
} from 'containers/AnnotationSystem/utils';
import useMousePositionRef, {
  MousePosStateRef
} from 'hooks/useMousePositionRef';
import { getSvgObjSizes } from 'utils';
import { humanPosesLabels } from 'containers/AnnotationSystem/AnnotationSystem.config';

interface Props
  extends Pick<
    ReturnType<typeof useMousePositionRef>,
    'onStart' | 'onReset' | 'stateRef'
  > {
  svgRef: React.MutableRefObject<SVGSVGElement | null> | null;
}

const DEFAULT_LABEL = 'Human';

export default function useMouseDown({
  svgRef,
  onStart,
  onReset,
  stateRef
}: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const preFinalPosStateRef = useRef<AnnotationUnitCoords | null>(null);

  const dots = useAnnotationSystem();
  const { curEvent, svgLayerRef, curZoomLevel } = useAnnotationSystemState();
  const { onSetCurEvent, onAddAnnotationUnit, onSetCurSelUnitId } =
    useAnnotationSystemActions();

  const handleMouseMoveStart = useCallback(
    (state: MutableRefObject<MousePosStateRef>) => {
      const svgElement = svgRef?.current;

      if (!svgElement) {
        return;
      }

      const { x, w, h, y } = preventResizeInverse(state.current);

      svgElement?.setAttribute('x', x.toString());
      svgElement?.setAttribute('y', y.toString());
      svgElement?.setAttribute('height', h.toString());
      svgElement?.setAttribute('width', w.toString());
    },
    [svgRef]
  );

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      switch (curEvent) {
        case null: {
          return;
        }
        case AnnotationSystemEventsEnum.CREATE_MODE: {
          if (!svgRef) return;
          onStart({
            event,
            initPos: {
              x: 0,
              y: 0,
              w: 0,
              h: 0
            },
            onUpdate: handleMouseMoveStart
          });
          setIsClicked(true);
          onSetCurEvent(AnnotationSystemEventsEnum.CREATING_SIZE);
          return;
        }
        case AnnotationSystemEventsEnum.CREATING_SIZE: {
          if (!svgRef || !svgRef.current) return;
          preFinalPosStateRef.current = preventResizeInverse(stateRef.current);

          const groupIndex = findLastGroupIndex(dots);

          const svgObjSize = getSvgObjSizes(svgRef.current);
          const labelsWithZoom = convertPercentageToPixelsRelativeToOuterRect({
            objects: humanPosesLabels['dot']
              ?.labels as unknown as AnnotationUnitProps[],
            label: DEFAULT_LABEL,
            width: svgObjSize.w,
            height: svgObjSize.h,
            initX: svgObjSize.x,
            initY: svgObjSize.y,
            curZoomLevel,
            groupIndex: groupIndex
          });

          onAddAnnotationUnit({
            type: 'wrapper',
            ...getAnnotationUnitsWrapperSize(labelsWithZoom),
            unitId: `wrapper-${groupIndex}-${DEFAULT_LABEL}`,
            groupIndex: groupIndex,
            label: DEFAULT_LABEL,
            isEnd: false
          });

          for (const dot of labelsWithZoom) {
            onAddAnnotationUnit(dot);
          }

          onSetCurSelUnitId(null);
          setIsClicked(false);
          return;
        }
        case AnnotationSystemEventsEnum.CREATING_LABEL: {
          setIsClicked(false);
          return;
        }
      }
    },
    [
      svgRef,
      curEvent,
      onStart,
      handleMouseMoveStart,
      onSetCurEvent,
      stateRef,
      dots,
      curZoomLevel,
      onAddAnnotationUnit,
      onSetCurSelUnitId
    ]
  );

  useEffect(() => {
    onReset();
  }, [onReset]);

  useEffect(() => {
    if (!svgLayerRef) return;

    // @ts-ignore
    svgLayerRef?.addEventListener('mousedown', handleMouseDown);
    return () => {
      // @ts-ignore
      svgLayerRef?.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown, svgLayerRef]);

  return useMemo(
    () => ({
      isClicked,
      preFinalPosStateRef,
      setIsClicked
    }),
    [isClicked, setIsClicked]
  );
}
