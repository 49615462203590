import React, { createContext, useContext, useState } from 'react';
import { TTaskDataMapped } from 'containers/TasksRouter/Tasks.types';

interface AnnotationViewContextState {
  taskData: TTaskDataMapped | null;
  setTaskData: (args: TTaskDataMapped | null) => void;
}

const annotationViewContext = createContext<AnnotationViewContextState>({
  taskData: null,
  setTaskData: () => {}
});

export function AnnotationViewProvider({
  children
}: React.PropsWithChildren<{}>) {
  const [taskData, setTaskData] = useState<TTaskDataMapped | null>(null);

  return (
    <annotationViewContext.Provider
      value={{
        taskData,
        setTaskData
      }}
    >
      {children}
    </annotationViewContext.Provider>
  );
}

export function useAnnotationViewContext() {
  return useContext(annotationViewContext);
}
