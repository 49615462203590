import React, { useMemo } from 'react';
import { Row } from 'components/_main';
import { StepCircle } from './StepsSidebar.ui';
import {
  NullableConfiguration,
  NullableConfigurationArray
} from 'containers/AddProjectWizard/AddProjectWizard.types';

interface StepsSidebarProps {
  currentStep?: number;
  configuration: NullableConfigurationArray;
  gridArea?: string;
}

function countAllChilds(config?: NullableConfigurationArray): number {
  return !config
    ? 0
    : config.reduce((accum: number, singleStep) => {
        return singleStep?.childSteps
          ? /** Logic adopted for activating by default also first child step */
            accum + countAllChilds(singleStep.childSteps)
          : accum + 1;
      }, 0);
}

function renderStep(
  step: NullableConfiguration,
  currentStep: number,
  index: number
): React.ReactNode {
  const allChildLength = countAllChilds(step?.childSteps);

  /** Active when between `index` and `index` + all possible sub-children */
  const isActive =
    allChildLength > 0
      ? currentStep <= index + allChildLength && currentStep >= index
      : currentStep === index;

  /** Done when just `currentStep` more than `index` */
  const done =
    allChildLength > 0
      ? currentStep >= index + allChildLength
      : currentStep > index;

  const isLinkActive = allChildLength > 0 && isActive ? true : undefined;

  if (!step) {
    return null;
  }

  return (
    <StepCircle
      key={step.id}
      isLinkActive={isLinkActive}
      isActive={!done && isActive}
      done={done}
      isSmallDot={step?.type === 'small'}
      title={step?.title}
      description={step?.description}
      isLocked={step?.isLocked}
    />
  );
}

function renderRecursion(
  configuration?: NullableConfigurationArray,
  currentStep: number = 0,
  initI: number = 0
) {
  let render: any = [];
  let i = initI;

  if (!configuration) {
    return [];
  }

  for (const config of configuration) {
    if (!config) {
      continue;
    }

    const allChilds = countAllChilds(config?.childSteps);

    render = [...render, renderStep(config, currentStep, i)];

    if (allChilds > 0) {
      render = [
        ...render,
        /** Logic adopted for activating by default also first child step.
         *  Otherwise, use i + 1
         */
        ...renderRecursion(config?.childSteps, currentStep, i)
      ];
      i = i + allChilds;
    } else {
      i = i + 1;
    }
  }

  return render;
}

function StepsSidebar({
  gridArea,
  currentStep = 0,
  configuration
}: StepsSidebarProps) {
  const renderSteps = useMemo(() => {
    return renderRecursion(configuration, currentStep);
  }, [configuration, currentStep]);

  return (
    <Row gridArea={gridArea} width="max-content" justifyItems="center">
      <Row
        width="100%"
        gridAutoColumns="100%"
        justifyItems="flex-start"
        gridGap="0"
      >
        {renderSteps}
      </Row>
    </Row>
  );
}

export default StepsSidebar;
