import React, { useState } from 'react';
import { Col, Grid } from 'components/_main';
import theme from 'theme';
import { PlayButton, ProgressBar } from 'containers/VideoPlayer/components';
import { ArrowDoubleLeftIcon, ArrowDoubleRightIcon } from 'assets';
import { VIDEO_ANNOTATION_PREDEFINED_FRAMES_INTERVALS } from 'appConstants';
import ChangeFrameStepDropdown from './components/ChangeFrameStepDropdown';
import CurFrameInputField from './components/CurFramesInputField';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import useAnnotationVideo from 'containers/AnnotationSystem/hooks/useAnnotationVideo';
import Icon from 'components/Icon';

export const VideoSliderWithFrame = () => {
  const { videoEnd, videoStart, videoFPS } = useAnnotationSystemState();
  const {
    curVidTime,
    curFrame,
    frames: timelineFrames,
    setCurFrame,
    updateFramesWithTime,
    isPlaying,
    onStop,
    onPlay
  } = useAnnotationVideo();
  const [curStep, setCurStep] = useState<number>(
    VIDEO_ANNOTATION_PREDEFINED_FRAMES_INTERVALS[0]?.key as number
  );

  const handleStepForward = () => {
    if (curFrame < timelineFrames.length - curStep) {
      const newIndex = curFrame + curStep;
      setCurFrame(newIndex);
    }
  };

  const handleStepBackward = () => {
    if (curFrame > curStep - 1) {
      const newIndex = curFrame - curStep;
      setCurFrame(newIndex);
    }
  };

  return (
    <Col
      gridAutoColumns="max-content max-content max-content max-content max-content 1fr"
      width="100%"
      height="60px"
      noGap
      backgroundColor={theme.colors.lightest}
      borderRadius="0 0 10px 10px"
      pr="20px"
      alignItems="center"
    >
      <Grid width="50px" justifyContent="center" alignItems="flex-start">
        <PlayButton
          isPlaying={isPlaying}
          onClick={isPlaying ? onStop : onPlay}
        />
      </Grid>
      <Grid
        width="50px"
        justifyContent="center"
        alignItems="flex-start"
        onClick={handleStepBackward}
      >
        <Icon disabled={curFrame <= 0}>
          <ArrowDoubleLeftIcon />
        </Icon>
      </Grid>
      <Grid justifyContent="center" alignItems="flex-start">
        <ChangeFrameStepDropdown setStep={setCurStep} step={curStep} />
      </Grid>
      <Grid
        justifyContent="center"
        width="50px"
        alignItems="flex-start"
        onClick={handleStepForward}
      >
        <Icon disabled={curFrame === timelineFrames.length - 1}>
          <ArrowDoubleRightIcon />
        </Icon>
      </Grid>
      <Grid px="12px">
        <CurFrameInputField />
      </Grid>
      <Grid height="100%" gridAutoFlow="row" gridGap={0}>
        <Grid alignItems="center">
          <ProgressBar
            curVidTime={curVidTime}
            onSetCurTime={updateFramesWithTime}
            boxProps={{
              height: '100%'
            }}
            onStop={onStop}
            step={1 / videoFPS}
            max={videoEnd}
            hideControl={true}
            min={videoStart}
          />
        </Grid>
      </Grid>
    </Col>
  );
};

export default VideoSliderWithFrame;
