import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter, upper } from 'utils/_stringUtilsLegacy';
import { Box, Row, Col, Text } from 'components/_main';
import { toast } from 'utils/toast';
import AvatarGenerator from 'components/AvatarGenerator';
import AntdCheckbox from 'components/AntdCheckbox';
import { useCtx } from 'containers/ProjectDetailsCard/ProjectDetailsCard.Context';

interface CheckCellRenderProps {
  id: number;
  type: 'helper' | 'auditor';
  listIds: number[];
  onSelect: (checked: boolean, id: number) => void;
}

interface HelperNameCellProps {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const CheckCellRender = ({
  id,
  type,
  listIds,
  onSelect
}: CheckCellRenderProps) => {
  const [checked, setChecked] = useState(false);
  const { isReadOnly } = useCtx();

  useEffect(() => {
    const isChecked = listIds.includes(id);
    setChecked(isChecked);
  }, [checked, id, listIds]);

  const handleClick = () => {
    if (checked && type === 'helper' && listIds.length === 1) {
      toast.error(`Should be at least one ${type}`);
      return;
    }

    onSelect(!checked, id);
    setChecked(!checked);
  };

  return (
    <AntdCheckbox
      checked={checked}
      disabled={isReadOnly}
      onChange={handleClick}
    >
      <Box padding="2px 0 0 4px">{capitalizeFirstLetter(type)}</Box>
    </AntdCheckbox>
  );
};

export const HelperNameCell = ({
  email = '',
  firstName = '',
  lastName = ''
}: HelperNameCellProps) => {
  return (
    <Col width="100%" overflow="hidden" css="text-overflow: ellipsis;">
      <AvatarGenerator firstName={firstName} lastName={lastName} />
      <Row gridGap="0">
        <Text variant="ui-1-bold">
          {firstName} {lastName}
        </Text>
        <Text variant="ui-small-regular" colorVariant="light">
          {email}
        </Text>
      </Row>
    </Col>
  );
};
