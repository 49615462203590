export const varEnv = {
  beUrlSubscription: process.env.REACT_APP_SERVER_URL_SUBSCRIPTIONS,
  beUrl: process.env.REACT_APP_SERVER_URL,
  isDebug: !!process.env.REACT_APP_IS_DEBUG,
  emailHelpAddresses:
    process.env.REACT_APP_EMAIL_HELP_ADDRESSES ||
    'taskware.test@gmail.com;anastasia.kompaniets@eteam.io;alina.vlasenko@helpware.com',
  sbAdminUserLogin: process.env.REACT_APP_SB_ADMIN_LOGIN,
  sbAdminUserPassword: process.env.REACT_APP_SB_ADMIN_PASSWORD,
  googleOAuth2ClientId: process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID,
  googleProjectNumber: process.env.REACT_APP_GOOGLE_PROJECT_NUMBER,
  termsAndConditionsPageUrl:
    process.env.REACT_APP_TERMS_AND_CONDITIONS_PAGE_URL,
  privacyAndPolicyPageUrl: process.env.REACT_APP_PRIVACY_POLICY_PAGE_URL,
  taskwareIoUrl: process.env.REACT_APP_TASKWARE_IO_URL,
  maxFreeHelpersInvite: parseInt(
    process.env.REACT_APP_MAX_FREE_HELPERS_INVITE || '2',
    10
  ),
  defaultRedirectTimeoutAfterRequest: parseInt(
    process.env.REACT_APP_DEFAULT_REDIRECT_TIMEOUT_AFTER_REQEUST || '2000',
    10
  ),
  isSb: process.env.REACT_APP_SB_IS_SB,
  ciEnv: process.env.REACT_APP_CI_ENV,
  isDev: process.env.REACT_APP_CI_ENV === 'dev',
  isProd: process.env.REACT_APP_CI_ENV === 'prod',
  isStage: process.env.REACT_APP_CI_ENV === 'stage'
};

console.info({ varEnv });

export default varEnv;
