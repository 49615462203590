import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import styled from 'styled-components/macro';
import AnnotationUnitToolbar from '../UnitToolbar/UnitToolbar';
import LabelsDropdown from '../LabelsDropdown';
import { withIds } from '../LabelsDropdown/utils';

import useAnnotationSystemLabels from '../../context/useAnnotationSystemLabels';
import useAnnotationDelete from '../../hooks/useAnnotationDelete';
import { Box } from 'components/_main';
import { Z_INDEXES } from 'appConstants';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useAnnotationVideo from 'containers/AnnotationSystem/hooks/useAnnotationVideo';
import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import useCurSelUnitProps from 'containers/AnnotationSystem/hooks/useCurSelUnitProps';
import ReactDOM from 'react-dom';
import { TaskType } from 'generated/graphql';

interface Props {
  svgRef: MutableRefObject<SVGElement | null>;
  label?: string;
  isEditDisabled?: boolean;
  color: string;
  show: boolean;
  onCopy?: () => void;
  onEdit?: () => void;
  onRevertHorizontalDots?: () => void;
  onRevertVerticalDots?: () => void;
  onSelect?: (label: string) => void;
  unitProps?: Partial<AnnotationUnitProps>;
  absolutePos?: boolean;
}

export const Foreign = styled.foreignObject`
  overflow: visible;
`;

export default function UnitToolbarWithLabelSelect({
  label,
  isEditDisabled,
  color,
  svgRef,
  show,
  onSelect,
  onCopy,
  onRevertHorizontalDots,
  onRevertVerticalDots,
  unitProps,
  onEdit
}: Props) {
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);

  const labels = useAnnotationSystemLabels();

  const { timelineTransitions, unitId } = useCurSelUnitProps();
  const { curVidTime, isPlaying, curFrame } = useAnnotationVideo();

  const {
    videoEnd,
    videoStart,
    curSelUnitId,
    hideAnnotationUnitIds,
    curZoomLevel,
    curEvent,
    curMode
  } = useAnnotationSystemState();
  const {
    onSetCurEvent,
    onSetHideAnnotationUnitId,
    onModifyTransitionPoint,
    toggleLabelSelectDropdown,
    onModifyAnnotationUnit
  } = useAnnotationSystemActions();

  const isCreate = curEvent === AnnotationSystemEventsEnum.CREATING_LABEL;
  const isSelected =
    curEvent === AnnotationSystemEventsEnum.SELECTED_MODE &&
    curMode === AnnotationSystemModesEnum.UNIT_SELECT;
  const isActiveOrCreating =
    show && (curSelUnitId === unitProps?.unitId || isCreate);

  const isVideo = videoEnd !== videoStart;

  const hidden = isVideo
    ? timelineTransitions?.some(
        (timestamp) => timestamp.timestamp === curVidTime && !!timestamp.isEnd
      )
    : hideAnnotationUnitIds.includes(curSelUnitId ?? '');

  const handleDelete = useAnnotationDelete();

  const toggleHiddenTransition = () => {
    if (!timelineTransitions || !unitId) return;

    for (const timeline of timelineTransitions) {
      if (timeline.timestamp !== curVidTime) {
        continue;
      }

      onModifyTransitionPoint({
        unitId,
        timelineTimestamp: timeline.timestamp,
        content: {
          isEnd: !timeline.isEnd
        }
      });

      return;
    }

    /** If no time point exist */
    onModifyTransitionPoint({
      unitId,
      timelineTimestamp: curVidTime,
      content: {
        isEnd: true
      }
    });
  };

  const handleHideToggle = () => {
    if (!curSelUnitId) return;

    if (isVideo) {
      toggleHiddenTransition();
      return;
    }

    if (hidden) {
      onSetHideAnnotationUnitId([
        ...hideAnnotationUnitIds.filter((id) => id !== curSelUnitId)
      ]);
      return;
    }

    onSetHideAnnotationUnitId([...hideAnnotationUnitIds, curSelUnitId]);
  };

  const handleSetLabel = (label: string) => {
    if (!unitProps) return;
    onModifyAnnotationUnit({
      unitId: unitProps.unitId,
      label,
      isLabelDropdownOpen: true
    });
    toggleLabelSelectDropdown(null);
    onSetCurEvent(null);
  };

  let renderToolbar = isSelected ? (
    <AnnotationUnitToolbar
      css={`
        visibility: ${visible ? 'visible' : 'hidden'};
      `}
      color={color}
      label={label}
      onCopy={onCopy}
      onLabelChange={
        isEditDisabled === true
          ? undefined
          : () => toggleLabelSelectDropdown(null)
      }
      onEdit={onEdit}
      onRevertHorizontalDots={onRevertHorizontalDots}
      onRevertVerticalDots={onRevertVerticalDots}
      onDelete={handleDelete}
      {...{
        onHide: handleHideToggle,
        hidden,
        onShow: handleHideToggle
      }}
    />
  ) : null;

  if (
    unitProps?.isLabelDropdownOpen ||
    curEvent === AnnotationSystemEventsEnum.CREATING_LABEL
  ) {
    renderToolbar = (
      <LabelsDropdown
        elRef={toolbarRef}
        data={withIds(labels)}
        onSelect={onSelect ?? handleSetLabel}
      />
    );
  }

  const handleProps = useCallback(() => {
    const wrapperEl = document.querySelector(
      '[data-testid="AnnotationViewPort"]'
    );
    const wrapperElWidth = document.getElementById(
      'AnnotationMediaLayerWrapper'
    );
    const forObjEl = document.getElementById('for-obj-label-wrapper');
    const toolbarEl = document.getElementById('AnnotationUnitToolbar');
    const toolbarDivWrapper = document.getElementById('toolbarUnitDivWrapper');
    const svgEl = svgRef.current;

    if (
      !wrapperEl ||
      !forObjEl ||
      !svgEl ||
      !wrapperElWidth ||
      !toolbarDivWrapper
    )
      return;

    const wrapperElProps = wrapperEl.getBoundingClientRect();
    const svgElProps = svgEl.getBoundingClientRect();
    const wrapperElWidthProps = wrapperElWidth.getBoundingClientRect();

    const posX = svgElProps.x;
    const posY = svgElProps.y;

    toolbarDivWrapper.style.left = `${posX - wrapperElWidthProps.x}px`;
    toolbarDivWrapper.style.top = `${posY - wrapperElWidthProps.y}px`;
    toolbarDivWrapper.style.width = `${svgElProps.width}px`;
    toolbarDivWrapper.style.height = `${svgElProps.height}px`;

    let newX: any = 0;
    let newY: any = '100%';

    /** Set unit toolbar pos for relative coords */
    const toolbarElProps = toolbarEl?.getBoundingClientRect() ?? {
      height: 0,
      width: 0
    };

    /** Too close to left -> Show toolbar right */
    if (
      svgElProps.x < wrapperElWidthProps.x ||
      svgElProps.x < wrapperElProps.x
    ) {
      newX = '100%';
    }

    /** Too close to bottom -> Show toolbar above */
    if (
      svgElProps.y + svgElProps.height + toolbarElProps.height + 30 >
      wrapperElProps.y + wrapperElProps.height
    ) {
      newY = `-${toolbarElProps?.height + 30}px`;
      if (toolbarEl?.style?.margin) newX = 0;
    }

    /** Too close to right -> Show toolbar left */
    const rightXEdge =
      svgElProps.x +
      svgElProps.width +
      (toolbarElProps.width - svgElProps.width) +
      30;
    if (
      rightXEdge > wrapperElWidthProps.x + wrapperElWidthProps.width ||
      rightXEdge > wrapperElProps.x + wrapperElProps.width
    ) {
      newX = `-${toolbarElProps.width}px`;
    }

    /** Top and bottom too close to edges -> Show at the middle */
    const tooCloseToTopOrBottomEdges =
      svgElProps.y < wrapperElProps.y + 70 &&
      svgElProps.height + svgElProps.y >
        wrapperElProps.y + wrapperElProps.height - 70;

    if (tooCloseToTopOrBottomEdges) {
      newY = `${wrapperElProps.y - svgElProps.y + wrapperElProps.height / 2}px`;
    }

    /** Left and right too close to edges -> Show at the middle */
    const tooCloseToLeftOrRightEdges =
      svgElProps.x < wrapperElProps.x + 70 &&
      svgElProps.width + svgElProps.x >
        wrapperElProps.x + wrapperElProps.width - 70;

    if (tooCloseToTopOrBottomEdges) {
      newX = `${wrapperElProps.x - svgElProps.x + wrapperElProps.width / 2}px`;
    }

    forObjEl.style.left = `${newX}`;
    forObjEl.style.top = `${newY}`;

    if (toolbarDivWrapper.style.width) {
      setVisible(true);
      return;
    }

    setVisible(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [svgRef?.current]);

  useEffect(() => {
    const svgEl = svgRef.current;

    if (!svgEl || !isActiveOrCreating) return;

    handleProps();
  }, [
    handleProps,
    curZoomLevel,
    svgRef,
    curSelUnitId,
    unitProps?.unitId,
    isActiveOrCreating,
    curVidTime,
    curFrame
  ]);

  useEffect(() => {
    const wrapperEl = document.querySelector(
      '[data-testid="AnnotationViewPort"]'
    );
    const svgEl = svgRef.current;

    if (!svgEl || !isActiveOrCreating) return;

    svgEl.addEventListener('mousemove', handleProps);
    svgEl.addEventListener('mouseup', handleProps);
    wrapperEl && wrapperEl.addEventListener('scroll', handleProps);

    return () => {
      svgEl.removeEventListener('mousemove', handleProps);
      svgEl.removeEventListener('mouseup', handleProps);
      wrapperEl && wrapperEl.removeEventListener('scroll', handleProps);
    };
  });

  const portal = document.getElementById('AnnotationMediaLayerWrapper');

  if (!show || isPlaying || !portal) return null;

  return (
    <>
      {ReactDOM.createPortal(
        <div
          id="toolbarUnitDivWrapper"
          ref={wrapperRef}
          style={{ position: 'absolute' }}
        >
          <div id="for-obj-label-wrapper" style={{ position: 'absolute' }}>
            <Box
              ref={toolbarRef}
              mt="10px"
              position="absolute"
              zIndex={Z_INDEXES.popover}
            >
              {renderToolbar}
            </Box>
          </div>
        </div>,
        portal
      )}
    </>
  );
}
