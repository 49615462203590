import React from 'react';
import {
  AvatarGenerator,
  Layout,
  ModalStyled,
  TitleHeader
} from './HelperDetailsCard.ui';
import { TitleView } from './Title.ui';
import GeneralTab from './tabs/General';
import PerformanceTab from './tabs/Performance';
import StatisticsTab from './tabs/Statistics';
import ProvideHelperData from './ProvideHelperData';
import TabsArea from './TabsArea';
import { ButtonPrimary } from 'components/_main';
import Title from './Title';
import { ModalFooterButtons } from 'components/_modals/ui';

interface HelperDetailsCardProps {
  defaultTabKey?: string;
  helperId?: number;
  withModal?: boolean;
  isOpen?: boolean;
  onCancel?: () => void;
  mockData?: {
    title?: any;
    personalTab?: any;
    performanceTab?: any;
    statisticsTab?: any;
  };
}

function HelperDetailsCard({
  helperId,
  isOpen = false,
  onCancel,
  withModal = true,
  mockData,
  defaultTabKey
}: HelperDetailsCardProps) {
  const view = (
    <Layout>
      <TabsArea defaultTabKey={defaultTabKey || 'personal'}>
        <GeneralTab
          data-testid="general-tab"
          key="personal"
          title="Personal"
          helperId={helperId}
          data={mockData?.personalTab?.data}
          loading={mockData?.personalTab?.loading}
        />
        <PerformanceTab
          data-testid="performance-tab"
          key="performance"
          title="Performance"
          helperId={helperId}
          data={mockData?.performanceTab?.data}
          loading={mockData?.performanceTab?.loading}
        />
        <StatisticsTab
          data-testid="statistics-tab"
          key="statistics"
          title="Statistics"
          helperId={helperId}
          data={mockData?.statisticsTab?.data}
          loading={mockData?.statisticsTab?.loading}
          columns={mockData?.statisticsTab?.columns}
        />
      </TabsArea>
    </Layout>
  );

  return withModal ? (
    <ModalStyled
      width={900}
      destroyOnClose={true}
      isOpen={isOpen}
      cancelText={false}
      onCancel={onCancel}
      onOk={onCancel}
      title={[
        <TitleHeader>
          <>
            {helperId ? (
              <ProvideHelperData helperId={helperId}>
                <AvatarGenerator />
              </ProvideHelperData>
            ) : (
              (mockData && (
                <AvatarGenerator
                  loading={mockData?.title?.loading}
                  {...mockData?.title?.data}
                />
              )) ||
              null
            )}
          </>
          <>
            {helperId ? (
              <Title helperId={helperId} />
            ) : (
              (mockData?.title?.data && (
                <TitleView
                  loading={mockData?.title?.loading}
                  {...mockData?.title?.data}
                />
              )) ||
              null
            )}
          </>
        </TitleHeader>
      ]}
      footer={[
        <ModalFooterButtons>
          <ButtonPrimary onClick={onCancel}>Close</ButtonPrimary>
        </ModalFooterButtons>
      ]}
    >
      {view}
    </ModalStyled>
  ) : (
    <>{view}</>
  );
}

export default HelperDetailsCard;
