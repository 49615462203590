import React, { useEffect } from 'react';
import { VerticalField } from '../components/VerticalField';
import { GeneralTabRootUi } from '../ui';
import useAvailableToggleMutationProvider from 'hooks/useClientAvailableToggleMutationProvider';
import AvailableToggle from '../components/AvailableToggle';
import { useClientCardCtx } from '../ctx';
import SendInvite from '../components/SendInvite';
import { Button } from 'components/_main';
import { useUpgradePricingPlanMutation } from 'generated/graphql';
import { consoleError } from 'utils';
import { toast } from 'utils/toast';
import { LIST_CLIENTS } from 'graphql/queries/client';
import useTablePage from 'hooks/useTablePage';
import { CLIENTS_LIST_PAGE } from 'appConstants';
import { activeStatusColumn } from 'components/Table';

function General() {
  const { page } = useTablePage(CLIENTS_LIST_PAGE);
  const { data, refetchQueries, setLoading } = useClientCardCtx() ?? {};
  const [upgradePricing] = useUpgradePricingPlanMutation({
    refetchQueries: [
      {
        query: LIST_CLIENTS,
        variables: {
          page
        }
      }
    ]
  });

  const [toggleRender, toggleLoading] = useAvailableToggleMutationProvider({
    component: React.useMemo(
      () =>
        ({ onToggle, loading }: any) =>
          (
            <AvailableToggle
              onToggle={onToggle}
              loading={loading}
              id={data?.id}
              available={!!data?.enabled}
            />
          ),
      [data]
    ),
    refetchQueries
  });

  const handleUpgradePricingPlan = () => {
    if (!data?.id) {
      consoleError('No data?.id');
      return;
    }
    try {
      upgradePricing({
        variables: {
          clientId: parseInt(data.id, 10)
        }
      });
      toast.success('Pricing plan upgraded!');
    } catch (e: any) {
      consoleError(e);
      toast.error(e?.message ?? e);
    }
  };

  useEffect(() => {
    setLoading({ toggleLoading });
  }, [setLoading, toggleLoading]);

  return (
    <GeneralTabRootUi>
      {data?.companyName && (
        <VerticalField title="Company name">{data?.companyName}</VerticalField>
      )}
      <VerticalField title="First name">{data?.firstName}</VerticalField>
      <VerticalField title="Last name">{data?.lastName}</VerticalField>
      <VerticalField title="Email">{data?.email}</VerticalField>
      {!data?.isDeleted && toggleRender}
      <VerticalField title="Status">{activeStatusColumn(data)}</VerticalField>
      {!data?.activated && data?.id && (
        <VerticalField title="Invitation">
          <SendInvite clientId={data.id} />
        </VerticalField>
      )}

      <VerticalField title="Current pricing plan">
        {data?.isPremium ? (
          'Premium'
        ) : (
          <Button onClick={handleUpgradePricingPlan} small square>
            UPGRADE
          </Button>
        )}
      </VerticalField>
    </GeneralTabRootUi>
  );
}

export default General;
