import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { changePosDotsAccordingToOuterRectMove } from 'containers/AnnotationSystem/utils';
import { MutableRefObject } from 'react';
import { consoleError } from 'utils';

interface Props {
  groupIndex?: number;
  svgRef: MutableRefObject<SVGElement | null>;
}

export default function useUpdateDotsOnOuterRectMove({
  groupIndex,
  svgRef
}: Props) {
  const { onInitAnnotationUnits } = useAnnotationSystemActions();
  const { annotationUnits, curZoomLevel } = useAnnotationSystemState();

  const sameGroupIndexDots = annotationUnits.filter(
    (unit) => unit.groupIndex === groupIndex && unit.type === 'dot'
  );

  const excludeIds = sameGroupIndexDots.map((dot) => dot.unitId);

  const callback = () => {
    const element: SVGElement | null = svgRef.current;
    if (!element) {
      return;
    }
    const dX =
      (parseInt(element.getAttribute('x') ?? '0', 10) -
        parseInt(element.dataset?.startPosX ?? '0', 10)) /
      curZoomLevel;
    const dY =
      (parseInt(element.getAttribute('y') ?? '0', 10) -
        parseInt(element.dataset?.startPosY ?? '0', 10)) /
      curZoomLevel;

    const newUnits = changePosDotsAccordingToOuterRectMove({
      dots: sameGroupIndexDots,
      dX,
      dY
    });

    onInitAnnotationUnits([
      ...annotationUnits.filter((unit) => !excludeIds.includes(unit.unitId)),
      ...newUnits
    ]);
  };

  return callback;
}
