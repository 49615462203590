import React from 'react';
import { TAntdFormItem } from 'appTypes';
import { titleRules } from 'utils';
import InputLabel from 'components/InputLabel';
import Input from 'components/Input';

const AntdFormItemInput: React.FC<TAntdFormItem> = ({
  label,
  rules = titleRules,
  ...props
}) => {
  return (
    <InputLabel hasFeedback label={label} rules={rules} {...props}>
      <Input type="text" placeholder={label} />
    </InputLabel>
  );
};

export default AntdFormItemInput;
