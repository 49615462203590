import { useCallback, useMemo } from 'react';
import useLocalStorage from './useLocalStorage';
import {
  StoredTaskData,
  TTaskDataMapped,
  TTasksTaskDataResult
} from 'containers/TasksRouter/Tasks.types';
import { TaskType } from 'generated/graphql';

function useSaveResultsLocally() {
  const [storedResult, setStoredResult] =
    useLocalStorage<StoredTaskData | null>('result', null);

  const saveResultsLocally = useCallback(
    (taskId: number, results: TTasksTaskDataResult) => {
      const result = { results, taskId };
      setStoredResult(result);
    },
    [setStoredResult]
  );

  const mappedTaskDataWithLocallySavedResults = useCallback(
    (data: TTaskDataMapped) => {
      if (
        storedResult?.taskId === data.taskId &&
        data.taskType !== TaskType.VideoAnnotation
      ) {
        return {
          ...data,
          result: storedResult.results
        };
      }
      return data;
    },
    [storedResult]
  );

  return useMemo(
    () => ({
      saveResultsLocally,
      mappedTaskDataWithLocallySavedResults
    }),
    [saveResultsLocally, mappedTaskDataWithLocallySavedResults]
  );
}

export default useSaveResultsLocally;
