export default function getHtmlElementSize<
  T extends HTMLElement = HTMLDivElement
>(ref: T) {
  const { width: w, height: h, x, y } = ref.getBoundingClientRect();

  return {
    w,
    h,
    x,
    y
  };
}
