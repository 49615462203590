import { useApolloClient } from '@apollo/client';
import {
  APP_STATE,
  APP_STATE_CONTEXT_RETURN_INIT,
  MESSAGES
} from 'appConstants';
import { AppStateContextReturn, CurrentUser } from 'appTypes';
import useRedirect from 'hooks/useRedirect';
import { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'utils/toast';
import {
  consoleError,
  getTokenStorage,
  getUserDataStorage,
  updateUserDataStorage
} from 'utils';

const context = createContext<AppStateContextReturn>(
  APP_STATE_CONTEXT_RETURN_INIT
);

const useAppContextState = (): AppStateContextReturn => {
  const initialUserData = getUserDataStorage();
  const initialToken = getTokenStorage();
  const client = useApolloClient();
  const redirect = useRedirect();

  const [state, setState] = useState({
    ...APP_STATE,
    currentUser: {
      ...APP_STATE.currentUser,
      ...initialUserData,
      isLoggedIn: Boolean(initialToken)
    }
  });

  const ifUserCorrect = (userData: CurrentUser) => {
    if (!userData?.id) {
      consoleError({ message: 'User data incorrect', userData });
      toast.error(MESSAGES.serverError.common);
      return false;
    }
    return true;
  };

  const signOut: AppStateContextReturn['signOut'] = useCallback(async () => {
    localStorage.clear();
    sessionStorage.clear();
    await client.clearStore();
    setTimeout(async () => {
      localStorage.clear();
      sessionStorage.clear();
      await client.clearStore();
    }, 0);
    setState(APP_STATE);
  }, [client]);

  const updateCurrentUser: AppStateContextReturn['updateCurrentUser'] =
    useCallback(
      (newUser, isLoggedIn = false) => {
        setState((previousState) => {
          const token = getTokenStorage();

          const newCurUser = {
            ...previousState.currentUser,
            ...newUser,
            isLoggedIn: isLoggedIn ?? Boolean(token)
          };

          if (!ifUserCorrect(newCurUser)) {
            signOut();
            return previousState;
          }

          updateUserDataStorage(newCurUser);

          return {
            ...previousState,
            currentUser: newCurUser
          };
        });
      },
      [signOut]
    );

  const signIn: AppStateContextReturn['signIn'] = useCallback(
    (newUser, redirectToStartPage = true) => {
      setState((previousState) => {
        const token = getTokenStorage();

        const newCurUser = {
          ...previousState.currentUser,
          ...newUser,
          isLoggedIn: Boolean(token)
        };

        if (!ifUserCorrect(newCurUser)) {
          signOut();
          return previousState;
        }

        updateUserDataStorage(newCurUser);

        return {
          ...previousState,
          currentUser: newCurUser
        };
      });
      redirectToStartPage && setTimeout(() => redirect('/'), 500);
    },
    [redirect, signOut]
  );

  return {
    currentUser: state.currentUser,
    updateCurrentUser,
    signOut,
    signIn
  };
};

export const AppContextProvider = ({
  children
}: React.PropsWithChildren<{}>) => {
  const appContextValues = useAppContextState();

  return (
    <context.Provider value={appContextValues}>{children}</context.Provider>
  );
};

export const useAppContext = () => {
  return useContext(context);
};
