import styled from 'styled-components/macro';
import { Layout } from 'antd';

export const RootLayoutStyled = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
`;

export const LayoutStyled = styled(Layout)`
  width: 100%;
  height: 100%;
  position: relative;
`;
