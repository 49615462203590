import styled, { css } from 'styled-components/macro';
import React from 'react';
import { SwitchProps } from 'antd/lib/switch';
import { Switch } from 'antd';

const AntdSwitch = styled(
  React.forwardRef<HTMLElement, SwitchProps & { disabledAsNormal?: boolean }>(
    ({ disabledAsNormal, ...props }, ref) => <Switch ref={ref} {...props} />
  )
)`
  &.ant-switch-checked {
    background-color: #11d3b9;
  }
  ${({ disabledAsNormal }) =>
    disabledAsNormal &&
    css`
      opacity: 1;
    `}
`;

export default AntdSwitch;
