import styled from 'styled-components/macro';
import { Modal } from 'antd';
import { CARD_MODAL_HEIGH, CARD_MODAL_WIDTH } from 'appConstants';

export const MainRootUi = styled.div`
  height: 100%;
  overflow: visible;
  display: grid;
`;

export const TabTitleUi = styled.span`
  padding: 0 1rem;
`;

export const ModalStyled = styled(Modal)`
  width: ${CARD_MODAL_WIDTH}px;
  min-width: ${CARD_MODAL_WIDTH}px;

  .ant-modal-body {
    height: ${CARD_MODAL_HEIGH}px;
    min-height: ${CARD_MODAL_HEIGH}px;
    padding: 0 24px 24px;

    ul {
      margin-left: 0;
      padding-left: 0;
    }
  }
`;

export const ModalFooterButtons = styled.div`
  &::after {
    position: absolute;
    width: calc(100% + 24px * 2);
    margin-left: -24px;
    height: 1px;
    top: -1rem;
    content: ' ';
    border-top: 1px solid #f0f0f0;
  }
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
`;

export const ModalContentWithButtonsRoot = styled.div`
  display: grid;
  height: 100%;
  padding: 0 30px 30px;
  grid-auto-flow: row;
  grid-template-rows: 1fr max-content;
`;

export const GeneralTabRootUi = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

export const ModalFooterButtonsRootUi = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const TitleHeader = styled.div`
  display: grid;
  padding: 0 1rem;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  grid-auto-columns: max-content;
  gap: 1rem;
`;
