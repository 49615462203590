import { GetProjectDetailsChartDataQuery } from 'generated/graphql';
import { consoleError } from 'utils';
import { useEffect, useState } from 'react';

interface Props {
  periodEnd?: number | null;
  periodStart?: number | null;
  initialStats: GetProjectDetailsChartDataQuery['project']['projectStats'];
}

export default function useStats({
  periodEnd,
  periodStart,
  initialStats
}: Props) {
  const [stats, setStats] =
    useState<GetProjectDetailsChartDataQuery['project']['projectStats']>(
      initialStats
    );
  const [showBy, setShowBy] = useState<string>('1h');

  useEffect(() => {
    if (initialStats) {
      setStats(initialStats);
    }
  }, [initialStats]);

  useEffect(() => {
    if (!initialStats || !stats) {
      consoleError('No project in data');
      return;
    }

    let newPeriodEnd = periodEnd;
    let newPeriodStart = periodStart;

    /** Start same as end */
    if (newPeriodStart && newPeriodEnd && periodStart === periodEnd) {
      let x = new Date(newPeriodStart);
      newPeriodStart = new Date(
        x.getFullYear(),
        x.getMonth(),
        x.getDate(),
        0
      ).getTime();
      newPeriodEnd = new Date(
        x.getFullYear(),
        x.getMonth(),
        x.getDate(),
        23,
        59
      ).getTime();
    }

    let newStats = [...stats];

    let indexBeforStartPeriod = newStats?.findIndex((obj) => {
      if (!obj || !newPeriodStart) return false;
      const unixTime = Math.round(new Date(obj?.createdAt).getTime());
      if (unixTime > newPeriodStart) return true;
      return false;
    });

    indexBeforStartPeriod =
      indexBeforStartPeriod > 0 ? indexBeforStartPeriod : 0;

    let indexAfterEndPeriod = newStats?.findIndex((obj) => {
      if (!obj || !newPeriodEnd) return false;
      const unixTime = Math.round(new Date(obj?.createdAt).getTime());
      if (unixTime > newPeriodEnd) return true;
      return false;
    });

    indexAfterEndPeriod =
      indexAfterEndPeriod > 0 ? indexAfterEndPeriod : newStats.length - 1;

    newStats = newStats.slice(indexBeforStartPeriod, indexAfterEndPeriod);
    let quantity = Math.floor((newStats.length + 1) / 24) || 1;

    newStats = [
      ...newStats.filter((value, index) => index % quantity === 0 && value),
      newStats[indexAfterEndPeriod]
    ];

    switch (true) {
      // 1 h
      case quantity === 1: {
        setShowBy('1h');
        break;
      }
      // 4 h
      case quantity <= 4: {
        setShowBy('4h');
        break;
      }
      // 8 h
      case quantity <= 8: {
        setShowBy('8h');
        break;
      }
      // 12 h
      case quantity <= 12: {
        setShowBy('12h');
        break;
      }

      // 1 day
      case quantity <= 24: {
        setShowBy('1day');
        break;
      }
      // 2 day
      case quantity <= 48: {
        setShowBy('2day');
        break;
      }
      // 4 day
      case quantity <= 96: {
        setShowBy('4day');
        break;
      }
      // 8 day
      case quantity <= 192: {
        setShowBy('8day');
        break;
      }
      // 12 day
      case quantity <= 288: {
        setShowBy('12day');
        break;
      }
      // 1 month
      case quantity > 720: {
        setShowBy('1month');
        break;
      }
      // 3 month
      case quantity <= 2160: {
        setShowBy('3month');
        break;
      }
      // 6 month
      case quantity <= 4320: {
        setShowBy('6month');
        break;
      }
      // 1 year
      case quantity <= 86400: {
        setShowBy('1year');
        break;
      }
      case quantity > 86400: {
        setShowBy('1year');
        break;
      }
    }

    setStats(newStats ? [...newStats] : []);
  }, [initialStats, periodEnd, periodStart, stats]);

  return {
    showBy,
    stats
  } as const;
}
