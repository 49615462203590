function getSvgMouseRelPos(event: MouseEvent) {
  const e: any = event.currentTarget;
  const dim = e.getBoundingClientRect();
  const x = event.clientX - dim.left;
  const y = event.clientY - dim.top;

  const relPosX = x;
  const relPosY = y;

  return {
    relPosX,
    relPosY
  };
}

export default getSvgMouseRelPos;
