import { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Text } from 'components/_main';

interface TimerProps {
  endTime?: string;
  onTimeOut: () => void;
}

function Timer({ endTime, onTimeOut }: TimerProps) {
  const [remainingTime, setRemainingTime] = useState(0);
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const animIntervalId = useRef<any>(null);

  const updateTimer = useCallback(
    (startTime: number) => {
      if (!endTime) return;

      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - startTime;
      const sessionTime = new Date(endTime).valueOf() - startTime;
      const remainingTime = Math.max(sessionTime - elapsedTime, 0);
      setRemainingTime(remainingTime);

      if (remainingTime > 0) {
        animIntervalId.current = requestAnimationFrame(() =>
          updateTimer(startTime)
        );
      } else {
        onTimeOut();
        cancelAnimationFrame(animIntervalId.current);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endTime]
  );

  useEffect(() => {
    const startTime = new Date().getTime();
    updateTimer(startTime);

    return () => cancelAnimationFrame(animIntervalId.current);
  }, [updateTimer]);

  useEffect(() => {
    const minutes = Math.floor(remainingTime / 60000);
    const seconds = Math.floor((remainingTime % 60000) / 1000);
    setTime({
      minutes: Math.max(minutes, 0),
      seconds: Math.max(seconds, 0)
    });
  }, [remainingTime]);

  if (!endTime) {
    return null;
  }

  return (
    <Box displayFlex alignItems="center">
      <Text variant="ui-1-bold">
        {time.minutes}:{time.seconds.toString().padStart(2, '0')}
      </Text>
    </Box>
  );
}

export default Timer;
