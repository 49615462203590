import { useEffect } from 'react';
import { AnnotationSystemEventsEnum } from '../AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../context';

export default function useGlobalHotkeys() {
  const { curEvent, isUnitCreatingProcess, annotationSpecificationType } =
    useAnnotationSystemState();
  const { onSetCurEvent, onSetCurSelUnitId } = useAnnotationSystemActions();
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (isUnitCreatingProcess) {
        onSetCurEvent(null);
        return;
      }

      if (curEvent === null) {
        onSetCurEvent(AnnotationSystemEventsEnum.CREATE_MODE);
        return;
      }

      onSetCurEvent(null);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });
}
