import React, { useState, useEffect } from 'react';
import HeaderAppBar from 'containers/HeaderAppBar';
import { useNavigate, useParams } from 'react-router-dom';
import useSetAppBarTitle from 'hooks/useSetAppBarTitle';
import { Box, Button, Row } from 'components/_main';
import { TProjectDetails } from './ChartsWithProjectDetails.types';
import useGetChartData from './useGetChartData';
import useDownloadProjectsJsonResults from '../../hooks/useDownloadProjectsJsonResults';
import {
  ProjectTitle,
  RouteContentStyled,
  TitleCol
} from './ChartsWithProjectDetails.ui';
import {
  ProjectStatByHour,
  useGetProjectByIdLazyQuery
} from 'generated/graphql';
import SelectProjects from 'components/SelectProjects';
import useRedirect from 'hooks/useRedirect';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import handleStats from './useStats';
import Chart from './components/Chart';
import { consoleError } from 'utils';
import useStats from './useStats';

const ChartsWithProjectDetails: React.FC<TProjectDetails> = () => {
  useSetAppBarTitle('Project details');

  const { id } = useGetUserSessionData();
  const { projectId } = useParams();
  const {
    data: projectChartsData,
    error,
    loading
  } = useGetChartData(projectId as string);
  const [getProjectData] = useGetProjectByIdLazyQuery();
  const [onDownloadResults] = useDownloadProjectsJsonResults();
  const { title } = projectChartsData ?? {};
  const redirect = useRedirect();

  const [periodStart, setPeriodStart] = useState<number | null>(null);
  const [periodEnd, setPeriodEnd] = useState<number | null>(null);

  const history = useNavigate();

  const { showBy, stats } = useStats({
    initialStats: projectChartsData?.projectStats,
    periodEnd,
    periodStart
  });

  const onProjectSelect = (projectId?: string) => {
    projectId && redirect('/project-details/' + projectId);
  };

  const handleDownloadResults = async () => {
    try {
      if (!projectId) throw new Error('No project id provided');
      const projectDataRaw = await getProjectData({
        variables: {
          projectId: parseInt(projectId, 10)
        }
      });
      onDownloadResults(projectDataRaw?.data?.project);
    } catch (e) {
      consoleError(e);
    }
  };

  if (!projectId) {
    return (
      <div>
        <HeaderAppBar backPath="/dashboard" />
        <RouteContentStyled>
          <Row gridGap="2rem" maxWidth="1200px">
            <TitleCol>
              <ProjectTitle>Please, select project</ProjectTitle>
            </TitleCol>
            <SelectProjects
              placeholder="Please, select project"
              clientId={id}
              onSelect={onProjectSelect}
              isReadyOnly
            />
          </Row>
        </RouteContentStyled>
      </div>
    );
  }

  return (
    <div>
      <HeaderAppBar />
      <RouteContentStyled>
        <Row gridGap="2rem" maxWidth="1200px">
          <TitleCol>
            <ProjectTitle>
              {projectId && title ? title : 'Reports'}
            </ProjectTitle>
          </TitleCol>
          <SelectProjects
            placeholder="Please, select project"
            clientId={id}
            onSelect={onProjectSelect}
            isReadyOnly
          />
        </Row>
        <Box>
          {/** TODO: Disabled
          <Chart
            onDownloadResults={handleDownloadResults}
            projectId={projectId}
            setPeriodEnd={setPeriodEnd}
            setPeriodStart={setPeriodStart}
            showBy={showBy}
            stats={stats}
            data={projectChartsData}
            loading={loading}
            error={error}
          />
          */}
        </Box>
      </RouteContentStyled>
    </div>
  );
};

export default ChartsWithProjectDetails;
