import React, { useEffect } from 'react';
import { VerticalField } from '../HelperDetailsCard.ui';
import { TabProps } from '../types';
import { GeneralTabRoot } from './General.ui';
import { useGetHelperPersonalDataLazyQuery } from 'generated/graphql';
import { formatDate } from 'utils/_dateUtilsLegacy';

type GeneralTabProps = {
  data: Array<{
    title: string;
    content: string;
  }>;
  loading?: boolean;
  helperId?: number;
} & TabProps;

const format = 'MM/DD/YYYY HH:mm';

function GeneralTab({ data: dataProp, helperId }: GeneralTabProps) {
  const [query, { data }] = useGetHelperPersonalDataLazyQuery();

  useEffect(() => {
    if (helperId) {
      query({
        variables: {
          helperId
        }
      });
    }
  }, [helperId, query]);

  const finalData = dataProp ?? [
    {
      title: 'Created At',
      content: formatDate(data?.helper?.createdAt, format)
    },
    {
      title: 'Last Login At',
      content: formatDate(data?.helper?.lastLoginAt, format)
    },
    {
      title: 'Activated At',
      content: formatDate(data?.helper?.activatedAt, format)
    },
    {
      title: 'Invited By',
      content: data?.helper?.invitedBy ?? 'System'
    }
  ];

  return (
    <GeneralTabRoot>
      {finalData &&
        Array.isArray(finalData) &&
        finalData.map(({ content, title }) => {
          return (
            <VerticalField key={title} title={title}>
              {content}
            </VerticalField>
          );
        })}
    </GeneralTabRoot>
  );
}

export default GeneralTab;
