import { css } from 'styled-components/macro';
import { TextProps } from '../Text.types';

const colorVariants = ({ theme, colorVariant = 'normal' }: TextProps) => {
  switch (colorVariant) {
    case 'primary':
      return css`
        color: ${theme?.colors.primary};
      `;
    case 'normal':
      return css`
        color: ${theme?.colors.fg};
      `;
    case 'invert':
      return css`
        color: ${theme?.colors.bg};
      `;
    case 'light':
      return css`
        color: ${theme?.colors.light};
      `;
    case 'lighter':
      return css`
        color: ${theme?.colors.lighter};
      `;
    case 'lightest':
      return css`
        color: ${theme?.colors.lightest};
      `;
    case 'warning':
    case 'error':
      return css`
        color: ${theme?.colors.warning};
      `;
    default:
      return;
  }
};

export default colorVariants;
