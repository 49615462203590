import { AnnotationSystemProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { arrayRemoveByIndex } from 'utils';
import { DeleteTransitionPoint } from '../types';
import { roundTimestampsToMs } from '../utils';
import modifyAnnotationUnitReducerCase from './modifyAnnotationUnitReducerCase';

export default function deleteTransitionPointReducerCase(
  state: AnnotationSystemProps,
  { timelineTimestamp, unitId }: DeleteTransitionPoint['payload']
) {
  const unit = state.annotationUnits.find((unit) => unit.unitId === unitId);
  if (!unit || !unit?.timelineTransitions) {
    return state;
  }

  const transitions = unit.timelineTransitions;

  const curTransPointIndex = transitions.findIndex(
    (point) => point.timestamp === roundTimestampsToMs(timelineTimestamp)
  );

  if (curTransPointIndex === -1) {
    return state;
  }

  return modifyAnnotationUnitReducerCase(state, {
    unitId: unitId,
    timelineTransitions: arrayRemoveByIndex(transitions, curTransPointIndex)
  });
}
