import React from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'utils/toast';
import { Col, Row, Button } from 'components/_main';
import { InputLabelField } from 'components/_form';
import { changePasswordValidationSchema } from 'utils';
import { ProfileDetailsProps } from 'containers/ProfileDetails/ProfileDetails.types';
import { useChangePasswordMutation } from 'generated/graphql';

function ChangePassword(props: ProfileDetailsProps) {
  const { email } = props;

  const initialValues = {
    oldPassword: '',
    password: '',
    passwordConfirmation: ''
  };

  const [changePassword] = useChangePasswordMutation({
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: () => toast.success('Password successfully updated.')
  });

  const handleSubmit = async ({
    oldPassword,
    password
  }: {
    oldPassword: string;
    password: string;
  }) => {
    await changePassword({
      variables: { oldPassword, newPassword: password, email }
    });
  };

  return (
    <Col gridGap="40px" wide maxWidth="600px">
      <Formik
        initialValues={initialValues}
        validationSchema={changePasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Row>
            <InputLabelField
              name="oldPassword"
              label="Current Password *"
              layout="vertical"
              password
            />
            <InputLabelField
              name="password"
              label="New Password *"
              layout="vertical"
              password
            />
            <InputLabelField
              name="passwordConfirmation"
              label="Confirm New Password *"
              layout="vertical"
              password
            />
          </Row>
          <Button type="submit" marginTop="30px">
            UPDATE
          </Button>
        </Form>
      </Formik>
    </Col>
  );
}

export default ChangePassword;
