import { Box, BoxProps } from 'components';
import React from 'react';

type DividerType = 'horizontal' | 'vertical';

interface Props extends BoxProps {
  type?: DividerType;
}

export function Divider({ type = 'horizontal', ...restProps }: Props) {
  return type === 'vertical' ? (
    <Box width="1px" height="100%" bg="lighter" {...restProps} />
  ) : (
    <Box width="100%" height="1px" bg="lighter" {...restProps} />
  );
}
