import React from 'react';
import { Row, Col } from 'components/_main';
import { Radio, RadioGroup } from 'components/Radio';
import { useAddProjectWizardContext } from '../../AddProjectWizard.context';
import { Platform } from 'generated/graphql';

function ProjectPlatformSelect() {
  const { platform, onSetProjectPlatform } = useAddProjectWizardContext();

  const handlePlatformChange: React.EventHandler<any> = (e) =>
    onSetProjectPlatform(e.target.value);

  return (
    <Col width="100%" gridAutoColumns="max-content 1fr">
      <Row>
        <RadioGroup value={platform} onChange={handlePlatformChange}>
          <Row gridGap="0.6rem">
            <Radio value={Platform.Hosted}>Use Taskware</Radio>
            <Radio value={Platform.Own}>I have my own platform</Radio>
          </Row>
        </RadioGroup>
      </Row>
    </Col>
  );
}

export default ProjectPlatformSelect;
