import React, { FC } from 'react';
import { Formik, FormikProps } from 'formik';
import { Form as AntForm } from 'antd';
import { ForgotPasswordFormValues } from './types';
import { toast } from 'utils/toast';
import * as yup from 'yup';
import { email } from 'utils/formikFormValidationHelpers';
import { Container, Row, Button, Text } from 'components/_main';

import { useRedirect } from 'hooks/useRedirect';
import { currentRole } from 'utils/role';
import { upper } from 'utils/_stringUtilsLegacy';
import { InputLabelField } from 'components/_form';
import { useRequestPasswordResetMutation } from 'generated/graphql';
import AuthPageView from 'containers/AuthPageViewNew';
import AuthFormWrapper from 'containers/AuthPageViewNew/AuthFormWrapper';
import { LinkStyled } from 'containers/AuthPageViewNew/AuthPageView.ui';

const FormItem = AntForm.Item;

export const ForgotPasswordForm: FC = () => {
  const handleRedirect = useRedirect();
  const initialValues: ForgotPasswordFormValues = {
    email: '',
    role: upper(currentRole() ?? 'client')
  };

  const [requestPassword, { loading }] = useRequestPasswordResetMutation({
    onCompleted: () => {
      toast.success('An email has been sent to you with instructions');
      handleRedirect('/reset');
    },
    onError: (e: Error) => {
      toast.error(e.message);
    }
  });

  return (
    <AuthPageView>
      <AuthPageView.PaneChild>
        <Container
          width="440px"
          minWidth="440px"
          flexDirection="column"
          gridGap="30px"
        >
          <Row gridGap="1rem" justifyItems="center">
            <Text variant="ui-4-bold">Reset Password</Text>
            <Text
              variant="ui-1-regular"
              lineHeight="22px"
              textAlign="center"
              padding="0 31px"
            >
              Type your email and we will send you a link to restore your
              password
            </Text>
          </Row>
          <Formik
            initialValues={initialValues}
            validationSchema={yup.object({
              email
            })}
            onSubmit={async (values: any) => {
              await requestPassword({ variables: values });
            }}
          >
            {({ submitForm }: FormikProps<ForgotPasswordFormValues>) => (
              <AuthFormWrapper submitForm={submitForm}>
                <Row gridGap="20px" width="100%">
                  <InputLabelField name="email" placeholder="Email" />
                  <FormItem noStyle>
                    <Button
                      width="100%"
                      maxWidth="100%"
                      isLoading={loading}
                      onClick={submitForm}
                    >
                      SEND ME A LINK
                    </Button>
                  </FormItem>
                </Row>
              </AuthFormWrapper>
            )}
          </Formik>
          <Text textAlign="center" padding="16px 0 2px">
            <LinkStyled to="/signin">
              <Text variant="ui-1-bold" colorVariant="primary">
                Back to Sign In
              </Text>
            </LinkStyled>
          </Text>
        </Container>
      </AuthPageView.PaneChild>
    </AuthPageView>
  );
};
