import { Button } from 'components/_main';
import React from 'react';
import { SubmitConfirmProps } from './ModalForm.types';

const SubmitConfirm = ({
  okText,
  onConfirmOpen,
  loading,
  isDisabled
}: SubmitConfirmProps) => {
  return (
    <Button
      variant="primary"
      isLoading={loading}
      htmlType="button"
      disabled={isDisabled}
      onClick={onConfirmOpen}
    >
      {okText}
    </Button>
  );
};

export default SubmitConfirm;
