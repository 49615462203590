import React from 'react';
import { ImgStyled } from './MediaLayer.ui';
import retPxOrString from 'utils/retPxOrString';
import { MediaLayerImageProps, MediaLayerVideoProps } from './MediaLayer.types';
import consoleError from 'utils/consoleError';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../../context';
import VideoPlayer from 'containers/VideoPlayer';

export const MediaLayerVideo = React.forwardRef<
  HTMLVideoElement,
  MediaLayerVideoProps
>(({ mediaUrl, onLoadMetadata, videoProps }: MediaLayerVideoProps, ref) => {
  const { videoEnd, videoStart, initH, initW, curZoomLevel } =
    useAnnotationSystemState();

  const { onSetCurVideoStatus } = useAnnotationSystemActions();

  if (
    !mediaUrl ||
    videoEnd === null ||
    videoEnd === undefined ||
    videoStart === undefined ||
    videoStart === null
  ) {
    consoleError('MediaLayerVideo load error', {
      mediaUrl,
      videoEnd,
      videoStart
    });
    return null;
  }

  return (
    <VideoPlayer
      ref={ref}
      w={retPxOrString(initW * curZoomLevel)}
      h={retPxOrString(initH * curZoomLevel)}
      startTime={videoStart}
      endTime={videoEnd}
      src={mediaUrl}
      onPlay={() => {
        onSetCurVideoStatus('play');
      }}
      onPause={() => {
        onSetCurVideoStatus('stop');
      }}
      type="video/webm"
      onLoadMetadata={onLoadMetadata}
      videoProps={videoProps}
    />
  );
});

export const MediaLayerImage = React.forwardRef<
  HTMLImageElement,
  MediaLayerImageProps
>(({ onLoad, imgProps, mediaUrl }: MediaLayerImageProps, ref) => {
  const { initH, initW, curZoomLevel } = useAnnotationSystemState();

  const { onSetIsLoading } = useAnnotationSystemActions();

  if (!mediaUrl) {
    return null;
  }

  return (
    <ImgStyled
      className="noselect"
      ref={ref}
      src={mediaUrl}
      w={retPxOrString(initW * curZoomLevel)}
      h={retPxOrString(initH * curZoomLevel)}
      onLoad={(e) => {
        onLoad && onLoad(e);
        onSetIsLoading(false);
      }}
      {...imgProps}
    />
  );
});
