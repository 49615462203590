import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { toast } from 'utils/toast';
import consoleError from 'utils/consoleError';
import { getTokenStorage } from 'utils';
import sentryException from 'utils/sentryException';

const ApolloClientInit = () => {
  const cache = new InMemoryCache();

  const authLink = new ApolloLink((operation, forward) => {
    const token = getTokenStorage();
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    });

    return forward(operation);
  });

  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_SERVER_URL
  });

  const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        sentryException(graphQLErrors);
        consoleError({ graphQLErrors });
        graphQLErrors.forEach(({ message, locations, path }) => {
          consoleError(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${path}`
          );
          if (message.match(/access forbidden/i)) {
            localStorage.clear();
            sessionStorage.clear();
            client.clearStore();
            setTimeout(() => {
              consoleError('Refresh page, from Apollo');
              window.location.replace('/signin?error=unauth');
            }, 500);
          }
        });
      }

      if (networkError) {
        sentryException(networkError);
        consoleError({
          message: `[Network error]: ${networkError}`,
          networkError
        });
        toast.error(`[Network error]: ${networkError}`);
      }
    }),
    authLink,
    uploadLink as unknown as ApolloLink
  ]);

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link,
    cache
  });

  return client;
};

export default ApolloClientInit;
