import styled, { css } from 'styled-components/macro';
import Box, { BoxProps } from 'components/_main/Box';
import React, { PropsWithChildren } from 'react';

export const FooterStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  border-top: 1px solid #f0f0f0;
  padding-top: 20px;
`;

export function Body(props: PropsWithChildren<BoxProps>) {
  return <Box {...props} width="100%" data-name="modal-body" />;
}

export const Content = styled.div<{ formLayout?: 'horizontal' | 'vertical' }>(
  ({ formLayout }) => css`
    display: grid;
    grid-auto-flow: row;
    gap: 1rem;
    width: 100%;
    height: 100%;

    .ant-row.ant-form-item {
      ${formLayout === 'horizontal' &&
      css`
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 150px 1fr;
      `};
      .ant-col.ant-form-item-label {
        display: grid;

        label {
          height: 100%;
          text-align: left;
          white-space: break-spaces;
        }
      }
    }
  `
);

export const ConfirmContent = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
  justify-content: center;
`;

export const ConfirmTitle = styled.div`
  justify-content: center;
  display: grid;
`;

export const ConfirmButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
`;

export const ModalFooterButtons = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;
