import styled from 'styled-components/macro';
import { Col } from 'components/_main';
import { RouteContent } from 'components/RouteContent';
import React from 'react';

export const RouteContentStyled = styled(RouteContent)`
  gap: 2rem;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content 1fr;
  align-items: flex-start;

  .recharts-wrapper .recharts-surface {
    overflow: visible;
  }
`;

export const Paper = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: visible;
  background-color: white;
`;

export const TopPlank = styled.div`
  position: relative;
  padding: 0 1rem;
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;
  width: max-content;
  grid-auto-columns: max-content;
  align-items: center;
`;

export const BackButton = styled.span`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

export const TasksSeparator = styled.div`
  position: relative;

  &::after {
    position: absolute;
    content: ' ';
    width: 1px;
    height: 60%;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ color }) => (color ? color : '#dbdbdb')};
  }
`;

export const BigNumber = styled(
  ({
    className,
    title,
    number
  }: {
    className?: string;
    title: string;
    number?: number;
    color?: string;
  }) => {
    return (
      <div className={className}>
        <div data-component={'title'}>{title}</div>
        <div data-component={'number'}>{number ?? 0}</div>
      </div>
    );
  }
)`
  display: grid;
  grid-auto-flow: row;
  gap: 5px;
  height: 100%;
  padding: 1rem;
  justify-items: center;
  position: relative;
  [data-component='title'] {
    font-size: 11px;
    color: #8095a3;
  }
  [data-component='number'] {
    font-size: 30px;
    font-weight: 500;
    line-height: 100%;
  }
  &::after {
    bottom: 0;
    position: absolute;
    content: ' ';
    width: 100%;
    height: 3px;
    background-color: ${({ color }) => (color ? color : '#8095a3')};
  }
`;

export const ChartWrapper = styled.div`
  display: grid;
  gap: 1rem;
  padding: 2rem 1rem;
  grid-template-columns: max-content max-content;
  grid-template-rows: max-content max-content;
`;

export const ChartHeader = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-row: 1/2;
  grid-column: 1/3;
  align-items: center;
  margin-bottom: 1rem;
`;

export const ChartTogglersWrapper = styled.div`
  display: grid;
  grid-column: 1/2;
  grid-row: 2/3;
  grid-auto-flow: row;
  gap: 1rem;
  grid-auto-rows: max-content;

  .ant-row.ant-form-item {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-end;
  }
`;

export const ProjectTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

export const TitleCol = styled(Col)`
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
`;
