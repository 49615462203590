import React from 'react';
import { VerticalField } from './VerticalField';
import AntdSwitch from 'components/AntdSwitch';

function AvailableToggle({ onToggle, loading, id, available }: any) {
  const [curAvailable, setCurAvailable] = React.useState(available);

  return (
    <VerticalField title="Is enabled">
      <AntdSwitch
        loading={loading}
        disabled={loading}
        checked={curAvailable}
        onChange={async () => {
          try {
            await onToggle(id);
            setCurAvailable(!curAvailable);
          } catch (e) {}
        }}
      />
    </VerticalField>
  );
}

export default AvailableToggle;
