import { RouteContent } from 'components/RouteContent';
import HeaderAppBar from 'containers/HeaderAppBar';
import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Text } from 'components/_main';
import Search from 'components/Search';
import { Card } from './Microtasking.ui';
import { ReactComponent as MicrotaskingItemIcon } from './item.svg';
import useOpen from 'hooks/useOpen';
import { AddProjectWizard } from 'containers/AddProjectWizard';
import { ProjectSpecialization, ProjectType, ProjectTypeLabel } from 'appTypes';
import {
  mapProjectSpecToAnnotationTitle,
  mapProjectSpecToAnnotationType,
  microtaskingExtendedStructure
} from 'appConstants';
import 'styled-components/macro';
import CautionPlateResendActivationLink from 'containers/CautionPlateResendActivationLink';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import ExpandTasksBlock from 'containers/Microtasking/components/ExpandTasksBlock';
import { CARD_COLORS } from 'containers/Microtasking/Microtasking.constants';

const TASK_CARD_GAP = 16;
const TASK_CARD_WIDTH = 300;

interface IconProps {
  color: string;
}

function Icon({ color }: IconProps) {
  return (
    <Grid
      flexCenter
      width="60px"
      height="60px"
      borderRadius="100rem"
      backgroundColor={color}
    >
      <MicrotaskingItemIcon />
    </Grid>
  );
}

export default function Microtasking() {
  const [projectData, setProjectData] = useState({
    type: ProjectType.IMAGE_ANNOTATION,
    annotationType: ProjectSpecialization.SENTIMENT_ANALYSIS
  });
  const [structure, setStructure] = useState(microtaskingExtendedStructure);
  const [search, setSearch] = useState('');
  const { activated } = useGetUserSessionData();
  const {
    isOpen: isCreateProjectOpen,
    onOpenCb: onCreateProjectOpenCb,
    onClose: onCreateProjectClose
  } = useOpen();

  useEffect(() => {
    setStructure(
      microtaskingExtendedStructure.map(({ specializations, ...rest }) => ({
        ...rest,
        specializations: specializations.filter(({ specType }) =>
          mapProjectSpecToAnnotationTitle[
            mapProjectSpecToAnnotationType[specType]
          ]
            .toLowerCase()
            .includes(search.toLowerCase())
        )
      }))
    );
  }, [search]);

  const handleOpenModal = (
    type: ProjectType,
    specialization: ProjectSpecialization
  ) => {
    onCreateProjectOpenCb(() =>
      setProjectData({ type, annotationType: specialization })
    )();
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <div>
      <HeaderAppBar />
      <RouteContent>
        <Row gridGap="30px">
          <Row justifyItems="center">
            {!activated && <CautionPlateResendActivationLink />}
          </Row>
          <Row noGap width="100%">
            <Text pb="20px" variant="header-2">
              Microtasking
            </Text>
            <Col displayFlex width="100%" justifyContent="space-between">
              <Text pb="40px">
                Create a microtask, add instructions, set a deadline.
                <br /> And get yourself a break, we will manage everything!
              </Text>
              <Search
                placeholder="Search task..."
                boxProps={{ padding: 0, width: '285px' }}
                onSearch={(input) => {
                  handleSearch(input);
                }}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </Col>
            <Row gridGap="30px" width="100%">
              {structure.map(({ id, type, specializations }) => {
                if (specializations.length === 0) {
                  return null;
                }
                return (
                  <ExpandTasksBlock
                    key={id}
                    cardWidth={TASK_CARD_WIDTH}
                    cardGap={TASK_CARD_GAP}
                    allCardsCount={specializations.length}
                  >
                    <Row gridGap="20px">
                      <Text variant="ui-small-2-bold" upper>
                        {
                          ProjectTypeLabel[
                            type as keyof typeof ProjectTypeLabel
                          ]
                        }
                      </Text>
                      <Grid
                        width="100%"
                        gridTemplateColumns={`repeat(auto-fill, minmax(${TASK_CARD_WIDTH}px, ${
                          TASK_CARD_WIDTH + 20
                        }px))`}
                        gridGap={TASK_CARD_GAP}
                      >
                        {specializations.map(({ id, specType, color }) => (
                          <Card
                            key={id}
                            title={
                              mapProjectSpecToAnnotationTitle[
                                mapProjectSpecToAnnotationType[specType]
                              ]
                            }
                            cardWidth={TASK_CARD_WIDTH}
                            description=""
                            icon={<Icon color={color ?? CARD_COLORS[0]} />}
                            onAdd={() => handleOpenModal(type, specType)}
                          />
                        ))}
                      </Grid>
                    </Row>
                  </ExpandTasksBlock>
                );
              })}
            </Row>
          </Row>
        </Row>
        <AddProjectWizard
          key="create-new-project"
          isOpen={isCreateProjectOpen}
          initialFormData={{
            type: projectData.type,
            projectSettings: {
              annotationType: projectData.annotationType
            }
          }}
          onClose={onCreateProjectClose}
        />
      </RouteContent>
    </div>
  );
}
