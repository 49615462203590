import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ResizePosition } from '../../AnnotationSystem.types';

interface ResizePointProps {
  color?: string;
  x: string;
  y: string;
  cursor: string;
  resizePosition: string;
}

const SIZE = 5;

const RootSvg = styled.svg(
  ({ cursor }: { cursor: string }) => css`
    pointer-events: all;
    overflow: visible;
    cursor: ${cursor};
    &:hover {
      ${RectStyled}, ${CircleStyled} {
      }
    }
  `
);

const RectInnerStyled = styled.rect(
  () => css`
    pointer-events: all;
    overflow: visible;
    z-index: 10;
    transform: translate(-50%, -50%);
  `
);

const RectStyled = styled.rect(
  ({ color }) => css`
    overflow: visible;
    transform: translate(-50%, -50%);
    fill: ${color};
    position: relative;
    z-index: 2;
  `
);

const CircleStyled = styled.circle(
  ({ color }) => css`
    overflow: visible;
    fill: ${color};
    position: relative;
    z-index: 2;
  `
);

const CircleInnerStyled = styled.circle(
  () => css`
    overflow: visible;
    fill: ${({ theme }) => theme.colors.bg};
    position: relative;
    z-index: 2;
    pointer-events: none;
  `
);

function ResizePoint({
  color,
  resizePosition,
  ...restProps
}: ResizePointProps) {
  return (
    <RootSvg
      {...restProps}
      data-resize-position={resizePosition}
      width={SIZE}
      height={SIZE}
      data-change-type="resize"
    >
      <CircleStyled
        data-change-type="resize"
        data-resize-position={resizePosition}
        color={color}
        r="100%"
        width={SIZE}
        height={SIZE}
        cx="0"
        cy="0"
        x="0"
        y="0"
      />
      <CircleInnerStyled
        data-resize-position={resizePosition}
        cx="0"
        cy="0"
        x="0"
        y="0"
        r="20%"
        data-change-type="resize"
      />
    </RootSvg>
  );
}

function ResizePoints({ color }: { color: string }) {
  return (
    <>
      <ResizePoint
        color={color}
        y="0%"
        x="0%"
        resizePosition={ResizePosition.TOP_LEFT}
        cursor="nwse-resize"
      />
      <ResizePoint
        color={color}
        y="0%"
        x="50%"
        resizePosition={ResizePosition.TOP_CENTER}
        cursor="ns-resize"
      />
      <ResizePoint
        color={color}
        y="0%"
        x="100%"
        cursor="nesw-resize"
        resizePosition={ResizePosition.TOP_RIGHT}
      />
      <ResizePoint
        color={color}
        x="0%"
        y="50%"
        cursor="ew-resize"
        resizePosition={ResizePosition.LEFT_CENTER}
      />
      <ResizePoint
        color={color}
        y="100%"
        x="0%"
        cursor="nesw-resize"
        resizePosition={ResizePosition.BOTTOM_LEFT}
      />
      <ResizePoint
        color={color}
        x="100%"
        y="50%"
        cursor="ew-resize"
        resizePosition={ResizePosition.RIGHT_CENTER}
      />
      <ResizePoint
        color={color}
        x="100%"
        y="100%"
        cursor="nwse-resize"
        resizePosition={ResizePosition.BOTTOM_RIGHT}
      />
      <ResizePoint
        color={color}
        x="50%"
        y="100%"
        cursor="ns-resize"
        resizePosition={ResizePosition.BOTTOM_CENTER}
      />
    </>
  );
}

export default ResizePoints;
