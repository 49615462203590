import React, { useCallback, useMemo, useState } from 'react';
import { Row, ButtonPrimary, ButtonSecondary } from 'components/_main';
import DatePicker from 'components/DatePicker';
import InputLabel from 'components/InputLabel';
import Table from 'components/Table';
import SelectProjects from 'components/SelectProjects';
import SelectClients from 'components/SelectClients';
import {
  useClientCurrentTasksStateReportCsvLazyQuery,
  useClientCurrentTasksStateReportLazyQuery
} from 'generated/graphql';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import columns from './ClientCurrentTasksStateReport.columns';
import downloadContent from 'utils/downloadContent';
import { TFormValues } from './ClientCurrentTasksStateReport.types';
import {
  ButtonCellStyled,
  ColHeaderStyled,
  Root
} from './ClientCurrentTasksStateReport.styled';
import { ClearAllText } from '../ReportsPage.styled';

const initValues: TFormValues = {
  project: '',
  client: '',
  dateFrom: null,
  dateTo: null
};

const ClientCurrentTasksStateReport = () => {
  const [{ dateTo, dateFrom, client }, setFormValues] =
    useState<TFormValues>(initValues);

  const [tableData, setTableData] = useState<(object | null)[]>();

  const [form] = useForm();

  const [onGetClientStateReport, { loading }] =
    useClientCurrentTasksStateReportLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data1) => {
        const dataJson = data1?.clientProjectsReport?.JSON || [];
        const reversed = [...dataJson].reverse();
        setTableData(
          Array.isArray(reversed) && reversed.length > 0 ? reversed : []
        );
      }
    });

  const [onGetClientStateReportCsv, { data: dataCsv, loading: loadingCsv }] =
    useClientCurrentTasksStateReportCsvLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: () => {}
    });

  const handleOnSubmit = useCallback(
    (values: TFormValues) => {
      const { client, dateFrom, dateTo, project } = values;

      if (!dateTo || !dateFrom || !client) {
        console.error('Not enough variables');
        return;
      }

      onGetClientStateReport({
        variables: {
          projectId: parseInt(project, 10),
          clientId: parseInt(client, 10),
          dateFrom: dateFrom.toDate(),
          dateTo: dateTo.toDate()
        }
      });

      onGetClientStateReportCsv({
        variables: {
          projectId: parseInt(project, 10),
          clientId: parseInt(client, 10),
          dateFrom: dateFrom.toDate(),
          dateTo: dateTo.toDate()
        }
      });
    },
    [onGetClientStateReport, onGetClientStateReportCsv]
  );

  const isSubmitDisabled: boolean = useMemo(() => {
    return !client || !dateTo?.toDate() || !dateFrom?.toDate();
  }, [client, dateFrom, dateTo]);

  const handleOnFormReset = useCallback(() => {
    form.resetFields();
    setFormValues(initValues);
    setTableData(undefined);
  }, [form]);

  const handleOnProjectSelect = useCallback((projectId: any) => {
    setFormValues((values) => ({
      ...values,
      project: projectId ?? ''
    }));
  }, []);
  const handleOnClientSelect = useCallback(
    (clientId: any) => {
      setFormValues((values) => ({
        ...values,
        client: clientId ?? ''
      }));
      form.setFieldsValue({
        project: ''
      });
    },
    [form]
  );
  const handleOnDateFromSelect = useCallback((date: any) => {
    setFormValues((values) => ({
      ...values,
      dateFrom: date ?? null
    }));
  }, []);
  const handleOnDateToSelect = useCallback((date: any) => {
    setFormValues((values) => ({
      ...values,
      dateTo: date ?? null
    }));
  }, []);

  return (
    <Form
      form={form}
      initialValues={initValues}
      onFinish={handleOnSubmit}
      layout={'vertical'}
    >
      <Root>
        <Row wide>
          <ColHeaderStyled>
            <SelectClients
              name={'client'}
              isRequired={true}
              rules={[{ required: true }]}
              onSelect={handleOnClientSelect}
            />
            <SelectProjects
              showArchived={true}
              clientId={client}
              name={'project'}
              isClientRequired={true}
              onSelect={handleOnProjectSelect}
            />
            <InputLabel
              rules={[{ required: true }]}
              name={'dateFrom'}
              required={true}
              label={'Date from'}
              layout={'vertical'}
            >
              <DatePicker onSelect={handleOnDateFromSelect} />
            </InputLabel>
            <InputLabel
              rules={[{ required: true }]}
              name={'dateTo'}
              required={true}
              label={'Date to'}
              layout={'vertical'}
            >
              <DatePicker onSelect={handleOnDateToSelect} />
            </InputLabel>
            <ClearAllText onClick={handleOnFormReset}>Clear all</ClearAllText>
            <span> </span>
            <ButtonCellStyled>
              <ButtonPrimary
                disabled={isSubmitDisabled || loading}
                type={'submit'}
              >
                Search
              </ButtonPrimary>
            </ButtonCellStyled>
            <ButtonCellStyled>
              <ButtonSecondary
                disabled={
                  loadingCsv || loading || !tableData || tableData?.length === 0
                }
                type={'button'}
                onClick={() => {
                  const data = dataCsv?.clientProjectsReport?.CSV;
                  data &&
                    downloadContent(
                      'current-tasks-state-report.csv',
                      data,
                      'data:text/csv;charset=utf-8,'
                    );
                }}
              >
                Export
              </ButtonSecondary>
            </ButtonCellStyled>
          </ColHeaderStyled>
        </Row>
        <Row wide>
          <Table
            loading={loading}
            columns={columns}
            dataSource={(tableData as object[]) || []}
          />
        </Row>
      </Root>
    </Form>
  );
};

export default ClientCurrentTasksStateReport;
