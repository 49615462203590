import {
  AnnotationSpecificationType,
  ImageAnnotationDatasetProcessingType,
  InvitedHelper,
  ProjectSpecialization,
  ProjectType,
  VideoAnnotationDatasetProcessingType,
  VideoAnnotationDatasetSplitByType
} from 'appTypes';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  AddProjectFormOptions,
  AddProjectWizardContextState,
  FormRef,
  GoogleDriveFile,
  WizardFormValues
} from './AddProjectWizard.types';
import {
  ADD_PROJECT_WIZARD_CONTEXT_STATE_INIT,
  WIZARD_FORM_INITIAL_VALUES
} from './AddProjectWizard.constants';
import { currentRole } from 'utils';
import { varEnv } from 'utils/envVars';
import {
  ADD_WIZARD_FIELDS,
  CHUNK_LENGTH,
  getSpecializationByAnnotationType,
  mapProjectSpecToAnnotationType
} from 'appConstants';
import useGetUserSessionData from '../../hooks/useGetUserSessionData';
import { consoleLogDebounced } from 'utils/consoleLog';
import { Platform } from 'generated/graphql';
import { checkIsOnlyRequestCustomHelpers } from 'containers/AddProjectWizard/AddProjectWizard.utils';

const addProjectWizardContext = createContext<AddProjectWizardContextState>(
  ADD_PROJECT_WIZARD_CONTEXT_STATE_INIT
);

export function ContextConsumer({
  children
}: {
  children: (context: any) => React.ReactNode;
}) {
  return (
    <addProjectWizardContext.Consumer>
      {(state) => children(state)}
    </addProjectWizardContext.Consumer>
  );
}

interface ContextStateProps {
  initialData?: Partial<AddProjectWizardContextState>;
  initialFormData?: Partial<WizardFormValues>;
}

export function useContextState({
  initialData,
  initialFormData
}: ContextStateProps): AddProjectWizardContextState {
  const { activated } = useGetUserSessionData();

  const curRole = currentRole();

  const initialIsMultiLevelLabels = Boolean(
    initialFormData?.mlLabels?.multilabels &&
      initialFormData?.mlLabels?.multilabels.length > 0
  );

  const initialSubType =
    (initialFormData?.projectSettings
      ?.annotationType as ProjectSpecialization) ||
    ProjectSpecialization.BOUNDING_BOX;

  const [state, setState] = useState<AddProjectWizardContextState>({
    ...ADD_PROJECT_WIZARD_CONTEXT_STATE_INIT,
    ...initialData,
    isMultiLevelLabels: initialIsMultiLevelLabels,
    specialization: initialSubType,
    type: initialFormData?.type ?? ADD_PROJECT_WIZARD_CONTEXT_STATE_INIT.type,
    curRole: initialData?.curRole ?? curRole,
    isUserActivated: activated,
    formOptions: {
      ...ADD_PROJECT_WIZARD_CONTEXT_STATE_INIT.formOptions,
      ...initialData?.formOptions
    }
  });

  varEnv.isSb &&
    consoleLogDebounced({ addProjectWizardContext: state }, 'AddProjectWizard');

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      isUserActivated: activated
    }));
  }, [activated]);

  useEffect(() => {
    const isOnlyRequestCustomHelpers = checkIsOnlyRequestCustomHelpers({
      type: initialFormData?.type,
      platform: initialFormData?.platform,
      projectSpecialization: initialSubType
    });
    setState((prevState) => ({
      ...prevState,
      isOnlyRequestCustomHelpers
    }));
  }, [initialFormData, initialSubType]);

  const handleSetMediaFile = useCallback((file: File | null) => {
    setState((prev) => {
      return {
        ...prev,
        mediaFile: file
      };
    });
  }, []);

  const handleSetVideoMediaFile = useCallback(
    (file: File | null, duration?: number, fileNameFromBe?: string) => {
      setState((prevState) => {
        setTimeout(() => {
          if (fileNameFromBe) {
            prevState?.formRef?.current?.setFieldValue(
              ADD_WIZARD_FIELDS.media,
              fileNameFromBe
            );
            return;
          }

          prevState?.formRef?.current?.setFieldValue(
            ADD_WIZARD_FIELDS.media,
            ''
          );
        }, 0);

        if (file) {
          return {
            ...prevState,
            videoMediaFile: file,
            videoFileDuration: duration ?? 0
          };
        }

        return {
          ...prevState,
          videoMediaFile: null,
          videoFileDuration: 0
        };
      });
    },
    [setState]
  );

  const handleSetDuration = useCallback(
    (duration: number) => {
      setState((prevState) => ({
        ...prevState,
        videoFileDuration: duration
      }));
    },
    [setState]
  );

  const handleSetVideoLoadProgress = useCallback(
    (loaded: number, total: number) => {
      setState((prevState) => ({
        ...prevState,
        videoLoadProgressLoaded: loaded,
        videoLoadProgressTotal: total
      }));
    },
    [setState]
  );

  const handleSetLoading = useCallback(
    (isLoading: boolean) => {
      setState((prevState) => ({ ...prevState, isLoading }));
    },
    [setState]
  );

  const handleSetAnnotationMultipleFiles = useCallback(
    (files: File[] | null | GoogleDriveFile[]) => {
      setState((prevState) => ({
        ...prevState,
        annotationMultipleFiles: files && Array.isArray(files) ? files : null
      }));
    },
    [setState]
  );

  const handleSetAuthToken = useCallback(
    (authToken: string) => {
      setState((prevState) => ({ ...prevState, authToken }));
    },
    [setState]
  );

  const handleSetProjectType = useCallback(
    (newProjectType: ProjectType) => {
      let specType: AnnotationSpecificationType = 'box';

      if (newProjectType === ProjectType.NLP) {
        specType = 'sentimentAnalysis';
      }
      if (newProjectType === ProjectType.CONTENT_MODERATION) {
        specType = 'contentModeration';
      }
      if (newProjectType === ProjectType.DATA_SET_COLLECTION) {
        specType = 'dataset';
      }

      setState((prevState) => {
        const isOnlyRequestCustomHelpers = checkIsOnlyRequestCustomHelpers({
          type: newProjectType,
          platform: prevState.platform,
          projectSpecialization: getSpecializationByAnnotationType(specType)
        });

        setTimeout(
          () =>
            prevState?.formRef?.current?.setValues(
              (prevState: WizardFormValues): WizardFormValues => ({
                ...prevState,
                mediaFileName: '',
                labels: [],
                mlLabels: WIZARD_FORM_INITIAL_VALUES.mlLabels,
                projectSettings: {
                  ...prevState.projectSettings,
                  subType: specType,
                  chunkLength: CHUNK_LENGTH.unlim
                }
              })
            ),
          0
        );

        return {
          ...prevState,
          type: newProjectType,
          specialization: getSpecializationByAnnotationType(specType),
          videoAnnotationSpecSubType:
            VideoAnnotationDatasetProcessingType.ENTIRE_VIDEO,
          videoAnnotationSplitType:
            VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE,
          imageAnnotationSpecSubType:
            ImageAnnotationDatasetProcessingType.SINGLE_ZIP_ARCHIVE,
          annotationMultipleFiles: [],
          videoMediaFile: null,
          annotationFilesUploadType: 'single',
          isOnlyRequestCustomHelpers
        };
      });
    },
    [setState]
  );

  const handleSetProjectSpecialization = useCallback(
    (newProjectSpecialization: ProjectSpecialization) => {
      setState((prevState) => {
        let newProjectSpec: AnnotationSpecificationType =
          mapProjectSpecToAnnotationType[newProjectSpecialization];
        let videoAnnotationSpecSubType =
          VideoAnnotationDatasetProcessingType.ENTIRE_VIDEO;
        let videoAnnotationSplitType =
          VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE;
        let defSplitTime = CHUNK_LENGTH.unlim;
        const isOnlyRequestCustomHelpers = checkIsOnlyRequestCustomHelpers({
          type: prevState.type,
          platform: prevState.platform,
          projectSpecialization: newProjectSpecialization
        });

        if (
          prevState.type === ProjectType.VIDEO_ANNOTATION &&
          newProjectSpecialization === ProjectSpecialization.HUMAN_POSES
        ) {
          defSplitTime = 1;
          videoAnnotationSpecSubType =
            VideoAnnotationDatasetProcessingType.SPLIT_BY_FRAMES;
          videoAnnotationSplitType =
            VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE;
        }

        setTimeout(
          () =>
            prevState?.formRef?.current?.setValues(
              (prevState: WizardFormValues): WizardFormValues => {
                return {
                  ...prevState,
                  mediaFileName: '',
                  labels: [],
                  mlLabels: WIZARD_FORM_INITIAL_VALUES.mlLabels,
                  projectSettings: {
                    ...prevState.projectSettings,
                    subType: newProjectSpec,
                    chunkLength: defSplitTime
                  }
                };
              }
            ),
          0
        );

        return {
          ...prevState,
          specialization: newProjectSpecialization,
          videoAnnotationSpecSubType,
          videoAnnotationSplitType,
          imageAnnotationSpecSubType:
            ImageAnnotationDatasetProcessingType.SINGLE_ZIP_ARCHIVE,
          annotationMultipleFiles: [],
          videoMediaFile: null,
          mediaFile: null,
          annotationFilesUploadType: 'single',
          isOnlyRequestCustomHelpers
        };
      });
    },
    [setState]
  );

  const handleSetProjectPlatform = useCallback(
    (newProjectPlatform: Platform) => {
      setState((prevState) => {
        const isOnlyRequestCustomHelpers = checkIsOnlyRequestCustomHelpers({
          type: prevState.type,
          platform: newProjectPlatform,
          projectSpecialization: prevState.specialization
        });

        return {
          ...prevState,
          platform: newProjectPlatform,
          isOnlyRequestCustomHelpers
        };
      });
    },
    [setState]
  );

  const handleSetVideoAnnotationSpecSubType = useCallback(
    (newVideoAnnotationSpecSubType: VideoAnnotationDatasetProcessingType) => {
      setState((prevState) => {
        setTimeout(
          () =>
            prevState?.formRef?.current?.setValues(
              (prevState: WizardFormValues): WizardFormValues => {
                let defChunks = CHUNK_LENGTH.unlim;

                if (
                  newVideoAnnotationSpecSubType ===
                  VideoAnnotationDatasetProcessingType.ENTIRE_VIDEO
                ) {
                  defChunks = CHUNK_LENGTH.unlim;
                }

                if (
                  newVideoAnnotationSpecSubType ===
                  VideoAnnotationDatasetProcessingType.SPLIT_BY_FRAMES
                ) {
                  defChunks = CHUNK_LENGTH.def;
                }

                return {
                  ...prevState,
                  projectSettings: {
                    ...prevState.projectSettings,
                    chunkLength: defChunks
                  }
                };
              }
            ),
          0
        );

        return {
          ...prevState,
          videoAnnotationSpecSubType: newVideoAnnotationSpecSubType,
          videoAnnotationSplitType:
            VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE
        };
      });
    },
    [setState]
  );

  const handleSetVideoAnnotationSplitType = useCallback(
    (newVideoAnnotationSplitType: VideoAnnotationDatasetSplitByType) => {
      setState((prevState) => {
        switch (newVideoAnnotationSplitType) {
          case VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE:
            break;
          case VideoAnnotationDatasetSplitByType.SPLIT_BY_EACH_FRAME:
            break;
          default:
        }

        setTimeout(
          () =>
            prevState?.formRef?.current?.setValues(
              (prevState: WizardFormValues): WizardFormValues => {
                let chunk = CHUNK_LENGTH.unlim;
                let subType = prevState?.projectSettings?.annotationType;

                if (
                  newVideoAnnotationSplitType ===
                    VideoAnnotationDatasetSplitByType.SPLIT_BY_EACH_FRAME &&
                  !subType?.match('/frames')
                ) {
                  subType = (subType +
                    '/frames') as AnnotationSpecificationType;
                  chunk = CHUNK_LENGTH.def;
                }

                if (
                  subType &&
                  newVideoAnnotationSplitType ===
                    VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE
                ) {
                  subType = subType.replace(
                    '/frames',
                    ''
                  ) as AnnotationSpecificationType;
                  chunk = CHUNK_LENGTH.def;
                }

                return {
                  ...prevState,
                  projectSettings: {
                    ...prevState.projectSettings,
                    chunkLength: chunk,
                    subType
                  }
                };
              }
            ),
          0
        );

        return {
          ...prevState,
          videoAnnotationSplitType: newVideoAnnotationSplitType
        };
      });
    },
    [setState]
  );

  const handleSetImageAnnotationSpecSubType = useCallback(
    (newImageAnnotationSpecSubType: ImageAnnotationDatasetProcessingType) => {
      setState((prevState) => {
        setTimeout(
          () =>
            prevState?.formRef?.current?.setFieldValue(
              ADD_WIZARD_FIELDS.media,
              ''
            ),
          0
        );
        let uploadType: 'single' | 'multiple' = 'single';

        switch (newImageAnnotationSpecSubType) {
          case ImageAnnotationDatasetProcessingType.MULTIPLE_IMAGES:
            uploadType = 'multiple';
            break;
          default:
        }

        return {
          ...prevState,
          annotationFilesUploadType: uploadType,
          imageAnnotationSpecSubType: newImageAnnotationSpecSubType,
          annotationMultipleFiles: [],
          videoMediaFile: null
        };
      });
    },
    [setState]
  );

  const handleSetIsLabelsCorrect = useCallback(
    (newIsLabelsCorrect: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isLabelsCorrect: newIsLabelsCorrect
      }));
    },
    [setState]
  );
  const handleSetIsMultiLevelLabels = useCallback(
    (newIsMultiLevelLabels: boolean) => {
      setState((prevState) => ({
        ...prevState,
        isMultiLevelLabels: newIsMultiLevelLabels
      }));
    },
    [setState]
  );

  const handleSetAnnotationFilesUploadType = useCallback(
    (newType: 'single' | 'multiple' | 'googleDrive') => {
      setState((prevState) => {
        setTimeout(() => {
          prevState?.formRef?.current?.setFieldValue(
            ADD_WIZARD_FIELDS.media,
            ''
          );
        }, 0);

        return {
          ...prevState,
          annotationFilesUploadType: newType
        };
      });
    },
    [setState]
  );

  const handleSetFormRef = useCallback(
    (newFormRef: FormRef) => {
      setState((prevState) => {
        return {
          ...prevState,
          formRef: { current: newFormRef }
        };
      });
    },
    [setState]
  );

  const handleSetFormOptions = useCallback(
    (newOptions: Partial<AddProjectFormOptions>) => {
      setState((prevState) => {
        return {
          ...prevState,
          formOptions: {
            ...prevState.formOptions,
            ...newOptions
          }
        };
      });
    },
    [setState]
  );

  const handleSetCurrentStep = useCallback(
    (newStep: number) => {
      setState((prevState) => {
        return {
          ...prevState,
          formOptions: {
            ...prevState.formOptions,
            currentStep: newStep
          }
        };
      });
    },
    [setState]
  );

  const handleSetInvitedHelpers = useCallback(
    (newInvitedHelpers: InvitedHelper[]) => {
      setState((prevState) => {
        return {
          ...prevState,
          invitedHelpers: newInvitedHelpers
        };
      });
    },
    [setState]
  );
  const handleSetIsRequestCustomHelpers = useCallback(
    (isRequestCustomHelpers: boolean) => {
      setState((prevState) => {
        return {
          ...prevState,
          isRequestCustomHelpers
        };
      });
    },
    [setState]
  );

  return useMemo(
    (): AddProjectWizardContextState => ({
      onSetMediaFile: handleSetMediaFile,
      isRequestCustomHelpers: state.isRequestCustomHelpers,
      isOnlyRequestCustomHelpers: state.isOnlyRequestCustomHelpers,
      onSetIsRequestCustomHelpers: handleSetIsRequestCustomHelpers,
      initialFormData,
      invitedHelpers: state.invitedHelpers,
      onSetInvitedHelpers: handleSetInvitedHelpers,
      curRole: state.curRole,
      onSetCurrentStep: handleSetCurrentStep,
      onSetFormRef: handleSetFormRef,
      formRef: state.formRef,
      isUserActivated: state?.isUserActivated,
      type: state?.type,
      platform: state?.platform,
      specialization: state?.specialization,
      onSetAuthToken: handleSetAuthToken,
      authToken: state?.authToken,
      annotationMultipleFiles: state?.annotationMultipleFiles,
      onSetAnnotationMultipleFiles: handleSetAnnotationMultipleFiles,
      onSetLoading: handleSetLoading,
      videoLoadProgressLoaded: state?.videoLoadProgressLoaded,
      videoLoadProgressTotal: state?.videoLoadProgressTotal,
      isLoading: state?.isLoading,
      videoFileDuration: state?.videoFileDuration,
      videoMediaFile: state?.videoMediaFile,
      mediaFile: state?.mediaFile,
      onSetVideoMediaFile: handleSetVideoMediaFile,
      onSetDuration: handleSetDuration,
      onVideoLoadProgress: handleSetVideoLoadProgress,
      onSetProjectSpecialization: handleSetProjectSpecialization,
      onSetProjectPlatform: handleSetProjectPlatform,
      onSetProjectType: handleSetProjectType,
      annotationFilesUploadType: state.annotationFilesUploadType,
      isMultiLevelLabels: state?.isMultiLevelLabels,
      videoAnnotationSpecSubType: state?.videoAnnotationSpecSubType,
      videoAnnotationSplitType: state?.videoAnnotationSplitType,
      imageAnnotationSpecSubType: state?.imageAnnotationSpecSubType,
      isLabelsCorrect: state?.isLabelsCorrect,
      onSetIsLabelsCorrect: handleSetIsLabelsCorrect,
      onSetAnnotationFilesUploadType: handleSetAnnotationFilesUploadType,
      onSetIsMultiLevelLabels: handleSetIsMultiLevelLabels,
      onSetVideoAnnotationSpecSubType: handleSetVideoAnnotationSpecSubType,
      onSetVideoAnnotationSplitType: handleSetVideoAnnotationSplitType,
      onSetImageAnnotationSpecSubType: handleSetImageAnnotationSpecSubType,
      onSetFormOptions: handleSetFormOptions,
      formOptions: state?.formOptions
    }),
    [
      handleSetMediaFile,
      state.isRequestCustomHelpers,
      state.isOnlyRequestCustomHelpers,
      state.invitedHelpers,
      state.curRole,
      state.formRef,
      state?.isUserActivated,
      state?.type,
      state?.platform,
      state?.specialization,
      state?.authToken,
      state?.annotationMultipleFiles,
      state?.videoLoadProgressLoaded,
      state?.videoLoadProgressTotal,
      state?.isLoading,
      state?.videoFileDuration,
      state?.videoMediaFile,
      state?.mediaFile,
      state.annotationFilesUploadType,
      state?.isMultiLevelLabels,
      state?.videoAnnotationSpecSubType,
      state?.videoAnnotationSplitType,
      state?.imageAnnotationSpecSubType,
      state?.isLabelsCorrect,
      state?.formOptions,
      handleSetIsRequestCustomHelpers,
      initialFormData,
      handleSetInvitedHelpers,
      handleSetCurrentStep,
      handleSetFormRef,
      handleSetAuthToken,
      handleSetAnnotationMultipleFiles,
      handleSetLoading,
      handleSetVideoMediaFile,
      handleSetDuration,
      handleSetVideoLoadProgress,
      handleSetProjectSpecialization,
      handleSetProjectPlatform,
      handleSetProjectType,
      handleSetIsLabelsCorrect,
      handleSetAnnotationFilesUploadType,
      handleSetIsMultiLevelLabels,
      handleSetVideoAnnotationSpecSubType,
      handleSetVideoAnnotationSplitType,
      handleSetImageAnnotationSpecSubType,
      handleSetFormOptions
    ]
  );
}

export function ContextProvider({
  children,
  ...restProps
}: React.PropsWithChildren<ContextStateProps>) {
  const value = useContextState(restProps);

  return (
    <addProjectWizardContext.Provider value={value}>
      {children}
    </addProjectWizardContext.Provider>
  );
}

export function useAddProjectWizardContext(): AddProjectWizardContextState {
  return useContext(addProjectWizardContext);
}
