import React from 'react';
import { Col, Grid } from '../_main';
import Text, { Link } from '../_main/Text';
import { WarningTipIcon } from 'assets/tips';
import { css, useTheme } from 'styled-components/macro';
import { upper } from 'utils/_stringUtilsLegacy';
import { CloseIcon } from 'assets';

interface CautionPlateProps {
  contentText: string;
  actionTitle?: string;
  size?: 'small' | 'normal' | 'big';
  onActionButton?: () => void;
  onCloseButton?: () => void;
}

function CautionPlate({
  contentText,
  actionTitle,
  size = 'small',
  onActionButton,
  onCloseButton
}: CautionPlateProps) {
  const theme = useTheme();

  let contentWidth = '340px';

  if (size === 'normal') contentWidth = '415px';
  if (size === 'big') contentWidth = '680px';

  const actionButtonGridStyle = css`
    position: relative;
    padding-left: 20px;
    &::after {
      position: absolute;
      content: ' ';
      width: 1px;
      height: 30px;
      background-color: ${theme.colors.lightest};
      left: 0;
    }
  `;

  return (
    <Col
      padding="0"
      display="grid"
      gridGap="0"
      alignItems="center"
      gridAutoColumns="max-content 1fr"
      borderRadius="5px"
      border={`1px solid ${theme.colors.yellow}`}
      backgroundColor="white"
      minWidth="380px"
    >
      <Grid
        maxSize
        width="50px"
        flexCenter
        backgroundColor={theme.colors.yellow}
      >
        <WarningTipIcon />
      </Grid>
      <Col
        width="100%"
        displayFlex
        justifyContent="space-between"
        padding="15px 20px"
        gridGap="20px"
      >
        <Grid flexCenter maxWidth={contentWidth}>
          <Text>{contentText}</Text>
        </Grid>

        <Col gridGap="20px">
          {onActionButton && (
            <Grid flexCenter>
              <Link onClick={onActionButton}>
                {actionTitle && upper(actionTitle)}
              </Link>
            </Grid>
          )}

          {onCloseButton && (
            <Grid flexCenter css={actionButtonGridStyle}>
              <CloseIcon />
            </Grid>
          )}
        </Col>
      </Col>
    </Col>
  );
}

export default CautionPlate;
