import React, { forwardRef, PropsWithChildren, ReactNode } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Box, { BoxProps } from '../../../../components/_main/Box';
import { RowProps } from 'components/_main';

export const Skeleton = forwardRef<HTMLDivElement, {}>((_, ref) => {
  let render: ReactNode[] = [];

  const theme = useTheme();

  for (const index in Array.from({ length: 4 }).fill(1).keys()) {
    render.push(
      <Box
        key={index}
        ref={ref}
        height="100%"
        width="100%"
        backgroundColor={theme.colors.lightest}
      >
        {' '}
      </Box>
    );
  }

  return <>{render}</>;
});

export const Wrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<BoxProps & RowProps>
>(({ children, ...restProps }, ref) => {
  return (
    <Box
      ref={ref}
      display="grid"
      gridAutoFlow="row"
      width="100%"
      height="100%"
      borderRadius="10px"
      overflowX="hidden"
      overflowY="auto"
      position="relative"
      alignItems="center"
      justifyContent="center"
      {...restProps}
    >
      {children}
    </Box>
  );
});

export const ImgNavBtn = styled(
  ({
    className,
    direction,
    onClick
  }: {
    className?: string;
    direction: 'left' | 'right';
    onClick?: React.MouseEventHandler;
  }) => {
    return (
      <div className={className} onClick={onClick}>
        {direction === 'left' ? <LeftOutlined /> : <RightOutlined />}
      </div>
    );
  }
)(
  ({ direction }) => css`
    cursor: pointer;
    position: absolute;
    display: grid;
    justify-content: center;
    align-content: center;
    border-radius: 50rem;
    width: 2rem;
    height: 2rem;
    background-color: rgba(255, 255, 255, 0.5);
    top: 50%;
    z-index: 1;
    ${direction === 'left'
      ? css`
          left: 1rem;
        `
      : css`
          right: 1rem;
        `};
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  `
);

export const ProjDetailImgCounter = styled.div`
  color: #88a0b1;
  font-weight: 300;
  font-style: italic;
`;
