import { useEffect } from 'react';
import appendScript from 'utils/appendScript';

export function useScript(script: string, skip?: boolean) {
  useEffect(() => {
    if (skip) return;

    const removeScript = appendScript(script);
    return removeScript;
  }, [script, skip]);
}
