import React from 'react';
import { toast } from 'utils/toast';
import { consoleError } from 'utils';
import { useResendActivationLinkMutation } from 'generated/graphql';
import CautionPlate from 'components/CautionPlate';

interface Props {
  contentText?: string;
}

function CautionPlateResendActivationLink({ contentText }: Props) {
  const [resendActivationLink] = useResendActivationLinkMutation();
  const handleResendActivationLink = async () => {
    try {
      await resendActivationLink();
      toast.success(
        'Activation link re-send successfully. Please check your email'
      );
    } catch (e) {
      toast.error('Please, try again later');
      consoleError(e);
    }
  };

  return (
    <CautionPlate
      contentText={
        contentText ??
        'Please check your email and activate your account to get full access to the app.'
      }
      actionTitle="Resend Link"
      onActionButton={handleResendActivationLink}
    />
  );
}

export default CautionPlateResendActivationLink;
