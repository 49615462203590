import React, { useMemo } from 'react';
import { upper } from 'utils';
import {
  UploadFiles,
  UploadFilesContextProviderProps
} from '../../UploadFiles';
import { Row, Text } from 'components/_main';
import { InputLabelField } from 'components/_form';
import { useField } from 'formik';
import handleUploadGeneric from '../../UploadFiles/handleUploadGeneric';
import { convertToFileStates } from '../../UploadFiles/UploadFiles.utils';
import { useAddProjectWizardContext } from '../AddProjectWizard.context';
import CautionPlateResendActivationLink from 'containers/CautionPlateResendActivationLink';
import { UPLOAD_CLIENT_INSTRUCTION_FILES } from 'graphql/mutations/createProjectWizard';
import { useMutation } from '@apollo/client';
import { ADD_WIZARD_FIELDS } from 'appConstants';
import { toast } from 'utils/toast';

interface StepUploadInstructionsDataProviderProps {
  clientShortInstructionName?: string;
  clientInstructionFileNamesName?: string;
}

interface StepUploadClientInstructionsProps
  extends UploadFilesContextProviderProps {
  isUserActivated?: boolean | null;
  clientShortInstructionName?: string;
  loading?: boolean;
}

const maxFileSize = 10000000;

export function StepUploadClientInstructions({
  isUserActivated,
  clientShortInstructionName = ADD_WIZARD_FIELDS.clientInstructionTextarea,
  uploaderProps,
  initialData
}: StepUploadClientInstructionsProps) {
  if (!isUserActivated) {
    return <CautionPlateResendActivationLink />;
  }

  return (
    <Row gridGap="40px">
      <UploadFiles
        initialData={{
          ...initialData
        }}
        uploaderProps={{
          ...uploaderProps,
          multiple: true,
          accept: '.pdf, .jpg, .txt, .doc, .pages, .csv, .png',
          maxFiles: 5,
          uploadFilesBlock: (
            <Text variant="ui-small-2-bold" upper>
              Upload instructions
            </Text>
          )
        }}
      />
      <Row width="100%" maxWidth="580px">
        <Text variant="ui-small-2-bold">{upper('Short instructions')}</Text>
        <InputLabelField
          name={clientShortInstructionName}
          placeholder="Write the quick summary of what needs to be done"
          textarea
          rows={6}
        />
      </Row>
    </Row>
  );
}

export function StepUploadClientInstructionsDataProvider({
  clientShortInstructionName = 'clientShortInstruction',
  clientInstructionFileNamesName = 'clientInstructionFileNames'
}: StepUploadInstructionsDataProviderProps) {
  const { curRole, isUserActivated } = useAddProjectWizardContext();

  const [field, , helper] = useField(clientInstructionFileNamesName);

  const value = field.value;

  const abortController = React.useRef<AbortController>(new AbortController());

  const [upload] = useMutation(UPLOAD_CLIENT_INSTRUCTION_FILES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    context: {
      fetchOptions: {
        signal: abortController.current.signal
      }
    }
  });

  const handleUpload = (fileObj: File) =>
    handleUploadGeneric({
      abortController,
      uploadMutation: upload({ variables: { file: fileObj } }),
      resolvePath: 'data.projectClientInstructionUpload.fname'
    });

  const finalInitialFile = useMemo(() => convertToFileStates(value), [value]);

  return (
    <StepUploadClientInstructions
      isUserActivated={isUserActivated}
      initialData={{
        initialFiles: finalInitialFile,
        fieldValueSetter: helper.setValue,
        onError: (_, error) => {
          error && toast.error(error);
        },
        maxSizePerFile: maxFileSize
      }}
      uploaderProps={{
        onUpload: handleUpload
      }}
      clientShortInstructionName={clientShortInstructionName}
    />
  );
}

export default StepUploadClientInstructionsDataProvider;
