import { ADD_WIZARD_FIELDS } from 'appConstants';
import { Roles } from 'appTypes';
import {
  LOGIN_ADMIN,
  LOGIN_CLIENT,
  LOGIN_HELPER,
  REGISTER_CLIENT,
  REGISTER_HELPER
} from 'graphql/mutations/auth';

const currentRoleFromEnv = process.env.REACT_APP_ROLE ?? 'client';
const [currentRoleFromUrl] = window.location.host.split('.') ?? 'client';

export { Roles };

export const currentRole = () => {
  const currentRoleFromStorage = sessionStorage.getItem(
    ADD_WIZARD_FIELDS.sbCurUser
  );
  return (currentRoleFromStorage ??
    (currentRoleFromUrl.match(/localhost/)
      ? currentRoleFromEnv
      : currentRoleFromUrl)) as Roles;
};

export const registerByUserRole = (roleType: any) => {
  switch (roleType) {
    case Roles.HELPER:
      return REGISTER_HELPER;
    case Roles.CLIENT:
      return REGISTER_CLIENT;
    default:
      return REGISTER_CLIENT;
  }
};

export const loginByUserRole = (roleType: any): any => {
  switch (roleType) {
    case Roles.HELPER:
      return LOGIN_HELPER;
    case Roles.CLIENT:
      return LOGIN_CLIENT;
    case Roles.ADMIN:
      return LOGIN_ADMIN;
    default:
      return LOGIN_CLIENT;
  }
};

export const isClient = () => currentRole() === Roles.CLIENT;
export const isHelper = () => currentRole() === Roles.HELPER;
export const isAdmin = () => currentRole() === Roles.ADMIN;

export const ifUserIsClient = (user?: string) => user === Roles.CLIENT;
export const ifUserIsAdmin = (user?: string) => user === Roles.ADMIN;
export const ifUserIsHelper = (user?: string) => user === Roles.HELPER;
