import React, { PropsWithChildren, useContext, useState } from 'react';
import { ClientCardProps, ContextType, CurPrjData } from './types';

const INIT_DATA: ContextType = {
  data: null,
  curPrjData: {
    data: null,
    isOpen: false
  },
  isOpen: false,
  onClose: () => {},
  refetchQueries: [],
  loading: {
    toggleLoading: false,
    editLoading: false
  },
  setLoading: () => {},
  isFromProjectDetailsCard: false,
  onCurPrjClose: () => {},
  setCurPrjData: () => {}
};

export const ctx = React.createContext<typeof INIT_DATA>(INIT_DATA);

export function ClientCardCtxProvider({
  data,
  refetchQueries,
  onClose,
  isOpen,
  children,
  isFromProjectDetailsCard
}: PropsWithChildren<ClientCardProps>) {
  const [loading, setLoading] = useState<{
    toggleLoading: boolean;
    editLoading: boolean;
  }>({
    toggleLoading: false,
    editLoading: false
  });

  const [curPrjData, setCurPrjData] = React.useState<CurPrjData>({
    data: null,
    isOpen: false
  });

  const handleOnCurPrjClose = () => {
    setCurPrjData((prevState: CurPrjData) => ({
      ...prevState,
      isOpen: false
    }));
  };

  const handleSetCurPrjData = (data: CurPrjData['data']) =>
    setCurPrjData({ data, isOpen: true });

  const handleSetLoading = React.useCallback(
    ({ editLoading, toggleLoading }: any) => {
      setLoading((prevLoadingStatus) => ({
        ...prevLoadingStatus,
        ...(editLoading !== undefined && { editLoading }),
        ...(toggleLoading !== undefined && { toggleLoading })
      }));
    },
    []
  );

  const ctxData: ContextType = {
    data,
    curPrjData,
    onCurPrjClose: handleOnCurPrjClose,
    setCurPrjData: handleSetCurPrjData,
    loading: {
      editLoading: loading.editLoading,
      toggleLoading: loading.toggleLoading
    },
    setLoading: handleSetLoading,
    refetchQueries,
    isFromProjectDetailsCard,
    onClose,
    isOpen
  };

  return <ctx.Provider value={ctxData}>{children}</ctx.Provider>;
}

export function useClientCardCtx() {
  const data = useContext(ctx);

  return data;
}
