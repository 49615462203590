import gql from 'graphql-tag';

export const CREATE_PROJECT = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    CreateProject(input: $input) {
      id
    }
  }
`;

export const CREATE_VIDEO_PROJECT = gql`
  mutation CreateVideoProject($input: CreateVideoAnnotationProjectInput!) {
    CreateVideoAnnotationProject(input: $input) {
      id
    }
  }
`;

export const CREATE_MULTIPLE_IMAGES_PROJECT = gql`
  mutation CreateMultiImageProject($input: CreateProjectInput) {
    createMultiImageProject(input: $input) {
      id
    }
  }
`;

export const UPLOAD_ADMIN_INSTRUCTION_FILES = gql`
  mutation UploadInstructionFile($file: Upload!) {
    projectAdminInstructionUpload(file: $file) {
      fname
    }
  }
`;

export const UPLOAD_CLIENT_INSTRUCTION_FILES = gql`
  mutation UploadClientInstructionFiles($file: Upload!) {
    projectClientInstructionUpload(file: $file) {
      fname
    }
  }
`;

export const CREATE_GOOGLE_DRIVE_PROJECT = gql`
  mutation CreateGoogleDriveProject($input: CreateProjectInput) {
    createGoogleDriveProject(input: $input) {
      id
    }
  }
`;

export const SAVE_DRAFT_PROJECT = gql`
  mutation SaveDraftProject($input: DraftCreateProjectInput) {
    saveDraftProject(input: $input) {
      id
    }
  }
`;

export const SEND_REQUEST_TO_HELPER = gql`
  mutation SendRequestToHelper(
    $email: String!
    $projectId: Int!
    $firstName: String!
    $lastName: String!
  ) {
    sendRequestToHelper(
      email: $email
      projectId: $projectId
      firstName: $firstName
      lastName: $lastName
    )
  }
`;

export const REQUEST_HELPERS = gql`
  mutation RequestHelpers($comment: String!, $projectId: Int!) {
    requestHelpers(comment: $comment, projectId: $projectId)
  }
`;

export const UPLOAD_MEDIA = gql`
  mutation ProjectMediaUpload($file: Upload!, $clientId: Int) {
    projectMediaUpload(file: $file, clientId: $clientId)
  }
`;
