import React, { useMemo } from 'react';
import { Project } from 'generated/graphql';
import InviteHelpers, {
  InviteHelpersContextProvider
} from 'containers/InviteHelpers';
import {
  useGetListOfHelpersQuery,
  useRequestHelpersMutation,
  useSendRequestToHelperMutation
} from 'generated/graphql';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import parseSubType from 'utils/parseSubType';
import { InvitedHelper, ProjectType } from 'appTypes';
import { checkIsOnlyRequestCustomHelpers } from 'containers/AddProjectWizard/AddProjectWizard.utils';
import { getSpecializationByAnnotationType } from 'appConstants';

interface Props {
  projectId: number;
  projectData: Project;
}

export default function InviteHelpersTab({ projectId, projectData }: Props) {
  const { data: responseHelpers } = useGetListOfHelpersQuery({
    fetchPolicy: 'network-only',
    variables: { projectId }
  });
  const annotationSpecialization = parseSubType(
    projectData?.projectSettings?.subType ??
      projectData?.projectSettings?.annotationType
  );
  const isOnlyRequestCustomHelpers = checkIsOnlyRequestCustomHelpers({
    type: projectData?.type as unknown as ProjectType,
    platform: projectData?.platform,
    projectSpecialization: getSpecializationByAnnotationType(
      annotationSpecialization
    )
  });
  const isEditable = !isOnlyRequestCustomHelpers;

  const { isPremium } = useGetUserSessionData();

  const helpers: InvitedHelper[] = useMemo(
    () =>
      [
        ...(responseHelpers?.getListOfHelpers?.invited ?? []),
        ...(responseHelpers?.getListOfHelpers?.requested ?? []),
        ...(responseHelpers?.getListOfHelpers?.pending?.map((helpers) => ({
          ...helpers,
          isPending: true
        })) ?? [])
      ] as InvitedHelper[],
    [responseHelpers?.getListOfHelpers]
  );

  const [sendRequestToHelper] = useSendRequestToHelperMutation();
  const [requestHelpers] = useRequestHelpersMutation();

  const handleSendMessageToRequestHelpers = async (
    customMessageInviteHelpersRequest: string
  ) => {
    await requestHelpers({
      variables: {
        comment: customMessageInviteHelpersRequest,
        projectId: projectId
      }
    });
  };

  const handleSendRequestToHelper = async (
    email: string,
    firstName: string,
    lastName: string
  ) => {
    await sendRequestToHelper({
      variables: {
        email: email,
        projectId,
        firstName: firstName,
        lastName: lastName
      }
    });
  };

  return (
    <InviteHelpersContextProvider
      isPremium={isPremium}
      helpers={helpers}
      initiallySelectedHelpers={helpers}
      allowAddTeammates={false}
      allowRemoveHelpers={false}
      projectType={projectData?.type}
      onSendInvite={handleSendRequestToHelper}
      onSendCustomRequest={handleSendMessageToRequestHelpers}
    >
      <InviteHelpers withDescription={false} isEditable={isEditable} />
    </InviteHelpersContextProvider>
  );
}
