import React from 'react';
import { ProjectStatus, TaskType } from 'generated/graphql';
import StyledTable from 'components/Table';
import columnsHelper from './ProjectsHelper/columns.helper';
import columnsAdmin from 'containers/Projects/ProjectsAdmin/ActiveProjects/columns.admin';
import columnsClient from './ProjectsClient/columns.client';
import { MappedListProjects } from 'appTypes';

interface ProjectsTableProps {
  columns: ReturnType<
    typeof columnsHelper | typeof columnsAdmin | typeof columnsClient
  >;
  listProjects: MappedListProjects[];
  page?: number;
  pagesCount?: number;
  setPage(page: number): void;
  projectListLoading: boolean;
}

const ProjectsTable = ({
  columns,
  listProjects,
  page,
  pagesCount,
  setPage,
  projectListLoading
}: ProjectsTableProps) => (
  <StyledTable
    rowClassName={({ status, type }) =>
      status === ProjectStatus.Archived || type === TaskType.Digitization
        ? 'archived'
        : ''
    }
    setCurTablePage={setPage}
    page={page}
    pagesCount={pagesCount}
    columns={columns}
    dataSource={listProjects}
    loading={projectListLoading}
  />
);

export default ProjectsTable;
