import React, { useCallback, useState } from 'react';
import { Row, ButtonPrimary, ButtonSecondary } from 'components/_main';
import SelectClients from 'components/SelectClients';
import SelectProjects from 'components/SelectProjects';
import {
  useReportsGetHelpersStatsByPrjIdCsvLazyQuery,
  useReportsGetHelpersStatsByPrjIdLazyQuery
} from 'generated/graphql';
import { downloadContent } from 'utils';
import { Root, ColHeaderStyled } from './HelperStatisticsReport.styled';
import Table from 'components/Table';
import columns from './HelperStatisticsReport.columns';
import { ClearAllText } from '../ReportsPage.styled';

const HelperStatisticsReport = () => {
  const [curClient, setCurClient] = useState<string>();
  const [curPrjId, setCurPrjId] = useState<string>();
  const [tableData, setTableData] = useState<(object | null)[]>();

  const [onGetRepResults, { loading }] =
    useReportsGetHelpersStatsByPrjIdLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: (data1) => {
        const dataJson = data1?.helpersStatsByProject?.JSON || [];
        const reversed = [...dataJson].reverse();
        setTableData(
          Array.isArray(reversed) && reversed.length > 0 ? reversed : []
        );
      }
    });

  const [onGetRepResultsCsv, { loading: loadingCsv, data: dataCsv }] =
    useReportsGetHelpersStatsByPrjIdCsvLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: () => {}
    });

  const handleOnGenerate = useCallback(() => {
    if (curPrjId) {
      onGetRepResults({
        variables: {
          projectId: parseInt(curPrjId, 10)
        }
      });

      onGetRepResultsCsv({
        variables: {
          projectId: parseInt(curPrjId, 10)
        }
      });
    }
  }, [curPrjId, onGetRepResults, onGetRepResultsCsv]);

  const handleOnFormReset = useCallback(() => {
    setCurPrjId(undefined);
    setCurClient(undefined);
    setTableData(undefined);
  }, []);

  return (
    <Root>
      <Row wide>
        <ColHeaderStyled>
          <SelectClients
            value={curClient}
            setValue={setCurClient}
            isRequired={true}
            onSelect={setCurClient}
          />
          <SelectProjects
            showArchived={true}
            isRequired={true}
            value={curPrjId}
            onSelect={setCurPrjId}
            clientId={curClient}
          />
          <ClearAllText onClick={handleOnFormReset}>Clear all</ClearAllText>
          <span> </span>
          <ButtonPrimary
            disabled={!curPrjId || loading}
            onClick={handleOnGenerate}
          >
            Search
          </ButtonPrimary>
          <ButtonSecondary
            disabled={
              loading || loadingCsv || !tableData || tableData?.length === 0
            }
            onClick={() => {
              const dataCsvParsed = dataCsv?.helpersStatsByProject?.CSV;
              if (dataCsvParsed) {
                downloadContent(
                  `helpers-statistic-report.csv`,
                  dataCsvParsed,
                  'data:text/csv;charset=utf-8,'
                );
              }
            }}
          >
            Export
          </ButtonSecondary>
        </ColHeaderStyled>
      </Row>
      <Row wide>
        <Table
          loading={loading}
          columns={columns}
          dataSource={(tableData as object[]) ?? []}
        />
      </Row>
    </Root>
  );
};

export default HelperStatisticsReport;
