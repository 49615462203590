import { Row, Text } from 'components/_main';
import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import useLabelColor from 'containers/AnnotationSystem/hooks/useLabelColor';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import React, { useMemo } from 'react';
import ExpandBlock from 'containers/TasksRouter/AnnotationView/components/AnnotationSidebar/ExpandBlock';
import Label from './Label';

interface WithColorPros {
  item: AnnotationUnitProps;
}

export default function AnnotationsPolygonLabels() {
  const { annotationUnits } = useAnnotationSystemState();
  const title = 'Annotations';
  const isExpandable = annotationUnits.length > 0;

  const labels = useMemo(() => {
    return annotationUnits.map((item) => (
      <PolygonLabel key={item.unitId} item={item} />
    ));
  }, [annotationUnits]);

  return (
    <ExpandBlock
      title={
        <Text variant="ui-small-bold" upper>
          {title}
        </Text>
      }
      expandable={isExpandable}
    >
      {Boolean(annotationUnits.length) ? (
        labels
      ) : (
        <Text variant="ui-1-regular" colorVariant="light">
          No annotations added yet.
        </Text>
      )}
    </ExpandBlock>
  );
}

function PolygonLabel({ item }: WithColorPros) {
  const color = useLabelColor(item.label);
  const { isActive } = useStatus(item);

  if (item?.polygonPoints?.length! <= 2) return null;

  return (
    <Label isActive={isActive} key={item.unitId} item={item} color={color} />
  );
}
