import { CARD_COLORS } from '../Microtasking.constants';
import _ from 'lodash';

const getCardColors = (count: number): string[] => {
  const shuffledColors = _.shuffle(CARD_COLORS);
  const colors = [];
  for (let i = 0; i < count; i++) {
    const color = shuffledColors.pop();
    if (!color) {
      break;
    }
    colors.push(color);
  }
  return colors;
};

export default getCardColors;
