import { Checkbox } from 'antd';
import styled, { css } from 'styled-components/macro';

const AntdCheckbox = styled(Checkbox)(() => {
  return css`
    display: flex;
    align-items: flex-start;

    .ant-checkbox {
      height: 16px;
      width: 16px;
      transform-origin: 0 50%;
      transform: scale(1.2);
      padding-top: 2px;
    }

    .ant-checkbox-inner {
      border-radius: 5px;
      border-color: ${({ theme }) => theme.colors.lighter};
    }

    .ant-checkbox-checked {
      &:after {
        display: none;
      }
      .ant-checkbox-inner {
        border-color: ${({ theme }) => theme.colors.primary};
        background-color: ${({ theme }) => theme.colors.primary};

        &:after {
          width: 4px;
          border: 1px solid ${({ theme }) => theme.colors.bg};
          border-top: 0;
          border-left: 0;
          top: 41%;
          left: 26.5%;
        }
      }
    }

    .ant-checkbox-disabled:not(.ant-checkbox-checked) .ant-checkbox-inner {
      background-color: transparent;
    }

    &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  `;
});

export default AntdCheckbox;
