export function arrayModifyByIndex<T = Record<any, any>>(
  array: Array<T>,
  elementIndex: number,
  element: Partial<T>
): Array<T> {
  return [
    ...array.slice(0, elementIndex),
    { ...array[elementIndex], ...element },
    ...array.slice(elementIndex + 1)
  ];
}

export default arrayModifyByIndex;
