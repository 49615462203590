export enum ProjectTabType {
  Admin = 'Admin',
  General = 'General',
  InviteHelpers = 'Invite Helpers',
  Results = 'Results',
  Client = 'Client',
  Helper = 'Helper',
  Assigned = 'Assigned',
  Upload = 'Upload project',
  Details = 'Project Details',
  Preview = 'Project Preview Results'
}
