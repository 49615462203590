import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useFormikContext } from 'formik';
import { Dropdown, Menu } from 'antd';
import { Col, Row, Text } from 'components/_main';
import columnsFn from './columns';
import { Project } from 'generated/graphql';
import Search from 'components/Search';
import useAssignedHelpersList from 'hooks/useAssignedHelpersList';
import UnassignedAuditorModal from './UnassignedAuditorModal';
import useOpen from 'hooks/useOpen';
import { ReactComponent as ArrowDownIcon } from './arrowDown.svg';
import { CheckCellRender, HelperNameCell } from './AssignedHelpres.ui';
import { useTheme } from 'styled-components/macro';

type TTabAssigned = {
  projectData: Project;
};

function AssignedHelpers({ projectData }: PropsWithChildren<TTabAssigned>) {
  const { values, setValues, setFieldValue } = useFormikContext<any>();
  const [sortMode, setSortMode] = useState('all');
  const theme = useTheme();

  const {
    isOpen: isProjectUnassignedAuditorOpen,
    onOpen: onProjectUnassignedAuditorOpen,
    onClose: onProjectUnassignedAuditorClose
  } = useOpen();

  const search = values?.search ?? '';
  const selectedHelperIds = useMemo(
    () => values?.selectedHelperIds ?? [],
    [values?.selectedHelperIds]
  );
  const selectedAuditorIds = useMemo(
    () => values?.selectedAuditorIds ?? [],
    [values?.selectedAuditorIds]
  );

  const { helpersAllActive, loading } = useAssignedHelpersList(
    projectData.id,
    'cache-only'
  );

  const setSearch = (
    callbackOrString: ((search: string) => string) | string
  ) => {
    let newSearch;
    if (typeof callbackOrString === 'function') {
      newSearch = callbackOrString(search);
    } else {
      newSearch = callbackOrString;
    }

    setValues({ ...values, search: newSearch.trim() || '' });
  };

  const handleOnCheck =
    (onCheckFn: (newListIds: number[]) => void, listIds: number[]) =>
    (checked: boolean, id: number) => {
      onCheckFn(
        checked
          ? [...listIds, id]
          : [...listIds.filter((selId) => id !== selId)]
      );
    };

  const helpersListArr = useMemo(() => {
    let newHelperList = helpersAllActive?.helpers?.helpers || [];

    if (search) {
      const regExp = new RegExp(search, 'i');
      newHelperList = newHelperList.filter(
        (helper) =>
          helper &&
          (helper.firstName.match(regExp) || helper.lastName.match(regExp))
      );
    }

    let newFinalHelpersListArr = [...newHelperList];

    if (sortMode === 'checked') {
      newFinalHelpersListArr = newHelperList.filter(
        (helper) =>
          helper &&
          (selectedHelperIds.includes(parseInt(helper.id, 10)) ||
            selectedAuditorIds.includes(parseInt(helper.id, 10)))
      );
    }

    return newFinalHelpersListArr?.reverse();
  }, [
    helpersAllActive,
    search,
    sortMode,
    selectedAuditorIds,
    selectedHelperIds
  ]);

  const handleSelAuditor = handleOnCheck((newListIds) => {
    if (!newListIds.length) {
      onProjectUnassignedAuditorOpen();
      return;
    }
    setValues({
      ...values,
      selectedAuditorIds: newListIds ? [...newListIds] : []
    });
  }, selectedAuditorIds);

  const handleSelHelper = handleOnCheck((newListIds) => {
    setValues({
      ...values,
      selectedHelperIds: newListIds ? [...newListIds] : []
    });
  }, selectedHelperIds);

  const columns = useMemo(() => {
    return columnsFn({
      selectedAuditorIds,
      selectedHelperIds,
      onSelAuditor: handleSelAuditor,
      onSelHelper: handleSelHelper
    });
  }, [
    selectedAuditorIds,
    selectedHelperIds,
    handleSelAuditor,
    handleSelHelper
  ]);

  const handleUnassignedAuditorSubmit = useCallback(() => {
    setFieldValue('selectedAuditorIds', []);
    onProjectUnassignedAuditorClose();
  }, [setFieldValue, onProjectUnassignedAuditorClose]);

  const handleChangeSort = useCallback(
    (sortMode: string) => {
      setSortMode(sortMode);
      setValues({ ...values, search: '' });
    },
    [values, setSortMode, setValues]
  );

  const menu = useMemo(
    () => (
      <Menu
        items={[
          {
            key: 'all',
            onClick: () => handleChangeSort('all'),
            label: 'all'
          },
          {
            key: 'checked',
            onClick: () => handleChangeSort('checked'),
            label: 'checked'
          }
        ]}
      />
    ),
    [handleChangeSort]
  );

  return (
    <Row
      height="100%"
      alignItems="flex-start"
      overflow="hidden"
      gridAutoRows="max-content auto"
    >
      <Col
        px="4px"
        width="100%"
        displayFlex
        gridTemplateColumns="325px max-content"
        alignItems="center"
        justifyContent="space-between"
      >
        <Search
          boxProps={{ padding: 0 }}
          value={search}
          allowClear
          noBorder
          onSearch={(input) => {
            setSearch(input);
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Col>
          show:
          <Dropdown placement="bottomRight" overlay={menu} trigger={['click']}>
            <Col gap="4px" alignItems="center">
              <Text variant="ui-1-bold" colorVariant="primary">
                {sortMode}
              </Text>
              <ArrowDownIcon />
            </Col>
          </Dropdown>
        </Col>
      </Col>
      <Row gap="4px" overflow="auto" height="100%" p="4px">
        {helpersListArr.map((helper) => {
          if (!helper) return null;

          return (
            <Col
              alignItems="center"
              px="20px"
              width="100%"
              key={helper.email}
              height="60px"
              gridAutoColumns="1fr max-content max-content"
              boxShadow={`0px 4px 10px ${theme.colors.shadow}`}
              borderRadius="6px"
            >
              <HelperNameCell {...helper} />
              <CheckCellRender
                key={'helper'}
                type={'helper'}
                id={parseInt(helper.id)}
                listIds={[...selectedHelperIds]}
                onSelect={handleSelHelper}
              />
              <CheckCellRender
                key={'auditor'}
                type={'auditor'}
                id={parseInt(helper.id)}
                listIds={[...selectedAuditorIds]}
                onSelect={handleSelAuditor}
              />
            </Col>
          );
        })}
      </Row>
      <UnassignedAuditorModal
        isOpen={isProjectUnassignedAuditorOpen}
        onClose={onProjectUnassignedAuditorClose}
        onSubmit={handleUnassignedAuditorSubmit}
      />
    </Row>
  );
}

export default AssignedHelpers;
