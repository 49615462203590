import React from 'react';
import { useTheme } from 'styled-components/macro';
import { certificationTypes } from 'containers/LearningMaterials/LearningMaterial.constants';
import { Box, Col, Row, Text } from 'components/_main';

function CertificationTabContent() {
  const theme = useTheme();

  return (
    <Row padding="0 30px" opacity="0.5">
      {certificationTypes.map((item) => (
        <Col alignItems="center">
          <Box
            width="6px"
            height="6px"
            borderRadius="50%"
            backgroundColor={theme.colors.primary}
          />
          <Text variant="ui-1-bold" colorVariant="primary">
            Certification:
          </Text>
          <Text>{item}</Text>
        </Col>
      ))}
    </Row>
  );
}

export default CertificationTabContent;
