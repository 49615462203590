import { AnnotationSystemEventsEnum } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import { preventResizeInverse } from 'containers/AnnotationSystem/utils';
import useMousePositionRef from 'hooks/useMousePositionRef';
import { useCallback, useEffect } from 'react';

interface Props
  extends Pick<
    ReturnType<typeof useMousePositionRef>,
    'stateRef' | 'onUpdate'
  > {
  svgRef: React.MutableRefObject<SVGSVGElement | null> | null;
}

export default function useMouseMove({ svgRef, stateRef, onUpdate }: Props) {
  const { curEvent, svgLayerRef } = useAnnotationSystemState();

  const handleMouseMoveUpdate = useCallback(() => {
    const svgElement = svgRef?.current;

    if (!svgElement) return;

    const { x, w, h, y } = preventResizeInverse({
      initX: stateRef.current.initX,
      initY: stateRef.current.initY,
      dX: stateRef.current.dX,
      dY: stateRef.current.dY
    });

    svgElement?.setAttribute('x', x.toString());
    svgElement?.setAttribute('y', y.toString());
    svgElement?.setAttribute('height', h.toString());
    svgElement?.setAttribute('width', w.toString());
  }, [stateRef, svgRef]);

  const handleSvgLayerMouseMove = useCallback(
    (event: MouseEvent) => {
      switch (curEvent) {
        case AnnotationSystemEventsEnum.CREATE_MODE: {
          onUpdate({
            event,
            onUpdate: handleMouseMoveUpdate
          });
          return;
        }
        case AnnotationSystemEventsEnum.CREATING_SIZE: {
          onUpdate({
            event,
            onUpdate: handleMouseMoveUpdate
          });
          return;
        }
      }
    },
    [curEvent, handleMouseMoveUpdate, onUpdate]
  );

  useEffect(() => {
    if (!svgLayerRef) return;

    // @ts-ignore
    svgLayerRef?.addEventListener('mousemove', handleSvgLayerMouseMove);
    return () => {
      // @ts-ignore
      svgLayerRef?.removeEventListener('mousemove', handleSvgLayerMouseMove);
    };
  }, [curEvent, handleSvgLayerMouseMove, svgLayerRef]);
}
