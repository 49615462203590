import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  compose,
  typography
} from 'styled-system';
import numberToPx from '../../../utils/numberToPx';
import { NORMAL_MAX_WIDTH, SIDES_SPACING } from './constants';
import {
  styleAlternate,
  styleBig,
  styleButtonBase,
  styleCapitalize,
  styleGhost,
  styleIcon,
  styleMini,
  styleNormal,
  styleOutlined,
  stylePrimary,
  styleRound,
  styleSecondary,
  styleSmall,
  styleSquare,
  styleSuccess,
  styleWarning
} from './styles';
import { ButtonProps, Size, Variant } from './types';

export const IconContent = styled('span')(
  () =>
    css`
      display: inline-flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      span {
        width: 100%;
        height: 100%;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    `
);

export const TextContent = styled('span')(
  () => css`
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: ${numberToPx(NORMAL_MAX_WIDTH - SIDES_SPACING * 2)};
    display: block;
    white-space: nowrap;
  `
);

export const IconSuffixContent = styled('span')(
  () => css`
    margin-left: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  `
);

export const IconPrefixContent = styled('span')(
  () => css`
    margin-right: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  `
);

export const Root = styled.button.withConfig({
  shouldForwardProp: (prop, defValidFunc) => defValidFunc(prop)
})<PropsWithChildren<ButtonProps>>(
  styleButtonBase,
  ({ variant }) => {
    switch (variant) {
      case Variant.primary:
        return stylePrimary;
      case Variant.secondary:
        return styleSecondary;
      case Variant.warning:
        return styleWarning;
      case Variant.success:
        return styleSuccess;
      case Variant.alternate:
        return styleAlternate;
      case Variant.ghost:
        return styleGhost;
      case Variant.outlined:
        return styleOutlined;
      default:
        return stylePrimary;
    }
  },
  ({ size }) => {
    switch (size) {
      case Size.mini:
        return styleMini;
      case Size.small:
        return styleSmall;
      case Size.normal:
        return styleNormal;
      case Size.big:
      default:
        return styleBig;
    }
  },
  ({ big }) => big && styleBig,
  ({ mini }) => mini && styleMini,
  ({ small }) => small && styleSmall,
  ({ normal }) => normal && styleNormal,
  ({ noPadding }) =>
    noPadding &&
    css`
      max-width: max-content;
      padding: 0;
    `,
  ({ round }) => round && styleRound,
  ({ iconStyle }) => iconStyle && styleIcon,
  ({ square }) => square && styleSquare,
  ({ capitalize }) => capitalize && styleCapitalize,
  ({ noHover }) =>
    noHover &&
    css`
      &:hover {
        background-color: transparent;
      }
    `,
  compose(
    background,
    border,
    color,
    flexbox,
    grid,
    layout,
    space,
    position,
    shadow,
    typography
  )
);
