import React from 'react';
import { Project, TaskType } from 'generated/graphql';
import { upper } from 'utils/_stringUtilsLegacy';
import projectProgressColumn from 'components/Table/_columnRender/projectProgressColumn';
import projectStatusColumn from 'components/Table/_columnRender/projectStatusColumn';
import RowActionsHelper from 'containers/Projects/ProjectsHelper/RowActionsHelper';
import specializationIconColumn from 'components/Table/_columnRender/specializationIconColumn';
import { ColumnsHelperProps } from 'containers/Projects/columns.types';

export const columns = ({
  onStartTasking,
  onStartAudit,
  isEnabled
}: ColumnsHelperProps) => [
  {
    title: upper('project'),
    width: 1500,
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: upper('specialization'),
    width: 200,
    dataIndex: 'mappedType',
    key: 'type'
  },
  {
    title: upper('type'),
    width: 80,
    key: 'specType',
    render: specializationIconColumn
  },
  {
    title: upper('created'),
    width: 200,
    dataIndex: 'formattedCreatedAt',
    key: 'createdAt'
  },
  {
    title: upper('Status'),
    render: projectStatusColumn,
    width: 180
  },
  {
    title: upper('Progress'),
    render: projectProgressColumn({
      inPercents: false,
      showProgressBar: false,
      showDetailsWithTooltip: true
    }),
    width: 220,
    align: 'center' as const
  },
  {
    render: (data: Project) => {
      if (data.type === TaskType.Digitization || data.type === TaskType.Nlp)
        return null;
      return (
        <RowActionsHelper
          data={data}
          isEnabled={isEnabled}
          onStartTasking={onStartTasking}
          onStartAudit={onStartAudit}
        />
      );
    },
    width: 50
  }
];

export default columns;
