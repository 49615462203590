import React, { ReactNode } from 'react';
import type {
  DefaultOptionType,
  SelectProps as SelectPropsBase
} from 'antd/es/select';
import {
  Project,
  LoginResponseClient,
  LoginResponseAdmin,
  LoginResponseHelper,
  Client,
  Helper,
  Admin,
  MLabels,
  Platform
} from 'generated/graphql';
import { FormItemProps } from 'antd/es/form';
import { DefaultTheme } from 'styled-components/macro';
import { DocumentNode } from 'graphql';
import { FieldHelperProps } from 'formik';
import { AnnotationUnitType } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { microtaskingStructure } from 'appConstants';

/** TODO: Move here all types that used more than in one folder or cross-folders, without parent-child dependency. Same as one that in GQL/types, but for App, like ThemeType, ColorsType & so on */

export type Theme = DefaultTheme;

export interface ColorsMinRequired {
  primary: string;
  light: string;
  lighter: string;
  lightest: string;
  primaryHover: string;
  secondary: string;
  secondaryHover: string;
  warning: string;
  warningHover: string;
  alternate: string;
  alternateHover: string;
  bg: string;
  fg: string;
  yellow: string;
  orange: string;
  orangeLight: string;
  purple: string;
  purpleLight: string;
  blue: string;
  green: string;
  greenLight: string;
  greenLighter: string;
  red: string;
  [key: string]: string;
}

export enum Roles {
  CLIENT = 'client',
  HELPER = 'helper',
  ADMIN = 'admin'
}

export type NewPrjData = {
  mappedType?: string;
  formattedCreatedAt?: string;
  formattedUpdatedAt?: string;
  formattedClient?: string;
  formattedClientName?: string;
  key?: string;
  platform?: Platform;
};

export type TAntdFormItem = {
  label: string;
} & FormItemProps;

export type ProjectDetails = Project;

export type MappedListProjects = Project & NewPrjData;

export type DashboardCtxData = {
  appBarTitle: string;
  isFullScreen: boolean;
};

export type DashboardCtx = {
  data: DashboardCtxData;
  setData: React.Dispatch<React.SetStateAction<DashboardCtxData>>;
};

export enum ProjectType {
  IMAGE_ANNOTATION = 'IMAGE_ANNOTATION',
  VIDEO_ANNOTATION = 'VIDEO_ANNOTATION',
  PHONE_CALL = 'PHONE_CALL',
  CONTENT_MODERATION = 'CONTENT_MODERATION',
  DIGITIZATION = 'DIGITIZATION',
  CATEGORIZATION = 'CATEGORIZATION',
  DATA_SET_COLLECTION = 'DATA_SET_COLLECTION',
  NLP = 'NLP'
}

export enum ProjectSpecialization {
  BOUNDING_BOX = 'BOUNDING_BOX',
  ROTATED_BOUNDING_BOX = 'ROTATED_BOUNDING_BOX',
  POLYGONS = 'POLYGONS',
  HUMAN_POSES = 'HUMAN_POSES',
  SEMANTIC_SEGMENTS = 'SEMANTIC_SEGMENTS',
  POLYLINE = 'POLYLINE',
  CUBOIDS_3D = 'CUBOIDS_3D',
  SENTIMENT_ANALYSIS = 'SENTIMENT_ANALYSIS',
  CONTENT_RELEVANCE_SCORE = 'CONTENT_RELEVANCE_SCORE',
  PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES',
  LANGUAGE_DETECTION = 'LANGUAGE_DETECTION'
}

export enum ProjectTypeLabel {
  VIDEO_ANNOTATION = 'Video Annotation',
  IMAGE_ANNOTATION = 'Image Annotation',
  CONTENT_MODERATION = 'Content Moderation',
  DIGITIZATION = 'Digitization',
  CATEGORIZATION = 'Categorization',
  PHONE_CALL = 'Phone Call',
  DATA_SET_COLLECTION = 'Dataset Collection',
  NLP = 'NLP'
}

export enum VideoAnnotationDatasetProcessingType {
  ENTIRE_VIDEO = 'ENTIRE_VIDEO',
  SPLIT_BY_FRAMES = 'SPLIT_BY_FRAMES'
}

export enum VideoAnnotationDatasetSplitByType {
  SPLIT_BY_TIME_RANGE = 'SPLIT_BY_TIME_RANGE',
  SPLIT_BY_EACH_FRAME = 'SPLIT_BY_EACH_FRAME'
}

export enum ImageAnnotationDatasetProcessingType {
  SINGLE_ZIP_ARCHIVE = 'SINGLE_ZIP_ARCHIVE',
  MULTIPLE_IMAGES = 'MULTIPLE_IMAGES'
}

export type AnnotationDatasetProcessingType =
  | ImageAnnotationDatasetProcessingType
  | VideoAnnotationDatasetProcessingType;

export type AnnotationSpecificationType =
  | 'box'
  | 'dot'
  | 'polygon'
  | 'contentModeration'
  | 'rotatedBoxes'
  | 'semantics'
  | 'polyLines'
  | 'dataset'
  | 'cuboids3D'
  | 'sentimentAnalysis'
  | 'productCategories'
  | 'languageDetection'
  | 'contentRelevanceScore';

export interface AuthFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName?: string;
}

export type LoginHelper = LoginResponseHelper;

export interface SignInValues {
  email: string;
  password: string;
}

export type LoginResponse =
  | LoginResponseClient
  | LoginResponseAdmin
  | LoginResponseHelper;

export type LoginClient = LoginResponseClient;

export type LoginAdmin = LoginResponseAdmin;

export type TRefetchQueries = Array<{
  variables: any;
  query: DocumentNode;
}>;

export type CurrentUser = {
  isLoggedIn?: boolean;
  companyName?: string | null;
  enabled?: boolean;
  activated?: boolean;
  isPremium?: boolean;
} & Omit<Client | Helper | Admin, '__typename'>;

export type CurrentUserWithTypeName = CurrentUser &
  Pick<Client | Helper | Admin, '__typename'>;

export interface AppState {
  currentUser: CurrentUser;
}

export interface AppStateContextReturn extends AppState {
  updateCurrentUser: (
    newUser: Partial<CurrentUser | null> | null,
    isLoggedIn?: boolean
  ) => void;
  signIn: (
    newUser?: Partial<CurrentUser>,
    redirectToStartPage?: boolean
  ) => void;
  signOut: () => Promise<void>;
}

export type InvitedHelper = Pick<Helper, 'email' | 'firstName' | 'lastName'> & {
  isPending?: boolean;
};

export type MultiLabels = MLabels[];

export type SelectOption<T extends string | object = string> = {
  label: ReactNode;
  value: T;
  disabled?: boolean;
};

export type SelectPropsReduced<T extends string | object = string> = Omit<
  SelectPropsBase<T>,
  | 'onSelect'
  | 'options'
  | 'controlled'
  | 'value'
  | 'selectedValue'
  | 'onDeselect'
  | 'onChange'
  | 'defaultValue'
>;

interface SelectPropsOverride<T extends string | object = string> {
  onSelect?: (
    value: T,
    option: DefaultOptionType | DefaultOptionType[] | null,
    helper?: FieldHelperProps<T>
  ) => void;
  onChange?: (
    value: T,
    option: DefaultOptionType | DefaultOptionType[]
  ) => void;
  onDeselect?: (value: T, option: DefaultOptionType) => void;
  controlled?: boolean;
  options?: SelectOption<T>[];
  value?: T;
  selectedValue?: T;
  defaultValue?: T;
}
export interface SelectProps<T extends object | string = string>
  extends SelectPropsReduced<T>,
    SelectPropsOverride<T> {
  small?: boolean;
}

export type UnreviewProjectStatusType = 'review' | 'publish';

export type TaskingType = 'tasking' | 'auditing' | 'practice';

export enum ProcessingType {
  FRAMES = 'frames',
  FILMS = 'films',
  NONE = ''
}

export interface MicrotaskingSpecializationsProps {
  id: number;
  specType: ProjectSpecialization;
  color?: string;
}

export interface MicrotaskingStructureProps {
  id: string;
  type: ProjectType;
  specializations: MicrotaskingSpecializationsProps[];
}
