import { useCallback, useEffect } from 'react';

const SITE_MIN_WIDTH = 1200;

export default function useHtmlViewportZoom() {
  const handleViewportSize = useCallback(() => {
    const viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) return;

    if (window.screen.width > SITE_MIN_WIDTH) {
      viewport.setAttribute('content', 'initial-scale=1');
      return;
    }

    const scale = window.screen.width / SITE_MIN_WIDTH;

    document
      .querySelector('meta[name="viewport"]')
      ?.setAttribute(
        'content',
        'width=' + SITE_MIN_WIDTH + ', initial-scale=' + scale + ''
      );
  }, []);

  useEffect(() => {
    handleViewportSize();
    window.addEventListener('resize', handleViewportSize);
    return () => {
      window.removeEventListener('resize', handleViewportSize);
    };
  }, [handleViewportSize]);
}
