import { TaskAction, TaskType } from 'generated/graphql';
import { useAnnotationSystemState } from '../context';

interface CallbackProps {
  taskAction?: TaskAction;
}

export default function useGetResults() {
  const { annotationUnits } = useAnnotationSystemState();

  return (props?: CallbackProps) => {
    const { taskAction } = props ?? {};

    if (
      taskAction === TaskAction.Reject ||
      taskAction === TaskAction.RejectAsAuditor
    ) {
      return { data: [] };
    }

    return {
      data: annotationUnits.map((unit, index) => ({
        ...unit,
        id: Date.now() + index
      }))
    };
  };
}
