import { useAppContext } from 'containers/App';
import { useMemo } from 'react';
import { isAdmin } from 'utils';

function useGetUserSessionData() {
  const { currentUser } = useAppContext();

  const activated = isAdmin() ? true : currentUser.activated;

  return useMemo(
    () => ({
      ...currentUser,
      activated
    }),
    [currentUser, activated]
  );
}

export default useGetUserSessionData;
