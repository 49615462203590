import React from 'react';
import { Project } from 'generated/graphql';
import ProjectDetailsCardModalContent from './ProjectDetailsCard.Modal.Content';
import { useCtx } from './ProjectDetailsCard.Context';
import { ClientCardCtxProvider } from '../ClientCard/ctx';
import ClientCardModalContent from '../ClientCard/ClientCard.Modal.Content';

const ProjectDetailsCardModalWrapper = ({
  onCancel,
  projectData,
  loading
}: {
  onCancel?: () => void;
  projectData: Project;
  loading?: boolean;
}) => {
  const { isFromClientCard, clientModal, setClientModal } = useCtx();

  const handleOnClientModalClose = () => {
    setClientModal({
      isOpen: false
    });
  };

  if (!isFromClientCard && clientModal.isOpen && clientModal.data) {
    return (
      <ClientCardCtxProvider
        data={clientModal.data}
        isOpen={clientModal.isOpen}
        onClose={handleOnClientModalClose}
        isFromProjectDetailsCard
      >
        <ClientCardModalContent />
      </ClientCardCtxProvider>
    );
  }

  return (
    <ProjectDetailsCardModalContent
      loading={loading}
      onCancel={onCancel}
      projectData={projectData}
    />
  );
};

export default ProjectDetailsCardModalWrapper;
