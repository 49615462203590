import { Z_INDEXES } from 'appConstants';
import { Box, Col, Text } from 'components/_main';
import useCurSelUnitProps from 'containers/AnnotationSystem/hooks/useCurSelUnitProps';
import useIndexColor from 'containers/AnnotationSystem/hooks/useIndexColor';
import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTheme } from 'styled-components/macro';
import { consoleError } from 'utils';

interface Props {
  svgRef: React.MutableRefObject<SVGElement | null>;
  hintDescription?: string;
}

export default function Hint({ hintDescription, svgRef }: Props) {
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const placeholderElement = document.getElementById('AnnotationMainHint');

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };

  useLayoutEffect(() => {
    const element = svgRef?.current;

    if (!element) {
      return;
    }

    element.addEventListener('mouseenter', handleShow);
    element.addEventListener('mouseleave', handleHide);

    return () => {
      element.removeEventListener('mouseenter', handleShow);
      element.removeEventListener('mouseleave', handleHide);
    };
  });

  const curSelUnitProps = useCurSelUnitProps();
  const color = useIndexColor(curSelUnitProps?.groupIndex);

  return (
    <>
      {show &&
        placeholderElement &&
        ReactDOM.createPortal(
          <Box
            position="absolute"
            top="20px"
            right="20px"
            borderRadius="35px"
            padding="4px 10px"
            backgroundColor={`${theme.colors.bg}80`}
            zIndex={Z_INDEXES.dropdown}
          >
            <Col flexCenter gridGap="6px">
              <Box
                displayFlex
                flexCenter
                width="12px"
                height="12px"
                borderRadius="100px"
                backgroundColor={theme.colors.bg}
              >
                <Box
                  width="3px"
                  height="3px"
                  borderRadius="100px"
                  backgroundColor={color}
                />
              </Box>
              <Text variant="ui-small-regular">{hintDescription}</Text>
            </Col>
          </Box>,
          placeholderElement
        )}
    </>
  );
}
