import { isAdmin } from 'utils';
import { Project } from 'generated/graphql';
import { ProjectStatus, TaskType } from 'generated/graphql';

const useGetIsStatusEditable = (projectData: Project, isEditable?: boolean) => {
  if (
    isAdmin() &&
    projectData.status === ProjectStatus.Active &&
    projectData.type === TaskType.Nlp
  )
    return true;

  return isEditable;
};

export default useGetIsStatusEditable;
