import AntdSlider from 'components/AntdSlider';
import { Box, Col, ColProps } from 'components/_main';
import React, { PropsWithChildren } from 'react';
import { css, useTheme } from 'styled-components/macro';
import {
  AnnotationToolbarBoxProps,
  AnnotationToolbarIconProps
} from '../../AnnotationView.types';
import { SliderSingleProps, Tooltip as TooltipBase } from 'antd';

interface Props {
  disabled?: boolean;
  onClick?: (e?: MouseEvent) => void;
  active?: boolean;
}

export const Slider = (props: SliderSingleProps) => (
  <AntdSlider
    min={0}
    max={1}
    step={0.1}
    {...props}
    css={css`
      .ant-slider-rail {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.24) 10%,
          #3e3e3e 98.96%
        );
      }

      &:hover .ant-slider-rail {
        background-color: transparent;
      }

      &:hover .ant-slider-track {
        background: transparent;
      }

      & .ant-slider-handle:focus {
        border-color: #3e3e3e;
        box-shadow: none;
      }

      .ant-slider-track {
        background: transparent;
      }
    `}
  />
);

export function ToolbarSection({
  active,
  children,
  ...props
}: AnnotationToolbarBoxProps & ColProps) {
  const theme = useTheme();

  const activeStyle = css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.bg};
    transition: all 300ms;
    span {
      color: ${theme.colors.bg};
    }
    svg path {
      stroke: ${theme.colors.bg};
    }
  `;

  return (
    <Col
      height="50px"
      width="100%"
      gridGap="0px"
      flexCenter
      boxShadow="0px 4px 10px 0px #B4B4B433"
      borderRadius="10px"
      gridAutoColumns="max-content"
      css={active && activeStyle}
      {...props}
    >
      {children}
    </Col>
  );
}

export function ToolbarIcon({
  icon,
  disabled,
  onClick,
  tooltip,
  active,
  children
}: AnnotationToolbarIconProps) {
  const theme = useTheme();

  const Tooltip = tooltip
    ? TooltipBase
    : ({ children }: React.PropsWithChildren<{}>) => <>{children}</>;

  return (
    <Tooltip title={tooltip}>
      <Box
        height="100%"
        css={`
          border-right: 1px solid ${theme.colors.lightest};
          div {
            border-radius: 0;
            color: ${theme.colors.primary};
            &:hover {
              border-radius: 0;
            }
          }
          &:first-child {
            div {
              border-radius: 10px 0 0 10px;
            }
          }
          &:last-child {
            border-right: none;
            div {
              border-radius: 0 10px 10px 0;
            }
          }
          &:only-child {
            div {
              border-radius: 10px;
            }
          }
        `}
      >
        <ToolbarIconButton
          disabled={disabled}
          active={active}
          onClick={onClick}
        >
          {children ?? icon}
        </ToolbarIconButton>
      </Box>
    </Tooltip>
  );
}

export const ToolbarIconButton = ({
  children,
  active,
  disabled,
  onClick
}: PropsWithChildren<Props>) => {
  const theme = useTheme();

  const activeStyle = css`
    background-color: ${theme.colors.primary};
    transition: background-color 200ms ease-in;
    stroke: ${theme.colors.bg};
    svg > path {
      stroke: ${theme.colors.bg};
    }
  `;

  const disabledStyle = css`
    cursor: no-drop;
    background-color: transparent;
    stroke: ${theme.colors.gray4};
    svg > path {
      stroke: ${theme.colors.gray4};
    }
  `;

  return (
    <Box
      displayFlex
      flexCenter
      width="100%"
      height="100%"
      p="12px"
      color={theme.colors.dark}
      fontWeight="600"
      css={css`
        svg > path {
          stroke: ${theme.colors.fg};
        }
        &:hover {
          background-color: ${theme.colors.lighter};
          ${active && activeStyle};
        }
        ${active && activeStyle};
        ${disabled && disabledStyle};
      `}
      onClick={(e) => {
        if (disabled) return;
        e.stopPropagation();
        onClick && onClick();
      }}
    >
      {children}
    </Box>
  );
};
