import {
  AnnotationSystemProps,
  AnnotationUnitProps
} from '../AnnotationSystem.types';
import { Actions, ReducerActionTypes } from './types';

export const withDispatch =
  (dispatch: React.Dispatch<ReducerActionTypes>) =>
  <T extends ReducerActionTypes>(action: Actions<T>) =>
  (payload: T['payload']) =>
    dispatch(action(payload));

export const getCurSelUnitId = (state: AnnotationSystemProps) => {
  const selectedUnit = state.annotationUnits.find(
    (unit) => unit.unitId === state.curSelUnitId
  );
  return selectedUnit ?? null;
};

export const getUnit = (state: AnnotationSystemProps, id: string | null) => {
  const selectedUnit = state.annotationUnits.find((unit) => unit.unitId === id);
  return selectedUnit ?? null;
};

export const removeUnfinishedPolygons = (
  annotationUnits: AnnotationSystemProps['annotationUnits'],
  neededDots = 2
) => {
  return [
    ...annotationUnits.filter(
      (unit) => unit?.polygonPoints?.length! > neededDots
    )
  ];
};

export const roundTimestampsToMs = (timestamp: string | number): number => {
  return parseFloat(parseFloat(timestamp.toString()).toFixed(3));
};

export const roundAnnotationUnitsTimestampsToMs = (
  units: AnnotationUnitProps[]
) => {
  if (!Array.isArray(units)) return [];

  return units?.map((unit) => {
    if (!unit.timelineTransitions) {
      return unit;
    }

    return {
      ...unit,
      timelineTransitions: unit?.timelineTransitions?.map((trans) => ({
        ...trans,
        timestamp: roundTimestampsToMs(trans.timestamp)
      }))
    };
  });
};
