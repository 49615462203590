import { StepsDescriptions } from 'containers/AddProjectWizard/AddProjectWizard.types';

export const WizardStep = ({
  children
}: {
  children: React.ReactElement;
  validationSchema?: any;
  title?: string;
  description?: StepsDescriptions;
}) => children;
