import React, { useRef } from 'react';

import { toast } from 'utils/toast';
import { consoleError } from 'utils';
import { useAppContext } from 'containers/App';
import { MESSAGES, ROUTES } from 'appConstants';
import { useActivateAccountMutation } from 'generated/graphql';
import useRedirect from 'hooks/useRedirect';

interface ConfirmSignupByLinkProps {
  withRedirect?: boolean;
}

export const ConfirmSignupByLink = ({
  withRedirect = true
}: ConfirmSignupByLinkProps) => {
  const redirect = useRedirect();
  const [, , token] = window.location.pathname.split('/') ?? [];
  const { currentUser, signIn, signOut } = useAppContext();
  const isExec = useRef(false);

  const [userConfirmEmail, { loading }] = useActivateAccountMutation({
    onCompleted(result) {
      isExec.current = false;

      const isActivated = result?.ActivateAccountByToken?.activated;

      if (isActivated === undefined || isActivated === null) {
        toast.error(MESSAGES.serverError.common);
        consoleError(
          'Invalid expression result.data?.userConfirmEmail?.activated'
        );
        consoleError({ confirmRegisterByEmailMutationResult: result });
        signOut();
        return;
      }

      toast.success(MESSAGES.authRegActivatedSuccess);

      if (currentUser?.id) {
        signIn({ activated: true }, withRedirect);
        return;
      }

      setTimeout(() => {
        withRedirect && redirect(ROUTES.root);
      }, 1000);
    },
    onError(e) {
      isExec.current = false;
      toast.error(MESSAGES.authRegActivatedFail);
      if ('message' in e) {
        consoleError(e.message);
      }
      setTimeout(() => {
        withRedirect && signOut();
      }, 1000);
    }
  });

  React.useEffect(() => {
    if (token && !isExec.current) {
      isExec.current = true;
      userConfirmEmail({ variables: { token } }).then();
    }
  }, [token, userConfirmEmail]);

  return <div>{loading && 'Loading...'}</div>;
};

export default ConfirmSignupByLink;
