import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import { PolygonPoint } from 'containers/AnnotationSystem/reducer';
import { MouseEvent, useCallback, useRef } from 'react';
import useCanvasCoords from './useCanvasCoords';
import { addAtStrokePolygonPoint } from './utils';

interface Props {
  setPropsWithReducerUpdate: (
    partialProps: Partial<AnnotationUnitProps>
  ) => void;
  unitId: string;
  polygonPoints: PolygonPoint[];
}

export default function usePolyMouseDown({
  unitId,
  polygonPoints,
  setPropsWithReducerUpdate
}: Props) {
  const firstClickId = useRef<any>(0);
  const { onSetCurSelUnitId, onSetCurSelPointId, onSetCurEvent, onSetCurMode } =
    useAnnotationSystemActions();
  const { curSelUnitId, curEvent, curMode } = useAnnotationSystemState();
  const { isCreate, isDotSel } = useStatus({
    unitId
  });

  const canvasCoords = useCanvasCoords();

  const handleMouseDown = useCallback(
    (e: MouseEvent<SVGPolygonElement>) => {
      if (isCreate || curEvent === AnnotationSystemEventsEnum.ZOOM_NAVIGATION)
        return;
      if (!canvasCoords) return;
      if (isDotSel) {
        e.stopPropagation();
        const x = e.clientX - canvasCoords[0];
        const y = e.clientY - canvasCoords[1];
        const newPoints = addAtStrokePolygonPoint(polygonPoints, [x, y]);
        setPropsWithReducerUpdate({ polygonPoints: newPoints });
      }

      if (curSelUnitId !== unitId) {
        onSetCurSelUnitId(unitId);
        onSetCurSelPointId(null);
        onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
        return;
      }

      if (
        curEvent === AnnotationSystemEventsEnum.SELECTED_MODE &&
        curMode === AnnotationSystemModesEnum.UNIT_SELECT
      ) {
        /** On double click */
        if (firstClickId.current) {
          firstClickId.current = 0;
          onSetCurMode(AnnotationSystemModesEnum.DOT_SELECT);
          return;
        }

        firstClickId.current = 1;
        setTimeout(() => {
          firstClickId.current = 0;
        }, 200);

        return;
      }
    },
    [
      canvasCoords,
      curEvent,
      curMode,
      curSelUnitId,
      isCreate,
      isDotSel,
      onSetCurEvent,
      onSetCurMode,
      onSetCurSelPointId,
      onSetCurSelUnitId,
      polygonPoints,
      setPropsWithReducerUpdate,
      unitId
    ]
  );

  return handleMouseDown;
}
