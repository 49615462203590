import useGetUrlParams from './useGetUrlParams';
import useLocalStorage from './useLocalStorage';
import { useCallback } from 'react';

function useTablePage(key: string) {
  const { params, update } = useGetUrlParams();

  const curPage = parseInt(params.get('page') ?? '1', 10) || 1;

  const [page, setPage] = useLocalStorage(key, curPage - 1);

  const handleSetPage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      params.set('page', (newPage + 1).toString());
      update();
    },
    [setPage, params, update]
  );

  return { page, setPage: handleSetPage };
}

export default useTablePage;
