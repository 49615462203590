import { DefaultTheme } from 'styled-components/macro';
import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  ShadowProps,
  TypographyProps
} from 'styled-system';

export const enum Variant {
  'primary' = 'primary',
  'secondary' = 'secondary',
  'warning' = 'warning',
  'success' = 'success',
  'alternate' = 'alternate',
  'ghost' = 'ghost',
  'outlined' = 'outlined'
}

export const enum Size {
  'big' = 'big',
  'normal' = 'normal',
  'small' = 'small',
  'micro' = 'micro',
  'mini' = 'mini'
}

export type ButtonPropsSelf = {
  variant?: keyof typeof Variant;
  size?: keyof typeof Size;
  theme?: DefaultTheme;
  isLoading?: boolean;
  micro?: boolean;
  mini?: boolean;
  small?: boolean;
  big?: boolean;
  normal?: boolean;
  square?: boolean;
  color?: string;
  type?: string;
  capitalize?: boolean;
  htmlType?: string;
  elRef?: React.MutableRefObject<HTMLButtonElement | null>;
  noHover?: boolean;
  iconSuffix?: React.ReactElement;
  iconPrefix?: React.ReactElement;
  hoverSvgFillColor?: string;
  hoverSvgStrokeColor?: string;
  noPadding?: boolean;
  round?: boolean;
  roundSize?: number;
  disabled?: boolean;
  iconSize?: number | string;
  iconStyle?: boolean;
  w?: number;
  h?: number;
  iconPadding?: number;
};

type StyledSystemProps = BackgroundProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  TypographyProps &
  ShadowProps;

export type ButtonProps = ButtonPropsSelf &
  React.ButtonHTMLAttributes<HTMLButtonElement> &
  StyledSystemProps;
