import React from 'react';
import { Col } from 'components/_main';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import { AnnotationToolbarProps } from '../../AnnotationView.types';

import ImageAnnotationToolbar from './ImageAnnotationToolbar';
import DotsAnnotationToolbar from './DotsAnnotationToolbar';
import ContentModerationToolbar from './ContentModerationToolbar';
import { TaskType } from 'generated/graphql';
import SectionFrames from './SectionFrames';
import PolygonsAnnotationToolbar from './PolygonsAnnotationToolbar';

function AnnotationToolbar({
  taskId,
  helperTimeSpent,
  taskType,
  taskingType,
  showFramesSelection,
  setTaskData
}: AnnotationToolbarProps) {
  const isModerationType = taskType === TaskType.ContentModeration;
  const { annotationSpecificationType } = useAnnotationSystemState();

  let toolbarRender = (
    <>
      {showFramesSelection && taskingType === 'tasking' && (
        <SectionFrames taskId={taskId} setTaskData={setTaskData} />
      )}
      <ImageAnnotationToolbar />
    </>
  );

  if (annotationSpecificationType === 'dot') {
    toolbarRender = (
      <>
        {showFramesSelection && taskingType === 'tasking' && (
          <SectionFrames taskId={taskId} setTaskData={setTaskData} />
        )}
        <DotsAnnotationToolbar />
      </>
    );
  }

  if (annotationSpecificationType === 'polygon') {
    toolbarRender = (
      <>
        {showFramesSelection && taskingType === 'tasking' && (
          <SectionFrames taskId={taskId} setTaskData={setTaskData} />
        )}
        <PolygonsAnnotationToolbar />
      </>
    );
  }

  if (isModerationType) {
    toolbarRender = <ContentModerationToolbar />;
  }

  return (
    <Col
      displayFlex
      justifyContent="space-between"
      width="100%"
      padding="20px 0 0px"
    >
      <Col>{toolbarRender}</Col>
    </Col>
  );
}

export default AnnotationToolbar;
