import React, { PropsWithChildren } from 'react';

interface AuthFormWrapperProps {
  submitForm: () => void;
}

function AuthFormWrapper({
  children,
  submitForm
}: PropsWithChildren<AuthFormWrapperProps>) {
  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          submitForm();
        }
      }}
      style={{ width: '100%' }}
    >
      {children}
    </form>
  );
}

export default AuthFormWrapper;
