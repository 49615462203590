import React from 'react';
import { Project } from 'generated/graphql';
import { ProjectDetails } from 'appTypes';
import { Tooltip } from 'antd';
import { Col } from 'components/_main';
import { CopyIcon, EditIcon } from 'assets/tableRowActions';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import Icon from 'components/Icon';

interface ClientActionsProps {
  onEdit: (data: ProjectDetails) => void;
  onCopyProject: (data: ProjectDetails) => void;
}

interface RowActionsProps {
  data: Project;
  isLoading?: boolean;
  onEdit: (data: ProjectDetails) => void;
  onCopyProject: (data: ProjectDetails) => void;
}

function RowActions({
  data,
  onCopyProject,
  isLoading,
  onEdit
}: RowActionsProps) {
  const { isPremium } = useGetUserSessionData();

  return (
    <Col className="row-actions">
      {isPremium && (
        <Tooltip title="Duplicate">
          <Icon
            hoverStrokeColor="fg"
            disabled={isLoading}
            onClick={() => onCopyProject(data)}
          >
            <CopyIcon />
          </Icon>
        </Tooltip>
      )}
      <Tooltip title="Edit">
        <Icon
          hoverStrokeColor="fg"
          disabled={isLoading}
          onClick={() => onEdit(data)}
        >
          <EditIcon />
        </Icon>
      </Tooltip>
    </Col>
  );
}

const clientActions =
  ({ onEdit, onCopyProject }: ClientActionsProps) =>
  (data: Project) =>
    <RowActions data={data} onEdit={onEdit} onCopyProject={onCopyProject} />;

export default clientActions;
