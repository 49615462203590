import { Link, Row } from 'components/_main';
import { Modal } from 'components/_modals';
import useOpen from 'hooks/useOpen';
import React from 'react';
import { useInviteHelpersContext } from '../InviteHelpers.context';
import InviteHelpersModalBody from './InviteHelpersModalBody';

function InviteHelpersModal() {
  const { helpers, selectedHelpers, isAllowToInviteMore } =
    useInviteHelpersContext();
  const { onClose, onOpen, isOpen } = useOpen();
  const isExistTeammates =
    helpers?.length > 0 &&
    helpers.filter((e) => !selectedHelpers?.includes(e))?.length > 0;

  return (
    <>
      {isExistTeammates && (
        <Row>
          <Link disabled={!isAllowToInviteMore} upper onClick={onOpen}>
            + Add existing teammates
          </Link>
          <Modal
            width="550px"
            destroyOnClose
            title="Add helper"
            isVisible={isOpen}
            bodyStyle={{ padding: '20px 10px' }}
            onCancel={onClose}
            footer={false}
          >
            <InviteHelpersModalBody onCancel={onClose} />
          </Modal>
        </Row>
      )}
    </>
  );
}

export default InviteHelpersModal;
