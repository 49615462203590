import React from 'react';
import { TabPane, Tabs } from 'components/Tabs';
import HelperStatisticsReport from './HelperStatisticsReport';
import styled from 'styled-components/macro';
import ClientCurrentTasksStateReport from './ClientCurrentTasksStateReport';
import HeaderAppBar from 'containers/HeaderAppBar';
import { upper } from 'utils';
import useSetAppBarTitle from 'hooks/useSetAppBarTitle';

const Root = styled.div`
  padding: 1rem 28px 3rem;
  background: #f4f8fb;
`;

const ReportsPage = () => {
  useSetAppBarTitle('Reports');

  return (
    <div>
      <HeaderAppBar />
      <Root>
        <Tabs defaultActiveKey="Projects" tabBarGutter={75}>
          <TabPane tab={upper('Helper statistics')} key="Helper statistics">
            <HelperStatisticsReport />
          </TabPane>
          <TabPane tab={upper('Current state')} key="Current state">
            <ClientCurrentTasksStateReport />
          </TabPane>
        </Tabs>
      </Root>
    </div>
  );
};

export default ReportsPage;
