import {
  AnnotationSystemEventsEnum,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import { PolygonPoint } from 'containers/AnnotationSystem/reducer';
import { MouseEvent, useCallback, useEffect } from 'react';

interface Props {
  onReducerUpdate: (partialProps: Partial<AnnotationUnitProps>) => void;
  unitId: string;
  polygonPoints: PolygonPoint[];
}

export default function useSvgMouseUp({
  polygonPoints,
  onReducerUpdate,
  unitId
}: Props) {
  const { curEvent, svgLayerRef } = useAnnotationSystemState();
  const { onSetCurEvent } = useAnnotationSystemActions();
  const { isActive } = useStatus({
    unitId
  });

  const handleMouseUp = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      if (!isActive) {
        return;
      }

      switch (curEvent) {
        case AnnotationSystemEventsEnum.DRAG_MODE:
          onReducerUpdate({ polygonPoints });
          onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
          break;
        default:
          break;
      }
    },
    [curEvent, isActive, onReducerUpdate, onSetCurEvent, polygonPoints]
  );

  useEffect(() => {
    // @ts-ignore
    svgLayerRef?.addEventListener('mouseup', handleMouseUp);
    return () => {
      // @ts-ignore
      svgLayerRef?.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseUp, isActive, svgLayerRef]);

  return handleMouseUp;
}
