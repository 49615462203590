import { FormikHelpers } from 'formik';
import { toast } from 'utils/toast';
import {
  Project,
  useListProjectsLazyQuery,
  useListUnreviewdProjectsLazyQuery,
  useProjectUpdateAssignedAuditorMutation,
  useProjectUpdateAssignedHelperMutation,
  useProjectUpdateFilesMutation,
  useProjectUpdateInfoMutation,
  useProjectUpdateRateMutation,
  useProjectUpdateStatusMutation,
  useUploadOverrideZipMutation
} from 'generated/graphql';
import {
  isChangedFiles,
  isChangedInfo,
  isChangedRate,
  isChangedAssignedHelper,
  isChangedAssignedAuditor,
  mapValuesToPrjUpdateAssignedHelperInput,
  mapValuesToPrjUpdateFilesInput,
  mapValuesToPrjUpdateInfoInput,
  mapValuesToPrjUpdateRateInput,
  mapValuesToPrjUpdateAssignedAuditorInput,
  isChangedStatus,
  isChangedMediaFileName,
  isChangedValues
} from 'containers/ProjectDetailsCard/utils';
import consoleError from 'utils/consoleError';
import { isAdmin, rateToFloat } from 'utils';
import useTablePage from 'hooks/useTablePage';
import { PROJECTS_LIST_ALL_PAGE, PROJECTS_LIST_ALL_SORT } from 'appConstants';
import useTableSortMethod from 'hooks/useTableSortMethod';
import { FormikType } from 'containers/ProjectDetailsCard/types';
import useAssignedHelpersList from 'hooks/useAssignedHelpersList';

interface ProjectDetailsUpdateProps {
  initPrjData: FormikType;
  updatePrjLoading: boolean;
  refetchListProjects(): void;
  updateProject: (
    values: Project & { isLabelsCorrect?: boolean | undefined },
    formikHelpers: FormikHelpers<any>
  ) => void | Promise<void>;
}

export const useProjectDetailsUpdate = (
  projectData?: Project,
  onCancel?: () => void
): ProjectDetailsUpdateProps => {
  const { page: projectPendingCurTablePage } = useTablePage(
    PROJECTS_LIST_ALL_PAGE
  );
  const { sortMethod: projectPendingSortMethod } = useTableSortMethod(
    PROJECTS_LIST_ALL_SORT
  );
  const { assignedAuditorIds, assignedHelperIds } = useAssignedHelpersList(
    projectData?.id
  );
  const projectId = projectData?.id;

  const initPrjData: FormikType = projectData?.id
    ? {
        ...projectData,
        helperRate: projectData?.helperRate
          ? rateToFloat(projectData?.helperRate)
          : 0,
        auditorRate: projectData?.auditorRate
          ? rateToFloat(projectData?.auditorRate)
          : 0,
        selectedHelperIds: assignedHelperIds,
        selectedAuditorIds: assignedAuditorIds,
        search: '',
        clientInstructionUrl: projectData?.clientInstructionUrl?.[0]
          ? projectData?.clientInstructionUrl?.[0].split(',')
          : []
      }
    : ({} as FormikType);

  const [updatePrjStatus, { loading: updatePrjStatusLoading }] =
    useProjectUpdateStatusMutation({
      onCompleted: () => {
        toast.success('Project status updated');
      },
      onError: (error) => {
        toast.error(`Error:  ${error.message}`);
      }
    });

  const [updatePrjInfo, { loading: updatePrjInfoLoading }] =
    useProjectUpdateInfoMutation({
      onCompleted: () => {
        toast.success('Project info updated');
      },
      onError: (error) => {
        toast.error(`Error:  ${error.message}`);
      }
    });

  const [updatePrjRate, { loading: updatePrjRateLoading }] =
    useProjectUpdateRateMutation({
      onCompleted: () => {
        toast.success('Project rate updated');
      },
      onError: (error) => {
        toast.error(`Error:  ${error.message}`);
      }
    });

  const [updatePrjFiles, { loading: updatePrjFilesLoading }] =
    useProjectUpdateFilesMutation({
      onCompleted: () => {
        toast.success('Project files updated');
      },
      onError: (error) => {
        toast.error(`Error:  ${error.message}`);
      }
    });

  const [updatePrjAssignedHelper, { loading: updatePrjAssignedHelperLoading }] =
    useProjectUpdateAssignedHelperMutation({
      onCompleted: () => {
        toast.success('Project assigned helpers updated');
      },
      onError: (error) => {
        toast.error(`Error:  ${error.message}`);
      }
    });

  const [
    updatePrjAssignedAuditor,
    { loading: updatePrjAssignedAuditorLoading }
  ] = useProjectUpdateAssignedAuditorMutation({
    onCompleted: () => {
      toast.success('Project assigned auditors updated');
    },
    onError: (error) => {
      toast.error(`Error:  ${error.message}`);
    }
  });

  const updatePrjLoading =
    updatePrjStatusLoading ||
    updatePrjInfoLoading ||
    updatePrjRateLoading ||
    updatePrjFilesLoading ||
    updatePrjAssignedHelperLoading ||
    updatePrjAssignedAuditorLoading;

  const [listProjectsQuery, { refetch: refetchListProjects }] =
    useListProjectsLazyQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        page: projectPendingCurTablePage,
        sortByDateOrder: projectPendingSortMethod
      }
    });

  const [
    listUnreviewedProjectsData,
    { refetch: refetchUnreviewedProjectsData }
  ] = useListUnreviewdProjectsLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: projectPendingCurTablePage,
      sortByDateOrder: projectPendingSortMethod
    }
  });

  const fetchUpdatedList = async () => {
    await refetchListProjects();
    if (isAdmin()) {
      await refetchUnreviewedProjectsData();
    }
  };

  const [overrideFile] = useUploadOverrideZipMutation({
    onCompleted: () => {
      toast.success(
        'Please wait, we’re processing your project. Check out later, it may take time',
        {
          autoClose: 10000
        }
      );
    },
    onError: (error1) => toast.error(error1.message)
  });

  const updateProject = async (
    values: Project & { isLabelsCorrect?: boolean },
    formikHelpers: FormikHelpers<any>
  ) => {
    const { isLabelsCorrect, ...newValues } = values;

    if (!projectId) {
      return;
    }

    const updatedValues = {
      id: newValues.id,
      ...isChangedValues(initPrjData, newValues)
    };

    try {
      if (isChangedInfo(initPrjData, newValues)) {
        await updatePrjInfo({
          variables: {
            input: mapValuesToPrjUpdateInfoInput(updatedValues)
          }
        });
      }

      if (isChangedRate(initPrjData, newValues)) {
        await updatePrjRate({
          variables: {
            input: mapValuesToPrjUpdateRateInput(updatedValues)
          }
        });
      }

      if (isChangedFiles(initPrjData, newValues)) {
        await updatePrjFiles({
          variables: {
            input: mapValuesToPrjUpdateFilesInput(updatedValues)
          }
        });
      }

      if (isChangedAssignedHelper(initPrjData, newValues)) {
        await updatePrjAssignedHelper({
          variables: {
            input: mapValuesToPrjUpdateAssignedHelperInput(newValues)
          }
        });
      }

      if (isChangedAssignedAuditor(initPrjData, newValues)) {
        await updatePrjAssignedAuditor({
          variables: {
            input: mapValuesToPrjUpdateAssignedAuditorInput(newValues)
          }
        });
      }

      if (isChangedMediaFileName(initPrjData, values)) {
        await overrideFile({
          variables: {
            projectId,
            fileName:
              (newValues.mediaFileName && newValues.mediaFileName[0]) ?? ''
          }
        });
      }

      if (isChangedStatus(initPrjData, newValues)) {
        await updatePrjStatus({
          variables: {
            input: {
              projectId,
              status: newValues.status
            }
          }
        });
      }

      onCancel && onCancel();
      formikHelpers.resetForm();
      fetchUpdatedList();
    } catch (error) {
      consoleError({
        message: 'Error during update project',
        projectId,
        error
      });
    }
  };
  return {
    initPrjData,
    updateProject,
    refetchListProjects,
    updatePrjLoading
  };
};
