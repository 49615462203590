import React from 'react';
import { css, useTheme } from 'styled-components/macro';
import { Box, Row } from 'components/_main';
import Text from 'components/_main/Text';

interface ProjectTypeCardProps {
  title?: string;
  icon?: React.FunctionComponent;
  isActive?: boolean;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  iconType?: 'fill' | 'stroke';
}

export function ProjectTypeCard({
  title,
  icon: Icon,
  isActive,
  onClick,
  disabled,
  iconType = 'stroke'
}: ProjectTypeCardProps) {
  const theme = useTheme();

  if (!Icon || !title) {
    return null;
  }

  return (
    <Row
      overflowVisible
      onClick={!disabled ? onClick : undefined}
      css={`
        position: relative;
        transition: all 300ms;
        cursor: pointer;

        &:hover {
          background-color: ${
            isActive ? theme.colors.primary : theme.colors.lightest
          };
          box-shadow: 0 1px 1px ${theme.colors.shadow};
        }

        svg {
          height: 25px;
          path,
          rect {
          ${
            iconType === 'stroke'
              ? css`
                  stroke: ${isActive
                    ? theme.colors.bg
                    : theme.colors.primary} !important;
                `
              : css`
                  fill: ${isActive
                    ? theme.colors.bg
                    : theme.colors.primary} !important;
                `
          }
        }

        ${
          disabled &&
          css`
            &,
            &:hover {
              cursor: auto;
              box-shadow: none;
            }

            &:after {
              border-radius: 7px;
              position: absolute;
              width: 100%;
              height: 100%;
              content: ' ';
              z-index: 100;
              background-color: ${theme.colors.lighter};
              opacity: 0.5;

              svg {
                filter: grayscale(100%);
              }
            }
          `
        }
      `}
      p="20px"
      gridGap={0}
      justifyItems="center"
      alignContent="center"
      alignItems="center"
      width="120px"
      height="120px"
      gridAutoRows="1fr 1fr"
      boxShadow={`0 4px 10px ${theme.colors.shadow}`}
      borderRadius="7px"
      {...(isActive && {
        backgroundColor: theme.colors.primary
      })}
    >
      <Box>
        <Icon />
      </Box>
      <Text
        variant="ui-small-regular"
        textAlign="center"
        {...(isActive && { color: theme.colors.bg, variant: 'ui-small-bold' })}
      >
        {title}
      </Text>
    </Row>
  );
}
export default ProjectTypeCard;
