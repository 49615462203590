import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import { PolygonPoint } from 'containers/AnnotationSystem/reducer';
import React, { MouseEvent, useCallback, useEffect } from 'react';
import useCanvasCoords from './useCanvasCoords';
import { addPolygonPoint } from './utils';

interface Props {
  setPropsWithReducerUpdate: (
    partialProps: Partial<AnnotationUnitProps>
  ) => void;
  unitId: string;
  polygonPoints: PolygonPoint[];
}

export default function useSvgMouseDown({
  polygonPoints,
  setPropsWithReducerUpdate,
  unitId
}: Props) {
  const { curEvent, curMode, svgLayerRef } = useAnnotationSystemState();
  const { onSetCurEvent } = useAnnotationSystemActions();
  const canvasCoords = useCanvasCoords();
  const { isActive } = useStatus({
    unitId
  });

  const handleMouseDown = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      if (e.target !== svgLayerRef) return;

      if (
        !isActive ||
        curEvent === AnnotationSystemEventsEnum.ZOOM_NAVIGATION
      ) {
        return;
      }

      if (curMode === AnnotationSystemModesEnum.DOT_SELECT) {
        /** Not allow to exit from Dots edit mode by clicking outside. To prevent accidentally misclick */
        e.stopPropagation();
        e.preventDefault();
        return;
      }

      switch (curEvent) {
        case AnnotationSystemEventsEnum.CREATE_MODE:
        case AnnotationSystemEventsEnum.APP_POLYGON_POINT:
          if (!e) break;

          const x = e.clientX - canvasCoords[0];
          const y = e.clientY - canvasCoords[1];

          setPropsWithReducerUpdate({
            polygonPoints: addPolygonPoint(polygonPoints, [x, y])
          });

          break;
        case AnnotationSystemEventsEnum.DRAG_MODE:
          onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
          break;
        case AnnotationSystemEventsEnum.SELECTED_MODE:
          onSetCurEvent(null);
          break;
        default:
          break;
      }
    },
    [
      canvasCoords,
      curEvent,
      curMode,
      isActive,
      onSetCurEvent,
      polygonPoints,
      setPropsWithReducerUpdate,
      svgLayerRef
    ]
  );

  useEffect(() => {
    if (!isActive) {
      return;
    }
    // @ts-ignore
    svgLayerRef?.addEventListener('mousedown', handleMouseDown);
    return () => {
      // @ts-ignore
      svgLayerRef?.removeEventListener('mousedown', handleMouseDown);
    };
  }, [handleMouseDown, isActive, svgLayerRef]);
}
