import React from 'react';
import { InputLabelProps } from './InputLabel.types';
import { FormItemStyled } from './InputLabel.ui';
import { withFormDisable } from './utils';

const InputLabel = ({
  children,
  layout = 'horizontal',
  forInputField,
  alignTop = true,
  disabled,
  loading,
  meta,
  role,
  disableErrors = false,
  labelPaddingTop,
  ...props
}: InputLabelProps) => {
  const layoutStyle = {
    wrapperCol: {
      span: layout === 'vertical' ? 24 : 0,
      offset: 0
    },
    labelCol: {}
  };

  const validationStatus = loading
    ? 'validating'
    : meta?.touched && meta?.error
    ? 'error'
    : meta?.touched && !meta?.error
    ? 'success'
    : undefined;

  return withFormDisable(
    <FormItemStyled
      data-role={role}
      alignTop={alignTop}
      layout={layout}
      forInputField={forInputField}
      validateStatus={validationStatus}
      help={
        !disableErrors
          ? meta?.touched && meta?.error && meta?.error.toString()
            ? meta?.error.toString()
            : undefined
          : undefined
      }
      {...props}
      {...layoutStyle}
    >
      {children}
    </FormItemStyled>,
    disabled
  );
};

export default InputLabel;
