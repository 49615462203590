import { TUseSubmitTask } from 'containers/TasksRouter/Tasks.types';
import { useSubmitTaskMutation } from 'generated/graphql';

const useGetSubmitTaskMutation = (): [TUseSubmitTask, boolean] => {
  const [submitTask, { loading }] = useSubmitTaskMutation();

  return [submitTask, loading];
};

export default useGetSubmitTaskMutation;
