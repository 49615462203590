import { ProjectType } from 'appTypes';

const getLabelsTitlesByProjectType = (
  projectType: ProjectType
): {
  title: string;
  field: string;
  mainTitle: string;
} => {
  switch (projectType) {
    case ProjectType.IMAGE_ANNOTATION:
      return {
        title: 'LABELS',
        field: 'Enter labels for project',
        mainTitle: 'Add labels'
      };
    case ProjectType.CONTENT_MODERATION:
      return {
        title: 'REJECT REASONS',
        field: 'Enter reject reasons for project',
        mainTitle: 'Add reasons'
      };
    case ProjectType.DIGITIZATION:
      return {
        title: 'SECTIONS',
        field: 'Enter sections for project',
        mainTitle: 'Add sections'
      };
    default:
      return {
        title: 'LABELS',
        field: 'Enter labels for project',
        mainTitle: 'Add labels'
      };
  }
};

export default getLabelsTitlesByProjectType;
