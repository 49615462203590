export function downloadContent(
  filename: string,
  text: string,
  dataContentType: string
) {
  const element = document.createElement('a');
  element.setAttribute('href', dataContentType + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export default downloadContent;
