import React from 'react';
import { Project, ProjectStatus, TaskType } from 'generated/graphql';
import { Menu, Dropdown } from 'antd';
import { upper } from 'utils';
import { Link, Text } from 'components/_main/Text';
import { Box } from 'components/_main';
import SpinnerLoader from 'components/SpinnerLoader';
import { getTasksCount } from 'utils/getTasksCount';
import useDownloadReport from 'containers/Projects/hooks/useDownloadReport';
import { ArrowDownIcon } from 'assets/tableRowActions';

interface DownloadResultsComponentProps {
  data: Project;
  isActive: boolean;
}

function DownloadResultsComponent({
  data,
  isActive
}: DownloadResultsComponentProps) {
  const [visible, setVisible] = React.useState(false);

  const { completedTasksCount, rejectedTasksCount } = getTasksCount(data);
  const {
    handleGenerateCocoReport,
    handleGenerateYoloReport,
    handleProjectResultsDownload,
    isLoading
  } = useDownloadReport(data);

  const isCanBeDownloaded =
    data.status !== 'UNREVIEWED' &&
    completedTasksCount + rejectedTasksCount > 0;

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          onClick: handleProjectResultsDownload,
          label: <span>JSON report</span>
        },
        (data.type === TaskType.VideoAnnotation ||
          data.type === TaskType.ImageAnnotation) &&
        data.status === ProjectStatus.Completed
          ? {
              key: '2',
              onClick: handleGenerateCocoReport,
              label: <span>COCO report</span>
            }
          : null,
        data.type === TaskType.ImageAnnotation &&
        data.projectSettings?.annotationType === 'box' &&
        data.status === ProjectStatus.Completed
          ? {
              key: '3',
              onClick: handleGenerateYoloReport,
              label: <span>YOLO report</span>
            }
          : null
      ]}
    ></Menu>
  );

  if (!isCanBeDownloaded) {
    return <Text colorVariant="light">No results</Text>;
  }

  return (
    <Dropdown
      overlay={menu}
      visible={visible}
      onVisibleChange={(isVisible) => setVisible(isVisible)}
    >
      <Box position="relative">
        {isLoading && isActive ? (
          <Box size="24px">
            <SpinnerLoader />
          </Box>
        ) : (
          <Link
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Box gridGap="4px" displayFlex alignItems="center">
              {upper('Export')} <ArrowDownIcon />
            </Box>
          </Link>
        )}
      </Box>
    </Dropdown>
  );
}

const downloadResultsColumn = (activeProjectId?: number) => (data: Project) =>
  (
    <DownloadResultsComponent
      data={data}
      isActive={activeProjectId === data?.id}
    />
  );

export default downloadResultsColumn;
