import React from 'react';
import { currentRole, Roles } from 'utils/role';
import ProjectsClient from './ProjectsClient';
import ProjectsAdmin from './ProjectsAdmin';
import ProjectsHelper from './ProjectsHelper';
import { ProjectsContextProvider } from 'containers/Projects/Projects.context';

const Projects = () => {
  let projects;

  switch (currentRole()) {
    case Roles.ADMIN:
      projects = <ProjectsAdmin />;
      break;
    case Roles.HELPER:
      projects = <ProjectsHelper />;
      break;
    case Roles.CLIENT:
    default:
      projects = <ProjectsClient />;
      break;
  }

  return <ProjectsContextProvider>{projects}</ProjectsContextProvider>;
};

export default Projects;
