import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import React, { useEffect, useState } from 'react';

export default function useMousePressed() {
  const [isMousePressed, setIsMousePressed] = useState(false);
  const { svgLayerRef } = useAnnotationSystemState();

  const handleMouseDown = () => {
    setIsMousePressed(true);
  };

  const handleMouseUp = () => {
    setIsMousePressed(false);
  };

  useEffect(() => {
    if (!svgLayerRef) return;

    svgLayerRef.addEventListener('mousedown', handleMouseDown);
    svgLayerRef.addEventListener('mouseup', handleMouseUp);

    return () => {
      svgLayerRef.removeEventListener('mousedown', handleMouseDown);
      svgLayerRef.removeEventListener('mouseup', handleMouseUp);
    };
  });

  return isMousePressed;
}
