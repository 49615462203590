import { Button } from 'components/_main';
import PricingPlansModal from 'containers/PricingPlans';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import useOpen from 'hooks/useOpen';
import React from 'react';

export default function BadgeUpdatePricingPlan() {
  const { isPremium } = useGetUserSessionData();
  const { isOpen, onClose, onOpen } = useOpen();

  if (isPremium) {
    return null;
  }

  return (
    <>
      <Button onClick={onOpen} normal square>
        UPGRADE
      </Button>
      <PricingPlansModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
