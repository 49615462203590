import React from 'react';
import { Button, Col, Text } from 'components/_main';
import { oneMb } from './constants';

const toMb = (value: number) => Math.floor(value / oneMb);

interface UploadZoneProps {
  onClick?: React.MouseEventHandler;
  accept?: string;
  maxSizePerFile?: number | null;
  maxTotalSize?: number | null;
}

function UploadZone({
  onClick,
  accept,
  maxSizePerFile,
  maxTotalSize
}: UploadZoneProps) {
  return (
    <Col gridGap="2rem" flexCenter>
      <Button variant="outlined" onClick={onClick}>
        Upload files
      </Button>
      <Text colorVariant="light" maxWidth="215px">
        Files can be in {accept} formats.{' '}
        {maxSizePerFile && `Max file size: ${toMb(maxSizePerFile)} MB.`}{' '}
        {maxTotalSize && `Max sum of all files size: ${toMb(maxTotalSize)} MB.`}
      </Text>
    </Col>
  );
}

export default UploadZone;
