import { Box, BoxProps, Col, Text } from 'components/_main';
import useAnnotationVideo from 'containers/AnnotationSystem/hooks/useAnnotationVideo';
import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from 'styled-components/macro';

export default function CurFrameInputField() {
  const [inputMode, setInputMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { curVidTime, setCurFrame, totalFrames, curFrame, vidDuration } =
    useAnnotationVideo();

  const wrapperProps: BoxProps = {
    flexCenter: true,
    height: '44px',
    borderRadius: '5px',
    backgroundColor: 'white',
    gridAutoFlow: 'row',
    gridGap: 0,
    padding: '6px',
    grid: true,
    width: '100px',
    onClick: () => {
      if (inputMode) return;
      setInputMode(true);
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.setAttribute('value', curFrame.toString());
      }, 0);
    },
    onBlur: () => {
      setInputMode(false);
    }
  };

  const handleSetValues = useCallback(
    (newFrame: number) => {
      let frame = newFrame;
      if (frame < 0) frame = 0;
      if (frame > totalFrames) frame = totalFrames;

      setCurFrame(frame);
      setTimeout(() => {
        inputRef.current?.setAttribute('value', frame.toString());
      });
      setInputMode(false);
    },
    [setCurFrame, totalFrames]
  );

  const normalizeVal = useCallback(() => {
    const input = inputRef.current;
    const inputVal = input?.value ?? '';
    let numFrame = parseInt(inputVal, 10);
    if (!numFrame) {
      return curFrame;
    }
    return numFrame;
  }, [curFrame]);

  const handleMouseActions = useCallback(
    (e: React.MouseEvent) => {
      const val = normalizeVal();
      const wrapper = wrapperRef.current;
      const target = e.target as Node;
      if (!wrapper || !target) return;
      if (wrapper !== target && !wrapper.contains(target)) {
        handleSetValues(val);
      }
    },
    [handleSetValues, normalizeVal]
  );

  const handleKeyActions = useCallback(
    (e: React.KeyboardEvent) => {
      const val = normalizeVal();
      if (
        e.key === 'Escape' ||
        e.key === 'Enter' ||
        e.code === 'Enter' ||
        e.code === 'Escape'
      ) {
        handleSetValues(val);
        return;
      }
    },
    [handleSetValues, normalizeVal]
  );

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('mousedown', handleMouseActions);
    // @ts-ignore
    document.addEventListener('keyup', handleKeyActions);
    return () => {
      // @ts-ignore
      document.removeEventListener('keyup', handleKeyActions);
      // @ts-ignore
      document.removeEventListener('mousedown', handleMouseActions);
    };
  }, [handleKeyActions, handleMouseActions]);

  if (inputMode) {
    return (
      <Box ref={wrapperRef} {...wrapperProps}>
        <Col noGap>
          <input
            type="number"
            min={0}
            max={totalFrames}
            ref={inputRef}
            css={css`
              font-size: 12px;
              font-weight: 600;
              text-align: end;
              width: 40px;
              border: 0;
              width: min-content;
            `}
          />
          <Text fontSize="12px" fontWeight="600">{`/${totalFrames}`}</Text>
        </Col>
        <Text fontSize="12px" fontWeight="600">{`${curVidTime}s/`}</Text>
      </Box>
    );
  }

  return (
    <Box {...wrapperProps}>
      <Text
        fontSize="12px"
        fontWeight="600"
      >{`${curFrame}/${totalFrames}`}</Text>
      <Text fontSize="12px" fontWeight="600">{`${curVidTime}s/${Math.floor(
        vidDuration
      )}s`}</Text>
    </Box>
  );
}
