import { AnnotationSpecificationType } from 'appTypes';
import { AnnotationUnitProps } from './AnnotationSystem.types';

type Settings = Record<
  AnnotationSpecificationType,
  {
    ids: Record<
      string,
      {
        connections: string[];
      }
    >;
  } | null
>;

type Template = Record<
  AnnotationSpecificationType,
  {
    labels: Array<Omit<AnnotationUnitProps, 'label'>>;
  } | null
>;

const settings: Settings = {
  dot: {
    ids: {
      '0': {
        connections: ['2', '1']
      },
      '1': {
        connections: ['0', '3']
      },
      '2': {
        connections: ['0', '4']
      },
      '3': {
        connections: ['1', '5']
      },
      '4': {
        connections: ['2', '6']
      },
      '5': {
        connections: ['6', '3', '7']
      },
      '6': {
        connections: ['4', '5', '8']
      },
      '7': {
        connections: ['5', '9']
      },
      '8': {
        connections: ['6', '10']
      },
      '9': {
        connections: ['7']
      },
      '10': {
        connections: ['8']
      },
      '11': {
        connections: ['5', '12', '13']
      },
      '12': {
        connections: ['6', '11', '14']
      },
      '13': {
        connections: ['11', '15']
      },
      '14': {
        connections: ['12', '16']
      },
      '15': {
        connections: ['13']
      },
      '16': {
        connections: ['14']
      }
    }
  },
  box: null,
  rotatedBoxes: null,
  polygon: null,
  contentModeration: null,
  semantics: null,
  polyLines: null,
  cuboids3D: null,
  dataset: null,
  sentimentAnalysis: null,
  productCategories: null,
  languageDetection: null,
  contentRelevanceScore: null
};

export const humanPosesLabels: Template = {
  dot: {
    labels: [
      {
        unitId: '0',
        x: 460,
        y: 111,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Nose',
        isEnd: false
      },
      {
        unitId: '1',
        x: 492,
        y: 73,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Eye left',
        isEnd: false
      },
      {
        unitId: '2',
        x: 415,
        y: 79,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Eye right',
        isEnd: false
      },
      {
        unitId: '3',
        x: 555,
        y: 90,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Ear left',
        isEnd: false
      },
      {
        unitId: '4',
        x: 390,
        y: 106,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Ear right',
        isEnd: false
      },
      {
        unitId: '5',
        x: 555,
        y: 220,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Shoulder left',
        isEnd: false
      },
      {
        unitId: '6',
        x: 390,
        y: 220,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Shoulder right',
        isEnd: false
      },
      {
        unitId: '7',
        x: 735,
        y: 394,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Elbow left',
        isEnd: false
      },
      {
        unitId: '8',
        x: 230,
        y: 394,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Elbow right',
        isEnd: false
      },
      {
        unitId: '9',
        x: 967,
        y: 480,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Wrist left',
        isEnd: false
      },
      {
        unitId: '10',
        x: 35,
        y: 480,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Wrist right',
        isEnd: false
      },
      {
        unitId: '11',
        x: 555,
        y: 504,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Bottom left',
        isEnd: false
      },
      {
        unitId: '12',
        x: 390,
        y: 504,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Bottom right',
        isEnd: false
      },
      {
        unitId: '13',
        x: 663,
        y: 745,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Knee left',
        isEnd: false
      },
      {
        unitId: '14',
        x: 377,
        y: 745,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Knee right',
        isEnd: false
      },
      {
        unitId: '15',
        x: 721,
        y: 946,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Bootleg left',
        isEnd: false
      },
      {
        unitId: '16',
        x: 366,
        y: 946,
        type: 'dot',
        w: 10,
        h: 10,
        description: 'Bootleg right',
        isEnd: false
      }
    ]
  },
  box: null,
  rotatedBoxes: null,
  polygon: null,
  contentModeration: null,
  semantics: null,
  polyLines: null,
  cuboids3D: null,
  dataset: null,
  sentimentAnalysis: null,
  productCategories: null,
  languageDetection: null,
  contentRelevanceScore: null
};

export default settings;
