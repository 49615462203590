import React from 'react';
import { TAntdFormItem } from '../../appTypes';
import { SwitchProps } from 'antd/lib/switch';
import InputLabel from 'components/InputLabel';
import AntdSwitch from 'components/AntdSwitch';

const AntdFormItemSwitch: React.FC<TAntdFormItem & SwitchProps> = ({
  label,
  defaultChecked,
  ...props
}) => {
  return (
    <InputLabel valuePropName="checked" label={label} hasFeedback {...props}>
      <AntdSwitch defaultChecked={defaultChecked} />
    </InputLabel>
  );
};

export default AntdFormItemSwitch;
