import React from 'react';
import ConfirmModal from 'components/_modals/Modal/ConfirmModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function UnassignedAuditorModal({ isOpen, onClose, onSubmit }: Props) {
  return (
    <ConfirmModal
      title={`
          By unassigning auditor, the tasks will be automatically moved to
          "Completed". Are you sure you want to unassign auditor?`}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}

export default UnassignedAuditorModal;
