import { useCallback } from 'react';
import { useArchiveProjectMutation } from 'generated/graphql';
import { toast } from 'utils/toast';
import { ProjectDetails } from 'appTypes';

interface ArchiveProjectProps {
  refetchListProjects: () => void;
}

const useArchiveProject = ({ refetchListProjects }: ArchiveProjectProps) => {
  const [archiveProject] = useArchiveProjectMutation({
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const handleProjectArchive = useCallback(
    (data: ProjectDetails) => {
      archiveProject({
        variables: {
          projectId: data.id
        },
        onCompleted: () => {
          toast.success(`Project with ID ${data.id} archived successfully`);
          refetchListProjects && refetchListProjects();
        }
      });
    },
    [archiveProject, refetchListProjects]
  );

  return { handleProjectArchive };
};

export default useArchiveProject;
