import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import {
  AnnotationSystemEventsEnum,
  AnnotationUnitProps,
  TimelineTransition
} from '../../AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../../context';
import ResizePoints from '../ResizePoints';
import {
  MainBorderRect,
  MainBorderRectViewOnly,
  Root
} from './AnnotationBox.ui';
import handleAnimateTransitions from '../../handlers/handleTransAnimation';
import handleUpdateWithTimeline from '../../handlers/handleUpdateWithTimeline';
import { saveWithZoom } from '../../utils';
import usePrevious from 'hooks/usePrevious';
import useLabelColor from 'containers/AnnotationSystem/hooks/useLabelColor';
import UnitToolbarWithLabelSelect from 'containers/AnnotationSystem/components/UnitToolbarWithLabelSelect';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import useMouseDown from 'containers/AnnotationSystem/hooks/useMouseDown';
import useSvgLayerMouseMove from 'containers/AnnotationSystem/hooks/useSvgLayerMouseMove';
import useSvgLayerMouseUp from 'containers/AnnotationSystem/hooks/useSvgLayerMouseUp';
import useOnCopy from '../../hooks/useOnCopy';
import useAnnotationVideo from 'containers/AnnotationSystem/hooks/useAnnotationVideo';

export default function AnnotationBox({
  children,
  unitProps
}: React.PropsWithChildren<{ unitProps: AnnotationUnitProps }>) {
  const { onModifyAnnotationUnit } = useAnnotationSystemActions();
  const svgRef = useRef<any>(null);
  const labelTagRef = useRef<HTMLDivElement>(null);
  const labelColor = useLabelColor(unitProps.label);
  const animFrameId = useRef<any>(null);
  const [, setLabelTagWidth] = useState(0);

  const {
    curSelUnitId,
    hideAnnotationUnitIds,
    type,
    isViewOnly,
    curZoomLevel,
    videoStart,
    curEvent,
    isUnitCreatingProcess
  } = useAnnotationSystemState();
  const prevZoom = usePrevious(curZoomLevel);
  const prevSelUnitId = usePrevious(curSelUnitId);
  const isVisible = !hideAnnotationUnitIds.includes(unitProps.unitId);
  const isDisableHover =
    isUnitCreatingProcess ||
    curEvent === AnnotationSystemEventsEnum.ZOOM_NAVIGATION;

  const { curVidTime, isPlaying, mediaVideoRef } = useAnnotationVideo();

  const handlerAnim = useCallback(
    (e?: any) => {
      const time: number = e?.target?.currentTime ?? curVidTime;

      let startTime: number | undefined = undefined;

      /** Change unit pos during animation (play) */
      function animationCb(newCurTime: number) {
        if (!startTime) {
          startTime = newCurTime ?? 0;
        }

        handleAnimateTransitions({
          timelineTransitions: unitProps.timelineTransitions,
          prevTime: startTime,
          curTime: (time * 1000 + newCurTime - startTime) / 1000,
          svgRef: svgRef.current,
          videoIsPlaying: isPlaying
        });

        animFrameId.current = requestAnimationFrame(animationCb);
      }

      if (!animFrameId.current && isPlaying) {
        animFrameId.current = requestAnimationFrame(animationCb);
        return;
      }

      if (!isPlaying && animFrameId.current) {
        cancelAnimationFrame(animFrameId.current);
        animFrameId.current = null;
        return;
      }

      if (!isPlaying) {
        /** Change unit pos if just click on timeframe or slider */
        handleAnimateTransitions({
          timelineTransitions: unitProps.timelineTransitions,
          prevTime: curVidTime ?? 0,
          curTime: curVidTime,
          svgRef: svgRef.current,
          videoIsPlaying: isPlaying,
          forceUpdate: true
        });
      }
    },
    [isPlaying, unitProps.timelineTransitions, svgRef, curVidTime]
  );

  useEffect(() => {
    if (type !== 'video') return;
    mediaVideoRef?.addEventListener('timeupdate', handlerAnim);
    return () => {
      mediaVideoRef?.removeEventListener('timeupdate', handlerAnim);
    };
  }, [
    type,
    unitProps,
    svgRef,
    mediaVideoRef,
    curVidTime,
    isPlaying,
    handlerAnim
  ]);

  let { x, y, w, h, label, unitId, isEnd } = unitProps ?? {
    x: 0,
    y: 0,
    w: 0,
    h: 0
  };

  const { isActive, isDrag, isResize } = useStatus(unitProps);

  const handleCopy = useOnCopy();

  const handleOnContextStateUpdate = useCallback(
    (newState: Partial<AnnotationUnitProps>) => {
      onModifyAnnotationUnit({
        ...saveWithZoom(newState as AnnotationUnitProps, curZoomLevel),
        timelineTransitions: newState.timelineTransitions?.map((unit) =>
          saveWithZoom(
            {
              ...unit,
              x: unit.x,
              y: unit.y
            } as unknown as AnnotationUnitProps,
            curZoomLevel
          )
        ) as unknown as TimelineTransition[],
        unitId: unitProps.unitId
      });
    },
    [curZoomLevel, onModifyAnnotationUnit, unitProps.unitId]
  );

  useLayoutEffect(() => {
    /** Update zoomLevel of units at current time position while zoom in/out. Video mode only */
    if (
      !mediaVideoRef ||
      type !== 'video' ||
      curVidTime === 0 ||
      prevZoom === curZoomLevel
    )
      return;

    handlerAnim();
  }, [
    svgRef,
    curSelUnitId,
    curVidTime,
    curZoomLevel,
    handlerAnim,
    mediaVideoRef,
    prevSelUnitId,
    prevZoom,
    type
  ]);

  const handleUpdate = useCallback(() => {
    const element = svgRef.current;

    if (!element) return;

    if (type === 'video' && mediaVideoRef) {
      handleUpdateWithTimeline({
        svgRef: svgRef.current,
        onUpdate: handleOnContextStateUpdate,
        curTime: curVidTime,
        timelineTransitions: unitProps.timelineTransitions,
        startTime: videoStart
      });
      return;
    }

    handleOnContextStateUpdate({
      ...unitProps,
      x: parseInt(element.getAttribute('x') ?? '0'),
      y: parseInt(element.getAttribute('y') ?? '0'),
      w: parseInt(element.getAttribute('width') ?? '0'),
      h: parseInt(element.getAttribute('height') ?? '0'),
      isEnd: element.getAttribute('data-is-end') === 'true',
      label: element.dataset.label ?? unitProps.label
    });
  }, [
    curVidTime,
    handleOnContextStateUpdate,
    mediaVideoRef,
    svgRef,
    type,
    unitProps,
    videoStart
  ]);

  useMouseDown({
    unitId,
    ref: svgRef
  });

  useSvgLayerMouseMove({
    ref: svgRef,
    unitId
  });

  useSvgLayerMouseUp({
    onStateUpdate: handleUpdate,
    unitId
  });

  if (type === 'video' && curVidTime === 0) {
    x = unitProps?.timelineTransitions?.[0]?.x ?? 0;
    y = unitProps?.timelineTransitions?.[0]?.y ?? 0;
    h = unitProps?.timelineTransitions?.[0]?.h ?? 0;
    w = unitProps?.timelineTransitions?.[0]?.w ?? 0;
    isEnd = unitProps?.timelineTransitions?.[0]?.isEnd ?? false;
  }

  useLayoutEffect(() => {
    if (!labelTagRef.current) {
      return;
    }

    const { width } = labelTagRef.current.getBoundingClientRect();

    if (width) {
      setLabelTagWidth(width);
    }
  }, []);

  useLayoutEffect(() => {
    const elem = svgRef.current;
    if (!elem) return;

    if (isVisible) {
      elem.style.visibility = 'visible';
      return;
    }

    elem.style.visibility = 'hidden';
  }, [isVisible, svgRef]);

  return (
    <Root
      color={labelColor}
      ref={svgRef}
      isActive={isActive}
      isDisableHover={isDisableHover}
      x={x}
      isDrag={isDrag}
      y={y}
      width={w}
      height={h}
      data-unit-id={unitId}
      data-is-active={isActive}
      data-is-drag={isDrag}
      data-label={label}
      data-is-end={isEnd}
      data-sidebar-visibility-status={isVisible}
      visibility={isVisible ? 'visible' : 'hidden'}
    >
      {isViewOnly ? (
        <MainBorderRectViewOnly width="100%" height="100%" />
      ) : (
        <>
          <MainBorderRect width="100%" data-change-type="drag" height="100%" />
          {isActive && !isResize && !isDrag && (
            <ResizePoints color={labelColor} />
          )}
          {!isViewOnly && (
            <UnitToolbarWithLabelSelect
              unitProps={unitProps}
              onCopy={handleCopy}
              show={isActive && !isResize && !isDrag}
              svgRef={svgRef}
              label={label}
              color={labelColor}
            />
          )}
          {children}
        </>
      )}
    </Root>
  );
}
