import React from 'react';
import { upper } from 'utils/_stringUtilsLegacy';
import { Project } from 'generated/graphql';
import { renderRate } from 'utils/_rateUtils';
import specializationIconColumn from 'components/Table/_columnRender/specializationIconColumn';
import { ColumnsAdminPendingProjectsProps } from 'containers/Projects/columns.types';
import adminPendingProjectsActions from 'containers/Projects/ProjectsAdmin/PendingProjects/rowActions';

const columnsPendingProjectAdmin = ({
  onEdit,
  onActivate
}: ColumnsAdminPendingProjectsProps) => [
  {
    title: upper('client'),
    width: 200,
    dataIndex: 'formattedClient',
    key: 'client'
  },
  {
    title: upper('project'),
    width: 600,
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: upper('created'),
    width: 250,
    dataIndex: 'formattedCreatedAt',
    key: 'createdAt'
  },
  {
    title: upper('specialization'),
    width: 250,
    dataIndex: 'mappedType',
    key: 'type'
  },
  {
    title: upper('type'),
    width: 80,
    key: 'specType',
    render: specializationIconColumn
  },
  {
    title: upper('helper/auditor rate, $'),
    width: 250,
    render: (data: Project) => {
      const { helperRate, auditorRate } = data;

      if (helperRate && auditorRate) {
        return (
          <>
            {renderRate(helperRate)} / {renderRate(auditorRate)}
          </>
        );
      }

      if (helperRate || auditorRate) {
        return <>{renderRate(helperRate || auditorRate)}</>;
      }

      return null;
    }
  },
  {
    title: '',
    render: adminPendingProjectsActions({ onEdit, onActivate }),
    width: 100
  }
];

export default columnsPendingProjectAdmin;
