import React, { useLayoutEffect, useRef } from 'react';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../../context';
import { Root } from './SvgLayer.ui';
import SvgLayerDot from './Dot';
import SvgLayerBoxes from './Boxes';
import styled, { css } from 'styled-components/macro';
import SvgLayerPolygons from './Polygons';
import useGlobalHotkeys from 'containers/AnnotationSystem/hooks/useGlobalHotkeys';

interface SvgOpacityLayerRectProps {
  opacityLevel: number;
  h: number;
  w: number;
}

const SvgOpacityLayerRect = styled.rect<SvgOpacityLayerRectProps>(
  ({ theme, opacityLevel, h, w }) => css`
    position: absolute;
    width: ${w}px;
    height: ${h}px;
    pointer-events: none;
    fill: ${theme.colors.fg};
    fill-opacity: ${opacityLevel};
  `
);

function SvgOpacityLayer() {
  const { svgLayerOpacityLevel, h, w, zoomStep } = useAnnotationSystemState();

  return (
    <SvgOpacityLayerRect
      className="noselect"
      h={h / zoomStep}
      w={w / zoomStep}
      opacityLevel={svgLayerOpacityLevel}
    />
  );
}

export default function SvgLayer() {
  const rootRef = useRef<SVGSVGElement>(null);
  const { annotationSpecificationType } = useAnnotationSystemState();
  const { onSetSvgLayerRef } = useAnnotationSystemActions();

  useLayoutEffect(() => {
    onSetSvgLayerRef(rootRef.current);
  }, [onSetSvgLayerRef]);

  useGlobalHotkeys();

  return (
    <Root className="noselect" ref={rootRef} width="100%" height="100%">
      <SvgOpacityLayer />
      {annotationSpecificationType === 'dot' && <SvgLayerDot />}
      {annotationSpecificationType === 'box' && <SvgLayerBoxes />}
      {annotationSpecificationType === 'polygon' && <SvgLayerPolygons />}
    </Root>
  );
}
