import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  adminInstructionsSchema,
  isLabelsCorrectSchema,
  ratesSchema,
  titleSchema
} from 'utils';
import { ProjectStatus, TaskType } from 'generated/graphql';
import { FormikType, ProjectDetailsCardModalContentProps } from './types';
import ProjectDetailsCardModalContentTabs from './ProjectDetailsCard.Modal.Content.tabs';
import ConfirmButtonsWrapper from './components/ConfirmButtons';
import { isClient } from 'utils/role';
import { ProjectTabType } from 'containers/ProjectDetailsCard/constants';
import { useProjectDetailsUpdate } from './hooks/useProjectDetailsUpdate';
import { Box } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import LeftPanel from 'containers/ProjectDetailsCard/components/LeftPanel';
import { WithFastField } from 'components/_form';
import useArchiveProject from 'containers/Projects/hooks/useArchiveProject';
import consoleLog from 'utils/consoleLog';
import { AnnotationSpecificationType } from 'appTypes';

export function ProjectDetailsCardModalContent({
  onCancel,
  projectData
}: ProjectDetailsCardModalContentProps) {
  const theme = useTheme();
  const [projectDetailsTab, setProjectDetailsTab] = useState<string>(
    ProjectTabType.General
  );

  consoleLog({ projectData });

  const withoutLabels =
    projectData?.type === TaskType.DataSetCollection ||
    projectData?.type === TaskType.Nlp ||
    (projectData?.projectSettings
      ?.annotationType as AnnotationSpecificationType) === 'dot';
  const labelsSchema = withoutLabels ? {} : isLabelsCorrectSchema;

  const { initPrjData, updateProject, updatePrjLoading, refetchListProjects } =
    useProjectDetailsUpdate(projectData, onCancel);

  const { handleProjectArchive } = useArchiveProject({
    refetchListProjects
  });

  if (!projectData?.id) {
    return null;
  }

  return (
    <Formik<FormikType>
      enableReinitialize
      initialValues={initPrjData}
      validationSchema={yup.object({
        ...ratesSchema,
        ...titleSchema,
        ...labelsSchema,
        ...adminInstructionsSchema
      })}
      validate={(values) => {
        const errors: any = {};

        if (isClient() && projectData.status === ProjectStatus.Draft) {
          if (!values.mediaFileName) {
            errors.mediaFileName = 'Media file (ZIP) required';
          }
        }

        return errors;
      }}
      onSubmit={updateProject}
    >
      <Box
        gridGap="0"
        display="grid"
        height="100%"
        gridTemplateRows={isClient() ? '100%' : '1fr max-content'}
        gridTemplateColumns="max-content 1fr"
      >
        <Box gridRow="1 / -1" gridColumn="1 / 2">
          <WithFastField>
            <LeftPanel projectData={projectData} />
          </WithFastField>
        </Box>
        <ProjectDetailsCardModalContentTabs
          projectData={initPrjData}
          projectDetailsTab={projectDetailsTab}
          setProjectDetailsTab={setProjectDetailsTab}
        />
        {!isClient() &&
          (projectDetailsTab === ProjectTabType.General ||
            projectDetailsTab === ProjectTabType.Helper ||
            projectDetailsTab === ProjectTabType.Assigned) && (
            <Box
              py="20px"
              mx="40px"
              borderTop={`1px solid ${theme.colors.lighter}`}
              gridRow="2 / -1"
              gridColumn="2 / 3"
            >
              <ConfirmButtonsWrapper
                onCancel={onCancel}
                loading={updatePrjLoading}
                isShowDeleteBtn={projectDetailsTab === ProjectTabType.General}
                onDelete={() => handleProjectArchive(projectData)}
              />
            </Box>
          )}
      </Box>
    </Formik>
  );
}

export default ProjectDetailsCardModalContent;
