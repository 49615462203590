import React from 'react';
import AvatarGenerator from 'components/AvatarGenerator';
import { Text, Box, Col, Row } from 'components/_main';
import { HelperCardProps } from '../InviteHelpers.types';
import { useTheme } from 'styled-components/macro';

function HelperCard({
  lastName,
  firstName,
  email,
  rightPart,
  isPending,
  className
}: HelperCardProps) {
  const theme = useTheme();

  return (
    <Col
      className={className}
      maxWidth="100%"
      padding="20px"
      flexCenter
      height="82px"
      gridAutoColumns="max-content 1fr max-content"
      width="100%"
      boxShadow={`0px 4px 10px ${theme.colors.shadow}`}
      backgroundColor={isPending ? `${theme.colors.lightest}` : 'transparent'}
      borderRadius="5px"
      overflow="hidden"
    >
      <Box>
        <AvatarGenerator firstName={firstName} lastName={lastName} />
      </Box>
      <Box width="100%" justifySelf="flex-start" overflow="hidden">
        <Row>
          <Text nowrap truncate textAlign="left" variant="ui-1-bold">
            {firstName} {lastName}
          </Text>
        </Row>
        <Row>
          <Text nowrap truncate variant="ui-small-regular" colorVariant="light">
            {email}
          </Text>
        </Row>
      </Box>
      {rightPart && <Box overflow="visible">{rightPart}</Box>}
    </Col>
  );
}
export default HelperCard;
