import { Box, Col, ColProps, Text } from 'components/_main';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import Icon from 'components/Icon';
import ConfirmModal from 'components/_modals/Modal/ConfirmModal';
import useOpen from 'hooks/useOpen';
import {
  CopyIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  ArrowDownIcon,
  ReflectHorizontalIcon,
  ReflectVerticalIcon,
  TrashIcon,
  PencilIcon
} from 'assets/taskingUnitToolbar';

interface Props {
  color: string;
  label?: string;
  onEdit?: () => void;
  onLabelChange?: () => void;
  onCopy?: () => void;
  onRevertHorizontalDots?: () => void;
  onRevertVerticalDots?: () => void;
  onDelete?: () => void;
  onHide?: () => void;
  onShow?: () => void;
  hidden?: boolean;
}

function Divider() {
  const theme = useTheme();
  return (
    <Box
      width="1px"
      height="16px"
      mx="10px"
      opacity={0.2}
      backgroundColor={theme.colors.lightest}
    />
  );
}

export default function AnnotationUnitToolbar({
  color,
  label,
  onCopy,
  onRevertHorizontalDots,
  onRevertVerticalDots,
  onDelete,
  onEdit,
  onHide,
  onShow,
  hidden,
  onLabelChange,
  ...restProps
}: Props & ColProps) {
  const {
    isOpen: isTaskDeleteAnnotationOpen,
    onOpen: onTaskDeleteAnnotationOpen,
    onClose: onTaskDeleteAnnotationClose
  } = useOpen();

  return (
    <Col
      id="AnnotationUnitToolbar"
      height="30px"
      borderRadius="5px"
      padding="5px 10px"
      backgroundColor={color}
      flexCenter
      {...restProps}
    >
      <Col flexCenter gridGap={0}>
        <Col
          gridGap="6px"
          flexCenter
          css="cursor: pointer"
          onClick={onLabelChange}
        >
          <Text
            variant="ui-small-regular"
            colorVariant="invert"
            fontStyle={!label ? 'italic' : 'normal'}
          >
            {label || 'No label'}
          </Text>
          {onLabelChange && <ArrowDownIcon />}
        </Col>
        {onEdit && (
          <>
            <Divider />
            <Icon onClick={onEdit}>
              <PencilIcon />
            </Icon>
          </>
        )}
        {onCopy && (
          <>
            <Divider />
            <Icon onClick={onCopy}>
              <CopyIcon />
            </Icon>
          </>
        )}
        {onRevertHorizontalDots && (
          <>
            <Divider />
            <Icon onClick={onRevertHorizontalDots}>
              <ReflectHorizontalIcon />
            </Icon>
          </>
        )}
        {onRevertVerticalDots && (
          <>
            <Divider />
            <Icon onClick={onRevertVerticalDots}>
              <ReflectVerticalIcon />
            </Icon>
          </>
        )}
        {!hidden && onHide && (
          <>
            <Divider />
            <Icon stroke="bg" onClick={onHide}>
              <EyeOpenIcon />
            </Icon>
          </>
        )}
        {hidden && onShow && (
          <>
            <Divider />
            <Icon stroke="bg" onClick={onShow}>
              <EyeCloseIcon />
            </Icon>
          </>
        )}
        {onDelete && (
          <>
            <Divider />
            <Icon onClick={onTaskDeleteAnnotationOpen}>
              <TrashIcon />
            </Icon>
          </>
        )}
      </Col>
      <ConfirmModal
        title={`
          Are you sure you want to delete annotation with all the related data (labels, timestamps etc)?`}
        isOpen={isTaskDeleteAnnotationOpen}
        onClose={onTaskDeleteAnnotationClose}
        onSubmit={onDelete}
      />
    </Col>
  );
}
