import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useCurSelUnitProps from 'containers/AnnotationSystem/hooks/useCurSelUnitProps';

export default function useOnRevertDots(customUnitId?: string | null) {
  const { annotationUnits } = useAnnotationSystemState();
  const { onInitAnnotationUnits } = useAnnotationSystemActions();

  const curSelUnitProps = useCurSelUnitProps(customUnitId);

  return (position: 'vertical' | 'horizontal') => {
    const curGroupIndex = curSelUnitProps?.groupIndex;

    const unitsWithDifferentGroupIndex = annotationUnits.filter(
      (unit) => unit.groupIndex !== curGroupIndex
    );
    const unitsWithSameGroupIndex = annotationUnits.filter(
      (unit) => unit.groupIndex === curGroupIndex
    );

    const wrapper = unitsWithSameGroupIndex.find(
      (unit) => unit.type === 'wrapper'
    );
    const centerX = wrapper ? wrapper.x + wrapper.w / 2 : 0;
    const centerY = wrapper ? wrapper.y + wrapper.h / 2 : 0;

    const newUnitsWithRevertedCoords = unitsWithSameGroupIndex.map((unit) => {
      if (unit.type === 'wrapper') return unit;
      if (position === 'vertical') {
        return {
          ...unit,
          y: centerY - (unit.y - centerY)
        };
      } else {
        return {
          ...unit,
          x: centerX - (unit.x - centerX)
        };
      }
    });

    onInitAnnotationUnits([
      ...unitsWithDifferentGroupIndex,
      ...newUnitsWithRevertedCoords
    ]);
  };
}
