import styled, { css } from 'styled-components/macro';
import React from 'react';

export const VerticalField = styled(({ className, title, children, style }) => {
  return (
    <div className={className} style={style}>
      <div className="title">{title}</div>
      <div className="content-list">
        {React.Children.toArray(children).map((child, index) => (
          <span className="content" key={index}>
            {child}
          </span>
        ))}
      </div>
    </div>
  );
})(
  ({ theme }) => css`
    display: grid;
    grid-auto-flow: row;
    gap: 0;

    & > .title {
      color: ${theme.colors.gray};
    }
    & > .content-list {
      display: grid;
      grid-auto-flow: row;

      & > .content {
        color: ${theme.colors.secondary};
        font-weight: 500;
      }
    }
  `
);
