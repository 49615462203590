import React from 'react';
import { Text, Row, Link } from 'components/_main';
import { useField } from 'formik';
import { trimInstructionName } from 'utils';

function ClientInstructions() {
  const [filesWithInstructionsField] = useField<string[]>(
    'clientInstructionUrl'
  );
  const [textInstructionField] = useField<string>('clientShortInstruction');

  const textInstruction = textInstructionField?.value;
  const fileInstructions = filesWithInstructionsField?.value;

  return (
    <Row wide gridGap="12px">
      {(textInstruction || fileInstructions?.length > 0) && (
        <Text variant="ui-small-bold" upper>
          Instructions
        </Text>
      )}
      {textInstruction && <Text>{textInstruction}</Text>}
      {fileInstructions?.map((link) => (
        <Link href={link}>{trimInstructionName(link)}</Link>
      ))}
    </Row>
  );
}

export default ClientInstructions;
