import { CurrentUser, Roles, LoginResponse } from 'appTypes';
import { currentRole } from './role';
import {
  CUR_USER_SESSION_INIT_DATA,
  TOKEN,
  USER_SESSION_DATA
} from 'appConstants';
import {
  Admin,
  Client,
  Helper,
  LoginResponseAdmin,
  LoginResponseClient,
  LoginResponseHelper
} from 'generated/graphql';
import consoleError from './consoleError';
import { varEnv } from 'utils/envVars';

export function parseLoggedInData(data: LoginResponse, role: Roles) {
  const { token } = data;
  if (token) {
    updateTokenStorage(token);
  }

  if (role === Roles.ADMIN) {
    return (data as LoginResponseAdmin)['admin'];
  }

  if (role === Roles.CLIENT) {
    return (data as LoginResponseClient)['client'];
  }

  if (role === Roles.HELPER) {
    return (data as LoginResponseHelper)['helper'];
  }

  consoleError({ message: 'No valid role', role });

  return {};
}

export function updateUserDataStorage(currentUser: Client | Helper | Admin) {
  const curUserStorage = { currentUser };
  localStorage.setItem(USER_SESSION_DATA, JSON.stringify(curUserStorage));
  sessionStorage.setItem(USER_SESSION_DATA, JSON.stringify(curUserStorage));
}

export function updateTokenStorage(token: string) {
  localStorage.setItem(TOKEN, token);
  sessionStorage.setItem(TOKEN, token);
}

export function getTokenStorage() {
  if (varEnv.isSb) {
    return localStorage.getItem(TOKEN) ?? sessionStorage.getItem(TOKEN);
  }

  return localStorage.getItem(TOKEN);
}

export function getUserDataStorage() {
  const data = varEnv.isSb
    ? localStorage.getItem(USER_SESSION_DATA) ??
      sessionStorage.getItem(USER_SESSION_DATA)
    : localStorage.getItem(USER_SESSION_DATA);
  if (data) return JSON.parse(data)?.currentUser;
  return null;
}

export function parseAuthSuccessResp(key: string, data: any) {
  let userData: CurrentUser = CUR_USER_SESSION_INIT_DATA;
  switch (currentRole()) {
    case Roles.ADMIN:
      // @ts-ignore
      userData = parseLoggedInData(data?.[`${key}Admin`], Roles.ADMIN);
      break;
    case Roles.CLIENT:
      // @ts-ignore
      userData = parseLoggedInData(data?.[`${key}Client`], Roles.CLIENT);
      break;
    case Roles.HELPER:
      // @ts-ignore
      userData = parseLoggedInData(data?.[`${key}Helper`], Roles.HELPER);
      break;
  }

  return userData;
}
