import React from 'react';
import { ModalForm } from 'components/_modals';
import { Form } from 'antd';
import { TData } from '../types';
import { useCreateClientMutation } from 'generated/graphql';
import { toast } from 'utils/toast';
import { titleRules } from 'utils/finalFormValidationHelpers';
import { TRefetchQueries } from 'appTypes';
import { initialValues } from '../constants';
import AntdFormItemSwitch from 'components/AntdFormItemSwitch';
import AntdFormItemInputEmail from 'components/AntdFormItemInputEmail';
import AntdFormItemInput from 'components/AntdFormItemInput';

const ModalCreate: React.FC<{
  curData?: TData;
  isOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  refetchQueries: TRefetchQueries;
}> = ({ isOpen, setModalOpen, refetchQueries }) => {
  const [formHook] = Form.useForm<TData>();

  const handleOnClose = () => {
    setModalOpen(false);
    formHook.resetFields();
  };

  const [createMutation, { loading }] = useCreateClientMutation({
    onCompleted: () => {
      toast.success('Client successfully created');
      handleOnClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: () => refetchQueries
  });

  return (
    <ModalForm<TData>
      title={'Add client'}
      form={formHook}
      isVisible={isOpen}
      initialValues={initialValues}
      onOk={() => {
        const values: TData = formHook.getFieldsValue();
        createMutation({ variables: values });
      }}
      onCancel={() => {
        handleOnClose();
      }}
      loading={loading}
      layout={'vertical'}
    >
      <AntdFormItemInputEmail label={'Email'} name={'email'} />
      <AntdFormItemInput
        label={'First name'}
        name={'firstName'}
        rules={[...titleRules]}
      />
      <AntdFormItemInput
        label={'Last name'}
        name={'lastName'}
        rules={[...titleRules]}
      />
      <AntdFormItemInput
        label={'Company name'}
        name={'companyName'}
        rules={[...titleRules]}
      />
      <AntdFormItemSwitch
        validateStatus={''}
        label={'Send an invite'}
        name={'sendInvite'}
      />
    </ModalForm>
  );
};

export default ModalCreate;
