import { useCallback } from 'react';
import { isAdmin, isClient } from 'utils';
import {
  Project,
  useProjectGenerateCocoReportLazyQuery,
  useProjectGenerateYoloReportLazyQuery,
  useProjectResultsLazyQuery
} from 'generated/graphql';
import { ProjectDetails } from 'appTypes';
import useDownloadProjectsJsonResults from 'hooks/useDownloadProjectsJsonResults';
import { toast } from 'utils/toast';
import useGetUserSessionData from 'hooks/useGetUserSessionData';

const useDownloadReport = (data: Project) => {
  const userSessionData = useGetUserSessionData();
  const [onDownloadProjectJsonReport, jsonReportLoading] =
    useDownloadProjectsJsonResults();

  const [
    projectGenerateCocoReport,
    { loading: projectGenerateCocoReportLoading }
  ] = useProjectGenerateCocoReportLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: () => {
      toast.success(
        'COCO report generated success. Please, check your email. '
      );
    }
  });

  const [
    projectGenerateYoloReport,
    { loading: projectGenerateYoloReportLoading }
  ] = useProjectGenerateYoloReportLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: () => {
      toast.success(
        'YOLO report generated success. Please, check your email. '
      );
    }
  });

  const [
    projectResultsToGoogleDrive,
    { loading: googleDriveProjectResultLoading }
  ] = useProjectResultsLazyQuery({
    fetchPolicy: 'network-only',
    onError: (error) => {
      toast.error(error.message);
    }
  });

  const handleGenerateCocoReport = useCallback(async () => {
    if (isClient()) {
      await projectGenerateCocoReport({
        variables: {
          clientId: parseInt(userSessionData?.id, 10),
          projectId: data.id
        }
      });
      return;
    }
    if (isAdmin() && data.client?.id) {
      await projectGenerateCocoReport({
        variables: {
          clientId: parseInt(data.client?.id, 10),
          projectId: data.id
        }
      });
      return;
    }
  }, [data, userSessionData, projectGenerateCocoReport]);

  const handleGenerateYoloReport = useCallback(async () => {
    if (isClient()) {
      await projectGenerateYoloReport({
        variables: {
          clientId: parseInt(userSessionData?.id, 10),
          projectId: data.id
        }
      });
      return;
    }
    if (isAdmin() && data.client?.id) {
      await projectGenerateYoloReport({
        variables: {
          clientId: parseInt(data.client?.id, 10),
          projectId: data.id
        }
      });
      return;
    }
  }, [data, userSessionData, projectGenerateYoloReport]);

  const handleProjectResultsDownload = useCallback(async () => {
    await onDownloadProjectJsonReport(data);
  }, [data, onDownloadProjectJsonReport]);

  const handleProjectResultsUploadToGoogleDrive = useCallback(
    async (data: ProjectDetails) => {
      await projectResultsToGoogleDrive({
        variables: {
          uploadToGDrive: true,
          projectId: data.id
        },
        onCompleted: () => {
          toast.success(
            `Results for project with ID ${data.id} downloaded successfully`
          );
        }
      });
    },
    [projectResultsToGoogleDrive]
  );

  const isLoading =
    googleDriveProjectResultLoading ||
    jsonReportLoading ||
    projectGenerateCocoReportLoading ||
    projectGenerateYoloReportLoading;

  return {
    handleGenerateCocoReport,
    handleGenerateYoloReport,
    handleProjectResultsDownload,
    handleProjectResultsUploadToGoogleDrive,
    isLoading
  };
};

export default useDownloadReport;
