import * as Sentry from '@sentry/react';
import getStorageData from './getStorageData';
import consoleError from './consoleError';

export function sentryException(error: any) {
  Sentry.withScope((scope) => {
    scope.setExtra('additional data', JSON.stringify(error));
    scope.setExtra('local storage', getStorageData(localStorage));
    scope.setExtra('session storage', getStorageData(sessionStorage));
    Sentry.captureException(error);
  });
}

export default consoleError;
