import React from 'react';
import CautionPlate from 'components/CautionPlate';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { isClient } from 'utils';

function StepUploadDatasetRestriction() {
  const { isPremium } = useGetUserSessionData();
  const showBanner = isClient() && !isPremium;

  return (
    <>
      {showBanner && (
        <CautionPlate
          size="big"
          contentText="You can upload dataset up to 500Mb. If you need more, please upgrade your plan in your profile."
        />
      )}
    </>
  );
}

export default StepUploadDatasetRestriction;
