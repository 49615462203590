import theme from 'theme';

export const CARD_COLORS = [
  theme.colors.yellow,
  theme.colors.blue,
  theme.colors.purple,
  theme.colors.redLight,
  theme.colors.primary,
  theme.colors.orange,
  theme.colors.light
];
