import {
  InviteHelpersContextHookReturnProps,
  InviteHelpersContextProps
} from './InviteHelpers.types';

export const INVITE_HELPER_CONTEXT_HOOK_RETURN_INIT: InviteHelpersContextHookReturnProps =
  {
    setIsRequestCustomHelpers: () => null,
    selectedHelpers: [],
    isAllowToInviteMore: false,
    isAllowToInviteMoreCustomCounter: () => false,
    setSelectedHelpers: () => null
  };

export const INVITE_HELPER_CONTEXT_INIT: InviteHelpersContextProps = {
  ...INVITE_HELPER_CONTEXT_HOOK_RETURN_INIT,
  isPremium: false,
  helpers: [],
  customMessageMeta: {
    error: undefined,
    touched: false
  },
  onCustomMessageUpdate: () => null
};

export const TRANSITION_GROUP_WRAPPER_ID = 'transition-group';
export const ANIMATION_TRANS_TIME = 500;
