import { useCallback, useMemo, useRef } from 'react';
import getSvgMouseRelPos from 'utils/getSvgMouseRelPos';
import { INIT_STATE_REF } from './useMousePositionRef.constants';
import {
  MousePosOnStart,
  MousePosOnUpdate,
  MousePosStateRef
} from './useMousePositionRef.types';

function useMousePositionRef() {
  const stateRef = useRef<MousePosStateRef>(INIT_STATE_REF);

  const onStart = useCallback(
    ({ event, initPos, onUpdate }: MousePosOnStart) => {
      const { relPosX, relPosY } = getSvgMouseRelPos(event);

      stateRef.current = {
        x: initPos?.x ?? relPosX,
        y: initPos?.y ?? relPosY,
        dX: initPos?.w ?? 0,
        dY: initPos?.y ?? 0,
        initX: relPosX,
        initY: relPosY
      };

      onUpdate(stateRef);
    },
    []
  );

  const onUpdate = useCallback(({ event, onUpdate }: MousePosOnUpdate) => {
    const { relPosX, relPosY } = getSvgMouseRelPos(event);

    stateRef.current = {
      ...stateRef.current,
      dX: relPosX - stateRef.current.initX,
      dY: relPosY - stateRef.current.initY,
      y: relPosY,
      x: relPosX
    };

    onUpdate(stateRef);
  }, []);

  const onReset = useCallback(() => {
    stateRef.current = { ...INIT_STATE_REF };
  }, []);

  const onResetRelPos = useCallback(() => {
    stateRef.current = {
      ...stateRef.current,
      x: 0,
      y: 0
    };
  }, []);

  return useMemo(
    () => ({
      onUpdate,
      onResetRelPos,
      onReset,
      onStart,
      stateRef
    }),
    [stateRef, onReset, onResetRelPos, onStart, onUpdate]
  );
}

export default useMousePositionRef;
