import React from 'react';
import Box from './Box';
import stringOrNumberToCssValue from '../../utils/stringOrNumberToCssValue';

export interface SpacerProps {
  size?: number | string;
}

function Spacer({ size = '1rem' }: SpacerProps) {
  return <Box height={stringOrNumberToCssValue(size)} />;
}

export default Spacer;
