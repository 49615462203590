import styled from 'styled-components/macro';

export const Root = styled.div`
  & .ant-picker {
    min-width: 150px;
    border-radius: 3px;
    border: solid 1px ${({ theme }) => theme.colors.gray2};
    padding: 9px 8px 7px 15px;
    width: 100%;

    &:hover,
    &.ant-picker-focused {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
