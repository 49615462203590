import { Button } from 'components/_main';
import React from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { SubmitButtonProps } from './ModalForm.types';
import SubmitConfirm from './SubmitConfirm';

const SubmitButton = ({
  okText,
  loading,
  onConfirmOpen,
  withSubmitConfirm
}: SubmitButtonProps) => {
  const form = useFormInstance();
  const isDisabled = !form.isFieldsTouched(false);

  if (withSubmitConfirm) {
    return (
      <SubmitConfirm
        isDisabled={isDisabled}
        onConfirmOpen={onConfirmOpen}
        okText={okText}
        loading={loading}
      />
    );
  }

  return (
    <Button variant="primary" isLoading={loading} htmlType="submit">
      {okText}
    </Button>
  );
};

export default SubmitButton;
