import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface QuerySlugs {
  taskId: string;
  projectId: string;
  topicId: string;
  lessonId: string;
}

const useGetSlug = (): any => {
  const { taskId, projectId, topicId, lessonId }: any = useParams();
  const [data, setData] = useState<QuerySlugs>({
    taskId,
    projectId,
    topicId,
    lessonId
  });

  useEffect(
    () => setData({ taskId, projectId, topicId, lessonId }),
    [taskId, projectId, topicId, lessonId]
  );

  return data;
};

export default useGetSlug;
