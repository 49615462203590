import { Box } from 'components/_main';
import { Modal, ModalProps } from 'components/_modals';
import { ReactElement, MouseEvent } from 'react';
import useOpen from './useOpen';

interface Props {
  modalContent: ReactElement;
  modalProps?: Partial<ModalProps>;
}

export default function useToggleModal({ modalProps, modalContent }: Props) {
  const { onClose, onOpen, isOpen } = useOpen({
    onOpen: (event?: MouseEvent) => {
      event?.preventDefault();
      event?.stopPropagation();
    }
  });

  return {
    modalComponent: (
      <Modal
        width="900px"
        onCancel={onClose}
        cancelText="Close"
        destroyOnClose
        {...modalProps}
        isVisible={isOpen}
      >
        <Box>{modalContent}</Box>
      </Modal>
    ),
    on: onOpen,
    off: onClose
  };
}
