import { useCallback, useEffect } from 'react';
import { consoleError } from 'utils';
import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum
} from '../AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../context';

interface Props {
  onMouseDown?: () => void;
}

export default function useSvgLayerMouseDown({ onMouseDown }: Props) {
  const { svgLayerRef, curEvent } = useAnnotationSystemState();
  const { onSetCurSelUnitId, onSetCurMode, onSetCurEvent } =
    useAnnotationSystemActions();

  const handleSvgLayerMouseDown = useCallback(
    (event: MouseEvent) => {
      if (event?.target !== svgLayerRef) {
        return;
      }

      onMouseDown && onMouseDown();

      switch (curEvent) {
        case AnnotationSystemEventsEnum.CREATE_MODE:
        case AnnotationSystemEventsEnum.CREATING_LABEL:
        case AnnotationSystemEventsEnum.CREATING_SIZE:
        case AnnotationSystemEventsEnum.ZOOM_NAVIGATION:
          break;
        default:
          onSetCurEvent(null);
          onSetCurMode(AnnotationSystemModesEnum.UNIT_SELECT);
          onSetCurSelUnitId(null);
      }
    },
    [
      curEvent,
      onMouseDown,
      onSetCurEvent,
      onSetCurMode,
      onSetCurSelUnitId,
      svgLayerRef
    ]
  );

  useEffect(() => {
    if (!svgLayerRef) {
      return;
    }

    svgLayerRef.addEventListener('mousedown', handleSvgLayerMouseDown);

    return () => {
      svgLayerRef.removeEventListener('mousedown', handleSvgLayerMouseDown);
    };
  }, [handleSvgLayerMouseDown, svgLayerRef]);
}
