import Box from '../Box';
import React, { forwardRef } from 'react';
import { RowProps } from './Row.types';
import styled from 'styled-components/macro';
import { horizCenter, widthWide } from '../Box/mixins';
import { scrollStyled } from 'theme';

const Row = styled(
  forwardRef<HTMLDivElement, RowProps>(
    ({ colMaxContent, ...restProps }, ref) => (
      <Box
        ref={ref}
        display="grid"
        gridAutoFlow="row"
        height="max-content"
        gridAutoRows="max-content"
        gridAutoColumns={colMaxContent ? 'max-content' : '100%'}
        alignItems={'center'}
        {...restProps}
      />
    )
  )
)(widthWide('100%'), horizCenter(true), scrollStyled);

export default Row;
