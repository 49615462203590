import React from 'react';
import { Box } from 'components/_main';
import { useScript } from 'hooks/useScript';

interface AnimatedIconProps {
  bgColor: string;
}

const getHtml = (bgColor: string) => `
  <svg-animate trigger="on">
    <svg viewBox="0 0 276 285" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%" anim:duration="0.5" anim:ease="power1.inOut">
      <g id="b89832ebe750bd3ef79b9c2e98daaf73">
        <circle id="605180087a61a56bf2021168fe477616" cx="138" cy="146" r="138" fill=${
          bgColor ?? '#F7F8F8'
        }></circle>
        <g id="c5c0eaef3de04cdc821f2474bfd91cb5">
          <mask id="3758f71ac3c77f1d05766d3d86107db4" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="9" width="276" height="276">
            <circle id="57e3cb7f8db58b784b1bc33d98cdb61e" cx="138" cy="147" r="138" fill="white"></circle>
          </mask>
          <g mask="url(#3758f71ac3c77f1d05766d3d86107db4)">
            <ellipse id="c71d2160a56007ea4f12c719f3c8feac" cx="138" cy="330.5" rx="104" ry="121.5" fill="#4BC3B5" anim="" anim:y="20|0" anim:delay="0.2" anim:duration="0.7"></ellipse>
          </g>
        </g>
        <g id="c6f137c73e3b0faf704b320a1adb559b" anim="" anim:y="30|0" anim:delay="0.5" anim:duration="0.5">
          <rect id="46834ade080ab148a5fa5cbc955930dd" x="30" y="121" width="216" height="56" rx="5" fill="#4BC3B5"></rect>
          <rect id="9fc2c4847058413c1c2c30d78af76bd7" x="39" y="110" width="199" height="79" rx="5" fill="#48BAAD"></rect>
          <rect id="03142c21b98655e380d05ac6481543a9" x="50" y="104" width="173" height="90" rx="5" fill="#4BC3B5"></rect>
          <path id="59d610ce35c3d77d2de84df8a85b193f" d="M53 96.2796C53 90.6025 56.9978 85.6868 62.5666 84.5836C120.286 73.1486 154.962 73.1388 211.485 84.554C217.031 85.674 221 90.5788 221 96.2362V203.264C221 208.953 217.02 213.836 211.408 214.763C197.216 217.106 168.847 221 137 221C105.153 221 76.7839 217.106 62.5924 214.763C56.9797 213.836 53 208.953 53 203.264V96.2796Z" fill="#48BAAD"></path>
          <path id="95778fe48825cdb91c6e6f937b936733" d="M67 112.773C67 106.979 71.1429 102 76.8541 101.027C123.989 93 153.028 92.991 199.193 101C204.884 101.987 209 106.958 209 112.734V186.003C209 191.789 204.883 196.727 199.156 197.544C186.678 199.326 163.676 202 138 202C112.324 202 89.3223 199.326 76.8441 197.544C71.1171 196.727 67 191.789 67 186.003V112.773Z" fill="#4BC3B5"></path>
          <path id="8b7aafd8c5f04343895390c98b472325" d="M70 114.992C70 109.19 74.1489 104.206 79.8709 103.246C124.611 95.7388 152.351 95.7296 196.171 103.219C201.873 104.193 206 109.169 206 114.954V183.717C206 189.472 201.917 194.394 196.224 195.232C184.196 197.002 162.355 199.609 138 199.609C113.645 199.609 91.8037 197.002 79.7762 195.232C74.0829 194.394 70 189.472 70 183.717V114.992Z" fill="white"></path>
          <path id="1b69ffd02dc60a7bd67c44550491e8b4" d="M128 167H148V167C148 172.523 143.523 177 138 177V177C132.477 177 128 172.523 128 167V167Z" fill="#373838" anim="" anim:opacity="0|1" anim:delay="0.8" anim:duration="0.2"></path>
          <g id="051c65ff299f2028a974ad09692f22f0" anim="" anim:opacity="0|1" anim:y="10|0" anim:scale="0|1" anim:delay="0.4" anim:duration="0.5">
            <circle id="9c3b7389fd68425750fdddabff144e94" cx="95" cy="149" r="9" fill="#373838"></circle>
            <circle id="340ac117a00a1fc51bc21dc81191ec2a" cx="92.5" cy="150.5" r="2.5" fill="white"></circle>
          </g>
          <g id="6249cee9224871d0ce0677b8a9eb9975" anim="" anim:opacity="0|1" anim:y="5|0" anim:scale="0|1" anim:delay="0.4" anim:duration="0.5">
            <circle id="8a779c53b5724fdae20c124f6354e893" cx="178" cy="149" r="9" fill="#373838"></circle>
            <circle id="76399155c71a72346f39a3ddb67701f6" cx="175.5" cy="150.5" r="2.5" fill="white"></circle>
          </g>
          <g id="669ff66cfbe27a7f687cb42e4cf20e09" anim="" anim:y="30|0" anim:delay="0.2" anim:duration="0.5">
            <line id="9f2e9a3b2b4c39da75a7f255e6efe576" x1="38" y1="9" x2="38" y2="127" stroke="#4BC3B5" stroke-width="2"></line>
            <circle id="bd3722304bae94e314f900ccec016d0e" cx="38" cy="5" r="5" fill="#4BC3B5"></circle>
          </g>
        </g>
      </g>
    </svg>
  </svg-animate>
`;

function AnimatedIcon({ bgColor }: AnimatedIconProps) {
  useScript(
    'https://cdn.jsdelivr.net/npm/@figmania/svg-animate/lib/index.min.js'
  );

  return (
    <Box
      width="30vh"
      height="30vh"
      minWidth="100px"
      minHeight="100px"
      maxWidth="350px"
      maxHeight="350px"
    >
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: getHtml(bgColor) }}
      ></div>
    </Box>
  );
}

export default AnimatedIcon;
