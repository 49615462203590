import { HistoryActions } from './types';

export const taskHistoryFormat = 'YYYY-MM-DD HH:mm:ss';

export const historyActionMessages: Record<HistoryActions, string> = {
  RETURN: 'Returned by',
  IN_AUDIT: 'In audit by',
  START: 'In progress by',
  SKIP: 'Skipped by',
  SUBMIT: 'Submitted by',
  CREATE: 'Task created'
};
