import { DatePicker as DatePickerBase } from 'antd';
import React from 'react';
import { Root } from './DatePicker.styled';
import { DatePickerProps } from 'antd/es/date-picker';

const DataPicker: React.FC<DatePickerProps & {}> = ({ ...props }) => {
  return (
    <Root>
      <DatePickerBase {...props} />
    </Root>
  );
};

export default DataPicker;
