import { Tooltip } from 'antd';
import { Box, Col, Text } from 'components/_main';
import React from 'react';
import { css } from 'styled-components/macro';
import { ProjectProgressProps } from './ProjectProgress.types';
import { ProgressStyled, StyledNumber } from './ProjectProgress.ui';
import { getTasksCount } from 'utils/getTasksCount';
import { ProjectStatus } from 'generated/graphql';

function ProjectProgress({
  data,
  inPercents,
  isVertical,
  showDetails,
  showAbsNumbersBesideProgressBar,
  showProgressBar,
  showDetailsWithTooltip
}: ProjectProgressProps) {
  const {
    completedTasksCount,
    inAuditTasksCount,
    newTasksCount,
    readyForAuditTasksCount,
    rejectedTasksCount,
    startedTasksCount,
    totalTasksCount
  } = getTasksCount(data);

  const tasksProcessed = completedTasksCount + rejectedTasksCount;
  const newTasks = newTasksCount + startedTasksCount;
  const readyForAudit = readyForAuditTasksCount + inAuditTasksCount;
  const percent = Math.floor((tasksProcessed * 100) / totalTasksCount);
  const isCompleted =
    data.status === ProjectStatus.Completed ||
    data.status === ProjectStatus.Archived;

  if (!inPercents) {
    return (
      <Box width="100%">
        {showDetailsWithTooltip && (
          <Tooltip
            title={`New: ${newTasks} / Ready for audit: ${readyForAudit}`}
          >
            <StyledNumber
              style={{
                ...(isVertical && {
                  width: '100%',
                  textAlign: 'center'
                })
              }}
            >
              {newTasks}/{readyForAudit}
            </StyledNumber>
          </Tooltip>
        )}
        {showDetails && (
          <Col>
            <StyledNumber
              style={{
                fontWeight: 'bold'
              }}
            >
              <span>New/Audit:</span> {newTasks}/
              <span
                style={{
                  color: 'gray'
                }}
              >
                {readyForAudit}
              </span>
            </StyledNumber>
          </Col>
        )}
        {showProgressBar && !isCompleted && (
          <Box
            grid
            wide
            css={`
              ${isVertical
                ? css`
                    gap: 0;
                    grid-auto-flow: row;
                  `
                : css`
                    grid-auto-flow: column;
                    ${showAbsNumbersBesideProgressBar &&
                    css`
                      grid-auto-columns: 1fr max-content;
                    `}
                  `}
            `}
          >
            <Box minWidth="100px" width="100%" height="max-content">
              <ProgressStyled format={() => ''} percent={percent} />
            </Box>
            {showAbsNumbersBesideProgressBar && (
              <StyledNumber
                css={`
                  ${isVertical &&
                  css`
                    width: 100%;
                    text-align: left;
                    grid-row: 1/2;
                  `}
                `}
              >
                <Tooltip
                  title={`Tasks processed: ${tasksProcessed} / Total: ${totalTasksCount}`}
                >
                  <Text>
                    {tasksProcessed}/{totalTasksCount}
                  </Text>
                </Tooltip>
              </StyledNumber>
            )}
          </Box>
        )}
      </Box>
    );
  }

  if (isCompleted) return null;

  return <ProgressStyled percent={percent} />;
}

export default ProjectProgress;
