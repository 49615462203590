import { Box, Row, Text } from 'components/_main';
import {
  projectProgressColumn,
  projectStatusColumn
} from 'components/Table/_columnRender';
import { formatDate } from 'utils/_dateUtilsLegacy';
import React from 'react';
import { PROJECT_TYPES } from 'appConstants';
import ProjectStatusRenderFormikWrapper from './ProjectStatusRenderFormikWrapper';
import { useTheme } from 'styled-components/macro';
import { Project, ProjectStatus, TaskType } from 'generated/graphql';
import { useCtx } from '../ProjectDetailsCard.Context';
import useGetIsStatusEditable from 'containers/ProjectDetailsCard/hooks/useGetIsEditableStatus';

interface Props {
  projectData: Project;
}

const LeftPanel = ({ projectData }: Props) => {
  const theme = useTheme();
  const { isEditable } = useCtx();
  const { status } = projectData;
  const isClosed =
    status === ProjectStatus.Archived || status === ProjectStatus.Completed;
  const { iconComponent: IconComponent, label } =
    PROJECT_TYPES.find((type) => type.value === projectData.type) ?? {};
  const isWithProgress =
    !isEditable && !isClosed && projectData.type !== TaskType.Nlp;
  const isEditableStatus = useGetIsStatusEditable(projectData, isEditable);

  return (
    <Row
      py="30px"
      px="40px"
      height="100%"
      borderRight={`1px solid ${theme.colors.lightest}`}
      gridGap="30px"
    >
      <Row gridGap="12px">
        <Text variant="ui-small-2-bold">SPECIALIZATION</Text>
        {IconComponent && (
          <Box
            borderRadius="6px"
            flexCenter
            display="grid"
            boxShadow={`0px 4px 10px ${theme.colors.shadow}`}
            width={120}
            height={120}
          >
            <Row flexCenter gridGap="10px">
              <Box
                width={40}
                height={40}
                css={`
                  path,
                  rect {
                    stroke: ${theme.colors.primary};
                  }
                `}
              >
                <IconComponent />
              </Box>
              <Text
                variant="ui-small-regular"
                textAlign="center"
                maxWidth="85px"
              >
                {label}
              </Text>
            </Row>
          </Box>
        )}
      </Row>
      <Row gridGap="12px">
        <Text variant="ui-small-2-bold">STATUS</Text>
        {isEditableStatus ? (
          <ProjectStatusRenderFormikWrapper />
        ) : (
          projectStatusColumn(projectData)
        )}
      </Row>
      {isWithProgress && (
        <Row gridGap="12px" maxWidth="120px">
          <Text variant="ui-small-2-bold">PROGRESS</Text>
          <Box>{projectProgressColumn()(projectData)}</Box>
        </Row>
      )}
      <Row gridGap="12px">
        <Text variant="ui-small-2-bold">CREATED AT</Text>
        <Text>{formatDate(projectData.createdAt)}</Text>
      </Row>
    </Row>
  );
};

export default LeftPanel;
