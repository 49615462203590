import React, { createContext, useContext, useState } from 'react';
import { ProjectDetails } from 'appTypes';
import { EMPTY_PROJECT_DATA } from 'containers/Projects/constants';

interface ProjectsDataContextState {
  currentProjectData: ProjectDetails;
  setCurrentProjectData(data: ProjectDetails): void;
}

const projectsDataContext = createContext<ProjectsDataContextState>({
  currentProjectData: EMPTY_PROJECT_DATA,
  setCurrentProjectData: () => {}
});

export function ProjectsContextProvider({
  children
}: React.PropsWithChildren<{}>) {
  const [currentProjectData, setCurrentProjectData] =
    useState<ProjectDetails>(EMPTY_PROJECT_DATA);

  return (
    <projectsDataContext.Provider
      value={{
        currentProjectData,
        setCurrentProjectData
      }}
    >
      {children}
    </projectsDataContext.Provider>
  );
}

export function useProjectsContext() {
  return useContext(projectsDataContext);
}
