import { createContext, useMemo, useContext } from 'react';
import { INVITE_HELPER_CONTEXT_INIT } from './InviteHelpers.constants';
import {
  InviteHelpersContextHookProps,
  InviteHelpersContextInitialProps,
  InviteHelpersContextProps
} from './InviteHelpers.types';
import useInviteHelpers from './useInviteHelpers';

const inviteHelpersContext = createContext<InviteHelpersContextProps>(
  INVITE_HELPER_CONTEXT_INIT
);

export function InviteHelpersContextProvider({
  children,
  ...contextValues
}: React.PropsWithChildren<InviteHelpersContextInitialProps>) {
  const hookInput: InviteHelpersContextHookProps = { ...contextValues };

  const hookValues = useInviteHelpers(hookInput);

  const values: InviteHelpersContextProps = {
    ...contextValues,
    ...hookValues,
    allowAddTeammates: contextValues?.allowAddTeammates ?? true,
    allowRemoveHelpers: contextValues?.allowRemoveHelpers ?? true
  };

  return (
    <inviteHelpersContext.Provider value={values}>
      {children}
    </inviteHelpersContext.Provider>
  );
}

export function useInviteHelpersContext(): InviteHelpersContextProps {
  return useContext(inviteHelpersContext);
}
