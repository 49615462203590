import { useGetHelperByIdLazyQuery } from 'generated/graphql';
import React, { useEffect } from 'react';

export default function ProvideHelperData({
  children,
  helperId
}: {
  children: React.ReactElement<{
    firstName: string;
    lastName: string;
    email: string;
    loading: boolean;
  }>;
  helperId: number;
}) {
  const [getHelperData, { data: helperData, loading }] =
    useGetHelperByIdLazyQuery();

  useEffect(() => {
    if (helperId) {
      getHelperData({
        variables: {
          helperId
        }
      });
    }
  }, [getHelperData, helperId]);

  return React.cloneElement(children, { ...helperData?.helper, loading });
}
