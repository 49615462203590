import useGetSlug from 'hooks/useGetSlug';
import { useEffect, useMemo } from 'react';
import { MappedListProjects } from 'appTypes';
import useTablePage from 'hooks/useTablePage';
import { PROJECTS_LIST_ALL_PAGE, PROJECTS_LIST_ALL_SORT } from 'appConstants';
import useTableSortMethod from 'hooks/useTableSortMethod';
import { SortByDateOrder, useListProjectsLazyQuery } from 'generated/graphql';
import { formatListProjects } from 'utils';
import { NetworkStatus } from '@apollo/client';

interface ProjectsProps {
  listProjects: MappedListProjects[];
  page?: number;
  pagesCount?: number;
  sortMethod?: SortByDateOrder;
  projectListLoading: boolean;
  setPage(page: number): void;
  refetchListProjects(): void;
}

const useGetListProject = (): ProjectsProps => {
  const { taskId, projectId } = useGetSlug();
  const { page, setPage } = useTablePage(PROJECTS_LIST_ALL_PAGE);

  const { sortMethod } = useTableSortMethod(PROJECTS_LIST_ALL_SORT);

  const [
    listProjectsQuery,
    {
      loading,
      data: listProjectsData,
      networkStatus,
      refetch: refetchListProjects
    }
  ] = useListProjectsLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      page,
      sortByDateOrder: sortMethod
    }
  });

  const pagesCount = listProjectsData?.listProjects?.pagesCount;
  const projectListLoading =
    loading ||
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.poll;

  const listProjects: MappedListProjects[] | [] = useMemo(() => {
    const projects = listProjectsData?.listProjects?.projects;
    if (!projects) return [];
    return formatListProjects([...projects]);
  }, [listProjectsData]);

  useEffect(() => {
    if (!taskId && !projectId) {
      listProjectsQuery();
    }
  }, [listProjectsQuery, projectId, taskId]);

  return {
    listProjects,
    page,
    pagesCount,
    sortMethod,
    setPage,
    projectListLoading,
    refetchListProjects
  };
};

export default useGetListProject;
