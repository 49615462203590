import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { InvitedHelper } from 'appTypes';
import AntdCheckbox from 'components/AntdCheckbox';
import CautionPlate from 'components/CautionPlate';
import { Box, Col } from 'components/_main';
import { Footer as ModalFooter, ModalProps } from 'components/_modals';
import React, { useState } from 'react';
import { stateArrayAdd, stateArrayRemove } from 'utils';
import { useInviteHelpersContext } from '../InviteHelpers.context';
import HelperCard from './HelperCard';

function InviteHelpersModalBody({ onCancel }: Pick<ModalProps, 'onCancel'>) {
  const {
    selectedHelpers,
    helpers,
    setSelectedHelpers,
    isAllowToInviteMoreCustomCounter
  } = useInviteHelpersContext();

  const unselectedHelpers = helpers.filter(
    (e) => !selectedHelpers.some((x) => x.email === e.email)
  );

  const [newHelpers, setNewHelpers] = useState<InvitedHelper[]>([]);

  const isAllowToInviteMore = isAllowToInviteMoreCustomCounter(
    selectedHelpers?.length + newHelpers?.length - 1
  );

  const handleCheckboxChange =
    (thisHelper: InvitedHelper) => (e: CheckboxChangeEvent) => {
      const checked = e.target.checked;
      if (!checked) {
        setNewHelpers(stateArrayRemove(thisHelper, 'email'));
        return;
      }

      setNewHelpers(stateArrayAdd(thisHelper));
    };

  const onOk = () => {
    setSelectedHelpers([...selectedHelpers, ...newHelpers]);
    onCancel && onCancel();
  };

  return (
    <>
      {!isAllowToInviteMore && (
        <Col width="100%" flexCenter>
          <CautionPlate contentText="Maximum ammount of helpers already added to the project" />
        </Col>
      )}
      <Box
        p="1rem"
        grid
        gridAutoFlow="row"
        gridTemplateColumns="1fr "
        maxWidth="600px"
        height="100%"
        maxHeight="680px"
        overflowY="auto"
      >
        {unselectedHelpers &&
          unselectedHelpers.map((helper) => {
            return (
              <HelperCard
                key={helper.email}
                lastName={helper.lastName}
                firstName={helper.firstName}
                email={helper.email}
                rightPart={
                  <AntdCheckbox onChange={handleCheckboxChange(helper)} />
                }
              />
            );
          })}
      </Box>
      <ModalFooter
        onCancel={onCancel}
        onOk={onOk}
        isOkDisabled={!isAllowToInviteMore || newHelpers?.length === 0}
        okText={
          newHelpers?.length > 0
            ? `Add ${newHelpers?.length} helper(s)`
            : `Add helpers`
        }
      />
    </>
  );
}

export default InviteHelpersModalBody;
