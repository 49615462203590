import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../context';
import useGetSelUnitProps from './useCurSelUnitProps';

export default function useAnnotationDotsGroupDelete() {
  const { onInitAnnotationUnits } = useAnnotationSystemActions();
  const { annotationUnits } = useAnnotationSystemState();
  const curSelUnitProps = useGetSelUnitProps();
  const handleOnDelete = () => {
    onInitAnnotationUnits([
      ...annotationUnits.filter(
        (unit) => unit.groupIndex !== curSelUnitProps?.groupIndex
      )
    ]);
  };

  return handleOnDelete;
}
