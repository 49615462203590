import React, { useLayoutEffect, useRef, useState } from 'react';
import { Project, TaskType } from 'generated/graphql';
import AnnotationSystem, {
  AnnotationSystemContext
} from 'containers/AnnotationSystem';
import { Box, Col, Row, Text } from 'components/_main';
import { Skeleton, Wrapper } from './FinishedTasksPreview.ui';
import {
  AnnotationSystemType,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import parseSubType from 'utils/parseSubType';
import { useTheme } from 'styled-components/macro';
import { RobotAvatarPlaceholderImage } from 'assets';
import { REDUCER_INIT } from 'containers/AnnotationSystem/reducer';
import { CHUNK_LENGTH } from 'appConstants';
import { PlayButton, ProgressBar } from 'containers/VideoPlayer';
import { VideoCtxProvider } from 'containers/AnnotationSystem/videoContext';
import useAnnotationVideo from 'containers/AnnotationSystem/hooks/useAnnotationVideo';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';

interface Props {
  isLoading?: boolean;
  projectData: Project;
  projectResults: Array<{
    results: { data: AnnotationUnitProps[] };
    media_url: string;
    videoStart: number | null;
    videoEnd: number | null;
  }>;
}

function SliderWithPlay() {
  const theme = useTheme();
  const { videoEnd, videoStart } = useAnnotationSystemState();
  const { curVidTime, onPlay, onStop, isPlaying, updateFramesWithTime } =
    useAnnotationVideo();
  return (
    <Col
      gridTemplateColumns="max-content 1fr"
      width="100%"
      bg={theme.colors.lightest}
      p="6px"
      borderRadius="0 0 10px 10px"
    >
      <PlayButton isPlaying={isPlaying} onClick={isPlaying ? onStop : onPlay} />
      <ProgressBar
        curVidTime={curVidTime}
        min={videoStart}
        max={videoEnd}
        onChange={(time) => updateFramesWithTime(time)}
      />
    </Col>
  );
}

function FinishedTasksPreview({
  isLoading,
  projectResults,
  projectData
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [, update] = useState<number>(0);

  useLayoutEffect(() => {
    update(Date.now);
  }, []);

  const theme = useTheme();

  const specType = parseSubType(
    projectData.projectSettings?.subType ??
      projectData.projectSettings?.annotationType
  );

  let type: AnnotationSystemType = 'image';
  const isFrames = projectData.projectSettings?.subType?.match('/frames');

  if (!isFrames && projectData.type === TaskType.VideoAnnotation) {
    type = 'video';
  }

  if (!specType) return null;

  if (!projectResults?.length)
    return (
      <Box
        width="276px"
        height="276px"
        borderRadius="50%"
        bg={theme.colors.lightest}
      >
        <RobotAvatarPlaceholderImage width="100%" height="100%" />
      </Box>
    );

  if (
    projectData.type === TaskType.VideoAnnotation &&
    projectData.projectSettings?.chunkLength === CHUNK_LENGTH.unlim
  ) {
    const task = projectResults[0];
    return (
      <AnnotationSystemContext
        {...REDUCER_INIT}
        type={'video'}
        taskId={1}
        annotationSpecificationType={specType}
        results={task.results?.data}
        isViewOnly={true}
        mediaUrl={task.media_url}
        videoStart={task.videoStart}
        videoEnd={task.videoEnd}
      >
        <VideoCtxProvider>
          <Row height="100%" width="100%" gridAutoRows="1fr max-content" noGap>
            <Box
              key={task.media_url}
              width="100%"
              height="100%"
              backgroundColor={theme.colors.lightest}
              borderTopLeftRadius="1rem"
              borderTopRightRadius="1rem"
              overflow="hidden"
              ref={ref}
            >
              <AnnotationSystem
                {...REDUCER_INIT}
                results={task.results?.data}
                taskId={1}
                isViewOnly={true}
                mediaUrl={task.media_url}
                boxProps={{
                  overflow: 'hidden'
                }}
                wrapperRef={ref.current}
                videoStart={task.videoStart}
                videoEnd={task.videoEnd}
              />
            </Box>
            <SliderWithPlay />
          </Row>
        </VideoCtxProvider>
      </AnnotationSystemContext>
    );
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="grid"
      gridAutoFlow="row"
      gridGap="1rem"
      gridAutoRows="max-content 1fr"
    >
      <Col gridGap="2rem" height="max-content">
        <Col>
          <Text>Total:</Text>{' '}
          <Text variant="ui-1-bold">{projectData?.totalTasksCount}</Text>
        </Col>
        <Col>
          <Text>Completed:</Text>{' '}
          <Text variant="ui-1-bold">{projectData?.completedTasksCount}</Text>
        </Col>
        <Col>
          <Text>In Audit:</Text>{' '}
          <Text variant="ui-1-bold">{projectData?.inAuditTasksCount}</Text>
        </Col>
      </Col>
      <Wrapper gridTemplateColumns="50% 50%" gridAutoRows="50%">
        {isLoading ? (
          <Skeleton />
        ) : (
          projectResults.map((task, index) => {
            return (
              <Box
                key={task.media_url}
                height="100%"
                width="100%"
                backgroundColor={theme.colors.lightest}
                ref={ref}
              >
                <AnnotationSystemContext
                  {...REDUCER_INIT}
                  type={type}
                  taskId={index + 1}
                  annotationSpecificationType={specType}
                  results={task.results?.data}
                  isViewOnly={true}
                  mediaUrl={task.media_url}
                  videoStart={task.videoStart}
                  videoEnd={task.videoEnd}
                >
                  <AnnotationSystem
                    {...REDUCER_INIT}
                    isViewOnly={true}
                    results={task.results?.data}
                    taskId={index + 1}
                    mediaUrl={task.media_url}
                    boxProps={{
                      overflow: 'hidden'
                    }}
                    wrapperRef={ref.current}
                    videoStart={task.videoStart}
                    videoEnd={task.videoEnd}
                  />
                </AnnotationSystemContext>
              </Box>
            );
          })
        )}
      </Wrapper>
    </Box>
  );
}

export default FinishedTasksPreview;
