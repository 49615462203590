import React from 'react';
import AuthPageViewNew from 'containers/AuthPageViewNew';
import { Container, Row, Link, Text, Button } from 'components/_main';
import { varEnv } from 'utils/envVars';
import { ReactComponent as CheckSvg } from './checkBig.svg';
import { useAppContext } from 'containers/App';

function SignUpEmailSent() {
  const { signIn } = useAppContext();

  return (
    <AuthPageViewNew>
      <AuthPageViewNew.PaneChild>
        <Row height="100%" width="100%" gridAutoRows="max-content 1fr">
          <Row justifyItems="flex-end">
            <Text>
              Back to{' '}
              <Link href={varEnv.taskwareIoUrl} target="_blank">
                taskware.io
              </Link>
            </Text>
          </Row>
          <Row gridGap="2.5rem" justifyItems="center" alignItems="center">
            <Container width="440px" py="60px" px="90px">
              <Row width="440px" gridGap="2rem" justifyItems="center">
                <Row height="90px" width="90px">
                  <CheckSvg />
                </Row>
                <Text variant="ui-4-bold">Thank you!</Text>
                <Text variant="ui-1-regular" textAlign="center">
                  We’ve sent you an e-mail with a link to activate your account.
                </Text>
                <Button
                  width="100%"
                  maxWidth="100%"
                  marginTop="20px"
                  onClick={() => signIn()}
                >
                  Get Started
                </Button>
              </Row>
            </Container>
          </Row>
        </Row>
      </AuthPageViewNew.PaneChild>
    </AuthPageViewNew>
  );
}

export default SignUpEmailSent;
