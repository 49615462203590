import { MESSAGES } from 'appConstants';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { consoleError } from 'utils';
import { useAcceptInviteByHelperMutation } from 'generated/graphql';
import { toast } from 'utils/toast';
import useRedirect from 'hooks/useRedirect';

export default function HelperAcceptInvitation() {
  const { id } = useParams();
  const [acceptInviteById] = useAcceptInviteByHelperMutation();
  const redirect = useRedirect();

  useEffect(() => {
    async function exec() {
      if (!id) {
        consoleError({
          messsage: 'No ID found'
        });
        return;
      }

      try {
        await acceptInviteById({
          variables: {
            id: parseInt(id, 10)
          }
        });
        toast.success(MESSAGES.acceptInviteByEmailSuccess);
        redirect('/', true);
      } catch (error: any) {
        toast.error(MESSAGES.acceptInviteByEmailFail);
        consoleError(error);
        consoleError({
          message: 'Error during accepting invite by ID',
          id
        });
        redirect('/', true);
      } finally {
      }
    }

    exec();
  }, [acceptInviteById, id, redirect]);

  return null;
}
