import { AnnotationSpecificationType } from 'appTypes';

export default function getAnnotationSpecializationTitle(
  specialization: AnnotationSpecificationType | string
) {
  switch (specialization) {
    case 'humanPoses-17dots':
      return 'Dots';
    case 'boxes':
      return 'Bounding boxes';
    case 'polygons':
      return 'Polygons';
    case 'sentimentAnalysis':
      return 'Sentiment-analysis';
    case 'contentRelevanceScore':
      return 'Content relevance score';
    case 'productCategories':
      return 'Product categories';
    case 'languageDetection':
      return 'Language detection';
  }

  return null;
}
