import React, { useCallback } from 'react';
import { useGoogleLogin, CodeResponse } from '@react-oauth/google';
import { Row, Col, Text, Button } from 'components/_main';
import {
  useLoginClientByGoogleMutation,
  LoginClientByGoogleMutation
} from 'generated/graphql';
import { useTheme } from 'styled-components/macro';
import { GoogleIcon } from 'assets';
import { consoleError, parseLoggedInData, Roles } from 'utils';
import { useAppContext } from 'containers/App';
import GoogleAuthProvider from './GoogleAuthProvider';
import { toast } from 'utils/toast';
import { IconContent } from 'components/_main/Button/styled';

interface GoogleAuthProps {
  title?: string;
}

function GoogleAuthConsumer({ title }: GoogleAuthProps) {
  return (
    <GoogleAuthProvider>
      <GoogleAuth title={title} />
    </GoogleAuthProvider>
  );
}

function GoogleAuth({ title }: GoogleAuthProps) {
  const theme = useTheme();
  const { signIn } = useAppContext();

  const handleOnComplete = useCallback(
    (data: LoginClientByGoogleMutation) => {
      const newUserData = parseLoggedInData(
        data.loginClientByGoogle,
        Roles.CLIENT
      );
      if (!newUserData) return;
      signIn(newUserData);
    },
    [signIn]
  );

  const handleOnError = (errorData: Error) => {
    if (errorData?.message) {
      toast.error(errorData.message);
      return;
    }

    consoleError('Sorry couldn’t sign you in');
    consoleError({ errorData });
    toast.error('Sorry, internal error. Please, try again later 😥');
  };

  const [loginClientByGoogle] = useLoginClientByGoogleMutation({
    onCompleted: handleOnComplete,
    onError: handleOnError
  });

  const handleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse: CodeResponse) => {
      loginClientByGoogle({
        variables: { googleToken: codeResponse.code }
      });
    }
  });

  return (
    <Row justifyItems="center">
      <Col alignItems="center">
        <Row width="70px" height="1px" backgroundColor={theme.colors.lighter} />
        <Text
          variant="ui-small-regular"
          colorVariant="light"
          lineHeight="24px"
          letterSpacing="0.02rem"
        >
          OR
        </Text>
        <Row width="70px" height="1px" backgroundColor={theme.colors.lighter} />
      </Col>
      <Button
        size="big"
        width="100%"
        maxWidth="100%"
        variant="outlined"
        iconPrefix={<GoogleIcon />}
        borderColor={theme.colors.lightest}
        onClick={() => handleLogin()}
        css={`
          ${IconContent} {
            path {
              stroke: none;
            }
          }
          &:hover {
            border-color: ${theme.colors.lighter};
          }
        `}
      >
        <Text
          marginLeft="15px"
          variant="ui-small-bold"
          color={theme.colors.dark}
          lineHeight="24px"
          letterSpacing="0.02rem"
        >
          {title ?? 'Sign in'} with google
        </Text>
      </Button>
    </Row>
  );
}

export default GoogleAuthConsumer;
