import { MIN_DELTA_SIZE } from '../AnnotationSystem.constants';
import {
  ResizePosition,
  ResizePositionNullable
} from '../AnnotationSystem.types';

interface HandleMouseMoveUnitResizeModeRef {
  resizePosition: ResizePositionNullable;
  movementX: number;
  movementY: number;
  x: number;
  y: number;
  w: number;
  h: number;
  minSize?: number;
}

export default function handleResize({
  resizePosition,
  x,
  y,
  w,
  h,
  movementX,
  movementY,
  minSize = MIN_DELTA_SIZE
}: HandleMouseMoveUnitResizeModeRef) {
  const dX = movementX;
  const dY = movementY;
  const newInverseW = w - dX;
  const newInverseH = h - dY;
  const newInverseX = x + dX;
  const newInverseY = y + dY;
  const newW = w + dX;
  const newX = x;
  const newH = h + dY;
  const newY = y;

  const defaultReturn = {
    x,
    y,
    w,
    h
  };

  switch (resizePosition) {
    case ResizePosition.TOP_LEFT: {
      if (newInverseW < minSize || newInverseH < minSize) {
        return defaultReturn;
      }

      return {
        x: newInverseX,
        y: newInverseY,
        w: newInverseW,
        h: newInverseH
      };
    }
    case ResizePosition.TOP_CENTER: {
      if (newInverseH < minSize) {
        return defaultReturn;
      }

      return {
        ...defaultReturn,
        y: newInverseY,
        h: newInverseH
      };
    }
    case ResizePosition.TOP_RIGHT: {
      if (newInverseH < minSize || newW < minSize) {
        return defaultReturn;
      }

      return {
        x: newX,
        y: newInverseY,
        w: newW,
        h: newInverseH
      };
    }
    case ResizePosition.LEFT_CENTER: {
      if (newInverseW < minSize) {
        return defaultReturn;
      }

      return {
        ...defaultReturn,
        x: newInverseX,
        w: newInverseW
      };
    }
    case ResizePosition.RIGHT_CENTER: {
      if (newW < minSize) {
        return defaultReturn;
      }

      return {
        ...defaultReturn,
        x: newX,
        w: newW
      };
    }
    case ResizePosition.BOTTOM_LEFT: {
      if (newH < minSize || newInverseW < minSize) {
        return defaultReturn;
      }

      return {
        x: newInverseX,
        y: newY,
        w: newInverseW,
        h: newH
      };
    }
    case ResizePosition.BOTTOM_CENTER: {
      if (newW < minSize) {
        return defaultReturn;
      }

      return {
        ...defaultReturn,
        h: newH,
        y: newY
      };
    }
    case ResizePosition.BOTTOM_RIGHT: {
      if (newW < minSize || newH < minSize) {
        return defaultReturn;
      }

      return {
        x: newX,
        y: newY,
        w: newW,
        h: newH
      };
    }
  }

  return defaultReturn;
}
