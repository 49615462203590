import { AnnotationSystemEventsEnum } from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import { MouseEvent, useCallback } from 'react';

interface Props {
  unitId: string;
}

export default function usePointMouseMove({ unitId }: Props) {
  const { curEvent, curSelPointId } = useAnnotationSystemState();
  const { onSetCurEvent, onSetCurSelPointId } = useAnnotationSystemActions();
  const { isDotSel } = useStatus({
    unitId
  });

  const handleMouseMove = useCallback(
    (index: number) => (e: MouseEvent) => {
      switch (curEvent) {
        case AnnotationSystemEventsEnum.SELECTED_MODE:
          if (curSelPointId !== index) {
            onSetCurSelPointId(index);
          }

          if (
            e.buttons === 1 &&
            (e.movementX !== 0 || e.movementY !== 0) &&
            isDotSel
          ) {
            onSetCurEvent(AnnotationSystemEventsEnum.DRAG_MODE);
          }
          break;
        default:
          break;
      }
    },
    [curEvent, curSelPointId, isDotSel, onSetCurEvent, onSetCurSelPointId]
  );

  return handleMouseMove;
}
