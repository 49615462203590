import {
  StepFinal,
  StepInviteHelpers,
  StepLabels,
  stepLabelsValidation,
  StepStart,
  stepStartAsAdminValidation,
  stepStartAsClientValidation,
  StepUploadDataset,
  stepUploadDatasetLegacyValidation,
  uploadInstructionsValidation
} from './steps';
import { ProjectSpecialization, ProjectType, Roles } from 'appTypes';
import {
  Configuration,
  ConfigurationArray,
  NullableConfigurationArray,
  StepsDescriptions,
  WizardConfigProps
} from './AddProjectWizard.types';
import { StepUploadClientInstructionsDataProvider } from './steps/StepUploadClientInstructions';
import { datasetMessageValidation } from './steps/StepStart.validation';
import { inviteHelpersValidation } from './steps/StepInviteHelpers.validation';
import { Platform } from 'generated/graphql';
import StepCustomHelpersRequestOnly from 'containers/AddProjectWizard/steps/StepCustomHelpersRequestOnly';

export const wizardConfig = (
  props: WizardConfigProps
): NullableConfigurationArray => {
  const isClient = props.curRole === Roles.CLIENT;
  const isLocked = !props?.isUserActivated;
  let labelsTitle: StepsDescriptions = StepsDescriptions.Labels;
  switch (props?.type) {
    case ProjectType.CONTENT_MODERATION:
      labelsTitle = StepsDescriptions.Reasons;
      break;
    case ProjectType.DIGITIZATION:
      labelsTitle = StepsDescriptions.Sections;
      break;
    case ProjectType.IMAGE_ANNOTATION:
    default:
      break;
  }

  return props?.type === ProjectType.DATA_SET_COLLECTION
    ? [
        {
          id: 1,
          title: '1',
          description: StepsDescriptions.ProjectSetup,
          validation: datasetMessageValidation,
          component: StepStart,
          showSkipStepButton: false
        },
        {
          isLocked,
          id: 2,
          title: '2',
          description: StepsDescriptions.Done,
          component: StepFinal,
          showSaveDraftButton: false,
          showSkipStepButton: false
        }
      ]
    : [
        {
          id: 1,
          title: '1',
          description: StepsDescriptions.ProjectSetup,
          childSteps: [
            {
              id: 11,
              description: StepsDescriptions.GenInfo,
              type: 'small',
              validation:
                props?.curRole === Roles.CLIENT
                  ? stepStartAsClientValidation
                  : stepStartAsAdminValidation,
              component: StepStart,
              showSkipStepButton: false
            },
            props?.type !== ProjectType.NLP &&
            props?.specialization !== ProjectSpecialization.HUMAN_POSES &&
            props?.platform !== Platform.Own
              ? {
                  id: 12,
                  description: labelsTitle,
                  type: 'small',
                  validation: stepLabelsValidation(props),
                  component: StepLabels,
                  showSkipStepButton: false
                }
              : null
          ]
        },
        props?.platform === Platform.Own
          ? {
              id: 2,
              title: '2',
              description: StepsDescriptions.Instruction,
              validation: uploadInstructionsValidation(props),
              component: StepUploadClientInstructionsDataProvider,
              showSaveDraftButton: false,
              isLocked
            }
          : {
              id: 2,
              title: '2',
              description: StepsDescriptions.Dataset,
              childSteps: [
                {
                  id: 21,
                  description: StepsDescriptions.Dataset,
                  validation: stepUploadDatasetLegacyValidation(props),
                  component: StepUploadDataset,
                  type: 'small' as const,
                  showSaveDraftButton: false,
                  showSkipStepButton: false,
                  isDisabledSaveButton: isLocked,
                  isLocked
                },
                {
                  id: 22,
                  description: StepsDescriptions.Instruction,
                  type: 'small' as const,
                  validation: uploadInstructionsValidation(props),
                  component: StepUploadClientInstructionsDataProvider,
                  showSaveDraftButton: false,
                  isLocked
                }
              ]
            },
        isClient
          ? {
              id: 3,
              title: '3',
              description: StepsDescriptions.InviteHelpers,
              component: props?.isOnlyRequestCustomHelpers
                ? StepCustomHelpersRequestOnly
                : StepInviteHelpers,
              validation:
                props?.isRequestCustomHelpers ||
                (props?.isOnlyRequestCustomHelpers &&
                  props.type !== ProjectType.NLP)
                  ? inviteHelpersValidation
                  : undefined,
              showSkipStepButton: !props?.isOnlyRequestCustomHelpers,
              showSaveDraftButton: false,
              isLocked
            }
          : null,
        {
          isLocked,
          id: 4,
          title: '4',
          description: StepsDescriptions.Done,
          component: StepFinal
        }
      ];
};

function flatternize(
  config?: NullableConfigurationArray | null
): NullableConfigurationArray {
  let flatConfig: NullableConfigurationArray = [];

  if (!config) {
    return flatConfig;
  }

  for (const step of config) {
    const childSteps = step?.childSteps;

    flatConfig = [...flatConfig, step];

    if (childSteps && childSteps?.length > 0) {
      const childFlat = flatternize(childSteps);
      if (childFlat) {
        flatConfig = [...flatConfig, ...childFlat];
      }
    }
  }
  return flatConfig;
}

export function flatWizardConfig(
  bakedConfig: NullableConfigurationArray
): ConfigurationArray {
  return flatternize(bakedConfig).filter(
    (step) => step && step.component
  ) as ConfigurationArray;
}

export function removeNullChildren(
  config?: NullableConfigurationArray
): ConfigurationArray {
  let newConfig: ConfigurationArray = [];
  if (!config) {
    return newConfig;
  }
  for (const step of config) {
    if (!step) continue;

    let newStep: Configuration = step;

    if (step?.childSteps && step.childSteps.length > 0) {
      newStep.childSteps = removeNullChildren(newStep.childSteps);
    }

    newConfig = [...newConfig, newStep];
  }

  return newConfig;
}
