import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { LearningMaterialsTypes } from 'containers/LearningMaterials/LearningMaterial.types';
import {
  getTotalLessons,
  getTotalReadLessons
} from 'containers/LearningMaterials/utils/getTotalLessons';
import { useGetLearningMaterialsStructureQuery } from 'generated/graphql';

interface ContextType {
  learningMaterials: LearningMaterialsTypes[];
  percentCompleted: number;
  setLearningMaterials: (data: LearningMaterialsTypes[]) => void;
}

const INIT_DATA: ContextType = {
  learningMaterials: [],
  percentCompleted: 0,
  setLearningMaterials: () => {}
};

export const ctx = React.createContext<typeof INIT_DATA>(INIT_DATA);

export function LearningMaterialsDataProvider({ children }: PropsWithChildren) {
  const { data } = useGetLearningMaterialsStructureQuery({
    fetchPolicy: 'network-only'
  });
  const topics = useCallback(
    () =>
      (data?.getLearningMaterialsStructure
        ?.topics as LearningMaterialsTypes[]) || [],
    [data?.getLearningMaterialsStructure?.topics]
  );

  const [learningMaterials, setLearningMaterials] = useState<
    LearningMaterialsTypes[]
  >([]);

  const percentCompleted = Math.ceil(
    (getTotalReadLessons(learningMaterials) /
      getTotalLessons(learningMaterials)) *
      100
  );

  useEffect(() => {
    setLearningMaterials(topics);
  }, [topics]);

  return (
    <ctx.Provider
      value={{
        learningMaterials,
        percentCompleted,
        setLearningMaterials
      }}
    >
      {children}
    </ctx.Provider>
  );
}

export function useGetLearningMaterialsDataCtx() {
  const data = useContext<ContextType>(ctx);

  return data;
}
