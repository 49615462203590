import React, { useCallback } from 'react';
import { UPLOAD_ADMIN_INSTRUCTION_FILES } from 'graphql/mutations/createProjectWizard';
import { UploadFilesStyled } from './UploadFiles';
import { useMutation } from '@apollo/client';

interface UploadInstructionsAdminProps {
  name: string;
  disabled?: boolean;
}

const UploadInstructionsAdmin = ({
  name,
  disabled
}: UploadInstructionsAdminProps) => {
  let abortController = React.useRef<AbortController>(new AbortController());

  const [upload, { loading }] = useMutation(UPLOAD_ADMIN_INSTRUCTION_FILES, {
    notifyOnNetworkStatusChange: true
  });

  const handleResetAbortController = useCallback(() => {
    if (abortController) {
      abortController.current = new AbortController();
    }
  }, []);

  const handleOnUpload = (fileObj: File) => {
    handleResetAbortController();
    return new Promise((resolve, reject) => {
      upload({
        variables: { file: fileObj },
        context: { fetchOptions: { signal: abortController?.current?.signal } }
      })
        .then((r) => {
          const fileNameFromBackend =
            r.data?.projectAdminInstructionUpload.fname;
          resolve(fileNameFromBackend);
        })
        .catch((e) => reject(e));
    });
  };

  return (
    <UploadFilesStyled
      accept=".pdf"
      name={name}
      abortController={abortController.current}
      handleUpload={handleOnUpload}
      loading={loading}
      disabled={disabled}
    />
  );
};

export default UploadInstructionsAdmin;
