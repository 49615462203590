import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helper as HelperBase, useHelpersQuery } from 'generated/graphql';
import columns from './columns';
import { NetworkStatus } from '@apollo/client';
import { isAdmin } from 'utils/role';
import { ButtonPrimary, Col, Row, Text } from 'components/_main';
import { RouteContent } from 'components/RouteContent';
import Table from 'components/Table';
import ModalCreate from './modals/create';
import ModalDelete from './modals/delete';
import { TData } from './Users.types';
import { initialValues } from './Users.constants';
import HeaderAppBar from 'containers/HeaderAppBar';
import useDashboardCtx from 'hooks/useDashboardCtx';
import ModalEdit from './modals/edit';
import {
  HELPERS_LIST,
  GET_ASSIGNED_HELPERS_LIST
} from 'graphql/queries/helper';
import ModalDisable from './modals/disable';
import HelperDetailsCard from 'containers/HelpersDetailsCard';
import { TRefetchQueries } from 'appTypes';
import useTablePage from 'hooks/useTablePage';
import { USERS_LIST_PAGE } from 'appConstants';

type Helper = Omit<HelperBase, 'createdAt'>;

const Users = () => {
  const { page, setPage } = useTablePage(USERS_LIST_PAGE);
  const {
    data: helpersList,
    loading: queryLoading,
    networkStatus
  } = useHelpersQuery({
    variables: {
      page
    }
  });
  const [isModalCreateOpen, setModalCreateOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [isModalEditOpen, setModalEditOpen] = useState(false);
  const [isModalDisableOpen, setModalDisableOpen] = useState<boolean>(false);
  const [isModalDetailsCard, setModalDetailsCard] = useState<boolean>(false);
  const [curData, setCurData] = useState<TData>(initialValues);

  let columnMemo: ReturnType<typeof columns>;

  const onToggle = useCallback(async (data: TData) => {
    if (!data || !data?.id) return console.error('Not data or data.id');

    setCurData(data);
    setModalDisableOpen(true);
  }, []);

  const onDelete = (data: TData) => {
    if (!data || !data?.id) return console.error('Not data or data.id');
    setCurData(data);
    setModalDeleteOpen(true);
  };

  const onDetailsCard = (data: TData) => {
    if (!data || !data?.id) return console.error('Not data or data.id');
    setCurData(data);
    setModalDetailsCard(true);
  };

  columnMemo = useMemo(() => {
    return columns({ onDelete, onToggle, onDetailsCard });
  }, [onToggle]);

  let helpersListArr: (Helper | null)[] =
    (helpersList?.helpers?.helpers &&
      Array.isArray(helpersList?.helpers?.helpers) && [
        ...helpersList?.helpers?.helpers
      ]) ||
    [];

  const { setAppBarTitle } = useDashboardCtx();

  useEffect(() => {
    setAppBarTitle('Users');
  }, [setAppBarTitle]);

  const refetchQueries: TRefetchQueries = useMemo(() => {
    const refetchQueries = [];

    if (curData?.id) {
      refetchQueries.push({
        query: GET_ASSIGNED_HELPERS_LIST,
        variables: {
          helperId: parseInt(curData.id, 10)
        }
      });
    }

    refetchQueries.push({
      query: HELPERS_LIST,
      variables: { page }
    });

    return [...refetchQueries];
  }, [curData, page]);

  useEffect(() => {}, [curData, refetchQueries]);

  return (
    <div>
      <HeaderAppBar />
      <RouteContent>
        <Row width={'max'} justifyContent={'space-between'}>
          <Col>
            {isAdmin() && (
              <ButtonPrimary
                data-component="button-create"
                onClick={() => setModalCreateOpen(true)}
              >
                + Create helper
              </ButtonPrimary>
            )}
          </Col>
        </Row>
        <Table
          setCurTablePage={setPage}
          page={page}
          pagesCount={helpersList?.helpers?.pagesCount}
          columns={columnMemo}
          dataSource={helpersListArr as object[]}
          loading={
            queryLoading ||
            networkStatus === NetworkStatus.refetch ||
            networkStatus === NetworkStatus.poll
          }
        />
        <ModalCreate<Helper>
          refetchQueries={refetchQueries}
          isOpen={isModalCreateOpen}
          setModalOpen={setModalCreateOpen}
        />
        <ModalEdit<Helper>
          refetchQueries={refetchQueries}
          curData={curData}
          isOpen={isModalEditOpen}
          setModalOpen={setModalEditOpen}
        />
        <ModalDelete<Helper>
          refetchQueries={refetchQueries}
          curData={curData}
          isOpen={isModalDeleteOpen}
          setModalOpen={setModalDeleteOpen}
        />
        <ModalDisable
          curData={curData}
          isOpen={isModalDisableOpen}
          setModalOpen={setModalDisableOpen}
          refetchQueries={refetchQueries}
        />
        {curData.id && (
          <HelperDetailsCard
            isOpen={isModalDetailsCard}
            onCancel={() => setModalDetailsCard(false)}
            helperId={parseInt(curData.id, 10)}
          />
        )}
      </RouteContent>
    </div>
  );
};

export default Users;
