import {
  AnnotationUnitProps,
  AnnotationSystemProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import { roundAnnotationUnitsTimestampsToMs } from '../utils';

export default function modifyAnnotationUnitReducerCase(
  state: AnnotationSystemProps,
  content: Partial<AnnotationUnitProps>
) {
  const annotationUnitIndex = state.annotationUnits.findIndex(
    ({ unitId }) => unitId === content.unitId
  );

  if (annotationUnitIndex < 0) {
    console.error(
      'Reducer action: ADD_ANNOTATION_UNIT\n' +
        'Cant find annotation unit with unitId: \n' +
        content.unitId
    );
  }

  const newAnnotationUnitsArray = roundAnnotationUnitsTimestampsToMs(
    state.annotationUnits
  );

  newAnnotationUnitsArray[annotationUnitIndex] = {
    ...newAnnotationUnitsArray[annotationUnitIndex],
    ...content
  };

  return {
    ...state,
    annotationUnits: [...newAnnotationUnitsArray]
  };
}
