import { css } from 'styled-components/macro';

export type TUseWidth = {
  width?: number;
};

export type TUseGap = {
  gap?: string;
};

export const cssJustifyContent = ({
  justify = 'start'
}: {
  justify?: string;
}) => {
  switch (justify) {
    case 'space-between':
      return 'space-between';
    case 'start':
      return 'flex-start';
    default:
      return 'flex-start';
  }
};

export const cssWidth = ({ width = 'content' }: { width?: string }) => {
  switch (width) {
    case 'max':
      return '100%';
    case 'content':
      return 'max-content';
    default:
      return width;
  }
};

const cssWidthNumber = ({ width }: { width?: number }) =>
  width ? `${width}px` : `100%`;

export const cssGap = ({ gap }: { gap?: string }) => (gap ? gap : '1rem');

export const useWidth = css<TUseWidth>`
  width: ${cssWidthNumber};
`;

export const useGap = css<TUseGap>`
  gap: ${cssGap};
`;
