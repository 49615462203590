import { useCallback, useEffect } from 'react';
import { AnnotationSystemEventsEnum } from '../AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from '../context';

interface Props {
  onStateUpdate?: () => void;
  unitId?: string;
  onDragMouseUp?: () => void;
  onResizeMouseUp?: () => void;
  onMouseUp?: () => void;
}

export default function useSvgLayerMouseUp({
  onDragMouseUp,
  onResizeMouseUp,
  onStateUpdate,
  onMouseUp,
  unitId
}: Props) {
  const { svgLayerRef, curEvent, curSelUnitId } = useAnnotationSystemState();
  const { onSetCurEvent } = useAnnotationSystemActions();

  const handleSvgLayerMouseUp = useCallback(() => {
    onMouseUp && onMouseUp();

    switch (curEvent) {
      case AnnotationSystemEventsEnum.DRAG_MODE: {
        if (curSelUnitId !== unitId || !onStateUpdate) return;
        onStateUpdate();
        onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
        onDragMouseUp && onDragMouseUp();
        break;
      }
      case AnnotationSystemEventsEnum.RESIZE_MODE: {
        if (curSelUnitId !== unitId || !onStateUpdate) return;
        onStateUpdate();
        onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
        onResizeMouseUp && onResizeMouseUp();
        break;
      }
    }
  }, [
    onMouseUp,
    curEvent,
    curSelUnitId,
    unitId,
    onStateUpdate,
    onSetCurEvent,
    onDragMouseUp,
    onResizeMouseUp
  ]);

  useEffect(() => {
    if (!svgLayerRef) {
      return;
    }

    svgLayerRef.addEventListener('mouseup', handleSvgLayerMouseUp);

    return () => {
      svgLayerRef.removeEventListener('mouseup', handleSvgLayerMouseUp);
    };
  }, [handleSvgLayerMouseUp, svgLayerRef]);
}
