import styled from 'styled-components/macro';
import React from 'react';
import { FieldInputProps, FieldProps } from 'formik';
import { FormItemProps } from 'antd/es/form';
import { Form } from 'antd';
import { upper } from 'utils';
const { Item: FormItem } = Form;

export const AntdFormItem = styled<
  React.FC<{ className?: string } & Partial<FieldProps> & FormItemProps>
>(({ className, children, meta, field, label, ...props }) => {
  let childrenProps: { id?: string } & Partial<FieldInputProps<string>> = {};

  if (field) {
    childrenProps = {
      ...field,
      id: field.name
    };
  }

  return (
    <FormItem
      className={className}
      labelCol={{ span: 24 }}
      hasFeedback
      label={label ? upper(label as string) : ''}
      help={meta && meta.touched && meta.error}
      validateStatus={meta && meta.touched && meta.error ? 'error' : ''}
      {...props}
    >
      {React.isValidElement(children) &&
        React.cloneElement(children, {
          ...(children.props as object),
          ...childrenProps
        })}
    </FormItem>
  );
})`
  label {
    font-size: 13px;
    color: #88a0b1;
    margin: 0 0 7px;
  }
`;

export default AntdFormItem;
