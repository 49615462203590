import React from 'react';
import { VideoPlayerProps } from './types';
import 'styled-components/macro';
import { useAnnotationSystemActions } from 'containers/AnnotationSystem/context';

const VideoPlayer = React.forwardRef<HTMLVideoElement, VideoPlayerProps>(
  (
    {
      startTime,
      h,
      onLoadMetadata,
      w,
      onPlayThrough,
      src,
      type = 'video/webm',
      endTime,
      videoProps
    }: VideoPlayerProps,
    ref
  ) => {
    const { onSetIsLoading } = useAnnotationSystemActions();

    const handlePlayThrough: React.ReactEventHandler<HTMLVideoElement> = (
      event
    ) => {
      setTimeout(() => {
        onSetIsLoading(false);
      }, 1000);
      onPlayThrough && onPlayThrough(event);
    };

    const handleLoadMetadata: React.ReactEventHandler<HTMLVideoElement> = (
      event
    ) => {
      const vid = event.currentTarget;

      if (!vid) {
        return;
      }

      vid.currentTime = startTime ?? 0;

      onLoadMetadata && onLoadMetadata(event);
    };

    const finalSrc =
      startTime && endTime ? `${src}#t=${startTime},${endTime}` : src;

    return (
      <>
        <video
          css={`
            position: absolute;
            left: 0;
            top: 0;
            z-index: 50;
          `}
          ref={ref}
          onCanPlayThrough={handlePlayThrough}
          onLoadedMetadata={handleLoadMetadata}
          onLoad={() => {}}
          width={`${w}`}
          height={`${h}`}
          id="myVid"
          controls={false}
          preload="metadata"
          src={finalSrc}
          {...videoProps}
        >
          {src && <source src={finalSrc} type={type} />}
          Your browser does not support HTML video.
        </video>
      </>
    );
  }
);

export default VideoPlayer;
