import styled, { css } from 'styled-components/macro';
import { MediaLayerImageProps } from './MediaLayer.types';

export const ImgStyled = styled.img(
  ({ w, h }: Pick<MediaLayerImageProps, 'w' | 'h'>) => css`
    width: ${w};
    height: ${h};
    z-index: 50;
    position: relative;
  `
);
