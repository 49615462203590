import { ADD_WIZARD_FIELDS } from 'appConstants';
import { Row, Text } from 'components/_main';
import { InviteHelpersContextProvider } from 'containers/InviteHelpers';
import { useField } from 'formik';
import React from 'react';
import CustomHelpersRequestTab from '../../InviteHelpers/components/CustomHelpersRequestTab';

export default function StepCustomHelpersRequestOnly() {
  const [
    ,
    customRequestMeta,
    { setValue: onSetCustomMessageInviteHelpersRequest }
  ] = useField(ADD_WIZARD_FIELDS.customMessageInviteHelpersRequest);

  return (
    <InviteHelpersContextProvider
      helpers={[]}
      customMessageMeta={customRequestMeta}
      onCustomMessageUpdate={onSetCustomMessageInviteHelpersRequest}
    >
      <Row gridGap="2rem">
        <Row>
          <Text variant="ui-small-2-bold" upper>
            Request helpers
          </Text>
          <CustomHelpersRequestTab
            name={ADD_WIZARD_FIELDS.customMessageInviteHelpersRequest}
          />
        </Row>
      </Row>
    </InviteHelpersContextProvider>
  );
}
