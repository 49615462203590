import React from 'react';
import { Tooltip } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { Row, Col } from 'components/_main';
import Text from 'components/_main/Text';
import { ButtonOutline } from 'components/_main/Button';

interface BackToProjectOrClientModalTitleProps {
  companyName?: string | null;
  firstName?: string;
  lastName?: string;
  onClose?: () => void;
  projectTitle?: string;
}

function BackToProjectOrClientModalTitle({
  firstName,
  companyName,
  lastName,
  onClose,
  projectTitle
}: BackToProjectOrClientModalTitleProps) {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Col horizCenter>
      <ButtonOutline round onClick={handleClose}>
        <Tooltip title="Back to client's projects list">
          <LeftOutlined />
        </Tooltip>
      </ButtonOutline>
      <Row gridGap="6px">
        {companyName ? (
          <Text colorVariant="light">
            {companyName} ({firstName} {lastName})
          </Text>
        ) : (
          <Text>
            {firstName} {lastName}
          </Text>
        )}
        {projectTitle && <Row>{projectTitle}</Row>}
      </Row>
    </Col>
  );
}

export default BackToProjectOrClientModalTitle;
