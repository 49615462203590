import React, { useCallback, useState } from 'react';
import { Box } from 'components/_main';
import { SidebarFoldableProps } from './types';
import { ReactComponent as ArrowLeftIcon } from './arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from './arrowRight.svg';

import { css, useTheme } from 'styled-components/macro';
import { px } from 'utils';

const WIDTH = 360;

const SidebarFoldable: React.FC<SidebarFoldableProps> = ({
  onOpenCb,
  isRightPosition = true,
  children,
  width: widthBase,
  ...props
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleOnToggle = useCallback(() => {
    setIsOpen((open) => {
      onOpenCb && onOpenCb(open);
      return !open;
    });
  }, [onOpenCb]);

  const width = widthBase ?? px(WIDTH);

  return (
    <Box
      display="grid"
      position="relative"
      width={isOpen ? width : '0px'}
      height="100%"
      borderLeft={
        isRightPosition ? `1px solid ${theme.colors.lighter}` : 'none'
      }
      borderRight={
        isRightPosition ? 'none' : `1px solid ${theme.colors.lighter}`
      }
      padding={isOpen ? '30px 26px' : '30px 10px'}
      gridColumn={isRightPosition ? '2 / 3' : '1 / 2'}
      gridRow="2 / -1"
      css={css`
        transition: all 250ms ease-in-out;
      `}
      {...props}
    >
      <Box
        data-testid="OpenButton"
        displayFlex
        justifyContent="center"
        alignItems="center"
        width="1.6rem"
        height="1.6rem"
        position="absolute"
        left={isRightPosition ? '-0.8rem' : 'auto'}
        right={isRightPosition ? 'auto' : '-0.8rem'}
        backgroundColor={theme.colors.lighter}
        borderRadius="50%"
        top="calc(50% - 1.6rem)"
        zIndex={2}
        onClick={handleOnToggle}
      >
        {isOpen ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      </Box>
      <Box
        width="100%"
        data-testid="SidebarChildrenWrapper"
        overflow="hidden"
        opacity={isOpen ? '1' : '0'}
        style={{ transition: 'opacity 250ms ease-in-out' }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SidebarFoldable;
