import { ProjectTypeLabel } from 'appTypes';
import { ProjectEntity } from 'components/Table/Table.types';
import { getAnnotationSpecializationIcon } from 'utils';

export default function specializationIconColumn(data: ProjectEntity) {
  const { IMAGE_ANNOTATION, VIDEO_ANNOTATION } = ProjectTypeLabel;
  const type = data?.mappedType?.toLowerCase();
  const specType = data?.mappedSpecType;

  if (
    specType &&
    (type === IMAGE_ANNOTATION?.toLowerCase() ||
      type === VIDEO_ANNOTATION?.toLowerCase())
  ) {
    return getAnnotationSpecializationIcon(specType);
  }

  return null;
}
