import React, { useMemo } from 'react';
import { useAnnotationSystem, useAnnotationSystemState } from '../../context';
import CreateBox from '../CreateBox';
import AnnotationBox from '../AnnotationBox';
import { showUnitsWithZoomLevel } from 'containers/AnnotationSystem/utils';
import useSvgLayerMouseDown from 'containers/AnnotationSystem/hooks/useSvgLayerMouseDown';
import useSvgLayerMouseMove from 'containers/AnnotationSystem/hooks/useSvgLayerMouseMove';
import useSvgLayerMouseUp from 'containers/AnnotationSystem/hooks/useSvgLayerMouseUp';

export default function SvgLayerBoxes() {
  const { isUnitCreatingProcess, curZoomLevel, annotationUnits } =
    useAnnotationSystemState();
  const units = showUnitsWithZoomLevel(annotationUnits, curZoomLevel ?? 1);

  useSvgLayerMouseDown({});
  useSvgLayerMouseMove({});
  useSvgLayerMouseUp({});

  return (
    <>
      {isUnitCreatingProcess && <CreateBox />}
      {units &&
        Array.isArray(units) &&
        units.map((box) => (
          <AnnotationBox
            key={`${box.unitId}-${box.x}-${box.y}`}
            unitProps={box}
          />
        ))}
    </>
  );
}
