import React from 'react';
import { PolygonPoint, ReducerActionTypes } from './reducer';
import { BoxProps } from 'components/_main';
import {
  TNewTask,
  TTaskDataMapped,
  TTasksTaskDataResult
} from 'containers/TasksRouter/Tasks.types';
import { AnnotationSpecificationType } from 'appTypes';
import { TaskType } from 'generated/graphql';

export type MediaType = MediaTypeImage | MediaTypeVideo;

export type MediaTypeVideo = HTMLVideoElement;

export type MediaTypeImage = HTMLImageElement;

export type VideoStatus = 'play' | 'stop';

export type StringNullable = string | null;
export type StringOrNumberNullable = string | number | null;

export interface PrevWrapperSize {
  w: number;
  h: number;
}

export interface AnnotationSystemProps {
  setTaskData: (data: TTaskDataMapped | TNewTask) => void;
  taskData: TTaskDataMapped | null;
  copyUnitId: StringNullable;
  results: TTasksTaskDataResult;
  mediaUrl: StringNullable;
  taskId: number | null;
  resizePosition: ResizePosition | null;
  svgLayerOpacityLevel: number;
  fitWrapperZoomLevel: number;
  isPercentage?: boolean;
  isViewOnly: boolean;
  onResultsChange: ((results?: AnnotationUnits) => void) | null;
  videoStart: number | null;
  videoEnd: number | null;
  videoFPS: number;
  videoFrameRate: number;
  cursorInitX: number;
  cursorInitY: number;
  curVideoStatus: VideoStatus | null;
  curSelPointId: StringOrNumberNullable;
  annotationUnits: AnnotationUnits;
  hideAnnotationUnitIds: string[];
  type: AnnotationSystemType;
  annotationType: TaskType | null;
  annotationSpecificationType: AnnotationSpecificationType | null;
  curHoverUnitId: StringNullable;
  labels: string[];
  w: number;
  h: number;
  x: number;
  y: number;
  mediaImageRef: HTMLImageElement | null;
  mediaVideoRef: HTMLVideoElement | null;
  svgLayerRef: SVGSVGElement | null;
  mouseDX: number;
  mouseDY: number;
  mouseInitX: number;
  mouseInitY: number;
  initH: number;
  initW: number;
  isInitMediaLoading: boolean;
  annotationAccuracy: number;
  annPracticeProgress: number;
  curMode: AnnotationSystemModes;
  curEvent: AnnotationSystemEvents;
  curSelUnitId: StringNullable;
  aspectRatio: number;
  curZoomLevel: number;
  zoomStep: number;
  pageFrames: number;
}

export interface TimelineTransition extends AnnotationUnitCoords {
  timestamp: number;
  isEnd: boolean;
}

export interface AnnotationUnitCoords {
  x: number;
  y: number;
  w: number;
  h: number;
  polygonPoints?: Array<[number, number]>;
}

export type AnnotationUnitType =
  | 'box'
  | 'rotatedBox'
  | 'dot'
  | 'wrapper'
  | 'dotConnectionLine'
  | 'contentModeration'
  | 'polygon'
  | 'semantic'
  | 'polyline'
  | 'cuboids3D'
  | 'sentimentAnalysis'
  | 'productCategories'
  | 'languageDetection'
  | 'contentRelevanceScore';

export type AnnotationMicroTaskingUnitType =
  | 'sentimentAnalysis'
  | 'contentRelevanceScore'
  | 'productCategories'
  | 'languageDetection';

export type Places = 'sidebar' | 'annotationLayer';

export interface Dot {
  id: number;
  description?: string;
  x: number;
  y: number;
  connection?: number[];
}

export interface AnnotationUnitProps
  extends Omit<TimelineTransition, 'timestamp'> {
  id?: number;
  unitId: string;
  label: string;
  timelineTransitions?: TimelineTransition[];
  isLabelDropdownOpen?: boolean;
  type: AnnotationUnitType;
  description?: string;
  groupIndex?: number;
  reasons?: string[];
  polygonPoints?: PolygonPoint[];
  dots?: Dot[];
  status?: 'valid' | 'invalid';
}

export interface ObjWithCoords {
  x: string;
  y: string;
}

export type AnnotationSystemEvents = AnnotationSystemEventsEnum | null;

export type AnnotationSystemModes = AnnotationSystemModesEnum;

export type VideoAnnotationUnits = Record<string, AnnotationUnits>;

export type AnnotationSystemType = 'video' | 'image';

export enum AnnotationSystemEventsEnum {
  'CREATING_SIZE' = 'CREATING_SIZE',
  'CREATING_LABEL' = 'CREATING_LABEL',
  'CREATE_MODE' = 'CREATE_MODE',
  'DRAG_MODE' = 'DRAG_MODE',
  'ZOOM_MODE' = 'ZOOM_MODE',
  'RESIZE_MODE' = 'RESIZE_MODE',
  'SELECTED_MODE' = 'SELECTED_MODE',
  'ZOOM_NAVIGATION' = 'ZOOM_NAVIGATION',
  'APP_POLYGON_POINT' = 'APP_POLYGON_POINT'
}
export enum AnnotationSystemModesEnum {
  'UNIT_SELECT' = 'UNIT_SELECT',
  'DOT_SELECT' = 'DOT_SELECT'
}

export type AnnotationUnits = AnnotationUnitProps[];

export interface AnnotationSystemLayerProps
  extends Pick<
    AnnotationSystemProps,
    'mediaUrl' | 'videoStart' | 'videoEnd' | 'taskId' | 'results' | 'isViewOnly'
  > {
  isForceCenter?: boolean;
  wrapperRef?: HTMLDivElement | null;
  isAutoSetSizeToWrapper?: boolean;
  onGetWrapperSize?: () => { wrapperH: number; wrapperW: number };
  onMediaLoadSuccess?: (props: {
    mediaElement: MediaType;
    onSetInitSizeCb: ({ h, w }: { h: number; w: number }) => void;
    onSetIsLoadingCb: (isLoading: boolean) => void;
  }) => void;
  boxProps?: Partial<BoxProps>;
  withSvgLayer?: boolean;
  adjustToHeight?: boolean;
}

export type AnnotationSystemContextProps = Partial<AnnotationSystemProps> &
  Pick<
    AnnotationSystemProps,
    'mediaUrl' | 'videoStart' | 'videoEnd' | 'taskId' | 'results' | 'isViewOnly'
  >;

export type AnnotationSystemContext = [
  AnnotationSystemProps,
  React.Dispatch<ReducerActionTypes>
];

export interface SvgLayerContextState {
  mouseRelY: number;
  mouseRelX: number;
  mousePressInitX: number;
  mousePressInitY: number;
  mouseDX: number;
  mouseDY: number;
}
export interface SvgLayerContext {
  svgLayerState: SvgLayerContextState;
  setSvgLayerState: React.Dispatch<
    React.SetStateAction<SvgLayerContextState> | SvgLayerContextState
  >;
}

export type ResizePositionNullable = ResizePosition | null;

export enum ResizePosition {
  'TOP_LEFT' = 'TOP_LEFT',
  'TOP_CENTER' = 'TOP_CENTER',
  'TOP_RIGHT' = 'TOP_RIGHT',
  'LEFT_CENTER' = 'LEFT_CENTER',
  'RIGHT_CENTER' = 'RIGHT_CENTER',
  'BOTTOM_LEFT' = 'BOTTOM_LEFT',
  'BOTTOM_CENTER' = 'BOTTOM_CENTER',
  'BOTTOM_RIGHT' = 'BOTTOM_RIGHT'
}

export type MergeState = (state: Partial<AnnotationUnitProps>) => void;
export type DebouncedUpdate = (newState: Partial<AnnotationUnitProps>) => void;

export interface InitCoordState {
  initX: number;
  initY: number;
}

export interface DotStateMutableRef extends InitCoordState {
  isMoved?: boolean;
}

export interface SvgLayerMoveChangeProps {
  dX: number;
  dY: number;
  unitId?: string;
  newX?: number;
  newY?: number;
  newW?: number;
  newH?: number;
}

export interface TimelineFrame {
  timestamp: number;
  isTouched: boolean;
}
