import React from 'react';
import { ProjectType } from 'appTypes';
import { Row, Col, Text } from 'components/_main';
import { Radio, RadioGroup } from 'components/Radio';
import { useAddProjectWizardContext } from '../../AddProjectWizard.context';
import {
  mapProjectSpecToAnnotationTitle,
  mapProjectSpecToAnnotationType,
  microtaskingStructure
} from 'appConstants';
import { upper } from 'utils';
import Icon from 'components/Icon';
import { Question } from '@phosphor-icons/react';
import { useTheme } from 'styled-components/macro';

interface ProjectSpecializationTypeSelectProps {
  projectType: ProjectType;
  onProjectTypeHelpOpen(): void;
}

function ProjectSpecializationTypeSelect({
  projectType,
  onProjectTypeHelpOpen
}: ProjectSpecializationTypeSelectProps) {
  const theme = useTheme();
  const { onSetProjectSpecialization, specialization } =
    useAddProjectWizardContext();

  const handleSubTypeChange: React.EventHandler<any> = (e) => {
    const newValue = e.target.value;
    onSetProjectSpecialization(newValue);
  };
  const specializations =
    microtaskingStructure?.find(({ type }) => type === projectType)
      ?.specializations ?? [];

  if (specializations.length === 0) {
    return null;
  }

  return (
    <Row>
      <Col flexCenter gridGap="10px">
        <Text variant="ui-small-2-bold">{upper('Annotation type')}</Text>
        <Icon hoverFillColor="fg" onClick={onProjectTypeHelpOpen}>
          <Question color={theme.colors.primary} size={24} />
        </Icon>
      </Col>
      <Col width="100%" gridAutoColumns="max-content 1fr">
        <RadioGroup value={specialization} onChange={handleSubTypeChange}>
          <Row gridGap="0.6rem">
            {specializations.map(({ id, specType }) => (
              <Radio key={id} value={specType}>
                {
                  mapProjectSpecToAnnotationTitle[
                    mapProjectSpecToAnnotationType[specType]
                  ]
                }
              </Radio>
            ))}
          </Row>
        </RadioGroup>
      </Col>
    </Row>
  );
}

export default ProjectSpecializationTypeSelect;
