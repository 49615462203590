import React from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import {
  SigninPage,
  ForgotPasswordEmail,
  ForgotPassword,
  ResetPassword
} from '../pages';
import SignUpNew from 'containers/SignUp';
import SignUpEmailSent from 'containers/SignUpEmailSent';
import ConfirmSignupByLink from 'containers/ConfirmationSignupByLink';
import { isAdmin, isClient } from 'utils';

export const defaultRoute: string = '/signin';

const routes: RouteProps[] = [
  {
    path: '/',
    element: React.createElement(() => <Navigate to={defaultRoute} />)
  },
  {
    path: '/signin',
    element: <SigninPage />
  },
  {
    path: '/signup',
    element: (() => {
      if (isAdmin()) {
        return React.createElement(() => <Navigate to="/signin" />);
      }

      if (isClient()) {
        return React.createElement(() => <SignUpNew />);
      }

      return React.createElement(() => <SigninPage />);
    })()
  },
  {
    path: '/activate',
    element: <SignUpEmailSent />
  },
  {
    path: '/activate/:hashId',
    element: <ConfirmSignupByLink />
  },
  {
    path: '/reset',
    element: <ForgotPasswordEmail />
  },
  {
    path: '/reset/:hashId',
    element: <ResetPassword />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  }
];

export default routes;
