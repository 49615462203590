import React, { useCallback } from 'react';
import { upper } from 'utils';
import { ProjectDetails } from 'appTypes';
import { TabPane, Tabs } from 'components/Tabs';
import { Button } from 'components/_main';
import useOpen from 'hooks/useOpen';
import { AddProjectWizard } from 'containers/AddProjectWizard';
import ActiveProjects from 'containers/Projects/ProjectsAdmin/ActiveProjects';
import PendingProjects from 'containers/Projects/ProjectsAdmin/PendingProjects';
import { EMPTY_PROJECT_DATA } from 'containers/Projects/constants';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import {
  ProjectDetailsCardDataProvider,
  ProjectDetailsCardDataWrapperWithCloneElement,
  ProjectDetailsCardModal
} from 'containers/ProjectDetailsCard';
import { Project, ProjectStatus } from 'generated/graphql';
import ActivateProjectModal from 'containers/Projects/ProjectsAdmin/ActiveProjects/ActivateProjectModal';
import { useProjectsContext } from 'containers/Projects/Projects.context';

function ProjectsAdmin() {
  const userData = useGetUserSessionData();
  const { currentProjectData, setCurrentProjectData } = useProjectsContext();
  const isDraftProject = currentProjectData?.status === ProjectStatus.Draft;

  const {
    isOpen: isCreateProjectOpen,
    onOpen: onCreateProjectOpen,
    onClose: onCreateProjectClose
  } = useOpen();

  const {
    isOpen: isProjectDetailsOpen,
    onOpen: onProjectDetailsOpen,
    onClose: onProjectDetailsClose
  } = useOpen();

  const {
    isOpen: isActivateProjectOpen,
    onOpen: onActivateProjectOpen,
    onClose: onActivateProjectClose
  } = useOpen();

  const handleAddProjectClick = useCallback(() => {
    setCurrentProjectData(EMPTY_PROJECT_DATA);
    onCreateProjectOpen();
  }, [onCreateProjectOpen, setCurrentProjectData]);

  const handleProjectDetailsOpen = useCallback(
    (data: ProjectDetails) => {
      setCurrentProjectData(data);
      onProjectDetailsOpen();
    },
    [setCurrentProjectData, onProjectDetailsOpen]
  );

  const handleProjectDetailsClose = useCallback(() => {
    onProjectDetailsClose();
    setTimeout(() => {
      setCurrentProjectData(EMPTY_PROJECT_DATA);
    }, 300);
  }, [onProjectDetailsClose, setCurrentProjectData]);

  const handleOnActivate = useCallback(
    (data: Project) => {
      setCurrentProjectData(data);
      onActivateProjectOpen();
    },
    [setCurrentProjectData, onActivateProjectOpen]
  );

  return (
    <>
      <Tabs
        defaultActiveKey="Ready"
        mb="20px"
        tabBarExtraContent={
          <Button onClick={handleAddProjectClick}>
            {upper('Add Project')}
          </Button>
        }
      >
        <TabPane tab="Active" key="Active">
          <ActiveProjects
            activeProjectId={currentProjectData?.id}
            onEdit={handleProjectDetailsOpen}
          />
        </TabPane>
        <TabPane tab="Pending" key="Pending">
          <PendingProjects
            onEdit={handleProjectDetailsOpen}
            onActivate={handleOnActivate}
          />
        </TabPane>
      </Tabs>

      {!isDraftProject && (
        <ProjectDetailsCardDataProvider
          projectId={currentProjectData?.id}
          key={`edit-project-${currentProjectData?.id}`}
        >
          <ProjectDetailsCardDataWrapperWithCloneElement>
            <ProjectDetailsCardModal
              isOpen={isProjectDetailsOpen}
              onCancel={handleProjectDetailsClose}
            />
          </ProjectDetailsCardDataWrapperWithCloneElement>
        </ProjectDetailsCardDataProvider>
      )}
      {!currentProjectData?.id && userData.enabled && (
        <AddProjectWizard
          key="create-new-project"
          isOpen={isCreateProjectOpen}
          onClose={onCreateProjectClose}
        />
      )}
      <ActivateProjectModal
        isOpen={isActivateProjectOpen}
        onClose={onActivateProjectClose}
      />
    </>
  );
}

export default ProjectsAdmin;
