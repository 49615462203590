export const mapClintInstructionFiles = (
  clientInstructionUrl?: string[] | null
) =>
  (clientInstructionUrl &&
    clientInstructionUrl
      .map((file) => {
        return trimInstructionName(file);
      })
      .filter((file: string) => !!file)) ??
  [];

export const trimInstructionName = (filePath: string) => {
  if (filePath.match('https://')) {
    return filePath.substring(filePath.lastIndexOf('/-') + 2, filePath.length);
  }

  return filePath;
};

export const trimInstructionShortName = (filePath: string) => {
  if (filePath.match('https://')) {
    return filePath.substring(filePath.lastIndexOf('-') + 1, filePath.length);
  }

  return filePath;
};
