import React from 'react';
import { useField } from 'formik';
import { Form as AntForm, Select as SelectBase } from 'antd';
import { Client, useListClientsQuery } from 'generated/graphql';
import Select from 'components/Select';
import { TSelectClient, TNewClient } from './SelectClientSingle.types';

const FormItem = AntForm.Item;
const { Option } = SelectBase;

const SelectClientSingle: React.FC<TSelectClient> = React.memo(
  ({ label = 'Select', name }) => {
    const [field, meta, helper] = useField(name);
    const { data, loading } = useListClientsQuery({
      variables: {
        returnAllData: true
      }
    });

    const newClients: TNewClient[] =
      (data?.listClients?.clients as Client[])
        ?.filter((client: Client) => client.enabled && !client.isDeleted)
        .map((client: Client) => ({
          id: parseInt(client.id, 10),
          label: client.companyName ? `${client.companyName}` : undefined,
          value: client.companyName ? `${client.companyName}` : undefined
        })) ?? [];

    const onChangeClient = React.useCallback(
      (value: string) => value && helper.setValue(value),
      [helper]
    );

    return (
      <FormItem
        hasFeedback
        help={meta.touched && meta.error}
        validateStatus={meta.touched && meta.error ? 'error' : ''}
        valuePropName={'key'}
      >
        <Select
          showSearch
          optionFilterProp={'children'}
          placeholder={label}
          {...field}
          dropdownStyle={{}}
          onChange={onChangeClient}
          loading={loading}
          filterOption={(input: string, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {newClients &&
            Array.isArray(newClients) &&
            newClients
              .filter((client) => !!client.label)
              .map((item: TNewClient) => (
                <Option value={item.id} key={item.id}>
                  {item.label}
                </Option>
              ))}
        </Select>
      </FormItem>
    );
  }
);

export default SelectClientSingle;
