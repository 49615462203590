import { ADD_WIZARD_FIELDS } from 'appConstants';
import * as yup from 'yup';
import { ProjectType } from '../../../appTypes';
import { instructionsTextareaValidation } from './StepUploadInstructions.validation';

const asClientTemplate = {
  title: yup
    .string()
    .required('Project title required')
    .min(1, 'Should contain at least 1 character')
    .max(30, 'Should contain less then 30 character')
    .test(
      'validLetters',
      'Must contain only latin letters, numbers & space',
      (value) => {
        return !!(value && value.match(new RegExp(`^[A-Za-z0-9 ]*$`, 'g')));
      }
    )
    .test(
      'atLeastOneLetter',
      'Must not contain only numbers and space',
      (value) => {
        return !!(value && value.match(/(?=.*[a-zA-Z])/g));
      }
    ),
  type: yup
    .string()
    .oneOf([
      ProjectType.VIDEO_ANNOTATION,
      ProjectType.IMAGE_ANNOTATION,
      ProjectType.CONTENT_MODERATION,
      ProjectType.PHONE_CALL,
      ProjectType.DIGITIZATION,
      ProjectType.CATEGORIZATION,
      ProjectType.NLP
    ])
    .required('The task type is a required field')
};

export const datasetMessageValidation = yup.object({
  title: asClientTemplate['title'],
  [ADD_WIZARD_FIELDS.clientInstructionTextarea]: instructionsTextareaValidation
});

export const asClient = yup.object({ ...asClientTemplate });

export const asAdmin = yup.object({
  ...asClientTemplate,
  clientId: yup
    .number()
    .positive()
    .nullable(true)
    .required(`You can't proceed without clients`)
});
