import React, { MouseEvent } from 'react';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import styled, { css } from 'styled-components/macro';
import { AnnotationSystemEventsEnum } from '../../AnnotationSystem.types';
import useMousePressed from './useMousePressed';

interface RootStyledProps {
  isViewOnly?: boolean;
  currentEvent?: AnnotationSystemEventsEnum | null;
  isMousePressed?: boolean;
  width?: string;
  height?: string;
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

const RootStyled = styled.svg(
  ({ currentEvent, isMousePressed, isViewOnly }: RootStyledProps) => css`
    z-index: 100;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    ${currentEvent === null &&
    css`
      cursor: auto;
    `}
    ${currentEvent === AnnotationSystemEventsEnum.CREATE_MODE &&
    css`
      cursor: crosshair;
    `}
    ${currentEvent === AnnotationSystemEventsEnum.ZOOM_NAVIGATION &&
    css`
      cursor: ${isMousePressed ? 'grabbing' : 'grab'};
    `}
    ${isViewOnly && 'cursor: auto !important; pointer-events: none !important;'}
  `
);

export const Root = React.forwardRef<
  SVGSVGElement,
  React.PropsWithChildren<RootStyledProps>
>((rootStyledProps, ref) => {
  const { curEvent, isViewOnly } = useAnnotationSystemState();
  const isMousePressed = useMousePressed();

  return (
    <RootStyled
      ref={ref}
      isViewOnly={isViewOnly}
      {...rootStyledProps}
      isMousePressed={isMousePressed}
      currentEvent={curEvent}
      {...(isViewOnly && {
        onMouseDownCapture: (e) => {
          e.stopPropagation();
        }
      })}
    />
  );
});
