import { AnnotationSystemProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { SetMediaLoadingFinish } from '../types';
import { TaskType } from 'generated/graphql';
import { CONTENT_MODERATION_UNIT_INIT } from 'containers/AnnotationSystem/AnnotationSystem.constants';
import { isArray } from 'lodash';
import createPolygonAnnotationUnit from 'containers/AnnotationSystem/reducer/reducerCases/createPolygonAnnotationUnit';

export default function setMediaLoadingFinishReducerCase(
  state: AnnotationSystemProps,
  {
    h,
    initH,
    initW,
    isViewOnly,
    mediaUrl,
    ref,
    results,
    taskId,
    videoEnd,
    videoStart,
    w
  }: SetMediaLoadingFinish['payload']
) {
  let zoomLevel = ((w * 100) / initW || 1) / 100;
  let newState = {};

  const resultsAnnotation = results?.length ? results : [];
  const resultsAnnotationUnits = Array.from(
    new Map(
      [...state.annotationUnits, ...(isArray(results) ? results : [])].map(
        (item) => [item.unitId, item]
      )
    ).values()
  );

  let newAnnotationUnits =
    taskId === state.taskId ? resultsAnnotationUnits : resultsAnnotation;

  if (zoomLevel <= 0 || !isFinite(zoomLevel)) {
    zoomLevel = 1;
  }

  if (zoomLevel <= 0.01) {
    zoomLevel = 1;
  }

  if (state.annotationType === TaskType.ContentModeration) {
    newAnnotationUnits = [CONTENT_MODERATION_UNIT_INIT];
  }

  if (state.annotationSpecificationType === 'polygon') {
    newState = createPolygonAnnotationUnit(newAnnotationUnits);
  }

  return {
    ...state,
    results,
    taskId,
    mediaUrl,
    videoEnd,
    videoStart,
    annotationUnits: [...newAnnotationUnits],
    mediaVideoRef: ref,
    mediaImageRef: ref,
    w: w || initW,
    h: h || initH,
    initH,
    initW,
    isViewOnly,
    fitWrapperZoomLevel: zoomLevel,
    curZoomLevel: zoomLevel,
    ...newState
  };
}
