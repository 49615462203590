import { upper } from 'utils/_stringUtilsLegacy';
import React from 'react';
import { TData } from './Users.types';
import { Col } from 'components/_main';
import AntdSwitch from 'components/AntdSwitch';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import HelperName from 'components/HelperName/HelperName';
import { activeStatusColumn } from 'components/Table';
import Icon from 'components/Icon';

export const columns = ({
  onDelete,
  onToggle,
  onDetailsCard
}: {
  onToggle: (data: TData) => void;
  onDelete: (data: TData) => void;
  onDetailsCard: (data: TData) => void;
}) => [
  {
    key: 'name',
    title: upper('Name'),
    width: 500,
    render: HelperName
  },
  {
    key: 'status',
    title: upper('Status'),
    width: 100,
    render: activeStatusColumn
  },
  {
    title: upper('Actions'),
    width: 100,
    render: (data: TData) => {
      return (
        <Col>
          <Icon
            color="primary"
            hoverFillColor="fg"
            size={22}
            data-component="button-delete"
            onClick={(e: any) => {
              e.stopPropagation();
              onDelete(data);
            }}
          >
            <DeleteOutlined />
          </Icon>
          <Icon
            size={22}
            color="primary"
            hoverFillColor="fg"
            data-component="button-edit"
            onClick={() => {
              onDetailsCard(data);
            }}
          >
            <EditOutlined />
          </Icon>
        </Col>
      );
    }
  },
  {
    title: upper('Available'),
    width: 100,
    render: (data: TData) => {
      return (
        <div
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <AntdSwitch
            onChange={(e: any) => {
              onToggle(data);
            }}
            checked={data.enabled}
            defaultChecked={false}
          />
        </div>
      );
    }
  }
];

export default columns;
