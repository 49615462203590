import { Modal } from 'components/_modals';
import React from 'react';
import PricingPlans from './PricingPlans';
import { Z_INDEXES } from 'appConstants';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

function PricingPlansModal({ isOpen, onClose }: Props) {
  return (
    <Modal
      width={790}
      title="Upgrade Plan"
      isVisible={isOpen}
      onCancel={onClose}
      zIndex={Z_INDEXES.modal}
      footer={false}
    >
      <PricingPlans onClick={onClose} />
    </Modal>
  );
}

export default PricingPlansModal;
