import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Col, Row, Text, Button } from 'components/_main';
import { TabPane, Tabs } from 'components/Tabs';
import PersonalInfo from './ProfileDetails.Tab.ProfileInfo';
import ChangePassword from './ProfileDetails.Tab.ChangePassword';
import { ProfileDetailsProps } from 'containers/ProfileDetails/ProfileDetails.types';
import BadgeUpdatePricingPlan from 'components/BadgeUpdatePricingPlan';
import HelpersTab from './ProfileDetails.Tab.Helpers';
import useSignOut from 'hooks/useSignOut';
import { AvatarImageGenerator } from 'components/AvatarGenerator';
import { APP_BAR_HEIGHT } from 'appConstants';
import useGetUserSessionData from 'hooks/useGetUserSessionData';

function ProfileDetails(props: ProfileDetailsProps) {
  const theme = useTheme();
  const { isPremium } = useGetUserSessionData();

  const bgColor = isPremium ? theme.colors.dark : theme.colors.light;

  const { firstName, lastName, email } = props;
  const handleSignout = useSignOut();

  const onSignout = React.useCallback(() => {
    handleSignout();
  }, [handleSignout]);

  return (
    <Col gridGap="40px" height={`calc(100vh - ${APP_BAR_HEIGHT})`}>
      <Row
        flex="1"
        justifyItems="center"
        alignContent="space-between"
        borderRight={`1px solid ${theme.colors.lighter}`}
        padding="30px"
        marginRight="40px"
        width="360px"
        height="100%"
      >
        <Row justifyItems="center" gridGap="30px">
          <AvatarImageGenerator bg={theme.colors.lightest} />
          <Row justifyItems="center" gridGap="3px">
            {firstName && lastName && (
              <Text variant="header-2" textAlign="center">
                {firstName} {lastName}
              </Text>
            )}
            {email && <Text>{email}</Text>}
          </Row>
          <Row justifyItems="center">
            <Button
              capitalize
              bg={bgColor}
              padding="5px 15px"
              borderRadius="5px"
              height="34px"
              small
              css={`
                &:hover {
                  background-color: ${bgColor};
                  cursor: default;
                }
              `}
            >
              <Text variant="ui-small-bold" color="white">
                {isPremium ? 'Premium Plan' : 'Freemium Plan'}
              </Text>
            </Button>
            <BadgeUpdatePricingPlan />
          </Row>
        </Row>
        <Button variant="outlined" onClick={onSignout}>
          Log out
        </Button>
      </Row>
      <Row>
        <Tabs
          defaultActiveKey="Projects"
          css={`
            width: calc(100vw - 560px);
          `}
        >
          <TabPane tab="Personal Info" key="info">
            <PersonalInfo {...props} />
          </TabPane>
          <TabPane tab="Change Password" key="password">
            <ChangePassword {...props} />
          </TabPane>
          <TabPane tab="Helpers" key="helpers">
            <HelpersTab />
          </TabPane>
        </Tabs>
      </Row>
    </Col>
  );
}

export default ProfileDetails;
