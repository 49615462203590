import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Box } from '../_main';

interface Props {
  color?: string;
}

function SpinnerLoader({ color }: Props) {
  const theme = useTheme();

  return (
    <Box
      overflowVisible
      width="100%"
      height="100%"
      css={`
        .lds-ring {
          display: inline-block;
          position: relative;
          width: 100%;
          height: 100%;
        }
        .lds-ring div {
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          border: 2px solid ${color ?? theme.colors.primary};
          border-radius: 50%;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: ${color ?? theme.colors.primary} transparent transparent
            transparent;
        }
        .lds-ring div:nth-child(1) {
          animation-delay: -0.45s;
        }
        .lds-ring div:nth-child(2) {
          animation-delay: -0.3s;
        }
        .lds-ring div:nth-child(3) {
          animation-delay: -0.15s;
        }
        @keyframes lds-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}
    >
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Box>
  );
}

export default SpinnerLoader;
