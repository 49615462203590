import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum,
  AnnotationUnitType,
  Places,
  StringNullable
} from '../AnnotationSystem.types';
import { useAnnotationSystemState } from '../context';
import useCurSelUnitProps from './useCurSelUnitProps';

interface CommonStateProps {
  type?: AnnotationUnitType;
  place?: Places;
  curSelUnitId?: StringNullable;
  groupIndex?: number;
  curSelGroupIndex?: number;
  curMode?: AnnotationSystemModesEnum;
  curEvent?: AnnotationSystemEventsEnum | null;
  unitId?: StringNullable;
  label?: string;
}

const getCommonState = ({
  type,
  place,
  curSelUnitId,
  curSelGroupIndex,
  curEvent,
  curMode,
  groupIndex,
  unitId,
  label
}: CommonStateProps) => {
  const isDot = type === 'dot';
  const isDotLine = type === 'dotConnectionLine';
  const isDotOrDotLine = isDot || isDotLine;
  const isDotWrapper = type === 'wrapper';
  const isDotRelated = isDotOrDotLine || isDotWrapper;
  const isWrapperSelected = curSelUnitId === `wrapper-${groupIndex}-${label}`;

  return {
    isDot,
    isDotLine,
    isDotOrDotLine,
    isDotWrapper,
    isDotRelated,
    isWrapperSelected,
    isBox: type === 'box',
    isPolygon: type === 'polygon',
    isSidebar: place === 'sidebar',
    isCurSel: curSelUnitId === unitId,
    isCurGroupIndex: curSelGroupIndex === groupIndex,
    isDotSel: curMode === AnnotationSystemModesEnum.DOT_SELECT,
    isUnitSel: curMode === AnnotationSystemModesEnum.UNIT_SELECT,
    isDrag: curEvent === AnnotationSystemEventsEnum.DRAG_MODE,
    isResize: curEvent === AnnotationSystemEventsEnum.RESIZE_MODE,
    isCreate:
      curEvent === AnnotationSystemEventsEnum.CREATE_MODE ||
      curEvent === AnnotationSystemEventsEnum.CREATING_SIZE ||
      curEvent === AnnotationSystemEventsEnum.CREATING_LABEL,
    isSelected:
      curEvent === AnnotationSystemEventsEnum.SELECTED_MODE &&
      curMode === AnnotationSystemModesEnum.UNIT_SELECT &&
      curSelUnitId !== null &&
      curSelUnitId === unitId,
    isSelect: curEvent === null,
    isPolyAddPoint: curEvent === AnnotationSystemEventsEnum.APP_POLYGON_POINT,
    isNavigate: curEvent === AnnotationSystemEventsEnum.ZOOM_NAVIGATION
  };
};

const checkIsActive = (props: CommonStateProps) => {
  const { isDotWrapper, isDotSel, isCurSel, isCurGroupIndex, isSidebar } =
    getCommonState(props);

  if (isDotWrapper && isDotSel && isSidebar && isCurGroupIndex) {
    return true;
  }

  return isCurSel;
};

export default function useStatus(props: CommonStateProps) {
  const {
    curSelUnitId,
    curEvent,
    curMode,
    hideAnnotationUnitIds,
    annotationUnits
  } = useAnnotationSystemState();

  const { unitId, groupIndex } = props;

  const curSelUnitProps = useCurSelUnitProps();

  const { groupIndex: curSelGroupIndex } = curSelUnitProps ?? {};

  const commonStateProps = {
    ...curSelUnitProps,
    ...props,
    curSelGroupIndex,
    curSelUnitId,
    curEvent,
    curMode
  };

  const {
    isDotOrDotLine,
    isSelected,
    isDrag,
    isResize,
    isUnitSel,
    isCurGroupIndex,
    isPolyAddPoint,
    isPolygon,
    isDot,
    isDotLine,
    isWrapperSelected,
    isNavigate,
    ...commonState
  } = getCommonState(commonStateProps);

  let isActive = checkIsActive(commonStateProps);

  let isHidden = hideAnnotationUnitIds.includes(unitId!);
  let isDotSel = commonState.isDotSel;
  let isCreate = commonState.isCreate;

  if (isDotLine) {
    isDotSel = isCurGroupIndex && commonState.isDotSel;
  }

  if (isDot && isDotSel) {
    isHidden = isHidden || !isCurGroupIndex;
  }

  if (isDotOrDotLine) {
    const sameGroupIndexWrapper = annotationUnits.find(
      (unit) => unit.type === 'wrapper' && unit.groupIndex === groupIndex
    );

    isHidden =
      isHidden ||
      hideAnnotationUnitIds.includes(sameGroupIndexWrapper?.unitId!);
  }

  return {
    ...commonState,
    isSelected,
    isCreate,
    isActive,
    isDrag,
    isHidden,
    isResize,
    isWrapperSelected,
    isUnitSel,
    isDotSel,
    isPolyAddPoint,
    isCurGroupIndex,
    isNavigate
  };
}
