import { ColProps } from 'antd';
import RouteContent from 'components/RouteContent';
import { BoxProps, Row, RowProps } from 'components/_main';
import HeaderAppBar from 'containers/HeaderAppBar';
import { HeaderAppBarProps } from 'containers/HeaderAppBar/HeaderAppBar.types';
import React, { PropsWithChildren } from 'react';

interface RouteContainerProps {
  title?: string;
  row?: boolean;
  headerProps?: Partial<HeaderAppBarProps>;
  rootBoxProps?: BoxProps;
  routeContentProps?: BoxProps;
}

export default function RouteContainer({
  children,
  title,
  headerProps,
  rootBoxProps,
  routeContentProps,
  row,
  ...restProps
}: PropsWithChildren<RouteContainerProps> & BoxProps) {
  const RowComp = row ? Row : React.Fragment;
  return (
    <Row gap="0" height="100%" {...rootBoxProps}>
      <HeaderAppBar title={title} {...headerProps} />
      <RouteContent {...routeContentProps}>
        <RowComp height="100%" {...restProps}>
          {children}
        </RowComp>
      </RouteContent>
    </Row>
  );
}
