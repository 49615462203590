import { Modal, ModalProps } from 'antd';
import React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

const VerticalFieldComponent = ({
  className,
  title,
  children,
  style,
  ...restProps
}: React.PropsWithChildren<{
  title: string;
  style: any;
  className: string;
  [key: string]: any;
}>) => {
  return (
    <div className={className} style={style} {...restProps}>
      <div className="title">{title}</div>
      <div className="content-list">
        {React.Children.toArray(children).map((child, index) => (
          <span className="content" key={index}>
            {child}
          </span>
        ))}
      </div>
    </div>
  );
};

export const VerticalField = styled(VerticalFieldComponent)(
  ({ theme }) => css`
    display: grid;
    grid-auto-flow: row;
    gap: 0;

    & > .title {
      color: ${theme.colors.gray};
    }
    & > .content-list {
      display: grid;
      grid-auto-flow: row;

      & > .content {
        color: ${theme.colors.secondary};
        font-weight: 500;
      }
    }
  `
);

export const TabTitle = styled.span`
  padding: 0 1rem;
`;

export const Layout = styled.div`
  display: grid;
  grid-template:
    'tabs tabs' 1fr
    'tabs tabs' 1fr
    / 145px 1fr;
`;

export const Portrait = styled(({ className, imgSrc }) => (
  <div className={className}>
    <img
      className="portrait"
      src={imgSrc || 'https://i.pravatar.cc/100'}
      alt=""
    />
  </div>
))`
  & > .portrait {
    border-radius: 100rem;
  }
`;

export const PortraitArea = styled.div`
  grid-area: portrait;
`;

export const TitleArea = styled.div`
  grid-area: title;
`;

export const TabsArea = styled.div`
  grid-area: tabs;
`;

export const ModalStyled = styled(
  ({
    className,
    children,
    isOpen,
    onCancel,
    ...restProps
  }: ModalProps & {
    className?: string;
    isOpen: boolean;
    children: React.ReactNode;
  }) => (
    <Modal
      visible={isOpen}
      onCancel={onCancel}
      title={'EDIT HELPER'}
      className={className}
      {...restProps}
    >
      {children}
    </Modal>
  )
)`
  top: 2rem;

  .ant-modal-body {
    padding: 0.5rem 3rem;
  }

  & > .ant-modal-content {
    min-width: 900px;
    min-height: 680px;
    height: 680px;
    max-height: 680px;
    display: grid;
    grid-template: max-content 1fr max-content / 100%;
  }
`;

export const BigTitleNumberUnit = styled(
  ({ className, title, number, style }) => (
    <div className={className} style={style}>
      <div className={'title'}>{title}</div>
      <div className={'number'}>{number}</div>
    </div>
  )
)(
  ({ barColor, numberColor, theme, py, px }) => css`
    display: grid;
    grid-auto-flow: row;
    gap: 1px;
    width: 100%;
    padding: ${py} ${px};
    position: relative;
    justify-content: center;
    justify-items: center;
    align-items: center;

    & > .title {
      font-size: 11px;
      color: ${theme.colors.gray};
    }
    & > .number {
      font-size: 30px;
      color: ${numberColor || theme.colors.secondary};
    }
    ${barColor &&
    css`
      &::after {
        bottom: 0;
        content: ' ';
        position: absolute;
        width: 100%;
        height: 5px;
        background-color: ${barColor};
      }
    `}
  `
);

export const BigTitleNumberUnitPrimary = (props: any) => {
  const theme = useTheme();

  return <BigTitleNumberUnit {...props} numberColor={theme.colors.primary} />;
};

export const AvatarGenerator = styled<any>(
  ({ className, firstName, lastName, loading }: any) =>
    !loading ? (
      <div className={className}>
        {firstName && <span>{firstName[0]}</span>}
        {lastName && <span>{lastName[0]}</span>}
      </div>
    ) : (
      '...loading'
    )
)(
  () => css`
    width: 50px;
    height: 50px;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: column;
    gap: 0;
    border-radius: 100rem;
    font-size: 20px;
    color: white;
    background-color: skyblue;
  `
);

export const TitleHeader = styled.div`
  display: grid;
  padding: 0 1rem;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  grid-auto-columns: max-content;
  gap: 1rem;
  min-height: 72px;
`;
