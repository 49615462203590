import React, { useEffect } from 'react';
import { ModalForm } from 'components/_modals';
import { Form } from 'antd';
import { TData } from '../Users.types';
import AntdFormItemInput from 'components/AntdFormItemInput';
import AntdFormItemInputEmail from 'components/AntdFormItemInputEmail';
import { useUpdateHelperMutation } from 'generated/graphql';
import { toast } from 'utils/toast';
import { titleRules } from 'utils/finalFormValidationHelpers';
import { TRefetchQueries } from 'appTypes';

function ModalEdit<TDataGeneric extends { id: string } = TData>({
  curData,
  isOpen,
  setModalOpen,
  refetchQueries
}: {
  isOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  curData: TDataGeneric;
  refetchQueries: TRefetchQueries;
}) {
  const [formHook] = Form.useForm<TData>();

  useEffect(() => {
    if (formHook) {
      formHook.resetFields();
    }
  }, [curData, formHook]);

  const handleOnClose = () => {
    setModalOpen(false);
    formHook.resetFields();
  };

  const [updateHelper, { loading }] = useUpdateHelperMutation({
    onCompleted: () => {
      toast.success('Helper successfully created');
      handleOnClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: () => refetchQueries
  });

  return (
    <ModalForm<TDataGeneric>
      title={'Edit user'}
      form={formHook}
      isVisible={isOpen}
      initialValues={curData}
      onOk={() => {
        const values = formHook.getFieldsValue();
        updateHelper({
          variables: {
            helperId: parseInt(curData.id, 10),
            ...values
          }
        });
      }}
      onCancel={handleOnClose}
      loading={loading}
    >
      <AntdFormItemInputEmail label={'Email'} name={'email'} />
      <AntdFormItemInput
        rules={[...titleRules]}
        label={'First name'}
        name={'firstName'}
      />
      <AntdFormItemInput
        rules={[...titleRules]}
        label={'Last name'}
        name={'lastName'}
      />
    </ModalForm>
  );
}

export default ModalEdit;
