import React from 'react';
import { formatDate } from 'utils';
import { Col, Row, Text } from 'components/_main';
import { AnnotationSidebarHistoryProps } from 'containers/TasksRouter/AnnotationView/AnnotationView.types';
import ExpandBlock from 'containers/TasksRouter/AnnotationView/components/AnnotationSidebar/ExpandBlock';
import {
  historyActionMessages,
  taskHistoryFormat
} from 'containers/TasksRouter/AnnotationView/components/AnnotationSidebar/constants';
import { HistoryActions } from 'containers/TasksRouter/AnnotationView/components/AnnotationSidebar/types';

function AnnotationHistoryBlock({ data }: AnnotationSidebarHistoryProps) {
  return (
    <ExpandBlock
      title={
        <Text variant="ui-small-bold" upper>
          Task history
        </Text>
      }
      expandable={!!data?.length}
    >
      <Row gridGap="6px" pl="14px" pr="14px">
        {data?.map((item) => {
          const action = item.action as HistoryActions;

          return (
            <Row noGap key={item.actionWasTakenAt}>
              <Col gridGap="4px" alignItems="center">
                <Text variant="ui-1-bold" lineHeight="26px">
                  {historyActionMessages[action]}
                </Text>
                <Text variant="ui-1-bold" colorVariant="primary">
                  {item.initiator?.firstName} {item.initiator?.lastName}
                </Text>
              </Col>
              <Text
                variant="ui-1-regular"
                lineHeight="26px"
                colorVariant="light"
              >
                {formatDate(item.actionWasTakenAt, taskHistoryFormat)} UTC
              </Text>
            </Row>
          );
        })}
      </Row>
    </ExpandBlock>
  );
}

export default AnnotationHistoryBlock;
