import { Helper, useGetAssignedHelpersListQuery } from 'generated/graphql';
import { useEffect, useState } from 'react';
import mapHelperAssignList from 'utils/mapHelperAssignList';
import { isAdmin } from '../utils';

function useAssignedHelpersList(
  projectId?: number,
  fetchPolicy: 'network-only' | 'cache-only' = 'network-only'
) {
  const [assignedHelperIds, setAssignedHelperIds] = useState<number[]>([]);
  const [assignedAuditorIds, setAssignedAuditorIdsIds] = useState<number[]>([]);

  const isSkip = !projectId || !isAdmin();

  const { data: helpersAllActive, loading: loadingHelpersAllActive } =
    useGetAssignedHelpersListQuery({
      skip: isSkip
    });

  const { data: helpersAssigned, loading: loadingHelpersAssigned } =
    useGetAssignedHelpersListQuery({
      variables: {
        projectId: projectId
      },
      skip: isSkip,
      fetchPolicy: fetchPolicy
    });

  const loading = loadingHelpersAssigned || loadingHelpersAllActive;

  useEffect(() => {
    if (!helpersAssigned?.helpers?.helpers) return;

    const helpers: Helper[] = helpersAssigned?.helpers?.helpers as Helper[];

    const { helpersIds, auditorsIds } = mapHelperAssignList([...helpers]);
    // @ts-ignore
    setAssignedHelperIds([...helpersIds]);
    // @ts-ignore
    setAssignedAuditorIdsIds([...auditorsIds]);
  }, [helpersAssigned]);

  return {
    loading,
    helpersAllActive,
    assignedHelperIds,
    assignedAuditorIds
  };
}

export default useAssignedHelpersList;
