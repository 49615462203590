import { TData } from './types';

export const initialValues: TData = {
  id: '',
  email: '',
  isPremium: false,
  firstName: '',
  lastName: '',
  companyName: '',
  createdAt: '',
  activated: false,
  isDeleted: false,
  enabled: true,
  sendInvite: true
};
