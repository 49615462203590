import ProjectProgress from 'components/ProjectProgress';
import { Project } from 'generated/graphql';
import React from 'react';

interface Props {
  inPercents?: boolean;
  isVertical?: boolean;
  showDetails?: boolean;
  showAbsNumbersBesideProgressBar?: boolean;
  showProgressBar?: boolean;
  showDetailsWithTooltip?: boolean;
}

const projectProgressColumn = (props?: Props) => (data: Project) =>
  (
    <ProjectProgress
      data={data}
      showProgressBar={props?.showProgressBar ?? true}
      showDetails={props?.showDetails ?? false}
      showDetailsWithTooltip={props?.showDetailsWithTooltip ?? false}
      showAbsNumbersBesideProgressBar={
        props?.showAbsNumbersBesideProgressBar ?? false
      }
      inPercents={props?.inPercents ?? true}
      isVertical={props?.isVertical ?? false}
    />
  );

export default projectProgressColumn;
