import React from 'react';
import { Box, Button, Col, Grid, Row, Text } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import { DoneIcon } from 'assets';
import {
  PricingPlanFeatureProps,
  PricingPlanProps
} from './PracingPlans.types';

export function PricingPlan({
  planType,
  title,
  description,
  children,
  onContactSales
}: React.PropsWithChildren<PricingPlanProps>) {
  const theme = useTheme();
  const color = planType === 'team' ? theme.colors.yellow : theme.colors.dark;
  const colorText = planType === 'team' ? theme.colors.fg : theme.colors.bg;

  return (
    <Row
      justifyItems="center"
      height="100%"
      gridAutoRows="1fr max-content"
      gridGap="30px"
    >
      <Row
        width="340px"
        minHeight="427px"
        borderRadius="5px"
        border={`1px solid ${color}`}
        p="50px 30px 20px"
        position="relative"
        height="100%"
        gridAutoRows="1fr max-content max-content"
      >
        <Grid
          position="absolute"
          top="-25px"
          left="10%"
          width="80%"
          height="50px"
          borderRadius="5px"
          bg={color}
          flexCenter
        >
          <Text variant="ui-small-bold" upper color={colorText}>
            {title}
          </Text>
        </Grid>
        <Row alignSelf="flex-start" gridGap="5px">
          {React.Children.map(children, (child) =>
            React.isValidElement(child)
              ? React.cloneElement(child, { ...child.props, planType })
              : null
          )}
        </Row>
        <Box width="100%" height="1px" bg={theme.colors.lighter} />
        <Text colorVariant="light">{description}</Text>
      </Row>
      <Button marginBottom="6px" onClick={onContactSales}>
        CONTACT SALES
      </Button>
    </Row>
  );
}

export function PricingPlanFeature({
  title,
  description,
  planType
}: PricingPlanFeatureProps) {
  const theme = useTheme();

  return (
    <Col gridGap="14px" width="100%" gridAutoColumns="max-content 1fr">
      <Box>
        <DoneIcon
          css={`
            path {
              fill: ${planType === 'team'
                ? theme.colors.yellow
                : theme.colors.dark};
            }
          `}
        />
      </Box>
      <Row gridGap="2px">
        <Text variant="ui-1-bold">{title}</Text>
        {description && (
          <Box>
            <Text variant="ui-small-regular" colorVariant="light">
              {description}
            </Text>
          </Box>
        )}
      </Row>
    </Col>
  );
}
