import * as yup from 'yup';
import {
  ProjectType,
  VideoAnnotationDatasetSplitByType,
  VideoAnnotationDatasetProcessingType
} from 'appTypes';
import { AddProjectWizardContextState } from '../../AddProjectWizard.types';
import { ALLOWED_FILE_FORMAT_FOR_NLP } from 'containers/AddProjectWizard/AddProjectWizard.constants';

type StepUploadDatasetLegacyValidationProps = Partial<
  Pick<
    AddProjectWizardContextState,
    | 'isUserActivated'
    | 'annotationMultipleFiles'
    | 'videoFileDuration'
    | 'annotationFilesUploadType'
    | 'videoAnnotationSpecSubType'
    | 'videoAnnotationSplitType'
    | 'mediaFile'
    | 'platform'
    | 'videoMediaFile'
  >
>;

export const stepUploadDatasetLegacyValidation = ({
  annotationMultipleFiles,
  annotationFilesUploadType,
  videoFileDuration,
  videoAnnotationSpecSubType,
  videoAnnotationSplitType,
  mediaFile,
  videoMediaFile
}: StepUploadDatasetLegacyValidationProps) =>
  yup.object({
    mediaFileName: yup.mixed().test('mediaFileValid', function () {
      const { type, projectSettings } = this.parent;
      const fileFormat = `.${mediaFile?.name.split('.').pop()}`;

      if (
        annotationFilesUploadType === 'multiple' ||
        annotationFilesUploadType === 'googleDrive'
      ) {
        if (!annotationMultipleFiles || annotationMultipleFiles?.length === 0) {
          return this.createError({
            message: "Can't process without media file(s)"
          });
        }
        return true;
      }

      if (
        annotationFilesUploadType === 'single' &&
        type === ProjectType.IMAGE_ANNOTATION &&
        !mediaFile
      ) {
        return this.createError({
          message: "Can't process without ZIP archive"
        });
      }

      if (
        annotationFilesUploadType === 'single' &&
        type === ProjectType.NLP &&
        !mediaFile
      ) {
        return this.createError({
          message: "Can't process without file"
        });
      }

      if (
        type === ProjectType.NLP &&
        fileFormat &&
        !ALLOWED_FILE_FORMAT_FOR_NLP.split(', ').includes(fileFormat)
      ) {
        return this.createError({
          message: "Can't continue with this file format"
        });
      }

      if (type === ProjectType.VIDEO_ANNOTATION) {
        if (annotationFilesUploadType === 'single' && !videoMediaFile) {
          return this.createError({
            message: "Can't process without video"
          });
        }

        if (
          videoAnnotationSpecSubType ===
            VideoAnnotationDatasetProcessingType.SPLIT_BY_FRAMES &&
          videoAnnotationSplitType ===
            VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE
        ) {
          if (!videoFileDuration) {
            return true;
          }

          if (videoFileDuration <= projectSettings.chunkLength) {
            return this.createError({
              message: "Chunk length can't be longer then video duration file"
            });
          }
        }
      }

      return true;
    })
  });
