const ignoreElementsSelectors = [
  '.ant-modal-content',
  'textarea',
  '[class*="ant-input"]',
  '[data-component="main-center-sidebar-styled"]'
];

const isHotkeyIgnore = (e: KeyboardEvent) =>
  ignoreElementsSelectors.reduce((acc, selector) => {
    const target = e.target;
    if (!selector || !target) return acc || false;

    const el = document.querySelector(selector);
    const isIgnore =
      acc || (el && el.contains(target as Node)) || el === target;
    return isIgnore;
  }, false);

export default isHotkeyIgnore;
