import * as yup from 'yup';
import { toast } from 'utils/toast';
import { AddProjectWizardContextState } from '../AddProjectWizard.types';
import { Roles } from 'appTypes';

type Props = Partial<
  Pick<AddProjectWizardContextState, 'isUserActivated' | 'curRole'>
>;

export const instructionsTextareaValidation = yup
  .string()
  .min(20, 'Should contain at least 20 char-s')
  .max(1000, 'Should contain less then 1000 char-s');

export const uploadInstructionsAdminValidation = () =>
  yup.object({
    adminShortInstruction: instructionsTextareaValidation.nullable(),
    adminInstructionFileName: yup.string()
  });

export const uploadInstructionsClientValidation = ({
  isUserActivated
}: Props) =>
  yup.object({
    clientShortInstruction: instructionsTextareaValidation,
    clientInstructionFileNames: yup.array().of(yup.string()),
    isUnregister: yup.mixed().test('isUnregistered', function () {
      if (!isUserActivated) {
        toast.error('Please, activate you account first!', {
          toastId: 'activateAccountError'
        });
        return this.createError();
      }

      return true;
    })
  });

export const uploadInstructionsValidation = ({
  curRole,
  isUserActivated
}: Props) =>
  curRole === Roles.CLIENT
    ? uploadInstructionsClientValidation({ isUserActivated })
    : uploadInstructionsAdminValidation;
