import { Helper } from 'generated/graphql';

export const initialValues: Helper = {
  id: '',
  email: '',
  createdAt: '',
  firstName: '',
  lastName: '',
  activated: false,
  isDeleted: false,
  enabled: true
};
