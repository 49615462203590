import { SliderSingleProps } from 'antd';
import AntdSlider from 'components/AntdSlider';
import { Box } from 'components/_main';
import React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';

interface SliderProps extends SliderSingleProps {
  disableClick?: boolean;
}

export const Slider = ({ disableClick, ...restProps }: SliderProps) => {
  const theme = useTheme();
  return (
    <AntdSlider
      min={0}
      max={1}
      css={css`
        ${disableClick &&
        css`
          pointer-events: none;
        `}

        .ant-slider-rail {
          height: 2px;
          background: ${theme.colors.lighter};
        }

        .ant-slider-track {
          height: 2px;
          background: ${theme.colors.dark};
        }
      `}
      {...restProps}
    />
  );
};

export const PlayButtonStyled = styled(Box)(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0 0 0 10px;

    svg {
      width: 24px;
      height: 24px;
    }
  `
);
