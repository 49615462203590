import React from 'react';
import styled, { css } from 'styled-components/macro';
import { BigTitleNumberUnit } from '../HelperDetailsCard.ui';

export const PerformanceTabRoot = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;
`;

export const PerformanceBlock = styled(({ className, title, children }) => (
  <div className={className}>
    <div className="title">{title}</div>
    <div className="content">{children}</div>
  </div>
))(
  ({ theme }) => css`
    display: grid;
    grid-auto-flow: row;
    gap: 10px;
    & > .title {
      font-size: 16px;
      font-weight: 500;
      color: ${theme.colors.secondary};
    }
    & > .content {
    }
  `
);

export const ProjectsBlockRoot = styled.div(
  ({ theme }) => css`
    padding: 0 2rem;
    display: grid;
    grid-auto-flow: column;
    border-radius: 7px;
    grid-gap: 10px;
    box-shadow: 0 4px 10px 0 ${theme.colors.grayLightest};
    grid-template-columns: 1fr 30px 1fr 1fr;
  `
);

export const ProjectUnit = (props: any) => (
  <BigTitleNumberUnit {...props} px={'2rem'} py={'.5rem'} />
);

export const VerticalBar = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    &::after {
      content: ' ';
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 1px;
      height: 60%;
      top: 50%;
      background-color: ${theme.colors.grayLight};
    }
  `
);

export const OtherBlockRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
`;

export const SpecializationBlockRoot = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 0;
  width: 100%;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
`;
