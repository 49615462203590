import React from 'react';
import { positiveNumberRules } from 'utils/finalFormValidationHelpers';
import { InputNumber, InputNumberProps } from 'antd';
import styled, { css } from 'styled-components/macro';
import { FormikHelperProp } from 'components/_form/types';

interface InputRateComponentProps extends InputNumberProps {
  value?: string;
}

export const InputRateComponent = styled(
  ({ ...inputProps }: InputRateComponentProps & FormikHelperProp) => {
    const handleChange = (value: string | number | null) => {
      inputProps?.helper && inputProps.helper.setValue(`${value ?? 0}`);
    };

    const parserNumber = (value?: string) => {
      if (!value) return '';
      return parseFloat(value).toFixed(2);
    };

    return (
      <InputNumber
        min="0"
        step={0.01}
        parser={parserNumber}
        {...inputProps}
        onChange={handleChange}
      />
    );
  }
)(
  ({ theme }) => css`
    width: 120px;
    border-radius: 5px;
    border: solid 1px ${theme.colors.lighter};

    &.ant-input-number-affix-wrapper {
      padding: 9px;
      &:hover,
      &:focus {
        border-color: ${theme.colors.primary};
      }
    }
  `
);

export const inputRateRules = [...positiveNumberRules];

export default InputRateComponent;
