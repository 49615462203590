import styled from 'styled-components/macro';
import { Box } from 'components/_main';

export const Root = styled(Box)`
  &.normal {
    display: grid;
    grid-template:
      'title editButton' 1fr
      'email editButton' 1fr / 1fr max-content;
    width: max-content;
    gap: 0.5rem 1rem;

    & > .title {
      font-size: 22px;
      grid-area: title;
      font-weight: 500;
      margin-bottom: 0;
    }

    & > .email {
      grid-area: email;
      font-size: 15px;
      font-weight: 500;
    }

    & > .editButton {
      grid-area: editButton;
      display: grid;
      justify-content: center;
      align-items: center;

      & svg {
        fill: #9e9e9e;
        cursor: pointer;
        width: 25px;
        height: 25px;
        transition: fill 300ms;
        &:hover {
          fill: #404040;
        }
      }
    }

    &.editing {
      max-width: 707px;
      grid-template:
        'firstName lastName controlButtons' 1fr
        'email companyName controlButtons' 1fr / 1fr 1fr max-content;
      gap: 10px;

      [data-role='firstName'] {
        grid-area: firstName;
      }

      [data-role='lastName'] {
        grid-area: lastName;
      }

      [data-role='email'] {
        grid-area: email;
      }

      [data-role='companyName'] {
        grid-area: companyName;
      }

      .control-buttons {
        grid-area: controlButtons;
        display: grid;
        height: 100%;
        align-content: center;
        grid-auto-flow: column;
        grid-auto-rows: max-content;
        justify-content: center;
        align-self: flex-end;
        align-items: center;
        grid-gap: 10px;
      }
    }
  }
`;
