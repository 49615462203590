import { Project } from 'generated/graphql';
import React from 'react';
import { Col, Row, Box, Text, Link } from 'components/_main';
import { trimInstructionName } from 'utils';
import { useTheme } from 'styled-components/macro';

interface ClientInfoProps {
  projectData: Project;
  isFromClientCard?: boolean;
  handleOpenClientCard: () => void;
}

function ClientInfo({ projectData, handleOpenClientCard }: ClientInfoProps) {
  const theme = useTheme();

  return (
    <Row>
      <Row>
        <Text variant="ui-small-bold" upper>
          Client Name
        </Text>
        <Link onClick={handleOpenClientCard}>
          <Text variant="ui-1-bold" colorVariant="primary">
            {projectData?.client?.firstName} {projectData?.client?.lastName}
          </Text>
        </Link>
      </Row>
      <Row>
        <Text variant="ui-small-bold" upper>
          Client Company
        </Text>
        <Text>{projectData?.client?.companyName}</Text>
      </Row>
      <Row>
        {(projectData.clientShortInstruction ||
          projectData?.clientInstructionUrl?.length! > 0) && (
          <Text variant="ui-small-bold" upper>
            Client Instructions
          </Text>
        )}
        {projectData.clientShortInstruction && (
          <Text width="100%" css="word-break: break-all">
            {projectData.clientShortInstruction}
          </Text>
        )}
        {projectData?.clientInstructionUrl?.length! > 0 && (
          <Box maxHeight="200px" overflowY="auto">
            {projectData?.clientInstructionUrl?.map((file, key) => {
              if (!file) return null;
              return (
                <Col
                  key={file}
                  gridGap="8px"
                  padding="8px 0"
                  borderBottom={`1px solid ${theme.colors.lighter}`}
                >
                  <Text variant="ui-1-medium" colorVariant="light">
                    {key + 1}.
                  </Text>
                  <Link href={file}>{trimInstructionName(file)}</Link>
                </Col>
              );
            })}
          </Box>
        )}
      </Row>
    </Row>
  );
}

export default ClientInfo;
