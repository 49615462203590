import { Rule } from 'antd/es/form';

export const rules: Rule[] = [
  {
    type: 'email',
    message: 'Must be valid email'
  },
  {
    required: true,
    message: 'Must not be empty'
  }
];
