import React, { forwardRef, PropsWithChildren } from 'react';
import { Button } from './Button';
import { ButtonProps, Variant } from './types';

export const buttonWithVariant = (
  variant: keyof typeof Variant,
  props?: Partial<ButtonProps>
) =>
  forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(
    ({ children, ...restProps }, ref) => (
      <Button ref={ref} variant={variant} {...props} {...restProps}>
        {children}
      </Button>
    )
  );
