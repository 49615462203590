import React from 'react';
import { Box } from 'components/_main';
import { RobotAvatarPlaceholderImage, RobotPremiumAvatarImage } from 'assets';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { css, useTheme } from 'styled-components/macro';
import { AvatarImageGeneratorProps } from './AvatarGenerator.types';

function AvatarImageGenerator({ bg, small }: AvatarImageGeneratorProps) {
  const { isPremium } = useGetUserSessionData();
  const theme = useTheme();
  const size = small ? 40 : 160;

  const style = css`
    ellipse#bg {
      fill: ${bg || theme.colors.bg};
    }
  `;

  return (
    <Box
      width={size}
      height={size}
      borderRadius="50%"
      backgroundColor={bg || theme.colors.bg}
    >
      {isPremium ? (
        <RobotPremiumAvatarImage width={size} height={size} css={style} />
      ) : (
        <RobotAvatarPlaceholderImage width={size} height={size} />
      )}
    </Box>
  );
}

export default AvatarImageGenerator;
