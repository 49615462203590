import { SortByDateOrder, useListProjectsQuery } from 'generated/graphql';
import useSignOut from './useSignOut';

export default function useCheckIfNoProjectExists() {
  const signOut = useSignOut();
  const { data: projectsList, loading } = useListProjectsQuery({
    fetchPolicy: 'network-only',
    variables: {
      page: 0,
      sortByDateOrder: SortByDateOrder.Desc
    },
    onError: (error) => {
      if (
        error?.message ===
        "Cannot use 'in' operator to search for 'companyName' in null"
      ) {
        signOut();
      }
    }
  });

  return {
    isNoProjects:
      !loading &&
      (!projectsList?.listProjects?.projects ||
        !(projectsList?.listProjects?.projects?.length > 0)),
    loading: loading
  };
}
