import { InvitedHelper } from 'appTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { varEnv } from 'utils/envVars';
import { INVITE_HELPER_CONTEXT_HOOK_RETURN_INIT } from './InviteHelpers.constants';
import {
  InviteHelpersContextHookProps,
  InviteHelpersContextHookReturnProps,
  Tabs
} from './InviteHelpers.types';

function useInviteHelpers({
  onSetIsRequestCustomHelpers,
  onSelectedHelpersUpdate,
  initiallySelectedHelpers,
  isPremium
}: InviteHelpersContextHookProps): InviteHelpersContextHookReturnProps {
  const [selectedHelpers, setSelectedHelpers] = useState(
    initiallySelectedHelpers ?? []
  );

  useEffect(() => {
    if (initiallySelectedHelpers) {
      setSelectedHelpers(initiallySelectedHelpers);
    }
  }, [initiallySelectedHelpers]);

  const isAllowToInviteMore =
    isPremium || selectedHelpers?.length < varEnv.maxFreeHelpersInvite;

  const isAllowToInviteMoreCustomCounter = useCallback(
    (counter: number) => isPremium || counter < varEnv.maxFreeHelpersInvite,
    [isPremium]
  );

  const handleIsSetIsRequestCustomHelpers = useCallback(
    (newValue: Tabs) => {
      if (newValue === 'request') {
        onSetIsRequestCustomHelpers && onSetIsRequestCustomHelpers(true);
        return;
      }

      onSetIsRequestCustomHelpers && onSetIsRequestCustomHelpers(false);
    },
    [onSetIsRequestCustomHelpers]
  );

  const handleSetSelectedHelpers = useCallback(
    (
      cb: ((prevHelpers: InvitedHelper[]) => InvitedHelper[]) | InvitedHelper[]
    ) => {
      setSelectedHelpers((prev) => {
        let newInvited = [];
        if (typeof cb === 'function') {
          newInvited = cb(prev);
        } else {
          newInvited = cb;
        }
        onSelectedHelpersUpdate && onSelectedHelpersUpdate(newInvited);
        return newInvited;
      });
    },
    [onSelectedHelpersUpdate]
  );

  return useMemo(
    () => ({
      ...INVITE_HELPER_CONTEXT_HOOK_RETURN_INIT,
      isAllowToInviteMoreCustomCounter,
      isAllowToInviteMore,
      setIsRequestCustomHelpers: handleIsSetIsRequestCustomHelpers,
      selectedHelpers,
      setSelectedHelpers: handleSetSelectedHelpers
    }),
    [
      isAllowToInviteMoreCustomCounter,
      isAllowToInviteMore,
      handleIsSetIsRequestCustomHelpers,
      selectedHelpers,
      handleSetSelectedHelpers
    ]
  );
}

export default useInviteHelpers;
