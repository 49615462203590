import { AnnotationUnitProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { MutableRefObject } from 'react';

interface Props {
  groupIndex?: number;
  svgRef: MutableRefObject<SVGElement | null>;
}

interface DotsPercentRelPos {
  x: number;
  y: number;
  relPercentX: number;
  relPercentY: number;
  unitId?: string;
}

export default function useUpdateDotsOnOuterRectResize({
  groupIndex,
  svgRef
}: Props) {
  const { onInitAnnotationUnits } = useAnnotationSystemActions();
  const { annotationUnits, curZoomLevel } = useAnnotationSystemState();

  const sameGroupIndexDots = annotationUnits.filter(
    (unit) => unit.groupIndex === groupIndex && unit.type === 'dot'
  );

  const excludeIds = sameGroupIndexDots.map((dot) => dot.unitId);

  return () => {
    const element: SVGElement | null = svgRef.current;

    if (!element) return;

    const dotsRefs = document.querySelectorAll<SVGElement>(
      `[data-group-index="${groupIndex}"][data-type='dot']`
    );

    if (!dotsRefs || dotsRefs.length === 0) return;

    const dotsPercentRelPos = Array.from(dotsRefs).map((dot) => ({
      x: parseInt(dot.getAttribute('x') ?? '', 10),
      y: parseInt(dot.getAttribute('y') ?? '', 10),
      relPercentX: parseInt(dot.dataset?.relPercentX ?? '', 10),
      relPercentY: parseInt(dot.dataset?.relPercentY ?? '', 10),
      unitId: dot.dataset?.unitId
    }));

    let objOfDotsRefs: Record<string, DotsPercentRelPos> = {};

    for (const dot of dotsPercentRelPos) {
      if (!dot?.unitId) continue;

      objOfDotsRefs[dot.unitId] = {
        ...dot
      };
    }

    let newUnits: AnnotationUnitProps[] = [];

    const newX = parseInt(element.getAttribute('x') ?? '0', 10);
    const newY = parseInt(element.getAttribute('y') ?? '0', 10);
    const newW = parseInt(element.getAttribute('width') ?? '0', 10);
    const newH = parseInt(element.getAttribute('height') ?? '0', 10);
    const startX = parseInt(element.dataset.startPosX ?? '0', 10);
    const startY = parseInt(element.dataset.startPosY ?? '0', 10);
    const startW = parseInt(element.dataset.startPosW ?? '0', 10);
    const startH = parseInt(element.dataset.startPosH ?? '0', 10);
    const startR = startX + startW;
    const startB = startY + startH;
    const newR = newX + newW;
    const newB = newY + newH;

    for (const dot of sameGroupIndexDots) {
      const dotX = objOfDotsRefs[dot.unitId].x;
      const dotY = objOfDotsRefs[dot.unitId].y;
      const dotRelPercentX = objOfDotsRefs[dot.unitId].relPercentX;
      const dotRelPercentY = objOfDotsRefs[dot.unitId].relPercentY;
      const dotRelInvertPercentX = 100 - dotRelPercentX;
      const dotRelInvertPercentY = 100 - dotRelPercentY;

      const deltaX = ((newW - startW) * dotRelPercentX) / 100;
      const deltaInvertX = ((newW - startW) * dotRelInvertPercentX) / 100;
      const deltaY = ((newH - startH) * dotRelPercentY) / 100;
      const deltaInvertY = ((newH - startH) * dotRelInvertPercentY) / 100;

      let newCoord = {
        x: dotX,
        y: dotY
      };

      if (newX > startX) {
        newCoord.x = dotX - deltaInvertX;
      }

      if (newX < startX) {
        newCoord.x = dotX - deltaInvertX;
      }

      if (newY > startY) {
        newCoord.y = dotY - deltaInvertY;
      }

      if (newY < startY) {
        newCoord.y = dotY - deltaInvertY;
      }

      if (newR > startR) {
        newCoord.x = dotX + deltaX;
      }
      if (newR < startR) {
        newCoord.x = dotX + deltaX;
      }

      if (newB > startB) {
        newCoord.y = dotY + deltaY;
      }

      if (newB < startB) {
        newCoord.y = dotY + deltaY;
      }

      newUnits.push({
        ...dot,
        x: newCoord.x / curZoomLevel,
        y: newCoord.y / curZoomLevel
      });
    }

    onInitAnnotationUnits([
      ...annotationUnits.filter((unit) => !excludeIds.includes(unit.unitId)),
      ...newUnits
    ]);
  };
}
