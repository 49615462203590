import React from 'react';
import { useUpdateClientsMutation } from 'generated/graphql';
import { toast } from 'utils/toast';

function useClientEditMutationProvider({
  component: Component,
  refetchQueries,
  onClose
}: any) {
  const [updateMutation, { loading }] = useUpdateClientsMutation({
    onCompleted: () => {
      toast.success('Client successfully created');
      onClose && onClose();
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: () => refetchQueries
  });

  const handleSubmit = (values: any) => {
    return updateMutation({
      variables: {
        ...values,
        firstName: values.firstName,
        lastName: values.lastName,
        clientId: parseInt(values.id, 10)
      }
    });
  };

  return [
    <Component onSubmit={handleSubmit} loading={loading} />,
    loading
  ] as const;
}

export default useClientEditMutationProvider;
