import { Form } from 'antd';

export const withFormDisable = (
  element: React.ReactElement,
  disabled?: boolean
) => {
  if (disabled) {
    return <Form disabled>{element}</Form>;
  }

  return <>{element}</>;
};
