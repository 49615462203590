import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import useVideo from 'hooks/useVideo';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { TimelineFrame } from './AnnotationSystem.types';
import {
  getApproximateFrameToTime,
  getNearestFrameToTime,
  setTimelineFrames
} from './utils';
import { roundTimestampsToMs } from './reducer/utils';

export interface VideoCtxProps {
  curFrame: number;
  frames: TimelineFrame[];
  setCurFrame(frame: number): void;
  updateFramesWithTime(time: number): void;
  isPlaying: boolean;
  curVidTime: number;
  onStop(): void;
  onPlay(): void;
  onSetCurTime(n: number): void;
  vidDuration: number;
  totalFrames: number;
  vidStart: number | null;
  vidEnd: number | null;
  mediaVideoRef: HTMLVideoElement | null;
  isVideo?: boolean;
}

const timeChangeSubs = new Map();

export const videoCtx = createContext<VideoCtxProps>({
  curFrame: 0,
  setCurFrame: () => null,
  frames: [],
  updateFramesWithTime: () => null,
  isPlaying: false,
  curVidTime: 0,
  onStop: () => null,
  onSetCurTime: () => null,
  vidDuration: 0,
  totalFrames: 0,
  onPlay: () => null,
  vidEnd: 0,
  vidStart: 0,
  mediaVideoRef: null,
  isVideo: false
});

export const useVideoCtx = () => {
  return useContext(videoCtx);
};

export const useVideoCtxLogic = () => {
  const { mediaVideoRef, videoStart, videoEnd, videoFPS } =
    useAnnotationSystemState();
  const [curFrame, setCurFrame] = useState(0);
  const [curTime, setCurTime] = useState(0);

  const vidDuration = (videoEnd ?? 0) - (videoStart ?? 0);
  const timelineFrames: TimelineFrame[] = useMemo(
    () => setTimelineFrames(vidDuration, videoFPS),
    [vidDuration, videoFPS]
  );
  const total = timelineFrames.length - 1;

  const { onSetCurTime, isPlaying, onPlayToggle, onStop, onPlay } = useVideo({
    videoRef: mediaVideoRef,
    onTimeChange: (newTime: number) => {
      if (!videoEnd) return;
      const newCurFrame = getNearestFrameToTime(timelineFrames, newTime);
      setCurFrame(() => {
        if (timelineFrames[newCurFrame]?.timestamp) {
          setCurTime(timelineFrames[newCurFrame].timestamp);
        }
        return newCurFrame;
      });
    }
  });

  const handleSetCurTime = useCallback(
    (time: number) => {
      const timeRounded = roundTimestampsToMs(time);
      onSetCurTime(timeRounded);
      setCurTime(timeRounded);
    },
    [onSetCurTime]
  );

  const handleSetCurFrameWithTime = useCallback(
    (newFrame: number) => {
      const newTime = timelineFrames[newFrame].timestamp;
      setCurFrame((prevFrame) => {
        handleSetCurTime(newTime);
        return newFrame;
      });
    },
    [handleSetCurTime, timelineFrames]
  );

  const updateFramesWithTime = useCallback(
    (time: number) => {
      if (!videoEnd) return;
      let newCurFrame = getApproximateFrameToTime(time, videoEnd, total);
      setCurFrame(newCurFrame);
      timelineFrames?.[newCurFrame]?.timestamp &&
        handleSetCurTime(timelineFrames?.[newCurFrame]?.timestamp);
    },
    [handleSetCurTime, timelineFrames, total, videoEnd]
  );

  const value = useMemo(
    () => ({
      isVideo: vidDuration > 0,
      curFrame,
      onSetCurTime: handleSetCurTime,
      setCurFrame: handleSetCurFrameWithTime,
      frames: timelineFrames,
      updateFramesWithTime,
      onPlayToggle,
      isPlaying,
      curVidTime: curTime,
      onStop,
      onPlay,
      timeChangeSubs,
      vidDuration,
      totalFrames: total,
      vidStart: videoStart,
      vidEnd: videoEnd,
      mediaVideoRef
    }),
    [
      curFrame,
      handleSetCurTime,
      handleSetCurFrameWithTime,
      timelineFrames,
      updateFramesWithTime,
      onPlayToggle,
      isPlaying,
      curTime,
      onStop,
      onPlay,
      vidDuration,
      total,
      videoStart,
      videoEnd,
      mediaVideoRef
    ]
  );

  return value;
};

export const VideoCtxProvider = ({ children }: React.PropsWithChildren) => {
  const value = useVideoCtxLogic();

  return <videoCtx.Provider value={value}>{children}</videoCtx.Provider>;
};
