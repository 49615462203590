import { Modal } from 'components/_modals';
import React, { useEffect } from 'react';
import InputLabel from 'components/InputLabel';
import Input from 'components/Input';
import { ProjectDetails } from 'appTypes';
import { checkIfTitleError } from 'utils/finalFormValidationHelpers';
import { Z_INDEXES } from 'appConstants';
import { Button, Col, Row, Text } from 'components/_main';

interface CopyEnterNewNameModalProps {
  isVisible: boolean;
  onCancel: () => void;
  data: ProjectDetails;
  onOk: (newName: string) => void;
}

function CopyEnterNewNameModal({
  isVisible,
  onOk: handleOnOk,
  onCancel,
  data
}: CopyEnterNewNameModalProps) {
  const [newName, setNewName] = React.useState(data?.title);
  const [errorMsg, setErrorMsg] = React.useState('');

  useEffect(() => {
    if (isVisible) {
      setNewName(data.title);
    } else {
      setNewName('');
    }
  }, [isVisible, data.id, data.title]);

  useEffect(() => {
    if (!newName) {
      setErrorMsg('Required');
      return;
    }

    const error = checkIfTitleError(newName);

    if (error) {
      setErrorMsg(error);
      return;
    }

    setErrorMsg('');
    return;
  }, [newName]);

  return (
    <Modal
      width="500px"
      title="Please, enter new project name"
      isVisible={isVisible}
      onCancel={onCancel}
      formLayout="vertical"
      okButtonProps={{
        disabled: !newName || !!errorMsg
      }}
      cancelText="Cancel"
      okText="OK"
      onOk={() => {
        handleOnOk(newName);
      }}
    >
      <Row>
        <InputLabel
          layout="vertical"
          label="New title"
          validateStatus={errorMsg ? 'error' : 'success'}
          help={errorMsg}
        >
          <Input
            value={newName}
            onChange={(e: any) => {
              const value = e.target.value;
              setNewName(value);
            }}
          />
        </InputLabel>
      </Row>
    </Modal>
  );
}

export default CopyEnterNewNameModal;
