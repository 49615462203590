import React, { useMemo, useRef } from 'react';
import { Dropdown, Menu } from 'antd';
import { Box, Col, Text } from 'components/_main';
import {
  MAX_ZOOM,
  MIN_ZOOM
} from 'containers/AnnotationSystem/AnnotationSystem.constants';
import {
  ArrowDownIcon,
  ZoomInIcon,
  ZoomOutIcon
} from 'assets/taskingTopToolbar';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { useTheme } from 'styled-components/macro';
import { ToolbarIcon, ToolbarSection } from './AnnotationToolbar.ui';

export default function SectionZoom() {
  const toolbarRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { curZoomLevel } = useAnnotationSystemState();

  const { onZoomIn, onZoomOut, onZoomReset, onSetZoomLevel } =
    useAnnotationSystemActions();

  const menu = useMemo(
    () => (
      <Menu
        items={[
          {
            key: '1',
            onClick: () => onSetZoomLevel(1),
            label: '100%'
          },
          {
            key: '2',
            onClick: () => onSetZoomLevel(1.5),
            label: '150%'
          },
          {
            key: '3',
            onClick: () => onSetZoomLevel(2),
            label: '200%'
          },
          {
            key: '4',
            onClick: () => onZoomReset(null),
            label: 'Zoom to fit'
          }
        ]}
      />
    ),
    [onSetZoomLevel, onZoomReset]
  );

  return (
    <Box ref={toolbarRef}>
      <ToolbarSection>
        <ToolbarIcon
          icon={<ZoomOutIcon />}
          disabled={curZoomLevel <= MIN_ZOOM}
          onClick={() => {
            onZoomOut(null);
          }}
        />
        <Dropdown
          overlayStyle={{
            width: toolbarRef?.current?.clientWidth
          }}
          placement="bottom"
          overlay={menu}
          getPopupContainer={() => toolbarRef?.current!}
          trigger={['click']}
        >
          <Col
            height="100%"
            padding="0 1rem"
            displayFlex
            alignItems="center"
            gridGap="8px"
            gridAutoColumns="max-content max-content"
            borderRight={`1px solid ${theme.colors.lightest}`}
            css={`
              transition: 200ms all;
              &:hover {
                background-color: ${theme.colors.grayLighter};
              }
            `}
          >
            <Text textAlign="right">{Math.floor(curZoomLevel * 100)}%</Text>
            <ArrowDownIcon />
          </Col>
        </Dropdown>

        <ToolbarIcon
          icon={<ZoomInIcon />}
          disabled={curZoomLevel >= MAX_ZOOM}
          onClick={() => {
            onZoomIn(null);
          }}
        />
      </ToolbarSection>
    </Box>
  );
}
