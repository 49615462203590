import useAnnotationSystemLabels from '../context/useAnnotationSystemLabels';
import { getUnitColor } from '../utils';

export default function useLabelColor(labelArg: string) {
  const labels = useAnnotationSystemLabels();

  let index = labels.findIndex((label) => label === labelArg);

  index = index === -1 ? 0 : index;

  return getUnitColor(index);
}
