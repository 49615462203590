import React from 'react';
import { WithFastField } from 'components/_form';
import { Tab, Tabs } from 'components/Tabs/Tabs.styled';
import { Project } from 'generated/graphql';
import AssignedHelpers from './tabs/AssignedHelpers';
import General from './tabs/General';
import ClientInfo from './tabs/ClientInfo';
import HelperInfo from './tabs/HelperInfo';
import { ProjectTabType } from 'containers/ProjectDetailsCard/constants';
import { useCtx } from './ProjectDetailsCard.Context';
import FinishedTasksPreviewDataProvider from 'containers/ProjectDetailsCard/tabs/FinishedTasksPreview/FinishedTasksPreviewDataProvider';

type TProjectEdit = {
  projectData: Project;
  setProjectDetailsTab(tab: string): void;
};

const ProjectDetailsCardModalContentTabs = ({
  projectData,
  setProjectDetailsTab
}: TProjectEdit) => {
  const { setClientModal } = useCtx();

  const handleOpenClientCard = () => {
    setClientModal({
      isOpen: true,
      data: projectData.client
    });
  };

  return (
    <Tabs
      mb="30px"
      defaultActiveKey={ProjectTabType.General}
      style={{
        padding: '0',
        height: '100%'
      }}
      onChange={setProjectDetailsTab}
    >
      <Tab data-testid="tab-general" tab="General" key={ProjectTabType.General}>
        <WithFastField>
          <General projectData={projectData} />
        </WithFastField>
      </Tab>
      <Tab
        data-testid="tab-client-info"
        tab="Client"
        key={ProjectTabType.Client}
      >
        <WithFastField>
          <ClientInfo
            projectData={projectData}
            handleOpenClientCard={handleOpenClientCard}
          />
        </WithFastField>
      </Tab>
      <Tab
        data-testid="tab-helper-info"
        tab="Helper"
        key={ProjectTabType.Helper}
      >
        <WithFastField>
          <HelperInfo />
        </WithFastField>
      </Tab>
      <Tab
        data-testid="tab-assigned"
        tab="assigned"
        key={ProjectTabType.Assigned}
      >
        <WithFastField>
          <AssignedHelpers projectData={projectData} />
        </WithFastField>
      </Tab>
      <Tab data-testid="tab-results" tab="Tasks" key={ProjectTabType.Results}>
        <WithFastField>
          <FinishedTasksPreviewDataProvider projectData={projectData} />
        </WithFastField>
      </Tab>
    </Tabs>
  );
};

export default ProjectDetailsCardModalContentTabs;
