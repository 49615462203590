import React, { useCallback, useRef } from 'react';
import { Row, Text } from '../../components/_main';
import { consoleError } from '../../utils';
import { UploadZone } from './components';
import { UploadFileItem } from './UploadFiles.ui';
import UploadFilesUploader from './UploadFilesUploader';
import {
  UploadFilesContextProvider,
  useUploadFilesContext
} from './UploadFiles.context';
import { toast } from 'utils/toast';
import { UploaderProps, UploadFilesProps } from './UploadFiles.types';
import { MAX_FILES } from './UploadFiles.constants';

export const UploadFilesDataConsumer = ({
  accept,
  multiple,
  isLoading,
  onUpload,
  maxSizePerFile,
  maxTotalSize,
  uploadFilesBlock = (
    <Text variant="ui-small-2-bold" upper>
      Upload files
    </Text>
  ),
  ...restProps
}: UploaderProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const maxFiles = restProps?.maxFiles ?? MAX_FILES;

  const {
    files,
    onStartUploading,
    onFinishUploading,
    onError,
    onCancel,
    onDelete
  } = useUploadFilesContext();

  const handleFilesUpdate = useCallback(
    async (newFiles: File[]) => {
      if (files.length + newFiles.length > maxFiles) {
        toast.error(`Only ${maxFiles} files allowed to download.`);
        return;
      }

      for (const file of Array.from(newFiles)) {
        const isOk = onStartUploading(file);

        if (!isOk) {
          continue;
        }

        try {
          const fileNameFromBe = await (
            onUpload ?? ((file) => Promise.resolve(file.name))
          )(file);
          onFinishUploading(file, fileNameFromBe);
        } catch (e: any) {
          const message = 'Error while file uploading';

          if ('message' in e) {
            toast.error(e.message);
            onError(file.name, e.message);
            return;
          }

          onError(file.name, e.message);
          toast.error(message);
          consoleError(message, e);
        }
      }
    },
    [
      files.length,
      maxFiles,
      onError,
      onFinishUploading,
      onStartUploading,
      onUpload
    ]
  );

  const uploadManuallyZoneRender = files.length < maxFiles && (
    <Row gridGap="20px">
      {uploadFilesBlock}
      <UploadFilesUploader
        inputRef={inputRef}
        loading={isLoading}
        onFilesUpdate={handleFilesUpdate}
        element={
          <UploadZone
            accept={accept}
            maxTotalSize={maxTotalSize}
            maxSizePerFile={maxSizePerFile}
          />
        }
        accept={accept}
        multiple={multiple}
      />
    </Row>
  );

  return (
    <Row gridGap="34px">
      {uploadManuallyZoneRender}
      {files?.length > 0 && (
        <Row maxWidth="370px">
          {files?.map((file, index) => (
            <UploadFileItem
              key={file.fileName}
              index={index}
              state={file.state}
              fileName={file.fileName}
              onDelete={onDelete}
              onCancel={onCancel}
            />
          ))}
        </Row>
      )}
    </Row>
  );
};

export const UploadFiles = (props: UploadFilesProps) => {
  return (
    <UploadFilesContextProvider initialData={props?.initialData}>
      <UploadFilesDataConsumer
        {...props?.uploaderProps}
        maxSizePerFile={props?.initialData?.maxSizePerFile}
        maxTotalSize={props?.initialData?.maxTotalSize}
      />
    </UploadFilesContextProvider>
  );
};

export default UploadFiles;
