import React, { useCallback, useContext, useState } from 'react';
import { consoleError } from 'utils';
import arrayInsert from 'utils/arrayInsert';
import arrayRemove from 'utils/arrayRemove';
import { CONTEXT_VALUES } from './UploadFiles.constants';
import {
  FileState,
  UploadFilesContextHookProps,
  UploadFilesContextProviderProps,
  UploadFilesContextValues
} from './UploadFiles.types';
import {
  convertFileStatesToFile,
  convertToFileStates
} from './UploadFiles.utils';

const context = React.createContext<UploadFilesContextValues>(CONTEXT_VALUES);

function handleFieldSetterMapper(files: FileState[]) {
  return files
    ? (files.map((file) => file.fileName).filter(Boolean) as string[])
    : [];
}

export function useUploadFilesContextProvider(
  props?: Partial<UploadFilesContextHookProps>
): UploadFilesContextValues {
  const [state, setState] = useState<UploadFilesContextValues>({
    ...CONTEXT_VALUES,
    files: convertToFileStates(props?.initialFiles) as FileState[]
  });

  const modifyFile = useCallback(
    (
      fileName: string,
      newState: Partial<FileState>,
      files: UploadFilesContextValues['files']
    ) => {
      let newE = files.find((e) => e.fileName === fileName);
      return files.map((e) => (e === newE ? { ...newE, ...newState } : e));
    },
    []
  );

  const deleteFile = useCallback(
    (fileName: string, files: UploadFilesContextValues['files']) =>
      arrayRemove(files, { fileName }, 'fileName'),
    []
  );

  const addFile = useCallback(
    (file: File, files: UploadFilesContextValues['files']): FileState[] =>
      arrayInsert(files, {
        fileName: file.name,
        file,
        state: 'new' as const
      }),
    []
  );

  const getFile = useCallback(
    (fileName: string, files: UploadFilesContextValues['files']) =>
      files.find((file) => file['fileName'] === fileName) ?? null,
    []
  );

  const handleError = useCallback(
    (fileName: string, error?: string) => {
      consoleError({ fileName, error });
      setState((prevState) => {
        const newFiles = modifyFile(
          fileName,
          {
            state: 'error',
            error
          },
          prevState.files
        );

        setTimeout(() => {
          props?.setLoading && props.setLoading(false);
          props?.onError && props.onError(fileName, error);
          props?.fieldErrorSetter && props.fieldErrorSetter(error);
        }, 0);

        return {
          ...prevState,
          files: newFiles,
          isUploading: false
        };
      });
    },
    [props, modifyFile]
  );

  const handleStartUploading = useCallback(
    (file: File): boolean => {
      if (props?.maxSizePerFile && file.size > props.maxSizePerFile) {
        handleError(file.name, 'Max size per file reached');
        return false;
      }

      setState((prevState) => {
        if (
          prevState.files.length > 0 &&
          prevState.files.findIndex(
            (prevFile) => prevFile.fileName === file.name
          ) !== -1
        ) {
          consoleError('Duplication', file.name);
          return prevState;
        }

        const curTotalSize =
          prevState.files.reduce(
            (totalSize, file) => totalSize + (file?.file?.size ?? 0),
            0
          ) + file.size;

        if (props?.maxTotalSize && curTotalSize > props?.maxTotalSize) {
          handleError(file.name, 'Max sum size of all files reached');
          return prevState;
        }

        props?.onStart && props.onStart(file.name);
        props?.setLoading && props.setLoading(true);
        return {
          ...prevState,
          files: addFile(file, prevState.files),
          isUploading: true
        };
      });

      return true;
    },
    [addFile, handleError, props]
  );

  const handleFinishUploading = useCallback(
    (file: File, fileNameFromBe: string) => {
      setState((prevState) => {
        const newFileStates = modifyFile(
          file.name,
          {
            state: 'uploaded',
            fileName: fileNameFromBe
          },
          prevState.files
        );

        const newFiles = convertFileStatesToFile(newFileStates) as File[];

        setTimeout(() => {
          props?.setLoading && props.setLoading(false);
          props?.onSuccess &&
            props.onSuccess({
              fileNameFromBe,
              files: newFiles,
              fileStates: newFileStates
            });

          props?.fieldValueSetter &&
            props.fieldValueSetter(handleFieldSetterMapper(newFileStates));
          props?.onFilesUpdate &&
            props.onFilesUpdate({
              fileStates: newFileStates,
              files: newFiles,
              fileNameFromBe
            });
        }, 0);

        return {
          ...prevState,
          files: newFileStates,
          isUploading: false
        };
      });
    },
    [props, modifyFile]
  );

  const handleCancel = useCallback(
    (fileName: string) => {
      setState((prevState) => {
        if (props?.abortController?.current) {
          props.abortController.current.abort();
          props.abortController.current = new AbortController();
        }
        props?.onCancel && props.onCancel(fileName);
        setTimeout(() => {
          props?.setLoading && props.setLoading(false);
        }, 0);
        return {
          ...prevState,
          files: deleteFile(fileName, prevState.files),
          isUploading: false
        };
      });
    },
    [deleteFile, props]
  );

  const handleDelete = useCallback(
    (fileName: string) => {
      setState((prevState) => {
        const newFileStates = deleteFile(fileName, prevState.files);

        setTimeout(() => {
          const newFilesForDelete = convertFileStatesToFile(newFileStates);
          props?.onDelete &&
            props.onDelete({
              fileName,
              fileStates: newFileStates,
              files: newFilesForDelete as File[]
            });
          props?.fieldValueSetter &&
            props.fieldValueSetter(handleFieldSetterMapper(newFileStates));
          props?.onFilesUpdate &&
            props.onFilesUpdate({
              fileStates: newFileStates,
              files: newFilesForDelete,
              fileNameFromBe: fileName
            });
        }, 0);

        return {
          ...prevState,
          files: newFileStates,
          isUploading: false
        };
      });
    },
    [deleteFile, props]
  );

  const handleGetFile = useCallback(
    (fileName: string) => {
      return getFile(fileName, state.files);
    },
    [getFile, state.files]
  );

  return {
    files: state.files,
    onFinishUploading: handleFinishUploading,
    onStartUploading: handleStartUploading,
    onCancel: handleCancel,
    onError: handleError,
    onDelete: handleDelete,
    onGetFile: handleGetFile
  };
}

export function UploadFilesContextProvider({
  children,
  initialData
}: React.PropsWithChildren<
  Pick<UploadFilesContextProviderProps, 'initialData'>
>) {
  const values = useUploadFilesContextProvider(initialData);
  return <context.Provider value={values}>{children}</context.Provider>;
}

export function useUploadFilesContext() {
  return useContext(context);
}
