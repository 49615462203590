import { Box, Col, Row, Text } from 'components/_main';
import React, { PropsWithChildren, useState } from 'react';
import { css } from 'styled-components/macro';
import { scrollStyled } from 'theme';
import { ReactComponent as ExpandOpenedIcon } from './expandOpened.svg';
import { ReactComponent as ExpandClosedIcon } from './expandClosed.svg';

interface Props {
  defaultOpen?: boolean;
  expandable?: boolean;
  title: string | React.ReactNode;
  titleContentGap?: string;
}

export default function ExpandBlock({
  title,
  children,
  defaultOpen = true,
  expandable = true,
  titleContentGap = '10px'
}: PropsWithChildren<Props>) {
  const [isExpanded, setExpanded] = useState(defaultOpen);

  const handleTrigger = () => {
    if (expandable) {
      setExpanded((previousState) => !previousState);
    }
  };

  const renderTitle =
    typeof title === 'string' ? (
      <Text variant="ui-small-bold" upper>
        {title}
      </Text>
    ) : (
      title
    );

  return (
    <Row
      noGap
      height="100%"
      gridAutoRows="minmax(0px, min-content)"
      overflow="hidden"
    >
      <Col
        px="14px"
        pr="16px"
        displayFlex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="10px"
        onClick={handleTrigger}
      >
        {renderTitle}
        {expandable &&
          (isExpanded ? <ExpandOpenedIcon /> : <ExpandClosedIcon />)}
      </Col>
      {isExpanded && (
        <Box
          paddingTop={titleContentGap}
          overflowY="auto"
          height="100%"
          css={css`
            ${scrollStyled}
          `}
        >
          {children}
        </Box>
      )}
    </Row>
  );
}
