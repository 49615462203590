import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { Client, useListClientsQuery } from 'generated/graphql';
import columnsFunc from './columns';
import { initialValues } from './constants';
import { ColLegacy, RowLegacy } from 'components/_main';
import { RouteContent } from 'components/RouteContent';
import { ButtonPrimary } from 'components/_main/Button';
import { LIST_CLIENTS } from 'graphql/queries/client';
import { TData } from './types';
import ModalCreate from './modals/Create';
import Delete from './modals/Delete';
import { TRefetchQueries } from 'appTypes';
import ClientCard from 'containers/ClientCard';
import StyledTable from 'components/Table';
import { useNavigate } from 'react-router';
import { isAdmin } from 'utils';
import HeaderAppBar from '../HeaderAppBar';
import useSetAppBarTitle from '../../hooks/useSetAppBarTitle';
import useTablePage from 'hooks/useTablePage';
import { CLIENTS_LIST_PAGE } from 'appConstants';

const Clients = () => {
  const { page, setPage } = useTablePage(CLIENTS_LIST_PAGE);
  const history = useNavigate();

  useSetAppBarTitle('Clients');

  const {
    data,
    loading: queryLoading,
    networkStatus
  } = useListClientsQuery({
    variables: {
      page
    }
  });

  const [isModalCreateOpen, setModalCreateOpen] = useState(false);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [curData, setCurData] = useState<TData>(initialValues);

  const onDelete = (data: TData) => {
    if (!data || !data?.id) return console.error('Not data or data.id');
    setCurData(data);
    setModalDeleteOpen(true);
  };

  let dataSource = data?.listClients?.clients ?? [];

  const [clientData, setClientData] = useState<any>({
    data: {},
    isOpen: false
  });

  const curClientId = clientData?.data?.id;

  useEffect(() => {
    if (!data?.listClients?.clients) {
      return;
    }
    for (const row of data?.listClients?.clients) {
      if (!row) {
        return;
      }

      if (curClientId && row.id === curClientId) {
        setClientData((prevClientData: any) => ({
          ...prevClientData,
          data: {
            ...row,
            firstName: row.firstName,
            lastName: row.lastName
          }
        }));
      }
    }
  }, [data, curClientId]);

  const onRowClick = useCallback(
    (data: any) => {
      if (!data?.id) {
        throw new Error('No ID in data object');
      }

      history('/clients/' + data.id);

      setClientData({
        data,
        isOpen: true
      });
    },
    [history]
  );
  const handleOnClose = () => {
    /** TODO: use useRedirect hook everywhere */
    history('/clients');

    setClientData((prevState: any) => ({
      ...prevState,
      isOpen: false
    }));
  };

  const columns = React.useMemo(
    () => columnsFunc({ onDelete, onRowClick }),
    [onRowClick]
  );

  const refetchQueries: TRefetchQueries = useMemo(
    () => [{ query: LIST_CLIENTS, variables: { page } }],
    [page]
  );

  return (
    <div>
      <HeaderAppBar />
      <RouteContent>
        <RowLegacy width={'max'} justifyContent={'space-between'}>
          <ColLegacy> </ColLegacy>
          <ColLegacy>
            {isAdmin() && (
              <ButtonPrimary
                data-component="button-create"
                onClick={() => setModalCreateOpen(true)}
              >
                + Create client
              </ButtonPrimary>
            )}
          </ColLegacy>
        </RowLegacy>
        <StyledTable
          setCurTablePage={setPage as any}
          page={page}
          pagesCount={data?.listClients?.pagesCount}
          columns={columns}
          dataSource={dataSource as Client[]}
          loading={
            queryLoading ||
            networkStatus === NetworkStatus.refetch ||
            networkStatus === NetworkStatus.poll
          }
        />
        <ModalCreate
          refetchQueries={refetchQueries}
          isOpen={isModalCreateOpen}
          setModalOpen={setModalCreateOpen}
        />
        <Delete
          refetchQueries={refetchQueries}
          curData={curData}
          isOpen={isModalDeleteOpen}
          setModalOpen={setModalDeleteOpen}
        />
        <ClientCard
          {...clientData}
          refetchQueries={refetchQueries}
          onClose={handleOnClose}
          onDelete={onDelete}
        />
      </RouteContent>
    </div>
  );
};

export default Clients;
