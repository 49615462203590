import styled from 'styled-components/macro';
import { TableProps } from 'antd/es/table';
import { Table as TableBase } from 'antd';

export const Table = styled(TableBase)<TableProps<any>>`
  margin-top: 20px;

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-thead > tr > th {
    background: transparent;
    font-size: 11px;
    font-weight: bold;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #fafafa;
  }

  .ant-table-cell {
    background-color: #fff;
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-table {
    background: transparent;

    .ant-table-tbody {
      box-shadow: 0 4px 10px 0 ${({ theme }) => theme.colors.grayLight};
    }
  }
`;
