import { useEffect } from 'react';
import _ from 'lodash';
import mapTaskData from '../utils/mapTaskData';
import useStartTasking from 'containers/TasksRouter/hooks/useStartTasking';
import { TTaskDataMapped, TNewTask } from '../Tasks.types';
import useSaveResultsLocally from 'hooks/useSaveResultsLocally';
import { TaskingType } from 'appTypes';
import { useAnnotationViewContext } from 'containers/TasksRouter/AnnotationView/AnnotationView.context';

function isTMappedTask(
  data: TNewTask | TTaskDataMapped
): data is TTaskDataMapped {
  return 'taskType' in data;
}

interface Props {
  type: TaskingType;
  projectId: string;
  taskId: string;
}

interface TaskDataProps {
  taskData: TTaskDataMapped | null;
  setTaskData: (args: TTaskDataMapped | TNewTask) => void;
  isTaskDataLoading: boolean;
  isTaskingDataError: string | undefined;
  practiceEndTime: string;
  startPracticeSession: () => void;
}

const useTaskData = ({ projectId, taskId, type }: Props): TaskDataProps => {
  const {
    startTaskingData,
    isTaskingDataLoading,
    isTaskingDataError,
    practiceEndTime,
    startPracticeSession
  } = useStartTasking(type, undefined, projectId, taskId);
  const { mappedTaskDataWithLocallySavedResults } = useSaveResultsLocally();

  const { taskData, setTaskData } = useAnnotationViewContext();

  useEffect(() => {
    if (!startTaskingData) {
      return;
    }

    setTaskData(
      mappedTaskDataWithLocallySavedResults(mapTaskData(startTaskingData))
    );
  }, [startTaskingData, mappedTaskDataWithLocallySavedResults, setTaskData]);

  const handleOnSetTaskData = (newTaskData: TNewTask | TTaskDataMapped) => {
    if (!startTaskingData) return;

    if (isTMappedTask(newTaskData)) {
      return;
    }

    const newTaskDataMapped: TTaskDataMapped = mapTaskData(newTaskData) || {};

    setTaskData({
      ..._.merge({}, taskData, newTaskDataMapped),
      ...{
        result: newTaskDataMapped.result,
        history: newTaskDataMapped.history ?? []
      }
    });
  };

  return {
    taskData,
    setTaskData: handleOnSetTaskData,
    isTaskDataLoading: isTaskingDataLoading,
    isTaskingDataError,
    practiceEndTime,
    startPracticeSession
  };
};

export default useTaskData;
