import React from 'react';
import { Field, FieldProps } from 'formik';
import { Row, Text } from 'components/_main';
import ProjectRates from 'containers/ProjectRates';
import UploadInstructionsAdmin from 'containers/UploadFilesLegacy/UploadInstructionsAdmin';
import AdminShortInstructionsTextAreaField from '../../components/AdminShortInstrTextAreaField';
import { useCtx } from 'containers/ProjectDetailsCard/ProjectDetailsCard.Context';

function HelperInfo() {
  const { isReadOnly } = useCtx();
  return (
    <Row>
      <ProjectRates disabled={isReadOnly} embedded />
      <Row>
        <Text variant="ui-small-bold">Short instructions</Text>
        <Field name={'adminShortInstruction'}>
          {(fieldProps: FieldProps) => (
            <AdminShortInstructionsTextAreaField
              disabled={isReadOnly}
              {...fieldProps}
            />
          )}
        </Field>
      </Row>
      <Row>
        <UploadInstructionsAdmin
          name={'adminInstructionUrl'}
          disabled={isReadOnly}
        />
      </Row>
    </Row>
  );
}

export default HelperInfo;
