import React, { PropsWithChildren, useCallback } from 'react';
import { useField } from 'formik';
import { Box, Col, Row, Text } from 'components/_main';
import Select from 'components/Select';
import { useTheme } from 'styled-components/macro';
import { Radio, RadioGroup } from 'components/Radio';
import {
  ImageAnnotationDatasetProcessingType,
  ProjectSpecialization,
  VideoAnnotationDatasetSplitByType,
  VideoAnnotationDatasetProcessingType
} from 'appTypes';
import { useAddProjectWizardContext } from 'containers/AddProjectWizard/AddProjectWizard.context';
import {
  DetailedVideoAnnotationTooltip,
  EntireVideoAnnotationTooltip,
  StandardVideoAnnotationTooltip
} from 'containers/AddProjectWizard/steps/components/ProjectSpecializationTypeTooltip';
import { VIDEO_ANNOTATION_PREDEFINED_INTERVALS } from 'appConstants';

interface VideoAnnotationSettingProps {
  settingChunkLengthName?: string;
}

export function ImageAnnotationSettings() {
  const { imageAnnotationSpecSubType, onSetImageAnnotationSpecSubType } =
    useAddProjectWizardContext();

  const handleImageSubTypeChange: React.EventHandler<any> = useCallback(
    (e) => {
      const value = e.target.value;

      onSetImageAnnotationSpecSubType(value);
    },
    [onSetImageAnnotationSpecSubType]
  );

  return (
    <RadioGroup
      value={imageAnnotationSpecSubType}
      onChange={handleImageSubTypeChange}
    >
      <Col>
        <Radio value={ImageAnnotationDatasetProcessingType.SINGLE_ZIP_ARCHIVE}>
          Single ZIP Archive
        </Radio>
        <Radio value={ImageAnnotationDatasetProcessingType.MULTIPLE_IMAGES}>
          Multiple Images Annotation
        </Radio>
      </Col>
    </RadioGroup>
  );
}

export function VideoAnnotationSetting({
  settingChunkLengthName
}: VideoAnnotationSettingProps) {
  const {
    videoAnnotationSpecSubType,
    onSetVideoAnnotationSpecSubType,
    specialization
  } = useAddProjectWizardContext();

  const handleVideoSubTypeChange: React.EventHandler<any> = useCallback(
    (e) => {
      const value = e.target.value;

      onSetVideoAnnotationSpecSubType(value);
    },
    [onSetVideoAnnotationSpecSubType]
  );

  return (
    <Row gridGap="0px">
      <RadioGroup
        value={videoAnnotationSpecSubType}
        onChange={handleVideoSubTypeChange}
      >
        <Col alignItems="center">
          {specialization !== ProjectSpecialization.HUMAN_POSES && (
            <Col gridGap="0px">
              <Radio value={VideoAnnotationDatasetProcessingType.ENTIRE_VIDEO}>
                Entire Video Annotation
              </Radio>
              <EntireVideoAnnotationTooltip />
            </Col>
          )}
          <Col alignItems="center">
            <Radio value={VideoAnnotationDatasetProcessingType.SPLIT_BY_FRAMES}>
              Split by Frames
            </Radio>
          </Col>
        </Col>
      </RadioGroup>
      <Row>
        {videoAnnotationSpecSubType ===
          VideoAnnotationDatasetProcessingType.SPLIT_BY_FRAMES && (
          <VideoAnnotationSplitByFrame
            settingChunkLengthName={settingChunkLengthName}
          />
        )}
      </Row>
    </Row>
  );
}

export function ProjectSplitTypeWrapper({
  isActive,
  children
}: PropsWithChildren<{ isActive?: boolean }>) {
  const theme = useTheme();

  return (
    <Box
      width="284px"
      height="160px"
      borderRadius="4px"
      padding="20px"
      border={`1px solid ${
        isActive ? theme.colors.primary : theme.colors.lighter
      }`}
    >
      {children}
    </Box>
  );
}

export function VideoAnnotationSplitByFrame({
  settingChunkLengthName = 'projectSettings.chunkLength'
}: VideoAnnotationSettingProps) {
  const { videoAnnotationSplitType, onSetVideoAnnotationSplitType } =
    useAddProjectWizardContext();

  const [{ value: chunkLengthValue }, , chunkLengthHelper] = useField(
    settingChunkLengthName
  );

  const selectedVideoInterval =
    VIDEO_ANNOTATION_PREDEFINED_INTERVALS.find(
      ({ value }) => parseFloat(value) === chunkLengthValue
    )?.label ?? VIDEO_ANNOTATION_PREDEFINED_INTERVALS[0].label;

  const handleVideoSplitTypeChange: React.EventHandler<any> = useCallback(
    (e) => {
      const value = e.target.value;

      onSetVideoAnnotationSplitType(value);
    },
    [onSetVideoAnnotationSplitType]
  );

  const handleSelectVideoInterval = useCallback(
    (value: string) => {
      chunkLengthHelper.setValue(parseFloat(value));
    },
    [chunkLengthHelper]
  );

  return (
    <RadioGroup
      value={videoAnnotationSplitType}
      onChange={handleVideoSplitTypeChange}
    >
      <Col marginTop="20px">
        <ProjectSplitTypeWrapper
          isActive={
            videoAnnotationSplitType ===
            VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE
          }
        >
          <Col gridGap="0px">
            <Radio
              value={VideoAnnotationDatasetSplitByType.SPLIT_BY_TIME_RANGE}
            >
              <Text>Standard Annotation</Text>
            </Radio>
            <StandardVideoAnnotationTooltip />
          </Col>
          <Box margin="10px 0 0 27px">
            <Text variant="ui-small-bold" lineHeight="26px">
              Annotate every
            </Text>
            <Box marginTop="5px">
              <Select
                value={selectedVideoInterval}
                onSelect={handleSelectVideoInterval}
                options={VIDEO_ANNOTATION_PREDEFINED_INTERVALS}
              />
            </Box>
          </Box>
        </ProjectSplitTypeWrapper>
        <ProjectSplitTypeWrapper
          isActive={
            videoAnnotationSplitType ===
            VideoAnnotationDatasetSplitByType.SPLIT_BY_EACH_FRAME
          }
        >
          <Col gridGap="0px">
            <Radio
              value={VideoAnnotationDatasetSplitByType.SPLIT_BY_EACH_FRAME}
            >
              <Text>Detailed Annotation</Text>
            </Radio>
            <DetailedVideoAnnotationTooltip />
          </Col>
        </ProjectSplitTypeWrapper>
      </Col>
    </RadioGroup>
  );
}
