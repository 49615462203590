import InviteHelpers, {
  InviteHelpersContextProvider
} from '../../InviteHelpers';
import { useGetListOfHelpersQuery } from 'generated/graphql';
import { useAddProjectWizardContext } from '../AddProjectWizard.context';
import { InvitedHelper } from 'appTypes';
import { useDebouncedCallback } from 'use-debounce';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { useField } from 'formik';
import { ADD_WIZARD_FIELDS } from 'appConstants';

function StepAddHelpers() {
  const {
    onSetInvitedHelpers,
    onSetIsRequestCustomHelpers,
    isOnlyRequestCustomHelpers
  } = useAddProjectWizardContext();

  const { data: responseHelpers } = useGetListOfHelpersQuery({
    fetchPolicy: isOnlyRequestCustomHelpers ? 'cache-only' : 'network-only',
    skip: isOnlyRequestCustomHelpers
  });

  const { isPremium } = useGetUserSessionData();

  const helpers = responseHelpers?.getListOfHelpers?.invited?.filter(
    Boolean
  ) as InvitedHelper[];

  const [
    ,
    customRequestMeta,
    { setValue: onSetCustomMessageInviteHelpersRequest }
  ] = useField(ADD_WIZARD_FIELDS.customMessageInviteHelpersRequest);

  const onSetCustomMessageInviteHelpersRequestDebounced = useDebouncedCallback(
    onSetCustomMessageInviteHelpersRequest,
    200
  );

  return (
    <InviteHelpersContextProvider
      onSetIsRequestCustomHelpers={onSetIsRequestCustomHelpers}
      customMessageMeta={customRequestMeta}
      isPremium={isPremium}
      helpers={helpers}
      onSelectedHelpersUpdate={onSetInvitedHelpers}
      onCustomMessageUpdate={onSetCustomMessageInviteHelpersRequestDebounced}
    >
      <InviteHelpers />
    </InviteHelpersContextProvider>
  );
}

export default StepAddHelpers;
