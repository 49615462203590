import { MultiLabelsRoot } from 'containers/MultiLevelLabels/types';
import { FormikConfig, FormikErrors } from 'formik';
import React, { MutableRefObject } from 'react';
import {
  ImageAnnotationDatasetProcessingType,
  InvitedHelper,
  ProjectSpecialization,
  ProjectType,
  Roles,
  VideoAnnotationDatasetSplitByType,
  VideoAnnotationDatasetProcessingType
} from 'appTypes';
import { Platform, Project } from 'generated/graphql';

export interface WizardConfigProps
  extends Partial<WizardFormValues & AddProjectWizardContextState> {}

export type NullableConfiguration = Configuration | null;
export type NullableConfigurationArray = Array<NullableConfiguration>;
export type ConfigurationArray = Array<Configuration>;

export interface AddProjectWizardProps {
  wizardConfig?: WizardConfig;
  initialFormData?: Partial<WizardFormValues>;
  initialContextData?: Partial<AddProjectWizardContextState>;
  onClose: () => void;
  isOpen?: boolean;
}

export interface GoogleDriveDataResultDoc {
  id: string;
  type: 'photo' | 'video';
  name: string;
}

export interface GoogleDriveDataResult {
  action: 'picked' | 'loaded';
  docs: GoogleDriveDataResultDoc[];
}

export interface Configuration {
  id: number;
  title?: string;
  stepNumber?: number;
  type?: 'small' | 'normal';
  done?: boolean;
  isActive?: boolean;
  isLinkActive?: boolean;
  childStepNumber?: number;
  parentStep?: number;
  childSteps?: NullableConfigurationArray;
  description?: StepsDescriptions;
  validation?: any;
  isLocked?: boolean;
  showSaveDraftButton?: boolean;
  showSkipStepButton?: boolean;
  isDisabledSaveButton?: boolean;
  component?: React.FunctionComponent<any & Partial<WizardConfigProps>>;
  componentProps?: Partial<WizardConfigProps>;
}

export type WizardConfig = (
  props: WizardConfigProps
) => NullableConfigurationArray;

export interface AddProjectWizardContext {
  state: AddProjectWizardContextState;
  setState: React.Dispatch<
    | React.SetStateAction<AddProjectWizardContextState>
    | AddProjectWizardContextState
  >;
}

export interface GoogleDriveFile {
  id: string;
  name: string;
  type: string;
}

export interface AddProjectFormOptions {
  finalSubmitStepDisabled?: boolean;
  currentStep?: number;
  skippedStepsNames?: StepsDescriptions[];
}

export enum StepsDescriptions {
  InviteHelpers = 'Add Helpers',
  ProjectSetup = 'Project Setup',
  GenInfo = 'General information',
  Labels = 'Set Up Labels',
  Sections = 'Set Up Sections',
  Reasons = 'Set Up Reasons',
  Dataset = 'Upload Dataset',
  Instruction = 'Upload Instructions',
  Done = 'Done'
}

export type MutableFromRef = { current: FormRef | null } | null;
export interface AddProjectWizardContextState {
  mediaFile: File | null;
  isRequestCustomHelpers?: boolean;
  isOnlyRequestCustomHelpers?: boolean;
  onSetIsRequestCustomHelpers?: (isRequestCustomHelpers: boolean) => void;
  initialFormData?: Partial<WizardFormValues>;
  curRole?: Roles;
  formOptions?: AddProjectFormOptions;
  isUserActivated?: boolean | null;
  type: ProjectType;
  authToken: string | null;
  annotationMultipleFiles: File[] | GoogleDriveFile[] | null;
  videoMediaFile: File | null;
  videoFileDuration: number;
  invitedHelpers: InvitedHelper[] | null;
  onSetAnnotationMultipleFiles: (
    files: File[] | null | GoogleDriveFile[]
  ) => void;
  onSetVideoMediaFile: (
    file: File | null,
    duration?: number,
    fileNameFromBe?: string
  ) => void;
  onSetMediaFile: (file: File | null) => void;
  onSetDuration: (duration: number) => void;
  isLoading: boolean;
  onSetLoading: (loading: boolean) => void;
  onSetAuthToken: (authToken: string) => void;
  videoLoadProgressLoaded: number;
  videoLoadProgressTotal: number;
  onVideoLoadProgress: (loaded: number, total: number) => void;
  onSetProjectSpecialization: (spec: ProjectSpecialization) => void;
  onSetProjectPlatform: (platform: Platform) => void;
  onSetProjectType: (type: ProjectType) => void;
  platform: Platform;
  specialization: ProjectSpecialization;
  annotationFilesUploadType: 'single' | 'multiple' | 'googleDrive';
  isMultiLevelLabels?: boolean;
  videoAnnotationSpecSubType?: VideoAnnotationDatasetProcessingType;
  videoAnnotationSplitType?: VideoAnnotationDatasetSplitByType;
  imageAnnotationSpecSubType?: ImageAnnotationDatasetProcessingType;
  isLabelsCorrect?: boolean;
  onSetIsLabelsCorrect: (isLabelsCorrect: boolean) => void;
  onSetAnnotationFilesUploadType: (
    newType: 'single' | 'multiple' | 'googleDrive'
  ) => void;
  onSetIsMultiLevelLabels: (isMultiLevelLabels: boolean) => void;
  onSetVideoAnnotationSpecSubType: (
    newVideoAnnotationSpecSubType: VideoAnnotationDatasetProcessingType
  ) => void;
  onSetVideoAnnotationSplitType: (
    newVideoAnnotationSplitType: VideoAnnotationDatasetSplitByType
  ) => void;
  onSetImageAnnotationSpecSubType: (
    newImageAnnotationSpecSubType: ImageAnnotationDatasetProcessingType
  ) => void;
  formRef: MutableFromRef;
  onSetFormRef: (newFormRef: FormRef) => void;
  onSetFormOptions: (props: Partial<AddProjectFormOptions>) => void;
  onSetCurrentStep: (newStep: number) => void;
  onSetInvitedHelpers: (newHelpers: InvitedHelper[]) => void;
}

export interface FormRef extends FormikConfig<WizardFormValues> {
  values: WizardFormValues;
  setFieldError: (field: string, error: string) => void;
  setFieldValue: (field: string, value: any) => void;
  validateForm: (
    values?: WizardFormValues
  ) => Promise<FormikErrors<WizardFormValues>>;
  setValues: (
    fields: React.SetStateAction<{ [field: string]: any }>,
    shouldValidate?: boolean
  ) => void;
  setTouched: (
    fields: { [field: string]: boolean },
    shouldValidate?: boolean
  ) => void;
}

export interface WizardFormValuesWithBetterNames extends WizardFormValues {
  customMessageInviteHelpersRequest: WizardFormValues['customMessageInviteHelpersRequest'];
  clientInstructionTextarea: WizardFormValues['clientShortInstruction'];
  media: WizardFormValues['mediaFileName'];
}

export interface WizardFormValues {
  title: string;
  files?: string[];
  authToken?: string | null;
  clientId?: string | number | null;
  type: ProjectType;
  mediaFileName?: string | string[];
  labels?: Array<string>;
  annotationImages?: Array<string> | null;
  helperRate?: number | null;
  auditorRate?: number | null;
  customLabels: boolean;
  adminShortInstruction?: string;
  adminInstructionFileName?: string;
  clientInstructionFileNames?: Array<string | null>;
  clientShortInstruction?: string;
  mlLabels?: MultiLabelsRoot;
  projectSettings?: Partial<Project['projectSettings']>;
  customMessageInviteHelpersRequest?: string;
  sbCurUser?: string;
  platform?: Platform;
}

export type AddProjectWizardLegacyProps = {
  validateCb?: (
    values: WizardFormValues
  ) => Partial<Record<keyof WizardFormValues, string>>;
  formRef: MutableRefObject<any | null>;
  initialValues: WizardFormValues;
  validationSchema?: any;
  children?: any;
  onSubmit: any;
  stepNumber?: number;
  onClose: () => void;
  loading: boolean;
  onStepChange: (step: number) => void;
  saveDraftProjectButton?: React.ReactNode;
  showSaveDraftProjectButton?: boolean;
  isDisabledSaveButton?: boolean;
  showSkipStepButton?: boolean;
};

export interface HandleNextProps {
  skip?: boolean;
  cb?: any;
  context?: AddProjectWizardContextState;
}

export interface ProjectSpecializationHelpItem {
  id: number;
  title: string;
  description: string | React.ReactNode;
  icon: React.ReactNode;
}

export interface ProjectTypeHelpItem {
  id: number;
  title: string;
  description: string;
  image: React.ReactNode;
  onlyPremium?: boolean;
}
