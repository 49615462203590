import { TaskStatus } from 'generated/graphql';
import { PRACTICAL_PROJECT_ID } from 'appConstants';

const getPracticalTask = (taskId: number, newTask: any) => {
  return {
    ...newTask,
    id: taskId,
    projectId: PRACTICAL_PROJECT_ID,
    title: 'Test',
    mediaUrl: newTask?.mediaFileName,
    status: TaskStatus.Started,
    result: {
      data: []
    },
    project: {
      id: PRACTICAL_PROJECT_ID,
      title: 'Test',
      type: newTask?.type,
      labels: newTask?.labels,
      mediaFileName: [newTask?.mediaFileName],
      projectSettings: newTask?.settings
    }
  };
};

export default getPracticalTask;
