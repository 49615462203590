import React from 'react';
import useRouter from '../../hooks/useRouter';
import {
  AnnotationSpecificationType,
  ProjectType,
  TaskingType
} from '../../appTypes';
import AnnotationView from './AnnotationView';
import parseSubType from 'utils/parseSubType';
import { TaskType } from 'generated/graphql';

interface RouterQueryProps {
  query: {
    projectId: string;
    auditingType: TaskingType;
    projectType: ProjectType;
    taskId: string;
    specType: AnnotationSpecificationType;
  };
}

function Tasks() {
  const { query } = useRouter() as unknown as RouterQueryProps;

  const urlParams = new URLSearchParams(window.location.search);
  const withFrames = Boolean(urlParams.get('frames'));
  const isVideoAsImage = Boolean(urlParams.get('isVideoAsImage'));
  const specType: AnnotationSpecificationType = parseSubType(
    urlParams.get('specType') ?? ''
  ) as AnnotationSpecificationType;
  const taskingType = query?.auditingType;
  const projectType = query?.projectType;
  const showFramesSelection = withFrames || isVideoAsImage;

  if (!taskingType) {
    return null;
  }

  if (projectType === ProjectType.IMAGE_ANNOTATION) {
    return (
      <AnnotationView
        taskType={TaskType.ImageAnnotation}
        taskingType={taskingType}
        specType={specType}
      />
    );
  }
  if (projectType === ProjectType.VIDEO_ANNOTATION && withFrames) {
    return (
      <AnnotationView
        showFramesSelection={showFramesSelection}
        taskType={TaskType.ImageAnnotation}
        taskingType={taskingType}
        specType={specType}
      />
    );
  }
  if (projectType === ProjectType.VIDEO_ANNOTATION) {
    return (
      <AnnotationView
        showFramesSelection={showFramesSelection}
        isVideoAsImage={isVideoAsImage}
        taskType={TaskType.VideoAnnotation}
        taskingType={taskingType}
        specType={specType}
      />
    );
  }
  if (projectType === ProjectType.CONTENT_MODERATION) {
    return (
      <AnnotationView
        taskType={TaskType.ContentModeration}
        taskingType={taskingType}
        specType={'contentModeration'}
      />
    );
  }

  return null;
}

export default Tasks;
