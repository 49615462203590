import styled, { css } from 'styled-components/macro';
import { Tag } from 'antd';

const AntdTag = styled(Tag)(
  ({ theme }) => css`
    height: 36px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    border-radius: 4px;
    border: none;
    padding: 0 15px;
    background: ${({ theme }) => theme.colors.lightest};
    font-size: 13px;
    color: #212121;
    line-height: normal;
    font-weight: 500;
    margin: 6px 5px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .ant-tag-close-icon {
      margin-left: 8px;
      width: 16px;
      height: 16px;
    }

    svg {
      width: 16px;
      height: 16px;
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }

    .tagName {
      text-overflow: ellipsis;
      max-width: 80px;
      overflow: hidden;
    }

    &:hover {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.bg};

      svg,
      svg path {
        fill: ${theme.colors.bg};
        stroke: ${theme.colors.bg};
      }
    }
  `
);

export default AntdTag;
