import React from 'react';
import { Box } from 'components/_main';
import { WarningTipIcon } from 'assets/tips';
import { useTheme } from 'styled-components/macro';
import Icon from 'components/Icon';

function ToastInfoIcon() {
  const theme = useTheme();

  return (
    <Box bg={theme.colors.yellow} padding="16px 12px 9px">
      <WarningTipIcon />
    </Box>
  );
}

export default ToastInfoIcon;
