import styled from 'styled-components/macro';

export const ClearAllText = styled.div`
  cursor: pointer;
  font-size: 13px;
  color: #4bc3b5;
  display: flex;
  align-items: center;
  align-self: center;
  transform: translateY(16px);
`;
