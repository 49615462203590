import { LearningMaterialsTypes } from 'containers/LearningMaterials/LearningMaterial.types';

export const getTotalLessons = (lessons: LearningMaterialsTypes[]) =>
  lessons.reduce((acc, currentValue) => acc + currentValue.lessons.length, 0);

export const getTotalReadLessons = (lessons: LearningMaterialsTypes[]) =>
  lessons.reduce(
    (acc, currentValue) =>
      acc + currentValue.lessons.filter(({ isDone }) => isDone).length,
    0
  );
