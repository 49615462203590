import React, { FC } from 'react';
import { Row, Text, Button } from 'components/_main';
import { FallbackUIProps } from './FallbackUI.types';
import { RobotErrorImage } from 'assets';
import { useRedirect } from 'hooks/useRedirect';

export const FallbackUI: FC<FallbackUIProps> = () => {
  const handleRedirect = useRedirect();
  const handleClick = () => handleRedirect('/projects');

  return (
    <Row alignContent="center" width="100%" height="100%">
      <Row gridGap="40px" justifyItems="center">
        <RobotErrorImage />
        <Row justifyItems="center">
          <Text variant="ui-4-bold">Oops!</Text>
          <Text>Something went wrong at our end.</Text>
          <Button variant="ghost" onClick={handleClick}>
            Go to home page
          </Button>
        </Row>
      </Row>
    </Row>
  );
};
