import React, { forwardRef } from 'react';
import { Box } from 'components/_main';
import { useTheme } from 'styled-components/macro';
import { AnnotationLayoutProps } from '../AnnotationView.types';
import { TaskStatus, TaskType } from 'generated/graphql';
import useHotKeys from 'containers/TasksRouter/hooks/useHotKeys';

const AnnotationLayout = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<AnnotationLayoutProps>
>(
  (
    {
      taskType,
      children,
      renderVideoProgressBar,
      renderAbove,
      renderSidebar,
      renderFooter,
      taskData
    },
    ref
  ) => {
    const theme = useTheme();

    useHotKeys({
      isAuditMode: taskData?.status === TaskStatus.InAudit
    });

    return (
      <Box
        display="grid"
        gridGap="0px"
        height="100%"
        gridTemplateRows="1fr auto"
        gridTemplateColumns="max-content 1fr"
      >
        <Box
          id="AnnotationMain"
          data-component="AnnotationMain"
          displayFlex
          flexDirection="column"
          gridRow="1 / 2"
          gridColumn="2 / 3"
          width="100%"
          padding="0 40px 0 40px"
          height="100%"
          overflow="auto"
          gridGap="0"
        >
          <Box marginBottom="1rem">{renderAbove ?? null}</Box>
          <div
            ref={ref}
            data-component="annotation-wrapper"
            css={`
              width: 100%;
              height: 100%;
              position: relative;
              overflow: auto;
              background-color: ${theme.colors.lighter};
              border-radius: ${taskType === TaskType.VideoAnnotation
                ? '10px 10px 0 0'
                : '10px'};
            `}
          >
            {children ?? null}
          </div>
          {renderVideoProgressBar && (
            <Box width="100%" height="max-content">
              {renderVideoProgressBar}
            </Box>
          )}
        </Box>
        {renderSidebar && (
          <Box data-testid="SidebarViewport" gridRow="1 / 3" gridColumn="1 / 2">
            {renderSidebar}
          </Box>
        )}
        {renderFooter && (
          <Box data-testid="FooterViewport" gridRow="2 / 3" gridColumn="2 / 3">
            {renderFooter}
          </Box>
        )}
      </Box>
    );
  }
);

export default AnnotationLayout;
