import { useContext, useMemo } from 'react';
import context from './context';

export default function useAnnotationSystemLabels() {
  const [state] = useContext(context);

  const { labels } = state;

  return useMemo(() => labels, [labels]);
}
