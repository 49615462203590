import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  ButtonPrimary,
  ButtonSecondary,
  ButtonSuccess,
  ButtonWarning,
  ButtonWithShortcutText,
  Col,
  Text
} from 'components/_main';
import { TaskAction, TaskStatus } from 'generated/graphql';
import { StatusbarProps } from '../AnnotationView.types';
import useOpen from 'hooks/useOpen';
import useHandleOnTaskSubmit from 'containers/TasksRouter/hooks/useHandleOnTaskSubmit';
import TaskReturnAuditorModal from './TaskReturnAuditorModal';
import { consoleError } from 'utils';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import PracticeResultModal from './AnnotationPracticeResultModal';
import AntdCircleProgress from 'components/AntdCircleProgress';
import Timer from 'containers/TasksRouter/AnnotationView/components/Timer';

const Statusbar: React.FC<StatusbarProps> = ({
  taskId,
  finalProjectId,
  taskingType = 'tasking',
  taskType,
  taskData,
  practiceEndTime,
  setTaskData,
  startPracticeSession
}) => {
  const { isViewOnly, annPracticeProgress, isInitMediaLoading } =
    useAnnotationSystemState();
  const [isTimeOut, setIsTimeOut] = useState(false);
  const {
    isOpen: isPracticeResultOpen,
    onOpen: onPracticeResultOpen,
    onClose: onPracticeResultClose
  } = useOpen();
  const timesReturnedFromAuditor = taskData?.timesReturnedFromAuditor;

  const buttonDisabled = isViewOnly || isInitMediaLoading;

  const {
    isOpen: isReturnModalOpen,
    onOpen: onReturnModalOpen,
    onClose: onReturnModalClose
  } = useOpen();
  const isImageAnnotation = taskType === 'IMAGE_ANNOTATION';

  const [handleOnTaskSubmit, handleOnPracticeTaskSubmit] =
    useHandleOnTaskSubmit(setTaskData, taskingType);

  /** Helper - Skip */
  const handleOnTaskSkip = useCallback(async () => {
    await handleOnTaskSubmit(taskId, finalProjectId, TaskAction.Skip);
  }, [handleOnTaskSubmit, taskId, finalProjectId]);

  const handleOnTaskReject = useCallback(async () => {
    try {
      await handleOnTaskSubmit(
        taskId,
        finalProjectId,
        taskData?.status === TaskStatus.InAudit
          ? TaskAction.RejectAsAuditor
          : TaskAction.Reject
      );
    } catch (e) {
      consoleError(e);
    }
  }, [handleOnTaskSubmit, taskData, taskId, finalProjectId]);

  /** Helper - Submit */
  const handleOnTaskSubmitSubmit = useCallback(async () => {
    await handleOnTaskSubmit(taskId, finalProjectId, TaskAction.Submit);
  }, [handleOnTaskSubmit, taskId, finalProjectId]);

  const handleOnPracticeSubmit = useCallback(
    async (noResults?: boolean) => {
      await handleOnPracticeTaskSubmit(
        taskId,
        finalProjectId,
        TaskAction.Submit,
        onPracticeResultOpen,
        noResults
      );
    },
    [handleOnPracticeTaskSubmit, taskId, finalProjectId, onPracticeResultOpen]
  );

  const handleTimeOut = async () => {
    setIsTimeOut(true);
    await handleOnPracticeSubmit(true);
  };

  /** Auditor - Approve */
  const handleOnTaskAuditApprove = useCallback(async () => {
    await handleOnTaskSubmit(taskId, finalProjectId, TaskAction.Approve);
  }, [handleOnTaskSubmit, taskId, finalProjectId]);

  /** Auditor - Return */
  const handleOnTaskAuditReturn = useCallback(
    async (comments?: string) => {
      await handleOnTaskSubmit(
        taskId,
        finalProjectId,
        TaskAction.Return,
        comments
      );
      onReturnModalClose();
    },
    [handleOnTaskSubmit, onReturnModalClose, taskId, finalProjectId]
  );

  return (
    <>
      <Col
        displayFlex
        justifyContent="space-between"
        width="100%"
        padding="20px 40px"
        gridColumn={isImageAnnotation ? '2 / -1' : '1 / 2'}
        gridRow="3 / -1"
        data-component={'HelperStatusbar'}
      >
        <Col>
          {taskingType === 'practice' ? (
            <Box displayFlex alignItems="center" justifyContent="center">
              <AntdCircleProgress
                type="circle"
                percent={annPracticeProgress}
                width={35}
              />
              <Col gridGap="4px">
                <Text variant="ui-1-bold">{annPracticeProgress}%</Text>
                <Text>completed</Text>
              </Col>
            </Box>
          ) : (
            <Button
              variant="outlined"
              disabled={buttonDisabled}
              onClick={handleOnTaskReject}
            >
              Report task as not doable
            </Button>
          )}
        </Col>
        <Col>
          {taskingType === 'tasking' && (
            <>
              {!timesReturnedFromAuditor && (
                <ButtonWithShortcutText>
                  <ButtonSecondary
                    variant="outlined"
                    data-testid="SkipButton"
                    disabled={buttonDisabled}
                    onClick={handleOnTaskSkip}
                  >
                    Skip
                  </ButtonSecondary>
                </ButtonWithShortcutText>
              )}
              <ButtonWithShortcutText>
                <ButtonPrimary
                  data-testid="SubmitButton"
                  disabled={buttonDisabled}
                  onClick={handleOnTaskSubmitSubmit}
                >
                  Submit
                </ButtonPrimary>
              </ButtonWithShortcutText>
            </>
          )}
          {taskingType === 'auditing' && (
            <>
              <ButtonWithShortcutText>
                <ButtonWarning
                  disabled={buttonDisabled}
                  data-testid="ReturnButton"
                  onClick={onReturnModalOpen}
                >
                  Return
                </ButtonWarning>
                <span>[Shift + S]</span>
              </ButtonWithShortcutText>
              <ButtonWithShortcutText>
                <ButtonSuccess
                  data-testid="ApproveButton"
                  disabled={buttonDisabled}
                  onClick={handleOnTaskAuditApprove}
                >
                  Approve
                </ButtonSuccess>
              </ButtonWithShortcutText>
            </>
          )}
          {taskingType === 'practice' && (
            <Col gridGap="30px">
              <Timer endTime={practiceEndTime} onTimeOut={handleTimeOut} />
              <ButtonWithShortcutText>
                <ButtonPrimary
                  data-testid="SubmitButton"
                  disabled={buttonDisabled}
                  onClick={() => handleOnPracticeSubmit()}
                >
                  Submit
                </ButtonPrimary>
              </ButtonWithShortcutText>
            </Col>
          )}
        </Col>
      </Col>
      <TaskReturnAuditorModal
        isOpen={isReturnModalOpen}
        onClose={onReturnModalClose}
        onSubmit={handleOnTaskAuditReturn}
      />
      <PracticeResultModal
        isOpen={isPracticeResultOpen}
        isTimeOut={isTimeOut}
        onClose={onPracticeResultClose}
        startPracticeSession={startPracticeSession}
      />
    </>
  );
};

export default Statusbar;
