import {
  BackgroundProps,
  ColorProps,
  SpaceProps,
  TypographyProps,
  LayoutProps
} from 'styled-system';
import { Theme } from 'appTypes';
import { MouseEventHandler } from 'react';

export const FONT_VARIANTS = {
  'ui-1-regular': 'ui-1-regular',
  'ui-1-medium': 'ui-1-medium',
  'ui-1-semi-bold': 'ui-1-semi-bold',
  'ui-1-bold': 'ui-1-bold',
  'ui-2-regular': 'ui-2-regular',
  'ui-2-bold': 'ui-2-bold',
  'ui-3-regular': 'ui-3-regular',
  'ui-3-bold': 'ui-3-bold',
  'ui-4-bold': 'ui-4-bold',
  'ui-small-regular': 'ui-small-regular',
  'ui-small-bold': 'ui-small-bold',
  'ui-small-2-bold': 'ui-small-2-bold',
  label: 'label',
  'header-1': 'header-1',
  'header-2': 'header-2',
  'header-3': 'header-3',
  'header-4': 'header-4',
  'header-5': 'header-5',
  'custom-20px-regular': 'custom-20px-regular',
  'custom-20px-bold': 'custom-20px-bold'
};

export type COLOR_VARIANTS =
  | 'invert'
  | 'normal'
  | 'primary'
  | 'light'
  | 'lighter'
  | 'lightest'
  | 'warning'
  | 'error';

export interface TextSelfProp {
  variant?: keyof typeof FONT_VARIANTS;
  colorVariant?: COLOR_VARIANTS;
  theme?: Theme;
  bold?: boolean;
  center?: boolean;
  light?: boolean;
  lighter?: boolean;
  lightest?: boolean;
  fs?: number;
  upper?: boolean;
  nowrap?: boolean;
  breakAll?: boolean;
  truncate?: boolean;
  onClick?: MouseEventHandler;
}

export type TextProps = Partial<
  TypographyProps &
    BackgroundProps &
    ColorProps &
    SpaceProps &
    LayoutProps &
    TextSelfProp
>;
