import { toast } from 'react-toastify';
import React from 'react';
import {
  ProjectUpdateInfoInput,
  ProjectUpdateRateInput,
  ProjectUpdateFilesInput,
  ProjectUpdateHelpersInput,
  ProjectUpdateAuditorsInput,
  TaskType
} from 'generated/graphql';
import { parseRate } from 'utils/_rateUtils';
import { ProjectForm } from './types';
import { FormikErrors } from 'formik';
import { ColLegacy } from 'components/_main';
import arrayIsEqual from 'utils/arrayIsEqual';

export const isReadyToChangeStatusFromDraftToUnreviewed = (
  values: any,
  isValid: boolean
) => {
  if (
    isValid &&
    (values?.labels?.length > 0 || values?.mlLabels?.multilabels?.length > 0)
  ) {
    return true;
  }

  return false;
};

export const isReadyToChangeStatusFromUnreviewedToPublished = (
  values: any,
  isValid: boolean
) => {
  const isNotDataSet = values?.type !== TaskType.DataSetCollection;

  if (
    isValid &&
    values?.selectedHelperIds?.length &&
    values?.selectedHelperIds?.length > 0 &&
    isNotDataSet &&
    (values?.labels?.length > 0 || values?.mlLabels?.multilabels?.length > 0)
  ) {
    return true;
  } else if (
    isValid &&
    values?.selectedHelperIds?.length &&
    values?.selectedHelperIds?.length > 0
  ) {
    return true;
  }

  return false;
};

export const mapValuesToPrjUpdateInfoInput = (
  values: Partial<ProjectForm> & { id: number }
): ProjectUpdateInfoInput => ({
  projectId: values.id,
  title: values.title,
  labels: values.labels,
  ...(values.mlLabels?.multilabels && {
    mlLabels: {
      multilabels: values.mlLabels?.multilabels?.map((label) => {
        const { type, id, entities, trigger, name } = label;
        const finalTrigger = trigger
          ? {
              items: trigger.items,
              parentId: trigger.parentId
            }
          : null;
        return {
          id,
          name,
          entities,
          trigger: finalTrigger,
          type
        };
      })
    }
  }),
  adminShortInstruction: values.adminShortInstruction,
  clientShortInstruction: values?.clientShortInstruction,
  customLabels: values?.customLabels,
  ...(values?.projectSettings && {
    projectSettings: {
      chunkLength: values.projectSettings.chunkLength,
      subType: values.projectSettings.subType
    }
  })
});

export const mapValuesToPrjUpdateRateInput = (
  values: Partial<ProjectForm> & { id: number }
): ProjectUpdateRateInput => ({
  projectId: values.id,
  auditorRate: parseRate(values.auditorRate),
  helperRate: parseRate(values.helperRate)
});

export const mapValuesToPrjUpdateFilesInput = (
  values: Partial<ProjectForm> & { id: number }
): ProjectUpdateFilesInput => ({
  projectId: values.id,
  adminInstructionUrl: values.adminInstructionUrl
    ? [values.adminInstructionUrl]
    : null,
  ...(values.clientInstructionUrl && {
    clientInstructionUrl: values.clientInstructionUrl as string[]
  })
});

export const mapValuesToPrjUpdateAssignedHelperInput = (
  values: ProjectForm
): ProjectUpdateHelpersInput => ({
  projectId: values.id,
  assignedHelperIds: values.selectedHelperIds
});

export const mapValuesToPrjUpdateAssignedAuditorInput = (
  values: ProjectForm
): ProjectUpdateAuditorsInput => ({
  projectId: values.id,
  assignedAuditorIds: values.selectedAuditorIds
});

export const getErrorFieldsArrayFromContext = (errors: FormikErrors<any>) => {
  return Object.keys(errors).map((field: string) => {
    if (field === 'title') {
      return 'Title';
    }

    if (field === 'isLabelsCorrect') {
      return 'Labels';
    }

    if (field === 'mediaFileName') {
      return 'Media file (ZIP)';
    }

    return field;
  });
};

export const showFillRequiredFieldsToastForFromReviewToPublished = (
  errorFields: string[] = [],
  values: Record<string, string>
) => {
  return toast.error(
    <ColLegacy>
      {errorFields && errorFields?.length > 0 && (
        <div>Please fix errors at those fields: {errorFields.join(' ')}</div>
      )}
      {!values?.helperRate && <div>Please set Helper Rate</div>}
      {!values?.auditorRate && <div>Please set Auditor Rate</div>}
      {(!values?.selectedAuditorIds ||
        values?.selectedAuditorIds?.length === 0) && (
        <div>Please assign at least 1 auditor</div>
      )}
      {(!values?.selectedHelperIds ||
        values?.selectedHelperIds?.length === 0) && (
        <div>Please assign at least 1 helper</div>
      )}
    </ColLegacy>,
    {
      toastId: 'project-card-error',
      updateId: Date.now(),
      autoClose: 10000
    }
  );
};

export const showFillRequiredFieldsToastForFromDraftToReview = (
  errorFields?: string[]
) => {
  return (
    errorFields &&
    errorFields?.length > 0 &&
    toast.error(
      <div>Please set fix all errors in fields: {errorFields.join(', ')}</div>,
      {
        toastId: 'project-card-error',
        updateId: Date.now(),
        autoClose: 10000
      }
    )
  );
};

export const isChangedValues = (initPrjData: any, newValues: any) => {
  return Object.fromEntries(
    Object.entries(newValues).filter(([key, value]) =>
      Array.isArray(value)
        ? !arrayIsEqual(value, initPrjData[key as keyof typeof newValues])
        : value !== initPrjData[key as keyof typeof newValues]
    )
  );
};

export const isChangedStatus = (initProjectData: any, newValues: any) =>
  initProjectData.status !== newValues.status;

export const isChangedInfo = (initProjectData: any, newValues: any) =>
  initProjectData.title !== newValues.title ||
  !arrayIsEqual(initProjectData.labels, newValues.labels) ||
  !arrayIsEqual(
    initProjectData?.mlLabels?.multilabels,
    newValues?.mlLabels?.multilabels
  ) ||
  initProjectData.customLabels !== newValues.customLabels ||
  initProjectData.adminShortInstruction !== newValues.adminShortInstruction ||
  initProjectData.clientShortInstruction !== newValues.clientShortInstruction;

export const isChangedRate = (initProjectData: any, newValues: any) =>
  initProjectData.auditorRate !== newValues.auditorRate ||
  initProjectData.helperRate !== newValues.helperRate;

export const isChangedFiles = (initProjectData: any, newValues: any) =>
  !arrayIsEqual(
    initProjectData.adminInstructionUrl,
    newValues.adminInstructionUrl
  ) ||
  !arrayIsEqual(
    initProjectData.clientInstructionUrl,
    newValues.clientInstructionUrl
  );

export const isChangedAssignedHelper = (initProjectData: any, newValues: any) =>
  !arrayIsEqual(initProjectData.selectedHelperIds, newValues.selectedHelperIds);

export const isChangedAssignedAuditor = (
  initProjectData: any,
  newValues: any
) =>
  !arrayIsEqual(
    initProjectData.selectedAuditorIds,
    newValues.selectedAuditorIds
  );

export const isChangedMediaFileName = (initProjectData: any, newValues: any) =>
  newValues.mediaFileName &&
  initProjectData.mediaFileName !== newValues.mediaFileName;
