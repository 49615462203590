import { Box } from 'components/_main';
import styled, { css } from 'styled-components/macro';
import { px } from 'utils';
import { IconProps } from './types';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  compose,
  typography
} from 'styled-system';

export const IconStyled = styled(Box)(
  ({
    pathFillColor,
    pathStrokeColor,
    circleFillColor,
    circleStrokeColor,
    size,
    stroke,
    fill,
    hoverFillColor,
    hoverStrokeColor,
    disabled,
    onClick,
    hoverColor
  }: IconProps) => css`
    transition: all 300ms;
    display: flex;
    justify-content: center;
    align-items: center;
    ${size &&
    css`
      font-size: ${px(size)};
      width: ${px(size)};
      height: ${px(size)};
    `}
    z-index: 100;
    background-color: transparent;

    ${onClick &&
    css`
      cursor: pointer;
    `}

    svg {
      width: ${px(size)};
      height: ${px(size)};

      * {
        fill: ${fill ?? 'auto'};
        stroke: ${stroke ?? 'auto'};
      }

      path {
        fill: ${pathFillColor};
        stroke: ${pathStrokeColor};
      }
      circle {
        fill: ${circleFillColor};
        stroke: ${circleStrokeColor};
      }
    }

    ${!disabled &&
    css`
      &:hover {
        ${hoverColor && `color: ${hoverColor} !important;`}

        svg {
          ${hoverFillColor && `fill: ${hoverFillColor} !important;`}
          ${hoverStrokeColor && `stroke: ${hoverStrokeColor} !important;`}

        * {
            ${hoverFillColor && `fill: ${hoverFillColor} !important;`}
            ${hoverStrokeColor && `stroke: ${hoverStrokeColor} !important;`}
          }
        }
      }
    `}

    ${disabled &&
    css`
      opacity: 0.25;
    `}
  `,
  compose(
    background,
    border,
    color,
    flexbox,
    grid,
    layout,
    space,
    position,
    shadow,
    typography
  )
);
