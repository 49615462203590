import React, { useEffect, useRef } from 'react';
import { Rect, SvgRoot } from './CreateDots.ui';
import { useMousePositionRef } from 'hooks/useMousePositionRef';
import useMouseDown from 'containers/AnnotationSystem/components/CreateDots/useMouseDown';
import useMouseMove from 'containers/AnnotationSystem/components/CreateDots/useMouseMove';
import { useKeyDown } from 'hooks/useKeyDown';

export default function CreateDots() {
  const svgRef = useRef<SVGSVGElement>(null);

  const { onReset, onStart, onUpdate, stateRef } = useMousePositionRef();

  const { isClicked, setIsClicked } = useMouseDown({
    svgRef,
    onStart,
    onReset,
    stateRef
  });
  useMouseMove({ svgRef, stateRef, onUpdate });

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsClicked(false);
    }
  };

  useKeyDown(handleKeyDown);

  if (!isClicked) return null;

  return (
    <SvgRoot ref={svgRef} x={0} y={0} width={0} height={0}>
      <Rect width="100%" height="100%" x={0} y={0} />
    </SvgRoot>
  );
}
