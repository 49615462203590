import React from 'react';
import { Col, Text, Box, Row } from 'components/_main';
import { css, useTheme } from 'styled-components/macro';
import useOpen from 'hooks/useOpen';
import { AnnotationLabelItemProps } from 'containers/TasksRouter/AnnotationView/AnnotationView.types';
import ConfirmModal from 'components/_modals/Modal/ConfirmModal';
import Icon from 'components/Icon';
import {
  EyeCloseIcon,
  EyeOpenIcon,
  TrashIcon
} from 'assets/taskingUnitToolbar';
import { ReactComponent as ArrowOpenedIcon } from './arrowOpened.svg';
import { ReactComponent as ArrowClosedIcon } from './arrowClosed.svg';

function AnnotationLabelItem({
  data,
  isActive,
  isShow,
  color,
  onClick,
  onShow,
  onDelete,
  children,
  small,
  isViewOnly,
  lastFrame,
  lastTime,
  onSetVidTime
}: React.PropsWithChildren<AnnotationLabelItemProps>) {
  const theme = useTheme();
  const { isOpen, onToggle } = useOpen();
  const {
    isOpen: isTaskDeleteAnnotationOpen,
    onOpen: onTaskDeleteAnnotationOpen,
    onClose: onTaskDeleteAnnotationClose
  } = useOpen();
  const isExpandable = React.Children.toArray(children).length > 0;
  const el = document.querySelector(`[data-unit-id="${data?.unitId}"]`);

  return (
    <Row
      onMouseEnter={() => {
        el?.classList.add('hover');
      }}
      onMouseLeave={() => {
        el?.classList.remove('hover');
      }}
      borderRadius={isActive ? '6px' : 0}
      gridGap="4px"
      borderBottom={small ? undefined : `1px solid ${theme.colors.lightest};`}
      width="max-content"
      minWidth="100%"
      backgroundColor={
        !small && isActive ? theme.colors.lightest : 'transparent'
      }
    >
      <Col
        height="100%"
        gridAutoRows="100%"
        p={small ? '4px 34px' : '10px 14px'}
        pr={'14px'}
        className="item"
        width="100%"
        justifyContent="space-between"
        onClickCapture={onClick}
        css={css`
          cursor: pointer;
          transition: 200ms all;

          &:last-child {
            border-bottom: none;
          }

          .control {
            visibility: hidden;
          }

          &:hover {
            border-radius: 6px;
            background-color: ${theme.colors.lighter};
            & > .control {
              visibility: visible;
            }
          }
        `}
      >
        <Col alignItems="center" gridGap="0">
          {isExpandable && (
            <Icon
              height="100%"
              onClick={onToggle}
              width="16px"
              css="justify-content: flex-start;"
            >
              {isOpen ? <ArrowOpenedIcon /> : <ArrowClosedIcon />}
            </Icon>
          )}
          <Box
            width={small ? '6px' : '14px'}
            height={small ? '6px' : '14px'}
            borderRadius="50%"
            bg={color ?? theme.colors.primary}
            mr="10px"
          />
          {data?.label?.trim() ? (
            <Text
              variant={isActive ? 'ui-1-bold' : 'ui-1-regular'}
              maxWidth="100px"
              overflow="hidden"
              css="text-overflow: ellipsis; white-space: nowrap;"
            >
              {data.label}
            </Text>
          ) : (
            <Text fontStyle="italic">Please, set label</Text>
          )}
        </Col>

        <Col className="control" alignItems="center">
          {onSetVidTime && lastTime && lastFrame ? (
            <Row
              noGap
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onSetVidTime(lastTime);
              }}
            >
              {
                <Text fontSize="10px" fontWeight={600}>
                  {lastTime}s
                </Text>
              }
              {
                <Text fontSize="10px" fontWeight={600}>
                  {lastFrame}
                </Text>
              }
            </Row>
          ) : null}
          <Col alignItems="center" gridGap="20px">
            {onShow &&
              (isShow ? (
                <Icon hoverStrokeColor="fg" onClick={onShow}>
                  <EyeOpenIcon />
                </Icon>
              ) : (
                <Icon hoverStrokeColor="fg" onClick={onShow}>
                  <EyeCloseIcon />
                </Icon>
              ))}
            {onDelete && !isViewOnly && (
              <Icon
                hoverStrokeColor="fg"
                stroke={theme.colors.light}
                onClick={() => {
                  onTaskDeleteAnnotationOpen();
                }}
              >
                <TrashIcon />
              </Icon>
            )}
          </Col>
        </Col>
      </Col>
      {isOpen && <Row gridGap="0">{children}</Row>}
      <ConfirmModal
        title={`
          Are you sure you want to delete annotation with all the related data (labels, timestamps etc)?`}
        isOpen={isTaskDeleteAnnotationOpen}
        onClose={onTaskDeleteAnnotationClose}
        onSubmit={onDelete}
      />
    </Row>
  );
}

export default AnnotationLabelItem;
