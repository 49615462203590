import styled, { css } from 'styled-components/macro';
import { Select } from 'antd';
import { TagsProps } from './Tags.types';
import { px } from 'utils';

export const TagsStyled = styled<React.FunctionComponent<TagsProps>>(
  Select
)<TagsProps>(
  ({ theme }) => css`
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      min-height: 50px;
      display: flex;
      align-items: center;
      border-radius: 5px;
      border: 1px solid ${theme.colors.lighter};
      overflow-y: auto;
    }

    &:not(.ant-select-disabled):hover .ant-select-selector,
    &:not(.ant-select-disabled):focus .ant-select-selector,
    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${theme.colors.primary};
    }

    &.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background: ${theme.colors.bg};
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: ${theme.colors.primary};
      box-shadow: none;
    }
  `
);
