export default function TermsStaticPage() {
  return (
    <>
      <p>
        <h1>TERMS OF SERVICE</h1>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <strong>Last updated: June 17, 2022</strong>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          These Terms of Service constitute an agreement made between you,
          whether personally or on behalf of an entity (&ldquo;you&rdquo;) and
          Helpware,
        </span>
        <span>
          &nbsp;Inc. and its worldwide companies &amp; subsidiaries
          (&quot;Helpware&quot;, &quot;we&quot;, &quot;us&quot; or
          &quot;our&quot;)
        </span>
        <span>, regarding your access to and use of the the webpage </span>
        <span>
          <a href="https://www.google.com/url?q=https://www.helpware.com/services/data-annotation-service&amp;sa=D&amp;source=editors&amp;ust=1657616215732206&amp;usg=AOvVaw0nCqayaC4UefuaneEZOAPz">
            https://www.helpware.com/services/data-annotation-service
          </a>
        </span>
        <span>&nbsp;</span>
        <span>on the website </span>
        <span>
          <a href="https://www.google.com/url?q=https://www.helpware.com/&amp;sa=D&amp;source=editors&amp;ust=1657616215732589&amp;usg=AOvVaw30YT570XYC-qYNIDhcANWS">
            https://www.helpware.com/
          </a>
        </span>
        <span>&nbsp;(the </span>
        <span>&quot;</span>
        <span>Site</span>
        <span>&quot;)</span>
        <span>. </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          You agree that by accessing the Site, you have read, understood, and
          agree to be bound by these Terms of Service.{' '}
        </span>
        <span>Please read this </span>
        <span>Terms of Service</span>
        <span>
          &nbsp;carefully. If you do not agree with all of these Terms of
          Service, then you are expressly prohibited from using the Site and you
          must cease using it immediately. &nbsp;
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>We reserve the right to amend these </span>
        <span>Terms of Service</span>
        <span>
          &nbsp;at any time, for any reason. Any changes we make to these{' '}
        </span>
        <span>Terms of Service</span>
        <span>
          &nbsp;will be posted on this page. The date of the last revision to
          the{' '}
        </span>
        <span>Terms of Service</span>
        <span>
          &nbsp;will be indicated by the &quot;Last updated&quot; date at the
          beginning of this page.
        </span>
        <span>
          &nbsp;You will be subject to, and will be deemed to have been made
          aware of and to have accepted, the changes in any revised Terms of
          Service by your continued use of the Site after the date such revised
          Terms of Service are posted. &nbsp;
        </span>
      </p>
      <p>
        <span>&nbsp; </span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>INTELLECTUAL PROPERTY RIGHTS</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          Except as expressly set forth in these Terms of Service, the Terms of
          Service do not grant either party any rights, implied or otherwise, to
          the other party&#39;s content or any of the other party&#39;s
          intellectual property. As between the parties, you own all
          intellectual property rights in your data, and Helpware owns and
          reserves all intellectual property rights in its services and
          platform, including, but not limited all source code, databases,
          functionality, software, website designs, audio, video, text,
          photographs, and graphics on the Site, as well as the trademarks, and
          logos contained therein.{' '}
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Provided that you are eligible to use the Site, you are granted a
          limited non-transferable, non-exclusive, non-sublicensable license to
          access and use the Site and to download or print a copy of any portion
          of the content to which you have properly gained access solely for
          your personal, non-commercial use. With respect to any software that
          is distributed or provided to you for use on your premises or devices,
          Helpware hereby grant you a non-exclusive, non-transferable,
          non-sublicensable license to use such software during the Term only in
          connection with the services.{' '}
        </span>
        <span>
          Nothing in these Terms of Service will be construed to grant you any
          right to transfer or assign rights to access or use Helpware&rsquo;s
          services.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          If you provide a feedback about our services, then we may use that
          information without obligation to Customer.
        </span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>USER ACCOUNT</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          To access most features of Helpware&rsquo;s services, you must
          register for an account at{' '}
        </span>
        <span>
          <a href="https://www.google.com/url?q=http://client.taskware.io/&amp;sa=D&amp;source=editors&amp;ust=1657616215734303&amp;usg=AOvVaw0QBbfR3l5w0Xj8CrsfR_eD">
            http://client.taskware.io/
          </a>
        </span>
        <span>. W</span>
        <span>
          hen you register for an account, you may be required to provide us
          with some personal data (such as your name, email address, or other
          contact information), and you agree that the information you provide
          to us is accurate and that you will keep it accurate and up-to-date at
          all times. When you register, you will be asked to provide a password,
          and you are solely responsible for maintaining the confidentiality of
          your account and password. You accept responsibility for all
          activities that occur under your account. Please note that User
          accounts are subject to customer&rsquo;s control. In some cases we may
          also be able to restrict or terminate your access to your account. If
          you believe that your account is no longer secure, then you must
          immediately notify us.{' '}
        </span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>USER REPRESENTATIONS AND PROHIBITED ACTIVITIES</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          By using the Site and Helpware&rsquo;s services mentioned on it, you
          represent and warrant that:
        </span>
        <span>&nbsp;</span>
      </p>
      <ol>
        <li>
          <span>all </span>
          <span>registration information</span>
          <span>&nbsp;y</span>
          <span>ou give to us is true, accurate, current, and complete;</span>
        </li>
        <li>
          <span>
            you will keep your password confidential and will be responsible for
            all use of your account and password;
          </span>
        </li>
        <li>
          <span>you will maintain</span>
          <span>&nbsp;</span>
          <span>the accuracy of such information</span>
          <span>&nbsp;an</span>
          <span>d promptly update it if necessary;</span>
        </li>
        <li>
          <span>
            you have the legal capacity and you agree to comply with these Terms
            of Service;{' '}
          </span>
          <span>&nbsp;</span>
        </li>
        <li>
          <span>
            you are not a minor in the jurisdiction in which you reside;{' '}
          </span>
        </li>
        <li>
          <span>
            you will not access the Site through automated or non-human means,
            whether through a bot, script or otherwise;{' '}
          </span>
        </li>
        <li>
          <span>
            you will not use the Site for any illegal or unauthorized purpose;
            and{' '}
          </span>
        </li>
        <li>
          <span>
            your use of the Site and Helpware&rsquo;s services mentioned on it
            will not violate any applicable law or regulation.
          </span>
        </li>
      </ol>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).{' '}
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          Furthermore, by using the Site and Helpware&rsquo;s services mentioned
          on it, you represent and warrant that you will not:{' '}
        </span>
      </p>
      <ol>
        <li>
          <span>
            directly or indirectly: edit, alter, modify, adapt reverse engineer,
            decompile, disassemble or otherwise attempt to discover or otherwise
            change the whole or any part of the source code, object code or
            underlying structure, ideas, know-how or algorithms relevant to the
            Services or any software, documentation or data related to the
            services (&ldquo;Software&rdquo;);
          </span>
        </li>
        <li>
          <span>
            reproduce, copy, distribute, resell or otherwise use the Software
            for any commercial purpose;
          </span>
        </li>
        <li>
          <span>
            modify, translate, or create derivative works based on the services
            or any software (except to the extent expressly permitted by
            Helpware);{' '}
          </span>
        </li>
        <li>
          <span>
            allow any third party to use the Software on behalf of or for the
            benefit of any third party;
          </span>
        </li>
        <li>
          <span>remove any proprietary notices or labels; and</span>
        </li>
        <li>
          <span>
            use the Software in any way which breaches any applicable local,
            national or international law.
          </span>
        </li>
      </ol>
      <p>
        <span></span>
      </p>

      <h2>THIRD PARTY SOFTWARE</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>Helpware&rsquo;s services</span>
        <span>
          &nbsp;may include or incorporate third party software components that
          are generally available free of charge under licenses granting
          recipients broad rights to copy, modify, and distribute those
          components (&ldquo;Third Party Components&rdquo;).
        </span>
        <span>
          Although the services are provided to you subject to these Terms of
          Service, nothing in these Terms of Service prevents, restricts, or is
          intended to prevent or restrict you from obtaining Third Party
          Components under the applicable third party licenses or to limit your
          use of Third Party Components under those third party licenses.{' '}
        </span>
        <span>
          The services may also contain links to third party websites. Such
          linked websites are not under our control, and we are not responsible
          for their content.
        </span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>PAYMENT OF FEES</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          The then applicable fees for the services to be provided by Helpware,
          as well as the payment procedure are to be mentioned in the relevant
          Master Services Agreement and SOW to it, that is to be concluded
          between the parties prior to the beginning of the services provision.
        </span>
      </p>

      <h2>PRIVACY POLICY</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          We at Helpware deeply care about privacy and security of your personal
          data.
        </span>
        <span>Please, review our </span>
        <span>
          <a href="https://www.google.com/url?q=https://www.helpware.com/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1657616215736859&amp;usg=AOvVaw3_Mil6VU_iUQM5WfYeloEN">
            Privacy Policy
          </a>
        </span>
        <span>&nbsp;to learn how </span>
        <span>Helpware,</span>
        <span>&nbsp;Inc. and its worldwide companies &amp; subsidiaries </span>
        <span>
          collects, uses and shares the personal information you provide to us.
          It also describes the choices available to you regarding the use of,
          your access to, and how to update and correct your personal
          information.{' '}
        </span>
        <span>
          By using the Site, you agree to be bound by our Privacy Policy, which
          is incorporated into these Terms of Service.
        </span>
      </p>
      <p>
        <span>
          Please be advised that Helpware is a global organization headquartered
          in the United States with offices and/or employees/contractors across
          the globe, namely in Ukraine, EU, the Philippines, Mexico, Puerto
          Rico. Therefore we may transfer your personal information outside the
          European Economic Area (EEA) to members of our Group as well as to
          third parties. When we do so, we rely on appropriate or suitable
          safeguards recognized under the GDPR, including adequacy decisions and
          Standard Contractual Clauses.
        </span>
      </p>
      <p>
        <span>
          Furthermore, we do not knowingly accept, request, or solicit
          information from children or knowingly market to children. Therefore,
          in accordance with the U.S. Children&rsquo;s Online Privacy Protection
          Act, if we receive actual knowledge that anyone under the age of 13
          has provided personal information to us without the requisite and
          verifiable parental consent, we will remove that information from the
          Site as quickly as is reasonably practical.{' '}
        </span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>TERM AND TERMINATION</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          These Terms of Service are effective for you beginning when you first
          access the Site, and end upon the termination or expiration of the
          Master Services Agreement and relevant SOW to it concluded between you
          and Helpware before the beginning of the services provision or upon
          the termination of the Terms of Service according to this Section.{' '}
        </span>
      </p>
      <p>
        <span>
          If you violate any provision of these Terms of Service, your
          authorization to access the Site and Helpware&rsquo;s services
          mentioned on it automatically terminate.{' '}
        </span>
      </p>
      <p>
        <span>
          In addition, both parties may at their sole discretion terminate your
          account at any time for any reason or no reason, with or without
          notice.
        </span>
      </p>
      <p>
        <span>
          Moreover, if Helpware becomes aware that your use of the Site or the
          Services violates these Terms of Service, Helpware will provide you
          with notice of the violation. If you fail to correct the violation
          promptly upon the request, Helpware may suspend all or part of your
          use of the services.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>DISCLAIMER</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          THE SITE, SERVICES AND ALL MATERIALS AND CONTENT ON AND AVAILABLE
          THROUGH THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND ON AN
          &ldquo;AS AVAILABLE&rdquo; BASIS.{' '}
        </span>
      </p>
      <p>
        <span>
          You expressly agree that use of Helpware technology and materials are
          at your sole risk. To the fullest extent permitted by law, Helpware
          makes no warranties, expressed or implied, and hereby disclaims and
          negates all other warranties, including without limitation, implied
          warranties or conditions of merchantability, fitness for a particular
          purpose, non-infringement of intellectual property or other violation
          of rights, and any warranty arising out of course of dealing, usage,
          or trade. Helpware does not warrant that the services or any portion
          of the services, or any materials or content offered through the
          services, are accurate, complete, or current, or will be
          uninterrupted, secure, or free of errors, viruses, or other harmful
          components.
        </span>
      </p>
      <p>
        <span>
          Helpware may make changes to its services at any time without notice.
          Helpware will have no liability for any change to the services or any
          suspension or termination of your access to or use of the services.
          You are solely responsible for securing and backing up your
          applications, models, annotations, and your data.{' '}
        </span>
      </p>

      <h2>LIMITATIONS OF LIABILITY</h2>

      <p>
        <span>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF
          A PERSON, HELPWARE AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL
          EQUIPMENT AND TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES,
          REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE OR
          LIABLE WITH RESPECT TO ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR
          OTHER THEORY:&nbsp;(A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR
          INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE
          GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY
          INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL
          DAMAGES;&nbsp;(C) FOR ANY MATTER BEYOND HELPWARE&rsquo;S REASONABLE
          CONTROL; OR&nbsp;(D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS
          ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CLIENT TO
          HELPWARE FOR THE SERVICES UNDER THIS AGREEMENT IN THE 12 MONTHS PRIOR
          TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR
          NOT HELPWARE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>INDEMNIFICATION</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>You</span>
        <span>
          &nbsp;agree to indemnify, defend, and hold harmless Helpware from and
          against any and all damages, liabilities, claims, demands, costs, and
          expenses (including reasonable attorney&rsquo;s fees) made by any
          third-party due to or arising out of: (1) your use of Helpware&rsquo;s
          services; (2) your use of the Site; (3) breach of these Terms of
          Service; (4) your violation of the rights of a third party. &nbsp;{' '}
        </span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>GOVERNING LAW AND DISPUTE RESOLUTION</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          These Terms of Service is governed and construed by the laws of the
          State of Kentucky without regard to its conflict of law provisions.{' '}
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span>
          If any dispute, controversy or claim arises under or in connection
          with these Terms of Service, the Parties shall first attempt to
          resolve it informally. All disputes, disagreements or claims, which
          may arise of or in connection with these Terms of Service, if the
          Parties cannot settle them by negotiations, shall be submitted to the
          courts of the State of Kentucky.{' '}
        </span>
        <span>&nbsp;</span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>MISCELLANEOUS</h2>

      <p>
        <span></span>
      </p>
      <p>
        <span>
          If any provision of these Terms of Service is found to be
          unenforceable or invalid, that provision shall be limited or
          eliminated to the minimum extent necessary so that these Terms of
          Service will otherwise remain in full force and effect and
          enforceable.{' '}
        </span>
      </p>
      <p>
        <span>
          These Terms of Service and all rights and obligations hereunder may
          not be assigned without the written consent of the other party. Such
          written consent shall not be unreasonably withheld or denied. In
          accordance with this Section, these Terms of Service shall be binding
          upon and shall inure to the benefit of the parties hereto and their
          respective successors and permitted assigns.
        </span>
      </p>
      <p>
        <span>
          Nothing in these Terms of Service shall be construed as creating an
          agency, partnership, joint venture or any other form of association
          between the parties and the parties shall at all times be and remain
          independent contractors. Neither party shall have any right or
          authority, express or implied, to assume or create any obligation of
          any kind, or to make any representation or warranty, on behalf of the
          other party or to bind the other party in any respect whatsoever.
          &nbsp;
        </span>
      </p>
      <p>
        <span>
          All notices under these Terms of Service shall be in writing and shall
          be deemed to have been duly given when received, if personally
          delivered; when receipt is electronically confirmed, if transmitted by
          facsimile or e-mail; the day after it is sent, if sent for next day
          delivery by recognized overnight delivery service; and upon receipt,
          if sent by certified or registered mail, return receipt requested.
        </span>
      </p>
      <p>
        <span>
          Neither party will be treated as having waived any rights by not
          exercising (or delaying the exercise of) any rights under these Terms
          of Service.
        </span>
      </p>
      <p>
        <span>
          Neither party will be liable for failure or delay in performance to
          the extent caused by circumstances beyond its reasonable control.
        </span>
      </p>
      <p>
        <span>
          If there is a conflict between the provisions of these Terms of
          Service and the relevant Master Services Agreement and SOW to it,
          signed by the parties, the provisions of the Master Services Agreement
          shall prevail.{' '}
        </span>
      </p>
      <p>
        <span></span>
      </p>

      <h2>CONTACT US</h2>

      <p>
        <span>Our full details are: </span>
      </p>
      <p>
        <span>Helpware, Inc.</span>
      </p>
      <p>
        <span>Attn: TaskWare</span>
      </p>
      <p>
        <span>PO Box 701059, PMB 99737 Louisville, </span>
      </p>
      <p>
        <span>Kentucky 40270-1059 US </span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span></span>
      </p>
      <p>
        <span></span>
      </p>
      <div>
        <p>
          <span></span>
        </p>
      </div>
    </>
  );
}
