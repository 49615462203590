import React from 'react';
import { Modal } from 'components/_modals';
import { Row, Col, Button } from 'components/_main';
import { taskReturnValidationSchema } from 'utils';
import { Formik, useFormikContext } from 'formik';
import { InputLabelField } from 'components/_form';
import styled, { css } from 'styled-components/macro';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
}

function TaskReturnAuditorModal({ isOpen, onClose, onSubmit }: Props) {
  const formInitValues = {
    message: ''
  };

  const handleSubmit = async (values: { message: string }) => {
    onSubmit(values.message);
  };

  return (
    <Modal
      width={440}
      bodyStyle={{ padding: '30px' }}
      title="Return Task"
      isVisible={isOpen}
      onCancel={onClose}
      footer={false}
    >
      <Formik
        initialValues={formInitValues}
        onSubmit={handleSubmit}
        validationSchema={taskReturnValidationSchema}
      >
        <FormikBody onClose={onClose} />
      </Formik>
    </Modal>
  );
}

const InputFieldStyled = styled(InputLabelField)(
  () =>
    css`
      &.ant-row.ant-form-item {
        grid-auto-columns: 1fr;
      }
    `
);

function FormikBody({ onClose }: { onClose: () => void }) {
  const { isSubmitting, isValidating, submitForm } = useFormikContext();

  return (
    <Row gridGap="30px">
      <Col gridAutoColumns="1fr" width="100%">
        <InputFieldStyled
          textarea
          rows={3}
          name="message"
          layout="vertical"
          placeholder="Describe the reason please..."
          inputProps={{ style: { resize: 'none', height: '100px' } }}
        />
      </Col>
      <Col width="100%" displayFlex justifyContent="flex-end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button isLoading={isValidating || isSubmitting} onClick={submitForm}>
          Return
        </Button>
      </Col>
    </Row>
  );
}

export default TaskReturnAuditorModal;
