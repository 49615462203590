import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum,
  AnnotationUnitProps
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import { PolygonPoints } from 'containers/AnnotationSystem/reducer';
import { MouseEvent, useCallback, useEffect } from 'react';
import useCanvasCoords from './useCanvasCoords';
import { modifyPolygonPoint } from './utils';

interface Props {
  setProps: (partialProps: Partial<AnnotationUnitProps>) => void;
  unitId: string;
  props: AnnotationUnitProps;
}

export default function useSvgMouseMove({ props, setProps, unitId }: Props) {
  const { curEvent, curSelPointId, svgLayerRef, curMode } =
    useAnnotationSystemState();
  const canvasCoords = useCanvasCoords();
  const { isActive, isDotSel } = useStatus({
    unitId
  });

  const handleMouseMove = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      if (!isActive) {
        return;
      }

      const { polygonPoints = [] } = props ?? {};

      switch (curEvent) {
        case AnnotationSystemEventsEnum.CREATE_MODE:
        case AnnotationSystemEventsEnum.APP_POLYGON_POINT:
          if (!e) break;

          const x = e.clientX - canvasCoords[0];
          const y = e.clientY - canvasCoords[1];

          setProps({
            polygonPoints: modifyPolygonPoint(polygonPoints, {
              index: polygonPoints.length - 1,
              newPosition: [x, y]
            })
          });
          break;
        case AnnotationSystemEventsEnum.SELECTED_MODE:
          break;
        case AnnotationSystemEventsEnum.DRAG_MODE:
          if (e.buttons !== 1) break;

          if (curMode === AnnotationSystemModesEnum.DOT_SELECT) {
            if (
              curSelPointId === null ||
              !isDotSel ||
              typeof curSelPointId !== 'number'
            )
              break;

            setProps({
              polygonPoints: polygonPoints?.map((point, index) =>
                index === curSelPointId
                  ? [e.clientX - canvasCoords[0], e.clientY - canvasCoords[1]]
                  : point
              )
            });
            break;
          }

          if (curMode === AnnotationSystemModesEnum.UNIT_SELECT) {
            const newPolyPoints: PolygonPoints = polygonPoints.map((point) => [
              point[0] + e.movementX,
              point[1] + e.movementY
            ]);

            setProps({
              polygonPoints: newPolyPoints
            });
            break;
          }

          break;
        default:
          break;
      }
    },
    [
      canvasCoords,
      curEvent,
      curMode,
      curSelPointId,
      isActive,
      isDotSel,
      props,
      setProps
    ]
  );

  useEffect(() => {
    // @ts-ignore
    svgLayerRef?.addEventListener('mousemove', handleMouseMove);
    return () => {
      // @ts-ignore
      svgLayerRef?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove, isActive, svgLayerRef]);

  return handleMouseMove;
}
