import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Row, Col, Box, Text } from 'components/_main';
import AntdCircleProgress from 'components/AntdCircleProgress';
import LessonsNav from 'containers/LearningMaterials/LessonPages/LessonsNav';
import { useGetLearningMaterialsDataCtx } from 'containers/LearningMaterials/hooks/useLearningMaterialDataProvider';

function LessonTabContent() {
  const theme = useTheme();

  const { learningMaterials, percentCompleted } =
    useGetLearningMaterialsDataCtx();

  return (
    <Row
      alignItems="flex-start"
      height="100%"
      gridAutoRows="1fr min-content"
      overflow="hidden"
    >
      <LessonsNav data={learningMaterials} />
      <Box
        gridGap="20px"
        displayFlex
        alignItems="center"
        justifyContent="center"
        padding="12px 0"
        borderTop={`1px solid ${theme.colors.lighter}`}
      >
        <AntdCircleProgress
          type="circle"
          percent={percentCompleted}
          width={35}
        />
        <Col gridGap="4px">
          <Text variant="ui-1-bold">{percentCompleted}%</Text>
          <Text>completed</Text>
        </Col>
      </Box>
    </Row>
  );
}

export default LessonTabContent;
