import React from 'react';
import { Project } from 'generated/graphql';
import { isAdmin, isClient } from 'utils';
import { Row } from 'components/_main';
import ProjectDetailsCardModalContentTabsClient from './ProjectDetailsCard.Modal.Content.tabs.client';
import ProjectDetailsCardModalContentTabsAdmin from './ProjectDetailsCard.Modal.Content.tabs.admin';

interface Props {
  projectData: Project;
  projectDetailsTab: string;
  setProjectDetailsTab(tab: string): void;
}

const ProjectDetailsCardModalContentTabs = ({
  projectData,
  setProjectDetailsTab
}: Props) => {
  let contentRender = (
    <ProjectDetailsCardModalContentTabsClient projectData={projectData} />
  );

  if (isAdmin()) {
    contentRender = (
      <ProjectDetailsCardModalContentTabsAdmin
        projectData={projectData}
        setProjectDetailsTab={setProjectDetailsTab}
      />
    );
  }

  return (
    <Row
      px="40px"
      wide
      height="100%"
      gridAutoRows="100%"
      overflow="hidden"
      gridRow="1 / 2"
      gridColumn="2 / 3"
      alignItems="flex-start"
      pb={isClient() ? '40px' : '0px'}
    >
      {contentRender}
    </Row>
  );
};

export default ProjectDetailsCardModalContentTabs;
