import styled, { css } from 'styled-components/macro';
import React from 'react';
import { Spin } from 'antd';

interface AntdLoadingSpinLegacyProps {
  withBg?: boolean;
  whiteDots?: boolean;
  size?: number;
}
const Wrapper = styled.div(
  ({ withBg, whiteDots, size = 16 }: AntdLoadingSpinLegacyProps) => css`
    display: flex;
    position: absolute;
    flex-flow: row;
    top: 0;
    left: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 999;

    .ant-spin-dot {
      top: 4px;
      width: ${size}px;
      height: ${size}px;

      .ant-spin-dot-item {
        width: ${size / 3}px;
        height: ${size / 3}px;
      }
    }

    .ant-spin .ant-spin-dot.ant-spin-dot-spin i.ant-spin-dot-item {
      background-color: ${({ theme }) => theme.colors.primary} !important;
    }

    ${withBg &&
    css`
      background-color: #ffffffcc;
    `}

    ${whiteDots &&
    css`
      .ant-spin .ant-spin-dot.ant-spin-dot-spin i.ant-spin-dot-item {
        background-color: #fff !important;
      }
    `}
  `
);

const AntdLoadingSpin: React.FC<AntdLoadingSpinLegacyProps> = ({
  withBg = true,
  whiteDots = false,
  size
}) => {
  return (
    <Wrapper size={size} withBg={withBg} whiteDots={whiteDots}>
      <Spin />
    </Wrapper>
  );
};

export default AntdLoadingSpin;
