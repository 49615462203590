import React from 'react';
import { Project } from 'generated/graphql';
import { Col } from 'components/_main';
import { StartTaskIcon, StartAuditIcon } from 'assets/tableRowActions';
import { Tooltip } from 'antd';
import { getTasksCount } from 'utils/getTasksCount';
import Icon from 'components/Icon';

interface RowHelperActionsProps {
  data: Project;
  isEnabled?: boolean;
  onStartTasking: (data: Project) => void;
  onStartAudit: (data: Project) => void;
}

function RowActionsHelper({
  data,
  isEnabled,
  onStartTasking,
  onStartAudit
}: RowHelperActionsProps) {
  const {
    inAuditTasksCount,
    newTasksCount,
    readyForAuditTasksCount,
    startedTasksCount
  } = getTasksCount(data);

  return (
    <Col className="row-actions">
      {isEnabled && data && (newTasksCount > 0 || startedTasksCount > 0) && (
        <Icon hoverStrokeColor="fg" onClick={() => onStartTasking(data)}>
          <Tooltip title="Start Tasking">
            <StartTaskIcon />
          </Tooltip>
        </Icon>
      )}
      {isEnabled && !!(readyForAuditTasksCount || inAuditTasksCount) && (
        <Icon hoverStrokeColor="fg" onClick={() => onStartAudit(data)}>
          <Tooltip title="Start Audit">
            <StartAuditIcon />
          </Tooltip>
        </Icon>
      )}
    </Col>
  );
}

export default RowActionsHelper;
