import React from 'react';
import { Formik } from 'formik';
import { Button, Row, Text, Col, Box } from 'components/_main';
import { Modal } from 'components/_modals';
import AssignedHelpers from 'containers/ProjectDetailsCard/tabs/AssignedHelpers';
import { useProjectDetailsUpdate } from 'containers/ProjectDetailsCard/hooks/useProjectDetailsUpdate';
import { Project, ProjectStatus } from 'generated/graphql';
import { FormikHelpers } from 'formik/dist/types';
import { useProjectsContext } from 'containers/Projects/Projects.context';
import ProjectRates from 'containers/ProjectRates';

interface ActivateProjectModalProps {
  isOpen: boolean;
  onClose(): void;
}

function ActivateProjectModal({ isOpen, onClose }: ActivateProjectModalProps) {
  const { currentProjectData } = useProjectsContext();
  const { initPrjData, updateProject, updatePrjLoading } =
    useProjectDetailsUpdate(currentProjectData, onClose);

  if (!currentProjectData.id) return null;

  const handleSubmitForm = (
    values: Project,
    formikHelpers: FormikHelpers<any>
  ) => {
    updateProject({ ...values, status: ProjectStatus.Active }, formikHelpers);
  };

  return (
    <Modal
      width="700px"
      title="Activate Project"
      isVisible={isOpen}
      onCancel={onClose}
      footer={false}
      formLayout="vertical"
      bodyStyle={{ padding: '20px 30px' }}
    >
      <Row
        gridGap="20px"
        height="680px"
        overflow="hidden"
        gridAutoRows="max-content 1fr"
      >
        <Text lineHeight="26px">
          In order to activate the project please set Helper and Auditor rates
          and assign at least one helper and one auditor.
        </Text>
        <Formik
          enableReinitialize
          initialValues={initPrjData}
          onSubmit={handleSubmitForm}
        >
          {({ submitForm, values }) => (
            <Row
              gridAutoRows="max-content 1fr max-content"
              height="100%"
              overflow="hidden"
            >
              <ProjectRates embedded />
              <AssignedHelpers projectData={currentProjectData} />
              <Col width="100%" justifyContent="flex-end">
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  disabled={
                    updatePrjLoading || !values.selectedHelperIds.length
                  }
                  onClick={submitForm}
                >
                  Activate
                </Button>
              </Col>
            </Row>
          )}
        </Formik>
      </Row>
    </Modal>
  );
}

export default ActivateProjectModal;
