import { Input } from 'antd';
import React from 'react';
import { Root } from './Search.styled';
import { SearchIcon } from 'assets';
import { BoxStyledProps } from 'components/_main/Box';
import { SearchProps } from 'antd/lib/input';

interface Props extends SearchProps {
  boxProps?: Omit<BoxStyledProps, 'color'>;
  noBorder?: boolean;
}

const Search = ({ boxProps, noBorder, ...rest }: Props) => {
  return (
    <Root {...boxProps} noBorder={noBorder}>
      <Input.Search
        placeholder="Type helper name or email..."
        prefix={<SearchIcon />}
        {...rest}
      />
    </Root>
  );
};

export default Search;
