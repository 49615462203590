import { MutableRefObject, useEffect } from 'react';
import { useAnnotationSystemActions } from 'containers/AnnotationSystem/context';

interface Props {
  ref: MutableRefObject<HTMLDivElement | null>;
}

const useTouchEvent = ({ ref }: Props) => {
  const { onZoomIn, onZoomOut } = useAnnotationSystemActions();

  const handleWheel = (e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
      if (e.deltaY < 0) {
        onZoomIn(null);
        return;
      }
      onZoomOut(null);
    }
  };

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    element.addEventListener('wheel', handleWheel);

    return () => {
      element.removeEventListener('wheel', handleWheel);
    };
  });
};

export default useTouchEvent;
