import { AnnotationSystemEventsEnum } from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';
import { MouseEvent, useCallback } from 'react';

interface Props {
  unitId: string;
}

export default function usePointMouseDown({ unitId }: Props) {
  const { curEvent } = useAnnotationSystemState();
  const { onSetCurEvent, onSetCurSelPointId } = useAnnotationSystemActions();
  const { isCreate, isDotSel } = useStatus({
    unitId
  });

  const handleMouseDown = useCallback(
    (index: number) => (e: MouseEvent) => {
      if (isCreate || !isDotSel) return;
      e.stopPropagation();
      onSetCurSelPointId(index);

      switch (curEvent) {
        case null:
          onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
          break;
        default:
          break;
      }
    },
    [curEvent, isCreate, isDotSel, onSetCurEvent, onSetCurSelPointId]
  );

  return handleMouseDown;
}
