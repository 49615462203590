import React, { useMemo } from 'react';
import { Drawer } from 'antd';
import {
  Layout as AddProjectWizardLayout,
  Styles
} from './AddProjectWizard.ui';
import useOpen from 'hooks/useOpen';
import { Box } from 'components/_main';
import { Button } from 'components/_main/Button';
import StepsSidebar from './components/StepsSidebar';
import {
  flatWizardConfig,
  removeNullChildren
} from './AddProjectWizard.config';
import DrawerHeader from './components/DrawerHeader';
import { useTheme } from 'styled-components/macro';
import {
  ContextProvider,
  useAddProjectWizardContext
} from './AddProjectWizard.context';
import AddProjectWizardMainLegacy from './AddProjectWizard.MainLegacy';
import { WizardStep } from './components/Wizard/WizardStep';
import { upper } from 'utils';
import { AddProjectWizardProps } from './AddProjectWizard.types';
import { wizardConfig as mainWizardConfig } from './AddProjectWizard.config';

export function AddProjectWizardWithAddButton(
  props: Omit<AddProjectWizardProps, 'isOpen' | 'onClose'> & {
    initiallyOpen?: boolean;
  }
) {
  const { isOpen, onOpen, onClose } = useOpen({
    initial: props?.initiallyOpen
  });

  return (
    <>
      <Button onClick={onOpen}>{upper('+ Add Project')}</Button>
      <AddProjectWizard {...props} onClose={onClose} isOpen={isOpen} />
    </>
  );
}

export function AddProjectWizard(props: AddProjectWizardProps) {
  const { isOpen, initialContextData, initialFormData, onClose, wizardConfig } =
    props;

  return (
    <Drawer
      destroyOnClose
      closable={false}
      visible={isOpen}
      width="100%"
      height="100vh"
    >
      <Styles />
      <ContextProvider
        initialData={initialContextData}
        initialFormData={initialFormData}
      >
        <AddProjectWizardContextConsumer
          wizardConfig={wizardConfig}
          onClose={onClose}
        />
      </ContextProvider>
    </Drawer>
  );
}

export function AddProjectWizardContextConsumer({
  onClose,
  wizardConfig = mainWizardConfig
}: Pick<AddProjectWizardProps, 'onClose' | 'wizardConfig'>) {
  const context = useAddProjectWizardContext();

  const { initialFormData } = context;

  const theme = useTheme();

  const bakedWizardConfig = useMemo(
    () => wizardConfig(context),
    [context, wizardConfig]
  );

  const flatBakedWizardConfig = useMemo(
    () => flatWizardConfig(bakedWizardConfig),
    [bakedWizardConfig]
  );

  const finalStep = context?.formOptions?.currentStep ?? 0;

  return (
    <>
      <AddProjectWizardLayout
        gridTemplateRows="60px 1fr"
        gridTemplateColumns="300px 1fr"
        height="100vh"
        gridGap="0px"
      >
        <DrawerHeader
          title="Add New Project"
          onClose={!context?.isLoading ? onClose : undefined}
          gridArea="header"
        />
        <Box
          gridArea="sidebar"
          padding="30px 40px"
          width="100%"
          borderRight={`1px solid ${theme.colors.grayLighter}`}
        >
          <StepsSidebar
            configuration={removeNullChildren(bakedWizardConfig)}
            currentStep={finalStep}
          />
        </Box>
        <Box
          height="100%"
          width="100%"
          maxWidth="1200px"
          gridArea="main"
          padding="0 60px 30px"
          overflowY="auto"
        >
          <AddProjectWizardMainLegacy
            showSaveDraftButton={
              flatBakedWizardConfig[finalStep]?.showSaveDraftButton
            }
            showSkipStepButton={
              flatBakedWizardConfig[finalStep]?.showSkipStepButton
            }
            closeWizardWindow={onClose}
            isDisabledSaveButton={
              flatBakedWizardConfig[finalStep]?.isDisabledSaveButton
            }
            initialValuesProps={initialFormData}
          >
            {flatBakedWizardConfig.map((step) => {
              if (!step?.component) return null;

              return (
                <WizardStep
                  title={step.title}
                  key={step.id}
                  description={step.description}
                  validationSchema={step?.validation}
                >
                  {step?.component &&
                    React.createElement(step?.component, step?.componentProps)}
                </WizardStep>
              );
            })}
          </AddProjectWizardMainLegacy>
        </Box>
      </AddProjectWizardLayout>
    </>
  );
}
export default AddProjectWizard;
