import React, { useLayoutEffect, useRef, useState } from 'react';
import useGetSlug from 'hooks/useGetSlug';
import { TaskType } from 'generated/graphql';
import { isClient } from 'utils/role';
import useTaskData from 'containers/TasksRouter/hooks/useTaskData';
import { TTasksPage } from 'containers/TasksRouter/Tasks.types';
import useBeforeCloseTab from 'hooks/useBeforeCloseTab';
import { useRedirect } from 'hooks/useRedirect';
import { css, useTheme } from 'styled-components/macro';
import { AnnotationSystemContextProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { Box } from 'components/_main';
import AnnotationHeader from './components/AnnotationHeader';
import AnnotationSystem, {
  AnnotationSystemContext
} from 'containers/AnnotationSystem';
import AnnotationLayout from './components/AnnotationLayout';
import AnnotationToolbar from './components/AnnotationToolbar';
import AnnotationSidebar from './components/AnnotationSidebar';
import Statusbar from './components/Statusbar';
import TaskReturnedInfo from './components/TaskReturnedInfo';
import { REDUCER_INIT } from 'containers/AnnotationSystem/reducer';
import { roundAnnotationUnitsTimestampsToMs } from 'containers/AnnotationSystem/reducer/utils';
import VideoSliderWithFrame from './components/VideoSliderWithFrame';
import { VideoCtxProvider } from 'containers/AnnotationSystem/videoContext';
import { AnnotationViewProvider } from 'containers/TasksRouter/AnnotationView/AnnotationView.context';

const AnnotationViewWrapper = (props: TTasksPage) => {
  return (
    <AnnotationViewProvider>
      <AnnotationView {...props} />
    </AnnotationViewProvider>
  );
};

const AnnotationView: React.FC<TTasksPage> = ({
  taskingType = 'tasking',
  argSlugTaskId,
  argProjectId,
  specType,
  taskType,
  isVideoAsImage,
  showFramesSelection
}) => {
  const { taskId: slugTaskId, projectId: slugProjectId } = useGetSlug();
  const finalTaskId = argSlugTaskId ?? slugTaskId;
  const finalProjectId = argProjectId ?? slugProjectId;
  const handleRedirect = useRedirect();
  const theme = useTheme();
  const mediaAssetWrapperRef = useRef<HTMLDivElement>(null);
  const [, update] = useState<number | null>(null);
  const intervalIdRef = useRef<ReturnType<typeof setInterval>>();

  const { taskData, setTaskData, startPracticeSession, practiceEndTime } =
    useTaskData({
      projectId: finalProjectId,
      taskId: finalTaskId,
      type: taskingType
    });

  const {
    project,
    id: taskId,
    taskType: annotationType,
    mediaUrl,
    labels,
    result,
    videoStart,
    videoEnd,
    isAvailableForHelper,
    videoFPS,
    videoFrameRate
  } = taskData ?? {};

  const { id: projectId } = project ?? {};

  useBeforeCloseTab({
    actionBeforeClose: async () => {}
  });

  /** TODO: Maybe fix? */
  useLayoutEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    if (mediaAssetWrapperRef.current) {
      return;
    }

    intervalIdRef.current = setInterval(() => {
      update(Date.now());

      if (mediaAssetWrapperRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }, 500);
  }, []);

  /**
   * Redirect back if helper start auditing without being an auditor
   */
  if (isClient()) {
    handleRedirect('/projects');
  }

  if (
    !(taskData && 'mediaUrl' in taskData && taskType) ||
    !specType ||
    !taskId ||
    !project ||
    !mediaUrl ||
    !projectId
  ) {
    return null;
  }

  const isVideo = taskType === TaskType.VideoAnnotation;

  let annotationSystemProps: AnnotationSystemContextProps = {
    ...REDUCER_INIT,
    type: 'image',
    taskData,
    taskId: taskData.taskId ?? REDUCER_INIT.taskId,
    results: result ? roundAnnotationUnitsTimestampsToMs(result) : [],
    mediaUrl,
    setTaskData,
    labels,
    annotationType,
    annotationSpecificationType: specType,
    isInitMediaLoading: true,
    isViewOnly: taskingType === 'tasking' && !isAvailableForHelper
  };

  if (isVideo) {
    annotationSystemProps = {
      ...annotationSystemProps,
      type: 'video',
      videoFPS: videoFPS ?? 1,
      videoFrameRate: videoFrameRate ?? 1,
      videoStart: videoStart ?? REDUCER_INIT.videoStart,
      videoEnd:
        (isVideoAsImage ? videoStart : videoEnd) ?? REDUCER_INIT.videoEnd
    };
  }

  return (
    <>
      <Box
        data-component="tasks-layout"
        display="grid"
        gridGap="0px"
        height="100vh"
        overflow="hidden"
        backgroundColor={theme.colors.bg}
        gridTemplateRows="max-content 1fr"
      >
        <AnnotationHeader
          title={taskData?.project?.title || ''}
          taskingType={taskingType}
        />
        <Box overflow="auto">
          <AnnotationSystemContext {...annotationSystemProps}>
            <VideoCtxProvider>
              <AnnotationLayout
                taskData={taskData}
                taskType={taskType}
                ref={mediaAssetWrapperRef}
                renderAbove={
                  <AnnotationToolbar
                    taskId={taskData.taskId}
                    helperTimeSpent={taskData?.helperTimeSpent ?? 0}
                    taskType={taskType}
                    taskingType={taskingType}
                    showFramesSelection={showFramesSelection}
                    setTaskData={setTaskData}
                  />
                }
                renderSidebar={
                  <AnnotationSidebar
                    taskId={taskData.taskId}
                    taskType={taskType}
                    taskingType={taskingType}
                    taskProjectData={taskData?.project}
                    taskProjectHistory={taskData?.history}
                  />
                }
                renderFooter={
                  <Statusbar
                    taskId={taskData.taskId}
                    finalProjectId={projectId}
                    taskingType={taskingType}
                    taskData={taskData}
                    taskType={taskType}
                    practiceEndTime={practiceEndTime}
                    returnedFromAuditor={taskData?.returnedFromAuditor ?? false}
                    setTaskData={setTaskData}
                    startPracticeSession={startPracticeSession}
                  />
                }
                renderVideoProgressBar={
                  !isVideoAsImage && isVideo ? (
                    <VideoSliderWithFrame />
                  ) : undefined
                }
              >
                <Box width="100%" height="100%" position="relative">
                  <Box id="AnnotationMainHint" />
                  <Box
                    width="100%"
                    height="100%"
                    position="relative"
                    overflow="auto"
                  >
                    <Box
                      id="LabelsDropdown"
                      width="100%"
                      height="100%"
                      position="absolute"
                    />
                    <AnnotationSystem
                      {...annotationSystemProps}
                      key={`AnnotationSystem-${taskData?.taskId}`}
                      boxProps={{ gridArea: isVideo ? 'video' : 'image' }}
                      withSvgLayer={true}
                      wrapperRef={mediaAssetWrapperRef.current}
                    />
                  </Box>
                </Box>
              </AnnotationLayout>
            </VideoCtxProvider>
          </AnnotationSystemContext>
        </Box>
      </Box>
      <TaskReturnedInfo taskData={taskData} taskingType={taskingType} />
    </>
  );
};

export default AnnotationViewWrapper;
