import React, { PropsWithChildren } from 'react';
import { Box } from 'components/_main';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { COUNT_PER_PAGE } from './SectionFrames.constants';
import Icon from 'components/Icon';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

export function SectionFramesPagination({
  total,
  children
}: PropsWithChildren<{ total: number }>) {
  const { pageFrames } = useAnnotationSystemState();
  const { onSetPageFrames } = useAnnotationSystemActions();
  const isFirstPage = pageFrames === 0;
  const isLastPage = (pageFrames + 1) * COUNT_PER_PAGE >= total;

  const handleLoadPrevItems = () => {
    onSetPageFrames(pageFrames - 1);
  };

  const handleLoadNextItems = () => {
    onSetPageFrames(pageFrames + 1);
  };

  return (
    <Box
      display="grid"
      minWidth={COUNT_PER_PAGE * 10 - 2}
      maxWidth={COUNT_PER_PAGE * 10 - 2}
      padding="40px 30px"
      gridGap={0}
      gridTemplateColumns="max-content 1fr max-content"
      gridTemplateRows="max-content max-content"
      alignItems="center"
      width="100%"
    >
      <Box gridColumn="1/2" gridRow="1/2">
        <Icon size={16} disabled={isFirstPage} onClick={handleLoadPrevItems}>
          <CaretLeft />
        </Icon>
      </Box>
      {children}
      <Box gridColumn="3/4" gridRow="1/2">
        <Icon size={16} disabled={isLastPage} onClick={handleLoadNextItems}>
          <CaretRight />
        </Icon>
      </Box>
    </Box>
  );
}
