import React from 'react';
import { CursorIcon, HandIcon, PlusCircleIcon } from 'assets/taskingTopToolbar';
import { PolygonIcon } from 'assets';
import {
  AnnotationSystemEventsEnum,
  AnnotationSystemModesEnum
} from 'containers/AnnotationSystem/AnnotationSystem.types';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import { ToolbarIcon, ToolbarSection } from './AnnotationToolbar.ui';
import { Col } from 'components/_main';
import SectionZoom from './SectionZoom';
import SectionSvgLayerOpacity from './SectionSvgLayerOpacity';
import useCurSelUnitProps from 'containers/AnnotationSystem/hooks/useCurSelUnitProps';
import useStatus from 'containers/AnnotationSystem/hooks/useStatus';

export default function PolygonsAnnotationToolbar() {
  const { isUnitCreatingProcess, curEvent, isViewOnly, curSelUnitId } =
    useAnnotationSystemState();

  const {
    onSetCurEvent,
    onSetCurMode,
    onModifyAnnotationUnit,
    onDeletePolygonPoint,
    toggleLabelSelectDropdown,
    onDeleteAnnotationUnit
  } = useAnnotationSystemActions();
  const curSelUnitProps = useCurSelUnitProps();
  const { unitId, polygonPoints } = curSelUnitProps;

  const isNavigate = curEvent === AnnotationSystemEventsEnum.ZOOM_NAVIGATION;

  const { isCreate, isSelected, isPolyAddPoint, isDotSel } =
    useStatus(curSelUnitProps);

  const handleCreate = () => {
    /** TODO: Move to reducer. DUPLICATED. Same as in onEndCreate for Polygons (inside useHotkeys for Poly) */
    if (isUnitCreatingProcess) {
      /** No dots placed -> Go to default mode, kinda exit from create mode and go to select */
      if (polygonPoints?.length! === 1) {
        onSetCurEvent(null);
        return;
      }

      /** Less then 3 dots placed -> Remove polygon */
      if (polygonPoints?.length! < 4) {
        onSetCurEvent(AnnotationSystemEventsEnum.CREATE_MODE);
        /** TODO: Add DELETE_CUR_ANNOTATION_UNIT to reducer */
        curSelUnitId && onDeleteAnnotationUnit(curSelUnitId);
        return;
      }

      /** More then 3 placed dots, & 1 floating, removing last one and select created polygon */
      polygonPoints && onDeletePolygonPoint(polygonPoints?.length - 1);
      onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
      toggleLabelSelectDropdown(null);
      return;
    }
    onSetCurEvent(AnnotationSystemEventsEnum.CREATE_MODE);
  };

  const handlePointsClick = () => {
    if (isDotSel) {
      onSetCurMode(AnnotationSystemModesEnum.UNIT_SELECT);
      return;
    }
    onSetCurMode(AnnotationSystemModesEnum.DOT_SELECT);
  };

  const handleHandClick = () => {
    if (isNavigate && isSelected) {
      onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
      return;
    }

    if (isNavigate) {
      onSetCurEvent(null);
      return;
    }

    onSetCurEvent(AnnotationSystemEventsEnum.ZOOM_NAVIGATION);
  };

  const handleAddPolyPointsClick = () => {
    onSetCurMode(AnnotationSystemModesEnum.UNIT_SELECT);
    if (isPolyAddPoint) {
      onSetCurEvent(AnnotationSystemEventsEnum.SELECTED_MODE);
      return;
    }

    polygonPoints?.length &&
      onModifyAnnotationUnit({
        unitId,
        polygonPoints: [...polygonPoints, [0, 0]]
      });
    onSetCurEvent(AnnotationSystemEventsEnum.APP_POLYGON_POINT);
  };

  return (
    <Col alignItems="center">
      <ToolbarSection>
        <ToolbarIcon
          tooltip="Start drawing new polygon"
          icon={<PolygonIcon />}
          active={isCreate}
          disabled={isViewOnly}
          onClick={handleCreate}
        />
      </ToolbarSection>
      <ToolbarSection>
        {isSelected && (
          <ToolbarIcon
            tooltip="Continue to add points from last"
            icon={<PlusCircleIcon />}
            active={isPolyAddPoint}
            onClick={handleAddPolyPointsClick}
          />
        )}
        <ToolbarIcon
          tooltip="Move canvas while in zoom mode"
          icon={<HandIcon />}
          active={isNavigate}
          onClick={handleHandClick}
        />
        {isSelected && (
          <ToolbarIcon
            tooltip="Start manipulating with polygon points"
            icon={<CursorIcon />}
            active={isDotSel}
            disabled={isViewOnly}
            onClick={handlePointsClick}
          />
        )}
      </ToolbarSection>
      <SectionZoom />
      <SectionSvgLayerOpacity />
    </Col>
  );
}
