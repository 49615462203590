import React, { useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'utils/toast';
import { EditOutlined } from '@ant-design/icons';
import InputLabelField from 'components/_form/InputLabelField';
import { ButtonPrimary, ButtonSecondary } from 'components/_main';
import { Root } from './TitleHeaderContent.ui';
import { clientValidationSchemaNew } from 'utils';

interface FormValues {
  firstName: string;
  lastName: string;
  companyName?: string | null;
  id: string;
  email: string;
}

interface TitleHeaderContentProps extends FormValues {
  loading?: boolean;
  onSubmit: (values: FormValues) => {};
  isEditable?: boolean;
}

const TitleHeaderContent = ({
  firstName,
  companyName,
  lastName,
  id,
  email,
  loading,
  onSubmit,
  isEditable = true
}: TitleHeaderContentProps) => {
  const [isEdit, setIsEdit] = useState(false);

  if (isEdit && isEditable) {
    return (
      <Formik
        onSubmit={async (values) => {
          try {
            (await onSubmit) && (await onSubmit(values));
            setIsEdit(false);
          } catch (e: any) {
            toast.error(e && 'message' in e && e.message);
          }
        }}
        initialValues={{
          firstName,
          lastName,
          email,
          companyName,
          id
        }}
        validationSchema={clientValidationSchemaNew}
      >
        {({ submitForm }) => (
          <Root className={`normal editing`}>
            <InputLabelField
              placeholder="First Name"
              layout="vertical"
              role="firstName"
              name="firstName"
              small
              forInputField
            />
            <InputLabelField
              layout="vertical"
              role="lastName"
              name="lastName"
              placeholder="Last Name"
              small
              forInputField
            />
            <InputLabelField
              layout="vertical"
              role="email"
              name="email"
              placeholder="Email"
              small
              forInputField
            />
            <InputLabelField
              layout="vertical"
              role="companyName"
              name="companyName"
              placeholder="Company Name"
              small
              forInputField
            />
            <div className="control-buttons">
              <ButtonPrimary
                iconStyle
                isLoading={loading}
                iconSize="2rem"
                onClick={() => submitForm()}
              >
                ✔
              </ButtonPrimary>
              {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
              <ButtonSecondary
                iconStyle
                iconSize="2rem"
                onClick={() => setIsEdit(false)}
              >
                ❌
              </ButtonSecondary>
            </div>
          </Root>
        )}
      </Formik>
    );
  }

  return !loading ? (
    <Root className={`normal ${isEditable && 'editable'}`}>
      {companyName ? (
        <span className="title">
          {companyName} ({firstName} {lastName})
        </span>
      ) : (
        <span className="title">
          {firstName} {lastName}
        </span>
      )}
      <span className="email">{email}</span>
      {isEditable && (
        <span className="editButton" onClick={() => setIsEdit(true)}>
          <EditOutlined />
        </span>
      )}
    </Root>
  ) : (
    <>...loading</>
  );
};

export default TitleHeaderContent;
