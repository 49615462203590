import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import { gridAutoFlow } from 'styled-system';
import Box from '../Box';
import { horizCenter, widthWide } from '../Box/mixins';
import { ColProps } from './Col.types';

const Col = styled(
  forwardRef<HTMLDivElement, ColProps>((props, ref) => (
    <Box
      display="grid"
      ref={ref}
      gridAutoFlow="column"
      gridAutoColumns={props?.wide ? '100%' : 'max-content'}
      {...props}
    />
  ))
)(widthWide('max-content'), horizCenter());

export default Col;
