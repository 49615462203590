import React from 'react';
import { FormikError } from '../../../../components/_form';
import { Row } from '../../../../components/_main';
import {
  UploadFiles,
  UploadFilesContextProviderProps
} from '../../../UploadFiles';
import { useAddProjectWizardContext } from '../../AddProjectWizard.context';
import { ProjectType } from '../../../../appTypes';
import { convertToFileStates } from '../../../UploadFiles/UploadFiles.utils';
import { ADD_WIZARD_FIELDS } from 'appConstants';
import { useField } from 'formik';
import { toast } from 'react-toastify';
import useGetUserSessionData from 'hooks/useGetUserSessionData';

interface StepUploadDatasetMultipleDataProviderProps {
  mediaFileNameName?: string;
  uploadFilesBlock?: React.ReactNode;
}

const videoTypes = '.avi, .mp4';
const imageTypes = '.jpg, .jpeg, .png';
const maxTotalSize = 500000000;

/** Image or video annotation. Multiple files */
function StepUploadDatasetMultipleDataProvider({
  mediaFileNameName = 'mediaFileName',
  uploadFilesBlock
}: StepUploadDatasetMultipleDataProviderProps) {
  const { isPremium } = useGetUserSessionData();
  const { annotationMultipleFiles, onSetAnnotationMultipleFiles, type } =
    useAddProjectWizardContext();

  const [, , helper] = useField(ADD_WIZARD_FIELDS.media);

  const accept =
    type === ProjectType.IMAGE_ANNOTATION ? imageTypes : videoTypes;

  const handleError = (_: string, error?: string) => {
    if (!error) return;
    helper.setTouched(true);
    helper.setError(error);
    toast.error(error);
  };

  return (
    <Row>
      <StepUploadDatasetMultiple
        initialData={{
          onSuccess: ({ files }) => {
            onSetAnnotationMultipleFiles(files);
          },
          onDelete: ({ files }) => {
            onSetAnnotationMultipleFiles(files);
          },
          onFilesUpdate: () => {
            helper.setError(undefined);
          },
          initialFiles: convertToFileStates(annotationMultipleFiles),
          maxTotalSize: isPremium ? null : maxTotalSize,
          onError: handleError
        }}
        uploaderProps={{
          accept,
          multiple: true,
          uploadFilesBlock
        }}
      />
      <FormikError name={mediaFileNameName} />
    </Row>
  );
}

function StepUploadDatasetMultiple(props: UploadFilesContextProviderProps) {
  return (
    <Row>
      <UploadFiles {...props} />
    </Row>
  );
}

export default StepUploadDatasetMultipleDataProvider;
