import React, { useEffect } from 'react';
import { TabProps } from '../types';
import {
  PerformanceBlock,
  PerformanceTabRoot,
  VerticalBar,
  OtherBlockRoot,
  ProjectsBlockRoot,
  SpecializationBlockRoot,
  ProjectUnit
} from './Performance.ui';
import {
  BigTitleNumberUnitPrimary,
  VerticalField
} from '../HelperDetailsCard.ui';
import { useGetHelperPerformanceDataLazyQuery } from 'generated/graphql';
import { useTheme } from 'styled-components/macro';
import { ProjectTypeLabel } from 'appTypes';

export type PerformanceData = {
  totalProjectsCount: number;
  projectsCountAsHelper: number;
  projectsCountAsAuditor: number;
  topProjects: { count: number; type: string }[];
  returnRateAsHelper: number;
  returnRateAsAuditor: number;
  penaltyRateAsHelper: number;
  penaltyRateAsAuditor: number;
};
export type PerformanceTabProps = {
  data: PerformanceData;
  loading?: boolean;
  helperId?: number;
} & TabProps;

function parseNumber(number?: number | null) {
  return number && number.toFixed(2);
}

function PerformanceTab(props: PerformanceTabProps) {
  const { helperId, data: dataProps } = props;
  const [query, { data }] = useGetHelperPerformanceDataLazyQuery();
  const theme = useTheme();

  useEffect(() => {
    if (helperId) {
      query({
        variables: {
          helperId
        }
      });
    }
  }, [helperId, query]);

  const finalData = dataProps ?? data?.helper;

  if (!finalData) return null;

  return (
    <PerformanceTabRoot>
      <PerformanceBlock title="Projects">
        <ProjectsBlockRoot>
          <ProjectUnit
            numberColor={theme.colors.primary}
            key="OVERALL"
            title="OVERALL"
            number={finalData?.totalProjectsCount}
          />
          <VerticalBar />
          <ProjectUnit
            key="AS HELPER"
            title="AS HELPER"
            number={finalData?.projectsCountAsHelper}
            barColor={theme.colors.grayLight}
          />
          <ProjectUnit
            key="AS AUDITOR"
            title="AS AUDITOR"
            number={finalData?.projectsCountAsAuditor}
            barColor={theme.colors.gray}
          />
        </ProjectsBlockRoot>
      </PerformanceBlock>
      {finalData.topProjects && finalData.topProjects.length > 0 && (
        <PerformanceBlock title="Specialization">
          <SpecializationBlockRoot>
            {finalData.topProjects?.map(({ count, type }, index) => {
              return (
                <>
                  <BigTitleNumberUnitPrimary
                    title={
                      ProjectTypeLabel[type as keyof typeof ProjectTypeLabel]
                    }
                    number={count}
                    key={type}
                    style={{
                      width: '125px'
                    }}
                  />
                  {finalData.topProjects &&
                    finalData.topProjects.length - 1 > index && (
                      <VerticalBar
                        style={{
                          width: '30px'
                        }}
                      />
                    )}
                </>
              );
            })}
          </SpecializationBlockRoot>
        </PerformanceBlock>
      )}
      <PerformanceBlock title="Other">
        <OtherBlockRoot>
          <VerticalField title="Average return rate">
            <span key="as-helper">
              As helper: {parseNumber(finalData?.returnRateAsHelper)}%
            </span>
            <span key="as-auditor">
              As auditor: {parseNumber(finalData?.returnRateAsAuditor)}%
            </span>
          </VerticalField>
          <VerticalField title="Penalty rate info">
            <span key="as-helper">
              As helper: {parseNumber(finalData?.penaltyRateAsHelper)}%
            </span>
            <span key="as-auditor">
              As auditor: {parseNumber(finalData?.penaltyRateAsAuditor)}%
            </span>
          </VerticalField>
        </OtherBlockRoot>
      </PerformanceBlock>
    </PerformanceTabRoot>
  );
}

export default PerformanceTab;
