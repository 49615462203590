import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

export default function useGetUrlParams() {
  const history = useNavigate();

  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  const handleUpdate = useCallback(() => {
    history(
      {
        search: params.toString()
      },
      {
        replace: true
      }
    );
  }, [history, params]);

  return useMemo(
    () => ({
      params,
      update: handleUpdate
    }),
    [handleUpdate, params]
  );
}
