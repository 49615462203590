import { useAssignedProjectTitlesToHelperQuery } from 'generated/graphql';

function useGetAssignedProjectTitlesToHelper(helperId: number) {
  const { data, loading } = useAssignedProjectTitlesToHelperQuery({
    variables: {
      helperId
    },
    skip: !helperId
  });

  return {
    data: data?.assignedProjectTitlesToHelper,
    loading
  };
}

export default useGetAssignedProjectTitlesToHelper;
