import { TransitionGroup } from 'react-transition-group';
import styled, { createGlobalStyle } from 'styled-components/macro';
import { ANIMATION_TRANS_TIME } from './InviteHelpers.constants';

export const TransitionGroupStyled = styled(TransitionGroup)`
  padding: 1rem 6px;
  grid-gap: 1rem;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  position: relative;
  left: -6px;
`;

export const GlobalStyle = createGlobalStyle`
.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity ${ANIMATION_TRANS_TIME}ms ease-in;
}

.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity ${ANIMATION_TRANS_TIME}ms ease-in;
}
`;
