import {
  LIST_PROJECTS,
  LIST_UNREVIEWD_PROJECTS
} from 'graphql/queries/project';
import { consoleError, isAdmin } from '../../../utils';
import { toast } from 'utils/toast';
import {
  CREATE_GOOGLE_DRIVE_PROJECT,
  CREATE_VIDEO_PROJECT,
  CREATE_MULTIPLE_IMAGES_PROJECT,
  CREATE_PROJECT,
  SAVE_DRAFT_PROJECT
} from 'graphql/mutations/createProjectWizard';
import useTableSortMethod from 'hooks/useTableSortMethod';
import useTablePage from '../../../hooks/useTablePage';
import { useCallback } from 'react';
import { ProjectType } from '../../../appTypes';
import {
  PROJECTS_LIST_ALL_PAGE,
  PROJECTS_LIST_ALL_SORT,
  PROJECTS_LIST_PENDING_PAGE,
  PROJECTS_LIST_PENDING_SORT,
  UPLOAD_TYPES
} from '../../../appConstants';
import { WizardFormValues } from '../AddProjectWizard.types';
import { useMutation } from '@apollo/client';

function useGetProjectCreateMutation() {
  const { sortMethod: projectAllSortMethod } = useTableSortMethod(
    PROJECTS_LIST_ALL_SORT
  );
  const { page: projectAllCurTablePage } = useTablePage(PROJECTS_LIST_ALL_PAGE);
  const { page: projectPendingCurTablePage } = useTablePage(
    PROJECTS_LIST_PENDING_PAGE
  );
  const { sortMethod: projectPendingSortMethod } = useTableSortMethod(
    PROJECTS_LIST_PENDING_SORT
  );

  const createProjectRefetchQueries = () => [
    {
      query: LIST_PROJECTS,
      variables: {
        page: projectAllCurTablePage,
        sortByDateOrder: projectAllSortMethod
      }
    },
    isAdmin()
      ? {
          query: LIST_UNREVIEWD_PROJECTS,
          variables: {
            page: projectPendingCurTablePage,
            sortByDateOrder: projectPendingSortMethod
          }
        }
      : ''
  ];
  const createProjectOnCompleted =
    (isDraft = false) =>
    () => {
      if (isDraft) {
        return toast.success('You successfully saved draft for your project');
      }

      return toast.success('You successfully created a project');
    };

  const createProjectOnError = (errorData: Error) => {
    if (errorData?.message) {
      toast.error(errorData.message);
      return;
    }

    consoleError('Project creating error');
    consoleError({ errorData });
    toast.error('Sorry, internal error. Please, try again later 😥');
  };

  const [
    createGoogleDriveProject,
    { loading: googleDriveProjectCreateLoading }
  ] = useMutation(CREATE_GOOGLE_DRIVE_PROJECT, {
    onCompleted: createProjectOnCompleted(),
    onError: createProjectOnError,
    refetchQueries: createProjectRefetchQueries
  });

  const [createVideoProject, { loading: videoProjectCreateLoading }] =
    useMutation(CREATE_VIDEO_PROJECT, {
      onCompleted: createProjectOnCompleted(),
      onError: createProjectOnError,
      refetchQueries: createProjectRefetchQueries
    });

  const [createMultiImageProject, { loading: multiImageProjectCreateLoading }] =
    useMutation(CREATE_MULTIPLE_IMAGES_PROJECT, {
      onCompleted: createProjectOnCompleted(),
      onError: createProjectOnError,
      refetchQueries: createProjectRefetchQueries
    });

  const [createProject, { loading: projectCreateLoading }] = useMutation(
    CREATE_PROJECT,
    {
      onCompleted: createProjectOnCompleted(),
      onError: createProjectOnError,
      refetchQueries: createProjectRefetchQueries
    }
  );

  const [saveDraftProject, { loading: saveDraftProjectLoading }] = useMutation(
    SAVE_DRAFT_PROJECT,
    {
      onCompleted: createProjectOnCompleted(true),
      onError: createProjectOnError,
      refetchQueries: createProjectRefetchQueries
    }
  );

  const loading =
    projectCreateLoading ||
    videoProjectCreateLoading ||
    multiImageProjectCreateLoading ||
    googleDriveProjectCreateLoading ||
    saveDraftProjectLoading;

  const getCreateProjectMutation = useCallback(
    (
      finalValues: WizardFormValues,
      annotationFilesUploadType: 'single' | 'multiple' | 'googleDrive',
      isSaveDraft = false
    ) => {
      if (isSaveDraft) {
        return {
          createProjectFinalFunc: saveDraftProject,
          resultKey: 'saveDraftProject'
        };
      }

      let resultKey = 'CreateProject';
      let createProjectFinalFunc: any = createProject;

      if (finalValues.type === ProjectType.VIDEO_ANNOTATION) {
        createProjectFinalFunc = createVideoProject;
        resultKey = 'CreateVideoAnnotationProject';
      }

      if (
        finalValues.type === ProjectType.IMAGE_ANNOTATION &&
        annotationFilesUploadType === 'multiple'
      ) {
        createProjectFinalFunc = createMultiImageProject;
        resultKey = 'createMultiImageProject';
      }

      if (annotationFilesUploadType === UPLOAD_TYPES.googleDrive) {
        createProjectFinalFunc = createGoogleDriveProject;
      }

      return {
        createProjectFinalFunc,
        resultKey
      };
    },
    [
      createGoogleDriveProject,
      createMultiImageProject,
      createProject,
      createVideoProject,
      saveDraftProject
    ]
  );

  return {
    loading,
    getCreateProjectMutation
  };
}

export default useGetProjectCreateMutation;
