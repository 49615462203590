import React from 'react';
import { Popover } from 'antd';
import { useParams } from 'react-router-dom';
import { Col, Text } from 'components/_main';
import { Z_INDEXES } from 'appConstants';
import { ArrowDownIcon } from 'assets/taskingTopToolbar';
import { ToolbarSection } from '../AnnotationToolbar.ui';
import { SectionFramesContent } from './SectionFrames.ui';
import { getTaskIndex } from './SectionFrames.constants';
import { useGetTaskListQuery } from 'generated/graphql';
import { TNewTask, TTaskDataMapped } from 'containers/TasksRouter/Tasks.types';
import { TaskingUrlParams } from 'containers/TasksRouter/AnnotationView/AnnotationView.types';
import useSelectTask from 'containers/TasksRouter/hooks/useSelectTask';

interface Props {
  taskId: number;
  setTaskData: (args: TTaskDataMapped | TNewTask) => void;
}

function SectionFrames({ taskId, setTaskData }: Props) {
  const [open, setOpen] = React.useState(false);
  const { projectId: projectIdParams }: TaskingUrlParams =
    useParams<Record<string, keyof TaskingUrlParams>>();
  const projectId = parseInt(projectIdParams ?? '', 10);

  const { data: taskListData, refetch } = useGetTaskListQuery({
    fetchPolicy: 'cache-and-network',
    variables: { projectId }
  });
  const [handleSelectTask] = useSelectTask({ refetch, setTaskData });

  const taskList = taskListData?.taskList;

  const handleOpenPopover = (open: boolean) => {
    setOpen(open);
    if (open) refetch();
  };

  const handleHidePopover = () => {
    handleOpenPopover(false);
  };

  const taskListTotalCount = taskList?.length ?? 0;
  const activeTaskIndex = getTaskIndex(taskList, taskId);

  return (
    <ToolbarSection active={open}>
      <Popover
        trigger="click"
        visible={open}
        placement="bottomLeft"
        overlayStyle={{ zIndex: Z_INDEXES.popover }}
        overlayInnerStyle={{ borderRadius: '10px' }}
        content={
          <SectionFramesContent
            taskList={taskList}
            total={taskListTotalCount}
            activeTaskId={taskId}
            handleSelectTask={handleSelectTask}
            handleHidePopover={handleHidePopover}
          />
        }
        onVisibleChange={handleOpenPopover}
      >
        <Col padding="12px 15px" alignItems="center">
          Frame:
          <Text variant="ui-small-bold">
            {activeTaskIndex}/{taskListTotalCount}
          </Text>
          <ArrowDownIcon />
        </Col>
      </Popover>
    </ToolbarSection>
  );
}

export default SectionFrames;
