import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Typography } from 'antd';
import { useField } from 'formik';
import { TUploadFiles } from './types';
import ControlledProgressBar from './ControlledProgressBar';
import { toast } from 'utils/toast';
import { ButtonOutline, ButtonIcon } from 'components/_main';
import styled from 'styled-components/macro';
import { DeleteOutlined } from '@ant-design/icons';
import Icon from 'components/Icon';

const UploadFiles: React.FC<TUploadFiles> = ({
  name,
  abortController,
  loading,
  disabled,
  handleUpload,
  accept = '.zip',
  className,
  mockLoadingFilename,
  onCancel
}) => {
  const inputRef = useRef<any>(null);
  const [field, meta, helper] = useField(name);
  const [filename, setFilename] = useState<string>(
    mockLoadingFilename ?? field.value
  );

  const finalLoading = loading && !abortController?.signal.aborted;

  useEffect(() => {
    setFilename(field.value);
  }, [field.value]);

  const handleClearValue = useCallback(() => {
    setFilename('');
    helper.setValue('');
    const input = inputRef?.current;
    if (input?.value) {
      input.value = '';
    }
  }, [helper]);

  const handleOnStopUpload = useCallback(() => {
    handleClearValue();
    onCancel && onCancel(filename);
  }, [filename, handleClearValue, onCancel]);

  const handleOnReset = useCallback(() => {
    handleClearValue();
    onCancel && onCancel(filename);
  }, [filename, handleClearValue, onCancel]);

  const onUploadFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        e.target &&
        e.target.validity.valid &&
        e.target.files &&
        e.target.files[0]
      ) {
        const fileObj = e.target?.files[0];
        handleUpload(fileObj)
          .then((fileNameFromBackend) => {
            setFilename(fileNameFromBackend);
            helper.setValue(fileNameFromBackend);
          })
          .catch((e) => {
            if ('message' in e) {
              toast.error(e.message);
            } else {
              toast.error('Error while file uploading');
              console.error('Error while file uploading. ', e);
            }
          });
      }
    },
    [handleUpload, helper]
  );

  const handleOnResetFileUpload = useCallback(() => {
    abortController?.abort();

    if (finalLoading) {
      handleOnStopUpload();
      return;
    }

    handleOnReset();
  }, [abortController, finalLoading, handleOnReset, handleOnStopUpload]);

  return (
    <div data-name={name} className={`${className} upload-button-container`}>
      {!finalLoading && !filename && (
        <ButtonOutline
          size="normal"
          square
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            inputRef?.current && inputRef.current.click();
          }}
        >
          Upload new
        </ButtonOutline>
      )}
      {!finalLoading && meta.touched && meta.error && (
        <Typography.Text style={{ color: 'red' }}>
          <span>{meta.error}</span>
        </Typography.Text>
      )}
      <input
        ref={inputRef}
        accept={accept}
        type="file"
        data-testid={'input-upload-file'}
        onChange={onUploadFiles}
        className="file-upload-button"
        name={name}
        style={{
          display: 'none',
          cursor: 'pointer',
          width: '100%',
          height: '100%'
        }}
      />
      <div
        style={{
          display: 'grid',
          gridAutoFlow: 'column',
          width: '100%',
          gridAutoColumns: '1fr max-content',
          maxHeight: '50px',
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ControlledProgressBar
          value={filename}
          handleOnStopUpload={handleOnResetFileUpload}
          loading={finalLoading}
        />
        {!finalLoading && filename && !disabled && (
          <Icon hoverStrokeColor="fg" onClick={handleOnResetFileUpload}>
            <DeleteOutlined />
          </Icon>
        )}
      </div>
    </div>
  );
};

export default styled(UploadFiles)`
  display: grid;
  grid-auto-flow: row;
  min-width: 300px;
  max-width: 300px;
  max-height: 50px;
  align-items: center;
`;
