import React, { ReactElement, useMemo } from 'react';
import { Form as AntForm, SelectProps } from 'antd';
import { CloseIcon } from 'assets';
import { Box, Row } from 'components/_main';
import AntdTag from 'components/AntdTag';
import { LABEL_MAX_CHARS } from 'appConstants';
import { useField } from 'formik';
import { MultipleSelect } from 'components/Select';
import Tags from './Tags';
import Form from 'antd/es/form';

const FormItem = AntForm.Item;

interface TagRenderProps {
  label: React.ReactNode;
  value: string;
  closable?: boolean;
  onClose(): void;
}

interface AnnotationLabelProps {
  disabled?: boolean;
  maxHeight?: string;
  validationRegex?: RegExp;
}

function AnnotationLabel({
  disabled,
  maxHeight,
  validationRegex = new RegExp(`^[A-Za-z0-9 ]*$`, 'g')
}: AnnotationLabelProps) {
  const fieldProps = useField('labels');
  const [field, meta, { setValue, setError }] = fieldProps ?? [];

  const tagOptions: SelectProps['options'] = useMemo(
    () =>
      field.value?.map((value: string) => ({
        label: value,
        value
      })) || [],
    [field.value]
  );

  const handleValidation = (curValue: string) => {
    const value = curValue.trim();
    if (!value.match(validationRegex)) {
      setError('Must contain only latin letters, numbers & space');
      return false;
    }
    if (value.length > LABEL_MAX_CHARS) {
      setError(`Can't contain more than ${LABEL_MAX_CHARS} char-s`);
      return false;
    }
    setError(undefined);
    return true;
  };

  const handleSelect = (value: string) => {
    if (handleValidation(value) && !field.value.includes(value)) {
      setValue([...field.value, value]);
    }
  };

  const handleDeselect = (value: string) => {
    setValue(field.value.filter((item: string) => item !== value));
  };

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const tagRender = ({
    label,
    value,
    closable,
    onClose
  }: TagRenderProps): ReactElement => {
    if (!value.match(validationRegex) || value.length > LABEL_MAX_CHARS) {
      return <></>;
    }

    return (
      <AntdTag
        key={value}
        closable={closable}
        closeIcon={<CloseIcon />}
        onMouseDown={onPreventMouseDown}
        onClose={onClose}
      >
        {label}
      </AntdTag>
    );
  };

  return (
    <Form
      initialValues={{
        [field.name]: field.value
      }}
    >
      <Row gridGap={0}>
        {disabled ? (
          <Box position="relative" left="-4px">
            {tagOptions?.map((item) => tagRender(item as TagRenderProps))}
          </Box>
        ) : (
          <FormItem
            name={field.name}
            help={meta.error}
            validateStatus={meta.error && 'error'}
          >
            <Tags
              open={false}
              disabled={disabled}
              placeholder="Please, add labels"
              options={tagOptions}
              tagRender={tagRender}
              onSelect={handleSelect}
              onDeselect={handleDeselect}
              maxTagTextLength={20}
            />
          </FormItem>
        )}
      </Row>
    </Form>
  );
}

export default AnnotationLabel;
