import { WORK_STATISTICS_PAGE } from 'appConstants';
import { Divider } from 'components';
import RouteContainer from 'components/RouteContainer';
import Table from 'components/Table';
import { Text, Col, Row } from 'components/_main';
import {
  SortByDateOrder,
  useGetAllTimeStatsQuery,
  useListProjectsQuery
} from 'generated/graphql';
import useGetListProjects from 'hooks/useGetListProjects';
import useTablePage from 'hooks/useTablePage';
import React from 'react';
import { parseRate, renderRate } from 'utils';
import columns from './columns';

interface StatItemProps {
  color: string;
  counter?: number;
  dayCounter?: number;
  title: string;
  dayCounterTitle: string;
  money?: boolean;
}

function StatItem({
  color,
  counter = 0,
  dayCounter = 0,
  dayCounterTitle,
  title,
  money
}: StatItemProps) {
  return (
    <Row>
      <Text upper fontSize="12px" lineHeight="16px" fontWeight="700">
        {title}
      </Text>
      <Col alignItems="flex-end" gap="0">
        <Text color={color} fontSize="60px" lineHeight="73px" fontWeight="300">
          {money
            ? renderRate(counter, { beforeDecimal: true, defaultReturn: '$00' })
            : counter}
        </Text>
        {money && (
          <Text color={color} fontSize="30px" fontWeight="300">
            {renderRate(counter, { afterDecimal: true, defaultReturn: '.00' })}
          </Text>
        )}
      </Col>
      {dayCounter > 0 ? (
        <Col gap="6px">
          <Text fontSize="12px" color={color} fontWeight="500">
            +{money ? renderRate(dayCounter) : dayCounter}
          </Text>
          <Text fontSize="12px" fontWeight="300">
            {dayCounterTitle}
          </Text>
        </Col>
      ) : null}
    </Row>
  );
}

export default function WorkStatistics() {
  const { page, setPage } = useTablePage(WORK_STATISTICS_PAGE);
  const { data: statsDate } = useGetAllTimeStatsQuery({
    fetchPolicy: 'network-only'
  });
  const { data: projectsListDate, loading } = useListProjectsQuery({
    fetchPolicy: 'network-only',
    variables: {
      page: page,
      sortByDateOrder: SortByDateOrder.Desc
    }
  });

  const { projectDone, taskCompleted, taskReturned, totalEarned, dailyStat } =
    statsDate?.getHelperStatistic ?? {};

  return (
    <RouteContainer title="Work statistics" row gap="30px">
      <Col
        height="156px"
        px="40px"
        alignContent="center"
        boxShadow="0px 4px 10px rgba(180, 180, 180, 0.2);"
        borderRadius="10px"
        width="max-content"
        gap="80px"
      >
        <StatItem
          title="Projects done"
          counter={projectDone}
          dayCounterTitle="today"
          dayCounter={dailyStat?.projectDone}
          color="orange"
        />
        <Divider type="vertical" />
        <StatItem
          title="Tasks completed"
          counter={taskCompleted}
          dayCounterTitle="completed today"
          dayCounter={dailyStat?.taskCompleted}
          color="primary"
        />
        <Divider type="vertical" />
        <StatItem
          title="Tasks returned"
          counter={taskReturned}
          dayCounterTitle="returned today"
          dayCounter={dailyStat?.taskReturned}
          color="red"
        />
        <Divider type="vertical" />
        <StatItem
          money
          title="Total earned"
          counter={totalEarned}
          dayCounterTitle="earned today"
          dayCounter={dailyStat?.totalEarned}
          color="purple"
        />
      </Col>
      <Table
        setCurTablePage={setPage}
        page={page}
        loading={loading}
        dataSource={projectsListDate?.listProjects?.projects ?? []}
        columns={columns}
        pagesCount={projectsListDate?.listProjects?.pagesCount}
      />
    </RouteContainer>
  );
}
