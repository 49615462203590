import React from 'react';
import { upper } from 'utils/_stringUtilsLegacy';
import { ClientsName } from './ui';
import { TData } from './types';
import { RowLegacy } from 'components/_main';
import pricingPlanStatusColumn from 'components/Table/_columnRender/pricingPlanStatusColumn';
import { activeStatusColumn } from 'components/Table/_columnRender';
import { EditIcon, ArchiveIcon } from 'assets/tableRowActions';
import Icon from 'components/Icon';
import { formatDate } from 'utils';

const columns = ({
  onDelete,
  onRowClick
}: {
  onDelete: (data: TData) => void;
  onRowClick: (data: TData) => void;
}) => [
  {
    title: upper('Client name'),
    width: 50,
    render: (data: TData) => {
      return (
        <ClientsName>
          <span>
            {data.firstName} {data.lastName}
          </span>
        </ClientsName>
      );
    }
  },
  {
    title: upper('Company name'),
    width: 50,
    render: (data: TData) => {
      return (
        <ClientsName>
          <span>{data.companyName}</span>
        </ClientsName>
      );
    }
  },
  {
    title: upper('created'),
    width: 150,
    render: (data: TData) => <>{formatDate(data?.createdAt, 'MM/DD/YYYY')}</>
  },
  {
    key: 'pricing plan',
    title: upper('Pricing plan'),
    width: 100,
    render: pricingPlanStatusColumn
  },
  {
    key: 'status',
    title: upper('Status'),
    width: 100,
    render: activeStatusColumn
  },
  {
    title: upper('Actions'),
    width: 100,
    render: (data: TData) => {
      return (
        <RowLegacy gridGap={'1rem'}>
          <Icon
            hoverStrokeColor="fg"
            data-component="button-delete"
            onClick={() => {
              onDelete(data);
            }}
          >
            <ArchiveIcon />
          </Icon>
          <Icon
            hoverStrokeColor="fg"
            data-component="button-edit"
            onClick={() => {
              onRowClick(data);
            }}
          >
            <EditIcon />
          </Icon>
        </RowLegacy>
      );
    }
  }
];

export default columns;
