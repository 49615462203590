import env from './envVars';

type LogArgs = Array<any>;

interface Options {
  debounced?: boolean;
}

function ConsoleLog(options?: Options) {
  let timeoutIds = new Map();

  return (...log: LogArgs) => {
    if (!env.isDebug || !env.isSb) {
      return;
    }

    const id = log;

    const fn = () => console.log(...log);

    if (id && options?.debounced) {
      if (timeoutIds.get(id)) {
        clearTimeout(timeoutIds.get(id));
        timeoutIds.delete(id);
      }

      timeoutIds.set(
        id,
        setTimeout(() => {
          fn();
          clearTimeout(timeoutIds.get(id));
          timeoutIds.delete(id);
        }, 500)
      );
      return;
    }

    fn();
  };
}

// @ts-ignore
export const consoleLog = new ConsoleLog();
// @ts-ignore
export const consoleLogDebounced = new ConsoleLog({ debounced: true });

// @ts-ignore
// eslint-disable-next-line
export default new ConsoleLog();
