import React from 'react';
import { Helper } from 'generated/graphql';
import { CheckCellRender, HelperNameCell } from './AssignedHelpres.ui';
import { upper } from 'utils';

type columnData = Helper;

export const columns = ({
  selectedAuditorIds,
  selectedHelperIds,
  onSelAuditor,
  onSelHelper
}: {
  selectedAuditorIds: number[];
  selectedHelperIds: number[];
  onSelAuditor: (checked: boolean, id: number) => void;
  onSelHelper: (checked: boolean, id: number) => void;
}) => [
  {
    key: 'name',
    title: upper('Name'),
    width: 300,
    render: HelperNameCell
  },
  {
    key: 'checkHelper',
    width: 150,
    render: (data: columnData) => (
      <CheckCellRender
        key={'helper'}
        type={'helper'}
        id={parseInt(data.id)}
        listIds={[...selectedHelperIds]}
        onSelect={onSelHelper}
      />
    )
  },
  {
    key: 'checkAuditor',
    width: 150,
    render: (data: columnData) => (
      <CheckCellRender
        key={'auditor'}
        type={'auditor'}
        id={parseInt(data.id)}
        listIds={[...selectedAuditorIds]}
        onSelect={onSelAuditor}
      />
    )
  }
];

export default columns;
