import {
  AnnotationUnits,
  AnnotationUnitProps,
  TimelineTransition,
  ObjWithCoords,
  TimelineFrame
} from './AnnotationSystem.types';
import {
  EMPTY_ARRAY,
  SINGLE_SAVE_STORAGE_KEY,
  UNIT_COLORS
} from './AnnotationSystem.constants';
import { PolygonPoint } from './reducer';
import { roundTimestampsToMs } from 'containers/AnnotationSystem/reducer/utils';

export const convertPercentageToPixels = ({
  objects,
  zoomLevel
}: {
  objects: ObjWithCoords[];
  zoomLevel: number;
}): AnnotationUnitProps[] => {
  return objects.map((obj) => {
    const newX = parseInt(obj.x, 10) * zoomLevel;
    const newY = parseInt(obj.y, 10) * zoomLevel;
    return {
      ...obj,
      x: newX,
      y: newY
    };
  }) as AnnotationUnitProps[];
};

export const convertPixelsToPercentage = ({
  objects,
  width,
  height
}: {
  objects: ObjWithCoords[];
  width: number;
  height: number;
}): AnnotationUnitProps[] => {
  return objects.map((obj) => {
    const newX = (parseInt(obj.x, 10) * 100) / width;
    const newY = (parseInt(obj.y, 10) * 100) / height;
    return {
      ...obj,
      x: newX,
      y: newY
    };
  }) as AnnotationUnitProps[];
};

export const convertSinglePixelsToPercentage = ({
  obj,
  width,
  height
}: {
  obj: AnnotationUnitProps;
  width: number;
  height: number;
}): AnnotationUnitProps => {
  const newX = (obj.x * 100) / width;
  const newY = (obj.y * 100) / height;
  return {
    ...obj,
    x: newX,
    y: newY
  };
};

export const findLastGroupIndex = (units: AnnotationUnits) => {
  let idPrefix = 1;

  if (units.length === 0) {
    return 1;
  }

  for (const unit of units) {
    if (!unit) {
      return 1;
    }

    const curIdPrefix = unit?.groupIndex;
    if (curIdPrefix && idPrefix < curIdPrefix) {
      idPrefix = curIdPrefix;
    }
  }

  return idPrefix + 1;
};

interface ConvertPercentageToPixelsRelativeToOuterRectProps {
  objects: AnnotationUnitProps[];
  width: number;
  height: number;
  curZoomLevel: number;
  initX: number;
  initY: number;
  groupIndex: number;
  label: string;
}

export const convertPercentageToPixelsRelativeToOuterRect = ({
  objects,
  height,
  width,
  curZoomLevel,
  initX,
  initY,
  groupIndex,
  label
}: ConvertPercentageToPixelsRelativeToOuterRectProps): AnnotationUnitProps[] => {
  return objects.map((obj: AnnotationUnitProps): AnnotationUnitProps => {
    const newX = ((obj.x / 10) * width) / 100 / curZoomLevel;
    const newY = ((obj.y / 10) * height) / 100 / curZoomLevel;
    return {
      ...obj,
      unitId: `${groupIndex}-${label}-${obj?.unitId}`,
      label: `${label}`,
      groupIndex: groupIndex,
      x: newX + initX / curZoomLevel,
      y: newY + initY / curZoomLevel
    };
  }) as AnnotationUnitProps[];
};

interface ChangePosDotsAccordingToOuterRectSizeChange {
  dots: AnnotationUnitProps[];
  w?: number;
  h?: number;
  x?: number;
  y?: number;
  dX: number;
  dY: number;
  newW?: number;
  newH?: number;
  newX?: number;
  newY?: number;
  zoomLevel?: number;
}

export const changePosDotsAccordingToOuterRectResize = ({
  dots,
  dX,
  dY
}: ChangePosDotsAccordingToOuterRectSizeChange) => {
  return dots.map((dot) => {
    if (!dX || !dY) {
      return dot;
    }

    return {
      ...dot,
      x: dot.x + dX,
      y: dot.y + dY
    };
  }) as AnnotationUnitProps[];
};

export const changePosDotsAccordingToOuterRectMove = ({
  dots,
  dX,
  dY
}: ChangePosDotsAccordingToOuterRectSizeChange) => {
  return dots.map((dot) => {
    if (dX === undefined || dY === undefined) {
      return dot;
    }

    return {
      ...dot,
      x: dot.x + dX,
      y: dot.y + dY
    };
  }) as AnnotationUnitProps[];
};

export const saveWithZoom = (unit: AnnotationUnitProps, zoomLevel: number) => {
  let newValue = { ...unit };

  newValue.x = unit.x / zoomLevel ?? 0;
  newValue.y = unit.y / zoomLevel ?? 0;
  newValue.w = unit.w / zoomLevel ?? 0;
  newValue.h = unit.h / zoomLevel ?? 0;

  return newValue;
};

export const showWithZoom = (unit: AnnotationUnitProps, zoomLevel: number) => {
  let newValue = { ...unit };

  newValue.x = unit.x * zoomLevel ?? 0;
  newValue.y = unit.y * zoomLevel ?? 0;
  newValue.w = unit.w * zoomLevel ?? 0;
  newValue.h = unit.h * zoomLevel ?? 0;

  return newValue;
};

export const showUnitsWithZoomLevel = (
  units: AnnotationUnits,
  curZoomLevel: number
) => {
  if (!units) return [];
  if (!curZoomLevel) return [];

  let finalArray: AnnotationUnits = [];

  if (Array.isArray(units)) {
    for (const annotationObj of units) {
      const timelineTransitions = annotationObj.timelineTransitions?.map(
        (unit) =>
          showWithZoom(unit as unknown as AnnotationUnitProps, curZoomLevel)
      ) as unknown as TimelineTransition[];

      finalArray = [
        ...finalArray,
        showWithZoom(
          {
            ...annotationObj,
            timelineTransitions
          } as unknown as AnnotationUnitProps,
          curZoomLevel
        )
      ];
    }

    return finalArray;
  }

  for (const [, value] of Object.entries<AnnotationUnitProps>(units)) {
    const timelineTransitions = value.timelineTransitions?.map((unit) =>
      showWithZoom(unit as unknown as AnnotationUnitProps, curZoomLevel)
    ) as unknown as TimelineTransition[];

    finalArray = [
      ...finalArray,
      showWithZoom(
        { ...value, timelineTransitions } as AnnotationUnitProps,
        curZoomLevel
      )
    ];
  }

  return finalArray;
};

export const showUnitsWithPercentage = (
  units: AnnotationUnits,
  width: number,
  height: number
) => {
  if (!units) return [];

  let finalArray: AnnotationUnits = [];

  if (Array.isArray(units)) {
    for (const annotationObj of units) {
      finalArray = [
        ...finalArray,
        convertSinglePixelsToPercentage({ obj: annotationObj, width, height })
      ];
    }

    return finalArray;
  }

  for (const [, value] of Object.entries(units)) {
    finalArray = [
      ...finalArray,
      convertSinglePixelsToPercentage({
        obj: value as AnnotationUnitProps,
        width,
        height
      })
    ];
  }

  return finalArray;
};

export const saveUnits = (
  annotationObjs: AnnotationUnits,
  curZoomLevel: number
) => {
  if (!annotationObjs) return EMPTY_ARRAY;

  let finalArray: AnnotationUnits = EMPTY_ARRAY;

  if (Array.isArray(annotationObjs)) {
    for (const annotationObj of annotationObjs) {
      let newValue = { ...annotationObj };

      finalArray = [...finalArray, annotationObj];
    }

    return finalArray;
  }

  for (const [, value] of Object.entries(annotationObjs)) {
    // @ts-ignore
    let newValue = { ...value };

    finalArray = [...finalArray, newValue];
  }

  return finalArray;
};

export default function getCoordsFromUnit(props: AnnotationUnitProps) {
  return {
    x: props.x,
    y: props.y,
    w: props.w,
    h: props.h
  };
}

export type Media = HTMLVideoElement | HTMLImageElement;

export function getSizeInsideWrapper(
  media: Media,
  wrapperW: number,
  wrapperH: number,
  adjustToHeight?: boolean
) {
  let w: number;
  let h: number;
  let finalW: number;
  let finalH: number;

  if ('naturalWidth' in media) {
    w = media.naturalWidth;
    h = media.naturalHeight;
  } else {
    w = media.videoWidth;
    h = media.videoHeight;
  }

  let prop = h > 0 ? w / h : 1;

  prop = prop ?? 1;

  finalH = wrapperH;
  finalW = finalH / prop;

  if (finalW > wrapperW) {
    finalW = wrapperW;
    finalH = finalW * prop;
  }

  if (adjustToHeight || w > h) {
    finalW = wrapperW;
    finalH = finalW / prop;

    if (finalH > wrapperH) {
      finalH = wrapperH;
      finalW = finalH * prop;
    }
  }

  return {
    h: finalH,
    w: finalW,
    initW: w,
    initH: h
  };
}

export function preventResizeInverse({
  initX,
  initY,
  dX,
  dY
}: {
  initX: number;
  initY: number;
  dX: number;
  dY: number;
}) {
  let finalInitX = initX;
  let finalInitY = initY;
  let finalDX = dX;
  let finalDY = dY;
  if (dX < 0) {
    finalInitX = initX + dX;
    finalDX = -dX;
  }
  if (dY < 0) {
    finalInitY = initY + dY;
    finalDY = -dY;
  }

  return {
    y: finalInitY,
    x: finalInitX,
    w: finalDX,
    h: finalDY
  };
}

export function getSavedFromStorage() {
  const results = JSON.parse(
    window.sessionStorage.getItem(SINGLE_SAVE_STORAGE_KEY) ?? ''
  );

  return results;
}

export function saveToStorage(results: any) {
  window.sessionStorage.setItem(
    SINGLE_SAVE_STORAGE_KEY,
    JSON.stringify(results)
  );
}

export function getUnitColor(index: number) {
  const colorsLength = UNIT_COLORS.length;
  const colorIndex = index % colorsLength;

  return UNIT_COLORS[colorIndex];
}

export function getAnnotationUnitsWrapperSize(units: AnnotationUnits) {
  let extremes = {
    top: -1,
    left: -1,
    right: 0,
    bottom: 0
  };

  for (const unit of units) {
    if (extremes.left === -1) {
      extremes.left = unit.x;
    }

    if (extremes.top === -1) {
      extremes.top = unit.y;
    }

    if (unit.x > extremes.right) {
      extremes.right = unit.x;
    }

    if (unit.y > extremes.bottom) {
      extremes.bottom = unit.y;
    }

    if (unit.y < extremes.top) {
      extremes.top = unit.y;
    }

    if (unit.x < extremes.left) {
      extremes.left = unit.x;
    }
  }

  return {
    x: extremes.left,
    y: extremes.top,
    w: extremes.right - extremes.left,
    h: extremes.bottom - extremes.top
  };
}

export const getParentNodeWithNeededSelectors = (
  targetNode: HTMLElement,
  selectors: string
): HTMLElement | null => {
  if (targetNode.matches(selectors)) return targetNode;

  if (
    targetNode.parentElement === null ||
    targetNode.parentElement.tagName === 'HTML'
  )
    return null;

  return getParentNodeWithNeededSelectors(targetNode.parentElement, selectors);
};

export const getNavigateOnMouseMove = (
  event: MouseEvent | React.MouseEvent,
  unitId?: string
) => {
  if (event.buttons !== 1) return;

  const viewportEl = document.getElementById('AnnotationViewPort');

  if (!viewportEl || unitId) return;

  viewportEl.scrollTo({
    top: viewportEl.scrollTop - event.movementY,
    left: viewportEl.scrollLeft - event.movementX
  });
};

export const isValidPolygon = (polygonPoints?: PolygonPoint[]) => {
  return polygonPoints?.length! > 2;
};

export const setTimelineFrames = (vidDuration: number, curFps: number) => {
  return Array.from({ length: Math.floor(vidDuration * curFps) + 1 }).map(
    (_, index) => {
      return {
        isTouched: false,
        timestamp: roundTimestampsToMs(
          (vidDuration / (vidDuration * curFps)) * index
        )
      };
    }
  );
};

export const getNearestFrameToTime = (
  timelineFrames: TimelineFrame[],
  time: number
) => {
  let largestIndex = 0;
  for (let i = 0; i < timelineFrames.length; i++) {
    if (timelineFrames[i].timestamp > time) {
      largestIndex = i;
      break;
    }
  }
  if (largestIndex < 1) return timelineFrames.length - 1;

  if (largestIndex === 0) return 0;

  return largestIndex - 1;
};

export const getApproximateFrameToTime = (
  time: number,
  duration: number,
  frames: number
) => {
  return Math.floor(time / (duration / frames));
};
