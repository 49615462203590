import React, { MutableRefObject, useCallback } from 'react';

interface UploadFilesUploaderProps {
  accept?: string;
  loading?: boolean;
  element: React.ReactElement;
  onFilesUpdate?: (files: File[]) => void;
  multiple?: boolean;
  inputRef: MutableRefObject<HTMLInputElement | null>;
}

const UploadFilesUploader = ({
  loading,
  accept = '.zip',
  element,
  onFilesUpdate,
  multiple,
  inputRef
}: UploadFilesUploaderProps) => {
  const handleUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target && e.target.validity.valid && e.target.files) {
        const files = e.target?.files;
        const filesAsFiles = [...Array.from(files)];

        onFilesUpdate && onFilesUpdate([...filesAsFiles]);
        e.target.value = '';
      }
    },
    [onFilesUpdate]
  );

  const handleClick: React.MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      inputRef?.current && inputRef.current.click();
    },
    [inputRef]
  );

  return (
    <>
      {React.cloneElement(element, {
        ...element.props,
        onClick: handleClick,
        loading
      })}
      <input
        ref={inputRef}
        accept={accept}
        multiple={multiple}
        type="file"
        onChange={handleUpload}
        style={{
          display: 'none'
        }}
      />
    </>
  );
};

export default UploadFilesUploader;
