import {
  AnnotationSystemModesEnum,
  AnnotationSystemEventsEnum,
  AnnotationSystemProps
} from '../AnnotationSystem.types';
import { EMPTY_ARRAY } from '../AnnotationSystem.constants';

export const REDUCER_INIT: AnnotationSystemProps = {
  setTaskData: () => null,
  videoFPS: 1,
  videoFrameRate: 1,
  copyUnitId: null,
  mediaUrl: null,
  taskData: null,
  taskId: null,
  results: [],
  annotationType: null,
  resizePosition: null,
  svgLayerOpacityLevel: 0,
  onResultsChange: null,
  videoStart: null,
  videoEnd: null,
  mediaImageRef: null,
  mediaVideoRef: null,
  svgLayerRef: null,
  curVideoStatus: null,
  cursorInitX: 0,
  cursorInitY: 0,
  mouseDX: 0,
  mouseDY: 0,
  mouseInitX: 0,
  mouseInitY: 0,
  initH: 0,
  initW: 0,
  labels: EMPTY_ARRAY,
  h: 0,
  w: 0,
  y: 0,
  x: 0,
  type: 'image',
  isInitMediaLoading: true,
  aspectRatio: 1,
  curMode: AnnotationSystemModesEnum.UNIT_SELECT,
  curEvent: AnnotationSystemEventsEnum.CREATE_MODE,
  curHoverUnitId: null,
  curSelUnitId: null,
  curSelPointId: null,
  curZoomLevel: 1,
  fitWrapperZoomLevel: 1,
  zoomStep: 0.1,
  annotationUnits: EMPTY_ARRAY,
  hideAnnotationUnitIds: EMPTY_ARRAY,
  isViewOnly: false,
  annotationSpecificationType: null,
  pageFrames: 0,
  annotationAccuracy: -1,
  annPracticeProgress: 0
};
