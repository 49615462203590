import React from 'react';
import { useField, useFormikContext } from 'formik';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import useOpen from 'hooks/useOpen';
import { isAdmin, isClient, upper } from 'utils';
import { varEnv } from 'utils/envVars';
import SelectClientSingle from 'components/SelectClientSingle';
import ProjectTitle from 'components/ProjectTitle';
import { Box, Col, Link, Row, Text } from 'components/_main';
import { ADD_WIZARD_FIELDS, PROJECT_TYPES } from 'appConstants';
import { ProjectSpecialization, ProjectType } from 'appTypes';
import { WizardFormValues } from '../AddProjectWizard.types';
import { useAddProjectWizardContext } from '../AddProjectWizard.context';
import { Question } from '@phosphor-icons/react';
import ProjectSpecializationTypeSelect from './components/ProjectSpecializationTypeSelect';
import ProjectSpecializationHelpModal from './components/ProjectSpecializationHelpModal';
import ProjectTypeHelpModal from './components/ProjectTypeHelpModal';
import ProjectPlatformSelect from './components/ProjectPlatformSelect';
import ProjectTypeCard from 'components/ProjectTypeCard';
import { InputLabelField } from 'components/_form';
import { useTheme } from 'styled-components/macro';
import Icon from 'components/Icon';

interface ProjectTypeSelectProps {
  typeName?: string;
}

interface AddProjectTitleProps {
  name: string;
}

export interface StepStartProps {
  isClient?: boolean;
}

function ProjectTypeSelect({
  typeName = ADD_WIZARD_FIELDS.type
}: ProjectTypeSelectProps) {
  const { onSetProjectType } = useAddProjectWizardContext();
  const [{ value: currentTypeValue }, , helper] = useField(typeName);

  const handleSelect = (id: ProjectType) => () => {
    onSetProjectType(id);
    helper.setValue(id);
  };

  return (
    <Col overflowVisible>
      {PROJECT_TYPES.map(
        ({ iconComponent, label, value, disabled, iconType }) => (
          <ProjectTypeCard
            key={label}
            icon={iconComponent}
            title={label}
            isActive={value === currentTypeValue}
            onClick={handleSelect(value as ProjectType)}
            disabled={disabled}
            iconType={iconType}
          />
        )
      )}
    </Col>
  );
}

function AddProjectTitle({ name }: AddProjectTitleProps) {
  const {
    values: { clientId: contextClientId }
  } = useFormikContext<WizardFormValues>();
  const { id } = useGetUserSessionData();

  if (isClient() && !id) {
    throw new Error('User ID is required');
  }

  const disabled = !isClient() && !contextClientId;
  const clientId = isClient()
    ? parseInt(id, 10)
    : typeof contextClientId === 'string'
    ? parseInt(contextClientId, 10)
    : contextClientId;

  return <ProjectTitle name={name} readOnly={disabled} clientId={clientId} />;
}

function StepStart(props: StepStartProps) {
  const { values } = useFormikContext<WizardFormValues>();
  const { lastName, firstName, id } = useGetUserSessionData();
  const theme = useTheme();

  const {
    isOpen: isProjectSpecializationHelpOpen,
    onOpen: onProjectSpecializationHelpOpen,
    onClose: onProjectSpecializationHelpClose
  } = useOpen();

  const {
    isOpen: isProjectTypeHelpOpen,
    onOpen: onProjectTypeHelpOpen,
    onClose: onProjectTypeHelpClose
  } = useOpen();

  const isClientSelected = (isAdmin() && values.clientId) || isClient();
  const isRealAdmin = props?.isClient === undefined && isAdmin();

  return (
    <>
      <Row gridGap="10px">
        {isRealAdmin && (
          <Row>
            <Text variant="ui-1-bold">{upper('Client')}</Text>
            <SelectClientSingle name="clientId" />
          </Row>
        )}
        {isClientSelected && (
          <Row gridGap="30px">
            <Row width="60%">
              <Text variant="ui-small-2-bold">{upper('Project name')}</Text>
              <AddProjectTitle name="title" />
            </Row>
            <Row overflowVisible>
              <Col flexCenter gridGap="10px">
                <Text variant="ui-small-2-bold">
                  {upper('Project specialization')}
                </Text>
                <Icon
                  hoverFillColor="fg"
                  onClick={onProjectSpecializationHelpOpen}
                >
                  <Question color={theme.colors.primary} size={24} />
                </Icon>
              </Col>
              <Box overflowVisible>
                {/**
                 * PROJECT TYPE SELECT
                 * ===================
                 */}
                <ProjectTypeSelect />
              </Box>
              <Row display="inline">
                <Text colorVariant="light">
                  Can’t find matching specialization?{' '}
                </Text>
                <Link
                  href={`mailto:${
                    varEnv.emailHelpAddresses
                  }?subject="Request custom project${
                    firstName &&
                    lastName &&
                    id &&
                    ` (${firstName} ${lastName} / ${id})`
                  }"`}
                >
                  Request custom project
                </Link>
              </Row>
            </Row>
            <ProjectSpecializationTypeSelect
              projectType={values?.type}
              onProjectTypeHelpOpen={onProjectTypeHelpOpen}
            />
            {values?.type !== ProjectType.NLP && (
              <Row>
                <Text variant="ui-small-2-bold" upper>
                  Annotation Platform
                </Text>
                <ProjectPlatformSelect />
              </Row>
            )}
            {values?.type === ProjectType.DATA_SET_COLLECTION && (
              <Row>
                <Text variant="ui-1-bold">{upper('Message')}</Text>
                <InputLabelField
                  name={ADD_WIZARD_FIELDS.clientInstructionTextarea}
                  placeholder="Write the quick summary of what needs to be done"
                  inputProps={{
                    textarea: true,
                    rows: 4
                  }}
                />
              </Row>
            )}
          </Row>
        )}
      </Row>
      <ProjectSpecializationHelpModal
        isOpen={isProjectSpecializationHelpOpen}
        onClose={onProjectSpecializationHelpClose}
      />
      <ProjectTypeHelpModal
        isOpen={isProjectTypeHelpOpen}
        onClose={onProjectTypeHelpClose}
      />
    </>
  );
}

export default StepStart;
