import React from 'react';
import { Row, Text } from 'components/_main';
import ExpandBlock from '../ExpandBlock';
import { AnnotationSidebarLabelsProps } from 'containers/TasksRouter/AnnotationView/AnnotationView.types';

function AnnotationLabelsBlock({
  title,
  isExpandable = true,
  children
}: React.PropsWithChildren<AnnotationSidebarLabelsProps>) {
  return (
    <ExpandBlock
      title={
        <Text variant="ui-small-bold" upper>
          {title}
        </Text>
      }
      expandable={isExpandable}
    >
      {children}
    </ExpandBlock>
  );
}

export default AnnotationLabelsBlock;
