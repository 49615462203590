import { useEffect, useMemo } from 'react';
import { MappedListProjects } from 'appTypes';
import useTablePage from 'hooks/useTablePage';
import {
  PROJECTS_LIST_PENDING_PAGE,
  PROJECTS_LIST_PENDING_SORT
} from 'appConstants';
import useTableSortMethod from 'hooks/useTableSortMethod';
import { useListUnreviewdProjectsQuery } from 'generated/graphql';
import { formatListProjects, isAdmin } from 'utils';
import { NetworkStatus } from '@apollo/client';

const useGetListPendingProject = () => {
  const { page, setPage } = useTablePage(PROJECTS_LIST_PENDING_PAGE);
  const { sortMethod, onSortMethodReset } = useTableSortMethod(
    PROJECTS_LIST_PENDING_SORT
  );

  const {
    loading,
    data: listProjectsData,
    networkStatus
  } = useListUnreviewdProjectsQuery({
    fetchPolicy: 'network-only',
    skip: !isAdmin(),
    notifyOnNetworkStatusChange: true,
    variables: {
      page,
      sortByDateOrder: sortMethod
    }
  });

  const listProjects: (MappedListProjects | null)[] | [] = useMemo(() => {
    if (!listProjectsData?.listUnreviewedProjects?.projects) return [];
    return formatListProjects([
      ...listProjectsData?.listUnreviewedProjects?.projects
    ]);
  }, [listProjectsData]);

  const pendingPagesCount =
    listProjectsData?.listUnreviewedProjects?.pagesCount;
  const pendingProjectsLoading =
    loading ||
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.poll;

  useEffect(() => {
    return () => {
      setPage(0);
      onSortMethodReset();
    };
  }, [onSortMethodReset, setPage]);

  return {
    listProjects,
    page,
    pendingPagesCount,
    setPage,
    pendingProjectsLoading
  };
};

export default useGetListPendingProject;
