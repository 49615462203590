import { MappedListProjects, ProjectTypeLabel } from 'appTypes';
import { formatDate } from 'utils/_dateUtilsLegacy';
import { Project } from 'generated/graphql';
import parseSubType from './parseSubType';

export const formatListProjects: (
  projects: (Project | null)[]
) => MappedListProjects[] | [] = (projects) => {
  const nonNullProject: Project[] = projects.filter(
    (project) => !!project
  ) as Project[];

  return (
    nonNullProject.map((project, index: number) => ({
      ...project,
      mappedSpecType: parseSubType(project?.projectSettings?.annotationType),
      mappedType:
        ProjectTypeLabel[project.type as keyof typeof ProjectTypeLabel],
      key: `${index}`,
      formattedCreatedAt: formatDate(project.createdAt, 'MM/DD/YYYY'),
      formattedUpdatedAt: formatDate(project.updatedAt),
      formattedClient: `${
        project.client?.companyName ? project.client?.companyName : ''
      }`,
      formattedClientName: `${
        project.client?.firstName ? project.client?.firstName : ''
      } ${project.client?.lastName ? project.client?.lastName : ''}`
    })) || []
  );
};
