import React from 'react';
import { toast } from 'utils/toast';
import {
  ButtonPrimary,
  ButtonOutline,
  Col,
  ButtonGhost
} from 'components/_main';
import { useFormikContext } from 'formik';
import { useCtx, useCtxSelectIsChanged } from '../ProjectDetailsCard.Context';
import { ConfirmButtons } from 'components/_modals/ui';
import {
  getErrorFieldsArrayFromContext,
  showFillRequiredFieldsToastForFromDraftToReview
} from '../utils';
import PublishButton from './PublishButton';
import { ProjectStatus } from 'generated/graphql';
import { isClient } from 'utils';
import {
  ProjectDetailsConfirmButtonsProps,
  ProjectForm
} from 'containers/ProjectDetailsCard/types';
import { useTheme } from 'styled-components/macro';

function ConfirmButtonsWrapper({
  loading,
  isShowDeleteBtn,
  onCancel,
  onDelete
}: ProjectDetailsConfirmButtonsProps) {
  const theme = useTheme();
  const isSomethingChanged = useCtxSelectIsChanged();
  const { isMediaUploading, isFromClientCard } = useCtx();

  const {
    touched,
    isValid,
    submitForm,
    errors,
    validateForm,
    resetForm,
    values
  } = useFormikContext<ProjectForm>();

  const isModifyButtonDisabled = !isValid || !touched || !isSomethingChanged;

  const errorFields = getErrorFieldsArrayFromContext(errors);

  const handleConfirm = async () => {
    validateForm();

    if (!isValid) {
      showFillRequiredFieldsToastForFromDraftToReview(errorFields);
      return;
    }

    submitForm();
  };

  const handleDelete = () => {
    resetForm();
    onDelete && onDelete();
    onCancel && onCancel();
  };

  let confirmButton = (
    <ButtonPrimary
      isLoading={loading}
      disabled={isModifyButtonDisabled}
      onClick={handleConfirm}
    >
      Update
    </ButtonPrimary>
  );

  if (isMediaUploading) {
    confirmButton = (
      <ButtonPrimary
        small
        onClick={() => {
          toast.info('Please wait until uploading new assets is complete', '', {
            autoClose: 5000,
            toastId: 'please-wait'
          });
        }}
      >
        Uploading media files...
      </ButtonPrimary>
    );
  }

  if (isFromClientCard) {
    return (
      <ButtonOutline
        small
        onClick={() => {
          onCancel && onCancel();
        }}
      >
        Return to client's card
      </ButtonOutline>
    );
  }

  return (
    <Col width="100%" gridGap="2rem" justifyContent="space-between">
      {isShowDeleteBtn ? (
        <ButtonGhost
          color={theme.colors.red}
          css={`
            &:hover {
              background-color: ${theme.colors.orangeLight};
              color: ${theme.colors.redHover};
            }
          `}
          onClick={handleDelete}
        >
          remove project
        </ButtonGhost>
      ) : (
        <div />
      )}
      {confirmButton}
      {isClient() && (values as any).status === ProjectStatus.Draft && (
        <PublishButton onConfirm={handleConfirm} />
      )}
    </Col>
  );
}

export default ConfirmButtonsWrapper;
