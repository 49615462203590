import { AnnotationUnitProps } from '../AnnotationSystem.types';
import { useAnnotationSystemState } from '../context';

export default function useCurSelUnitProps(customUnitId?: string | null) {
  const { annotationUnits, curSelUnitId } = useAnnotationSystemState();

  return (
    (annotationUnits.find(
      (unit) => unit.unitId === (customUnitId ?? curSelUnitId)
    ) as AnnotationUnitProps) ?? {}
  );
}
