import { Progress } from 'antd';
import styled, { css } from 'styled-components/macro';

const AntdCircleProgress = styled(Progress)(() => {
  return css`
    .ant-progress-text {
      display: none;
    }
    .ant-progress-inner:not(.ant-progress-circle-gradient)
      .ant-progress-circle-path {
      stroke: ${({ theme }) => theme.colors.primary};
      stroke-width: 20px;
    }
  `;
});

export default AntdCircleProgress;
