import styled from 'styled-components/macro';
import { Col } from 'components/_main';

export const Root = styled.div``;
export const ColHeaderStyled = styled(Col)`
  gap: 1rem;
  grid-auto-columns: max-content max-content max-content 1fr max-content
    max-content;
  width: 100%;
`;
