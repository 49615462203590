import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { EMPTY_FUNC } from '../AnnotationSystem.constants';
import { useAnnotationSystemState } from '../context';
import { roundAnnotationUnitsTimestampsToMs } from '../reducer/utils';
import { saveUnits } from '../utils';

export default function useOnResultChange() {
  const { annotationUnits, curZoomLevel, onResultsChange } =
    useAnnotationSystemState();

  /** Saving results every time annotation units changes */
  const onResultChangeDebounced = useDebouncedCallback(
    onResultsChange ?? EMPTY_FUNC,
    100
  );

  useEffect(() => {
    /** Updating results for further saving */
    onResultChangeDebounced(
      saveUnits(
        roundAnnotationUnitsTimestampsToMs(annotationUnits),
        curZoomLevel
      )
    );
  }, [annotationUnits, onResultChangeDebounced, curZoomLevel]);
}
