import React from 'react';
import { AnnotationLabel } from 'components/AnnotationLabel';
import { Row, Text } from 'components/_main';
import { upper } from 'utils';
import { useAddProjectWizardContext } from '../AddProjectWizard.context';
import { ProjectType } from 'appTypes';

function StepLabels() {
  const { type } = useAddProjectWizardContext();

  let labelsTitle = 'Add Labels';
  switch (type) {
    case ProjectType.CONTENT_MODERATION:
      labelsTitle = 'Add Reasons';
      break;
    case ProjectType.DIGITIZATION:
      labelsTitle = 'Add Sections';
      break;
    case ProjectType.IMAGE_ANNOTATION:
    default:
      break;
  }

  return (
    <Row>
      <Text variant="ui-1-bold">{upper(labelsTitle)}</Text>
      <AnnotationLabel />
    </Row>
  );
}

export default StepLabels;
