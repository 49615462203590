import {
  useGetTaskByIdLazyQuery,
  useStartAuditingMutation,
  useStartTaskingMutation,
  useStartPracticeSessionLazyQuery
} from 'generated/graphql';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'utils/toast';
import { useNavigate } from 'react-router-dom';
import useGetSlug from 'hooks/useGetSlug';
import usePrevious from 'hooks/usePrevious';
import { TNewTask } from 'containers/TasksRouter/Tasks.types';
import consoleError from 'utils/consoleError';
import getTaskingUrl from '../utils/getTaskingUrl';
import { TaskingType } from 'appTypes';
import getPracticalTask from 'containers/TasksRouter/utils/getPracticalTask';

interface TaskingDataProps {
  startTaskingData: TNewTask | null;
  isTaskingDataLoading: boolean;
  isTaskingDataError: string | undefined;
  startPracticeSession: () => void;
  practiceEndTime: string;
}

const useTasking = (
  type: TaskingType = 'tasking',
  redirectOnFalse: string | null = '/projects',
  argProjectId?: string,
  argTaskId?: string
): TaskingDataProps => {
  const { projectId, taskId: slugTaskId, topicId, lessonId } = useGetSlug();
  const finalTaskId = argTaskId ?? slugTaskId;
  const finalProjectId = argProjectId ?? projectId;

  const [data, setData] = useState<TNewTask | null>(null);
  const [practiceEndTime, setPracticeEndTime] = useState<string>('');

  const prevTaskId = usePrevious(finalTaskId);
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const [error, setError] = useState<string>();

  const history = useNavigate();
  const [startTasking, { loading: loadingTasking, error: errorTasking }] =
    useStartTaskingMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error: { message: string }) => {
        /** TODO: Refactor. Move such error processing, like 'access forbidden' to one place */
        if (error && error.message) {
          consoleError(error);
          toast.error(error.message);
          redirectOnFalse && history(redirectOnFalse);
        }
      }
    });

  const [startAuditing, { loading: loadingAudit, error: errorAudit }] =
    useStartAuditingMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error: { message: string }) => {
        /** TODO: Refactor. Move such error processing, like 'access forbidden' to one place */
        if (error && error.message) {
          consoleError(error);
          toast.error(error.message);
          redirectOnFalse && history(redirectOnFalse);
        }
      }
    });

  const [
    startPracticeSession,
    { loading: loadingPractice, error: errorPractice }
  ] = useStartPracticeSessionLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error: { message: string }) => {
      if (error && error.message) {
        consoleError(error);
        toast.error(error.message);
        history('/learning/lessons');
      }
    }
  });

  const [
    getTaskById,
    { loading: loadingGetTaskById, error: errorGetTaskById }
  ] = useGetTaskByIdLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error: { message: string }) => {
      /** TODO: Refactor. Move such error processing, like 'access forbidden' to one place */
      if (error && error.message) {
        consoleError(error);
        toast.error(error.message);
        redirectOnFalse && history(redirectOnFalse);
      }
    }
  });

  useEffect(() => {
    const error =
      errorAudit?.message ||
      errorTasking?.message ||
      errorPractice?.message ||
      errorGetTaskById?.message;
    setError(error);
  }, [
    errorAudit?.message,
    errorGetTaskById?.message,
    errorTasking?.message,
    errorPractice?.message
  ]);

  const handleResponse = useCallback(
    (data?: TNewTask | null) => {
      let respData: TNewTask | null | undefined = data;

      if (!respData) {
        consoleError('No data in respData', { respData });
        return;
      }

      const result =
        respData?.result?.data || respData?.result?.labels || respData?.result;

      setData({ ...respData, result, isAvailableForHelper: true });

      const newUrl = getTaskingUrl(
        respData,
        finalProjectId,
        type,
        topicId,
        lessonId
      );

      history(newUrl);
    },
    [finalProjectId, history, type, lessonId, topicId]
  );

  const handleFinally = () => {
    setIsTaskLoading(false);
  };

  const handleOnStartPracticeSession = useCallback(() => {
    setIsTaskLoading(true);
    startPracticeSession({
      variables: {
        lessonId: parseInt(lessonId, 10),
        practiceId: parseInt(finalTaskId, 10),
        topicId: parseInt(topicId, 10)
      }
    })
      .then((resp) => {
        const task = resp?.data?.startPracticSession;
        setPracticeEndTime(task?.sessionEndDate);

        const taskData = getPracticalTask(
          parseInt(finalTaskId, 10),
          task?.task
        );
        handleResponse(taskData);
      })
      .finally(handleFinally);
  }, [lessonId, finalTaskId, topicId, startPracticeSession, handleResponse]);

  const effect = useCallback(() => {
    if (
      data ||
      isTaskLoading ||
      (finalTaskId && prevTaskId && finalTaskId === prevTaskId)
    ) {
      return;
    }

    if (type === 'practice' && (!finalTaskId || finalTaskId !== prevTaskId)) {
      handleOnStartPracticeSession();
      return;
    }

    if (finalTaskId && !prevTaskId) {
      setIsTaskLoading(true);
      getTaskById({
        variables: {
          taskId: parseInt(finalTaskId, 10)
        }
      })
        .then((resp) => handleResponse(resp?.data?.task))
        .finally(handleFinally);
      return;
    }

    if (type === 'auditing' && (!finalTaskId || finalTaskId !== prevTaskId)) {
      setIsTaskLoading(true);
      startAuditing({
        variables: {
          projectId: parseInt(finalProjectId)
        }
      })
        .then((resp) => handleResponse(resp?.data?.startAuditing))
        .finally(handleFinally);
      return;
    }

    if (type === 'tasking' && (!finalTaskId || finalTaskId !== prevTaskId)) {
      setIsTaskLoading(true);
      startTasking({
        variables: {
          projectId: parseInt(finalProjectId)
        }
      })
        .then((resp) => handleResponse(resp?.data?.startTasking))
        .finally(handleFinally);
      return;
    }
  }, [
    data,
    finalProjectId,
    finalTaskId,
    getTaskById,
    handleResponse,
    isTaskLoading,
    prevTaskId,
    startAuditing,
    startTasking,
    handleOnStartPracticeSession,
    type
  ]);

  useLayoutEffect(() => {
    if (!isTaskLoading && !error) {
      effect();
    }
  }, [error, isTaskLoading, effect]);

  const loading =
    isTaskLoading ||
    loadingAudit ||
    loadingTasking ||
    loadingPractice ||
    loadingGetTaskById;

  return {
    startTaskingData: data,
    isTaskingDataLoading: loading,
    isTaskingDataError: error,
    startPracticeSession: handleOnStartPracticeSession,
    practiceEndTime
  };
};

export default useTasking;
