import { MutableRefObject, useCallback, useEffect } from 'react';
import { consoleError } from 'utils';
import {
  AnnotationSystemEventsEnum,
  SvgLayerMoveChangeProps
} from '../AnnotationSystem.types';
import handleResize from '../handlers/handleResize';
import { useAnnotationSystemState } from '../context';
import { getNavigateOnMouseMove } from '../utils';

interface Props {
  unitId?: string;
  ref?: MutableRefObject<SVGElement | HTMLElement | null>;
  onResize?: (props: SvgLayerMoveChangeProps) => void;
  onDrag?: (props: SvgLayerMoveChangeProps) => void;
}

export default function useSvgLayerMouseMove({
  ref,
  unitId,
  onResize,
  onDrag
}: Props) {
  const { svgLayerRef, curEvent, curSelUnitId, resizePosition } =
    useAnnotationSystemState();

  const handleSvgLayerMouseMove = useCallback(
    (event: MouseEvent) => {
      const element = ref?.current;

      switch (curEvent) {
        case AnnotationSystemEventsEnum.DRAG_MODE: {
          if (event.buttons !== 1) return;

          if (curSelUnitId !== unitId) return;

          if (!element) {
            return;
          }

          const prevX = parseInt(element.getAttribute('x') ?? '0', 10);
          const prevY = parseInt(element.getAttribute('y') ?? '0', 10);

          const newX = prevX + event.movementX;
          const newY = prevY + event.movementY;

          element.setAttribute('x', newX.toString());
          element.setAttribute('y', newY.toString());

          onDrag &&
            onDrag({
              dX: event.movementX,
              dY: event.movementY,
              newX,
              newY
            });

          return;
        }
        case AnnotationSystemEventsEnum.RESIZE_MODE: {
          if (event.buttons !== 1) return;

          if (curSelUnitId !== unitId || !resizePosition) return;

          if (!element) {
            return;
          }

          const { w, h, x, y } = handleResize({
            movementY: event.movementY,
            movementX: event.movementX,
            h: parseInt(element.getAttribute('height') ?? '0'),
            w: parseInt(element.getAttribute('width') ?? '0'),
            y: parseInt(element.getAttribute('y') ?? '0'),
            x: parseInt(element.getAttribute('x') ?? '0'),
            resizePosition
          });

          h && element.setAttribute('height', h.toString());
          w && element.setAttribute('width', w.toString());
          x && element.setAttribute('x', x.toString());
          y && element.setAttribute('y', y.toString());

          onResize &&
            onResize({
              dX: event.movementX,
              dY: event.movementY,
              newX: x ?? 0,
              newY: y ?? 0,
              newH: h,
              newW: w
            });

          return;
        }
        case AnnotationSystemEventsEnum.ZOOM_NAVIGATION: {
          getNavigateOnMouseMove(event, unitId);
          break;
        }
      }
    },
    [ref, curEvent, curSelUnitId, unitId, onDrag, resizePosition, onResize]
  );
  useEffect(() => {
    if (!svgLayerRef) {
      return;
    }

    svgLayerRef.addEventListener('mousemove', handleSvgLayerMouseMove);

    return () => {
      svgLayerRef.removeEventListener('mousemove', handleSvgLayerMouseMove);
    };
  }, [handleSvgLayerMouseMove, svgLayerRef]);
}
