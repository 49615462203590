const colorsFromFigma = {
  gray4: '#C3C3C3',
  gray3: '#828282',
  gray2: '#B1C7D7',
  gray1: '#F4F8FB',
  light: '#909090',
  lighter: '#E9E8E8',
  lightest: '#F7F8F8',
  primary: '#4BC3B5',
  primaryHover: '#4ECCBD',
  primaryLight: '#F0F8F7',
  disabled: '#F4F4F4',
  disabledFg: '#B9B9B9',
  dark: '#373838',
  darker: '#2a2c2c',
  yellow: '#FFC862',
  orange: '#FFA562',
  orangeLight: '#FFEDE0',
  orangeLighter: '#FFF4EB',
  purple: '#9A87E9',
  purpleLight: '#F5F3FD',
  blue: '#5A82CA',
  blueLight: '#DEE6F4',
  green: '#12BB03',
  greenLight: '#2DC520',
  greenLighter: '#DCF4DA',
  successHover: '#52C749',
  red: '#F85D5D',
  redHover: '#E95555',
  redLight: '#FF95A0',
  shadow: 'rgba(180, 180, 180, 0.2)'
};

interface ColorsMinRequired {
  primary: string;
  primaryHover: string;
  primaryLight: string;
  secondary: string;
  secondaryHover: string;
  warning: string;
  warningHover: string;
  alternate: string;
  alternateHover: string;
  bg: string;
  fg: string;
  bgGray: string;
  grayDeep: string;
  light: string;
  lighter: string;
  lightest: string;
  orange: string;
  disabled: string;
  disabledFg: string;
  red: string;
  redHover: string;
  [key: string]: string;
}

const colors: ColorsMinRequired & typeof colorsFromFigma = {
  ...colorsFromFigma,
  disabled: colorsFromFigma.disabled,
  disabledFg: colorsFromFigma.disabledFg,
  primary: colorsFromFigma.primary,
  primaryHover: colorsFromFigma.primaryHover,
  secondary: colorsFromFigma.dark,
  secondaryHover: '#484848',
  warning: '#ff4a11',
  warningHover: '#ff774b',
  alternate: '#3C7BA3',
  alternateHover: '#58a4d2',
  gray: '#88a0b1',
  grayLight: colorsFromFigma.gray3,
  grayLighter: colorsFromFigma.lighter,
  grayLightest: colorsFromFigma.lightest,
  grayMostLightest: '#F4F8FB',
  grayDeep: '#292D34',
  bg: '#ffffff',
  bgGray: colorsFromFigma.gray1,
  fg: colorsFromFigma.dark,
  fgInvert: '#ffffff'
};

export default colors;
