import styled, { css } from 'styled-components/macro';
import { typography, background, color, space, layout } from 'styled-system';
import React, { PropsWithChildren } from 'react';
import { fontStyleFromVariants, colorVariants } from './mixins';
import { TextProps } from './Text.types';
import { px, upper as upperFn } from 'utils';

const TextStyled = styled('span')<TextProps>(
  ({
    light,
    lighter,
    truncate,
    nowrap,
    breakAll,
    lightest,
    theme,
    bold,
    center,
    fs
  }) => css`
    ${fontStyleFromVariants};
    ${colorVariants};
    ${bold &&
    css`
      font-weight: 700;
    `};
    ${center &&
    css`
      text-align: center;
    `};
    ${fs &&
    css`
      font-size: ${px(fs)};
    `};
    ${light &&
    css`
      color: ${theme.colors.light};
    `};
    ${lighter &&
    css`
      color: ${theme.colors.lighter};
    `};
    ${lightest &&
    css`
      color: ${theme.colors.lightest};
    `};
    ${nowrap &&
    css`
      white-space: nowrap;
    `}
    ${breakAll &&
    css`
      word-break: break-all;
    `}
    ${truncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
    ${background};
    ${space};
    ${color};
    ${layout};
    ${typography};
  `
);

function Text({
  upper,
  children,
  color,
  ...restProps
}: PropsWithChildren<TextProps>) {
  return (
    <TextStyled {...restProps} color={color as any}>
      {children && upper && typeof children === 'string'
        ? upperFn(children)
        : children}
    </TextStyled>
  );
}

export default Text;
