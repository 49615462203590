import { AnnotationSystemProps } from 'containers/AnnotationSystem/AnnotationSystem.types';
import { arrayInsertByIndex, arrayModifyByIndex, consoleError } from 'utils';
import { ModifyTransitionPoint } from '../types';
import { roundTimestampsToMs } from '../utils';
import modifyAnnotationUnitReducerCase from './modifyAnnotationUnitReducerCase';

export default function modifyTransitionPointReducerCase(
  state: AnnotationSystemProps,
  { timelineTimestamp, unitId, content }: ModifyTransitionPoint['payload']
) {
  const unit = state.annotationUnits.find((unit) => unit.unitId === unitId);

  if (!unit || !unit?.timelineTransitions) {
    return state;
  }

  const transitions = unit.timelineTransitions.map((transition) => ({
    ...transition,
    timestamp: roundTimestampsToMs(transition.timestamp)
  }));

  const curTransPointIndex = transitions.findIndex(
    (point) => point.timestamp === timelineTimestamp
  );

  /** If now existing transition point found */
  if (curTransPointIndex === -1) {
    const nearestTimePointIndex = unit.timelineTransitions.findIndex(
      ({ timestamp }) => timestamp > timelineTimestamp
    );

    if (nearestTimePointIndex === -1) {
      /** Its mean that there is no timestamp larger then current time, so we should last one as
       *  base for new
       */
      return modifyAnnotationUnitReducerCase(state, {
        unitId: unitId,
        timelineTransitions: [
          ...unit.timelineTransitions,
          {
            ...unit.timelineTransitions[unit.timelineTransitions.length - 1],
            ...content,
            timestamp: roundTimestampsToMs(timelineTimestamp)
          }
        ],
        ...content
      });
    }

    return modifyAnnotationUnitReducerCase(state, {
      unitId: unitId,
      timelineTransitions: arrayInsertByIndex(
        transitions,
        nearestTimePointIndex,
        {
          ...unit.timelineTransitions[nearestTimePointIndex],
          ...content,
          timestamp: roundTimestampsToMs(timelineTimestamp)
        }
      ),
      ...content
    });
  }

  return modifyAnnotationUnitReducerCase(state, {
    unitId: unitId,
    timelineTransitions: arrayModifyByIndex(
      transitions,
      curTransPointIndex,
      content
    ),
    ...content
  });
}
