import { useAnnotationSystemState } from 'containers/AnnotationSystem/context';
import React, { useCallback, useEffect, useState } from 'react';

export default function useCanvasCoords() {
  const { svgLayerRef, curZoomLevel } = useAnnotationSystemState();
  const [canvasCoords, setCanvasCoords] = useState<[number, number]>([0, 0]);

  const handleCoords = useCallback(() => {
    if (!svgLayerRef) return;
    const canvas = svgLayerRef;
    const scrlLeft = canvas.scrollLeft;
    const scrlTop = canvas.scrollTop;
    const { x, y } = canvas.getBoundingClientRect();
    const newCoords: [number, number] = [x - scrlLeft, y - scrlTop];
    setCanvasCoords(newCoords);
  }, [svgLayerRef]);

  useEffect(() => {
    handleCoords();
  }, [curZoomLevel, handleCoords]);

  useEffect(() => {
    const viewPortElement = document.getElementById('AnnotationViewPort');
    if (!viewPortElement) return;

    // @ts-ignore
    viewPortElement?.addEventListener('scroll', handleCoords);
    return () => {
      // @ts-ignore
      viewPortElement?.removeEventListener('scroll', handleCoords);
    };
  }, [handleCoords, svgLayerRef]);

  return canvasCoords;
}
