import { useCallback, useEffect } from 'react';
import isHotkeyIgnore from 'containers/TasksRouter/utils/isHotKeyIgnore';
import {
  useAnnotationSystemActions,
  useAnnotationSystemState
} from 'containers/AnnotationSystem/context';
import useOnCopy from 'containers/AnnotationSystem/hooks/useOnCopy';

function buttonClick(selector: string) {
  const el = document.querySelector<HTMLButtonElement>(
    `[data-testid='${selector}Button']`
  );
  el && el.click();
  return;
}

const useHotKeys = ({
  isAuditMode = false
}: {
  isAuditMode?: boolean | null;
}) => {
  const { onZoomIn, onZoomOut, onZoomReset, onSetCopyUnitId } =
    useAnnotationSystemActions();
  const { copyUnitId } = useAnnotationSystemState();
  const handleCopy = useOnCopy(copyUnitId);

  const logKey = useCallback(
    (e: KeyboardEvent) => {
      const isIgnore = isHotkeyIgnore(e);

      if (isIgnore) {
        return;
      }

      /** Ctrl + V → Copy */
      if ((e.ctrlKey || e.metaKey) && (e.key === 'V' || e.code === 'KeyV')) {
        handleCopy();
        return;
      }

      /** Ctrl + C → Copy */
      if ((e.ctrlKey || e.metaKey) && (e.key === 'C' || e.code === 'KeyC')) {
        onSetCopyUnitId(null);
        return;
      }

      /** C → Zoom reset */
      if (e.key === 'C' || e.code === 'KeyC') {
        onZoomReset(null);
        return;
      }

      /** X → Zoom out */
      if (e.key === 'X' || e.code === 'KeyX') {
        onZoomOut(null);
        return;
      }

      /** Z → Zoom in */
      if (e.key === 'Z' || e.code === 'KeyZ') {
        onZoomIn(null);
        return;
      }

      /** Shift + Enter → Submit/Approve */
      if (e.shiftKey && e.altKey && (e.key === 'Enter' || e.code === 'Enter')) {
        if (isAuditMode) {
          buttonClick('Approve');
          return;
        }

        buttonClick('Submit');
        return;
      }

      /** Shift + S → Return */
      if (e.shiftKey && (e.key === 'S' || e.code === 'KeyS')) {
        if (isAuditMode) {
          buttonClick('Return');
          return;
        }

        buttonClick('Skip');
        return;
      }

      /** Shift + P → Penalty */
      if (e.shiftKey && (e.key === 'P' || e.code === 'KeyP')) {
        if (isAuditMode) {
          buttonClick('Penalty');
          return;
        }
      }
      return;
    },
    [handleCopy, isAuditMode, onSetCopyUnitId, onZoomIn, onZoomOut, onZoomReset]
  );

  useEffect(() => {
    document.addEventListener('keydown', logKey);

    return () => {
      document.removeEventListener('keydown', logKey);
    };
  }, [logKey]);
};

export default useHotKeys;
