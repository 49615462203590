import { css } from 'styled-components/macro';
import { FONT_VARIANTS, TextProps } from '../Text.types';

const fontStyleFromVariants = ({ variant = 'ui-1-regular' }: TextProps) => {
  switch (variant) {
    case FONT_VARIANTS['ui-1-regular']:
      return css`
        font-size: 14px;
        font-weight: 400;
      `;
    case FONT_VARIANTS['ui-1-medium']:
      return css`
        font-size: 14px;
        font-weight: 500;
      `;
    case FONT_VARIANTS['ui-1-semi-bold']:
      return css`
        font-size: 14px;
        font-weight: 600;
      `;
    case FONT_VARIANTS['ui-1-bold']:
      return css`
        font-size: 14px;
        font-weight: 700;
      `;
    case FONT_VARIANTS['ui-2-regular']:
      return css`
        font-size: 15px;
        font-weight: 400;
      `;
    case FONT_VARIANTS['ui-2-bold']:
      return css`
        font-size: 15px;
        font-weight: 700;
      `;
    case FONT_VARIANTS['ui-3-regular']:
      return css`
        font-size: 16px;
        font-weight: 400;
      `;
    case FONT_VARIANTS['ui-3-bold']:
      return css`
        font-size: 16px;
        font-weight: 700;
      `;
    case FONT_VARIANTS['ui-4-bold']:
      return css`
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
      `;
    case FONT_VARIANTS['ui-small-regular']:
      return css`
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
      `;
    case FONT_VARIANTS['ui-small-bold']:
      return css`
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
      `;
    case FONT_VARIANTS['label']:
    case FONT_VARIANTS['ui-small-2-bold']:
      /**
       * Label Figma examples
       *
       * - https://www.figma.com/file/TxNwCWCuOhVYzNjNXmejOg/Taskware-v2?node-id=1258%3A3373&t=Nn0EUhQXMRkIcbQR-0
       */
      return css`
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0.64px;
      `;
    case FONT_VARIANTS['header-1']:
      /**
       * Figma examples
       *
       * - https://www.figma.com/file/TxNwCWCuOhVYzNjNXmejOg/Taskware-v2?node-id=2%3A177&t=Nn0EUhQXMRkIcbQR-0
       */
      return css`
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48.76px;
      `;
    case FONT_VARIANTS['header-2']:
      return css`
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
      `;
    case FONT_VARIANTS['header-3']:
      return css`
        font-weight: 300;
        font-size: 22px;
        line-height: 32px;
      `;
    case FONT_VARIANTS['header-4']:
      return css`
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
      `;
    case FONT_VARIANTS['custom-20px-regular']:
      return css`
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
      `;
    case FONT_VARIANTS['custom-20px-bold']:
      return css`
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
      `;
    default:
      return;
  }
};

export default fontStyleFromVariants;
