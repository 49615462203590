import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Tasks from 'containers/TasksRouter';
import SidebarNav from 'containers/SidebarNav/SidebarNav';
import Users from 'containers/Users';
import useGetUserSessionData from 'hooks/useGetUserSessionData';
import { useSignOut } from 'hooks/useSignOut';
import { isAdmin, isClient, isHelper } from 'utils/role';
import { toast } from 'utils/toast';
import Reports from 'containers/ReportsPage/ReportsPage';
import ChartsWithProjectDetails from 'containers/ChartsWithProjectDetails';
import ProfileDetailsPage from 'containers/ProfileDetails';
import { dashboardCtx, dashboardCtxInitData } from './Dashboard.context';
import { DashboardCtx, DashboardCtxData } from 'appTypes';
import { LayoutStyled, RootLayoutStyled } from './Dashboard.styled';
import DashboardWithProjects from '../DashboardWithProjects/DashboardWithProjects';
import DashboardEmpty from '../DashboardEmpty/DashboardEmpty';
import Clients from '../Clients';
import { consoleError, getTokenStorage } from 'utils';
import useCheckIfNoProjectExists from 'hooks/useCheckIfNoProjectExists';
import ConfirmSignupByLink from 'containers/ConfirmationSignupByLink';
import HelperAcceptInvitation from 'containers/HelperAcceptInvitation';
import { ROUTES } from 'appConstants';
import Microtasking from 'containers/Microtasking';
import LearningMaterials from 'containers/LearningMaterials';
import { varEnv } from 'utils/envVars';
import WorkStatistics from 'containers/WorkStatistics';
import SpinnerLoader from 'components/SpinnerLoader';
import Box from 'components/_main/Box';

export default function Dashboard() {
  const [ctxData, setCtxData] =
    useState<DashboardCtxData>(dashboardCtxInitData);

  const { isNoProjects, loading } = useCheckIfNoProjectExists();

  const ctxValues: DashboardCtx = React.useMemo(
    () => ({
      data: ctxData,
      setData: setCtxData
    }),
    [ctxData]
  );

  const data = useGetUserSessionData();
  const signOut = useSignOut();

  useEffect(() => {
    const token = getTokenStorage();
    if (!token) {
      setTimeout(async () => {
        await signOut();
      }, 100);
    }
  }, [signOut]);

  useEffect(() => {
    if (data?.id && isAdmin() && data?.enabled === false) {
      toast.error(
        'Your account has been suspended. Please contact support@taskware.io if you think this is a mistake',
        { toastId: 'accountNotEnabled' }
      );
      consoleError('User is not active');
      signOut();
    }
  }, [data, signOut]);

  const finalDashboard =
    !isHelper() && isNoProjects ? (
      <DashboardEmpty />
    ) : (
      <DashboardWithProjects />
    );
  const startPageRoute =
    isClient() && isNoProjects ? '/microtasking' : '/projects';

  return (
    <dashboardCtx.Provider value={ctxValues}>
      {/** TODO: Move dashboard layout with AppBar & Sidebar to one place */}
      <RootLayoutStyled data-component={'root-layout-styled'}>
        {!ctxData.isFullScreen && <SidebarNav data-component={'sidebar-nav'} />}
        <LayoutStyled data-component={'layout-styled'}>
          {loading ? (
            <Box grid flexCenter height="100%">
              <Box width="40px" height="40px">
                <SpinnerLoader />
              </Box>
            </Box>
          ) : (
            <Routes>
              <Route path="/projects" element={finalDashboard} />
              <Route
                path="/projects/:projectReviewType"
                element={finalDashboard}
              />
              <Route
                path="/projects/ready/:projectId"
                element={finalDashboard}
              />
              <Route
                path="/projects/unreviewed/:projectId"
                element={finalDashboard}
              />
              <Route
                path="/activate/:hashId"
                element={<ConfirmSignupByLink />}
              />
              {isAdmin() && (
                <>
                  <Route path="/clients" element={<Clients />} />
                  <Route path="/clients/:clientId" element={<Clients />} />
                </>
              )}
              {isHelper() && (
                <>
                  <Route
                    path="/tasks/:auditingType/:projectType/:projectId"
                    element={<Tasks />}
                  />
                  <Route
                    path="/tasks/:auditingType/:projectType/:projectId/:taskId"
                    element={<Tasks />}
                  />
                  <Route path="/learning" element={<LearningMaterials />} />
                  <Route
                    path="/learning/:curPage"
                    element={<LearningMaterials />}
                  />
                  <Route
                    path="/learning/:curPage/:topicId/:lessonId"
                    element={<LearningMaterials />}
                  />
                  <Route
                    path="/learning/:topicId/lesson/:lessonId/:auditingType/:projectType/:taskId"
                    element={<Tasks />}
                  />
                </>
              )}
              {isClient() && (
                <>
                  <Route path="/profile" element={<ProfileDetailsPage />} />
                  <Route
                    path="/project-details/:projectId"
                    element={<ChartsWithProjectDetails />}
                  />
                  <Route
                    path="/project-details"
                    element={<ChartsWithProjectDetails />}
                  />
                </>
              )}
              {isHelper() && (
                <>
                  <Route
                    path={ROUTES.acceptInviteById}
                    element={<HelperAcceptInvitation />}
                  />
                </>
              )}
              {isHelper() && (
                <>
                  <Route
                    path={ROUTES.workStatistics}
                    element={<WorkStatistics />}
                  />
                </>
              )}
              {isAdmin() && (
                <>
                  <Route path="/users" element={<Users />} />
                </>
              )}
              {isAdmin() && (
                <>
                  <Route path="/reports" element={<Reports />} />
                </>
              )}
              {isClient() && (
                <>
                  <Route path="/microtasking" element={<Microtasking />} />
                </>
              )}
              <Route path="*" element={<Navigate to={startPageRoute} />} />
            </Routes>
          )}
        </LayoutStyled>
      </RootLayoutStyled>
    </dashboardCtx.Provider>
  );
}
