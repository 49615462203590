import React from 'react';
import { Tooltip } from 'antd';
import { Col } from 'components/_main';
import { ButtonIcon } from 'components/_main/Button';
import { EditIcon, ArchiveIcon } from 'assets/tableRowActions';
import {
  DELETE_DATA_COMPONENT,
  EDIT_DATA_COMPONENT
} from 'containers/Projects/constants';
import { Project } from 'generated/graphql';
import { ProjectDetails } from 'appTypes';
import Icon from 'components/Icon';

interface AdminActionsActiveProjectsProps {
  onEdit: (data: ProjectDetails) => void;
  onArchive: (data: ProjectDetails) => void;
}

type RowActionsActiveProjectsProps = AdminActionsActiveProjectsProps & {
  data: Project;
};

function RowActionsActiveProjects({
  data,
  onEdit,
  onArchive
}: RowActionsActiveProjectsProps) {
  const handleEdit = () => {
    onEdit && onEdit(data);
  };

  const handleArchive = () => {
    onArchive && onArchive(data);
  };

  return (
    <Col className="row-actions">
      <Tooltip title="Edit">
        <Icon
          hoverStrokeColor="fg"
          data-component={EDIT_DATA_COMPONENT}
          onClick={handleEdit}
        >
          <EditIcon />
        </Icon>
      </Tooltip>
      {!data?.isDeleted ? (
        <Tooltip title="Archive">
          <Icon
            hoverStrokeColor="fg"
            data-component={DELETE_DATA_COMPONENT}
            onClick={handleArchive}
          >
            <ArchiveIcon />
          </Icon>
        </Tooltip>
      ) : null}
    </Col>
  );
}

const adminActiveProjectsActions =
  ({ onEdit, onArchive }: AdminActionsActiveProjectsProps) =>
  (data: Project) =>
    (
      <RowActionsActiveProjects
        data={data}
        onEdit={onEdit}
        onArchive={onArchive}
      />
    );

export default adminActiveProjectsActions;
