import React from 'react';
import { TabProps } from '../../types';
import { StatisticsTabRoot } from './Statistics.ui';
import { Table } from '../../Table';
import columns from './columns';
import StatisticsDataProvider from './StatisticsDataProvider';
import { ColumnsType } from 'antd/es/table';

export type StatisticsData = {
  title: string;
  createdAt: Date;
  finishedAt: Date;
  helperPoints: number;
  helperRate: number;
  penalties: number;
  auditorTasks: number;
  auditorPoints: number;
};

export type StatisticsColumns = ColumnsType;

type StatisticsTabProps = {
  columns?: ColumnsType;
  data?: StatisticsData[];
  loading?: boolean;
  helperId?: number;
} & TabProps;

function StatisticsTabView({ data, loading, columns }: any) {
  return (
    <StatisticsTabRoot>
      <Table
        columns={columns}
        rowKey={'title'}
        dataSource={data ?? []}
        loading={loading}
        pagination={false}
      />
    </StatisticsTabRoot>
  );
}

function StatisticsTab({ helperId, ...restPros }: StatisticsTabProps) {
  return helperId ? (
    <StatisticsTabRoot>
      <StatisticsDataProvider helperId={helperId}>
        <StatisticsTabView columns={columns} />
      </StatisticsDataProvider>
    </StatisticsTabRoot>
  ) : (
    <StatisticsTabRoot>
      <StatisticsTabView columns={columns} {...restPros} />
    </StatisticsTabRoot>
  );
}

export default StatisticsTab;
